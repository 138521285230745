type PurchaseOrderStatus =
  | 'DRAFT'
  | 'SUBMITTED'
  | 'RECEIVING'
  | 'PENDING_LIQUIDATION'
  | 'SHIPPED'
  | 'CLOSED'
  | 'CANCELLED' // TODO: Implement in backend
  | 'ERROR'; // TODO: Implement in the backend

type EditableFields =
  | 'vendor'
  | 'vendorSo'
  | 'shipment'
  | 'receiveDate'
  | 'orderDate'
  | 'terms'
  | 'submittedAt'
  | 'salesTerms'
  | 'buyer'
  | 'businessEntity'
  | 'shipment'
  | 'deliverTo'
  | 'receiveDate'
  | 'shipDate'
  | 'businessEntityContact'
  | 'lineItems'
  | 'expenses'
  | 'currency'
  | 'subsidiary'
  | 'temperatureMin'
  | 'temperatureMax'
  | 'freightCompany';

type FieldEditability = {
  [field in EditableFields]: boolean;
};

export class Validator {
  private static toSubmitted: PurchaseOrderStatus[] = ['DRAFT', 'SUBMITTED'];
  private static toReceiving: PurchaseOrderStatus[] = ['DRAFT', 'SUBMITTED', 'RECEIVING'];
  private static toPendingLiquidation: PurchaseOrderStatus[] = [
    'DRAFT',
    'SUBMITTED',
    'RECEIVING',
    'SHIPPED',
    'PENDING_LIQUIDATION',
  ];

  public static active: PurchaseOrderStatus[] = ['SUBMITTED', 'RECEIVING', 'PENDING_LIQUIDATION'];

  public static isFieldEditable(field: EditableFields, status: PurchaseOrderStatus, isVendorAvailable = false) {
    const editabilityMap: FieldEditability = {
      // Fields editable up to 'SUBMITTED' status
      businessEntity: this.toSubmitted.includes(status),
      businessEntityContact: this.toSubmitted.includes(status),

      // Fields editable up to 'RECEIVING' status
      vendor: this.toReceiving.includes(status),
      deliverTo: this.toReceiving.includes(status),
      lineItems: this.toReceiving.includes(status),

      // Fields editable up to 'PENDING_LIQUIDATION' status
      currency: this.toPendingLiquidation.includes(status),
      receiveDate: this.toPendingLiquidation.includes(status),
      shipDate: this.toPendingLiquidation.includes(status),
      shipment: this.toPendingLiquidation.includes(status) && isVendorAvailable,
      salesTerms: this.toPendingLiquidation.includes(status),
      buyer: this.toPendingLiquidation.includes(status),
      terms: this.toPendingLiquidation.includes(status),
      vendorSo: this.toPendingLiquidation.includes(status),
      temperatureMax: this.toPendingLiquidation.includes(status),
      temperatureMin: this.toPendingLiquidation.includes(status),
      freightCompany: this.toPendingLiquidation.includes(status),

      expenses: this.toPendingLiquidation.includes(status),

      // Always editable
      submittedAt: true,
      orderDate: true,
      subsidiary: true,
    };

    return editabilityMap[field];
  }
}
