import { Prisma } from '@prisma/client';

import getProductWeight from 'src/services/utils/getProductWeight';

import { GenericLineItem } from './computeTotalPallets';

/**
 * Computes the total weight of a set of line items.
 *
 * Handles weight values as either a regular JS number or Prisma.Decimal data type, so it can be used on both the web
 * and api side. The returned weights are always regular JS number.
 */
const computeTotalWeight = (lineItems: Array<GenericLineItem>) => {
  const sampleLineItem = lineItems?.[0];
  const product = sampleLineItem?.product || sampleLineItem?.orderedProduct;

  if (!sampleLineItem) {
    return {
      ordered: 0,
      received: 0,
      picked: 0,
      shipped: 0,
      invoiced: 0,
    };
  }

  if (typeof product?.weightPerInventoryUnit === 'number') {
    // Use regular JS numbers for weight calculations
    const totalWeight = {
      ordered: 0,
      received: 0,
      picked: 0,
      shipped: 0,
      invoiced: 0,
    };

    lineItems
      .filter((li) => li.product)
      .forEach(
        ({ product, orderedProduct, lot, unitsOrdered, unitsReceived, unitsPicked, unitsInvoiced, unitsShipped }) => {
          const productWeight = getProductWeight(product || orderedProduct, lot);

          totalWeight.ordered = totalWeight.ordered + productWeight * (unitsOrdered ?? 0);
          totalWeight.received = totalWeight.received + productWeight * (unitsReceived ?? 0);
          totalWeight.picked = totalWeight.picked + productWeight * (unitsPicked ?? 0);
          totalWeight.shipped = totalWeight.shipped + productWeight * (unitsShipped ?? 0);
          totalWeight.invoiced = totalWeight.invoiced + productWeight * (unitsInvoiced ?? 0);
        }
      );

    return {
      // We force weights to 0 decimal places, rounding any weights to the nearest pound
      ordered: Math.round(totalWeight.ordered),
      received: Math.round(totalWeight.received),
      // external line items will be under shipped, so we need to include them with the picked metrics
      picked: Math.round(totalWeight.picked + totalWeight.shipped),
      shipped: Math.round(totalWeight.shipped),
      invoiced: Math.round(totalWeight.invoiced),
    };
  }

  const totalWeight = {
    ordered: new Prisma.Decimal(0),
    received: new Prisma.Decimal(0),
    picked: new Prisma.Decimal(0),
    shipped: new Prisma.Decimal(0),
    invoiced: new Prisma.Decimal(0),
  };

  lineItems
    .filter((li) => li.product)
    .forEach(
      ({ product, orderedProduct, lot, unitsOrdered, unitsReceived, unitsPicked, unitsInvoiced, unitsShipped }) => {
        const productWeight = getProductWeight(product || orderedProduct, lot);

        totalWeight.ordered = totalWeight.ordered.add(productWeight * (unitsOrdered ?? 0));
        totalWeight.received = totalWeight.received.add(productWeight * (unitsReceived ?? 0));
        totalWeight.picked = totalWeight.picked.add(productWeight * (unitsPicked ?? 0));
        totalWeight.shipped = totalWeight.shipped.add(productWeight * (unitsShipped ?? 0));
        totalWeight.invoiced = totalWeight.invoiced.add(productWeight * (unitsInvoiced ?? 0));
      }
    );

  return {
    // We force weights to 0 decimal places, rounding any weights to the nearest pound
    ordered: totalWeight.ordered.toDecimalPlaces(0).toNumber(),
    received: totalWeight.received.toDecimalPlaces(0).toNumber(),
    // external line items will be under shipped, so we need to include them with the picked metrics
    picked: totalWeight.picked.add(totalWeight.shipped).toDecimalPlaces(0).toNumber(),
    shipped: totalWeight.shipped.toDecimalPlaces(0).toNumber(),
    invoiced: totalWeight.invoiced.toDecimalPlaces(0).toNumber(),
  };
};

export default computeTotalWeight;
