import { useCallback, useMemo } from 'react';

import { Clear } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { GridColDef, gridColumnDefinitionsSelector, useGridApiRef, useGridSelector } from '@mui/x-data-grid-premium';

import { ServerGridFilter } from '../../serverEnhanced/types';
import { ServerEnhancedGridStateWithHelpers } from '../../serverEnhanced/useServerEnhancedGridState';

import FilterItem from './FilterItem';

type Props = {
  columns: GridColDef<any, any, any>[];
  enhancedGridState: ServerEnhancedGridStateWithHelpers;
};

export default function FilterList({ columns, enhancedGridState }: Props) {
  const { gridFilters, updateFilters } = enhancedGridState;

  const removeFilter = useCallback(
    (field: string) => {
      const newItems = gridFilters.filter((item) => item.field !== field);
      updateFilters(newItems);
    },
    [gridFilters, updateFilters]
  );

  const updateFilter = useCallback(
    (field: string, filter: ServerGridFilter) => {
      const newFilters = gridFilters.map((item) => (item.field === field ? filter : item));
      updateFilters(newFilters);
    },
    [gridFilters, updateFilters]
  );

  // Some filters come from places, like the URL bar, so don't fight over them
  const columnFilterPairs = useMemo(() => {
    return gridFilters
      .filter((f) => !f.id)
      .map((filter) => ({
        filter,
        column: columns.find((col) => col.field === filter.field),
      }))
      .filter((pair) => pair.column); // if columns change, the column will not be found. so we want to strip it out to.
  }, [gridFilters, columns]);
  if (!columnFilterPairs.length) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1, flexWrap: 'wrap' }}>
      <p>Filters:</p>
      {columnFilterPairs.map(({ filter, column }) => (
        <FilterItem
          key={filter.field}
          filter={filter}
          column={column}
          removeFilter={() => removeFilter(filter.field)}
          updateFilter={(filter) => updateFilter(filter.field, filter)}
        />
      ))}
      <Button
        variant="text"
        color="error"
        startIcon={<Clear />}
        onClick={() => updateFilters([])}
        sx={{ lineHeight: 1, pt: 1, borderRadius: '999px' }}
      >
        Clear All
      </Button>
    </Box>
  );
}
