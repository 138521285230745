import { Prisma } from '@prisma/client';

/**
 * Returns the per-unit weight of a product. If the product was a "variable weight" product, then the actual received
 * weight is used. Otherwise, the product's weightPerInventoryUnit is used.
 */
const getProductWeight = (
  product: Prisma.ProductGetPayload<unknown>,
  lot?: Prisma.LotGetPayload<unknown>
): number | undefined => {
  if (product.variableWeight && lot && lot.initialQuantity && lot.initialWeight) {
    const totalReceivedWeight = lot.initialWeight;
    return totalReceivedWeight / lot.initialQuantity;
  }

  // This util is used in document templates which are used on both the frontend/backend. The backend uses Decimal but
  // the frontend uses a regular JS number. This check is to ensure that the frontend doesn't throw an error.
  const type = typeof product.weightPerInventoryUnit;

  if (type === 'number') {
    return product.weightPerInventoryUnit as unknown as number;
  }

  if (type === 'string') {
    return new Prisma.Decimal(product.weightPerInventoryUnit).toNumber();
  }

  return product?.weightPerInventoryUnit.toNumber?.();
};

export default getProductWeight;
