import { useQuery } from '@redwoodjs/web';

import { PricingSheetMeta } from '../constants';

export const GET_PRICING_SHEETS = gql`
  query GetPricingSheets {
    pricingSheets {
      id
      draft
      enabled
      name
      customerGroups {
        id
        name
      }
      individualCustomers {
        id
        name
      }
      startDate
      expiryDate
      pricingFormula
    }
  }
`;

export const GET_PRICING_SHEETS_IMPORT = gql`
  query GetPricingSheetsImport {
    pricingSheets {
      id
      draft
      enabled
      name
      startDate
      expiryDate
      pricingFormula
      updatedAt
      entries {
        productId
        pricingFormula
        pricingValue
      }
    }
  }
`;

export default function usePricingSheets(forImport = false): PricingSheetMeta[] {
  const { data } = useQuery(forImport ? GET_PRICING_SHEETS_IMPORT : GET_PRICING_SHEETS);
  return data?.pricingSheets ?? [];
}
