import { useState } from 'react';

import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { MenuItem, Typography } from '@mui/material';

import { DropdownMenuButton } from 'src/components/containers/menus/DropdownMenuButton';
import NewTemplateModal from 'src/components/containers/modals/NewTemplateModal';

export default function NewOrderButton({
  handleCreateNewTemplate,
  handleCreateFromTemplate,
  items,
  types,
  descriptionLabel,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <DropdownMenuButton
        label="New"
        Icon={<ArrowDropDownIcon />}
        menuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
      >
        {items.map(({ label, handleCreateNew }) => {
          return (
            <MenuItem sx={{ display: 'flex' }} onClick={handleCreateNew} key="label">
              <AddIcon sx={{ color: 'action.active', mr: 2 }} />
              <Typography sx={{ my: 'auto' }}>New {label}</Typography>
            </MenuItem>
          );
        })}
        <MenuItem
          sx={{ display: 'flex' }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon sx={{ color: 'action.active', mr: 2 }} />
          <Typography sx={{ my: 'auto' }}>New From Template</Typography>
        </MenuItem>
      </DropdownMenuButton>
      <NewTemplateModal
        open={open}
        handleCreateNewTemplate={handleCreateNewTemplate}
        handleCreateFromTemplate={handleCreateFromTemplate}
        handleClose={() => {
          setOpen(false);
        }}
        types={types}
        label={descriptionLabel}
      />
    </>
  );
}
