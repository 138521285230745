/**
 * Compares two boolean values and returns a numeric value based on the comparison result.
 *
 * @param {boolean} a - The first boolean value to compare.
 * @param {boolean} b - The second boolean value to compare.
 * @returns {number} - A numeric value indicating the comparison result:
 *                    - Returns 1 if `a` is `true` and `b` is `false`.
 *                    - Returns -1 if `a` is `false` and `b` is `true`.
 *                    - Returns 0 if `a` and `b` have the same boolean value.
 */
export default function compareBooleans(a: boolean, b: boolean) {
  if (a && !b) {
    return 1;
  }

  if (!a && b) {
    return -1;
  }

  return 0;
}
