import { createContext, PropsWithChildren, useContext, useState } from 'react';

export const GridFocusContext = createContext<{
  focusedGrid?: string;
  handleGridFocus?: (gridId: string) => void;
}>(undefined);

/**
 * Provider to allow grids to communicate with each other,
 * so that they don't fight over focus.
 */
export default function GridFocusProvider({ children }: PropsWithChildren) {
  const [focusedGrid, setFocusedGrid] = useState<string>(undefined);

  const handleGridFocus = (gridId: string) => {
    setFocusedGrid(gridId);
  };

  return (
    <GridFocusContext.Provider
      value={{
        focusedGrid,
        handleGridFocus,
      }}
    >
      {children}
    </GridFocusContext.Provider>
  );
}

export const useGridFocus = () => useContext(GridFocusContext);
