import { useContext, useEffect, useState } from 'react';

import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from '@xstate/react';

import ScannerIcon from 'src/components/atoms/ScannerIcon';
import { StateMachineContext } from 'src/providers/StateMachineProvider';

export default function TransferScannerModal() {
  const { service } = useContext(StateMachineContext);
  const palletTag = useSelector(service, (state) => state.context.palletTag);
  const existingTransferItem = useSelector(service, (state) => state.context.existingTransferItem);

  const [unitsTransferredValue, setUnitsTransferred] = useState('');

  const unitsTransferred = Number(unitsTransferredValue);
  const palletUnitsRemaining = palletTag?.cartonQuantity - unitsTransferred;

  const notEnoughUnits = unitsTransferred > palletTag?.cartonQuantity;

  useEffect(() => {
    setUnitsTransferred(palletTag?.cartonQuantity || 0);
  }, [palletTag]);

  useEffect(() => {
    service.send('UPDATE_STATE', { unitsTransferred });
  }, [unitsTransferred, service]);

  return (
    <>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="baseline">
            <Typography fontSize="17px" lineHeight="150%" color="text.secondary">
              Lot {palletTag?.lot?.slug}
            </Typography>
            <Typography fontSize="36px" lineHeight="123.5%">
              {palletTag?.tagNumber}
            </Typography>
          </Box>
          <Box hidden /* implement */>
            <IconButton>
              <Search />
            </IconButton>
            <IconButton>
              <ScannerIcon />
            </IconButton>
          </Box>
        </Box>
        <Box my="20px" display="flex" flexDirection="column" gap={1}>
          <Box display="flex" gap={1}>
            {/* <Apple fontSize="small" /> */}
            <Typography variant="body1" color="text.secondary">
              Product: {palletTag?.lot?.product?.description}
            </Typography>
          </Box>
          {palletTag?.weight && (
            <Box display="flex" gap={1}>
              {/* <Weight fontSize="small" /> */}
              <Typography variant="body1" color="text.secondary">
                Net available weight: {palletTag?.weight} lbs
              </Typography>
            </Box>
          )}
          <Box display="flex" gap={1}>
            {/* <Box fontSize="small" /> */}
            <Typography variant="body1" color="text.secondary">
              Net available units: {palletTag?.cartonQuantity} units
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box my="20px">
          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <TextField
                label="Used amount"
                placeholder="e.g. 2"
                fullWidth
                helperText={notEnoughUnits ? 'Pallet does not have enough units.' : 'Enter the desired amount.'}
                defaultValue={0}
                value={unitsTransferredValue}
                type="number"
                onChange={(e) => setUnitsTransferred(e.target.value)}
                error={notEnoughUnits}
              />
              <Box display="flex" ml={1}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={'Add whole pallet'}
                  checked={unitsTransferred === palletTag?.cartonQuantity}
                  onClick={() => {
                    setUnitsTransferred(palletTag?.cartonQuantity?.toString());
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box display="flex" gap={4}>
            <Box mt="12px">
              <Typography variant="overline" color="text.secondary" textTransform="uppercase">
                Remaining:
              </Typography>
            </Box>
            <Box mt="12px">
              <Typography color={palletUnitsRemaining < 0 ? 'error.main' : 'text.secondary'} variant="body1">
                {palletUnitsRemaining} units
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap={4}>
            <Box mt="12px">
              <Typography variant="overline" color="text.secondary" textTransform="uppercase">
                Total:
              </Typography>
            </Box>
            <Box mt="12px">
              <Typography color={palletUnitsRemaining < 0 ? 'error.main' : 'text.secondary'} variant="body1">
                {Math.abs(existingTransferItem?.unitsTransferred ?? 0) + unitsTransferred} units
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            service.send('CANCEL');
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            service.send('CONFIRM', { unitsTransferred });
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
}
