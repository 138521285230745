import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { ProductionRunComponentType } from 'types/graphql';

import { computeProductionRunTotals } from 'src/services/productionRuns/utils';
import computeDescription from 'src/services/products/computed/computeDescription';
import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';
import getProductWeight from 'src/services/utils/getProductWeight';

import { DateBlock, DocumentDetailBlock, Expenses, Footer, Notes, OrgDetailsBlock } from '../sections';
import Table, { TableColumnDefinition, TableRowData } from '../sections/Table';
import { docStyles } from '../utils/styles';

type ProductionRunReportTemplateProps = Prisma.ProductionRunGetPayload<{
  include: {
    organization: { include: { places: true } };
    commodity: true;
    productionRunComponents: {
      include: {
        lot: {
          include: {
            product: {
              include: {
                commodity: true;
                commoditySize: true;
                commodityStyle: true;
                commodityUnit: true;
                commodityLabel: true;
              };
            };
          };
        };
      };
    };
    expenses: true;
    notes: {
      include: {
        membership: { include: { user: true } };
      };
    };
  };
}>;

type ProductionRunComponentTableRowData = {
  id: number;
  lot: string;
  product: string;
  quantity: string;
  weight: string;
  percentWeight: string;
};

function ProductionRunReportHeader({ productionRun }) {
  const { organization } = productionRun;
  const address = getPrimaryPlace(organization.places);

  const totals = computeProductionRunTotals(productionRun);

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={'PRODUCTION RUN REPORT'}
        orgLogoUrl={productionRun.organization.logoUrl}
        orgName={productionRun.organization.label}
        address={address}
      />
      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={`RUN #${productionRun.slug}`}>
          <DateBlock dateHeader={'Started At'} date={productionRun.startedAt} shouldDisplayTime={true} />
          <DateBlock dateHeader={'Completed At'} date={productionRun.completedAt} shouldDisplayTime={true} />
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText={'Commodity'}>
          <Text>Commodity: {productionRun.commodity?.name}</Text>
          <Text>Country of Origin: {productionRun.countryOfOrigin}</Text>
          <Text>Organic: {productionRun.organic ? 'Yes' : 'No'}</Text>
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText={'Totals'}>
          <Text>Used: {totals.totalInputWeight} lbs</Text>
          <Text>Produced: {totals.totalOutputWeight} lbs</Text>
          <Text>Culled: {totals.totalWeightDumped} lbs</Text>
          <Text>Percent Utilized: {totals.percentUtilized.toFixed(2)}%</Text>
          <Text>Percent Culled: {totals.percentDumped.toFixed(2)}%</Text>
        </DocumentDetailBlock>
      </View>
    </View>
  );
}

function ProductionRunComponentSection({
  componentType,
  components,
  productTemplate,
}: {
  componentType: ProductionRunComponentType;
  components: Array<
    Prisma.ProductionRunComponentGetPayload<{
      include: {
        lot: {
          include: {
            product: {
              include: {
                commodity: true;
                commoditySize: true;
                commodityStyle: true;
                commodityUnit: true;
                commodityLabel: true;
              };
            };
          };
        };
      };
    }>
  >;
  productTemplate?: string;
}) {
  const totalWeight = components.reduce((acc, component) => {
    const componentWeight =
      Math.abs(component.quantityChange ?? 0) * getProductWeight(component.lot.product, component.lot);
    return acc + componentWeight;
  }, 0);

  const componentGridColumns: TableColumnDefinition<ProductionRunComponentTableRowData>[] = [
    {
      field: 'lot',
      headerName: 'Lot',
      sx: { minWidth: 50 },
    },
    {
      field: 'product',
      headerName: 'Product',
      sx: { minWidth: 200 },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      sx: { minWidth: 50, textAlign: 'right' },
    },
    {
      field: 'weight',
      headerName: 'Weight',
      sx: { minWidth: 50, textAlign: 'right' },
    },
    {
      field: 'percentWeight',
      headerName: '% Weight',
      sx: { minWidth: 50, textAlign: 'right' },
    },
  ];

  const rows: TableRowData<ProductionRunComponentTableRowData>[] = components.map((component) => {
    const quantity = Math.abs(component.quantityChange);

    const componentWeight = quantity * getProductWeight(component.lot.product, component.lot);

    return {
      id: component.id,
      lot: component.lot.slug,
      product: computeDescription(component.lot.product, productTemplate),
      quantity: `${quantity} ${component.lot.product.commodityUnit.name}`,
      weight: `${componentWeight} lbs`,
      percentWeight: `${((componentWeight / totalWeight) * 100).toFixed(2)}%`,
    };
  });

  const totalQuantityByUnit = components.reduce((acc, component) => {
    const quantity = Math.abs(component.quantityChange);
    const unit = component.lot.product.commodityUnit.name;

    if (!acc[unit]) {
      acc[unit] = 0;
    }

    acc[unit] += quantity;

    return acc;
  }, {});

  return (
    <View style={docStyles.sectionContainer} wrap={false}>
      <View style={docStyles.sectionHeader}>
        <Text>{componentType === 'INPUT' ? 'INPUTS' : 'OUTPUTS'}</Text>
      </View>
      <Table
        columns={componentGridColumns}
        rows={rows}
        aggregation={{
          weight: () => `${totalWeight.toFixed(0)} lbs`,
          percentWeight: () => '100%', // No need to calculate this again--by definition, the total percent weight is 100%
          quantity: () => {
            return Object.entries(totalQuantityByUnit)
              .map(([unit, quantity]) => {
                return `${quantity} ${unit}`;
              })
              .join('\n');
          },
        }}
      />
    </View>
  );
}

export function ProductionRunReportTemplate(productionRun: ProductionRunReportTemplateProps) {
  const { expenses, notes } = productionRun;

  const documentNotes = notes.filter((n) => n.documentTypes.includes('PRODUCTION_RUN_REPORT'));

  const hasExpenses = expenses.length > 0;

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <ProductionRunReportHeader productionRun={productionRun} />

        <ProductionRunComponentSection
          componentType="INPUT"
          components={productionRun.productionRunComponents.filter((component) => component.type === 'INPUT')}
          productTemplate={productionRun.organization.productDescriptionTemplate}
        />
        <ProductionRunComponentSection
          componentType="OUTPUT"
          components={productionRun.productionRunComponents.filter((component) => component.type === 'OUTPUT')}
          productTemplate={productionRun.organization.productDescriptionTemplate}
        />

        {hasExpenses && <Expenses expenses={expenses} />}

        <Notes notes={documentNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
