import { Metadata } from '@redwoodjs/web';

import AssignLocationFlow from 'src/modules/palletTags/containers/AssignLocationFlow';

export default function ScanLocationPage() {
  return (
    <>
      <Metadata title="Scan Location" />
      <div className="flex flex-col sm:gap-3 p-4 items-center bg-white min-h-full">
        <AssignLocationFlow close={() => {}} />
      </div>
    </>
  );
}
