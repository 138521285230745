import { CustomFields, Shipment } from '../../types';
import { TemplateComponent } from '../DocumentGenerator';
import { formatDate } from '../templates/formatters';

import { AddressComponent, AddressTemplateType } from './address.template';

export type ShipmentTemplateType = {
  shipDate: string;
  deliveryDate: string;
  origin: AddressTemplateType;
  destination: AddressTemplateType;
  temperatureMin: number;
  temperatureMax: number;
  customFields: CustomFields;
};

export const ShipmentComponent: TemplateComponent<Shipment, ShipmentTemplateType> = {
  templatize: (shipment) => ({
    shipDate: formatDate(shipment.shipDate),
    deliveryDate: formatDate(shipment.deliveryDate),
    origin: AddressComponent.templatize(shipment.place),
    destination: AddressComponent.templatize(shipment.destination),
    freightCompany: shipment.freightCompany?.name ?? '--',
    customFields: shipment.customFields,
    terms: shipment.terms,
    temperatureMin: shipment.temperatureMin,
    temperatureMax: shipment.temperatureMax,
  }),
  definitionMap: {
    shipDate: { label: 'Ship Date', type: 'string' },
    deliveryDate: { label: 'Delivery Date', type: 'string' },
    origin: {
      label: 'Origin',
      type: 'address',
      children: AddressComponent.definitionMap,
    },
    destination: {
      label: 'Destination',
      type: 'address',
      children: AddressComponent.definitionMap,
    },
    temperatureMin: { label: 'Minimum Temperature', type: 'number' },
    temperatureMax: { label: 'Maximum Temperature', type: 'number' },
    customFields: {
      label: 'Custom Fields',
      type: 'customFields',
      root: 'purchaseOrder',
      section: 'shipment',
    },
  },
};
