import { SalesTerms } from '../types';

export class Mapper {
  public static getLabel(terms: SalesTerms): string {
    const labelsMap: Record<SalesTerms, string> = {
      COD: 'COD PACA Prompt',
      NET_7: 'Net 7 Days PACA Prompt',
      NET_10: 'Net 10 Days PACA Prompt',
      NET_14: 'Net 14 Days PACA Prompt',
      NET_21: 'Net 21 Days PACA Prompt',
      NET_25: 'Net 25 Days PACA Prompt',
      NET_30: 'Net 30 Days PACA Prompt',
      NET_45: 'Net 45 Days PACA Prompt',
      NET_60: 'Net 60 Days PACA Prompt',

      // deprecated
      NET_40: 'Net 40',
      NET_90: 'Net 90',
    };

    return labelsMap[terms];
  }
}
