import { useMemo, useState } from 'react';

import { Loop } from '@mui/icons-material';
import { Box, Button, Collapse, TableCell, TableRow } from '@mui/material';
import Color from 'color';
import randomColor from 'randomcolor';

import { SlimTextField } from './LabelsContainer';

type LabelEditRowProps = {
  id: string;
  initialLabel: string;
  initialDescription: string;
  initialColor: string;
  handleSave: (label: { id: string; label: string; description: string; color: string }) => void;
  handleCancel: () => void;
  isEditing: boolean;
  isDraft?: boolean;
};

const tryParseColor = (color) => {
  try {
    return Color(color);
  } catch (e) {
    // return red if invalid color
    return Color('#f44336');
  }
};

export default function LabelEditRow({
  id,
  initialLabel,
  initialDescription,
  initialColor,
  handleSave,
  handleCancel,
  isEditing,
  isDraft = false,
}: LabelEditRowProps) {
  const [color, setColor] = useState(Color(initialColor).hex());
  const [label, setLabel] = useState(initialLabel);
  const [description, setDescription] = useState(initialDescription);

  const colors = useMemo(() => {
    const baseColor = tryParseColor(color);
    const textColor = baseColor.isDark() ? baseColor.lightness(75).string() : baseColor.darken(0.75).string();

    return {
      background: baseColor.string(),
      hover: baseColor.lighten(0.025).string(),
      border: baseColor.darken(0.2).string(),
      text: textColor,
    };
  }, [color]);

  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Collapse in={isEditing}>
          <Box display="flex" flexDirection="column" gap={2} pt={1}>
            <Box display="flex" gap={1.5} alignItems="center">
              <SlimTextField
                label="Label"
                sx={{
                  flex: 1,
                }}
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
              <SlimTextField
                label="Description"
                sx={{
                  flex: 1.5,
                }}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <Box display="flex" gap={0.5} alignItems="center">
                <Box
                  width="28px"
                  height="28px"
                  borderRadius="4px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: colors.background,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: colors.hover,
                    },
                    border: '1px solid',
                    borderColor: colors.border,
                  }}
                  onClick={() => {
                    setColor(Color(randomColor()).lightness(90).hex());
                  }}
                >
                  <Loop
                    sx={{
                      fontSize: '18px',
                      color: colors.text,
                    }}
                  />
                </Box>
                <SlimTextField
                  label="Color (Hex)"
                  sx={{
                    width: '100px',
                  }}
                  onChange={(e) => {
                    let value = e.target.value;

                    if (!value.startsWith('#')) {
                      value = `#${value}`;
                    }

                    if (value.length > 7) {
                      value = value.slice(0, 7);
                    }

                    setColor(value);
                  }}
                  value={color}
                />
              </Box>
            </Box>
            <Box display="flex" ml="auto" gap={1}>
              <Button
                onClick={() => {
                  setLabel(initialLabel);
                  setDescription(initialDescription);
                  setColor(initialColor);
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (isDraft) {
                    setLabel(initialLabel);
                    setDescription(initialDescription);
                    setColor(initialColor);
                  }

                  handleSave({
                    id,
                    label,
                    description,
                    color,
                  });
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
