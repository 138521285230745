import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page } from '@react-pdf/renderer';

import { Footer, Notes, ProductsGrid, SalesOrderHeader } from '../sections';
import { docStyles } from '../utils/styles';

type SalesOrderTemplateProps = Prisma.SalesOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    salesPerson: { include: { user: true; subsidiary: true } };
    businessEntity: true;
    businessEntityContact: true;
    subsidiary: true;
    lineItems: {
      select: {
        sellPrice: true;
        unitsPicked: true;
      };
      include: {
        lot: true;
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
            commodityLabel: true;
          };
        };
      };
    };
    expenses: true;
    payables: true;
    notes: {
      include: {
        membership: { include: { user: true } };
      };
    };
  };
}> & {
  overrideContactEmail?: string;
};

export function PickTicketTemplate({ overrideContactEmail, ...salesOrder }: SalesOrderTemplateProps) {
  const allLineItems = salesOrder.lineItems;
  const internalNotes = salesOrder.notes.filter((n) => n.documentTypes.includes('PICK_TICKET'));

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <SalesOrderHeader order={salesOrder} headerName={'PICK TICKET'} overrideContactEmail={overrideContactEmail} />

        <ProductsGrid
          productLineItems={allLineItems}
          showUnitsOrdered={true}
          showLineItemWeight={true}
          orderType="SO"
          productTemplate={salesOrder.organization.productDescriptionTemplate}
        />

        <Notes notes={internalNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
