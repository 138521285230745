import Indicator from './Indicator';

export type CountIconProps = {
  count: number;
  Icon: React.ComponentType;
};

export default function CountIcon({ count, Icon }: CountIconProps) {
  return (
    <div className="relative">
      <Indicator value={count} />
      <Icon />
    </div>
  );
}
