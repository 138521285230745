import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';

import { DocumentDetailBlock, Footer, Notes, OrgDetailsBlock } from '../sections';
import { docStyles } from '../utils/styles';

export function CarrierHeader({ organization, load }) {
  const address = getPrimaryPlace(organization.places);

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={'CARRIER CONFIRMATION'}
        orgLogoUrl={organization.logoUrl}
        orgName={organization.label}
        address={address}
        user={load.coordinator?.user}
      />
      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={'Load #'}>
          <Text>{load.slug}</Text>
          <Text>Ref: #{load.referenceNumber}</Text>
        </DocumentDetailBlock>
        <DocumentDetailBlock headerText={'Carrier'}>
          <Text>{load.freightCompany?.name || '--'}</Text>
        </DocumentDetailBlock>
        <DocumentDetailBlock headerText={'Driver Info'}>
          <Text>Name: {load.driverName}</Text>
          <Text>
            License: {load.licensePlate} {load.licensePlateState}
          </Text>
        </DocumentDetailBlock>
        <DocumentDetailBlock headerText={'Temperature Range'}>
          <Text>
            {load.temperatureMin}°F - {load.temperatureMax}°F
          </Text>
        </DocumentDetailBlock>
      </View>
    </View>
  );
}

// TODO: Add type for input
export default function CarrierConfirmationTemplate({ organization, load, orders, waypoints }) {
  const notes = load.notes.filter((n) => n.documentTypes.includes('CARRIER_CONFIRMATION'));

  const totalPallets = orders.reduce((acc, order) => {
    return acc + order.numPallets.ordered;
  }, 0);

  const totalWeight = orders.reduce((acc, order) => {
    return acc + order.weight.ordered;
  }, 0);

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <CarrierHeader organization={organization} load={load} />

        {waypoints.map((waypoint) => (
          <WaypointGrid waypoint={waypoint} key={waypoint.id} />
        ))}

        <View
          style={{
            ...docStyles.lineItemContainer,
            gap: 4,
            justifyContent: 'flex-end',
          }}
          wrap={false}
        >
          <View style={docStyles.subTotalColumn}>
            <Text style={docStyles.subTotalHeader}>PALLETS</Text>

            <Text style={docStyles.gridTotal}>{totalPallets.toLocaleString('en-US')}</Text>
          </View>

          <View style={docStyles.subTotalColumn}>
            <Text style={docStyles.subTotalHeader}>WEIGHT</Text>

            <Text style={docStyles.gridTotal}>{totalWeight.toLocaleString('en-US')} lbs.</Text>
          </View>
        </View>

        <Notes notes={notes} />

        <Footer />
      </Page>
    </Document>
  );
}

export function WaypointGrid({ waypoint }) {
  const label = waypoint.type === 'PICK_UP' ? 'PICK UP' : 'DROP OFF';
  const { purchaseOrder, salesOrder, buySellOrder } = waypoint.loadOrderConnection;
  const order = purchaseOrder || salesOrder || buySellOrder;
  const prefix = order.__typename === 'PurchaseOrder' ? 'PO' : 'SO';

  return (
    <View style={docStyles.lineItemContainer} wrap={false}>
      {/* Product Description */}
      <View style={docStyles.tableColumn}>
        <Text style={docStyles.columnHeader}>{label}</Text>
        <View style={docStyles.lineItemMainTextCell}>
          <Text style={docStyles.lineItemMainText}>{`${prefix} #${order.slug}`}</Text>
        </View>
      </View>

      <View style={{ ...docStyles.tableColumn, flex: 1.25 }}>
        <Text style={docStyles.columnHeader}>ETA</Text>
        <View style={docStyles.lineItemMainTextCell}>
          <Text style={docStyles.lineItemMainText}>{moment(waypoint.arrivalTime).format('MM/DD/YYYY hh:mm A')}</Text>
        </View>
      </View>

      <View style={docStyles.mainDescriptionColumn}>
        <Text style={docStyles.columnHeader}>ADDRESS</Text>
        <View style={docStyles.lineItemMainTextCell}>
          <Text style={docStyles.lineItemMainText}>{waypoint.location || ' '}</Text>
        </View>
      </View>

      <View style={docStyles.tableColumn}>
        <Text style={docStyles.columnHeader}>PALLETS</Text>

        <View style={docStyles.lineItemMainTextCell}>
          <Text style={docStyles.lineItemMainText}>{order.numPallets.ordered}</Text>
        </View>
      </View>

      {/* TODO: Add lots. 1. Either pre-entered lots, 2. empty space where they can write lots */}

      {/* Weight */}
      <View style={docStyles.tableColumn}>
        <Text style={docStyles.columnHeader}>WEIGHT (lb.)</Text>
        <View key={'lineItemTotalWeight'} style={docStyles.lineItemMainTextCell}>
          <Text style={docStyles.lineItemMainText}>{order.weight.ordered}</Text>
        </View>
      </View>
    </View>
  );
}
