import { GridToolbarQuickFilter } from 'src/modules/grids/toolbar/GridToolbarV2';
import { LedgerEntryGroup } from 'types/graphql';
import { ServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';
import { PaginationWebResult } from 'src/modules/grids/serverEnhanced/types';
import useServerEnhancedGridQuery from 'src/modules/grids/serverEnhanced/useServerEnhancedGridQuery';

const GET_LEDGER_ENTRIES_GRID = gql`
  query GetLedgerEntriesPage($input: PagedLedgerEntriesInput!) {
    pagedLedgerEntries(input: $input) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }

      edges {
        node {
          organizationId
          ledgerKey

          entry {
            key
            memo
            postedAt
            createdAt
            sources {
              slug
              type
            }
            reconciled
            depositSlip {
              id
              depositedAt
              memo
            }
            accountReconciliation {
              id
            }
            counterParties
          }

          lines {
            key
            account {
              path
              name
            }
            amount
            rollingBalance
            sequenceNumber
            sources {
              slug
              type
            }
            memo
          }
        }
      }
    }
  }
`;

export function useLedgerEntriesGrid(gridState: ServerEnhancedGridState): PaginationWebResult<LedgerEntryGroup> {
  return useServerEnhancedGridQuery({
    queryKey: 'pagedLedgerEntries',
    query: GET_LEDGER_ENTRIES_GRID,
    gridState,
  });
}
