import BoldHeader from 'src/components/atoms/grid/BoldHeader';

export default function createCustomFieldColumns(
  customFields: {
    key: string;
    name: string;
    type: string;
  }[] = [],
  editable = false
) {
  const fields = {};

  customFields.forEach((customField) => {
    fields[`customFields.${customField.key}`] = {
      sortable: false,
      editable,
      headerName: customField.name,
      type: customField.type,
      minWidth: 100,
      flex: 1,
      renderHeader: BoldHeader,
      valueGetter: (_value, row) => {
        return row.customFields?.[customField.key] ?? null;
      },
      valueSetter: (value, row) => {
        return {
          ...row,
          customFields: {
            ...(row.customFields ?? {}),
            [customField.key]: value,
          },
        };
      },
    };
  });

  return fields;
}
