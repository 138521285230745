import { useQuery } from '@redwoodjs/web';

import CustomerStatementDocumentApi from '../api/CustomerStatementDocument.api';

export default function useCustomerStatementDocument({
  businessEntityId: _businessEntityId,
  skip = false,
}: {
  businessEntityId?: number;
  skip?: boolean;
}) {
  const { data, loading, refetch } = useQuery(CustomerStatementDocumentApi.GetCustomerStatementDocumentData, {
    variables: { businessEntityId: _businessEntityId },
    skip,
  });

  return {
    customerStatement: data,
    loading,
    getDocumentData: (businessEntityId?: number) =>
      refetch({ businessEntityId: businessEntityId ?? _businessEntityId }).then((res) => res.data),
  };
}
