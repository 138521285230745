import { useRef, useState } from 'react';

import { useUser } from '@clerk/clerk-react';
import { AttachFile } from '@mui/icons-material';
import { Box, Button, Collapse, FormControl, IconButton, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { DocumentType, DocumentTemplate } from 'types/graphql';
import { v4 } from 'uuid';

import { Form, useForm } from '@redwoodjs/forms';
import { useMutation } from '@redwoodjs/web';

import { CREATE_NOTE_MUTATION } from 'src/api/notes.api';
import CountIcon from 'src/components/atoms/CountIcon';
import convertToTitleCase from 'src/utils/convertToTitleCase';

import DisplayedDocumentsMenu, { DocumentTypeOptions } from '../menus/DisplayedDocumentsMenu';
import { DropdownMenuButton } from '../menus/DropdownMenuButton';

import { NotedOnId } from './CommentsSection';

export type NewNote = {
  external: boolean;
  onCreate: (note: any) => void;
  documentTypeOptions?: DocumentTypeOptions;
  postCommentTo: NotedOnId;
  defaultDocumentTypes?: DocumentTypeOptions;
  canAttachToDocument?: boolean;
  customDocumentTypeOptions?: DocumentTypeOptions;
};

export default function NewComment({
  external,
  onCreate,
  documentTypeOptions,
  defaultDocumentTypes = [],
  postCommentTo,
  canAttachToDocument,
  customDocumentTypeOptions = [],
}: NewNote) {
  const { enqueueSnackbar } = useSnackbar();
  const [createComment] = useMutation(CREATE_NOTE_MUTATION, {
    onCompleted: () => {
      enqueueSnackbar('Comment Posted', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const formMethods = useForm<{
    comment: string;
  }>({
    defaultValues: {
      comment: '',
    },
  });
  const { user } = useUser();
  const { register, reset, watch } = formMethods;
  const comment = watch('comment');
  const ref = useRef(null);
  const [focused, setFocused] = useState(false);
  const showButtons = focused || comment.length > 0;
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>(defaultDocumentTypes);
  const [customDocumentTypes, setCustomDocumentTypes] = useState<DocumentTemplate[]>([]);

  const count = documentTypes.length + customDocumentTypes.length;
  const attachedDocs = documentTypes.map((type) => convertToTitleCase(type)).join(', ');
  const docsLabel = count > 0 ? `on ${attachedDocs}` : '(No documents selected)';

  return (
    <Form
      ref={ref}
      formMethods={formMethods}
      onSubmit={({ comment }) => {
        createComment({
          variables: {
            input: {
              text: comment,
              external,
              documentTypes: canAttachToDocument ? documentTypes : [],
              customDocumentTypeIds: customDocumentTypes.map((type) => type.id),
              ...postCommentTo,
            },
          },
        });

        onCreate({
          text: comment,
          current: true,
          external,
          id: v4(), // use random string id for local state
          membership: {
            user: {
              firstName: user.firstName,
              lastName: user.lastName,
            },
          },
          createdAt: new Date().toISOString(),
          documentTypes,
          customDocumentTypes: customDocumentTypes.map((type) => ({ documentTemplateId: type.id })),
        });

        reset();
      }}
    >
      <FormControl sx={{ width: '100%', pt: 1 }}>
        <TextField
          multiline
          {...register('comment')}
          label={`Enter new ${external ? `note ${docsLabel}` : 'comment'}`}
          value={comment}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          minRows={2}
          onKeyDown={(e) => {
            if (e.ctrlKey && e.key === 'Enter') {
              ref.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }
          }}
          InputProps={{
            endAdornment: external ? (
              <DropdownMenuButton
                Icon={<CountIcon count={count} Icon={AttachFile} />}
                ButtonComponent={IconButton}
                hint="Attach Documents"
              >
                <DisplayedDocumentsMenu
                  setDocumentTypes={setDocumentTypes}
                  documentTypes={documentTypes}
                  documentTypeOptions={documentTypeOptions}
                  renderOptionLabel={(type) => convertToTitleCase(type)}
                  isOptionSelected={(option, values) => values.includes(option)}
                />
                <DisplayedDocumentsMenu
                  setDocumentTypes={setCustomDocumentTypes}
                  documentTypes={customDocumentTypes}
                  documentTypeOptions={customDocumentTypeOptions}
                  renderOptionLabel={(type) => type.name}
                  isOptionSelected={(option, values) => values.includes(option)}
                />
              </DropdownMenuButton>
            ) : null,
          }}
        />
      </FormControl>
      <Collapse in={showButtons} dir="down">
        <Box
          sx={{
            pt: 1,
            display: 'flex',
          }}
        >
          <Button
            onClick={() => {
              reset();
            }}
            sx={{ ml: 'auto' }}
            disabled={comment.length == 0}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={comment.length == 0}>
            Comment
          </Button>
        </Box>
      </Collapse>
    </Form>
  );
}
