import React from 'react';

import { Prisma } from '@prisma/client';
import { Text, View } from '@react-pdf/renderer';

import { USD } from 'src/lib/money/usd';
import { computeExpenseCost } from 'src/services/expenses/utils/computeExpenseCost';

import { formatCurrency } from '../utils/formatters';
import money from '../utils/money';
import { colors, docStyles } from '../utils/styles';

import Table, { AggregationDefinition, TableColumnDefinition, TableRowData } from './Table';

type ExpenseTableRowData = {
  id: number;
  description: string;
  category: string;
  unitAmount: string;
  quantity: number;
  total: string;
};

const calculateTotalExpenseAmount = (expense: Prisma.ExpenseGetPayload<unknown>) => {
  return computeExpenseCost(expense.currencyAmount ?? expense.unitAmount, expense.quantity);
};

const calculateTotal = (expenses: Prisma.ExpenseGetPayload<unknown>[]) => {
  return expenses.reduce((acc, expense) => {
    return acc.add(calculateTotalExpenseAmount(expense));
  }, USD.fromCents(0));
};

export function Expenses({
  expenses = [],
  viewStyle = docStyles.sectionContainer,
  currency = 'USD',
  showPrices = true,
  headerText = 'CHARGES',
}: {
  expenses: Prisma.ExpenseGetPayload<{
    include: { expenseType: true };
  }>[];
  viewStyle?;
  currency?: string;
  showPrices?: boolean;
  headerText?: string;
}) {
  const expenseGridColumns: Array<TableColumnDefinition<ExpenseTableRowData>> = [
    {
      field: 'description',
      headerName: 'Description',
      sx: { minWidth: 100, flex: 1.75 },
    },
    {
      field: 'category',
      headerName: 'Category',
      sx: { minWidth: 80, flex: 1 },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      sx: { minWidth: 75, textAlign: 'right', flex: 0.75 },
    },
  ];

  if (showPrices) {
    expenseGridColumns.push(
      {
        field: 'unitAmount',
        headerName: 'Unit Price',
        sx: { minWidth: 80, textAlign: 'right', flex: 0.8 },
      },

      {
        field: 'total',
        headerName: 'Subtotal',
        sx: {
          minWidth: 75,
          flex: 0.75,
          textAlign: 'right',
          backgroundColor: colors.subTotalColumnColor,
        },
        headerCellSx: {
          color: 'white',
          backgroundColor: colors.subTotalHeaderColor,
          textAlign: 'center',
        },
      }
    );
  }

  const rows: TableRowData<ExpenseTableRowData>[] = expenses.map((expense) => {
    return {
      id: expense.id,
      description: expense.description,
      category: expense.expenseType.name || '--',
      quantity: expense.quantity,

      ...(showPrices && {
        unitAmount: formatCurrency(money.toDollars(expense.currencyAmount ?? expense.unitAmount), currency),
        total: formatCurrency(calculateTotalExpenseAmount(expense).dollars, currency),
      }),
    };
  });

  const aggregation: AggregationDefinition<ExpenseTableRowData> = {
    total: () => {
      return formatCurrency(calculateTotal(expenses).dollars, currency);
    },
  };

  return (
    <View style={viewStyle} wrap={false}>
      <Text style={docStyles.sectionHeader}>{headerText}</Text>

      <Table columns={expenseGridColumns} rows={rows} aggregation={aggregation} />
    </View>
  );
}
