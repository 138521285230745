import React, { useMemo } from 'react';

import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { colors } from '../utils/styles';

/**
 * A row of data for a table.
 *
 * @param T The type of the data in the table rows
 * @param id The unique identifier for this row
 */
export type TableRowData<T extends Record<string, string | number>> = {
  id: number | string;
} & T;

/**
 * A column definition for a table.
 *
 * @param T The type of the data in the table rows
 * @param field The name of the field in the row data to display in this column
 * @param headerName The name to display in the header cell for this column
 * @param sx Custom style for the entire column (header, data, and aggregation cells)
 * @param headerCellSx Style for header cell in this column; overrides sx
 * @param dataCellSx Style for data cell in this column; overrides sx
 * @param aggregationCellSx Style for aggregation cell in this column; overrides sx
 *
 */
export type TableColumnDefinition<T extends Record<string, string | number>> = {
  field: Extract<keyof T, string>;
  headerName: string;
  sx?: Style;
  headerCellSx?: Style;
  dataCellSx?: Style;
  aggregationCellSx?: Style;
  formatter?: (value: string | number) => string;
};

/**
 * An aggregation definition for a table.
 *
 * @param T The type of the data in the table rows
 * @param aggregationDefinition A partial record of aggregation functions for each column. If a column is not included,
 * no aggregation will be performed for that column. Aggregation functions take an array of rows and return a string or
 * number.
 */
export type AggregationDefinition<T extends Record<string, string | number>> = Partial<
  Record<keyof T, (rows: TableRowData<T>[]) => string | number>
>;

export const tableStyles = StyleSheet.create({
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingVertical: 10,
  },
  headerRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: colors.primaryDarkTextColor,
  },
  headerCell: {
    flex: 1,
    fontSize: 10,
    textTransform: 'uppercase',
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  dataRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: colors.primaryLightTextColor,
  },
  dataCell: {
    flex: 1,
    fontSize: 9,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  aggregationRow: {
    flexDirection: 'row',
  },
  aggregationCell: {
    flex: 1,
    fontSize: 9,
    paddingVertical: 5,
    paddingHorizontal: 10,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: colors.primaryLightTextColor,
  },
  aggregationEmptyCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
});

export default function Table<T extends Record<string, string | number>>({
  columns,
  rows,
  aggregation,
}: {
  columns: TableColumnDefinition<T>[];
  rows: TableRowData<T>[];
  aggregation?: AggregationDefinition<T>;
}) {
  // some columns are filtered out, but would take up space if not properly filtered
  const displayedColumns = useMemo(() => {
    return columns.filter(Boolean);
  }, [columns]);

  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.headerRow}>
        {displayedColumns.map((col) => (
          <Text
            key={col.field}
            style={{
              ...tableStyles.headerCell,
              ...col.sx,
              ...col.headerCellSx,
            }}
          >
            {col.headerName}
          </Text>
        ))}
      </View>

      {rows.map((row, rowIndex) => (
        <View key={rowIndex} style={tableStyles.dataRow}>
          {displayedColumns.map((col) => {
            const formattedValue = col.formatter ? col.formatter(row[col.field]) : row[col.field];

            return (
              <Text
                key={col.field}
                style={{
                  ...tableStyles.dataCell,
                  ...col.sx,
                  ...col.dataCellSx,
                }}
              >
                {formattedValue}
              </Text>
            );
          })}
        </View>
      ))}

      {aggregation && (
        <View style={tableStyles.aggregationRow}>
          {displayedColumns.map((col) => {
            const aggregator = aggregation[col.field];
            let value = null;

            if (aggregator) {
              const computedValue = aggregator(rows);

              value = col.formatter ? col.formatter(computedValue) : computedValue;
            }

            return (
              <Text
                key={col.field}
                style={{
                  ...(aggregation[col.field] ? tableStyles.aggregationCell : tableStyles.aggregationEmptyCell),
                  ...col.sx,
                  ...col.aggregationCellSx,
                }}
              >
                {value}
              </Text>
            );
          })}
        </View>
      )}
    </View>
  );
}
