import { PropsWithChildren } from 'react';

import { Cancel, Edit, Save } from '@mui/icons-material';
import { Box, IconButton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SlugValidator } from 'shared/validators';

import Breadcrumbs from '../atoms/Breadcrumbs';

import { GLOBAL_APP_BAR_HEIGHT } from './AppBar';

export enum PageType {
  // Accounting
  AccountReconciliation,
  AccountReconciliations,
  AccountRegister,
  ChartOfAccounts,

  Invoice,
  Invoices,

  Payment,
  Payments,

  Payable,
  Payables,
  ConsignmentPayable,

  Settlement,
  PrintChecks,
  DepositSlips,

  // Business Intelligence
  AgingReports,
  AccountingReports,
  BusinessIntelligence,

  // Inventory
  Inventory,
  Lot,

  InventoryTransfer,
  InventoryTransfers,

  ProductionRun,
  ProductionRuns,

  // Freight Tracking
  FreightTracking,
  Load,

  // Order Management system
  PurchaseOrder,
  PurchaseOrders,

  ReceivingTicket,
  ReceivingTickets,

  SalesOrder,
  SalesOrders,

  PickTicket,
  PickTickets,

  GrowerProduct,
  GrowerProducts,

  BuySellOrder,
  BuySellOrders,

  OrderBoard,

  // System Settings
  BusinessEntityManagement,
  OrganizationSettings,
  Products,
  SystemManagement,
  PricingSheet,

  SearchResults,
}

interface PageTitleMapping {
  title: string;
  compactTitle: string;
}

const PageTypeToTitleMapping: Record<PageType, PageTitleMapping> = {
  // -- Accounting
  [PageType.AccountReconciliation]: {
    title: 'Account Reconciliation',
    compactTitle: 'Account Reconciliation',
  },
  [PageType.AccountReconciliations]: {
    title: 'Account Reconciliations',
    compactTitle: 'Account Reconciliations',
  },
  [PageType.AccountRegister]: {
    title: 'Account Register',
    compactTitle: 'Account Register',
  },
  [PageType.ChartOfAccounts]: {
    title: 'Accounts',
    compactTitle: 'Accounts',
  },

  [PageType.Invoice]: {
    title: 'Invoice',
    compactTitle: 'Invoice',
  },
  [PageType.Invoices]: {
    title: 'Invoices',
    compactTitle: 'Invoices',
  },

  [PageType.Payment]: {
    title: 'Payment',
    compactTitle: 'Payment',
  },
  [PageType.Payments]: {
    title: 'Payments',
    compactTitle: 'Payments',
  },

  [PageType.Payable]: {
    title: 'Payable',
    compactTitle: 'Payable',
  },
  [PageType.Payables]: {
    title: 'Payables',
    compactTitle: 'Payables',
  },
  [PageType.ConsignmentPayable]: {
    title: 'Consignment Payable',
    compactTitle: 'Consignment Payable',
  },

  [PageType.Settlement]: {
    title: 'Settlement',
    compactTitle: 'Settlement',
  },
  [PageType.PrintChecks]: {
    title: 'Print Checks',
    compactTitle: 'Print Checks',
  },
  [PageType.DepositSlips]: {
    title: 'Deposit Slips',
    compactTitle: 'Deposit Slips',
  },

  // -- Business Intelligence
  [PageType.AgingReports]: {
    title: 'Aging Reports',
    compactTitle: 'Aging Reports',
  },
  [PageType.AccountingReports]: {
    title: 'Accounting Reports',
    compactTitle: 'Accounting Reports',
  },
  [PageType.BusinessIntelligence]: {
    title: 'Business Intelligence',
    compactTitle: 'Business Intelligence',
  },

  // Inventory
  [PageType.Inventory]: {
    title: 'Inventory',
    compactTitle: 'Inventory',
  },
  [PageType.Lot]: {
    title: 'Lot',
    compactTitle: 'Lot',
  },

  [PageType.InventoryTransfer]: {
    title: 'Inventory Transfer',
    compactTitle: 'IT',
  },
  [PageType.InventoryTransfers]: {
    title: 'Inventory Transfers',
    compactTitle: 'Inventory Transfers',
  },

  [PageType.ProductionRun]: {
    title: 'Production Run',
    compactTitle: 'PR',
  },

  [PageType.ProductionRuns]: {
    title: 'Production Runs',
    compactTitle: 'Production Runs',
  },

  // -- Freight Tracking
  [PageType.FreightTracking]: {
    title: 'Freight Tracking',
    compactTitle: 'Freight Tracking',
  },

  [PageType.Load]: {
    title: 'Load',
    compactTitle: 'Load',
  },

  // -- Order Management system
  [PageType.PurchaseOrder]: {
    title: 'Purchase Order',
    compactTitle: 'PO',
  },
  [PageType.PurchaseOrders]: {
    title: 'Purchase Orders',
    compactTitle: 'Purchase Orders',
  },

  [PageType.ReceivingTicket]: {
    title: 'Receiving Ticket',
    compactTitle: 'RT',
  },
  [PageType.ReceivingTickets]: {
    title: 'Receiving Tickets',
    compactTitle: 'Receiving Tickets',
  },

  [PageType.SalesOrder]: {
    title: 'Sales Order',
    compactTitle: 'SO',
  },
  [PageType.SalesOrders]: {
    title: 'Sales Orders',
    compactTitle: 'Sales Orders',
  },

  [PageType.PickTicket]: {
    title: 'Pick Ticket',
    compactTitle: 'Pick Ticket',
  },
  [PageType.PickTickets]: {
    title: 'Pick Tickets',
    compactTitle: 'Pick Tickets',
  },

  [PageType.GrowerProduct]: {
    title: 'Grower Product',
    compactTitle: 'GP',
  },
  [PageType.GrowerProducts]: {
    title: 'Grower Products',
    compactTitle: 'Grower Products',
  },

  [PageType.BuySellOrder]: {
    title: 'Buy-Sell Order',
    compactTitle: 'BSO',
  },
  [PageType.BuySellOrders]: {
    title: 'Buy-Sell Orders',
    compactTitle: 'Buy-Sell Orders',
  },

  [PageType.OrderBoard]: {
    title: 'Order Board',
    compactTitle: 'Order Board',
  },

  // -- System Settings
  [PageType.BusinessEntityManagement]: {
    title: 'Business Entity Management',
    compactTitle: 'Business Entity Management',
  },
  [PageType.OrganizationSettings]: {
    title: 'Organization Settings',
    compactTitle: 'Org Settings',
  },
  [PageType.Products]: {
    title: 'Products',
    compactTitle: 'Products',
  },
  [PageType.SystemManagement]: {
    title: 'System Management',
    compactTitle: 'System Management',
  },
  [PageType.PricingSheet]: {
    title: 'Pricing Sheet',
    compactTitle: 'Pricing Sheet',
  },

  [PageType.SearchResults]: {
    title: 'Search Results',
    compactTitle: 'Search Results',
  },
};

export type PageHeaderProps = {
  /**
   * The type of page that is being rendered.
   */
  pageType: PageType;

  /**
   * The slug of the entity that is being rendered.
   */
  entitySlug?: string;

  /**
   * Delimiter to join Page Type and Entity Slug
   */
  delimiter?: string;

  /**
   * Custom page title to render, overrides pageType et al
   */
  title?: string;

  /**
   * Sometimes the Slug of the page is editable. This function is called when the edited title is to be saved in the backend.
   * @param slug New slug
   * @returns
   */
  entitySlugEditHandler?: (slug: string) => Promise<string>;

  children?: PropsWithChildren<React.ReactNode>;
  error?: boolean;
  info?: boolean;
  afterBreadcrumbs?: React.ReactNode;
  afterTitle?: React.ReactNode;
};

const getBorderColor = (theme, error, info) => {
  if (error) {
    return theme.palette.error.light;
  }

  if (info) {
    return theme.palette.info.light;
  }
};

export function PageHeader({
  pageType,
  entitySlug,
  delimiter,
  title,

  children,
  error,
  info,
  afterBreadcrumbs = null,
  entitySlugEditHandler = null,
}: PageHeaderProps) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const borderColor = getBorderColor(theme, error, info);

  const [editedPageTitle, setEditedPageTitle] = React.useState<string>(null);
  const [editedCompactPageTitle, setEditedCompactPageTitle] = React.useState<string>(null);

  const pageTitle =
    editedPageTitle ||
    title ||
    (entitySlug && `${PageTypeToTitleMapping[pageType].title} ${delimiter ?? '#'}${entitySlug}`) ||
    PageTypeToTitleMapping[pageType].title;

  const compactPageTitle =
    editedCompactPageTitle ||
    title ||
    (entitySlug && `${PageTypeToTitleMapping[pageType].compactTitle} ${delimiter ?? '#'}${entitySlug}`) ||
    PageTypeToTitleMapping[pageType].compactTitle;

  // -- Allow Editing of Title
  const [editMode, setEditMode] = React.useState(false);
  const [editedSlug, setEditedSlug] = React.useState(entitySlug);
  const [editedSlugError, setEditedSlugError] = React.useState(null);

  const handleEditSlugEnterIntent = () => {
    console.debug('Slug edit started');
    setEditMode(true);
  };

  const handleEditSlugSaveIntent = () => {
    console.debug('Saving new slug', editedSlug);

    const error = SlugValidator.validateSlugFormat(editedSlug);
    if (error) {
      console.debug('Slug is not valid');
      setEditedSlugError(error);
      return; // Do not proceed to save
    } else {
      setEditedSlugError(null);
    }

    entitySlugEditHandler(editedSlug)
      .then((savedSlug) => {
        // Update `slug` in the URL

        setEditedPageTitle(`${PageTypeToTitleMapping[pageType].title} ${savedSlug}`);
        setEditedCompactPageTitle(`${PageTypeToTitleMapping[pageType].compactTitle} ${savedSlug}`);

        setEditMode(false);
        console.debug('Slug edit saved');
      })
      .catch((err) => {
        console.error('Slug edit failed');
        console.error(err);

        setEditedSlugError(err.message);
      });
  };

  const handleEditSlugCancelIntent = () => {
    console.debug('Slug edit cancelled');
    setEditMode(false);
  };
  // -- Allow Editing of Title

  return (
    <Box
      sx={{
        width: '100%',
        background: theme.palette.background.paper,
        boxShadow: '0px 0px 0px 1px #E0E0E0',
        position: 'sticky',
        zIndex: 'appBar',
        top: GLOBAL_APP_BAR_HEIGHT,
        overscrollBehaviorY: 'none',
        px: 3,
        py: 1.5,
        borderBottom: borderColor ? `4px solid ${borderColor}` : undefined,
      }}
    >
      <Box display="flex" sx={{ pb: 1 }}>
        <Breadcrumbs />
        <Box>{afterBreadcrumbs}</Box>
      </Box>

      <Box display="flex" gap={1} sx={{ height: '58px' }}>
        {editMode && entitySlugEditHandler ? (
          // Edit the title
          <Tooltip title={editedSlugError ?? ''} open={!!editedSlugError} placement="bottom">
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Box border={editedSlugError ? '1px solid red' : undefined}>
                <TextField
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleEditSlugSaveIntent(); // Call the function to save the slug
                      e.preventDefault();
                    }
                  }}
                  value={editedSlug}
                  onChange={(e) => setEditedSlug(e.target.value?.toUpperCase())}
                  size="small"
                  variant="outlined"
                  inputProps={{ style: { textTransform: 'uppercase' } }} // CSS to visually capitalize letters
                />
              </Box>

              <IconButton onClick={handleEditSlugSaveIntent}>
                <Save />
              </IconButton>

              <IconButton onClick={handleEditSlugCancelIntent}>
                <Cancel />
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          // Show the title
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" textOverflow="ellipsis" noWrap>
              {(smallScreen && compactPageTitle) || pageTitle}
            </Typography>

            {entitySlugEditHandler && (
              <IconButton onClick={handleEditSlugEnterIntent}>
                <Edit />
              </IconButton>
            )}
          </Box>
        )}

        {children}
      </Box>
    </Box>
  );
}
