export const UpdateSlug = gql`
  mutation updateWorkOrderSlug($id: Int!, $slug: String!) {
    updateWorkOrderSlug(id: $id, slug: $slug) {
      id
      slug
    }
  }
`;

export const UpdateOrder = gql`
  mutation UpdateWorkOrder($id: Int!, $input: UpdateWorkOrderInput!) {
    updateWorkOrder(id: $id, input: $input) {
      id
      grower {
        places {
          ...PlaceAttributes
        }
        contacts {
          id
          name
        }
      }
    }
  }
`;

export const UpdateOrderStatus = gql`
  mutation UpdateWorkOrderStatus($id: Int!, $input: UpdateWorkOrderStatusInput!) {
    updateWorkOrderStatus(id: $id, input: $input) {
      id
      status
      lineItems {
        id
        lot {
          id
          slug
          warehouseId
        }
      }
    }
  }
`;

export const DeleteOrder = gql`
  mutation DeleteWorkOrder($id: Int!) {
    deleteWorkOrder(id: $id) {
      id
    }
  }
`;

export const DuplicateOrder = gql`
  mutation DuplicateWorkOrder($id: Int!, $templateInput: DuplicateOrderTemplateInput) {
    duplicateWorkOrder(id: $id, templateInput: $templateInput) {
      id
      slug
    }
  }
`;

export const FIND_GROWER_PRODUCT_GROWER_QUERY = gql`
  query FindGrowerProductGrower($id: Int!) {
    businessEntity(id: $id) {
      id
      name
      contacts {
        id
        name
      }
      lastGrowerProduct {
        shipment {
          destinationId
        }
        growerId
        growerContactId
        productType
        settlementBasis
        commissionRate
        growerContact {
          id
          name
        }
      }
    }
  }
`;
