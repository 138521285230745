export const attrsToString = (attrs: Record<string, unknown>) => {
  const result = [];

  for (const key in attrs) {
    let value = attrs[key];
    const toParse = value instanceof Array || value instanceof Object;
    value = toParse ? JSON.stringify(value) : value;
    result.push(`${key}=${toParse ? `'${value}'` : `'${value}'`}`);
  }

  return result.length ? ` ${result.join(' ')}` : '';
};

const step = 0.2;
const minDim = 1;
const currentUnit = 1;

export const resizerBtm: Record<string, number> = {
  tl: 0,
  tc: 0,
  tr: 0,
  cl: 0,
  cr: 0,
  bl: 0,
  br: 0,
  minDim,
};

export const resizerRight: Record<string, number> = {
  ...resizerBtm,
  cr: 1,
  bc: 0,
  currentUnit,
  minDim,
  step,
};

export const resizerAll: Record<string, number> = {
  tl: 1,
  tc: 1,
  tr: 1,
  cl: 1,
  cr: 1,
  bl: 1,
  br: 1,
  minDim,
};
