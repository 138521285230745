import { IconButton, IconButtonProps } from '@mui/material';
import { GridExpandMoreIcon, GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';

import { useGridCore } from 'src/providers/GridCoreProvider';

export default function ExpandMore({
  id,
  field,
  rowNode,
  sx,
  hideIfNoChildren = true,
  onClick,
}: GridRenderCellParams & {
  sx?: IconButtonProps['sx'];
  hideIfNoChildren?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const apiRef = useGridApiContext();
  const setGroupExpanded = useGridCore((store) => store.setGroupExpanded);

  if (rowNode.type !== 'group') {
    return null;
  }

  const notExpanded = !rowNode.childrenExpanded;

  if (!rowNode.children && hideIfNoChildren) {
    return null;
  }

  const handleClick: IconButtonProps['onClick'] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, notExpanded);
    apiRef.current.setCellFocus(id, field);

    setGroupExpanded(rowNode.groupingKey, notExpanded);

    onClick?.(event);

    event.stopPropagation();
  };

  return (
    <IconButton sx={{ p: '5px', width: '28px', height: '28px', ...sx }} onClick={handleClick}>
      <GridExpandMoreIcon
        sx={{
          width: '18px',
          height: '18px',
          transform: notExpanded ? 'rotate(-90deg)' : '',
        }}
      />
    </IconButton>
  );
}
