import { useState } from 'react';

import { Box, Button, LinearProgress } from '@mui/material';

export default function LoadingButton({
  handleRequest,
  disabled,
  handleRequestComplete,
  validateRequest,
  ...buttonProps
}: {
  handleRequest: () => Promise<unknown>;
  handleRequestComplete?: () => void;
  validateRequest?: () => boolean;
} & Partial<Omit<React.ComponentProps<typeof Button>, 'key'>>) {
  const [isRequesting, setIsRequesting] = useState(false);

  return (
    <Box position="relative">
      <Button
        onClick={() => {
          const isRequestValid = validateRequest?.() ?? true;

          if (!isRequesting && isRequestValid) {
            setIsRequesting(true);
            handleRequest().finally(() => {
              setIsRequesting(false);
              handleRequestComplete?.();
            });
          }
        }}
        disabled={isRequesting || disabled}
        {...buttonProps}
      >
        {buttonProps.children}
      </Button>
      {isRequesting && (
        <LinearProgress
          sx={{
            position: 'absolute',
            transform: 'translate(0, -100%)',
            width: '100%',
            borderRadius: '0 0 4px 4px',
          }}
        />
      )}
    </Box>
  );
}
