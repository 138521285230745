import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import Cookies from 'js-cookie';
import { DateTime } from 'luxon';

import useExponentialBackoff from 'src/hooks/useExpontentialBackoff';

export const CubeTokenContext = createContext(undefined);

export const CUBE_TOKEN_COOKIE = 'cubeToken';
export const CUBE_TOKEN_EXPIRY_COOKIE = 'cubeToken-expiry';

export const CubeTokenProvider = ({ children }) => {
  const [cubeToken, setCubeToken] = useState(Cookies.get(CUBE_TOKEN_COOKIE) || null);

  const fetchToken = useExponentialBackoff(async () => {
    const response = await fetch('/.redwood/functions/cubeToken', {
      method: 'GET',
    });
    const { token, expiry } = await response.json();

    setCubeToken(token);
    Cookies.set(CUBE_TOKEN_COOKIE, token ?? '', { expires: expiry });
    Cookies.set(CUBE_TOKEN_EXPIRY_COOKIE, DateTime.now().plus({ days: expiry }), {
      expires: expiry,
    });

    return token;
  });

  useEffect(() => {
    // Fetch the token when the component mounts
    if (!cubeToken) {
      fetchToken();
    }
  }, [fetchToken, cubeToken]);

  const cubejsApi = useMemo(
    () =>
      cubejs(cubeToken, {
        apiUrl: process.env.CUBEJS_REST_API,
      }),
    [cubeToken]
  );

  useEffect(() => {
    // Check if the token is about to expire every time the app loads or token changes.
    const checkExpiry = async () => {
      const expiryDate = new Date(Cookies.get(CUBE_TOKEN_EXPIRY_COOKIE));
      const now = new Date();

      // Refresh token 20 min before expiry
      if (expiryDate.getTime() - now.getTime() <= 20 * 60 * 1000) {
        await fetchToken(); // Fetch a new token if it's expired
      }
    };

    checkExpiry();
  }, [cubeToken, fetchToken]);

  return (
    <CubeTokenContext.Provider value={cubeToken}>
      <CubeProvider cubejsApi={cubejsApi}>{children}</CubeProvider>
    </CubeTokenContext.Provider>
  );
};

export const useCubeToken = () => useContext(CubeTokenContext);
