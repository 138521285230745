import useServerEnhancedGridQuery from 'src/modules/grids/serverEnhanced/useServerEnhancedGridQuery';
import { ServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';

const GET_GROWER_PRODUCTS_GRID = gql`
  query GetGrowerProductsPage($input: StandardPageInput) {
    growerProductPage(input: $input) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          slug
          status
          flagged
          isTemplate
          updatedAt
          submittedAt
          receivedAt
          grower {
            id
            name
            places {
              streetAddress1
            }
          }
          growerContact {
            id
            name
            email
          }
          harvestLocation
          harvestedAt
          fieldTicketId
          shipment {
            destination {
              streetAddress1
            }
            shipDate
            deliveryDate
          }
        }
        cursor
      }
    }
  }
`;

export function useGrowerProductsGrid(gridState: ServerEnhancedGridState) {
  return useServerEnhancedGridQuery({
    queryKey: 'growerProductPage',
    query: GET_GROWER_PRODUCTS_GRID,
    gridState,
  });
}
