import { useQuery } from '@redwoodjs/web';
import { GET_ACCOUNT_RECONCILIATIONS } from 'src/api/accountReconciliations.api';
import { useEffect, useState } from 'react';
import { AccountReconciliation, AccountReconciliationStatus } from '$api/types/graphql';
import { DateTime } from 'luxon';
import { Box, Card, CardContent, FormLabel, Tooltip, Typography } from '@mui/material';
import { asUTCDate } from 'shared/utils/DateTime';
import Link from 'src/components/atoms/Link';
import Label from 'src/components/atoms/Label';
import { ThemeProvider } from '@mui/system';
import theme from 'src/lib/theme';
import { CircleOutlined, CircleRounded, Close, EastOutlined, ErrorOutline } from '@mui/icons-material';
import Color from 'color';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { routes } from '@redwoodjs/router';

export type AccountReconciliationsTabProps = {
  accountPath: string;
};

type AccountReconciliationItemProps = {
  accountReconciliationId: number;
  startDate: DateTime;
  status: AccountReconciliationStatus;
  reconciledBalance: number;
  adjustedBalance: number;
  adjustingAccounts: {
    path: string;
    name: string;
  }[];
  accountPath: string;
};

const AccountReconciliationItem = ({
  accountReconciliationId,
  startDate,
  status,
  reconciledBalance,
  adjustedBalance,
  adjustingAccounts,
  accountPath,
}: AccountReconciliationItemProps) => {
  const cardLabelColor = status === 'OPEN' ? 'rgb(102, 114, 57)' : '#F7F8F5';
  const cardLabelIcon =
    status === 'OPEN' ? (
      <CircleRounded sx={{ fontSize: '8px', alignSelf: 'center', color: Color(cardLabelColor).darken(0.5).string() }} />
    ) : (
      <Close sx={{ fontSize: '12px', alignSelf: 'center', color: Color(cardLabelColor).darken(0.5).string() }} />
    );

  const adjustmentAmountColor = adjustedBalance > 0 ? '#951D1D' : '#388E3C';
  const adjustmentIcon =
    adjustedBalance > 0 ? <ErrorOutline sx={{ fontSize: '16px' }} /> : <CheckCircleIcon sx={{ fontSize: '16px' }} />;

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Box sx={{ display: 'flex' }} flexDirection="row" justifyContent="space-between">
          <Box flexDirection="row">
            <Typography variant="h6" textOverflow="ellipsis" noWrap>
              {startDate.toFormat('MM/dd/yyyy')}
              <Label
                color={cardLabelColor}
                label={status === 'OPEN' ? 'Ongoing period' : 'Closed period'}
                description={
                  status === 'OPEN' ? 'More reconciliations can still be done' : 'The reconciliation session is closed'
                }
                icon={cardLabelIcon}
              />
            </Typography>

            <Link
              href={routes.accountRegister({ accountReconciliationId, path: accountPath })}
              color="primary.main"
              label="VIEW DETAILS"
              icon={<EastOutlined />}
              sx={{ textDecoration: 'none' }}
            />
          </Box>
          <Box sx={{ display: 'flex' }} flexDirection="column" justifyContent="right">
            <Box sx={{ display: 'flex' }} flexDirection="row" alignContent="flex-end">
              <Typography variant="subtitle1" color="text.secondary">
                RECONCILED BALANCE:
              </Typography>
              <Typography variant="h6" color="text.main" marginLeft={1}>
                {(reconciledBalance / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }} flexDirection="row-reverse">
              <Tooltip
                title={adjustedBalance > 0 ? 'Adjustment entry was added' : 'No adjustment entry has been added'}
              >
                <Typography variant="subtitle2" color={adjustmentAmountColor}>
                  {adjustmentIcon}
                  {(adjustedBalance / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export const AccountReconciliationsTab = ({ accountPath }: AccountReconciliationsTabProps) => {
  const [reconciliations, setReconciliations] = useState<AccountReconciliation[]>([]);
  const query = useQuery(GET_ACCOUNT_RECONCILIATIONS, { variables: { ledgerAccountPath: accountPath } });

  useEffect(() => {
    if (query.data?.accountReconciliations) {
      setReconciliations(query.data.accountReconciliations);
    }
  }, [query.data]);

  return (
    <ThemeProvider theme={theme}>
      {...reconciliations.map((reconciliation) => (
        <AccountReconciliationItem
          accountReconciliationId={reconciliation.id}
          startDate={asUTCDate(reconciliation.startDate)}
          status={reconciliation.status}
          reconciledBalance={reconciliation.aggregate.reconciledBalance}
          adjustedBalance={reconciliation.adjustments.reduce((sum, adjustment) => sum + adjustment.amount, 0)}
          adjustingAccounts={[]}
          accountPath={accountPath}
        />
      ))}
    </ThemeProvider>
  );
};
