import currency from 'currency.js';

/**
 * Converts a cent-based monetary value into a human-friendly string. Only supports US Dollars.
 *
 * @param amountInCents - The monetary value in cents (e.g. $1.00 should be provided as 100)
 * @returns A human-friendly string with appropriate currency symbol and delimiters
 * @example
 * toHuman(10000); // returns $1.00
 */
function toHuman(amountInCents: currency.Any): string {
  return currency(amountInCents, { fromCents: true }).format();
}

/**
 * Converts a dollar-based monetary value into equivalent cents. Only supports US Dollars.
 *
 * @param amountInDollars - The monetary value in dollars. Strings or numbers are acceptable. Currency symbols ($) and
 * explicit cent values are not required. E.g. "$100" or "$100.00" or "100.00" or 100 or 100.00 are all acceptable.
 * @returns The equivalent monetary value in cents
 * @example
 * toCents("$100.00") // returns 10000
 * toCents("100.00")  // returns 10000
 * toCents(100.00)    // returns 10000
 * toCents(100)       // returns 10000
 */
function toCents(amountInDollars: currency.Any): number {
  return currency(amountInDollars).intValue;
}

function toDollars(amountInCents: currency.Any): number {
  return currency(amountInCents, { fromCents: true }).value;
}

const money = {
  toHuman,
  toCents,
  toDollars,
};

export default money;
