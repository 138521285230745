import { navigate, routes } from '@redwoodjs/router';
import { useMutation } from '@redwoodjs/web';

export const CREATE_GROWER_PRODUCT_MUTATION = gql`
  mutation createWorkOrder($input: CreateWorkOrderInput!) {
    createWorkOrder(input: $input) {
      id
      slug
    }
  }
`;

const CREATE_GROWER_PRODUCT_TEMPLATE_MUTATION = gql`
  mutation createWorkOrderTemplate($name: String!) {
    createWorkOrderTemplate(name: $name) {
      id
      slug
    }
  }
`;

const CREATE_GROWER_PRODUCT_FROM_TEMPLATE_MUTATION = gql`
  mutation createWorkOrderFromTemplate($orderTemplateId: Int!) {
    createWorkOrderFromTemplate(orderTemplateId: $orderTemplateId) {
      id
      slug
    }
  }
`;

export function useCreateGrowerProduct() {
  const [createWorkOrder] = useMutation(CREATE_GROWER_PRODUCT_MUTATION);

  return () => {
    createWorkOrder({
      variables: { input: {} },
      onCompleted: ({ createWorkOrder }) => {
        navigate(routes.growerProduct({ slug: createWorkOrder.slug }));
      },
    });
  };
}

export function useCreateGrowerProductTemplate() {
  const [createTemplate] = useMutation(CREATE_GROWER_PRODUCT_TEMPLATE_MUTATION);

  return (name) => {
    createTemplate({ variables: { name } }).then(({ data }) => {
      const { slug } = data.createWorkOrderTemplate;
      navigate(routes.growerProduct({ slug }));
    });
  };
}

export function useCreateGrowerProductFromTemplate() {
  const [createFromTemplate] = useMutation(CREATE_GROWER_PRODUCT_FROM_TEMPLATE_MUTATION);

  return (orderTemplateId) => {
    createFromTemplate({ variables: { orderTemplateId } }).then(({ data }) => {
      const { slug } = data.createWorkOrderFromTemplate;
      navigate(routes.growerProduct({ slug }));
    });
  };
}
