import { useState } from 'react';

import { Box, Button, Stack, TextField } from '@mui/material';

import { FilterItemProps } from './FilterItem';

export default function NumberFilter({ removeFilter, updateFilter, filter, column }: FilterItemProps) {
  const [from, setFrom] = useState<number>(() => {
    const val = filter.where?.find((w) => w.gte)?.gte;
    return column.serverFilterValueTransform?.from?.(val) ?? val;
  });
  const [to, setTo] = useState<number>(() => {
    const val = filter.where?.find((w) => w.lte)?.lte;
    return column.serverFilterValueTransform?.from?.(val) ?? val;
  });

  return (
    <>
      <Stack direction="row" sx={{ p: 1, gap: 1, alignItems: 'center' }}>
        <TextField
          size="small"
          placeholder="Min"
          className="flex-grow"
          type="number"
          value={from}
          onChange={(e) => setFrom(+e.target.value)}
        />
        <p>to</p>
        <TextField
          size="small"
          placeholder="Max"
          className="flex-grow"
          type="number"
          value={to}
          onChange={(e) => setTo(+e.target.value)}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          p: 1,
          borderTop: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Button variant="text" color="primary" onClick={removeFilter}>
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const where = [];

            const fromValue = column.serverFilterValueTransform?.to?.(from) ?? from;
            const toValue = column.serverFilterValueTransform?.to?.(to) ?? to;

            if (typeof fromValue === 'number') where.push({ gte: fromValue });
            if (typeof toValue === 'number') where.push({ lte: toValue });
            updateFilter({ field: filter.field, where });
          }}
        >
          Apply
        </Button>
      </Box>
    </>
  );
}
