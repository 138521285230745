import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page } from '@react-pdf/renderer';

import { Footer, Notes, ProductsGrid, WorkOrderHeader } from '../sections';
import { docStyles } from '../utils/styles';

type WorkOrderReceivingTicketTemplateProps = Prisma.WorkOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    grower: { include: { places: true } };
    growerContact: true;
    shipment: true;
    lineItems: {
      include: {
        lot: true;
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
          };
        };
      };
    };
    notes: {
      include: {
        membership: {
          include: {
            user: true;
          };
        };
      };
    };
  };
}>;

export function WorkOrderReceivingTicketTemplate({
  overrideContactEmail,
  ...workOrder
}: WorkOrderReceivingTicketTemplateProps) {
  const allLineItems = workOrder.lineItems;
  const internalNotes = workOrder.notes.filter((n) => n.documentTypes.includes('RECEIVING_TICKET'));
  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <WorkOrderHeader
          order={workOrder}
          headerName={'RECEIVING TICKET'}
          overrideContactEmail={overrideContactEmail}
        />

        <ProductsGrid
          productLineItems={allLineItems}
          showLineItemWeight={true}
          showUnitsOrdered={true}
          productTemplate={workOrder.organization.productDescriptionTemplate}
        />

        <Notes notes={internalNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
