import { ExpenseCategory } from 'types/graphql';

/**
 * TODO: Remove when we have a solution for custom expense categories
 */

const EXPENSE_CATEGORY_LABELS: Record<ExpenseCategory, string> = {
  FREIGHT: 'Freight',
  COOLING: 'Cooling',
  STORAGE: 'Warehousing & Storage',
  PACKAGING: 'Packaging',
  LABOR: 'Labor',
  QUALITY_CONTROL: 'Quality Control & Inspection',
  INSURANCE: 'Insurance',
  IMPORT_EXPORT: 'Import & Export Fees',
  COMMISSION: 'Brokerage & Commission',
  MARKETING: 'Marketing & Promotion',
  WASTE: 'Waste & Shrinkage',
  LICENSING: 'Licensing & Permits',
  EQUIPMENT: 'Equipment & Maintenance',
  XDOCK: 'Cross-Dock',
  OTHER: 'Other',
} as const;

const EXPENSE_CATEOGRY_LABELS_719_PRODUCE = {
  // 719 SPECIFIC
  PHYTOSANITARY_CERTIFICATE: 'Phyto-Sanitary Certificate',
  PALLETS: 'Pallets',
  DETENTION: 'Detention',
  EXPORT_FEES: 'Export Fees',
  FEDERAL_INSPECTION: 'Federal Inspection',
  LABELS: 'Labels',
  LAYOVER: 'Layover',
  PACKAGING_PASS_THROUGH: 'Packaging Pass Through',
  PLASTIC: 'Plastic',
  TEMP_RECORDER: 'Temperature Recorder',
  TRUCK_ORDER_NOT_USED: 'Truck Order Not Used',
  UNLOAD_FEE: 'Unload Fee',
  WIRE_TRANSFER_FEE: 'Wire Transfer Fee',
  TROUBLE_SETTLEMENT: 'Trouble Settlement',

  // OVERLAPPING
  STORAGE: 'Warehousing & Storage',
  FREIGHT: 'Freight',
  LABOR: 'Labor',
  OTHER: 'Other',
};

export const EXPENSE_CATEGORY_OPTIONS = Object.keys(EXPENSE_CATEGORY_LABELS) as ExpenseCategory[];

export const EXPENSE_CATEGORY_OPTIONS_719_PRODUCE = Object.keys(
  EXPENSE_CATEOGRY_LABELS_719_PRODUCE
) as ExpenseCategory[];

export const ALL_EXPENSE_CATEGORY_LABELS = {
  ...EXPENSE_CATEGORY_LABELS,
  ...EXPENSE_CATEOGRY_LABELS_719_PRODUCE,
};

export const getExpenseCategoriesForOrg = (orgId: string) => {
  const clerk719Orgs = ['org_2Vwg4hgVWkaSSLBvrQme7LiFE5A', 'org_2QIsxMVKzJczQ1nLuPMtbaPMf0k'];

  if (clerk719Orgs.includes(orgId)) {
    return EXPENSE_CATEGORY_OPTIONS_719_PRODUCE;
  }

  return EXPENSE_CATEGORY_OPTIONS;
};

/**
 * Maps the enumerated ExpenseCategory value to a a user-friendly label for use in documents and UI. If no label is
 * defined, the original value is returned.
 *
 * @param category - the ExpenseCategory enum value
 * @returns a user-friendly label for the given category
 */
export const mapExpenseCategoryToLabel = (category: ExpenseCategory | string): string => {
  return ALL_EXPENSE_CATEGORY_LABELS[category] ?? category;
};
