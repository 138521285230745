import { useMemo } from 'react';

import Skeleton from 'src/components/atoms/Skeleton';

import FormGrid from '../FormGrid';

export type SkeletonEntryFormProps = {
  fields?: number;
};

/*
 * A general purpose skeleton entry form.
 * @param {number} columns - The number of columns to display.
 * @param {number} rows - The number of rows to display.
 */
export default function SkeletonEntryForm({ fields }: SkeletonEntryFormProps) {
  const form = useMemo(() => {
    const form = [];
    for (let i = 0; i < fields; i++) {
      form.push(<Skeleton key={i} variant="rounded" height={56} />);
    }
    return form;
  }, [fields]);

  return <FormGrid>{form}</FormGrid>;
}
