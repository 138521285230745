import { createContext, PropsWithChildren, useCallback, useContext } from 'react';

import { debounce } from '@mui/material';

export const SidebarContext = createContext({
  sidebarOpen: false,
  favorites: [],
  toggleOpen: () => {},
  addFavorite: (_route: string, _label: string) => {},
  removeFavorite: (_route: string) => {},
});

export type Favorites = { route: string; label: string }[];

export function loadFavorites(): Favorites {
  const favorites = localStorage.getItem('favorites');
  return favorites ? JSON.parse(favorites) : [];
}

export default function SidebarContextProvider({ children }: PropsWithChildren) {
  const [open, setOpen] = React.useState(() => localStorage.getItem('sidebar-open') === 'true');
  const [favorites, setFavorites] = React.useState<Favorites>(loadFavorites());

  const toggleOpen = useCallback(() => {
    setOpen(!open);
    localStorage.setItem('sidebar-open', String(!open));
  }, [open, setOpen]);

  const saveFavorites = debounce((favorites) => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
  }, 300);

  const addFavorite = (route: string, label: string) => {
    const update = [...favorites, { route, label }];
    setFavorites(update);
    saveFavorites(update);
  };

  const removeFavorite = (route: string) => {
    const update = favorites.filter((favorite) => favorite.route !== route);
    setFavorites(update);
    saveFavorites(update);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarOpen: open,
        favorites,
        toggleOpen,
        addFavorite,
        removeFavorite,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export const useSidebar = () => useContext(SidebarContext);
