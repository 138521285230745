import { useMemo, useState } from 'react';

import { Button } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { InstantSearch } from 'react-instantsearch-hooks-web';

import SearchLookup from 'src/components/atoms/SearchLookup';
import StyledDataGrid from 'src/components/containers/grids/StyledDataGrid';
import BulkEditCostBasis from 'src/modules/systemManagement/pricingSheets/components/BulkEditCostBasis';
import BulkEditFormula from 'src/modules/systemManagement/pricingSheets/components/BulkEditFormula';
import ImportProducts from 'src/modules/systemManagement/pricingSheets/components/ImportProducts';
import PrintPricingSheet from 'src/modules/systemManagement/pricingSheets/components/PrintPricingSheet';
import useEditPricingSheetEntry from 'src/modules/systemManagement/pricingSheets/hooks/useEditPricingSheetEntry';
import { SearchIndex, useAlgolia } from 'src/providers/AlgoliaProvider';
import fuzzyComplete from 'src/utils/fuzzyComplete';

import { PricingSheetTabProps } from '../containers/EditPricingSheet';
import useAddPricingSheetEntry from '../hooks/useAddPricingSheetEntry';
import usePricingSheetEntries from '../hooks/usePricingSheetEntries';
import usePricingSheetEntriesColumns, { columnOrderToSync } from '../hooks/usePricingSheetEntriesColumns';

export default function ProductList({ pricingSheet }: PricingSheetTabProps) {
  const algolia = useAlgolia();
  const apiRef = useGridApiRef();
  const [selection, setSelection] = useState([]);
  const [showImport, setShowImport] = useState(false);

  const rows = usePricingSheetEntries(pricingSheet.id);
  const columns = usePricingSheetEntriesColumns(
    pricingSheet.pricingFormula,
    pricingSheet.pricingValue,
    pricingSheet.roundingValue
  );
  const editPricingSheetEntry = useEditPricingSheetEntry();

  const selectedRows = useMemo(() => selection.map((rowId) => apiRef.current.getRow(rowId)), [selection, apiRef]);

  const createPricingSheetEntry = useAddPricingSheetEntry();

  return (
    <div className="flex flex-col gap-6 p-6">
      <p>Choose products to include in this pricing list.</p>
      <div className="flex justify-between items-center gap-2">
        <InstantSearch searchClient={algolia} indexName={SearchIndex.Products}>
          <SearchLookup
            label="Search by product name or code"
            filterOptions={fuzzyComplete(['slug', 'description', 'productCode'], {
              filter: (options) => options.filter((o) => !rows.some((r) => r.productId === o.productId)),
            })}
            textFieldClassName="grow max-w-[400px]"
            getOptionLabel={(o) => o.description}
            onSelect={(o) => createPricingSheetEntry({ pricingSheetId: pricingSheet.id, productId: o.productId })}
            renderOption={(props, o) => (
              <li {...props} key={o.objectID}>
                <div className="flex flex-col">
                  <div>{o.description}</div>
                  <div className="text-sm text-grey-500">{o.productCode}</div>
                </div>
              </li>
            )}
          />
        </InstantSearch>
        <Button variant="outlined" onClick={() => setShowImport(true)}>
          Import entries
        </Button>
        {showImport && <ImportProducts pricingSheetId={pricingSheet.id} close={() => setShowImport(false)} />}
        {pricingSheet.pricingFormula === 'CUSTOM' && <BulkEditFormula selectedRows={selectedRows} />}
        <BulkEditCostBasis selectedRows={selectedRows} />
        <PrintPricingSheet sheet={pricingSheet} entries={rows} selectedRows={selectedRows} />
      </div>
      <StyledDataGrid
        checkboxSelection
        rowSelectionModel={selection}
        onRowSelectionModelChange={(selection) => {
          setSelection(selection);
        }}
        storageKey="pricingSheetEntries"
        columnOrderToSync={columnOrderToSync}
        classes={{ row: 'group' }}
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        pagination={true}
        processRowUpdate={(row, oldRow) =>
          row.note !== oldRow.note && editPricingSheetEntry(row.id, { note: row.note })
        }
      />
    </div>
  );
}
