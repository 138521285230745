import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepConnectorProps,
  StepIconProps,
  StepLabel,
  stepLabelClasses,
  Stepper,
  styled,
  useTheme,
} from '@mui/material';
import Color from 'color';
import {
  BuySellOrderStatus,
  InvoiceStatus,
  LoadStatus,
  ProductionRunStatus,
  PurchaseOrderStatus,
  SalesOrderStatus,
  WorkOrderStatus,
} from 'types/graphql';

import theme from 'src/lib/theme';
import convertToTitleCase from 'src/utils/convertToTitleCase';

export const getSalesOrderStatuses = ({
  isEdi,
  isExternal,
}: {
  isEdi: boolean;
  isExternal: boolean;
}): SalesOrderStatus[] => {
  if (isExternal) {
    return ['DRAFT', 'SUBMITTED', 'SHIPPED', 'CLOSED'];
  }

  if (isEdi) {
    return ['PENDING', 'APPROVED', 'PICKING', 'SHIPPED', 'CLOSED'];
  }

  return ['DRAFT', 'SUBMITTED', 'PICKING', 'SHIPPED', 'CLOSED'];
};

export const getPurchaseOrderStatuses = (soldThroughSalesOrder: boolean): PurchaseOrderStatus[] => {
  if (soldThroughSalesOrder) {
    return ['DRAFT', 'SUBMITTED', 'SHIPPED', 'CLOSED'];
  }

  return ['DRAFT', 'SUBMITTED', 'RECEIVING', 'PENDING_LIQUIDATION', 'CLOSED'];
};

export const salesOrderStatuses: SalesOrderStatus[] = [
  'DRAFT',
  'APPROVED',
  'SUBMITTED',
  'PICKING',
  'SHIPPED',
  'CLOSED',
];

export const purchaseOrderStatuses: PurchaseOrderStatus[] = [
  'DRAFT',
  'SUBMITTED',
  'RECEIVING',
  'PENDING_LIQUIDATION',
  'SHIPPED',
  'CLOSED',
];

export const buySellOrderStatuses: BuySellOrderStatus[] = ['DRAFT', 'SUBMITTED', 'PAYMENT_RECONCILIATION', 'CLOSED'];

export const financialEntityStatuses: InvoiceStatus[] = ['DRAFT', 'OPEN', 'PAID_PARTIAL', 'PAID_FULL', 'FINALIZED'];

export const workOrderStatuses: WorkOrderStatus[] = ['DRAFT', 'SUBMITTED', 'RECEIVING', 'PENDING_SETTLEMENT', 'CLOSED'];

export const productionRunStatuses: ProductionRunStatus[] = ['DRAFT', 'IN_PROGRESS', 'COMPLETE'];

export const loadStatuses: LoadStatus[] = ['DRAFT', 'BOOKED', 'EN_ROUTE', 'COMPLETE'];

export default function StatusStepper<T extends string>({
  allStatuses,
  currentStatus,
}: {
  allStatuses: T[];
  currentStatus: T;
}) {
  const currentStatusIndex = allStatuses.indexOf(currentStatus);

  return (
    <Stepper
      activeStep={currentStatusIndex}
      connector={<StatusStepConnector />}
      sx={{ alignItems: 'flex-start', mb: 4.5, px: 2 }}
    >
      {allStatuses.map((label, index) => {
        const beforeLast = index < allStatuses.length - 1;
        const isCurrent = index === currentStatusIndex;

        return (
          <Step key={label} active={isCurrent && beforeLast} completed={index <= currentStatusIndex}>
            <StatusStepLabel StepIconComponent={StatusStepIcon}>{convertToTitleCase(label)}</StatusStepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

const StatusStepLabel = styled(StepLabel)(({ theme }) => ({
  [`&.${stepLabelClasses.root}`]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    position: 'absolute',
    width: 'auto',
    transform: 'translateY(110%)',
  },
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.active}`]: {
      color: Color(theme.palette.primary.main).darken(0.1).toString(),
      fontWeight: 600,
    },
    [`&.${stepLabelClasses.completed}`]: {
      color: Color(theme.palette.primary.light).darken(0.1).toString(),
    },
    [`&.${stepLabelClasses.label}`]: {
      marginTop: 2,
    },
    color: Color(theme.palette.primary.light).darken(0.1).toString(),
  },
}));

const StatusStepConnector = (props: StepConnectorProps) => {
  const theme = useTheme();

  return (
    <StepConnector
      sx={{
        [`&.${stepConnectorClasses.active}`]: {
          [`&.${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: Color(theme.palette.primary.light).alpha(0.26).toString(),
          borderTopWidth: 3,
          borderRadius: 1,
          position: 'relative',
          top: 10,
          left: '-8px', // Adjust this value to expand the line to left
          right: '-8px', // Adjust this value to expand the line to right
          width: 'calc(100% + 16px)',
        },
      }}
      {...props}
    />
  );
};

function StatusStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  if (active) {
    return <CircleTwoToneIcon sx={{ color: Color(theme.palette.primary.main).darken(0.1).toString() }} />;
  }
  if (completed) {
    return <CheckCircleIcon sx={{ color: theme.palette.primary.main }} />;
  }

  return (
    <CircleTwoToneIcon
      sx={{
        color: Color(theme.palette.primary.light).alpha(0.5).toString(),
        position: 'relative',
      }}
    />
  );
}
