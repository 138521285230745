import { Box } from '@mui/material';

import { Metadata } from '@redwoodjs/web';

import ExportGridButton from 'src/components/atoms/ExportGridButton';
import DepositSlipsCell from 'src/components/cells/DepositSlipsCell';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader } from 'src/components/containers/PageHeader';
import { PageType } from 'src/components/containers/PageHeader';

const DepositSlipsPage = () => {
  return (
    <>
      <Metadata title="Deposit Slips" />

      <PageHeader pageType={PageType.DepositSlips} delimiter={null}>
        <Box sx={{ ml: 'auto', display: 'flex', my: 'auto', gap: 1 }}>
          <ExportGridButton />
        </Box>
      </PageHeader>
      <PageBody>
        <DepositSlipsCell />
      </PageBody>
    </>
  );
};

export default DepositSlipsPage;
