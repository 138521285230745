import { useMemo } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';

import { useQuery } from '@redwoodjs/web';

import Autocomplete from 'src/components/atoms/Autocomplete';
import FormField from 'src/components/atoms/form/FormField';

type TemplateOrderType = 'PURCHASE_ORDER' | 'SALES_ORDER' | 'BUY_SELL_ORDER' | 'WORK_ORDER' | 'PRODUCTION_RUN';

const typeLabels: Record<TemplateOrderType, string> = {
  PURCHASE_ORDER: 'Purchase Order',
  SALES_ORDER: 'Sales Order',
  BUY_SELL_ORDER: 'Buy-Sell Order',
  WORK_ORDER: 'Grower Product',
  PRODUCTION_RUN: 'Production Run',
};

export type NewTemplateModalProps = {
  open: boolean;
  handleClose: () => void;
  label: string;
  handleCreateNewTemplate: (name: string, type: TemplateOrderType) => void;
  handleCreateFromTemplate: (slug: string, __typename: string) => void;
  types: TemplateOrderType[];
};

const ORDER_TEMPLATES_QUERY = gql`
  query FindOrderTemplates($types: [OrderTemplateType]!) {
    orderTemplates(types: $types) {
      id
      name
      purchaseOrder {
        id
      }
      salesOrder {
        id
      }
      buySellOrder {
        id
      }
      workOrder {
        id
      }
      productionRun {
        id
      }
    }
  }
`;

export default function NewTemplateModal({
  types,
  handleClose,
  open,
  label,
  handleCreateNewTemplate,
  handleCreateFromTemplate,
}: NewTemplateModalProps) {
  const { data, loading } = useQuery(ORDER_TEMPLATES_QUERY, {
    variables: { types },
  });

  const formik = useFormik({
    initialValues: {
      orderType: types[0],
      name: '',
      template: null,
    },
    onSubmit: ({ orderType, name, template }) => {
      if (name) {
        handleCreateNewTemplate(name, orderType);
      } else {
        const { purchaseOrder, salesOrder, workOrder, buySellOrder, productionRun } = template;

        const templateSource = purchaseOrder || salesOrder || workOrder || buySellOrder || productionRun;

        handleCreateFromTemplate(template.id, templateSource.__typename);
      }

      handleClose();
      formik.resetForm();
    },
  });

  const orderTemplates = useMemo(() => {
    if (loading || !data) {
      return [];
    }

    return data.orderTemplates;
  }, [loading, data]);

  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };

  const templateSelected = !!formik.values.template;
  const creatingNew = formik.values.name?.length > 0;
  const notSelected = !templateSelected && !creatingNew;

  return (
    <Dialog open={open} onClose={closeModal}>
      <FormikProvider value={formik}>
        <Form>
          <DialogTitle>New From Template</DialogTitle>
          <DialogContent>
            <DialogContentText maxWidth="700px">
              Choose an existing template to start a new {label} from, or create a new customizable template.
            </DialogContentText>
            <Box display="flex" flexDirection="column" p={1}>
              <FormField
                margin="dense"
                name="template"
                Field={
                  Autocomplete<{
                    id: string;
                    name: string;
                  }>
                }
                fieldProps={{
                  options: orderTemplates,
                  label: 'From Existing Template',
                  size: 'small',
                  disabled: creatingNew,
                  getOptionLabel: (option) => option.name,
                  details: (option) => {
                    return (
                      <Box>
                        <Typography variant="caption">
                          {option.purchaseOrder && 'Purchase Order'}
                          {option.salesOrder && 'Sales Order'}
                          {option.buySellOrder && 'Buy-Sell Order'}
                          {option.workOrder && 'Grower Product'}
                          {option.productionRun && 'Production Run'}
                        </Typography>
                      </Box>
                    );
                  },
                }}
                continuousSync={false}
              />
              <Typography fontWeight={600}> OR </Typography>

              <Box display="flex" gap={2}>
                <FormField
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  margin="dense"
                  name="name"
                  sx={{
                    flex: 1,
                  }}
                  fieldProps={{
                    type: 'text',
                    disabled: templateSelected,
                    label: 'New Template Name',
                    size: 'small',
                  }}
                  fullWidth
                  continuousSync={false}
                />
                {types.length > 1 && (
                  <FormField
                    margin="dense"
                    name="orderType"
                    sx={{
                      flex: 0.5,
                    }}
                    Field={Autocomplete<string>}
                    fieldProps={{
                      options: types,
                      disabled: templateSelected,
                      label: 'New Template Type',
                      size: 'small',
                      getOptionLabel: (option) => typeLabels[option],
                    }}
                    continuousSync={false}
                  />
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cancel</Button>
            <Button disabled={notSelected} type="submit">
              Create
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
