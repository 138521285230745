import { HTMLAttributes, useEffect, useRef, useState } from 'react';

import {
  AutocompleteOwnerState,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  Box,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import MuiAutoComplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import UnderlinedMatch from './UnderlinedMatch';

export type ExtraAutoCompleteProps = {
  label?: string;
  freeSoloPrefix?: string;
  textFieldProps?: React.ComponentProps<typeof TextField>;
  components?: {
    TextField?: React.ComponentType<React.ComponentProps<typeof TextField>>;
  };
  details?: (option, inputValue: string) => React.ReactNode;
  getOptionKey?: (option) => string;
  error?: boolean;
  showResultsList?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  transform?: (value) => AutocompleteValue;
  border?: string | number;
  width?: string | number;
};

export type AutocompleteWithoutRender<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  renderInput?: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderInput'];
};

export default function Autocomplete<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  label,
  getOptionLabel,
  getOptionKey = getOptionLabel,
  textFieldProps,
  components,
  freeSoloPrefix = 'Add New',
  details,
  onChange,
  error,
  multiple,
  loading,
  value,
  showResultsList = true,
  startAdornment = null,
  endAdornment = null,
  disabled,
  open: controlledOpen,
  transform = (value) => value,
  onOpen,
  onClose,
  onKeyDown,
  border,
  wrapperProps = {},
  width = '100%',
  sx = {},
  renderInput,
  ...props
}: AutocompleteWithoutRender<T, Multiple, DisableClearable, FreeSolo> & ExtraAutoCompleteProps) {
  const UseTextField = components?.TextField || TextField;
  type ValueType = AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>;
  const [highlightedOption, setHighlightedOption] = useState<ValueType>(null);
  const [inputValue, setInputValue] = useState('');
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [deferredEvent, setDeferredEvent] = useState<{
    event: React.SyntheticEvent;
    value: ValueType;
  }>(null);

  const onChangeRef = useRef(onChange);

  /* Since onChange needs to be used by the follow up effect, we need to
   * a ref is needed to keep track of it. If it is referenced directly
   * in the effect, it will cause an infinite loop.
   */
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  /**
   * This is a work around for the tab multi-select tab completion.
   * Unfortunately, when `onChange` is called for multi select inputs,
   * the input value MUST be cleared. If it is not cleared, it will crash.
   * So, this means we have to defer the change to the next render.
   * Putting it in `setTimeout` works, but it has a noticable delay.
   * This is more instantaneous.
   */
  useEffect(() => {
    if (shouldUpdate && deferredEvent) {
      setShouldUpdate(false);
      onChangeRef.current(deferredEvent.event, deferredEvent.value, 'selectOption');
    }
  }, [shouldUpdate, deferredEvent]);

  const disabledColor = disabled && 'primary.disabled';
  const erroredColor = error && 'error.main';
  const overrideColor = disabledColor || erroredColor;
  const renderOption = (
    innerProps: HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState,
    ownerState: AutocompleteOwnerState<T, Multiple, DisableClearable, FreeSolo>
  ) => {
    const isFreeSoloOption =
      typeof option === 'object' &&
      Object.hasOwn(option, 'freeSolo') &&
      (option as { freeSolo?: string } & unknown).freeSolo;
    const showPrefix = isFreeSoloOption && freeSoloPrefix?.length > 0;
    const label = getOptionLabel(option);

    return (
      <li {...innerProps} key={`${getOptionKey(option)}-${state.index}`}>
        {props.renderOption ? (
          props.renderOption(innerProps, option, state, ownerState)
        ) : (
          <div>
            {showPrefix && <span>{`${freeSoloPrefix} "`}</span>}
            <UnderlinedMatch target={label} input={state.inputValue} />
            {showPrefix && <span>"</span>}
            {details && details(option, state.inputValue)}
          </div>
        )}
      </li>
    );
  };

  return (
    <Box width={width} {...wrapperProps} position="relative" height="100%">
      <MuiAutoComplete
        {...props}
        multiple={multiple}
        value={value}
        loading={loading}
        sx={{
          [`& .MuiOutlinedInput-root`]: {
            [`& fieldset`]: {
              borderColor: overrideColor || 'primary.text.disabled',
              border: error ? undefined : border,
            },
            [`&:hover fieldset`]: {
              borderColor: overrideColor || 'primary.main',
              border: error ? undefined : border,
            },
            [`&.Mui-focused fieldset`]: {
              borderColor: overrideColor || 'primary.main',
            },
          },
          [`& .MuiFormLabel-root`]: {
            color: overrideColor || 'text.secondary',
          },
          ...sx,
        }}
        disabled={disabled}
        color="error.main"
        getOptionLabel={getOptionLabel}
        clearOnEscape
        autoHighlight
        renderInput={
          renderInput
            ? renderInput
            : (params) => (
                <UseTextField
                  {...params}
                  {...textFieldProps}
                  label={label}
                  InputProps={{
                    sx: {
                      [`& .MuiAutocomplete-endAdornmen`]: {
                        position: 'absolute',
                        right: 8, // Adjust based on padding or border widths
                        top: '50%',
                        transform: 'translateY(-50%)',
                      },
                    },
                    // TODO: REFACTOR 🤮
                    endAdornment: (
                      <>
                        {loading && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps?.endAdornment}
                        {endAdornment}
                      </>
                    ),
                    ...(showResultsList
                      ? {
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {endAdornment}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          startAdornment: startAdornment || params.InputProps.startAdornment,
                        }
                      : {}),
                    ...(textFieldProps?.InputProps ?? {}),
                  }}
                />
              )
        }
        onOpen={(e) => {
          if (disabled) {
            return;
          }

          setOpen(true);
          onOpen?.(e);
        }}
        onClose={(...e) => {
          setOpen(false);
          onClose?.(...e);
        }}
        open={controlledOpen || open}
        onHighlightChange={(_, option) => {
          setHighlightedOption(transform(option) as ValueType);
        }}
        onChange={(e, value, reason) => {
          const transformed = value instanceof Array ? value.map(transform) : transform(value);
          return onChange(e, transformed, reason);
        }}
        onKeyDown={(e) => {
          const hasValue = highlightedOption && inputValue;

          if (e.key === 'Tab' && hasValue) {
            if (multiple) {
              const curValue = Array.isArray(value) ? value : [value];

              const newValue = [...curValue, highlightedOption];

              setInputValue('');
              setShouldUpdate(true);
              setDeferredEvent({ event: e, value: newValue });

              e.preventDefault();
            } else {
              onChange(e, highlightedOption, 'selectOption');
            }
          }

          onKeyDown?.(e);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderOption={renderOption}
      />
      {loading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      )}
    </Box>
  );
}
