export const UPDATE_STANDARD_PAYABLE = gql`
  mutation UpdateStandardPayable($id: Int!, $input: UpdateStandardPayableInput!) {
    updateStandardPayable(id: $id, input: $input) {
      id
      paymentDueDate
      payTo {
        id
        name
        places {
          ...PlaceAttributes
        }
        contacts {
          id
          name
          disabled
        }

        payablesAccount {
          id
          accountName
          accountNumber
          accountType
        }
      }
    }
  }
`;

export const DELETE_STANDARD_PAYABLE = gql`
  mutation DeleteStandardPayable($id: Int!) {
    deleteStandardPayable(id: $id) {
      id
    }
  }
`;
