const GetCustomerStatementDocumentData = gql`
  query GetCustomerStatementDocumentData($businessEntityId: Int!) {
    organization {
      label
      logoUrl
      primaryLocation {
        ...PlaceAttributes
      }
    }
    invoices(businessEntityId: $businessEntityId, statuses: [OPEN, PAID_PARTIAL]) {
      slug
      totalAmountDue
      sentAt
      payments {
        paymentAmount
      }
      receiptAdjustments {
        amount
        type
      }
      referenceNumber
      salesOrder {
        salesTerms
      }
    }
    standardInvoices(billToId: $businessEntityId, statuses: [OPEN, PAID_PARTIAL]) {
      slug
      totalAmountDue
      invoiceDate
      payments {
        paymentAmount
      }
      receiptAdjustments {
        amount
        type
      }
      referencePayableNum
      paymentTerms
    }
    buySellOrderInvoices(customerId: $businessEntityId, statuses: [OPEN, PAID_PARTIAL]) {
      slug
      totalAmountDue
      sentAt
      payments {
        paymentAmount
      }
      receiptAdjustments {
        amount
        type
      }
      referenceNumber
      buySellOrder {
        customerSalesTerms
      }
    }
  }
`;

export default {
  GetCustomerStatementDocumentData,
};
