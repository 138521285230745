import { useState } from 'react';

import { Box, Button, Stack, TextField } from '@mui/material';

import { dateRangeShortcuts } from 'src/components/atoms/buttons/DateRangePicker';
import QuickDatePicker from 'src/components/atoms/QuickDatePicker';

import { FilterItemProps } from './FilterItem';

export default function DateFilter({ removeFilter, updateFilter, filter, column }: FilterItemProps) {
  const [startDate, setStartDate] = useState<string>(() => {
    const val = filter.where?.find((w) => w.gte)?.gte;
    return column.serverFilterValueTransform?.from?.(val) ?? val;
  });
  const [endDate, setEndDate] = useState<string>(() => {
    const val = filter.where?.find((w) => w.lte)?.lte;
    return column.serverFilterValueTransform?.from?.(val) ?? val;
  });

  return (
    <>
      <Stack direction="row" sx={{ p: 1, gap: 1, alignItems: 'center' }}>
        {dateRangeShortcuts.map((shortcut) => (
          <Button
            key={shortcut.label}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ borderRadius: '999px', textTransform: 'none', fontSize: '14px', px: 1.5, pt: 0.5, pb: 0.25 }}
            onClick={() => {
              const [start, end] = shortcut.getRange();
              setStartDate(start.toISO());
              setEndDate(end.toISO());
            }}
          >
            {shortcut.label}
          </Button>
        ))}
      </Stack>
      <Stack direction="row" sx={{ p: 1, pt: 0, gap: 1, alignItems: 'center', my: 1 }}>
        <QuickDatePicker
          className="flex-grow"
          value={startDate}
          onChange={(date) => setStartDate(date)}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        <QuickDatePicker
          className="flex-grow"
          value={endDate}
          onChange={(date) => setEndDate(date)}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          p: 1,
          borderTop: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Button variant="text" color="primary" onClick={removeFilter}>
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const where = [];
            const fromValue = column.serverFilterValueTransform?.to?.(startDate) ?? startDate;
            const toValue = column.serverFilterValueTransform?.to?.(endDate) ?? endDate;
            if (fromValue) where.push({ gte: fromValue });
            if (toValue) where.push({ lte: toValue });
            updateFilter({ field: filter.field, where });
          }}
        >
          Apply
        </Button>
      </Box>
    </>
  );
}
