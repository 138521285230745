import { useState } from 'react';

import { Delete, Edit, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';

import { useQuery } from '@redwoodjs/web';

import {
  dataGridStateToConfig,
  emptyGridState,
  QUERY_VIEW_CONFIGS,
  useViewConfigActions,
  useViewConfigStore,
} from 'src/managers/viewConfig.store';

import { DropdownMenuButton } from '../../../components/containers/menus/DropdownMenuButton';
import ConfirmModal from '../../../components/containers/modals/ConfirmModal';
import { ServerEnhancedGridStateWithHelpers } from '../serverEnhanced/useServerEnhancedGridState';

type ViewConfigurationMenuProps = {
  page: string;
  enhancedGridState?: ServerEnhancedGridStateWithHelpers;
};

export default function ViewConfigurationMenu({ page, enhancedGridState }: ViewConfigurationMenuProps) {
  const { createViewConfig, updateViewConfig, deleteViewConfig, setConfig } = useViewConfigActions();
  const config = useViewConfigStore((state) => state.view) as {
    id: string;
    name: string;
    config: Record<string, unknown>;
  };
  const apiRef = useGridApiContext();
  const [viewConfigs, setViewConfigs] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [viewToRename, setViewToRename] = useState(null);
  const [viewToDelete, setViewToDelete] = useState(null);

  useQuery(QUERY_VIEW_CONFIGS, {
    variables: { page },
    onCompleted: (data) => {
      setViewConfigs(data.viewConfigurations);
    },
  });

  const buildConfigToExport = () => ({
    ...dataGridStateToConfig(apiRef.current.exportState()),
    quickFilter: enhancedGridState?.quickFilterOption?.label,
    serverFilters: enhancedGridState?.gridFilters ?? [],
  });

  const restoreState = (config) => {
    const { quickFilter, serverFilters, ...gridExport } = config;
    if (enhancedGridState) {
      enhancedGridState.setQuickFilterOption(
        enhancedGridState.quickFilterOptions?.find((f) => f.label === quickFilter)
      );
      enhancedGridState.updateFilters(serverFilters ?? []);
    }
    apiRef.current.restoreState(gridExport);
  };

  const handleCreateConfirm = async (name: string) => {
    setCreateDialogOpen(false);
    const { data } = await createViewConfig({ name, config: buildConfigToExport(), page });
    const newView = data.createViewConfiguration;
    setConfig(newView);
    setViewConfigs([...viewConfigs, newView]);
  };

  return (
    <>
      <DropdownMenuButton
        buttonProps={{ startIcon: <Visibility />, variant: 'text' }}
        label={'VIEWS' + (config?.name ? ` (${config.name})` : '')}
      >
        <Box minWidth="256px">
          {viewConfigs.length > 0 && (
            <Typography sx={{ p: 0.5, pl: 2 }} variant="overline">
              YOUR VIEWS
            </Typography>
          )}
          {viewConfigs.map((viewConfig) => {
            const selected = config?.id === viewConfig.id;
            return (
              <MenuItem
                onClick={() => {
                  restoreState(viewConfig.config);
                  setConfig(viewConfig);
                }}
                selected={selected}
                key={viewConfig.id}
              >
                <Typography sx={{ flexGrow: 1 }}>{viewConfig.name}</Typography>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setViewToRename(viewConfig);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setViewToDelete(viewConfig);
                  }}
                >
                  <Delete />
                </IconButton>
              </MenuItem>
            );
          })}
          {viewConfigs.length > 0 && <Divider />}
          <MenuItem
            onClick={() => {
              apiRef.current.restoreState({ ...emptyGridState });
              enhancedGridState?.resetToDefault();
              setConfig(null);
            }}
          >
            Default view
          </MenuItem>
          <Divider />
          <MenuItem
            disabled={!viewConfigs.find((v) => v.id === config?.id)}
            onClick={() => {
              const updatedConfig = buildConfigToExport();
              updateViewConfig({ id: config.id, config: updatedConfig });
              setViewConfigs(viewConfigs.map((v) => (v.id === config.id ? { ...v, config: updatedConfig } : v)));
            }}
          >
            Save changes
          </MenuItem>
          <MenuItem onClick={() => setCreateDialogOpen(true)}>Save as new view</MenuItem>
        </Box>
      </DropdownMenuButton>
      <ViewNameDialog
        open={createDialogOpen}
        handleClose={() => setCreateDialogOpen(false)}
        handleConfirm={handleCreateConfirm}
        confirmText="Create view"
      />
      <ViewNameDialog
        open={!!viewToRename}
        handleClose={() => setViewToRename(null)}
        handleConfirm={(name) => {
          updateViewConfig({ id: viewToRename.id, name });
          setViewConfigs(viewConfigs.map((v) => (v.id === viewToRename.id ? { ...v, name } : v)));
          setViewToRename(null);
        }}
        confirmText="Rename view"
      />
      <ConfirmModal
        title="Delete view"
        prompt={`Are you sure you want to delete the view "${viewToDelete?.name}"?`}
        open={viewToDelete !== null}
        handleClose={() => setViewToDelete(null)}
        handleConfirm={() => {
          setViewConfigs(viewConfigs.filter((v) => v.id !== viewToDelete.id));
          deleteViewConfig({ id: viewToDelete.id });
          setViewToDelete(null);
        }}
      />
    </>
  );
}

const ViewNameDialog = ({ open, handleClose, confirmText, handleConfirm }) => {
  const [name, setName] = useState('');

  const close = () => {
    setName('');
    handleClose();
  };

  const confirm = () => {
    handleConfirm(name);
    setName('');
  };

  return (
    <Dialog onClose={close} open={open}>
      <DialogTitle>{confirmText}</DialogTitle>
      <DialogContent>
        <TextField autoFocus value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" fullWidth />
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={close}>
          Cancel
        </Button>
        <Button type="submit" disabled={name.length === 0} onClick={() => confirm()}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
