import { Expense, ListTemplate } from '../../types';
import Money from '../../utils/Money';
import { TemplateComponent } from '../DocumentGenerator';
import { applyTableMetadata, formatCurrency, formatDecimal } from '../templates/formatters';

export type ExpensesGridOptions = {
  currency?: string;
};

export type ExpenseTemplateDataType = ListTemplate<
  Partial<{
    type: string;
    description: string;
    quantity: string;
    unitAmount: string;
    total: string;
  }>
>;

export const ExpensesComponent: TemplateComponent<Expense[], ExpenseTemplateDataType, ExpensesGridOptions> = {
  templatize: (expenses: Expense[], { currency }): ExpenseTemplateDataType => {
    return applyTableMetadata(
      expenses.map((expense) => {
        const unitAmount = expense.currencyAmount ?? expense.unitAmount;

        return {
          type: expense.expenseType?.name,
          description: expense.description ?? '--',
          quantity: formatDecimal(expense.quantity),
          unitAmount: formatCurrency(Money.toDollars(unitAmount), currency),
          total: formatCurrency(Money.toDollars(unitAmount) * expense.quantity, currency),
        };
      })
    );
  },
  definitionMap: {
    type: { label: 'Type', type: 'string' },
    description: { label: 'Description', type: 'string' },
    quantity: { label: 'Quantity', type: 'string' },
    unitAmount: { label: 'Unit Amount', type: 'string' },
    total: { label: 'Total', type: 'string' },
  },
};
