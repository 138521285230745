import { DateTime } from 'luxon';

/**
 * Helpers for interoperating Date objects between client and server
 */

type ParseableDate = DateTime | Date | number | string | null | undefined;

/**
 * Accepts any date type and parses it into a DateTime object.
 */
export function fromUTC(value: ParseableDate): DateTime | null {
  let date: DateTime | null = null;

  if (value instanceof DateTime) {
    date = value.setZone('utc', { keepLocalTime: true }).toLocal();
  } else if (typeof value === 'string') {
    date = DateTime.fromISO(value, {
      zone: 'utc',
    }).toLocal();
  } else if (value instanceof Date) {
    date = DateTime.fromJSDate(value, {
      zone: 'utc',
    }).toLocal();
  } else if (typeof value === 'number' && Number.isInteger(value)) {
    // epoch
    date = DateTime.fromMillis(value, {
      zone: 'utc',
    }).toLocal();
  }

  if (!date?.isValid) {
    return null;
  }

  return date;
}

/**
 * Accepts any date type and parses it into a DateTime object.
 */
export function parseDateTime(value: ParseableDate): DateTime | null {
  let date: DateTime | null = null;

  if (value instanceof DateTime) {
    date = value as DateTime;
  } else if (typeof value === 'string') {
    date = DateTime.fromISO(value);
  } else if (value instanceof Date) {
    date = DateTime.fromJSDate(value);
  }

  if (!date?.isValid) {
    return null;
  }

  return date;
}

/**
 * Accepts any date type and parses it into a DateTime object,
 * The time is zero'd out as to represent a fixed date.
 */
export function parseDate(value: ParseableDate): DateTime | null {
  const dateTime = parseDateTime(value);

  if (!dateTime) {
    return null;
  }

  return dateTime.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}

/**
 * Convert any Date-like object into a UTC DateTime object.
 */
export function asUTC(value: ParseableDate): DateTime | null {
  let date: DateTime | null = null;

  if (value instanceof DateTime) {
    date = value as DateTime;
  } else if (typeof value === 'string') {
    date = DateTime.fromISO(value, {
      zone: 'utc',
    });
  } else if (value instanceof Date) {
    date = DateTime.fromJSDate(value, {
      zone: 'utc',
    });
  } else if (typeof value === 'number' && Number.isInteger(value)) {
    date = DateTime.fromMillis(value, { zone: 'utc' });
  }

  if (!date?.isValid) {
    return null;
  }

  return date;
}

/**
 * Converts a given date into a UTC date, with a zero'd out timestamp.
 *
 * This is used for sending dates to the server, as the server expects
 * all dates to be in UTC.
 */
export function asUTCDate(value: ParseableDate): DateTime | null {
  const date = asUTC(value);

  if (!date) {
    return null;
  }

  return date
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .setZone('utc', { keepLocalTime: true });
}

/**
 * Used for comparing between Date (NOT DATETIME) objects from the DB.
 * DateTime comparisons should be kept to local. Date objects are stored as
 * generic DateTime for now, with zero'd out time.
 *
 * @returns DateTime object set to the current date and time in the UTC timezone.
 */
export function startOfDay() {
  return DateTime.now().startOf('day').setZone('utc', { keepLocalTime: true });
}

export function endOfDay() {
  return DateTime.now().endOf('day').setZone('utc', { keepLocalTime: true });
}

/**
 * Parses and compares two dates, and handles nullish values.
 */
export function dateSort(a: ParseableDate, b: ParseableDate) {
  const dateA = parseDate(a);
  const dateB = parseDate(b);

  if (dateA === null && dateB === null) return 0;

  if (dateA === null) return 1;

  if (dateB === null) return -1;

  return dateA.toMillis() - dateB.toMillis();
}
