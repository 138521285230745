import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';

import Label from 'src/components/atoms/Label';

export default function LabelPreviewRow({ label, index, handleDelete, isEditing, setEditingRow }) {
  const cellStyle = {
    borderBottom: 0,
  };

  return (
    <>
      <TableRow key={label.id}>
        <TableCell sx={cellStyle}>
          <Label {...label} />
        </TableCell>
        <TableCell sx={cellStyle}>
          <Typography fontSize="14px" variant="subtitle2" color="text.secondary" flexGrow={2}>
            {label.description}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle}>
          <Box display="flex" gap={1} justifyContent="flex-end">
            {!isEditing && (
              <Button
                size="small"
                onClick={() => {
                  setEditingRow(index);
                }}
              >
                Edit
              </Button>
            )}
            <Button
              size="small"
              color="error"
              onClick={() => {
                handleDelete(label.id);
              }}
            >
              Delete
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
