import { ThemeProvider } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { LicenseInfo } from '@mui/x-license';
import { Font } from '@react-pdf/renderer';
import { Worker } from '@react-pdf-viewer/core';
import { enableMapSet } from 'immer';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import './api/fragments';
import './lib/grafana';

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web';
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo';

import FatalErrorPage from 'src/pages/FatalErrorPage';
import Routes from 'src/Routes';

import 'material-symbols/outlined.css';
import { AuthProvider, useAuth } from './auth';
import './index.css';
import theme from './lib/theme';
import PrintManagerProvider from './modules/palletTags/providers/PrintManagerProvider';
import AlgoliaContextProvider from './providers/AlgoliaProvider';
import { CubeTokenProvider } from './providers/CubeTokenProvider';
import OrgLocalizationProvider from './providers/OrgLocalizationProvider';

enableMapSet();

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY);

// -- Register fonts for PDF generation
Font.register({
  family: 'Montserrat',
  fonts: [
    // Italic
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 800,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 900,
    },

    // Regular
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf',
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf',
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf',
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf',
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-.ttf',
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf',
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
});
// -- Register fonts for PDF generation

Font.registerHyphenationCallback((word) => [word]);

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <RedwoodApolloProvider useAuth={useAuth}>
              <OrgLocalizationProvider>
                <CubeTokenProvider>
                  <AlgoliaContextProvider>
                    <PrintManagerProvider>
                      {/* TODO: We should host this in our public folder ourselves. I do not trust CDNs after recent events... */}
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Routes />
                      </Worker>
                    </PrintManagerProvider>
                  </AlgoliaContextProvider>
                </CubeTokenProvider>
              </OrgLocalizationProvider>
            </RedwoodApolloProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>
);

export default withLDProvider({
  clientSideID: process.env.LAUNCH_DARKLY_CLIENTSIDE_ID,
  context: {
    kind: 'user',
    anonymous: true,
  },
})(App);
