import { Box } from '@mui/material';

import { Metadata } from '@redwoodjs/web';

import FavoriteButton from 'src/components/atoms/buttons/FavoriteButton';
import NewOrderButton from 'src/components/atoms/buttons/NewOrderButton';
import ExportGridButton from 'src/components/atoms/ExportGridButton';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import { createViewConfigStore, VIEW_PAGES } from 'src/managers/viewConfig.store';
import {
  useCreateGrowerProduct,
  useCreateGrowerProductFromTemplate,
  useCreateGrowerProductTemplate,
} from 'src/modules/orders/growerProducts/api/create';
import GrowerProductsGrid from 'src/modules/orders/growerProducts/GrowerProductsGrid';
import { StoreStateProvider } from 'src/providers/StoreStateProvider';

export default function ViewGrowerProductsPage() {
  const handleCreateNewGrowerProduct = useCreateGrowerProduct();
  const handleCreateNewTemplate = useCreateGrowerProductTemplate();
  const handleCreateFromTemplate = useCreateGrowerProductFromTemplate();

  return (
    <>
      <Metadata title="Grower Products" />
      <PageHeader pageType={PageType.GrowerProducts}>
        <Box sx={{ ml: 'auto', display: 'flex', my: 'auto', gap: 1 }}>
          <FavoriteButton label="Grower Products" />
          <ExportGridButton canPrint={false} />
          <NewOrderButton
            types={['WORK_ORDER']}
            descriptionLabel="Grower Product"
            items={[{ label: 'Grower Product', handleCreateNew: handleCreateNewGrowerProduct }]}
            handleCreateNewTemplate={handleCreateNewTemplate}
            handleCreateFromTemplate={handleCreateFromTemplate}
          />
        </Box>
      </PageHeader>
      <PageBody>
        <StoreStateProvider initializeStore={createViewConfigStore(VIEW_PAGES.GROWER_PRODUCTS)}>
          <GrowerProductsGrid />
        </StoreStateProvider>
      </PageBody>
    </>
  );
}
