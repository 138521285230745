import { useMemo, useState } from 'react';

import { Button, Dialog, Radio } from '@mui/material';

import { GridColObj } from 'src/components/containers/grids/DataGrid';
import StyledDataGrid from 'src/components/containers/grids/StyledDataGrid';
import useBulkAddPricingSheetEntry from 'src/modules/systemManagement/pricingSheets/hooks/useBulkAddPricingSheetEntry';
import usePricingSheets from 'src/modules/systemManagement/pricingSheets/hooks/usePricingSheets';
import { dateCellFormatter } from 'src/utils/grid/cell/cellFormatters';

type ImportProductsProps = {
  pricingSheetId: number;
  close: () => void;
};

export default function ImportProducts({ pricingSheetId, close }: ImportProductsProps) {
  const pricingSheets = usePricingSheets(true);
  const [selectedSheet, setSelectedSheet] = useState<number | null>(null);
  const bulkAddPricingSheetEntry = useBulkAddPricingSheetEntry();

  const rows = useMemo(
    () => pricingSheets.filter((sheet) => !sheet.draft && sheet.id !== pricingSheetId),
    [pricingSheetId, pricingSheets]
  );

  const columns: GridColObj = useMemo(
    () => ({
      radio: {
        width: 40,
        headerName: '',
        renderCell: (row) => (
          <Radio checked={row.id === selectedSheet} onChange={() => setSelectedSheet(row.id)} value={row.id} />
        ),
      },
      name: { headerName: 'Name', flex: 1 },
      numProducts: {
        headerName: 'Number of products',
        flex: 0.75,
        valueGetter: (_value, row) => row.entries.length,
      },
      updatedAt: { headerName: 'Last Updated', flex: 0.75, valueFormatter: dateCellFormatter },
    }),
    [selectedSheet]
  );

  const importProducts = async () => {
    const sheet = pricingSheets.find((sheet) => sheet.id === selectedSheet);
    setSelectedSheet(null);
    await bulkAddPricingSheetEntry({
      pricingSheetId: pricingSheetId,
      productIds: sheet.entries?.map((e) => e.productId) ?? [],
    });
    close();
  };

  return (
    <Dialog open onClose={close} fullWidth>
      <div className="flex flex-col p-6 gap-4">
        <b className="text-lg">Import pricing sheet entries</b>
        <p>Select which pricing sheet to import a product list from.</p>
        <StyledDataGrid rows={rows} columns={columns} />
        <footer className="flex gap-2 justify-end mt-4 -mb-2 -mr-2">
          <Button onClick={close}>Cancel</Button>
          <Button variant="contained" disabled={!selectedSheet} onClick={importProducts}>
            Import
          </Button>
        </footer>
      </div>
    </Dialog>
  );
}
