import { ReactNode } from 'react';

import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';
import { Box } from '@mui/system';

import Indicator from './Indicator';

export type TabProps = MuiTabProps & {
  label: ReactNode;
  icon?: ReactNode;
  indicatorValue?: ReactNode;
};

// text.secondary if not selected

export default function Tab({ label, icon, indicatorValue, ...props }: TabProps) {
  return (
    <MuiTab
      {...props}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Indicator
            value={indicatorValue}
            sx={{
              right: 0,
              top: 5,
              backgroundColor: 'info.main',
            }}
            animate
          />
          {icon} {label}
        </Box>
      }
    />
  );
}
