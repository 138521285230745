import { useQuery } from '@redwoodjs/web';

import { PricingSheetMeta } from '../constants';

export const GET_PRICING_SHEET = gql`
  query GetPricingSheet($id: Int!) {
    pricingSheet(id: $id) {
      id
      draft
      enabled
      name
      customerGroups {
        id
        name
      }
      individualCustomers {
        id
        name
      }
      startDate
      expiryDate
      pricingFormula
      pricingValue
      roundingValue
    }
  }
`;

export default function usePricingSheet(id?: number): PricingSheetMeta {
  const { data } = useQuery(GET_PRICING_SHEET, { variables: { id }, skip: !id });
  if (!data?.pricingSheet) return;
  return {
    ...data.pricingSheet,
    startDate: data.pricingSheet.startDate ? new Date(data.pricingSheet.startDate) : undefined,
    expiryDate: data.pricingSheet.expiryDate ? new Date(data.pricingSheet.expiryDate) : undefined,
  };
}
