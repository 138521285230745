import { UpdatePricingSheetEntryInput } from 'types/graphql';

import { useMutation } from '@redwoodjs/web';

import { GET_PRICING_SHEET_ENTRIES } from './usePricingSheetEntries';

const EDIT_PRICING_SHEET_ENTRY = gql`
  mutation EditPricingSheetEntry($id: Int!, $input: UpdatePricingSheetEntryInput!) {
    updatePricingSheetEntry(id: $id, input: $input) {
      id
    }
  }
`;

export default function useEditPricingSheetEntry() {
  const [editPricingSheetEntry] = useMutation(EDIT_PRICING_SHEET_ENTRY, {
    refetchQueries: [GET_PRICING_SHEET_ENTRIES],
  });

  return async (id: number, input: UpdatePricingSheetEntryInput) => editPricingSheetEntry({ variables: { id, input } });
}
