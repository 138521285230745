import { MutableRefObject } from 'react';

import { Editor } from 'grapesjs';

// letter size as per ReactPDF:
// Source https://github.com/diegomura/react-pdf/blob/3f5bf67d84ee784b6685cf603f04ac5f568a146d/packages/layout/src/page/getSize.js#L10

// LETTER document in pixels
export const PAGE_WIDTH = 612;
export const PAGE_HEIGHT = 792;

export const NEW_PAGE = `<section data-gjs-type="page" style="font-family: Montserrat; padding-bottom: 20px; width: ${PAGE_WIDTH}px; height: ${PAGE_HEIGHT}px"></section>`;

export const NEW_DOCUMENT = {
  pages: [
    {
      name: 'New Page',
      component: NEW_PAGE,
    },
  ],
};

export default function createDocumentStoragePlugin({
  saveRemote,
  loadRemote,
  documentConfig,
  setStorageSaveModalOpen,
  setStorageLoadModalOpen,
}: {
  saveRemote: (
    saveState,
    pages,
    metadata: {
      name: string;
      entity: string;
      type: string;
      generationOptions: any;
    }
  ) => Promise<{ id: number }>;
  loadRemote: () => Promise<any>;
  documentConfig: MutableRefObject<{
    name: string;
    entity: string;
    type: string;
  }>;
  setStorageSaveModalOpen: (open: boolean) => void;
  setStorageLoadModalOpen: (open: boolean) => void;
}) {
  return (editor: Editor) => {
    const commands = editor.Commands;

    commands.add('save-changes', {
      run: () => {
        setStorageSaveModalOpen(true);
      },
    });

    commands.add('open-document', {
      run: () => {
        setStorageLoadModalOpen(true);
      },
    });

    commands.add('reload-changes', {
      run: () => {
        editor.load({
          remote: true,
        });
      },
    });

    editor.StorageManager.add<{
      remote: boolean;
      documentName: string;
      documentType: {
        entity: string;
        type: string;
      };
      generationOptions: any;
    }>('hybrid', {
      async load({ remote }) {
        if (!remote) {
          const dataString = localStorage.getItem(`temp-store-${documentConfig.current.name}`);

          if (dataString) {
            return JSON.parse(dataString);
          }
        }

        const remoteData = await loadRemote();

        if (!remoteData) {
          return NEW_DOCUMENT;
        }

        return remoteData;
      },
      store(data, { remote, documentName, documentType, generationOptions }) {
        console.debug('Storing data', data);

        if (!remote) {
          localStorage.setItem(`temp-store-${documentName}`, JSON.stringify(data, null, 2));

          return Promise.resolve();
        }

        const pages = editor.Pages.getAll().map((page) => {
          const component = page.getMainComponent();
          return {
            html: editor.getHtml({ component }),
            css: editor.getCss({ component }),
          };
        });

        return saveRemote(data, pages, {
          name: documentName,
          entity: documentType.entity,
          type: documentType.type,
          generationOptions,
        });
      },
    });
  };
}
