import StarIcon from '@mui/icons-material/Star';
import { Box, Typography } from '@mui/material';

import { useSidebar } from 'src/providers/SidebarContextProvider';

import SidebarLink from './SidebarLink';

export default function RouteFavorites() {
  const { favorites, sidebarOpen } = useSidebar();
  return (
    favorites.length > 0 && (
      <Box sx={{ pb: 2 }}>
        <Box sx={{ p: 3, py: 2, height: '56px' }}>
          {sidebarOpen && <Typography variant="body1">Starred Pages</Typography>}
        </Box>

        {favorites.map(({ route, label }) => (
          <SidebarLink key={label} text={label} icon={StarIcon} to={route} />
        ))}
      </Box>
    )
  );
}
