import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { SalesOrderLineItemWithProportionOfSale } from 'types/graphql';

import { USD } from 'src/lib/money/usd';
import computeDescription from 'src/services/products/computed/computeDescription';
import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';

import { ConsignmentPayableHeader, Expenses, Footer, formatDate } from '../sections';
import Table from '../sections/Table';
import { formatCurrency } from '../utils/formatters';
import money from '../utils/money';
import styles, { docStyles } from '../utils/styles';

interface GrowerSettlementTemplateProps {
  consignmentPayable: Prisma.ConsignmentPayableGetPayload<{
    include: {
      organization: { include: { places: true } };
      workOrder: {
        include: {
          grower: { include: { places: true } };
          growerContact: true;
          shipment: true;
        };
      };
      salesOrderLineItems: {
        where: { deletedAt: null };
        include: {
          salesOrder: {
            include: {
              expenses: {
                where: { accountLevel: 'GROWER_PAYABLE'; deletedAt: null };
              };
              shipment: true;
            };
          };
          lot: {
            include: {
              product: {
                include: {
                  commodity: true;
                  commoditySize: true;
                  commodityStyle: true;
                  commodityUnit: true;
                };
              };
            };
          };
        };
      };
    };
  }>;
  salesOrderLineItemsWithProportionOfSale: SalesOrderLineItemWithProportionOfSale[];
}
export function GrowerSettlementTemplate({
  consignmentPayable,
  salesOrderLineItemsWithProportionOfSale,
  // Due to linking errors, we need to pass in the shared Product here
  // TODO: Fix linking errors (TAS-699)
  Product,
  currency = 'USD',
}: GrowerSettlementTemplateProps & {
  Product;
  currency?: string;
}) {
  const deductions = consignmentPayable.deductions; // TODO: Add type.
  const settlementBasis = consignmentPayable.workOrder.settlementBasis;

  const totalDeduction = deductions?.reduce((total, deduction) => {
    const { settlementAmount, totalAmount } = deduction;

    return total + (settlementAmount ?? totalAmount);
  }, 0);

  // Total sales
  const totalSales = salesOrderLineItemsWithProportionOfSale.reduce((total, { lineItem, proportionOfSale }) => {
    const { settlementPrice, settlementPriceWeight, unitsPicked } = lineItem;

    const price = (settlementBasis === 'WEIGHT' ? settlementPriceWeight : settlementPrice) ?? 0;
    const units = unitsPicked ?? 0;

    let settlementFactor = units;

    if (settlementBasis === 'WEIGHT') {
      const weight = Product.Weight.of(lineItem.lot.product)
        .withLot(lineItem.lot)
        .withLineItemPalletTags(lineItem.parentLineItem?.palletTagsOnLineItem)
        .forQuantity(units);

      settlementFactor = weight;
    }

    const totalRevenue = price * settlementFactor;

    return total + totalRevenue * proportionOfSale;
  }, 0);

  const columns = [
    { field: 'shipDate', headerName: 'Ship Date', sx: { minWidth: 80 } },
    { field: 'salesOrder', headerName: 'Order', sx: { minWidth: 90 } },
    {
      field: 'product',
      headerName: 'Product',
      sx: { minWidth: 150 },
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      sx: { minWidth: 40, align: 'right' },
      formatter: (value) => value?.toFixed(2),
    },
    {
      field: 'weight',
      headerName: 'Weight',
      sx: { minWidth: 50, align: 'right' },
      formatter: (value) => value?.toFixed(2),
    },
    {
      field: 'salesPrice',
      headerName: 'Price',
      sx: {
        minWidth: 80,
        align: 'right',
      },
      formatter: (value) => formatCurrency(money.toDollars(value), 'USD'),
    },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      sx: {
        minWidth: 80,
        align: 'right',
      },
      formatter: (value) => formatCurrency(money.toDollars(value), 'USD'),
    },
  ];

  const rows = salesOrderLineItemsWithProportionOfSale.map(({ lineItem, proportionOfSale }) => {
    const price = (settlementBasis === 'WEIGHT' ? lineItem.settlementPriceWeight : lineItem.settlementPrice) ?? 0;
    const units = lineItem.unitsPicked ?? 0;

    const weight = Product.Weight.of(lineItem.lot.product)
      .withLot(lineItem.lot)
      .withLineItemPalletTags(lineItem.parentLineItem?.palletTagsOnLineItem)
      .forQuantity(units);

    const settlementFactor = settlementBasis === 'WEIGHT' ? weight : units;

    const date = lineItem.salesOrder?.shipment?.shipDate ?? lineItem.salesOrder?.shipDate;

    return {
      id: lineItem.id,
      shipDate: formatDate(date),
      weight,
      salesOrder: `SO #${lineItem.salesOrder?.slug ?? '--'}`,
      product: computeDescription(lineItem.lot.product, consignmentPayable.organization.productDescriptionTemplate),
      quantity: lineItem.unitsPicked ?? 0,
      salesPrice: price,
      subtotal: price * settlementFactor * proportionOfSale,
    };
  });

  const aggregation = {
    subtotal: (rows) =>
      rows.reduce((total, row) => {
        return total + row.subtotal;
      }, 0),
    weight: (rows) =>
      rows.reduce((total, row) => {
        return total + row.weight;
      }, 0),
    quantity: (rows) =>
      rows.reduce((total, row) => {
        return total + row.quantity;
      }, 0),
  };

  const terms = consignmentPayable.workOrder.productType;

  // Commisison
  const commissionRate = consignmentPayable.commissionRate;
  const commissionAmount = consignmentPayable.commissionAmount;

  const commission = commissionAmount !== null ? commissionAmount : totalSales * commissionRate;

  const totalReturn = totalSales - totalDeduction - commission;

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <ConsignmentPayableHeader consignmentPayable={consignmentPayable} />

        {/* SALES ORDERS  */}
        <View style={{ ...styles.commoditiesAndExpensesWrapper }}>
          <View style={docStyles.sectionContainer} wrap={false}>
            <Table columns={columns} rows={rows} aggregation={aggregation} />
          </View>

          {/* GROWER PAYABLE EXPENSES */}
          {deductions?.length && <Expenses expenses={deductions} headerText="DEDUCTIONS" />}
          {/* NET RETURNS */}
          <View wrap={false} style={docStyles.sectionContainer}>
            <View style={styles.row}>
              <View style={styles.table}>
                <View style={styles.tableHeader}>
                  <View style={styles.tableRowHeaderFull}>
                    <View style={styles.tableColLarge}>
                      <Text style={styles.tableCell}>NET RETURNS</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumber}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumber}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRowWrapper}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableColLarge}>
                      <Text style={styles.tableCell}>Net Sales</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumber}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumber}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                  </View>
                  <View style={styles.tableRowSubtotal}>
                    <View style={styles.tableColSubtotal}>
                      <Text style={styles.tableCell}>{USD.fromCents(totalSales).string}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRowWrapper}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableColLarge}>
                      <Text style={styles.tableCell}>Net Expenses</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumber}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumber}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                  </View>
                  <View style={styles.tableRowSubtotal}>
                    <View style={styles.tableColSubtotal}>
                      <Text style={styles.tableCell}>{`- ${USD.fromCents(totalDeduction).string}`}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRowWrapper}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableColLarge}>
                      <Text style={styles.tableCell}>Commission</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumber}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumber}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                  </View>
                  <View style={styles.tableRowSubtotal}>
                    <View style={styles.tableColSubtotal}>
                      <Text style={styles.tableCell}>{`- ${USD.fromCents(commission).string}`}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRowWrapper} wrap={false}>
                  <View style={styles.tableRowBottomTotalsFull}>
                    <View style={styles.tableColBottomTotals}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColBottomTotals}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumberBottomTotals}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableColNumberBottomTotals}>
                      <Text style={styles.tableCell}>NET RETURN</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColNumberBottomTotals,
                        paddingRight: 0,
                      }}
                    >
                      <Text style={styles.tableCell}>{USD.fromCents(totalReturn).string}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <Footer />
      </Page>
    </Document>
  );
}
