import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { Configure, Index, InstantSearch, useHits } from 'react-instantsearch-hooks-web';

import { SearchIndex, useAlgolia } from './AlgoliaProvider';

export type MatchHit = {
  matchLevel: string;
  value: string;
};

export type Hits = {
  _highlightResult: Record<string, MatchHit | Record<string, MatchHit>>;
};
export type MultiHit = Record<string, Hits[]>;

export type MultiIndexSearchProps = {
  render: (hits: MultiHit) => JSX.Element;
  indexes: { index: SearchIndex; filters?: string }[];
  query: string;
};

/**
 * To search multiple indexes, we must use the Index component, within an InstantSearch context.
 * This is a problem, since we want access to all hits at once.
 * This component listens to the postHits event, and passes the hits to the collect function.
 */
export default function MultiIndexSearch({ render, indexes, query }: MultiIndexSearchProps) {
  const [hits, setHits] = useState<MultiHit>({});
  const algolia = useAlgolia();

  const collect = useCallback((index: string, hits: Hits[]) => {
    setHits((prev) => ({ ...prev, [index]: hits }));
  }, []);

  const collectors = useMemo(() => {
    return indexes.map(({ index, filters }) => (
      <Index indexName={index} key={index}>
        <Configure query={query} filters={filters} />
        <HitCollector indexName={index} collect={collect} />
      </Index>
    ));
  }, [indexes, query, collect]);

  const rendered = useMemo(() => {
    return render(hits);
  }, [hits, render]);

  return (
    <InstantSearch searchClient={algolia} indexName={indexes[0].index}>
      {collectors}
      {rendered}
    </InstantSearch>
  );
}

const HitCollector = ({
  collect,
  indexName: indexName,
}: {
  indexName: string;
  collect: (index: string, hits: Record<string, unknown>[]) => void;
}) => {
  const { hits, results } = useHits();

  useEffect(() => {
    collect(indexName, hits);
  }, [hits, indexName, collect, results]);

  return <Fragment />;
};
