import { useMemo, useState } from 'react';

import { Box, FormControl, TextField, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';
import { Configure, Index, InstantSearch } from 'react-instantsearch-hooks-web';
import { SalesTerms, ShippingTerms } from 'shared/constants';
import { SalesTermsMapper } from 'shared/mappers';
import { startOfDay } from 'shared/utils/DateTime';
import { SalesOrderValidator } from 'shared/validators';
import { FieldEditability } from 'shared/validators/SalesOrder';
import type { SalesTerms as SalesTermsType } from 'types/graphql';
import { useEffectOnce } from 'usehooks-ts';

import { useMutation, useQuery } from '@redwoodjs/web';

import { SalesOrdersApi } from 'src/api';
import { useAuth } from 'src/auth';
import { AddressAutocomplete } from 'src/components';
import Autocomplete from 'src/components/atoms/Autocomplete';
import CheckMount from 'src/components/atoms/CheckMount';
import { CurrencyInput } from 'src/components/atoms/form/CurrencyInput';
import QuickDatePicker from 'src/components/atoms/QuickDatePicker';
import SearchAutocomplete from 'src/components/atoms/SearchAutocomplete';
import FormGrid from 'src/components/containers/FormGrid';
import SkeletonEntryForm from 'src/components/containers/skeleton/SkeletonEntryForm';
import { useDebounce } from 'src/hooks/useDebounce';
import { DEBOUNCE_DELAY_HIGH } from 'src/lib/constants';
import { SearchIndex, useAlgolia } from 'src/providers/AlgoliaProvider';
import getFullName from 'src/utils/getFullName';
import { transformBusinessEntity } from 'src/utils/transformBusinessEntity';

import CustomFieldsForm from './CustomFieldsForm';

const FIND_SALES_ORDER_CUSTOMER_QUERY = gql`
  query FindSalesOrderCustomer($id: Int!) {
    businessEntity(id: $id) {
      id
      name
      lastSalesOrder {
        shipment {
          placeId
          originId
        }
        billTo {
          ...PlaceAttributes
        }
        salesTerms
      }
      places {
        ...PlaceAttributes
      }
    }
  }
`;

export const UPDATE_SHIPMENT = gql`
  mutation UpdateShipment($id: Int!, $input: UpdateShipmentInput!) {
    updateShipment(id: $id, input: $input) {
      id
    }
  }
`;

export const QUERY = gql`
  query FindSalesOrderCustomerEntryFormQuery($id: Int!) {
    salesOrderCustomerEntryForm: salesOrder(id: $id) {
      id
      slug
      organizationId
      createdAt
      customerPo
      deliveryDate
      shipDate
      submittedAt
      salesTerms
      salesPerson {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }

      billTo {
        ...PlaceAttributes
      }

      businessEntity {
        id
        name
        places {
          ...PlaceAttributes
        }
      }
      businessEntityContact {
        id
        name
        email
      }

      shipment {
        id
        terms
        place {
          ...PlaceAttributes
        }

        origin {
          ...PlaceAttributes
        }

        temperatureMin
        temperatureMax
      }

      subsidiary {
        ...PlaceAttributes
      }
    }

    orgLocations: places(businessEntityId: null, addressRoles: [WAREHOUSE, SUBSIDIARY]) {
      ...PlaceAttributes
    }
  }
`;

export type SalesOrderCustomerEntryFormProps = {
  entryForm: any;
  status: any;
  isTemplate?: boolean;
  memberships: any;
  onCustomerChange?: () => void;
  orgLocations: any;
  fieldEditability?: FieldEditability;

  customer: any;
  setCustomer?: (customer: any) => void;

  shippingOrigin: any;
  setShippingOrigin?: (shippingOrigin: any) => void;

  shippingDestination: any;
  setShippingDestination?: (shippingDestination: any) => void;

  shippingTerms: any;
  setShippingTerms?: (shippingTerms: any) => void;

  currency: any;
  setCurrency?: (currency: any) => void;

  exchangeRate: any;
  setExchangeRate?: (exchangeRate: any) => void;

  freightCompany: any;
  setFreightCompany?: (freightCompany: any) => void;

  isExternal?: boolean;
};

export const SalesOrderCustomerEntryForm = ({
  entryForm,
  status,
  isTemplate,
  memberships,
  onCustomerChange,
  orgLocations,
  fieldEditability,

  customer,
  setCustomer,

  shippingOrigin,
  setShippingOrigin,

  shippingDestination,
  setShippingDestination,

  shippingTerms,
  setShippingTerms,

  currency,
  setCurrency,

  exchangeRate,
  setExchangeRate,

  freightCompany,
  setFreightCompany,

  isExternal = false,
}: SalesOrderCustomerEntryFormProps) => {
  const algolia = useAlgolia();
  const shouldDatesBeVisible = !(isTemplate ?? false);
  const flags = useFlags();

  // TODO: Remove instant-hooks dependency, so we have better control over rendering
  const [isAlgoliaMounted, setIsAlgoliaMounted] = useState(false);
  const handleMount = () => {
    setIsAlgoliaMounted(true);
  };

  // Queries and Mutations
  const { refetch: findCustomer } = useQuery(FIND_SALES_ORDER_CUSTOMER_QUERY, {
    skip: true,
  });

  const [updateSalesOrder] = useMutation(SalesOrdersApi.UpdateOrder);

  const [updateShipment] = useMutation(UPDATE_SHIPMENT);

  const [pricingSheetId, setPricingSheetId] = React.useState(entryForm.pricingSheet?.id);
  const [shipDate, setShipDate] = React.useState(entryForm.shipDate);
  const [deliveryDate, setDeliveryDate] = React.useState(entryForm.deliveryDate);
  const [orderDate, setOrderDate] = React.useState(entryForm.submittedAt);
  const [salesTerms, setSalesTerms] = React.useState<SalesTermsType>(entryForm.salesTerms);
  const [customerPo, setCustomerPo] = React.useState(entryForm.customerPo || '');
  const [temperatureMin, setTemperatureMin] = useState(entryForm.shipment?.temperatureMin ?? 0);
  const [temperatureMax, setTemperatureMax] = useState(entryForm.shipment?.temperatureMax ?? 0);
  const [billTo, setBillTo] = React.useState(entryForm.billTo);

  const [customFieldValues, setCustomFieldValues] = React.useState(entryForm.customFields ?? {});

  const [shipmentCustomFieldValues, setShipmentCustomFieldValues] = useState(entryForm.shipment?.customFields ?? {});

  // Save the default sales terms if not already set
  useEffectOnce(() => {
    if (salesTerms || isTemplate) {
      return;
    }

    setSalesTerms(SalesTerms.DEFAULT);

    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: { salesTerms: SalesTerms.DEFAULT },
      },
    });
  });

  fieldEditability =
    fieldEditability ?? SalesOrderValidator.getFieldEditability(status, !isNil(entryForm.ediPendingUpdatesCount));

  function handleChangeSalesTerms(salesTerms: SalesTermsType) {
    if (!fieldEditability['salesTerms']) {
      return;
    }

    setSalesTerms(salesTerms);
    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: { salesTerms },
      },
    });
  }

  const debouncedHandleUpdate = useDebounce((input) => {
    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input,
      },
    });
  }, DEBOUNCE_DELAY_HIGH);

  const debouncedHandleUpdateShipment = useDebounce((input) => {
    updateShipment({
      variables: {
        id: entryForm.shipment.id,
        input,
      },
    });
  }, DEBOUNCE_DELAY_HIGH);

  // We are editing the customerPo as the user types. We might want to debounce this to prevent
  // unnecessary updates.
  // And, we might want to disable editing entirely when the PO hits a certain state.
  function handleUpdateCustomerPo(e) {
    if (!fieldEditability['customerPO']) {
      return;
    }

    debouncedHandleUpdate({ customerPo: e.target.value });
    setCustomerPo(e.target.value);
  }

  function handleUpdateShipDate(value) {
    if (!fieldEditability['shipDate']) {
      return;
    }

    setShipDate(value);
    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: { shipDate: value },
      },
    });
  }

  function handleUpdateDeliveryDate(value) {
    if (!fieldEditability['deliveryDate']) {
      return;
    }

    setDeliveryDate(value);
    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: { deliveryDate: value },
      },
    });
  }

  function handleUpdateTempMin(e) {
    if (!fieldEditability['shipment']) {
      return;
    }

    const value = Number(e.target.value);

    setTemperatureMin(value);
    updateShipment({
      variables: {
        id: entryForm.shipment.id,
        input: { temperatureMin: value },
      },
    });
  }

  function handleUpdateTempMax(e) {
    if (!fieldEditability['shipment']) {
      return;
    }

    const value = Number(e.target.value);

    setTemperatureMax(value);
    updateShipment({
      variables: {
        id: entryForm.shipment.id,
        input: { temperatureMax: value },
      },
    });
  }

  function handleUpdateOrderAt(value) {
    if (!fieldEditability['orderDate']) {
      return;
    }

    setOrderDate(value);
    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: { submittedAt: value },
      },
    });
  }

  const warehouses = useMemo(
    () => orgLocations.filter((place) => place.addressRoles.includes('WAREHOUSE')),
    [orgLocations]
  );

  function handleUpdateCustomer(customer) {
    if (!fieldEditability['customer']) {
      return;
    }

    // set customer to search result
    setCustomer(customer);

    if (!customer) {
      setShippingDestination(null);
      setBillTo(null);

      updateSalesOrder({
        variables: {
          id: entryForm.id,
          input: {
            businessEntityId: null,
            billToId: null,
          },
        },
      }).then(() => {
        onCustomerChange();
      });

      updateShipment({
        variables: {
          id: entryForm.shipment.id,
          input: {
            placeId: null,
          },
        },
      });

      return;
    }

    findCustomer({
      id: customer.id,
    }).then((result) => {
      const { businessEntity: customer } = result.data;

      // populate business entity with db data
      setCustomer(customer);

      const places = customer.places;
      const lastDestination = places.find((place) => place.id === customer.lastSalesOrder?.shipment?.placeId);
      const onlyDestination = places.length === 1 && places[0];
      const autoFillDestination = lastDestination || onlyDestination || null;

      // Use this vendors last place or if there is only one place, use that.
      setShippingDestination(autoFillDestination);

      const lastWarehouse = warehouses.find((place) => place.id === customer.lastSalesOrder?.shipment?.originId);
      const onlyWarehouse = warehouses.length === 1 && warehouses[0];
      const autoFillWarehouse = lastWarehouse || onlyWarehouse || null;

      const updatedWarehouse = shippingOrigin ?? autoFillWarehouse;

      // only use previous warehouse if it's not already set
      setShippingOrigin(updatedWarehouse);

      // If there are no sales terms set, use the last vendor's purchase order's sales terms.
      const autoFillSalesTerms = salesTerms ?? customer.lastSalesOrder?.salesTerms;

      setSalesTerms(autoFillSalesTerms);

      const lastBillTo = customer.lastSalesOrder?.billTo;
      const updatedBillTo = !billTo ? lastBillTo : billTo;

      setBillTo(updatedBillTo);

      updateSalesOrder({
        variables: {
          id: entryForm.id,
          input: {
            businessEntityId: customer.id,
            salesTerms: autoFillSalesTerms,
            billToId: updatedBillTo?.id,
          },
        },
      }).then(() => {
        onCustomerChange();
      });

      updateShipment({
        variables: {
          id: entryForm.shipment.id,
          input: {
            placeId: autoFillDestination?.id,
            originId: updatedWarehouse?.id,
          },
        },
      });
    });
  }

  // Update the Sales Order's Shipment
  function handleChangeShippingTerms(terms) {
    if (!fieldEditability['shippingTerms']) {
      return;
    }

    setShippingTerms(terms);
    const input = { terms } as { terms: string };

    updateShipment({
      variables: {
        id: entryForm.shipment.id,
        input,
      },
    });
  }

  function handleChangeShippingPlace(value: typeof shippingDestination) {
    if (!fieldEditability['shipTo']) {
      return;
    }

    setShippingDestination(value);

    updateShipment({
      variables: {
        id: entryForm.shipment.id,
        input: { placeId: value?.id ?? null },
      },
    });
  }

  function handleChangeShippingOrigin(value: typeof shippingOrigin) {
    if (!fieldEditability['shipFrom']) {
      return;
    }

    setShippingOrigin(value);

    updateShipment({
      variables: {
        id: entryForm.shipment.id,
        input: { originId: value?.id ?? null },
      },
    }).then(() => {
      onCustomerChange();
    });
  }

  function handleChangeBillTo(value: typeof billTo) {
    if (!fieldEditability['billTo']) {
      return;
    }

    setBillTo(value);

    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: { billToId: value?.id ?? null },
      },
    });
  }

  function handleChangeCurrency(currencyCode: string) {
    if (!fieldEditability['currency']) {
      return;
    }

    setCurrency(currencyCode);

    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: {
          currencyCode: currencyCode,
        },
      },
    });
  }

  function handleChangeExchangeRate(exchangeRate: number) {
    if (!fieldEditability['currency']) {
      return;
    }

    setExchangeRate(exchangeRate);
    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: {
          exchangeRate: exchangeRate,
        },
      },
    });
  }

  const showCurrencySelect = flags.multipleCurrencies;

  const [subsidiary, setSubsidiary] = useState(entryForm.subsidiary);

  function handleChangeSubsidiary(subsidiary) {
    if (!fieldEditability['subsidiary']) {
      return;
    }

    setSubsidiary(subsidiary);
    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: {
          subsidiaryId: subsidiary?.id ?? null,
        },
      },
    });
  }

  const { currentUser } = useAuth();

  const [salesPerson, setSalesPerson] = React.useState(entryForm.salesPerson);

  function handleChangeSalesPerson(value: typeof salesPerson) {
    setSalesPerson(value);
    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: {
          salesPersonId: value?.id ?? null,
        },
      },
    });
  }

  useEffectOnce(() => {
    if (salesPerson || isTemplate) {
      return;
    }

    const member = memberships.find((membership) => membership.user.id === currentUser?.userId);

    if (member) {
      handleChangeSalesPerson(member);
    }
  });

  useEffectOnce(() => {
    if (orderDate || isTemplate) {
      return;
    }

    const newOrderDate = startOfDay().toISO();

    updateSalesOrder({
      variables: {
        id: entryForm.id,
        input: {
          submittedAt: newOrderDate,
        },
      },
    });

    setOrderDate(newOrderDate);
  });

  const handleUpdateCustomFields = (field, value) => {
    setCustomFieldValues((values) => ({
      ...values,
      [field.key]: value,
    }));

    debouncedHandleUpdate({
      customFields: {
        ...customFieldValues,
        [field.key]: value,
      },
    });
  };

  const handleUpdateShipmentCustomFields = (field, value) => {
    setShipmentCustomFieldValues((values) => ({
      ...values,
      [field.key]: value,
    }));

    debouncedHandleUpdateShipment({
      customFields: {
        ...shipmentCustomFieldValues,
        [field.key]: value,
      },
    });
  };

  const showSubsidiarySelect = flags.multipleSubsidiaries;

  return (
    <>
      {!isAlgoliaMounted && <SkeletonEntryForm />}
      <InstantSearch searchClient={algolia} indexName={SearchIndex.BusinessEntities}>
        <CheckMount onMount={handleMount} />
        {isAlgoliaMounted && (
          <FormGrid>
            <FormControl>
              {/* TODO: Pull Algolia filter set up in the `shared` directory */}
              <Configure filters="(areRolesEmpty:true OR roles:CUSTOMER) AND disabled:false" />
              <SearchAutocomplete<{
                name: string;
                id: number;
              }>
                value={customer}
                label="Customer"
                disabled={!fieldEditability['customer']}
                onChange={(_, value) => handleUpdateCustomer(value)}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.businessEntityId === value.businessEntityId}
                transform={transformBusinessEntity}
              />
            </FormControl>

            <FormControl>
              <AddressAutocomplete
                selectedAddress={shippingDestination}
                fieldLabel="Ship To"
                addressBook={
                  customer?.places?.filter(
                    (place) => place.addressRoles.includes('SHIPPING') || place.addressRoles.length === 0
                  ) ?? []
                }
                businessEntityId={customer?.id}
                disabled={!customer || !fieldEditability['shipTo']}
                onChange={(value) => handleChangeShippingPlace(value)}
              />
            </FormControl>

            <FormControl>
              <AddressAutocomplete
                selectedAddress={isExternal ? { id: -1, description: 'External' } : shippingOrigin}
                fieldLabel="Ship From"
                addressBook={warehouses}
                disabled={!fieldEditability['shipFrom'] || isExternal}
                onChange={(value) => handleChangeShippingOrigin(value)}
              />
            </FormControl>

            {shouldDatesBeVisible && (
              <QuickDatePicker
                label="Order Date"
                value={orderDate}
                disabled={!fieldEditability['orderDate']}
                onChange={(data) => {
                  handleUpdateOrderAt(data);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            )}

            <FormControl>
              <TextField
                label="Customer PO#"
                value={customerPo}
                onChange={handleUpdateCustomerPo}
                disabled={!fieldEditability['customerPO']}
              />
            </FormControl>

            <FormControl>
              <AddressAutocomplete
                selectedAddress={billTo}
                fieldLabel="Bill To"
                addressBook={
                  customer?.places?.filter(
                    (place) => place.addressRoles.includes('BILLING') || place.addressRoles.length === 0
                  ) ?? []
                }
                businessEntityId={customer?.id}
                disabled={!customer || !fieldEditability['billTo']}
                onChange={(value) => handleChangeBillTo(value)}
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                value={salesTerms}
                label="Sales Terms"
                disabled={!fieldEditability['salesTerms']}
                disableClearable
                options={SalesTerms.CODES}
                getOptionLabel={SalesTermsMapper.getLabel}
                onChange={(_, value) => handleChangeSalesTerms(value)}
              />
            </FormControl>
            {showCurrencySelect && (
              <FormControl>
                <CurrencyInput
                  currencyValue={currency}
                  exchangeRateValue={exchangeRate}
                  disabled={!fieldEditability['currency']}
                  onChangeCurrency={handleChangeCurrency}
                  onChangeExchangeRate={handleChangeExchangeRate}
                />
              </FormControl>
            )}
            <FormControl>
              <Autocomplete
                value={salesPerson}
                disabled={!fieldEditability['salesPerson']}
                label="Sales Person"
                getOptionLabel={(option) => getFullName(option.user)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => handleChangeSalesPerson(value)}
                options={memberships}
              />
            </FormControl>

            {showSubsidiarySelect && (
              <FormControl>
                <AddressAutocomplete
                  selectedAddress={subsidiary}
                  fieldLabel="Subsidiary"
                  addressBook={orgLocations.filter((place) => {
                    return place.addressRoles.includes('SUBSIDIARY');
                  })}
                  disabled={!fieldEditability['subsidiary']}
                  onChange={(value) => handleChangeSubsidiary(value)}
                />
              </FormControl>
            )}
            <CustomFieldsForm
              customFieldValues={customFieldValues}
              customFieldsKey="salesOrder"
              handleUpdateCustomFields={handleUpdateCustomFields}
              filterFields={(field) => !field.section}
            />
          </FormGrid>
        )}
        <Box my={2}>
          <Typography sx={{ pl: 1, pb: 2 }} variant="h6">
            Shipping
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <FormControl sx={{ width: '15%' }}>
              <Autocomplete
                value={shippingTerms}
                label="Shipping"
                disabled={!fieldEditability['shippingTerms']}
                getOptionLabel={(option) => option}
                onChange={(_, value) => handleChangeShippingTerms(value)}
                options={ShippingTerms.CODES}
              />
            </FormControl>
            <FormControl sx={{ width: '40%' }}>
              <FormControl
                sx={{
                  flex: 1,
                }}
              >
                <Index indexName={SearchIndex.BusinessEntities}>
                  <Configure filters="(areRolesEmpty:true OR roles:CARRIER) AND disabled:false" />
                  <SearchAutocomplete<{
                    name: string;
                    id: number;
                  }>
                    value={freightCompany}
                    label="Freight Company"
                    disabled={!fieldEditability['shipment']}
                    onChange={(_, value) => {
                      setFreightCompany(value);
                      updateShipment({
                        variables: {
                          id: entryForm.shipment.id,
                          input: {
                            freightCompanyId: value?.id ?? null,
                          },
                        },
                      });
                    }}
                    transform={transformBusinessEntity}
                    getOptionLabel={(option) => option.name}
                  />
                </Index>
              </FormControl>
            </FormControl>

            {shouldDatesBeVisible && (
              <>
                <FormControl sx={{ width: '22.5%' }}>
                  <QuickDatePicker
                    label="Ship Date"
                    value={shipDate}
                    onChange={(date) => {
                      handleUpdateShipDate(date);
                    }}
                    disabled={!fieldEditability['shipDate']}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
                <FormControl sx={{ width: '22.5%' }}>
                  <QuickDatePicker
                    label="Delivery Date"
                    value={deliveryDate}
                    onChange={(date) => {
                      handleUpdateDeliveryDate(date);
                    }}
                    disabled={!fieldEditability['deliveryDate']}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </>
            )}
          </Box>
          <Box display="flex" gap={2} flexWrap="wrap">
            <FormControl
              sx={{
                width: '100px',
              }}
            >
              <TextField
                label="Min Temp"
                type="number"
                disabled={!fieldEditability['shipment']}
                value={temperatureMin}
                error={temperatureMin > temperatureMax}
                onChange={handleUpdateTempMin}
              />
            </FormControl>
            <FormControl
              sx={{
                width: '100px',
              }}
            >
              <TextField
                label="Max Temp"
                type="number"
                disabled={!fieldEditability['shipment']}
                value={temperatureMax}
                error={temperatureMin > temperatureMax}
                onChange={handleUpdateTempMax}
              />
            </FormControl>
            <CustomFieldsForm
              customFieldValues={shipmentCustomFieldValues}
              customFieldsKey="salesOrder"
              handleUpdateCustomFields={handleUpdateShipmentCustomFields}
              filterFields={(field) => field.section === 'shipment'}
            />
          </Box>
        </Box>
      </InstantSearch>
    </>
  );
};
