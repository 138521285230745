import { GenericLineItem } from './computeTotalPallets';

/**
 * This needs to be migrated to the `shared/constants` folder. But I was running into some import issues to I just put it here for now.
 * Will migrate it later.
 */
const MaterialInventoryProductIDs = {
  // Millennium Pacific
  4284: 'Pallet CHEP',
};

/**
 * Computes total cartons used in the given LineItems.
 *
 * Note: For now, cartons are equivalent to "units" (e.g. "unitsReceived" or "unitsOrdered"). There is a concept of an
 * inventory unit of measure, which may change in the future, but it is currently 1 carton.
 */
const computeTotalCartons = (lineItems: Array<GenericLineItem>) => {
  const numCartons = {
    ordered: 0,
    received: 0,
    picked: 0,
    shipped: 0,
    invoiced: 0,
  };

  const nonMaterialInventoryLineItems = lineItems.filter(({ productId }) => !MaterialInventoryProductIDs[productId]);

  nonMaterialInventoryLineItems?.forEach(
    ({ unitsOrdered, unitsReceived, unitsPicked, unitsShipped, unitsInvoiced }) => {
      numCartons.ordered += unitsOrdered ?? 0;
      numCartons.received += unitsReceived ?? 0;
      // external line items will be under "unitsShipped" as they are not picked from inventory
      numCartons.picked += unitsPicked ?? unitsShipped ?? 0;
      numCartons.shipped += unitsShipped ?? 0;
      numCartons.invoiced += unitsInvoiced ?? 0;
    }
  );

  return numCartons;
};

export default computeTotalCartons;
