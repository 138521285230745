import { SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { FilePresent as DocumentIcon } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import { Divider, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { DocumentTemplateEntityType } from 'shared/documents/DocumentGenerator';

import { useMutation, useQuery } from '@redwoodjs/web';

import { DELETE_NOTE_MUTATION } from 'src/api/notes.api';
import DocumentTemplateApi from 'src/modules/document-editor/api/DocumentTemplate.api';

import Comment, { CommentProps } from './Comment';
import NewComment, { DocumentTypeOptions } from './NewComment';

export type NoteWithId = CommentProps & { id: number | string };
export type NoteList = Array<NoteWithId>;

export type NotedOnId = {
  purchaseOrderId?: number;
  salesOrderId?: number;
  buySellOrderId?: number;
  workOrderId?: number;
  productionRunId?: number;
  loadId?: number;
  lotId?: number;
  standardInvoiceId?: number;
  standardPayableId?: number;
};

export type CommentsSectionProps = {
  notes: NoteList;
  documentTypeOptions?: DocumentTypeOptions;
  defaultDocumentTypes?: DocumentTypeOptions[];
  postCommentTo: NotedOnId;
  entityType: DocumentTemplateEntityType;
};

export const postComment = (comment: NoteWithId) => {
  const event = new CustomEvent('addComment', { detail: comment });
  document.dispatchEvent(event);
};

export default function CommentsSection({
  notes,
  documentTypeOptions,
  defaultDocumentTypes,
  postCommentTo,
  entityType,
}: CommentsSectionProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [deleteNote] = useMutation(DELETE_NOTE_MUTATION);
  const [comments, setComments] = useState<NoteList>(notes);
  const external = selectedTab === 1;

  const handleTabChange = (_e: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleAddComment = useCallback(
    (comment: NoteWithId) => {
      setComments([comment, ...comments]);
    },
    [setComments, comments]
  );

  const addComment = useCallback(
    ({ detail }: CustomEvent<NoteWithId>) => {
      handleAddComment(detail);
    },
    [handleAddComment]
  );

  useEffect(() => {
    document.addEventListener('addComment', addComment);

    return () => {
      document.removeEventListener('addComment', addComment);
    };
  }, [addComment]);

  // when notes get updated via refetch, update the local state
  useEffect(() => {
    setComments(notes);
  }, [notes]);

  const handleDeleteComment = useCallback(
    (id: number) => {
      deleteNote({
        variables: { id },
      });
      setComments(comments.filter((comment) => comment.id !== id));
    },
    [setComments, comments, deleteNote]
  );

  const canHaveDocumentNotes = documentTypeOptions?.length > 0;

  const { data: customDocumentTypes } = useQuery(DocumentTemplateApi.GetDocumentTypesForEntityType, {
    variables: { entityType },
    skip: !entityType,
  });

  return (
    <>
      <Box sx={{ mt: 4 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="notes tabs" sx={{ mb: 1 }}>
          <Tab
            value={0}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <GroupIcon /> Internal Comments
              </Box>
            }
          />
          {canHaveDocumentNotes && (
            <Tab
              value={1}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <DocumentIcon /> Document Notes
                </Box>
              }
            />
          )}
        </Tabs>
      </Box>
      <NewComment
        postCommentTo={postCommentTo}
        external={external}
        onCreate={handleAddComment}
        documentTypeOptions={documentTypeOptions}
        customDocumentTypeOptions={customDocumentTypes?.documentTemplates}
        defaultDocumentTypes={defaultDocumentTypes}
        canAttachToDocument={selectedTab === 1}
      />
      <Divider sx={{ my: 1.5 }} />
      {comments
        ?.filter((note) => note.external === external)
        .map((note) => (
          <Comment
            key={note.id}
            customDocumentTypeOptions={customDocumentTypes?.documentTemplates}
            {...note}
            handleDelete={handleDeleteComment}
          />
        ))}
    </>
  );
}
