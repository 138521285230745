import React from 'react';

import { Prisma } from '@prisma/client';
import { View } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import { ExpenseUnitOfMeasure } from 'types/graphql';

import computeDeliveredPrice from 'src/lib/shared/computeDeliveredPrice';
import computeDescription from 'src/services/products/computed/computeDescription';
import getProductWeight from 'src/services/utils/getProductWeight';

import { formatCurrency } from '../utils/formatters';
import money from '../utils/money';
import { docStyles } from '../utils/styles';

import Table, { TableColumnDefinition } from './Table';

/**
 * This needs to be migrated to the `shared/constants` folder. But I was running into some import issues to I just put it here for now.
 * Will migrate it later.
 */
const MaterialInventoryProductIDs = {
  // Millennium Pacific
  4284: 'Pallet CHEP',
};

type LineItemProps = Prisma.LineItemGetPayload<{
  include: {
    lot: true;
    product: {
      include: {
        commodity: true;
        commodityLabel: true;
        commoditySize: true;
        commodityStyle: true;
        commodityUnit: true;
      };
    };
  };
}>;

type BSOLineItemProps = Prisma.BuySellOrderLineItemGetPayload<{
  include: {
    product: {
      include: {
        commodity: true;
        commodityLabel: true;
        commoditySize: true;
        commodityStyle: true;
        commodityUnit: true;
      };
    };
  };
}>;

type WorkOrderLineItemProps = Prisma.WorkOrderLineItemGetPayload<{
  include: {
    lot: true;
    product: {
      include: {
        commodity: true;
        commodityLabel: true;
        commoditySize: true;
        commodityStyle: true;
        commodityUnit: true;
      };
    };
  };
}>;

const isBuySellOrderLineItem = (
  lineItem: LineItemProps | BSOLineItemProps | WorkOrderLineItemProps
): lineItem is BSOLineItemProps => (lineItem as BSOLineItemProps).buySellOrderId !== undefined;

const isSalesOrderLineItem = (
  lineItem: LineItemProps | BSOLineItemProps | WorkOrderLineItemProps
): lineItem is LineItemProps => (lineItem as LineItemProps).salesOrderId !== undefined;

const isPurchaseOrderLineItem = (
  lineItem: LineItemProps | BSOLineItemProps | WorkOrderLineItemProps
): lineItem is LineItemProps => (lineItem as LineItemProps).purchaseOrderId !== undefined;

const isWorkOrderLineItem = (
  lineItem: LineItemProps | BSOLineItemProps | WorkOrderLineItemProps
): lineItem is WorkOrderLineItemProps => (lineItem as WorkOrderLineItemProps).workOrderId !== undefined;

const calculateLineItemWeight = (
  lineItem: LineItemProps | BSOLineItemProps | WorkOrderLineItemProps,
  orderType?: 'BSO' | 'SO' | 'PO' | 'GP'
) => {
  if (isSalesOrderLineItem(lineItem) || orderType === 'SO') {
    const { lot, product, orderedProduct, unitsPicked, unitsShipped, unitsOrdered } = lineItem;

    const productWeight = getProductWeight(product || orderedProduct, lot);

    return productWeight * (unitsPicked ?? unitsShipped ?? unitsOrdered);
  }

  if (isPurchaseOrderLineItem(lineItem) || isWorkOrderLineItem(lineItem) || orderType === 'PO' || orderType === 'GP') {
    const { lot, product, unitsReceived, unitsOrdered } = lineItem;

    const productWeight = getProductWeight(product, lot);

    return productWeight * (unitsReceived ?? unitsOrdered);
  }

  if (isBuySellOrderLineItem(lineItem) || orderType === 'BSO') {
    const { product, unitsShipped, unitsOrdered } = lineItem;
    const { weightPerInventoryUnit } = product;

    const productWeight =
      typeof weightPerInventoryUnit === 'number' ? weightPerInventoryUnit : weightPerInventoryUnit.toNumber();

    return productWeight * (unitsShipped ?? unitsOrdered);
  }
};

type LineItemTableRowData = {
  id: number;
  product: string;
  unitsOrdered: number;
  unitsPicked: number;
  unitsShipped: number;
  unitsInvoiced: number;
  unitPrice: string;
  buyPrice: string;
  lineItemWeight: string;
  total: string;
};

export const getUnitPrice = (
  li: LineItemProps & BSOLineItemProps,
  totals: {
    cartons: number;
    pallets: number;
    weight: number;
  },
  deliveredExpenses: Array<{
    quantity: number;
    unitAmount: number;
    includedInDlvPrice?: boolean;
    unitOfMeasure: ExpenseUnitOfMeasure;
  }>,
  isBuySellOrderPurchaseOrder = false
) => {
  if (isBuySellOrderPurchaseOrder) {
    return li.buyCurrencyPrice ?? li.buyPrice;
  }

  const deliveredPrice = li.deliveredCurrencyPrice ?? li.deliveredPrice;

  const price = li.sellCurrencyPrice ?? li.sellPrice;

  return deliveredPrice ?? price;
};

export function ProductsGrid({
  productLineItems,

  // Individual column visibility
  showUnitsOrdered = false,
  showUnitsPicked = false,
  showUnitsShipped = false,
  showUnitsInvoiced = false,
  showUnitPrice = false,
  showBuyPrice = false,
  showLineItemWeight = false,
  showSubTotal = false,

  isBuySellOrderPurchaseOrder = false,
  isBuySellOrderInvoice = false,

  deliveredExpenses,
  orderType,
  totals,
  viewStyle = docStyles.sectionContainer,
  currency = 'USD',
  productTemplate,
}: {
  productLineItems: Array<
    LineItemProps & BSOLineItemProps & WorkOrderLineItemProps // TODO: This should actually be a union not an intersection
  >;

  showUnitsOrdered?: boolean;
  showUnitsPicked?: boolean;
  showUnitsShipped?: boolean;
  showUnitsInvoiced?: boolean;
  showUnitPrice?: boolean;
  showBuyPrice?: boolean;
  showLineItemWeight?: boolean;
  showSubTotal?: boolean;

  isBuySellOrderPurchaseOrder?: boolean;
  isBuySellOrderInvoice?: boolean;

  deliveredExpenses?: Array<{
    quantity: number;
    unitAmount: number;
    includedInDlvPrice?: boolean;
    unitOfMeasure: ExpenseUnitOfMeasure;
  }>;
  totals?: {
    cartons: number;
    pallets: number;
    weight: number;
  };
  viewStyle?;
  orderType?: 'BSO' | 'SO' | 'PO' | 'GP';
  currency?: string;
  productTemplate?: string;
}) {
  const nonMaterialInventoryLineItems = productLineItems.filter(
    (li) => !(li?.product?.productId in MaterialInventoryProductIDs)
  );

  const getUnitsForTotal = (li: LineItemProps & BSOLineItemProps) => {
    if (showUnitsInvoiced) {
      return li.unitsInvoiced ?? 0;
    }

    if (showUnitsPicked) {
      return li.unitsPicked ?? li.unitsShipped ?? 0;
    }

    if (showUnitsShipped) {
      return li.unitsShipped ?? li.unitsDelivered ?? 0;
    }

    if (showUnitsOrdered) {
      return li.unitsOrdered ?? 0;
    }
  };

  const orderTotalPrice = productLineItems.reduce((total, li) => {
    const unitPrice = showBuyPrice
      ? li.buyCurrencyPrice ?? li.buyPrice
      : getUnitPrice(li, totals, deliveredExpenses, isBuySellOrderPurchaseOrder);

    return total + getUnitsForTotal(li) * money.toDollars(unitPrice);
  }, 0);

  const orderTotalWeight = productLineItems.reduce(
    (total, li) => total + calculateLineItemWeight(li, orderType) || 0,
    0
  );

  const totalUnitsOrdered = nonMaterialInventoryLineItems.reduce((total, li) => total + li.unitsOrdered || 0, 0);

  const hasUnitsPicked = nonMaterialInventoryLineItems.some((li) => !isNil(li.unitsPicked) || !isNil(li.unitsShipped));

  const totalUnitsPicked = hasUnitsPicked
    ? nonMaterialInventoryLineItems.reduce((total, li) => {
        return total + (li.unitsPicked ?? li.unitsShipped ?? 0);
      }, 0)
    : null;

  const totalUnitsShipped = nonMaterialInventoryLineItems.reduce((total, li) => total + li.unitsShipped, 0);

  const componentGridColumns: TableColumnDefinition<LineItemTableRowData>[] = [
    {
      field: 'product',
      headerName: 'Product',
      sx: { minWidth: 200 },
    },

    showUnitsOrdered && {
      field: 'unitsOrdered',
      headerName: 'Ordered',
      sx: { minWidth: 75, textAlign: 'right' },
    },

    showUnitsPicked && {
      field: 'unitsPicked',
      headerName: 'Shipped',
      sx: { minWidth: 75, textAlign: 'right' },
    },

    showUnitsShipped && {
      field: 'unitsShipped',
      headerName: 'Shipped',
      sx: { minWidth: 75, textAlign: 'right' },
    },

    showUnitsInvoiced && {
      field: 'unitsInvoiced',
      headerName: 'Invoiced',
      sx: { minWidth: 75, textAlign: 'right' },
    },

    showUnitPrice && {
      field: 'unitPrice',
      headerName: 'Unit Price',
      sx: { minWidth: 80, textAlign: 'right' },
    },

    showBuyPrice && {
      field: 'buyPrice',
      headerName: 'Unit Price',
      sx: { minWidth: 80, textAlign: 'right' },
    },

    showLineItemWeight && {
      field: 'lineItemWeight',
      headerName: 'Weight (lb.)',
      sx: { minWidth: 75, textAlign: 'right' },
    },

    (showSubTotal || showUnitPrice) && {
      field: 'total',
      headerName: 'Subtotal',
      sx: {
        ...docStyles.subTotalColumn,
      },
      headerCellSx: { ...docStyles.subTotalHeader },
    },

    /* TODO: Add lots. 1. Either pre-entered lots, 2. empty space where they can write lots */
  ];

  const rows = productLineItems.map((li) => {
    const unitQuantity = getUnitsForTotal(li);

    const unitPrice = showBuyPrice
      ? li.buyCurrencyPrice ?? li.buyPrice
      : getUnitPrice(li, totals, deliveredExpenses, isBuySellOrderPurchaseOrder);

    const row: LineItemTableRowData = {
      id: li.id,
      product: computeDescription(li.product || li.orderedProduct, productTemplate),

      unitsOrdered: li.unitsOrdered || 0,
      unitsPicked: li.unitsPicked ?? li.unitsShipped,
      unitsShipped: li.unitsShipped,
      unitsInvoiced: li.unitsInvoiced,
      buyPrice: formatCurrency(money.toDollars(li.buyCurrencyPrice ?? li.buyPrice), currency),
      unitPrice: formatCurrency(money.toDollars(unitPrice), currency),
      lineItemWeight: (calculateLineItemWeight(li, orderType) ?? '--').toLocaleString('en-US'),
      total: formatCurrency(unitQuantity * money.toDollars(unitPrice), currency),
    };

    return row;
  });

  return (
    <View style={viewStyle} wrap={false}>
      <Table
        columns={componentGridColumns}
        rows={rows}
        aggregation={{
          total: () => formatCurrency(orderTotalPrice, currency),
          unitsOrdered: () => totalUnitsOrdered ?? '--',
          unitsPicked: () => totalUnitsPicked,
          unitsShipped: () => totalUnitsShipped,
          lineItemWeight: () => (orderTotalWeight ? `${orderTotalWeight.toFixed(0)} lbs` : '--'),
        }}
      />
    </View>
  );
}
