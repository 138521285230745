import { isNil } from 'lodash';

/**
 * Rounds a number to the nearest 1/4. This is useful for rounding partial inventory units and keeping the displayed
 * value more user-friendly.
 *
 * Why 1/4? It's a somewhat arbitrary choice, but it's a good balance between precision and readability.
 */
const roundToNearestQuarter = (value: number | string) => {
  // Number(null) returns 0, and similarly undefined -> NaN, which is bad for item potency.
  if (isNil(value)) {
    return value;
  }

  const valueAsNumber = Number(value);

  const rounded = Math.round(valueAsNumber * 4) / 4;

  return Number(rounded.toFixed(2));
};

export default roundToNearestQuarter;
