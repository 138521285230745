import { Clear, Search } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';

import { ServerEnhancedGridStateWithHelpers } from './useServerEnhancedGridState';

type Props = {
  enhancedGridState: ServerEnhancedGridStateWithHelpers;
};

export default function NoRowsOverlay({ enhancedGridState }: Props) {
  const hasFilters = enhancedGridState.gridFilters?.length > 0;
  const hasSearch = enhancedGridState.searchText?.length > 0;

  const clearFilters = () => {
    enhancedGridState.updateFilters([]);
    enhancedGridState.setSearch('');
  };

  return (
    <Stack alignItems="center" justifyContent="center" width="100%" my={2}>
      <Search sx={{ color: 'primary.400', fontSize: '32px', mb: 1 }} />
      <Typography>No matching results.</Typography>
      {(hasFilters || hasSearch) && (
        <>
          <Typography>Try adjusting filters or clear them all.</Typography>
          <Button sx={{ mt: 2 }} variant="outlined" color="error" onClick={() => clearFilters()} startIcon={<Clear />}>
            Clear all filters
          </Button>
        </>
      )}
    </Stack>
  );
}
