import { useState } from 'react';

import { Edit } from '@mui/icons-material';
import { Button, Dialog, IconButton, InputAdornment, TextField } from '@mui/material';

import useEditProductCostBasis from 'src/modules/systemManagement/pricingSheets/hooks/useEditProductCostBasis';
import money from 'src/utils/money';

type CostBasisCellProps = {
  costBasis?: number;
  productId: number;
  productName: string;
  productCode: string;
};

export default function CostBasisCell(props: CostBasisCellProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(props.costBasis ? money.toDollars(props.costBasis) : null);

  const editProductCostBasis = useEditProductCostBasis();

  return (
    <>
      <div className="row items-center gap-2">
        <span>{props.costBasis ? money.toHuman(props.costBasis) : '--'}</span>
        <IconButton className="invisible group-hover:visible" onClick={() => setIsEditing(true)}>
          <Edit />
        </IconButton>
      </div>
      {isEditing && (
        <Dialog open onClose={() => setIsEditing(false)}>
          <div className="flex flex-col p-6 gap-4 w-[356px]">
            <b>Editing cost basis</b>
            <p className="text-lg">{props.productName}</p>
            <p className="text-sm text-grey-600 -mt-3 mb-6">{props.productCode}</p>
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
              }}
              label="Cost basis"
              variant="outlined"
              value={editValue}
              type="number"
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                setEditValue(Math.abs(value));
              }}
            />
            <p>Editing the cost basis for this product will change it in ALL pricing sheets.</p>
            <footer className="flex gap-2 justify-end mt-4 -mb-2 -mr-2">
              <Button
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  editProductCostBasis(props.productId, money.toCents(editValue));
                  setIsEditing(false);
                }}
              >
                Save
              </Button>
            </footer>
          </div>
        </Dialog>
      )}
    </>
  );
}
