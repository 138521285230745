import { KeyOption, matchSorter } from 'match-sorter';

export default function fuzzyComplete(
  keys: ReadonlyArray<KeyOption<string>>,
  config: {
    baseSort?: (a: any, b: any) => number;
    filter?: (options: any) => any;
    threshold?: (typeof matchSorter.rankings)[keyof typeof matchSorter.rankings];
  } = {}
) {
  return (options, { inputValue }) => {
    const terms = inputValue.split(' ');

    if (!terms) {
      return options;
    }

    const filteredOptions = config.filter ? config.filter(options) : options;

    return terms.reduceRight(
      (options, term) =>
        matchSorter(filteredOptions, term, {
          keys,
          baseSort: options.baseSort,
          threshold: config.threshold,
        }),
      filteredOptions
    );
  };
}
