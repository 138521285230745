import { MetaTags } from '@redwoodjs/web';

import InventoryTransferCell from 'src/components/cells/InventoryTransferCell';

const InventoryTransferPage = ({ slug }) => {
  return (
    <>
      <MetaTags title={`Inventory Transfer #${slug}`} />

      <InventoryTransferCell slug={slug} />
    </>
  );
};

export default InventoryTransferPage;
