import { Prisma } from '@prisma/client';

export type GenericLineItem = {
  product: {
    cartonsPerPallet: number;
    weightPerInventoryUnit: Prisma.Decimal;
  };
  lot?: {
    initialQuantity: number;
    initialWeight: Prisma.Decimal;
  };
  unitsOrdered?: number;
  unitsShipped?: number;
  unitsInvoiced?: number;
  unitsReceived?: number;
  unitsPicked?: number;
};

/**  Made this generic, so we can use any type of line item.
 * Could update all BSO usages to use this as well, or make a more generic version
 * that could be expanded to more metric types.
 * Consider refactoring the existing functions into a single, generic function.
 * The new approach would utilize a mapping for unit types and metric-specific computations.
 * This will allow for a flexible and reusable solution to calculate different metrics such as pallets, cartons, and weight.
 * It aims to reduce code repetition and enhance maintainability.
 */
const computeTotalPallets = (lineItems: Array<GenericLineItem>) => {
  const numPallets = {
    ordered: 0,
    shipped: 0,
    invoiced: 0,
    received: 0,
    picked: 0,
  };

  lineItems
    .filter((li) => li.product)
    .forEach(
      ({
        product,
        orderedProduct,
        unitsOrdered = 0,
        unitsShipped = 0,
        unitsInvoiced = 0,
        unitsReceived = 0,
        unitsPicked = 0,
      }) => {
        const { cartonsPerPallet } = product || orderedProduct;

        // TODO: Use line item pallet tags to determine the pallet count
        const computePalletsForUnits = (units: number) => {
          if (!cartonsPerPallet) {
            return 0;
          }

          return units / cartonsPerPallet;
        };

        const shippedPallets = computePalletsForUnits(unitsShipped);

        numPallets.ordered += computePalletsForUnits(unitsOrdered);
        numPallets.shipped += shippedPallets;
        numPallets.invoiced += computePalletsForUnits(unitsInvoiced);
        numPallets.received += computePalletsForUnits(unitsReceived);
        // external line items will be under "unitsShipped" as they are not picked from inventory
        numPallets.picked += computePalletsForUnits(unitsPicked) + shippedPallets;
      }
    );

  return {
    ordered: Math.ceil(numPallets.ordered),
    shipped: Math.ceil(numPallets.shipped),
    invoiced: Math.ceil(numPallets.invoiced),
    received: Math.ceil(numPallets.received),
    picked: Math.ceil(numPallets.picked),
  };
};

export default computeTotalPallets;
