import * as React from 'react';

import { Button, ButtonTypeMap, CSSObject, ExtendButtonBase, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';

import Hint from 'src/components/atoms/Hint';
import useAnchorEl from 'src/hooks/useAnchorEl';

type ButtonComponent = ExtendButtonBase<ButtonTypeMap<unknown, React.ElementType>>;

export type NewMenuProps = {
  children: React.ReactNode;
  label?: string;
  Icon: React.ReactNode;
  hint?: string;
  ButtonComponent?: ButtonComponent;
  sx?: CSSObject;
  buttonProps?: Partial<React.ComponentProps<ButtonComponent>>;
  menuProps?: Partial<React.ComponentProps<typeof Menu>>;
  buttonClass?: (open: boolean) => string;
};

export function DropdownMenuButton({
  children,
  label,
  Icon,
  hint,
  sx = {},
  menuProps = {},
  buttonProps = {},
  ButtonComponent = Button,
  buttonClass,
}: NewMenuProps) {
  const { anchorEl, open, handleClick, handleClose } = useAnchorEl();

  return (
    <>
      <Hint title={hint}>
        <ButtonComponent
          sx={sx}
          onClick={handleClick}
          variant="contained"
          size="medium"
          className={buttonClass ? buttonClass(open) : undefined}
          {...buttonProps}
        >
          {label}
          {ButtonComponent === IconButton && Icon}
        </ButtonComponent>
      </Hint>
      <Menu {...menuProps} anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
        {children}
      </Menu>
    </>
  );
}
