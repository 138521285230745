import { useMemo } from 'react';

import { TypedDocumentNode, useApolloClient } from '@apollo/client';
import { PaginationResult } from 'shared/types';

import { useQuery } from '@redwoodjs/web';

import { PaginationWebResult } from './types';
import { ServerEnhancedGridState } from './useServerEnhancedGridState';

type ServerEnhancedGridQueryArgs = {
  queryKey: string;
  query: TypedDocumentNode;
  gridState: ServerEnhancedGridState;
};

export default function useServerEnhancedGridQuery<T = any>({
  queryKey,
  query,
  gridState,
}: ServerEnhancedGridQueryArgs): PaginationWebResult<T> {
  const client = useApolloClient();
  const input = useMemo(
    () => ({
      first: gridState.first,
      after: gridState.after,
      sortItems: gridState.sortItems,
      gridFilters: gridState.gridFilters,
      searchText: gridState.searchText,
      quickFilters: gridState.quickFilterOption?.filters,
    }),
    [gridState]
  );

  const { data, loading } = useQuery<{ [key: string]: PaginationResult }>(query, { variables: { input } });

  return useMemo(() => {
    return {
      ...mapServerPaginatedToGridData(queryKey, data),
      loading,
      fetchAllForExport: createExportFunction({ client, query, queryKey, input }),
    };
  }, [queryKey, data, loading, client, query, input]);
}

function mapServerPaginatedToGridData(
  queryKey: string,
  paginationResult?: Record<string, PaginationResult>
): PaginationWebResult {
  const data = paginationResult?.[queryKey];
  const nodes = data?.edges?.map((edge) => edge.node) ?? [];
  return {
    rows: nodes,
    totalCount: data?.totalCount,
    pageInfo: {
      ...data?.pageInfo,
    },
  };
}

function createExportFunction({ client, query, queryKey, input }) {
  const fullInput = {
    ...input,
    first: 9999,
    after: null,
  };

  return async () => {
    const { data } = await client.query({
      query,
      variables: { input: fullInput },
    });

    return data[queryKey]?.edges?.map((edge) => edge.node);
  };
}
