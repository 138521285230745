import { useOrganization } from '@clerk/clerk-react';
import GroupIcon from '@mui/icons-material/Group';
import { Box, BoxProps } from '@mui/material';

export default function OrganizationLogo(props: BoxProps) {
  const { organization, isLoaded } = useOrganization();

  return isLoaded && organization?.logoUrl ? (
    <Box {...props}>
      <img src={organization.logoUrl} width="100%" alt="Organization Logo" />
    </Box>
  ) : (
    <GroupIcon />
  );
}
