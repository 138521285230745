import { ReactElement } from 'react';

import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export type DropdownMenuItemProps = {
  Icon: React.FC<SvgIconProps>;
  label: string | ReactElement;
};

export function DropdownMenuItem({ Icon, label, ...props }: DropdownMenuItemProps & MenuItemProps) {
  return (
    <MenuItem {...props} sx={{ display: 'flex', gap: 2 }}>
      <ListItemIcon>
        <Icon color="action" />
      </ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
}
