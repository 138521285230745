import { AutocompleteProps, debounce, TextField } from '@mui/material';
import { useHits, useSearchBox } from 'react-instantsearch-hooks-web';

import Autocomplete, { ExtraAutoCompleteProps } from './Autocomplete';

export type SearchAutocompleteProps<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> = {
  value: unknown;
  textFieldProps?: React.ComponentProps<typeof TextField>;
} & Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'options'> &
  ExtraAutoCompleteProps;

export default function SearchAutocomplete<T, Multiple extends boolean = false>({
  value,
  textFieldProps,
  getOptionLabel,
  onChange,
  ...props
}: SearchAutocompleteProps<T, Multiple>) {
  const { refine } = useSearchBox();
  const { hits } = useHits();

  if (!hits) {
    return null;
  }

  const debounceChange = debounce((value) => {
    refine(value);
  }, 100);

  return (
    <Autocomplete
      value={value || null}
      fullWidth
      options={hits}
      getOptionLabel={getOptionLabel}
      getOptionKey={(option) => option.objectID}
      onChange={(e, value, details) => {
        onChange?.(e, value, details);
        refine(value ? getOptionLabel(value) : '');
      }}
      textFieldProps={{
        fullWidth: true,
        onBlur: () => {
          refine(value ? getOptionLabel(value) : '');
        },
        onChange: (e) => {
          debounceChange(e.target.value);
        },
        ...textFieldProps,
      }}
      {...props}
    />
  );
}
