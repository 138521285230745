import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import { docStyles } from '../utils/styles';

export function SignatureLinesGroup({ signatures }: { signatures: string[] }) {
  return (
    <View style={docStyles.signatureLinesContainer} wrap={false}>
      <View style={docStyles.signatureHeader}>
        <Text>SIGNED BY</Text>
      </View>

      {signatures.map((signature) => (
        <View
          key={signature}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: 10,
            paddingBottom: 5,
          }}
        >
          <View style={docStyles.signatureDetails}>
            <Text>{signature} Name</Text>
          </View>
          <View style={docStyles.signatureDetails}>
            <Text>{signature} Signature</Text>
          </View>
        </View>
      ))}
    </View>
  );
}
