import { BusinessEntity, GenericInvoice, Membership, Organization, Payment } from '../../types';
import Money from '../../utils/Money';
import { AddressComponent } from '../components/address.template';
import { DocumentTypePopulator } from '../DocumentGenerator';

import { applyTableMetadata, formatCurrency, formatDate } from './formatters';

export type RemittanceInputType = {
  organization: Organization;
  slug: string;
  shipDate: string;
  deliveryDate: string;
  contact: Membership;
  contactId: number;
  payee?: BusinessEntity;
  payments: Payment[];
  issuedDate: string;
};

const getOrderNumber = (invoice?: GenericInvoice): string => {
  if (!invoice) {
    return '--';
  }

  if (invoice.workOrder) {
    return invoice.workOrder.slug || '--';
  }

  if (invoice.buySellOrder) {
    return invoice.buySellOrder.vendorSo || '--';
  }

  if (invoice.salesOrder) {
    return invoice.salesOrder.slug || '--';
  }

  return invoice.purchaseOrder?.vendorPo || '--';
};

const getRelatedPayable = (payment: Payment): any => {
  const { standardPayable, buySellOrderPayable, consignmentPayable, payable } = payment;

  if (standardPayable) {
    return {
      payable: standardPayable,
      type: 'standard',
      invoiceDate: standardPayable.invoiceDate,
      referenceNumber: standardPayable.referenceInvoiceNum,
      orderNumber: getOrderNumber(standardPayable),
      billTo: standardPayable.payToAddress,
    };
  }

  if (buySellOrderPayable) {
    return {
      payable: buySellOrderPayable,
      type: 'buySellOrder',
      invoiceDate: buySellOrderPayable.receivedAt,
      referenceNumber: buySellOrderPayable.referenceNumber,
      orderNumber: getOrderNumber(buySellOrderPayable),
      billTo: buySellOrderPayable.buySellOrder?.vendorBillTo,
    };
  }

  if (consignmentPayable) {
    return {
      payable: consignmentPayable,
      type: 'consignment',
      invoiceDate: consignmentPayable.createdAt,
      referenceNumber: consignmentPayable.referenceNumber,
      orderNumber: getOrderNumber(consignmentPayable),
      billTo: consignmentPayable.workOrder?.shipment?.origin,
    };
  }

  return {
    payable,
    type: 'order',
    invoiceDate: payable?.receivedAt,
    referenceNumber: payable?.referenceNumber,
    orderNumber: getOrderNumber(payable),
    billTo: payable?.purchaseOrder?.shipment?.place,
  };
};

const formatPayable = (payment: Payment): any => {
  const { payable, invoiceDate, referenceNumber, orderNumber, billTo } = getRelatedPayable(payment);

  return {
    slug: payable?.slug,
    invoiceDate: formatDate(invoiceDate),
    totalAmountDue: formatCurrency(Money.toDollars(payable?.totalAmountDue)),
    referenceNumber: referenceNumber || '--',
    orderNumber,
    billTo: AddressComponent.templatize(billTo),
  };
};

const formatPayment = (payment: Payment): any => {
  return {
    paymentNumber: payment.slug,
    amount: formatCurrency(Money.toDollars(payment.paymentAmount)),
    invoice: formatPayable(payment),
  };
};

const formatPaymentBatch = (payment: Payment): any => {
  const date = formatDate(payment.paymentDate);

  if (payment.childPayments?.length) {
    const formattedPayments = payment.childPayments.map(formatPayment);

    const billTo = formattedPayments.find((p) => p.invoice.billTo?.length > 0);

    return {
      payments: payment.childPayments.map(formatPayment),
      billTo,
      paymentDate: date,
      paymentNumber: payment.slug,
    };
  }

  const formattedPayment = formatPayment(payment);

  return {
    paymentDate: date,
    paymentNumber: payment.slug,
    billTo: formattedPayment.invoice.billTo,
    payments: [formattedPayment],
  };
};

export const RemittanceTemplate: DocumentTypePopulator<RemittanceInputType, void, any> = {
  populate: (remittance) => {
    if (!remittance) {
      return {};
    }

    const paymentBatches = applyTableMetadata(remittance.payments.map(formatPaymentBatch));
    const totalCents = remittance.payments.reduce((acc, payment) => acc + payment.paymentAmount, 0);

    const primaryLocation = remittance.organization.primaryLocation || remittance.organization.places?.[0];

    return {
      organization: {
        ...remittance.organization,
        address: AddressComponent.templatize(primaryLocation),
      },
      remittanceNumber: remittance.slug,
      paymentBatches,
      paymentTotal: formatCurrency(Money.toDollars(totalCents)),
      issuedDate: formatDate(remittance.issuedDate),
    };
  },
};
