import { useQuery } from '@redwoodjs/web';

import RemittanceDocumentApi from '../api/RemittanceDocument.api';

export default function useRemittanceDocument({ slug, skip = false }) {
  const { data, loading, refetch } = useQuery(RemittanceDocumentApi.GetRemittanceDocumentData, {
    variables: { slug },
    skip,
  });

  return {
    remittance: data?.remittance,
    loading,
    getDocumentData: () => refetch({ slug }).then((res) => res.data.remittance),
  };
}
