import { MutableRefObject, useCallback, useEffect } from 'react';

import { GridCsvExportOptions, GridExcelExportOptions } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { enqueueSnackbar } from 'notistack';
import { create } from 'zustand';

import { wait } from 'src/utils/wait';

export const useGridExportState = create<{ isExporting: boolean; setExporting: (value: boolean) => void }>((set) => ({
  isExporting: false,
  setExporting: (value: boolean) => set({ isExporting: value }),
}));

export type GridExportOptions = 'csv' | 'excel' | 'print';

export const EXPORT_GRID_EVENT = 'exportGrid';

export const exportGrid = (format: GridExportOptions) => {
  window.focus();
  const event = new CustomEvent(EXPORT_GRID_EVENT, { detail: format });
  document.dispatchEvent(event);
};

export default function useGridExport(
  apiRef: MutableRefObject<GridApiPremium>,
  fileName: string,
  exportExcelOptions: GridExcelExportOptions = {},
  exportCsvOptions: GridCsvExportOptions = {},
  fetchAllRows?: () => Promise<any[]>
) {
  const gridExportState = useGridExportState();

  const exportGrid = useCallback(
    async ({ detail: format }: CustomEvent<GridExportOptions>) => {
      gridExportState.setExporting(true);

      const formattedDate = new Date().toISOString().replace(/:/g, '-');
      const datedFileName = `${fileName}-${formattedDate}`;

      let currentPage;
      if (fetchAllRows) {
        enqueueSnackbar('Collecting all data for export...', { variant: 'info' });
        currentPage = apiRef.current.getRowModels();
        const allRows = await fetchAllRows();
        apiRef.current.setRows(allRows);

        await wait(100);
      }

      enqueueSnackbar('Data fetched. Generating file for download...', { variant: 'info' });

      switch (format) {
        case 'excel':
          apiRef.current.exportDataAsExcel({
            fileName: datedFileName,
            escapeFormulas: false,
            ...exportExcelOptions,
          });
          break;
        case 'csv':
          apiRef.current.exportDataAsCsv({
            fileName: datedFileName,
            escapeFormulas: false,
            ...exportCsvOptions,
          });
          break;
        case 'print':
          apiRef.current.exportDataAsPrint({
            fileName: datedFileName,
          });
          break;
      }

      await wait(1_000); // wait for the file to be generated

      if (fetchAllRows) {
        apiRef.current.setRows([]);
      }

      gridExportState.setExporting(false);
    },
    [gridExportState, fileName, fetchAllRows, apiRef, exportExcelOptions, exportCsvOptions]
  );

  useEffect(() => {
    document.addEventListener(EXPORT_GRID_EVENT, exportGrid);
    return () => document.removeEventListener(EXPORT_GRID_EVENT, exportGrid);
  }, [exportGrid]);
}
