import { GridToolbarQuickFilter } from 'src/modules/grids/toolbar/GridToolbarV2';

export const gridQuickFilters: GridToolbarQuickFilter[] = [
  {
    label: 'All time',
    filters: [{ field: 'isTemplate', where: [{ equals: false }] }],
  },
  {
    label: 'Open Orders',
    filters: [
      {
        field: 'status',
        where: [{ in: ['SUBMITTED', 'RECEIVING', 'PENDING_SETTLEMENT'] }],
      },
    ],
  },
  {
    label: 'Closed',
    filters: [{ field: 'status', where: [{ equals: 'CLOSED' }] }],
  },
  {
    label: 'Drafts',
    filters: [
      { field: 'status', where: [{ equals: 'DRAFT' }] },
      { field: 'isTemplate', where: [{ equals: false }] },
    ],
  },
  {
    label: 'Templates',
    filters: [{ field: 'isTemplate', where: [{ equals: true }] }],
  },
];
