import { useMutation } from '@redwoodjs/web';

import { GET_PRODUCTS } from 'src/components/cells/ProductsCell/ProductsCell';

import { GET_PRICING_SHEET_ENTRIES } from './usePricingSheetEntries';

const BULK_EDIT_PRODUCT_COST_BASIS = gql`
  mutation BulkEditProductCostBasis($ids: [Int!]!, $costBasis: Int!) {
    bulkChangeProductCostBasis(ids: $ids, costBasis: $costBasis)
  }
`;

export default function useBulkEditProductCostBasis() {
  const [bulkEditProductCostBasis] = useMutation(BULK_EDIT_PRODUCT_COST_BASIS, {
    refetchQueries: [GET_PRICING_SHEET_ENTRIES, GET_PRODUCTS],
  });

  return async (ids: number[], costBasis: number) => {
    return bulkEditProductCostBasis({ variables: { ids, costBasis } });
  };
}
