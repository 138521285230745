import { MetaTags } from '@redwoodjs/web';

import PalletTagsCell from 'src/components/cells/PalletTagsCell';

const PalletTagsPage = () => {
  return (
    <>
      <MetaTags title="Pallet Tag Editor" />

      <PalletTagsCell />
    </>
  );
};

export default PalletTagsPage;
