import { useCallback, useState } from 'react';

import { Box, Divider, FormControl, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import type {
  FindInventoryTransferQuery,
  FindInventoryTransferQueryVariables,
  InventoryTransferStatus,
} from 'types/graphql';

import { useMutation, type CellFailureProps, type CellSuccessProps } from '@redwoodjs/web';

import { AddressAutocomplete } from 'src/components';
import Autocomplete from 'src/components/atoms/Autocomplete';
import LoadingButton from 'src/components/atoms/buttons/LoadingButton';
import GenerateDocumentButton from 'src/components/atoms/GenerateDocumentButton';
import HeaderStatusBadge from 'src/components/atoms/HeaderStatusBadge';
import QuickDatePicker from 'src/components/atoms/QuickDatePicker';
import StatusStepper from 'src/components/atoms/StatusStepper';
import FormGrid from 'src/components/containers/FormGrid';
import { UPDATE_SHIPMENT } from 'src/components/containers/forms/SalesOrderCustomerEntryForm';
import ExpensesGrid from 'src/components/containers/grids/ExpensesGrid';
import { InventoryTransferGrid } from 'src/components/containers/grids/InventoryTransferGrid';
import { MainCard } from 'src/components/containers/MainCard';
import OrderInfo from 'src/components/containers/OrderInfo';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import SideModal from 'src/components/containers/sidemodals/SideModal';
import ScanningApi from 'src/modules/scanning/api/Scanning.api';
import TransferScanner from 'src/modules/scanning/components/containers/TransferScanner';
import SideModalContextProvider from 'src/providers/SideModalContextProvider';
import getFullName from 'src/utils/getFullName';

export const QUERY = gql`
  query FindInventoryTransferQuery($slug: String!) {
    inventoryTransfer: inventoryTransfer(slug: $slug) {
      id
      slug
      status
      contact {
        user {
          firstName
          lastName
        }
      }
      lotTransferItems {
        id
        lot {
          ...LotAttributes
          transferredChildren {
            slug
            warehouseId
          }
          product {
            ...ProductAttributes
          }
          quantityAvailable
        }

        palletTagsOnTransferItem {
          palletTag {
            id
            tagNumber
            cartonQuantity
          }
          quantity
        }

        unitsTransferred
      }
      shipment {
        id
        shipDate
        deliveryDate
        origin {
          ...PlaceAttributes
        }
        destination {
          ...PlaceAttributes
        }
        temperatureMin
        temperatureMax
      }
      expenses {
        ...ExpenseAttributes
      }
      organization {
        label
        logoUrl
        productDescriptionTemplate
        places {
          ...PlaceAttributes
        }
      }
    }
    places(addressRoles: [WAREHOUSE], strict: true) {
      ...PlaceAttributes
    }
    memberships {
      id
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const UPDATE_INVENTORY_TRANSFER = gql`
  mutation UpdateInventoryTransfer($id: Int!, $input: UpdateInventoryTransferInput!) {
    updateInventoryTransfer(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_INVENTORY_TRANSFER_STATUS = gql`
  mutation UpdateInventoryTransferStatus($id: Int!, $input: UpdateInventoryTransferStatusInput!) {
    updateInventoryTransferStatus(id: $id, input: $input) {
      id
      status
    }
  }
`;

const actionStatuses: {
  [key in InventoryTransferStatus]?: {
    actionBtnLabel: string;
    event: InventoryTransferUpdateEvent;
  };
} = {
  DRAFT: { actionBtnLabel: 'Submit', event: 'submit' },
  SUBMITTED: { actionBtnLabel: 'Confirm Shipped', event: 'mark_as_shipped' },
  SHIPPED: { actionBtnLabel: 'Close', event: 'close' },
};

export const Loading = () => <div>Loading...</div>;

export const Failure = ({ error }: CellFailureProps<FindInventoryTransferQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
);

/**
 * Work around to make the inventory transfer readable by the bol,
 * we should consider this use case when we refactor documents
 *
 * (i.e., handle mapping to a component type (e.g., Products) +
 * controlling what components are incompatible (i.e., terms, ordered column))
 */
const makeBolReadable = (
  inventoryTransfer: CellSuccessProps<
    FindInventoryTransferQuery,
    FindInventoryTransferQueryVariables
  >['inventoryTransfer']
) => {
  return {
    ...inventoryTransfer,
    salesPerson: inventoryTransfer.contact,
    shipDate: inventoryTransfer.shipment.shipDate,
    deliveryDate: inventoryTransfer.shipment.deliveryDate,
    lineItems: inventoryTransfer.lotTransferItems.map((item) => {
      return {
        ...item,
        product: item.lot?.product,
        unitsPicked: item.unitsTransferred,
        unitsOrdered: item.unitsTransferred,
      };
    }),
  };
};

export const Success = ({
  inventoryTransfer,
  memberships,
  places,
}: CellSuccessProps<FindInventoryTransferQuery, FindInventoryTransferQueryVariables>) => {
  const [updateShipment] = useMutation(UPDATE_SHIPMENT);
  const [updateInventoryTransfer] = useMutation(UPDATE_INVENTORY_TRANSFER);
  const [updateInventoryTransferStatus] = useMutation(UPDATE_INVENTORY_TRANSFER_STATUS);

  const [contact, setContact] = useState(inventoryTransfer.contact);

  const [transferRows, setTransferRows] = useState(inventoryTransfer.lotTransferItems);

  const [expenses, setExpenses] = useState(inventoryTransfer.expenses);
  const [temperatureMin, setTemperatureMin] = useState(inventoryTransfer.shipment?.temperatureMin ?? 0);
  const [temperatureMax, setTemperatureMax] = useState(inventoryTransfer.shipment?.temperatureMax ?? 0);

  const [shipDate, setShipDate] = useState(inventoryTransfer.shipment.shipDate);
  const [deliveryDate, setDeliveryDate] = useState(inventoryTransfer.shipment.deliveryDate);
  const [shippingOrigin, setShippingOrigin] = useState(inventoryTransfer.shipment?.origin);
  const [shippingDestination, setShippingDestination] = useState(inventoryTransfer.shipment?.destination);

  const [status, setStatus] = useState(inventoryTransfer.status);

  function handleUpdateContact(value) {
    setContact(value);

    updateInventoryTransfer({
      variables: {
        id: inventoryTransfer.id,
        input: { contactId: value.id },
      },
    });
  }

  function handleUpdateShipDate(value) {
    setShipDate(value);
    updateShipment({
      variables: {
        id: inventoryTransfer.shipment.id,
        input: { shipDate: value },
      },
    });
  }

  function handleUpdateDeliveryDate(value) {
    setDeliveryDate(value);
    updateShipment({
      variables: {
        id: inventoryTransfer.shipment.id,
        input: { deliveryDate: value },
      },
    });
  }

  function handleUpdateTempMin(e) {
    const value = Number(e.target.value);

    setTemperatureMin(value);
    updateShipment({
      variables: {
        id: inventoryTransfer.shipment.id,
        input: { temperatureMin: value },
      },
    });
  }

  function handleUpdateTempMax(e) {
    const value = Number(e.target.value);

    setTemperatureMax(value);
    updateShipment({
      variables: {
        id: inventoryTransfer.shipment.id,
        input: { temperatureMax: value },
      },
    });
  }

  function handleUpdateShippingOrigin(value) {
    setShippingOrigin(value);

    if (!value) {
      return;
    }

    updateShipment({
      variables: {
        id: inventoryTransfer.shipment.id,
        input: { originId: value?.id ?? null },
      },
    });
  }

  function handleUpdateShippingDestination(value) {
    setShippingDestination(value);

    updateShipment({
      variables: {
        id: inventoryTransfer.shipment.id,
        input: { destinationId: value?.id ?? null },
      },
    });
  }

  function handleStatusUpdate() {
    return updateInventoryTransferStatus({
      variables: {
        id: inventoryTransfer.id,
        input: {
          event: statusAction.event,
        },
      },
    }).then(({ data }) => {
      setStatus(data.updateInventoryTransferStatus.status);
      enqueueSnackbar('Inventory Transfer Updated', { variant: 'success' });
    });
  }

  const statusAction = actionStatuses[status];

  const canEdit = status === 'DRAFT' || status === 'SUBMITTED';

  const [addPalletTagToTransferItem] = useMutation(ScanningApi.AddPalletTagToInventoryTransfer);

  const handleAddToTransfer = useCallback(
    async ({ palletTag, unitsTransferred, existingTransferItem }) => {
      const { data } = await addPalletTagToTransferItem({
        variables: {
          inventoryTransferId: inventoryTransfer.id,
          input: {
            palletTagId: palletTag.id,
            lotId: palletTag.lot.id,
            quantity: unitsTransferred,
          },
        },
      });

      if (!data) {
        return;
      }

      const { addPalletTagToInventoryTransfer: palletTagWithTransferItem } = data;

      const { lotTransferItem, ...palletTagResult } = palletTagWithTransferItem;

      if (existingTransferItem) {
        setTransferRows((rows) => {
          return rows.map((row) => {
            if (row.id === existingTransferItem.id) {
              const rowPalletTags = row.palletTags ?? row.palletTagsOnTransferItem;
              const dedupePalletTags = rowPalletTags.filter((tagOnItem) => tagOnItem.palletTag.id !== palletTag.id);

              return {
                ...row,
                palletTags: [...dedupePalletTags, palletTagResult],
                unitsTransferred: lotTransferItem.unitsTransferred,
              };
            }

            return row;
          });
        });

        return;
      }

      setTransferRows((rows) => {
        return [
          ...rows,
          {
            ...lotTransferItem,
            palletTags: [palletTagResult],
          },
        ];
      });
    },
    [addPalletTagToTransferItem, inventoryTransfer.id]
  );

  return (
    <>
      <TransferScanner
        handleAddToOrder={handleAddToTransfer}
        transferItems={transferRows}
        warehouseId={shippingOrigin?.id}
        status={status}
      />
      <SideModalContextProvider
        contextProps={{
          inventoryTransfer: makeBolReadable(inventoryTransfer),
        }}
      >
        <PageHeader pageType={PageType.InventoryTransfer} entitySlug={inventoryTransfer.slug}>
          <HeaderStatusBadge status={status} />
          <Box sx={{ ml: 'auto', display: 'flex', my: 'auto', gap: 1 }}>
            <GenerateDocumentButton
              generatable
              documentTypes={['BILL_OF_LADING']}
              generateOptions={{
                orderType: 'IT',
              }}
            />
            <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
            {statusAction && (
              <LoadingButton
                handleRequest={handleStatusUpdate}
                variant="contained"
                size="medium"
                disabled={!statusAction.event}
              >
                {statusAction.actionBtnLabel}
              </LoadingButton>
            )}
          </Box>
        </PageHeader>
        <PageBody
          sx={{
            flexDirection: 'column',
          }}
        >
          <OrderInfo
            statusStepper={
              <StatusStepper allStatuses={['DRAFT', 'SUBMITTED', 'SHIPPED', 'CLOSED']} currentStatus={status} />
            }
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <MainCard>
              <FormGrid>
                <FormControl>
                  <Autocomplete
                    value={contact}
                    label="Contact"
                    getOptionLabel={(option) => getFullName(option.user)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, value) => handleUpdateContact(value)}
                    options={memberships}
                    disabled={!canEdit}
                  />
                </FormControl>
                <FormControl>
                  <AddressAutocomplete
                    fieldLabel="Ship From"
                    addressBook={places.filter((place) => place.id !== shippingDestination?.id)}
                    onChange={handleUpdateShippingOrigin}
                    selectedAddress={shippingOrigin}
                    disabled={!canEdit}
                  />
                </FormControl>
                <FormControl>
                  <AddressAutocomplete
                    fieldLabel="Ship To"
                    addressBook={places.filter((place) => place.id !== shippingOrigin?.id)}
                    onChange={handleUpdateShippingDestination}
                    selectedAddress={shippingDestination}
                    disabled={!canEdit}
                  />
                </FormControl>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <FormControl
                    sx={{
                      flex: 0.5,
                    }}
                  >
                    <TextField
                      label="Min Temp"
                      type="number"
                      value={temperatureMin}
                      error={temperatureMin > temperatureMax}
                      onChange={handleUpdateTempMin}
                      disabled={!canEdit}
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      flex: 0.5,
                    }}
                  >
                    <TextField
                      label="Max Temp"
                      type="number"
                      value={temperatureMax}
                      error={temperatureMin > temperatureMax}
                      onChange={handleUpdateTempMax}
                      disabled={!canEdit}
                    />
                  </FormControl>
                </Box>

                <QuickDatePicker
                  label="Ship Date"
                  value={shipDate}
                  onChange={(date) => {
                    handleUpdateShipDate(date);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  disabled={!canEdit}
                />

                <QuickDatePicker
                  label="Delivery Date"
                  value={deliveryDate}
                  onChange={(date) => {
                    handleUpdateDeliveryDate(date);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  disabled={!canEdit}
                />
              </FormGrid>
              <InventoryTransferGrid
                transferRows={transferRows}
                setTransferRows={setTransferRows}
                fromWarehouseId={shippingOrigin?.id}
                toWarehouseId={shippingDestination?.id}
                inventoryTransferId={inventoryTransfer.id}
                canEdit={canEdit && !!shippingOrigin}
              />
              <ExpensesGrid
                expenses={expenses}
                setExpenseRows={setExpenses}
                inventoryTransferId={inventoryTransfer.id}
                canEdit={canEdit}
                columnVisibilityModel={{
                  accountLevel: false,
                }}
                defaultValues={{ accountLevel: 'ACCOUNTS_PAYABLE' }}
              />
            </MainCard>
            <Box>
              <SideModal />
            </Box>
          </Box>
        </PageBody>
      </SideModalContextProvider>
    </>
  );
};
