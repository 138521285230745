import { Box, DialogContent, Typography } from '@mui/material';

export default function ScannerInfo({ info }: { info: string[] }) {
  return (
    <DialogContent>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        {info.map((t, i) => (
          <Typography color="text.secondary" key={i}>
            {t}
          </Typography>
        ))}
      </Box>
    </DialogContent>
  );
}
