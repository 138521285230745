import { useEffect } from 'react';

import { Box } from '@mui/material';
import bwipjs from 'bwip-js';
import Mustache from 'mustache';
import { Barcode as BarcodeUtils } from 'shared/core';
import { fromUTC } from 'shared/utils/DateTime';

import useOrgInfo, { OrgInfo } from 'src/components/containers/organization/useOrgInfo';

type BarcodeProps = {
  name: string;
  textTemplate?: string;
  tagNumber: string;
  scale?: number;
  textsize?: number;
  barCodeHeight?: number;
  height?: string;
  inkspread?: number;
  width?: number;
  marginTop?: string;
  marginBottom?: string;

  // Barcode type props
  tagType?: 'upc' | 'gs1' | 'sscc';
  packDate?: string;
  gtinCode?: string;
  numberSystem?: string;
  lotNumber?: string;
  productCode?: string;
  tagId?: string;
};

export default function Barcode({
  tagType,
  name,
  packDate,
  gtinCode,
  textTemplate,
  lotNumber,
  numberSystem,
  productCode,
  tagNumber,
  scale = 3,
  textsize = 5.5,
  barCodeHeight = 5,
  height = '100%',
  inkspread = 0.5,
  width = 100,
  marginTop = '10px',
  marginBottom,
  tagId,
}: BarcodeProps) {
  const barcodeId = `${name}-${tagNumber}-${tagId}-bc`;
  const orgInfo = useOrgInfo();

  useEffect(() => {
    try {
      const barcodeProps = getBarcodeProps(tagNumber, {
        type: tagType,
        textTemplate,
        orgInfo,
        packDate,
        gtinCode,
        numberSystem,
        lotNumber,
        productCode,
      });

      bwipjs.toCanvas(barcodeId, {
        scale,
        textsize,
        inkspread,

        height: barCodeHeight,
        ...barcodeProps,
      });
    } catch (e) {
      console.error(`Failed to render barcode: ${e.message}`);
    }
  }, [
    barcodeId,
    tagNumber,
    barCodeHeight,
    scale,
    textsize,
    inkspread,
    textTemplate,
    tagType,
    orgInfo,
    gtinCode,
    packDate,
    numberSystem,
    lotNumber,
    productCode,
  ]);

  return (
    <Box display="flex" width="100%" flexGrow={1} justifyContent="center">
      <canvas style={{ width, height, marginTop, marginBottom }} id={barcodeId} />
    </Box>
  );
}

const barcodeTypes = {
  upc: {
    bcid: 'upca',
    requiresGS1: true,
    generate: ({ gs1CompanyPrefix, numberSystem, productCode }) => {
      const upcCode = `${numberSystem}${gs1CompanyPrefix}${productCode?.substring(0, 5)}`;

      const checkDigit = BarcodeUtils.upcCheckDigit(upcCode);

      return `${upcCode}${checkDigit}`;
    },
  },
  gs1: {
    bcid: 'gs1-128',
    requiresGS1: false,
    generate: ({ gtinCode, packDate, lotNumber }) => {
      const packDateFmt = packDate ? fromUTC(packDate)?.toFormat('yyMMdd') : '';
      const packDateStr = packDateFmt ? `(13)${packDateFmt}` : '';

      return `(01)${gtinCode}${packDateStr}(10)${lotNumber}`;
    },
    props: {
      includecheck: true,
      includecheckintext: true,
      textxalign: 'center',
    },
  },
  sscc18: {
    bcid: 'sscc18',
    requiresGS1: true,
    generate: ({ gs1CompanyPrefix, tagNumber }) => {
      return `(00) 0 ${gs1CompanyPrefix.padStart(7, '0')} ${tagNumber.padStart(9, '0')}`;
    },
    props: {
      includecheck: true,
      includecheckintext: true,
      textxalign: 'center',
    },
  },
};

function getBarcodeProps(
  tagNumber: string,
  options: {
    type?: 'upc' | 'gs1' | 'sscc';
    numberSystem?: string;
    textTemplate?: string;
    gtinCode?: string;
    packDate?: string;
    orgInfo: OrgInfo;
    lotNumber?: string;
    productCode?: string;
  }
) {
  const { type, textTemplate } = options;
  const tagType = barcodeTypes[type];

  if (!tagType) {
    return {
      text: tagNumber,
      includetext: !!textTemplate,
      alttext: textTemplate ? Mustache.render(textTemplate, { tagNumber }) : '',
      bcid: 'code128',
      textxalign: 'center',
    };
  }

  const companyPrefix = options.orgInfo?.gs1CompanyPrefix;

  if (tagType.requiresGS1 && !companyPrefix) {
    throw new Error('SET GS1 COMPANY PREFIX IN SETTINGS');
  }

  const text = tagType.generate({
    tagNumber,
    gs1CompanyPrefix: companyPrefix?.toString(),
    numberSystem: options.numberSystem,
    gtinCode: options.gtinCode,
    packDate: options.packDate,
    lotNumber: options.lotNumber,
    productCode: options.productCode,
  });

  return {
    text,
    includetext: !!text,
    bcid: tagType.bcid,
    ...tagType.props,
  };
}
