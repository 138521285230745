import { routes } from '@redwoodjs/router';
import Link from 'src/components/atoms/Link';
import {
  AccountRegisterCapabilities,
  AccountRegisterContext,
  AccountRegisterFilters,
} from 'src/pages/AccountRegisterPage/AccountRegisterContext';
import React from 'react';
import { Metric } from 'src/components/containers/Metric';
import { DateTime } from 'luxon';
import { LedgerAccountStub, SlugTuple } from 'types/graphql';
import _ from 'lodash';
import { asUTC, asUTCDate } from 'shared/utils/DateTime';
import {
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellParams,
  GridComparatorFn,
  GridFilterModel,
} from '@mui/x-data-grid-premium';
import { LedgerEntryIndex } from 'src/lib/algolia';
import { CurrencyExchange } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Entity } from 'shared/constants';
import { HierarchicalGroupingCell } from 'src/components/atoms/HierarchicalGroupingCell';
import { Box, Typography } from '@mui/material';
import { GridColObj } from 'src/components/containers/grids/DataGrid';
import { Type } from 'shared/constants/Entity';
import { dateCellFormatter, formatCurrency } from 'src/utils/grid/cell/cellFormatters';
import { GridToolbarQuickFilter } from 'src/modules/grids/toolbar/GridToolbarV2';
import { ServerGridFilter } from 'src/modules/grids/serverEnhanced/types';

export const buildRelevantEntityTuple = (slug: string, type: Entity.Type) => {
  if (!slug) {
    return null;
  }

  switch (type) {
    case 'Invoice':
      return { route: routes.invoice({ slug: slug }), label: slug };
    case 'StandardInvoice':
      return {
        route: routes.invoice({ slug: slug, type: 'standard' }),
        label: slug.match(/\D/) ? slug : `INV-${slug}`,
      };
    case 'BuySellOrderInvoice':
      return { route: routes.invoice({ slug: slug, type: 'bso' }), label: slug };
    case 'Payable':
      return { route: routes.payable({ slug: slug }), label: slug };
    case 'StandardPayable':
      return {
        route: routes.payable({ slug: slug, type: 'standard' }),
        label: slug.match(/\D/) ? slug : `PYB-${slug}`,
      };
    case 'BuySellOrderPayable':
      return { route: routes.payable({ slug: slug, type: 'bso' }), label: slug };
    case 'ConsignmentPayable':
      return { route: routes.payable({ slug: slug, type: 'consignment' }), label: slug };
    case 'Payment':
      return { route: routes.payment({ slug: slug }), label: `PYMNT-${slug}` };
    default:
      return { label: slug };
  }
};

export const buildRelevantEntityLink = (slug: string, type: Entity.Type) => {
  if (!slug) {
    return null;
  }

  const tuple = buildRelevantEntityTuple(slug, type);

  if (tuple.route === null || tuple.route === undefined) {
    return <span>{tuple.label}</span>;
  }

  return <Link sx={{ paddingBottom: 1 }} to={tuple.route} label={tuple.label} />;
};

export const toolBarDefinition = {
  searchbarLabel: 'Search by vendors, customers, invoices ...',
  useSearchbar: true,
};

export const buildAggregateStatCards = (context: AccountRegisterContext, totalCount?: number): React.ReactElement[] => {
  const cards = [];

  const totalAccountBalance = context.state.account?.ownBalance;
  const agrifulBalance = context.bankReconciliationStats ? context.bankReconciliationStats.agrifulBalance : null;
  const deltaBalance = context.bankReconciliationStats ? context.bankReconciliationStats.deltaBalance : null;
  const unreconciledBalance = context.bankReconciliationStats?.unreconciledBalance;
  if (context.state.mode === 'LISTING') {
    cards.push(
      <Metric
        label="TOTAL BALANCE"
        value={totalAccountBalance}
        format="money"
        containerSx={{ flexGrow: 1, marginLeft: 1 }}
      />
    );

    cards.push(<Metric label="TOTAL TRANSACTIONS" value={totalCount} containerSx={{ flexGrow: 1, marginLeft: 1 }} />);
  } else if (context.state.mode === 'RECONCILING_BANK') {
    cards.push(
      <Metric
        label="ENDING BALANCE DATE"
        value={context.state.endingDate}
        containerSx={{ flexGrow: 1, marginLeft: 1 }}
      />
    );
    cards.push(
      <Metric
        label="ENDING BALANCE AMOUNT"
        format="money"
        value={context.state.endingBalance}
        containerSx={{ flexGrow: 1, marginLeft: 1 }}
      />
    );
    cards.push(
      <Metric
        label="AGRIFUL BALANCE"
        format="money"
        value={agrifulBalance}
        containerSx={{ flexGrow: 1, marginLeft: 1 }}
      />
    );
    cards.push(
      <Metric
        label="DIFFERENCE"
        value={deltaBalance}
        format="money"
        status={Math.abs(deltaBalance) > 0 ? 'error' : 'success'}
        containerSx={{ flexGrow: 1, marginLeft: 1 }}
      />
    );
  } else if (context.state.mode === 'RECONCILING_ACCOUNT') {
    cards.push(
      <Metric label="STARTING DATE" value={context.filters.from} containerSx={{ flexGrow: 1, marginLeft: 1 }} />
    );
    cards.push(
      <Metric label="ENDING DATE" value={context.filters.until} containerSx={{ flexGrow: 1, marginLeft: 1 }} />
    );

    cards.push(
      <Metric
        label="RECONCILED BALANCE"
        format="money"
        value={agrifulBalance}
        containerSx={{ flexGrow: 1, marginLeft: 1 }}
      />
    );

    cards.push(
      <Metric
        label="UNRECONCILED BALANCE"
        value={unreconciledBalance}
        format="money"
        status={Math.abs(unreconciledBalance) > 0 ? 'error' : 'success'}
        containerSx={{ flexGrow: 1, marginLeft: 1 }}
      />
    );
  }

  return cards;
};

export type LedgerDepositSlipRow = {
  type: 'deposit_slip';
  reconciled: boolean;
  reconciledAt: DateTime;
  depositSlipKey: string;
  depositedAt: DateTime;
  memo: string;
  sequenceNumber: number;
  rollingBalance: number;
  entries: LedgerEntryRow[];
};

export type LedgerEntryRow = {
  type: 'entry';
  entryKey: string;
  postedAt: DateTime;
  createdAt: DateTime;
  accounts: LedgerAccountStub[];
  sources: SlugTuple[];
  memo: string;
  amount: number;
  rollingBalance: number;
  reconciled: boolean;
  reconciledAt: DateTime;
  depositSlip?: {
    depositSlipKey: string;
    depositedAt: DateTime;
    memo: string;
  };
  // When we don't actually render child ledger lines, we still need to delegate edits to the proper line
  targetLineKey?: string;
  sequenceNumber: number;
  counterParties: string[];
};

export type LedgerLineRow = {
  type: 'line';
  entryKey: string;
  lineKey: string;
  postedAt: DateTime;
  createdAt: DateTime;
  accounts: LedgerAccountStub[];
  sources: SlugTuple[];
  memo: string;
  amount: number;
  rollingBalance: number;
  reconciled: boolean;
  reconciledAt: DateTime;
  depositSlip?: {
    depositSlipKey: string;
    depositedAt: DateTime;
    memo: string;
  };
  sequenceNumber: number;
  counterParties: string[];
};
export type LedgerEntryGroupRow = LedgerEntryRow | LedgerLineRow | LedgerDepositSlipRow;

export const mapEntryGroupDepositSlipToRow = (
  entry: LedgerEntryIndex,
  currentAccountPath?: string
): LedgerDepositSlipRow | null => {
  if (!entry.entry.depositSlip) {
    return null;
  }
  const depositSlip = entry.entry.depositSlip;
  const lastLine = _.maxBy(
    entry.lines.filter((l) => !currentAccountPath || l.account.path === currentAccountPath),
    (l) => l.sequenceNumber
  );

  return {
    type: 'deposit_slip',
    reconciled: entry.entry.reconciled,
    reconciledAt: asUTC(entry.entry.reconciledAt),
    depositSlipKey: depositSlip.id.toString(),
    depositedAt: asUTC(depositSlip.depositedAt),
    memo: depositSlip.memo,
    sequenceNumber: _.max(entry.lines.map((l) => l.sequenceNumber)),
    rollingBalance: lastLine?.rollingBalance,
    entries: [mapEntryGroupEntryToRow(entry, currentAccountPath)],
  };
};

/*
  The sorting here has to be consistent with the backend; namely which timestamp we use (the deposit slip, if any; then the entry's)
  and when the timestamps match, we also use IDs to ensure stable sorting.
 */
export const ledgerEntryGroupComparator: GridComparatorFn<LedgerEntryGroupRow> = (_, __, aParams, bParams) => {
  // The sorting needs the full row value; but the filters need the cell value.
  // Since we can't get both, I've got to use the API here.
  const aRow = aParams.api.getRow(aParams.id);
  const bRow = bParams.api.getRow(bParams.id);

  return aRow.sequenceNumber < bRow.sequenceNumber ? -1 : 1;
};

export const mapEntryGroupEntryToRow = (entry: LedgerEntryIndex, currentAccountPath?: string): LedgerEntryRow => {
  /*
    If viewing transactions from a specific account, we only explode Payment related entries.
    Payment entries & no-account-POV will have no concept of lines in or out of the current account.
   */

  // For some reason GraphQl is returning an object.. instead of an array
  /* It is critical to ensure entry lines are sorted in the same order as rolling balances are computed */
  const lines = entry.lines.toSorted((a, b) => (a < b ? 1 : -1));
  const shouldExplode =
    !currentAccountPath ||
    lines.flatMap((line) => line.sources).filter((s) => s.type === Entity.Type.PAYMENT).length > 1;
  const linesInCurrentAccount = lines.filter((l) => currentAccountPath && l.account.path === currentAccountPath);
  const linesOutsideCurrentAccount = lines.filter((l) => !shouldExplode && l.account.path !== currentAccountPath);
  const sources = entry.entry.sources.concat(!shouldExplode ? lines.flatMap((l) => l.sources as SlugTuple[]) : []);
  const depositSlip = entry.entry.depositSlip;
  const targetLineKey = !shouldExplode
    ? entry.lines.find(
        (line) => line.sources.find((s) => s.type === Entity.Type.PAYMENT) && line.account.path === currentAccountPath
      )?.key
    : null;

  return {
    type: 'entry',
    entryKey: entry.entry.key,
    postedAt: asUTC(entry.entry.postedAt),
    createdAt: asUTC(entry.entry.createdAt),
    accounts: linesOutsideCurrentAccount.map((l) => l.account),
    sources: sources,
    amount: currentAccountPath
      ? linesInCurrentAccount.reduce((accumulator, line) => accumulator + line.amount, 0)
      : null,
    rollingBalance: currentAccountPath
      ? // for the purposes of rolling balances, the valid line for an entry with multiple lines in the same account is the last one
        linesInCurrentAccount.findLast(Boolean)?.rollingBalance
      : null,
    memo: entry.entry.memo,
    reconciled: entry.entry.reconciled,
    reconciledAt: asUTC(entry.entry.reconciledAt),
    depositSlip: depositSlip && {
      depositSlipKey: depositSlip.id.toString(),
      depositedAt: asUTC(depositSlip.depositedAt),
      memo: depositSlip.memo,
    },
    targetLineKey,
    sequenceNumber:
      _.max(linesInCurrentAccount.map((l) => l.sequenceNumber)) ?? _.max(lines.map((l) => l.sequenceNumber)),
    counterParties: entry.entry.counterParties,
  };
};

export const mapEntryGroupLinesToRows = (entry: LedgerEntryIndex, currentAccountPath?: string): LedgerLineRow[] => {
  /*
    If viewing transactions from a specific account, we only explode Payment related entries.
    Payment entries & no-account-POV will have no concept of lines in or out of the current account.
   */
  // For some reason GraphQl is returning an object.. instead of an array
  /* It is critical to ensure entry lines are sorted in the same order as rolling balances are computed */
  const lines = entry.lines.toSorted((a, b) => (a < b ? 1 : -1));
  const shouldExplode =
    !currentAccountPath ||
    lines?.flatMap((line) => line.sources).filter((s) => s.type === Entity.Type.PAYMENT).length > 1;
  const shouldFilterToCurrentAccount = !!currentAccountPath;
  const depositSlip = entry.entry.depositSlip;

  if (!shouldExplode) {
    return [];
  }

  const linesOutsideCurrentAccount = lines.filter((l) => currentAccountPath && l.account.path !== currentAccountPath);

  return lines
    .filter((line) => !shouldFilterToCurrentAccount || line.account.path === currentAccountPath)
    .map((line) => ({
      type: 'line',
      entryKey: entry.entry.key,
      lineKey: line.key ?? line.memo,
      postedAt: asUTC(entry.entry.postedAt),
      createdAt: asUTC(entry.entry.createdAt),
      accounts: currentAccountPath ? linesOutsideCurrentAccount.map((line) => line.account) : [line.account],
      sources: line.sources,
      amount: line.amount,
      rollingBalance: line.rollingBalance,
      memo: line.memo,
      reconciled: entry.entry.reconciled,
      reconciledAt: asUTC(entry.entry.reconciledAt),
      depositSlip: depositSlip && {
        depositSlipKey: depositSlip.id.toString(),
        depositedAt: asUTC(depositSlip.depositedAt),
        memo: depositSlip.memo,
      },
      sequenceNumber: line.sequenceNumber,
      counterParties: entry.counterParties,
    }));
};

export const mapEntryGroupsToRows = (
  entries: LedgerEntryIndex[],
  currentAccountPath?: string
): LedgerEntryGroupRow[] => {
  const results: LedgerEntryGroupRow[] = [];
  const depositSlips = new Map<string, LedgerDepositSlipRow>();
  for (const entry of entries) {
    const depositSlipRow = mapEntryGroupDepositSlipToRow(entry, currentAccountPath);
    if (depositSlipRow) {
      if (!depositSlips.has(depositSlipRow.depositSlipKey)) {
        depositSlips.set(depositSlipRow.depositSlipKey, depositSlipRow);
      } else {
        depositSlips
          .get(depositSlipRow.depositSlipKey)
          .entries.push(mapEntryGroupEntryToRow(entry, currentAccountPath));
      }
    }

    results.push(mapEntryGroupEntryToRow(entry, currentAccountPath));
    const lines = mapEntryGroupLinesToRows(entry, currentAccountPath);
    if (lines.length) {
      results.push(...lines);
    }
  }

  for (const value of depositSlips.values()) {
    results.push(value);
  }

  return results;
};

export const computeTabs = (capabilities: AccountRegisterCapabilities) =>
  capabilities.canStartBankReconciling
    ? [
        { label: 'TRANSACTIONS', icon: <CurrencyExchange />, payload: 'TRANSACTIONS' },
        { label: 'RECONCILIATIONS', icon: <CheckCircleIcon />, payload: 'RECONCILIATIONS' },
      ]
    : [{ label: 'TRANSACTIONS', icon: <CurrencyExchange />, payload: 'TRANSACTIONS' }];

export const extractFiltersFromModel = (
  current: AccountRegisterFilters,
  model: GridFilterModel
): AccountRegisterFilters => {
  const dateFilters = model.items
    .filter((item) => item.field === '__tree_data_group__')
    .map((item) => ({ operator: item.operator, value: asUTCDate(item.value) }))
    .filter((item) => item.value);

  const afterDate = _.minBy(
    [
      ...dateFilters
        .filter((f) => f.operator === 'after')
        .map((f) => ({ operator: f.operator, value: f.value.plus({ days: 1 }) })),
      ...dateFilters.filter((f) => f.operator === 'onOrAfter'),
    ],
    (f) => f.value
  );

  const beforeDate = _.minBy(
    [
      ...dateFilters
        .filter((f) => f.operator === 'before')
        .map((f) => ({ operator: f.operator, value: f.value.minus({ days: 1 }) })),
      ...dateFilters.filter((f) => f.operator === 'onOrBefore'),
    ],
    (f) => f.value
  );

  return {
    ...current,
    from: afterDate?.value,
    until: beforeDate?.value,
  };
};

// TODO - re-implement account register write features
export const processRowUpdate = async (updatedRow: LedgerEntryGroupRow, originalRow: LedgerEntryGroupRow) => {
  return originalRow;
};

export const isCellEditable = (params: GridCellParams<any, any, LedgerEntryGroupRow, any>) => {
  if (params.row.type === 'deposit_slip') {
    return false;
  }
  if (params.field === 'memo') {
    return true;
  }
  if (params.field === 'amount' && params.row.type === 'line') {
    return params.row.sources.filter((source) => source.type === 'Payment').length > 0;
  } else if (params.field === 'amount' && params.row.type === 'entry') {
    return !!params.row.targetLineKey;
  }

  return false;
};

export const groupingColDef = {
  headerName: 'Posted At',
  cellClassName: 'Agri-hierarchicalGrouping',
  valueGetter: (_value, row: LedgerEntryGroupRow) => {
    return row.sequenceNumber;
  },
  renderCell: (params) => {
    const row: LedgerEntryGroupRow = params.row;
    if (row.type === 'deposit_slip') {
      return (
        <HierarchicalGroupingCell params={params} toggle={true}>
          <Box sx={{ paddingRight: 0.5, paddingLeft: 1 }}>
            <strong>Deposit from {formatDateCellValue(row.depositedAt)}</strong>
          </Box>
        </HierarchicalGroupingCell>
      );
    }
    return (
      <HierarchicalGroupingCell params={params} toggle={true}>
        <Box sx={{ paddingRight: 0.5, paddingLeft: 1 }}>{formatDateCellValue(params.row.postedAt)}</Box>
      </HierarchicalGroupingCell>
    );
  },
  sortable: false,
  filterable: true,
  minWidth: 300,
  type: 'date',
  sortComparator: ledgerEntryGroupComparator,
};

export const isRowSelectable = ({ row }: { row: LedgerEntryGroupRow }) =>
  row.type === 'entry' && !row.depositSlip && row.amount > 0;

// TODO - re-implement account register write features
export const onRowSelectionModelChange = (model, details) => {};

const formatDateCellValue = (dateValue: DateTime | Date | number | string | null | undefined) => {
  const date = asUTCDate(dateValue);

  if (!date) {
    return '--';
  }

  return date.toFormat('MM/dd/yyyy');
};

export const getTreeDataPath = (row: LedgerEntryGroupRow) => {
  if (row.type === 'entry') {
    const depositSlipPathPart = row.depositSlip ? [row.depositSlip.depositSlipKey] : [];
    return [...depositSlipPathPart, row.entryKey];
  } else if (row.type === 'line') {
    const depositSlipPathPart = row.depositSlip ? [row.depositSlip.depositSlipKey] : [];
    return [...depositSlipPathPart, row.entryKey, row.lineKey];
  } else if (row.type === 'deposit_slip') {
    return [row.depositSlipKey];
  }
};

export const getRowId = (row) => {
  if (row.type === 'entry') {
    return row.entryKey;
  } else if (row.type === 'line') {
    return `${row.entryKey}/${row.lineKey}`;
  } else if (row.type === 'deposit_slip') {
    return row.depositSlipKey;
  }
};

export const columnDefinitions = (context: AccountRegisterContext, onIsReconciledChanged): GridColObj => ({
  __check__: {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    sortable: false,
    renderHeader: () => null,
  },
  accountPaths: {
    headerName: 'Account',
    type: 'scalar_string',
    flex: 1,
    minWidth: 200,
    filterable: false,
    sortable: false,
    filterOperators: getGridStringOperators(),
    valueGetter: (_value, row: LedgerEntryGroupRow) => row?.accounts?.flatMap((account) => account.name),
    // @ts-ignore
    renderCell: ({ row }: { row: LedgerEntryGroupRow }) => {
      if (row.type === 'line' || row.type === 'entry') {
        const uniqueAccounts = _.uniqBy(row.accounts, (account) => account.path);

        return (
          <Box display="flex" flexDirection="column">
            {...uniqueAccounts.map((account) => (
              <Link
                to={routes.accountRegister({ path: account.path })}
                label={account.name}
                alignItems="center"
                sx={{ paddingBottom: 1 }}
              />
            ))}
          </Box>
        );
      }
    },
  },
  sources: {
    headerName: 'Sources',
    type: 'scalar_string',
    flex: 1,
    minWidth: 150,
    sortable: false,
    // @ts-ignore
    renderCell: ({ row }: { row: LedgerEntryGroupRow }) => {
      if (row.type === 'line' || row.type === 'entry') {
        const uniqueSources = _.uniqBy(row.sources, (source) => `${source.slug}-${source.type}`);

        return (
          <Box display="flex" flexDirection="column">
            {...uniqueSources.map(({ slug, type }) => buildRelevantEntityLink(slug, type as Type))}
          </Box>
        );
      }
    },
    filterOperators: [getGridSingleSelectOperators().find((o) => o.value === 'isAnyOf')],
  },
  counterParties: {
    headerName: 'Counterparties',
    type: 'scalar_string',
    flex: 1,
    minWidth: 200,
    filterable: true,
    sortable: false,
    // @ts-ignore
    renderCell: ({ row }: { row: LedgerEntryGroupRow }) => {
      if (row.type === 'line' || row.type === 'entry') {
        const counterParties = _.uniq(row.counterParties);
        if (counterParties.length <= 2) {
          return (
            <ul>
              {counterParties.map((party) => (
                <li>{party}</li>
              ))}
            </ul>
          );
        } else {
          return (
            <ul title={counterParties.join('\n')}>
              <li>{counterParties[0]}</li>
              <li>...</li>
            </ul>
          );
        }
      }
    },
  },
  memo: {
    headerName: 'Memo',
    type: 'string',
    flex: 1,
    minWidth: 150,
    filterable: true,
    editable: false,
    sortable: false,
    valueGetter: (_value, row: LedgerEntryGroupRow) => row.memo,
  },
  amount: {
    headerName: 'Amount',
    type: 'number',
    flex: 1,
    minWidth: 150,
    maxWidth: 500,
    filterable: false,
    sortable: false,
    editable: false,
    filterOperators: getGridNumericOperators(),
    serverFilterValueTransform: {
      from: (value: number) => value / 100,
      to: (value: number) => value * 100,
    },
    valueFormatter: (value) => formatCurrency({ value: value / 100.0 }),
    valueGetter: (_value, row, _column, apiRef) => {
      if (row.type === 'line' || row.type === 'entry') {
        return row.amount;
      } else if (row.type === 'deposit_slip') {
        return row.entries.reduce((sum, e) => sum + e.amount, 0);
      }
    },
    cellClassName: ({ value }: GridCellParams<LedgerLineRow | LedgerEntryRow>) =>
      `Agri-hierarchicalGrouping ${(value as number) < 0 ? 'Agri-negativeAmount' : ''}`,
    renderCell: (params) => (
      <HierarchicalGroupingCell params={params} toggle={false}>
        <Typography fontSize="14px" sx={{ paddingLeft: 0.5 }}>
          {params.formattedValue}
        </Typography>
      </HierarchicalGroupingCell>
    ),
  },
  rollingBalance: {
    headerName: 'Rolling Balance',
    type: 'number',
    minWidth: 150,
    maxWidth: 150,
    resizable: false,
    filterable: false,
    sortable: false,
    filterOperators: getGridNumericOperators(),
    serverFilterValueTransform: {
      from: (value: number) => value / 100,
      to: (value: number) => value * 100,
    },
    valueFormatter: (value) => formatCurrency({ value: value }),
    valueGetter: (_value, row: LedgerEntryGroupRow) =>
      (context.filters?.path || context.state?.account?.path) && row.rollingBalance
        ? Number.parseInt(row.rollingBalance.toString()) / 100
        : null,
    cellClassName: ({ row }: GridCellParams<LedgerEntryRow | LedgerLineRow>) =>
      row.rollingBalance < 0 ? 'Agri-negativeAmount' : null,
  },
  reconciledAt: {
    headerName: 'Reconciled At',
    type: 'date',
    minWidth: 150,
    maxWidth: 150,
    resizable: false,
    filterable: false,
    sortable: false,
    filterOperators: getGridDateOperators(false),
    valueGetter: (_value, row: LedgerEntryGroupRow) => row?.reconciledAt?.startOf('day').toJSDate(),
    valueFormatter: dateCellFormatter,
  },
  reconciled: {
    headerName: 'Reconciled',
    type: 'boolean',
    width: 150,
    maxWidth: 150,
    resizable: false,
    filterable: true,
    sortable: false,
    filterOperators: getGridBooleanOperators(),
    // @ts-ignore
    renderCell: ({ row }: { row: LedgerEntryGroupRow }) => {
      return (
        <>
          {context.capabilities.canReconcileLedgerLines && (
            <IsReconciledCheckbox
              isReconciled={!!row.reconciled}
              onClick={async () => await onIsReconciledChanged(row.entryKey, !row.reconciled)}
            />
          )}
          {!context.capabilities.canReconcileLedgerLines && <IsReconciledCheckbox isReconciled={!!row.reconciled} />}
        </>
      );
    },
  },
});

export const filterModelToDataGridModelItems = (filterModel: AccountRegisterFilters): ServerGridFilter[] => {
  const items: ServerGridFilter[] = [];
  if (filterModel.path) {
    items.push({
      id: 'account_path_from_url',
      field: 'accountPaths',
      where: [{ has: filterModel.path }],
    });
  }

  return items;
};

const IsReconciledCheckbox = ({ isReconciled, onClick }: { isReconciled: boolean; onClick?: () => void }) => {
  return (
    <>
      {!isReconciled && (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
          style={{ cursor: onClick ? 'pointer' : 'not-allowed' }}
        >
          <path
            d="M17.8253 10.3167L12.3337 15.8084L9.34199 12.8251L8.16699 14.0001L12.3337 18.1667L19.0003 11.5001L17.8253 10.3167ZM14.0003 5.66675C9.40033 5.66675 5.66699 9.40008 5.66699 14.0001C5.66699 18.6001 9.40033 22.3334 14.0003 22.3334C18.6003 22.3334 22.3337 18.6001 22.3337 14.0001C22.3337 9.40008 18.6003 5.66675 14.0003 5.66675ZM14.0003 20.6667C10.317 20.6667 7.33366 17.6834 7.33366 14.0001C7.33366 10.3167 10.317 7.33341 14.0003 7.33341C17.6837 7.33341 20.667 10.3167 20.667 14.0001C20.667 17.6834 17.6837 20.6667 14.0003 20.6667Z"
            fill="#28310B"
            fillOpacity="0.54"
          />
        </svg>
      )}
      {isReconciled && (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
          style={{ cursor: onClick ? 'pointer' : 'not-allowed' }}
        >
          <path
            d="M14.0003 5.66675C9.40033 5.66675 5.66699 9.40008 5.66699 14.0001C5.66699 18.6001 9.40033 22.3334 14.0003 22.3334C18.6003 22.3334 22.3337 18.6001 22.3337 14.0001C22.3337 9.40008 18.6003 5.66675 14.0003 5.66675ZM12.3337 18.1667L8.16699 14.0001L9.34199 12.8251L12.3337 15.8084L18.6587 9.48341L19.8337 10.6667L12.3337 18.1667Z"
            fill="#28310B"
            fillOpacity="0.54"
          />
        </svg>
      )}
    </>
  );
};

export const gridQuickFilters: GridToolbarQuickFilter[] = [];
