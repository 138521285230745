import { useCallback, useEffect, useMemo, useState } from 'react';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Box, Button } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { GridRowId, useGridApiRef } from '@mui/x-data-grid-premium';
import { asUTC } from 'shared/utils/DateTime';

import { CreateDepositSlipModal } from 'src/components/containers/modals/CreateDepositSlipModal';
import TabbedMainCard from 'src/components/containers/TabbedMainCard';
import useGridExport from 'src/hooks/useGridExport';
import { LedgerEntryIndex } from 'src/lib/algolia';
import { accounts } from 'src/lib/theme';
import { useLedgerEntriesGrid } from 'src/modules/accounting/register/api/grid';
import { GridFilterController } from 'src/modules/accounting/register/GridFilterController';
import ServerEnhancedDataGrid from 'src/modules/grids/serverEnhanced/ServerEnhancedDataGrid';
import { useServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';
import GridToolbarV2 from 'src/modules/grids/toolbar/GridToolbarV2';
import { AccountReconciliationsTab } from 'src/pages/AccountRegisterPage/AccountReconciliationsTab';
import { AccountRegisterContext, AccountRegisterEvents } from 'src/pages/AccountRegisterPage/AccountRegisterContext';

import {
  buildAggregateStatCards,
  columnDefinitions,
  computeTabs,
  filterModelToDataGridModelItems,
  getRowId,
  getTreeDataPath,
  gridQuickFilters,
  groupingColDef,
  isCellEditable,
  isRowSelectable,
  mapEntryGroupsToRows,
  processRowUpdate,
} from './constants';

type LedgerEntriesProps = {
  context: AccountRegisterContext;
} & AccountRegisterEvents;

const STORAGE_KEY = 'ledgerEntries';

export const LedgerEntriesGrid = ({
  context,
  onIsReconciledChanged,
  onDepositSlipCreated,
  onFiltersChanged,
  onAnnotationsReceived,
}: LedgerEntriesProps) => {
  const apiRef = useGridApiRef();

  const gridState = useServerEnhancedGridState(STORAGE_KEY, gridQuickFilters);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState(null);
  const { rows, loading, pageInfo, totalCount, fetchAllForExport } = useLedgerEntriesGrid(gridState);

  // For now, pretend we're still coming from Algolia
  const indexRows: LedgerEntryIndex[] = useMemo(
    () =>
      rows?.length > 0
        ? rows.map((row) => ({
            ...row,
            entry: {
              ...row.entry,
              postedAt: asUTC(row.entry.postedAt).toJSDate().getTime(),
            },
            objectID: '',
            accountPaths: row.lines.flatMap((line) => line.account.path),
          }))
        : [],
    [rows]
  );

  useGridExport(apiRef, 'ledger-entries');

  const [showDepositModal, setShowDepositModal] = useState<boolean>(false);
  const [selectedEntryKeys, setSelectedEntryKeys] = useState<string[]>([]);

  const effectiveRows = useMemo(
    () =>
      mapEntryGroupsToRows(indexRows ?? [], context.state.account?.path.join('/') ?? context.filters?.path).sort(
        (a, b) => (a.sequenceNumber < b.sequenceNumber ? 1 : -1)
      ),
    [context.state.account?.path.join('/'), indexRows]
  );

  const toolbar = useMemo(
    () => (
      <>
        <GridToolbarV2 apiRef={apiRef} enhancedGridState={gridState} />
        {selectedEntryKeys.length > 0 && (
          <Box display="flex" flexDirection="row" justifyContent="end" sx={{ paddingBottom: 2.5 }}>
            <Button sx={{ color: '#667239' }} onClick={() => setShowDepositModal(true)}>
              <MonetizationOnIcon sx={{ marginRight: 1, color: '#667239' }} /> Create Deposit Slip
            </Button>
          </Box>
        )}
      </>
    ),
    [apiRef, gridState, selectedEntryKeys]
  );

  const innerOnIsReconciledChanged = useCallback(
    async (ledgerEntryKey: string, isReconciled: boolean) => {
      const preUpdateRow = effectiveRows.find((row) => {
        if (row.type === 'entry' && row.entryKey === ledgerEntryKey) {
          return row;
        }
      });

      apiRef.current.updateRows([
        {
          ...preUpdateRow,
          reconciled: isReconciled,
        },
      ]);
      onIsReconciledChanged(ledgerEntryKey, isReconciled).catch((error) => {
        apiRef.current.updateRows([
          {
            ...preUpdateRow,
          },
        ]);
      });
    },
    [onIsReconciledChanged]
  );

  useEffect(() => {
    if (rows && rows.length) {
      //onAnnotationsReceived(rows);
    }
  }, [rows]);

  const tabs = computeTabs(context.capabilities);

  const columns = useMemo(
    () => columnDefinitions(context, innerOnIsReconciledChanged),
    [
      context.filters?.path,
      context.state?.account?.path,
      context.capabilities.canReconcileLedgerLines,
      innerOnIsReconciledChanged,
    ]
  );

  const grid = (
    <ServerEnhancedDataGrid
      pageInfo={pageInfo}
      totalCount={totalCount}
      loading={loading}
      enhancedGridState={gridState}
      treeData
      getTreeDataPath={getTreeDataPath}
      getRowId={getRowId}
      getRowHeight={() => 'auto'}
      rows={effectiveRows}
      columns={columns}
      storageKey="ledger-entries"
      apiRef={apiRef}
      sx={accounts.dataGridStyling}
      getRowClassName={(params) => (params.row.rollingBalance < 0 ? 'Agri-negativeBalance' : null)}
      isCellEditable={isCellEditable}
      processRowUpdate={processRowUpdate}
      defaultGroupingExpansionDepth={2}
      rowGroupingColumnMode="single"
      disableChildrenFiltering={true}
      groupingColDef={groupingColDef}
      checkboxSelection={context.capabilities.canStartBankReconciling ? true : undefined}
      isRowSelectable={(params) => {
        return context.capabilities.canStartBankReconciling && isRowSelectable(params);
      }}
      onRowSelectionModelChange={(model, details) =>
        setSelectedEntryKeys(model.map((rowId: GridRowId) => rowId.toString()))
      }
      rowSelectionModel={selectedEntryKeys}
      slots={{
        toolbar,
      }}
    />
  );

  return (
    <>
      <Box display="flex" flexDirection="row" sx={{ marginBottom: 2.5 }}>
        {...buildAggregateStatCards(context, totalCount)}
      </Box>

      <TabbedMainCard
        tabs={tabs}
        noPadding
        sx={{ padding: 3 }}
        render={(payload) => (
          <ThemeProvider theme={accounts.dataGridTheme}>
            {payload === 'TRANSACTIONS' && grid}
            {payload === 'RECONCILIATIONS' && context.state.account && (
              <AccountReconciliationsTab accountPath={context.state.account.path.join('/')} />
            )}
          </ThemeProvider>
        )}
      ></TabbedMainCard>
      <CreateDepositSlipModal
        open={showDepositModal}
        onClose={() => setShowDepositModal(false)}
        onSubmitDeposit={({ depositDate, memo, depositedByMemberId }) =>
          onDepositSlipCreated(depositDate, memo, depositedByMemberId, selectedEntryKeys)
        }
        account={{
          name: context.state?.account?.name,
          path: context.state?.account?.path.join('/'),
        }}
        entries={indexRows.filter((tuple) => selectedEntryKeys.includes(tuple.entry.key))}
      />
      {apiRef.current && (
        <GridFilterController
          gridState={gridState}
          filterModel={context.filters}
          filterModelToDataGridModelItems={filterModelToDataGridModelItems}
        />
      )}
    </>
  );
};
