import { Place } from 'types/graphql';

/**
 * Formats a Place entity into a user-friendly address string
 * @param place - the place to format
 * @returns a string with the formatted address
 *
 * @example
 * const place = findPlace(); // some query to get a Place
 * const formattedAddress = formatAddress(place); // '123 Main Street, Apt 3, New York, NY 10001, USA'
 */
const formatAddress = (place: Place) => {
  if (!place) {
    return '';
  }

  const { streetAddress1, streetAddress2, city, state, postalCode, addressCountry } = place;

  const parts = [];
  streetAddress1 && parts.push(streetAddress1);
  streetAddress2 && parts.push(streetAddress2);
  city && parts.push(city);

  // State and zip code shouldn't have a comma in between
  const stateZipPart = [];
  state && stateZipPart.push(state);
  postalCode && stateZipPart.push(postalCode);
  if (stateZipPart.length) {
    parts.push(stateZipPart.join(' '));
  }

  addressCountry && parts.push(addressCountry);

  return parts
    .map((x) => x.trim())
    .filter((x) => x.length > 0)
    .join(', ');
};

export default formatAddress;
