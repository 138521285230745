import { useEffect, useState } from 'react';

import { useEditor } from '@grapesjs/react';

import MaterialSymbol from 'src/components/atoms/MaterialSymbol';

import { BTN_CLS, MAIN_BORDER_COLOR, cx } from './common';

interface CommandButton {
  id: string;
  iconName: string;
  options?: Record<string, any>;
  disabled?: () => boolean;
}

export default function TopbarButtons({ className }: React.HTMLAttributes<HTMLDivElement>) {
  const editor = useEditor();
  const [, setUpdateCounter] = useState(0);
  const { UndoManager, Commands } = editor;
  const cmdButtons: CommandButton[] = [
    {
      id: 'reload-changes',
      iconName: 'refresh',
    },
    {
      id: 'open-document',
      iconName: 'folder_open',
    },
    {
      id: 'save-changes',
      iconName: 'save',
    },
    {
      id: 'core:component-outline',
      iconName: 'border_clear',
    },
    {
      id: 'open-preview',
      iconName: 'preview',
    },
    {
      id: 'core:fullscreen',
      iconName: 'fullscreen',
      options: { target: '#root' },
    },
    {
      id: 'core:open-code',
      iconName: 'code',
    },
    {
      id: 'core:undo',
      iconName: 'undo',
      disabled: () => !UndoManager.hasUndo(),
    },
    {
      id: 'core:redo',
      iconName: 'redo',
      disabled: () => !UndoManager.hasRedo(),
    },
  ];

  useEffect(() => {
    const cmdEvent = 'run stop';
    const updateEvent = 'update';
    const updateCounter = () => setUpdateCounter((value) => value + 1);
    const onCommand = (id: string) => {
      cmdButtons.find((btn) => btn.id === id) && updateCounter();
    };
    editor.on(cmdEvent, onCommand);
    editor.on(updateEvent, updateCounter);

    return () => {
      editor.off(cmdEvent, onCommand);
      editor.off(updateEvent, updateCounter);
    };
  }, []);

  return (
    <div className={cx('flex gap-3', className)}>
      {cmdButtons.map(({ id, iconName, disabled, options = {} }) => (
        <button
          key={id}
          type="button"
          className={cx(
            BTN_CLS,
            MAIN_BORDER_COLOR,
            Commands.isActive(id) && 'text-sky-300',
            disabled?.() && 'opacity-50',
            'flex width[42px] height[34px] justify-center items-center'
          )}
          onClick={() => (Commands.isActive(id) ? Commands.stop(id) : Commands.run(id, options))}
          disabled={disabled?.()}
        >
          <MaterialSymbol name={iconName} />
        </button>
      ))}
    </div>
  );
}
