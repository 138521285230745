import { createMachine } from 'xstate';
import { assign, raise } from 'xstate/lib/actions';

export enum ScanStatus {
  IDLE = 'idle',
  AWAITING = 'awaiting',

  FINDING_PALLET_TAG = 'finding_pallet_tag',
  ERROR_NO_TAG_MATCH = 'error_no_tag_match',
  PALLET_TAG_FOUND = 'pallet_tag_found',

  NO_WAREHOUSE = 'no_warehouse',
  ADD_TRANSFER_ITEM = 'add_transfer_item',
}

type TransferItem = {
  lot: {
    id: number;
    product: {
      id: number;
      description: string;
    };
  };
  unitsTransferred?: number;
};

type TransferContext = {
  transferItems: Array<TransferItem>;
  existingTransferItem: TransferItem;
  warehouseId: number;
  palletTag?: {
    id: number;
    tagNumber: string;
    lot: {
      id: number;
      product: {
        id: number;
        description: string;
      };
    };
  };
  unitsTransferred?: number;
  status: 'DRAFT' | 'SUBMITTED' | 'SHIPPED' | 'CLOSED'; // InventoryTransferStatus type isn't generated?
};

const machine = createMachine<TransferContext>(
  {
    id: 'transferScanner',
    initial: ScanStatus.IDLE,
    strict: true,
    on: {
      CANCEL: ScanStatus.IDLE,
      UPDATE_CONTEXT: {
        actions: 'UPDATE_CONTEXT',
      },
      UPDATE_STATE: {
        actions: 'UPDATE_STATE',
      },
      SCAN: [
        {
          target: ScanStatus.NO_WAREHOUSE,
          cond: 'noWarehouse',
        },
        {
          target: ScanStatus.FINDING_PALLET_TAG,
          cond: 'canScanInStatus',
        },
        {
          target: ScanStatus.IDLE,
        },
      ],
    },
    states: {
      [ScanStatus.IDLE]: {},
      [ScanStatus.AWAITING]: {},
      [ScanStatus.FINDING_PALLET_TAG]: {
        invoke: {
          src: 'lookupPalletTag',
          onDone: [
            {
              target: ScanStatus.ERROR_NO_TAG_MATCH,
              cond: 'palletTagNotFound',
            },
            {
              target: ScanStatus.PALLET_TAG_FOUND,
              actions: assign({
                palletTag: (_, event) => event.data,
              }),
            },
          ],
        },
      },
      [ScanStatus.PALLET_TAG_FOUND]: {
        always: [
          {
            target: ScanStatus.ADD_TRANSFER_ITEM,
            actions: assign({
              existingTransferItem: ({ transferItems, palletTag }) => {
                return transferItems.find((transferItem) => {
                  return transferItem.lot?.id === palletTag.lot.id;
                });
              },
            }),
          },
        ],
      },
      [ScanStatus.ERROR_NO_TAG_MATCH]: {},
      [ScanStatus.ADD_TRANSFER_ITEM]: {
        on: {
          SCAN: { actions: raise('CONFIRM') },
          CONFIRM: {
            target: ScanStatus.AWAITING,
            actions: 'handleAddToOrder',
          },
        },
      },
      [ScanStatus.NO_WAREHOUSE]: {},
    },
  },
  {
    guards: {
      canScanInStatus: (context) => ['DRAFT', 'SUBMITTED'].includes(context.status),
      palletTagNotFound: (_, event) => !event.data,
      noWarehouse: (context) => !context.warehouseId,
    },
    actions: {
      UPDATE_CONTEXT: (context, event) => {
        context.transferItems = event.transferItems;
        context.warehouseId = event.warehouseId;
        context.status = event.status;
      },
      UPDATE_STATE: (context, event) => {
        context.unitsTransferred = event.unitsTransferred;
      },
    },
  }
);

export default {
  machine,
  ScanStatus,
};
