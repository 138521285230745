import currency from 'currency.js';

/**
 * The USD (US Dollar) class is a simple wrapper around currency.js to enforce usage of cent-based values. Only USD
 * is supported. All arithmetic operations should use cent-based values.
 *
 * See currency.js: https://currency.js.org/
 *
 * @param value
 * @example
 * const money = USD.fromCents(100).add(50);
 * money.string;   // returns "$1.50"
 * money.dollars  // returns 1.5
 * money.cents;   // returns 150
 */
export class USD {
  private currency: currency;

  private constructor(value: number) {
    this.currency = currency(value, { fromCents: true, precision: 2 });
  }

  /**
   * Create a USD instance from cents.
   *
   * @example
   * const money = USD.fromCents(100);
   * money.string;   // returns "$1.00"
   */
  public static fromCents(valueInCents: number) {
    return new USD(valueInCents);
  }

  /**
   * Create a USD instance from dollars.
   *
   * @example
   * const money = USD.fromDollars(1);
   * money.string;   // returns "$1.00"
   */
  public static fromDollars(valueInDollars: number) {
    const valueInCents = currency(valueInDollars).intValue;
    return new USD(valueInCents);
  }

  /**
   * Outputs USD in dollars.
   *
   * @example
   * const money = USD.fromCents(100)
   * const dollars = money.dollars // returns 1
   */
  get dollars(): number {
    return this.currency.value;
  }

  /**
   * Outputs USD in cents.
   *
   * @example
   * const money = USD.fromDollar(100)
   * const cents = money.cents // returns 10000
   */
  get cents(): number {
    return this.currency.intValue;
  }

  /**
   * Outputs USD in a human-friendly string.
   *
   * @example
   * const money = USD.fromDollar(100)
   * const string = money.string // returns "$1.00"
   */
  get string(): string {
    return this.currency.format();
  }

  private getAmountAsCents(value: number | USD) {
    return value instanceof USD ? value.cents : value;
  }

  public add(amountInCents: number | USD): USD {
    const result = this.currency.add(this.getAmountAsCents(amountInCents));
    return new USD(result.intValue);
  }

  public subtract(amountInCents: number | USD): USD {
    const result = this.currency.subtract(this.getAmountAsCents(amountInCents));
    return new USD(result.intValue);
  }

  public multiply(value: number): USD {
    const result = this.currency.multiply(value);
    return new USD(result.intValue);
  }

  public divide(value: number): USD {
    const result = this.currency.divide(value);
    return new USD(result.intValue);
  }

  public distribute(numParts: number): USD[] {
    const distrubutions = this.currency.distribute(numParts);
    return distrubutions.map((distribution) => new USD(distribution.intValue));
  }
}
