import { ApolloClient } from '@apollo/client';

import { useQuery } from '@redwoodjs/web';

import { GridToolbarQuickFilter } from '../GridToolbarV2';

const GET_DISTINCT_VALUES = gql`
  query DistinctValues($input: DistinctValuesInput!) {
    distinctValues(input: $input)
  }
`;

// This query calls custom SELECT DISTINCT queries for a given key
// to add a new key see `api/src/services/distinctValues/distinctValues.ts`

export async function getDistinctValues(client: ApolloClient<object>, key: string) {
  const result = await client.query({
    query: GET_DISTINCT_VALUES,
    variables: { input: { key } },
  });

  return result.data.distinctValues;
}

const GET_QUICK_FILTER_COUNTS = gql`
  query QuickFilterCounts($input: QuickFilterCountInput!) {
    quickFilterCounts(input: $input)
  }
`;

export function useQuickFilterCounts(modelName?: string, quickFilters?: GridToolbarQuickFilter[]) {
  const { data } = useQuery(GET_QUICK_FILTER_COUNTS, {
    variables: { input: { modelName, quickFilters } },
    skip: !modelName || !quickFilters?.length,
  });

  return data?.quickFilterCounts ?? {};
}
