import { DateTime } from 'luxon';

import { SalesTerms } from '../types';

export const computePaymentDueDate = (
  invoiceDate: Date | string | null,
  salesTerms: SalesTerms | string
): Date | null => {
  if (!invoiceDate) {
    return null;
  }

  // Ensure the invoiceDate is parsed as a DateTime object
  const date = typeof invoiceDate === 'string' ? DateTime.fromISO(invoiceDate) : DateTime.fromJSDate(invoiceDate);

  if (!date.isValid) {
    return null;
  }

  switch (salesTerms) {
    case 'COD':
      return date.toJSDate();
    case 'NET_7':
      return date.plus({ days: 7 }).toJSDate();
    case 'NET_10':
      return date.plus({ days: 10 }).toJSDate();
    case 'NET_14':
      return date.plus({ days: 14 }).toJSDate();
    case 'NET_21':
      return date.plus({ days: 21 }).toJSDate();
    case 'NET_25':
      return date.plus({ days: 25 }).toJSDate();
    case 'NET_30':
      return date.plus({ days: 30 }).toJSDate();
    case 'NET_45':
      return date.plus({ days: 45 }).toJSDate();
    case 'NET_60':
      return date.plus({ days: 60 }).toJSDate();

    case 'NET_40': // deprecated
      return date.plus({ days: 40 }).toJSDate();
    case 'NET_90': // deprecated
      return date.plus({ days: 90 }).toJSDate();

    default: // NET_30 by default
      return date.plus({ days: 30 }).toJSDate();
  }
};
