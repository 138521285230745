import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Switch, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import {
  getGridStringOperators,
  GridEditBooleanCell,
  GridRowId,
  isAutogeneratedRow,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import deepEqual from 'deep-equal';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useSnackbar } from 'notistack';
import { Product } from 'shared/core';
import type { ProductsQuery } from 'types/graphql';
import { z } from 'zod';

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web';
import { useMutation, useQuery } from '@redwoodjs/web';

import ExportGridButton from 'src/components/atoms/ExportGridButton';
import { autocompleteCellClassName } from 'src/components/atoms/grid/AutoCompleteCell';
import CountryCell, { CountryOption } from 'src/components/atoms/grid/CountryCell';
import FreeSoloCell, { useFreeSoloProcessing } from 'src/components/atoms/grid/FreeSoloCell';
import Link from 'src/components/atoms/Link';
import StatusBadge from 'src/components/atoms/StatusBadge';
import AddableDataGrid from 'src/components/containers/grids/AddableDataGrid';
import { GridColObj } from 'src/components/containers/grids/DataGrid';
import { MainCard } from 'src/components/containers/MainCard';
import ConfirmModal from 'src/components/containers/modals/ConfirmModal';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import { useDebounce } from 'src/hooks/useDebounce';
import useGridExport from 'src/hooks/useGridExport';
import { FeatureFlags } from 'src/lib/constants';
import BulkEditCostBasis from 'src/modules/systemManagement/pricingSheets/components/BulkEditCostBasis';
import { entityTypes } from 'src/utils/entityTypes';
import { formatCurrency } from 'src/utils/grid/cell/cellFormatters';
import createCustomFieldColumns from 'src/utils/grid/column/createCustomFieldColumns';
import isNil from 'src/utils/isNil';
import money from 'src/utils/money';

import isFirstDataFieldEmpty from '../utils/isFirstDataFieldEmpty';

type ProductColumns = CellSuccessProps<ProductsQuery>['products'][number];

export const GET_PRODUCTS = gql`
  query ProductsQuery($id: Int!) {
    commodity(id: $id) {
      name
      styles {
        id
        name
      }
      sizes {
        id
        name
      }
      units {
        id
        name
      }
      labels {
        id
        name
      }
    }
    products(commodityId: $id) {
      id
      disabled
      grade
      commodity {
        name
      }
      commodityStyle {
        id
        name
      }
      commoditySize {
        id
        name
      }
      commodityUnit {
        id
        name
      }
      commodityLabel {
        id
        name
      }
      countryOfOrigin
      organic
      weightPerInventoryUnit
      variableWeight
      cartonsPerPallet
      productCode
      gtinCode
      costBasis
      customFields
    }
  }
`;
export const QUERY = GET_PRODUCTS;

const DISABLE_PRODUCT = gql`
  mutation updateProduct($id: Int!, $input: UpdateProductInput!) {
    updateProduct(id: $id, input: $input) {
      id
    }
  }
`;

export const Loading = () => <div>Loading...</div>;

/**
 * We need to export a custom isEmpty function because the default isEmpty function only considers the cell empty if
 * all the data fields are empty. For this cell, we only want to consider the cell empty if the first data field is
 * empty. This is because the first data field is the only one that is required--without it, a Not Found page should be
 * displayed.
 */
export const isEmpty = isFirstDataFieldEmpty;
export const Empty = () => <div>Empty</div>;
export const Failure = ({ error }: CellFailureProps) => <div style={{ color: 'red' }}>Error: {error?.message}</div>;

const createRow = () => {
  return {
    commodityStyle: null,
    commoditySize: null,
    commodityUnit: null,
    countryOfOrigin: null,
    grade: null,
  };
};

const CREATE_VARIATION = gql`
  mutation CreateStyleMutation($input: CreateCommodityStyleInput!) {
    createCommodityStyle(input: $input) {
      id
      name
    }
  }
`;

const CREATE_PACK_STYLE = gql`
  mutation CreateSizeMutation($input: CreateCommoditySizeInput!) {
    createCommoditySize(input: $input) {
      id
      name
    }
  }
`;

const CREATE_UNIT_OF_MEASURE = gql`
  mutation CreateUnitMutation($input: CreateCommodityUnitInput!) {
    createCommodityUnit(input: $input) {
      id
      name
    }
  }
`;

const CREATE_LABEL = gql`
  mutation CreateLabelMutation($input: CreateCommodityLabelInput!) {
    createCommodityLabel(input: $input) {
      id
      name
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation CreateProductMutation($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
    }
  }
`;

const FIND_PRODUCT_RESERVATIONS = gql`
  query FindProductReservations($id: Int!) {
    productReservations(id: $id) {
      slug
      status
      type
      createdAt
      warehouseId
    }
  }
`;

type SuccessProps = CellSuccessProps<ProductsQuery> & {
  id: number;
};

const INITIAL_RESERVATION_REQ_LIMIT = 6;
const INITIAL_RESERVATION_DISPLAY_LIMIT = 10;

// sort by date created so that more types are more likely to be shown.
// otherwise, it would be in the order of types.
const sortReservations = (reservations) => {
  return [...reservations].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
};

/**
 * We're limting the amount of initial reservations loaded,
 * so that we're not doing a ton of work on the server.
 *
 * Each type will be capped at 1 less than what we're requesting from the server,
 * so that we know that there's at least 1 extra of needing to be loaded.
 */
const limitInitialReservations = (reservations, rowId) => {
  const typeCount = new Map();

  const resultReservations = [];
  let hasMore = false;

  for (const reservation of reservations) {
    const count = typeCount.get(reservation.type) || 0;

    if (resultReservations.length === INITIAL_RESERVATION_DISPLAY_LIMIT) {
      hasMore = true;
      break;
    }

    if (count < INITIAL_RESERVATION_REQ_LIMIT - 1) {
      resultReservations.push(reservation);
      typeCount.set(reservation.type, count + 1);
    } else {
      hasMore = true;
    }
  }

  return {
    id: rowId,
    reservations: sortReservations(resultReservations),
    hasMore,
  };
};

export const Success = ({ products, id, commodity }: SuccessProps) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const [productRows, setProductRows] = useState<ProductColumns[]>(products);
  const [selection, setSelection] = useState([]);

  // TODO: Remove this once the Variable Product Weight feature flag is removed
  const ldClient = useLDClient();
  const flags = useFlags();
  const [isVariableWeightEnabled, setIsVariableWeightEnabled] = useState(false);
  useEffect(() => {
    // Set up Grower Products' feature flag

    const isVariableWeightEnabled = ldClient?.variation(FeatureFlags.VariableProductWeight, false) ?? false;
    setIsVariableWeightEnabled(isVariableWeightEnabled);
  }, [flags, ldClient]);

  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onCompleted: () => {
      enqueueSnackbar('Product created', { variant: 'success' });
    },
    onError: (e) => {
      enqueueSnackbar(e.message, { variant: 'error' });
    },
  });

  const [updateProduct] = useMutation(DISABLE_PRODUCT, {
    onCompleted: () => {
      enqueueSnackbar('Product updated', { variant: 'success' });
    },
    onError: (e) => {
      enqueueSnackbar(e.message, { variant: 'error' });
    },
  });

  function handleDisableProduct(params) {
    updateProduct({
      variables: {
        id: params.id,
        input: { disabled: !params.value },
      },
    });
  }

  const [createVariety] = useMutation(CREATE_VARIATION);
  const [createPackStyle] = useMutation(CREATE_PACK_STYLE);
  const [createUnit] = useMutation(CREATE_UNIT_OF_MEASURE);
  const [createLabel] = useMutation(CREATE_LABEL);

  const [commodityStyles, setCommodityStyles] = useState(commodity.styles);
  const [commoditySizes, setCommoditySizes] = useState(commodity.sizes);
  const [commodityUnits, setCommodityUnits] = useState(commodity.units);
  const [commodityLabels, setCommodityLabels] = useState(commodity.labels);

  /**
   * Helper function to extract the id from the definition.
   *
   * If the definition is null, then we return null.
   */
  const getCommodityPropertyId = (commodityDef) => {
    if (commodityDef === null) {
      return null;
    }

    return commodityDef?.id;
  };

  const customFields = flags['customFields']?.product;

  const rowToInput = (row: ProductColumns & { isNew?: boolean }) => {
    const countryOfOrigin =
      typeof row.countryOfOrigin === 'object' ? (row.countryOfOrigin as CountryOption)?.code : row.countryOfOrigin;

    const input = {
      commodityStyleId: getCommodityPropertyId(row.commodityStyle),
      commoditySizeId: getCommodityPropertyId(row.commoditySize),
      commodityUnitId: getCommodityPropertyId(row.commodityUnit),
      commodityLabelId: getCommodityPropertyId(row.commodityLabel),
      countryOfOrigin: countryOfOrigin,
      grade: row.grade,
      organic: row.organic,
      productCode: row.productCode,
      weightPerInventoryUnit: row.weightPerInventoryUnit,
      variableWeight: row.variableWeight,
      cartonsPerPallet: row.cartonsPerPallet,
      gtinCode: row.gtinCode,
      costBasis: row.costBasis,
      customFields: row.customFields,
    };

    if (row.isNew) {
      input['commodityId'] = id;
    }

    return input;
  };

  const nameOperators = getGridStringOperators().map((operator) => ({
    ...operator,
    getApplyFilterFn: (filterItem, column) => {
      return (params) => {
        const value = params.value?.name ?? params.value;
        const filterFunction = operator.getApplyFilterFn(filterItem, column);

        if (!filterFunction) {
          return true;
        }

        return filterFunction({
          ...params,
          value,
        });
      };
    },
  }));

  const nameSorter = (v1, v2) => {
    return v1?.name?.localeCompare(v2?.name);
  };

  const productsColumns: GridColObj<ProductColumns & { isNew?: boolean }> = {
    commodity: {
      headerName: 'Commodity',
      minWidth: 180,
      flex: 1,
      type: 'string',
      editable: false,
      valueFormatter: (value) => value?.name,
      filterOperators: nameOperators,
      sortComparator: nameSorter,
    },
    commodityStyle: {
      headerName: 'Variety',
      minWidth: 180,
      flex: 1,
      type: 'string',
      editable: true,
      valueFormatter: (value) => value?.name,
      filterOperators: nameOperators,
      sortComparator: nameSorter,
      renderEditCell: (params) => {
        return (
          <FreeSoloCell
            cell={params}
            handleCreate={(name, res) => {
              createVariety({
                variables: {
                  input: { name, commodityId: id },
                },
              }).then(({ data }) => {
                return res(data.createCommodityStyle);
              });
            }}
            getOptionLabel={(option) => option.name}
            options={commodityStyles}
            setFreeSoloOptions={setCommodityStyles}
          />
        );
      },
    },
    commoditySize: {
      headerName: 'Pack-Style',
      minWidth: 130,
      flex: 1,
      type: 'string',
      editable: true,
      valueFormatter: (value) => value?.name,
      cellClassName: autocompleteCellClassName,
      filterOperators: nameOperators,
      sortComparator: nameSorter,
      renderEditCell: (params) => {
        return (
          <FreeSoloCell
            cell={params}
            handleCreate={(name, res) => {
              createPackStyle({
                variables: {
                  input: { name, commodityId: id },
                },
              }).then(({ data }) => {
                return res(data.createCommoditySize);
              });
            }}
            getOptionLabel={(option) => option.name}
            options={commoditySizes.filter((label) => label.name)}
            setFreeSoloOptions={setCommoditySizes}
          />
        );
      },
    },
    commodityLabel: {
      headerName: 'Label',
      minWidth: 130,
      flex: 1,
      type: 'string',
      editable: true,
      valueFormatter: (value) => value?.name,
      cellClassName: autocompleteCellClassName,
      filterOperators: nameOperators,
      sortComparator: nameSorter,
      renderEditCell: (params) => {
        return (
          <FreeSoloCell
            cell={params}
            handleCreate={(name, res) => {
              createLabel({
                variables: {
                  input: { name, commodityId: id },
                },
              }).then(({ data }) => {
                return res(data.createCommodityLabel);
              });
            }}
            getOptionLabel={(option) => option.name}
            options={commodityLabels.filter((label) => label.name)}
            setFreeSoloOptions={setCommodityLabels}
          />
        );
      },
    },
    commodityUnit: {
      headerName: 'Unit of Measure',
      minWidth: 120,
      flex: 1,
      type: 'string',
      editable: true,
      valueFormatter: (value) => value?.name,
      cellClassName: autocompleteCellClassName,
      filterOperators: nameOperators,
      sortComparator: nameSorter,
      renderEditCell: (params) => {
        return (
          <FreeSoloCell
            cell={params}
            handleCreate={(name, res) => {
              createUnit({
                variables: {
                  input: { name, commodityId: id },
                },
              }).then(({ data }) => {
                return res(data.createCommodityUnit);
              });
            }}
            getOptionLabel={(option) => option.name}
            options={commodityUnits.filter((label) => label.name)}
            setFreeSoloOptions={setCommodityUnits}
          />
        );
      },
    },
    weightPerInventoryUnit: {
      headerName: 'Unit Weight',
      minWidth: 100,
      flex: 1,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      editable: true,
    },
    variableWeight: {
      headerName: 'Variable Weight',
      minWidth: 70,
      flex: 1,
      type: 'boolean',
      editable: true,
      renderEditCell: (params) => <GridEditBooleanCell {...params} />,
    },
    cartonsPerPallet: {
      headerName: 'Units per Pallet',
      minWidth: 120,
      flex: 1,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      editable: true,
    },
    costBasis: {
      headerName: 'Cost Basis',
      minWidth: 120,
      flex: 1,
      type: 'number',
      editable: true,
      valueSetter: (value, row) => {
        return {
          ...row,
          costBasis: isNil(value) ? value : money.toCents(value),
        };
      },

      valueGetter: (_value, row) => {
        if (isNil(row.costBasis)) {
          return null;
        }
        return money.toDollars(Math.round(row.costBasis));
      },
      valueFormatter: (value) => formatCurrency({ value }),
    },
    countryOfOrigin: {
      headerName: 'Country',
      flex: 2,
      type: 'string',
      editable: true,
      cellClassName: autocompleteCellClassName,
      valueGetter: (value) => {
        return typeof value === 'object' ? value?.code : value;
      },
      renderEditCell: (params) => <CountryCell {...params} />,
    },
    grade: {
      headerName: 'Grade',
      minWidth: 100,
      flex: 1,
      type: 'string',
      editable: true,
    },
    organic: {
      headerName: 'Organic',
      width: 70,
      type: 'boolean',
      editable: true,
      renderEditCell: (params) => <GridEditBooleanCell {...params} />,
    },
    productCode: {
      headerName: 'Product Code',
      minWidth: 100,
      flex: 1.5,
      type: 'string',
      editable: true,
    },
    gtinCode: {
      headerName: 'GTIN Number',
      minWidth: 100,
      flex: 1.5,
      type: 'string',
      editable: true,
    },
    description: {
      headerName: 'Description',
      minWidth: 100,
      flex: 2,
      type: 'string',
      valueGetter: (_value, row) => {
        const countryOfOrigin =
          typeof row.countryOfOrigin === 'object' ? (row.countryOfOrigin as CountryOption)?.code : row.countryOfOrigin;

        return Product.productDescription({
          commodity,
          ...row,
          countryOfOrigin,
        });
      },
    },
    disabled: {
      headerName: 'Active',
      width: 70,
      renderCell: (params) => {
        return isAutogeneratedRow(params.row) || params.row.isNew ? null : (
          <Switch onChange={() => handleDisableProduct(params)} defaultChecked={!params.value} size="small" />
        );
      },
    },
    ...createCustomFieldColumns(customFields, true),
  };

  // Create a new product and synchronize the row id
  function handleSaveProduct(input) {
    return createProduct({
      variables: {
        input,
      },
    }).then(({ data }) => {
      const { id } = data.createProduct;

      return {
        id,
      };
    });
  }

  function processRowChanges(id, input) {
    updateProduct({
      variables: {
        id,
        input,
      },
    });
  }

  const { shouldPreventRowProcess: isFreeSoloProcessing } = useFreeSoloProcessing(apiRef);

  const { refetch: findProductReservations } = useQuery(FIND_PRODUCT_RESERVATIONS, {
    skip: true,
  });

  const [editWarningRow, setEditWarningRow] = useState<{
    id: GridRowId;
    reservations: {
      type: string;
      slug: string;
      status: string;
      createdAt: Date;
    }[];
    hasMore?: boolean;
  }>(null);

  const handleSeeMoreClick = useDebounce(() => {
    findProductReservations({
      id: editWarningRow.id,
    }).then(({ data }) => {
      setEditWarningRow({
        id: editWarningRow.id,
        reservations: sortReservations(data.productReservations),
      });
    });
  }, 500);

  const isBlockedByReservation = useCallback(
    async (rowId, oldRow, newRow) => {
      if (newRow.isNew) {
        return false;
      }

      // Triggered when the user confirms the edit warning modal,
      // causing the row to reprocess.
      if (editWarningRow?.id === rowId) {
        setEditWarningRow(null);
        return false;
      }

      // prevent the edit warning from being overwritten
      if (editWarningRow) {
        return true;
      }

      const noChanges = deepEqual(oldRow, newRow);

      if (noChanges) {
        return false;
      }

      // We're limiting the amount of each type
      const { data } = await findProductReservations({
        id: rowId,
        limit: INITIAL_RESERVATION_REQ_LIMIT,
      });

      const reservationCount = data.productReservations.length;

      if (reservationCount > 0) {
        const res = limitInitialReservations(data.productReservations, rowId);

        setEditWarningRow(res);
        return true;
      }
    },
    [editWarningRow, findProductReservations, setEditWarningRow]
  );

  const selectedRows = useMemo(() => selection.map((rowId) => apiRef.current.getRow(rowId)), [selection, apiRef]);

  const updateCostBasisState = useCallback((ids: number[], costBasis: number) => {
    setProductRows((rows) =>
      rows.map((row) => {
        if (ids.includes(row.id)) {
          return {
            ...row,
            costBasis,
          };
        }

        return row;
      })
    );
  }, []);

  useGridExport(apiRef, `${commodity.name}-products`);

  return (
    <>
      <PageHeader pageType={PageType.Products} entitySlug={commodity.name} delimiter="-">
        <Box sx={{ ml: 'auto', display: 'flex', my: 'auto', gap: 1 }}>
          <ExportGridButton />
        </Box>
      </PageHeader>
      <PageBody>
        <MainCard noPadding>
          <div className="flex justify-end mt-4 mr-4">
            <BulkEditCostBasis selectedRows={selectedRows} afterSubmit={updateCostBasisState} />
          </div>
          <AddableDataGrid
            columns={productsColumns}
            rows={productRows}
            apiRef={apiRef}
            canEdit
            checkboxSelection
            rowSelectionModel={selection}
            onRowSelectionModelChange={(selection) => {
              setSelection(selection);
            }}
            hideFooter={false}
            shouldPreventRowProcess={async (rowId, oldRow, newRow) => {
              if (isFreeSoloProcessing(rowId)) {
                return true;
              }

              const isBlocked = await isBlockedByReservation(rowId, oldRow, newRow);

              if (isBlocked) {
                return true;
              }
            }}
            trackHover={false}
            pagination
            toolbar={{
              useSearchbar: false,
            }}
            fieldToFocus="commodityStyle"
            storageKey="product-management"
            initialState={{
              density: 'compact',
              columns: {
                columnVisibilityModel: {
                  commodity: false,
                },
              },
            }}
            rowModel={() =>
              z.object({
                commodityStyle: z
                  .object({
                    name: z.string(),
                    id: z.number(),
                  })
                  .optional()
                  .nullable(),
                commoditySize: z
                  .object({
                    name: z.string(),
                    id: z.number(),
                  })
                  .optional()
                  .nullable(),
                commodityUnit: z.object(
                  {
                    name: z.string(),
                    id: z.number(),
                  },
                  {
                    invalid_type_error: 'Please select a valid unit of measure.',
                    required_error: 'Please select a unit of measure.',
                  }
                ),
                commodityLabel: z
                  .object({
                    name: z.string(),
                    id: z.number(),
                  })
                  .optional()
                  .nullable(),
                weightPerInventoryUnit: z.number().gte(0).optional(),
                variableWeight: z.boolean().optional(),
                cartonsPerPallet: z.number().gte(0).optional(),
                countryOfOrigin: z
                  .object({
                    name: z.string(),
                    code: z.string(),
                  })
                  .or(z.string())
                  .optional()
                  .nullable(),
                grade: z.string().optional().nullable(),
                organic: z.boolean().default(false),
                productCode: z.string().optional().nullable(),
                gtinCode: z.string().optional().nullable(),
                costBasis: z.number().gte(0).optional().nullable(),
                customFields: z.record(z.any()).optional().nullable(),
              })
            }
            createRow={createRow}
            rowToInput={rowToInput}
            newRowLabel="Create New Product"
            processCellSave={handleSaveProduct}
            processCellChanges={processRowChanges}
            onProcessRowUpdateError={(e) => {
              console.error(e);
            }}
            setRows={setProductRows}
            columnVisibilityModel={{
              variableWeight: isVariableWeightEnabled,
            }}
          />
        </MainCard>
      </PageBody>
      <ConfirmModal
        open={!!editWarningRow}
        handleClose={() => setEditWarningRow(null)}
        handleConfirm={() => {
          apiRef.current.stopRowEditMode({
            id: editWarningRow.id,
          });
        }}
        title="Product in Active Use"
        action="Confirm"
        prompt={
          <>
            <Typography variant="body1">
              By confirming, the product will be updated on the following active operations:
            </Typography>
            <Box
              sx={{
                py: 2,
                maxHeight: '402px',
                overflowY: 'auto',
              }}
            >
              <Table>
                <TableBody>
                  {editWarningRow?.reservations?.map((reservation, i) => {
                    const entityType = entityTypes[reservation.type];

                    return (
                      <TableRow key={i}>
                        <TableCell
                          sx={{
                            py: 0.75,
                          }}
                        >
                          {entityType.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            py: 0.75,
                            minWidth: '160px',
                          }}
                        >
                          <Link
                            to={entityType.route({
                              slug: reservation.slug,
                              warehouseId: reservation.warehouseId,
                            })}
                            label={`${entityType.prefix} #${reservation.slug}`}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            py: 0.75,
                            justifyItems: 'center',
                          }}
                        >
                          <StatusBadge status={reservation.status} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button onClick={handleSeeMoreClick} disabled={!editWarningRow?.hasMore}>
                See All
              </Button>
            </Box>
          </>
        }
      />
    </>
  );
};
