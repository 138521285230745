export interface ProductWeightAttributes {
  weightPerInventoryUnit: number;
  variableWeight: boolean;
}

export interface LotWeightAttributes {
  initialQuantity: number;
  initialWeight: number;
}

export interface PalletTagWeightAttributes {
  cartonQuantity: number;
  weight: number;
}

export interface InitialPalletTagWeightAttributes {
  initialQuantity: number;
  initialWeight: number;
}

export interface LineItemVariableWeightAttributes {
  unitsReceived?: number;
  unitsOrdered?: number;
  weightReceived?: number;
}

export interface LineItemPalletTagWeightAttributes {
  quantity: number;
  palletTag: {
    initialWeight: number;
    initialQuantity: number;
  };
}

type StrategyContext = {
  product: ProductWeightAttributes;
  lot?: LotWeightAttributes;
  palletTags?: PalletTagWeightAttributes[];
  isVariableWeight: boolean;
  lineItem?: LineItemVariableWeightAttributes;
};

type ConsumableWeightStrategy = (
  context: StrategyContext,
  accountedQuantity: number
) => { weight: number; remainingQuantity: number } | undefined;

type StandardWeightStrategy = (context: StrategyContext, accountedQuantity: number) => number | undefined;

type WeightStrategy = ConsumableWeightStrategy | StandardWeightStrategy;

const productWeightStrategy: StandardWeightStrategy = ({ product }, remainingQuantity) => {
  if (!product) {
    return 0;
  }

  return product?.weightPerInventoryUnit * remainingQuantity;
};

const lotWeightStrategy: StandardWeightStrategy = ({ lot, isVariableWeight }, remainingQuantity) => {
  const invalidInput = !lot?.initialQuantity || !lot?.initialWeight;

  if (invalidInput || !isVariableWeight) {
    return undefined;
  }

  const perUnitWeight = lot.initialWeight / lot.initialQuantity;

  return perUnitWeight * remainingQuantity;
};

const lineItemWeightStrategy: ConsumableWeightStrategy = ({ isVariableWeight, lineItem }, remainingQuantity) => {
  if (!lineItem) {
    return undefined;
  }

  const units = Math.min(lineItem.unitsReceived ?? lineItem.unitsOrdered ?? 0, remainingQuantity);

  if (!isVariableWeight || !units || !lineItem.weightReceived) {
    return undefined;
  }

  const weightPerUnit = lineItem.weightReceived / units;

  return {
    weight: weightPerUnit * remainingQuantity,
    remainingQuantity: remainingQuantity - units,
  };
};

const palletTagWeightStrategy: ConsumableWeightStrategy = ({ palletTags, isVariableWeight }, remainingQuantity) => {
  if (!palletTags || !isVariableWeight) {
    return undefined;
  }

  const result = palletTags.reduce(
    (prev, { weight, cartonQuantity }) => {
      if (prev.remainingQuantity <= 0) {
        return prev;
      }

      const quantityToRemove = Math.min(cartonQuantity, prev.remainingQuantity);

      if (cartonQuantity <= 0 || quantityToRemove <= 0) {
        return prev;
      }

      const percentOfPalletRemoved = quantityToRemove / cartonQuantity;

      return {
        weight: prev.weight + weight * percentOfPalletRemoved,
        remainingQuantity: prev.remainingQuantity - quantityToRemove,
      };
    },
    {
      weight: 0,
      remainingQuantity,
    }
  );

  return result;
};

class ProductWeight {
  protected product: ProductWeightAttributes;
  protected lot?: LotWeightAttributes;
  protected palletTags?: PalletTagWeightAttributes[];
  protected strategies: WeightStrategy[] = [];
  protected proportionOfSource = 1;
  protected lineItem?: LineItemVariableWeightAttributes;
  protected computeInitialWeight = false;

  constructor(product: ProductWeightAttributes) {
    this.product = product;
    this.addStrategy(productWeightStrategy);
  }

  /**
   * When setting "asInitialWeight", we know to target the initial weight of the product,
   * rather than whatever the current weight is on variable inputs (such as pallet tags).
   */
  asInitialWeight() {
    this.computeInitialWeight = true;
    return this;
  }

  withLot(lot: LotWeightAttributes): ProductWeight {
    this.lot = lot;
    this.addStrategy(lotWeightStrategy);

    return this;
  }

  withPalletTags(
    palletTags: (PalletTagWeightAttributes | InitialPalletTagWeightAttributes)[]
  ): Omit<ProductWeight, 'withLot'> {
    if (this.computeInitialWeight) {
      this.palletTags = (palletTags as InitialPalletTagWeightAttributes[]).map(
        ({ initialQuantity, initialWeight }) => ({
          weight: initialWeight,
          cartonQuantity: initialQuantity,
        })
      );
    } else {
      this.palletTags = palletTags as PalletTagWeightAttributes[];
    }

    this.addStrategy(palletTagWeightStrategy);

    return this;
  }

  withLineItemVariableWeight(lineItem: LineItemVariableWeightAttributes) {
    this.lineItem = lineItem;
    this.addStrategy(lineItemWeightStrategy);

    return this;
  }

  withLineItemPalletTags(palletTagsOnLineItem: LineItemPalletTagWeightAttributes[]): Omit<ProductWeight, 'withLot'> {
    this.palletTags = palletTagsOnLineItem?.map(({ quantity, palletTag }) => {
      const perUnitWeight = palletTag.initialQuantity ? palletTag.initialWeight / palletTag.initialQuantity : 0;

      // currently user does not select a weight when assigning a line item pallet tag
      const weight = perUnitWeight * quantity;

      return {
        weight,
        cartonQuantity: quantity,
      };
    });

    this.addStrategy(palletTagWeightStrategy);

    return this;
  }

  private addStrategy(strategy: WeightStrategy): void {
    this.strategies = [
      strategy,
      // Deduplicate strategies
      ...this.strategies.filter((s) => s !== strategy),
    ];
  }

  /**
   * Calculate the total weight of a product for a given quantity.
   */
  forQuantity(quantity?: number): number {
    if (!quantity) {
      return 0;
    }

    const context = {
      product: this.product,
      lot: this.lot,
      palletTags: this.palletTags,
      isVariableWeight: this.product.variableWeight,
      proportionOfSource: this.proportionOfSource,
      lineItem: this.lineItem,
    };

    const result = this.strategies.reduce(
      ({ weight, remainingQuantity }, strategy) => {
        const result = strategy(context, remainingQuantity);

        if (result === undefined) {
          return {
            weight,
            remainingQuantity,
          };
        }

        if (typeof result === 'number') {
          return {
            weight: weight + result,
            // if no specified remaining quantity is provided, all has been allocated.
            remainingQuantity: 0,
          };
        }

        return {
          weight: weight + result.weight,
          remainingQuantity: result.remainingQuantity,
        };
      },
      {
        weight: 0,
        remainingQuantity: quantity,
      }
    );

    return result.weight;
  }

  averageWeightForQuantity(quantity: number): number {
    if (quantity === 0) {
      return 0;
    }

    return this.forQuantity(quantity) / quantity;
  }
}

export default {
  /**
   * Calculate the total weight of a product for a given quantity, at different levels of granularity.
   *
   * @example
   *
   * ```
   *  import { Product } from 'shared/core';
   *
   *  // Lowest level of granularity (product level)
   *  Product.Weight.of(product).forQuantity(quantity);
   *
   *  // With variable weight lots
   *  Product.Weight.of(product).withLot(lot).forQuantity(quantity);
   *
   *  // Variable weight lots and pallet tags
   *  Product.Weight.of(product).withLot(lot).withPalletTags(palletTags).forQuantity(quantity);
   *
   *  // Direct from pallet tags
   *  Product.Weight.of(product).withPalletTags(palletTags).forQuantity(quantity);
   * ```
   */
  of(product: ProductWeightAttributes): ProductWeight {
    return new ProductWeight(product);
  },
};
