import { useCallback, useMemo } from 'react';

export default function useAnchorEl() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return useMemo(() => ({ anchorEl, open, handleClick, handleClose }), [anchorEl, open, handleClick, handleClose]);
}
