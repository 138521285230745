import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';

import roundToNearestQuarter from 'src/utils/roundToNearestQuarter';

enum SystemUnitRounding {
  NEAREST_QUARTER = 'NEAREST_QUARTER',
  TWO_DECIMALS = 'TWO_DECIMALS',
}

export default function useSystemUnitRounding() {
  const flags = useFlags();

  const method = useMemo(() => {
    const systemUnitRounding = flags['systemRounding'] as SystemUnitRounding;

    switch (systemUnitRounding) {
      case SystemUnitRounding.NEAREST_QUARTER:
        return roundToNearestQuarter;
      case SystemUnitRounding.TWO_DECIMALS:
        return (value: number) => {
          if (isNil(value)) {
            return value;
          }

          const valueAsNumber = Number(value);

          return Math.round(valueAsNumber * 100) / 100;
        };
    }
  }, [flags]);

  return method;
}
