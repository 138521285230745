import { UpdateOrganizationInput, Organization } from '$api/types/graphql';
import { useMutation, useQuery } from '@redwoodjs/web';

const GET_ORGANIZATION = gql`
  query GetOrganization {
    organization {
      id
      closedAt
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
    }
  }
`;

export const useUpdateClosingPeriodMutation = (): MutationOperationResult<
  Organization,
  { input: UpdateOrganizationInput }
> => {
  return useMutation<Organization, { input: UpdateOrganizationInput }>(UPDATE_ORGANIZATION);
};

export const useOrganizationQuery = (): QueryOperationResult<{ organization: Organization }, {}> => {
  return useQuery<{ organization: Organization }, {}>(GET_ORGANIZATION);
};
