import { useContext, useEffect } from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { useSelector } from '@xstate/react';

import { useQuery } from '@redwoodjs/web';

import { StateMachineContext, StateMachineProvider } from 'src/providers/StateMachineProvider';

import ScanningApi from '../../api/Scanning.api';
import BarcodeScanner from '../../events/BarcodeScanner';
import PickingScannerStates, { ScanStatus } from '../../state/TransferScanner.states';

import ScannerInfo from './modals/ScannerInfo';
import TransferScannerModal from './modals/TransferScannerModal';

const barcodeScanner = new BarcodeScanner.Target();

export default function TransferScanner({ transferItems, handleAddToOrder, warehouseId, status }) {
  const { refetch: lookupPalletTag } = useQuery(ScanningApi.LookupPalletTag, {
    skip: true,
  });

  return (
    <StateMachineProvider
      machine={PickingScannerStates.machine}
      context={{
        transferItems,
        warehouseId,
        status,
      }}
      actions={{
        handleAddToOrder,
      }}
      services={{
        lookupPalletTag: async (context, event) => {
          const { data } = await lookupPalletTag({
            tagNumber: event.barcode,
            warehouseId: context.warehouseId,
          });

          return data?.palletTagLookup;
        },
      }}
    >
      <ScannerBase />
    </StateMachineProvider>
  );
}

const ScannerScreens: Record<ScanStatus, React.FunctionComponent> = {
  [ScanStatus.IDLE]: React.Fragment,
  [ScanStatus.AWAITING]: () => (
    <ScannerInfo
      info={[
        'Scan pallet tag barcode to transfer.',
        'Once a pallet tag is found, input the desired units to transfer.',
      ]}
    />
  ),
  [ScanStatus.NO_WAREHOUSE]: () => <ScannerInfo info={['Input Ship From before scanning.']} />,
  [ScanStatus.FINDING_PALLET_TAG]: () => <ScannerInfo info={['Finding pallet tag...']} />,
  [ScanStatus.PALLET_TAG_FOUND]: () => <ScannerInfo info={['Finding pallet tag...']} />,
  [ScanStatus.ERROR_NO_TAG_MATCH]: () => <ScannerInfo info={['No matching tag found in this warehouse']} />,
  [ScanStatus.ADD_TRANSFER_ITEM]: TransferScannerModal,
};

function ScannerBase() {
  const { service } = useContext(StateMachineContext);
  const stateValue = useSelector(service, (state) => state.value as ScanStatus);

  useEffect(() => {
    const scanEvent = (e) => {
      service.send({ type: 'SCAN', barcode: e.detail });
    };

    barcodeScanner.addEventListener(BarcodeScanner.event, scanEvent);

    return () => {
      barcodeScanner.removeEventListener(BarcodeScanner.event, scanEvent);
    };
  });

  const onClose = () => {
    service.send('CANCEL');
  };

  const open = stateValue !== ScanStatus.IDLE;
  const Screen = ScannerScreens[stateValue];

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Scan Pallet Code</DialogTitle>
      <Screen />
    </Dialog>
  );
}
