import { useQuery } from '@redwoodjs/web';

import StandaloneInvoiceDocumentApi from '../api/StandaloneInvoiceDocument.api';

export default function useStandaloneInvoiceDocument({ slug, skip = false }) {
  const { data, loading, refetch } = useQuery(StandaloneInvoiceDocumentApi.GetStandaloneInvoiceDocumentData, {
    variables: { slug },
    skip,
  });

  return {
    invoice: data?.standardInvoice,
    loading,
    getDocumentData: () => refetch({ slug }).then((res) => res.data.standardInvoice),
  };
}
