type SalesTerms =
  | 'NET_7'
  | 'NET_10'
  | 'NET_14'
  | 'NET_21'
  | 'NET_25'
  | 'NET_30'
  | 'NET_45'
  | 'NET_60'
  | 'COD';

export const CODES: SalesTerms[] = [
  'NET_7',
  'NET_10',
  'NET_14',
  'NET_21',
  'NET_25',
  'NET_30',
  'NET_45',
  'NET_60',
  'COD',
];

export const DEFAULT: SalesTerms = 'NET_30';
