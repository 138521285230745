import { UpdatePricingSheetInput } from 'types/graphql';

import { useMutation } from '@redwoodjs/web';

import { GET_PRICING_SHEET } from 'src/modules/systemManagement/pricingSheets/hooks/usePricingSheet';
import money from 'src/utils/money';

import { PricingSheetMeta } from '../constants';

export const EDIT_PRICING_SHEET = gql`
  mutation EditPricingSheets($id: Int!, $input: UpdatePricingSheetInput!) {
    updatePricingSheet(id: $id, input: $input) {
      id
    }
  }
`;

export default function useEditPricingSheet(id: number) {
  const [editPricingSheet] = useMutation(EDIT_PRICING_SHEET, { refetchQueries: [GET_PRICING_SHEET] });

  return async (data: Partial<PricingSheetMeta>) => {
    const input: UpdatePricingSheetInput = { ...data };
    if (input.customerGroups?.length && typeof input.customerGroups[0] === 'object') {
      input.customerGroups = input.customerGroups.map((g) => g.id);
    }
    if (input.individualCustomers?.length && typeof input.individualCustomers[0] === 'object') {
      input.individualCustomers = input.individualCustomers.map((c) => c.businessEntityId);
    }
    if (input.pricingFormula === 'COST_BASIS_FLAT_MARGIN') {
      input.pricingValue = money.toCents(input.pricingValue);
    }

    return editPricingSheet({ variables: { id, input } });
  };
}
