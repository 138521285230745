import computeDescription from 'api/src/services/products/computed/computeDescription';

import { useQuery } from '@redwoodjs/web';

import { PricingFormula } from 'src/modules/systemManagement/pricingSheets/constants';

export const GET_PRICING_SHEET_ENTRIES = gql`
  query GetPricingSheetEntries($pricingSheetId: Int!) {
    pricingSheetEntries(pricingSheetId: $pricingSheetId) {
      id
      pricingFormula
      pricingValue
      roundingValue
      note
      product {
        id
        productCode
        costBasis
        commodity {
          name
        }
        commodityStyle {
          name
        }
        commoditySize {
          name
        }
        commodityUnit {
          name
        }
        commodityLabel {
          name
        }
      }
    }
  }
`;

export type PricingSheetEntry = {
  id: number;
  pricingFormula: PricingFormula;
  pricingValue: number;
  roundingValue: number;
  productId: number;
  productCode: string;
  productName: string;
  costBasis: number;
  note?: string;
};

export default function usePricingSheetEntries(pricingSheetId?: number): PricingSheetEntry[] {
  const { data } = useQuery(GET_PRICING_SHEET_ENTRIES, { variables: { pricingSheetId }, skip: !pricingSheetId });

  return (data?.pricingSheetEntries ?? []).map((entry) => {
    const { product, ...rest } = entry;
    return {
      ...rest,
      costBasis: product.costBasis,
      productId: product.id,
      productCode: product.productCode,
      productName: computeDescription(product),
    };
  });
}
