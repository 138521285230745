import { useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { Document, PDFViewer, Page, Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import { Html } from 'react-pdf-html';
import { DocumentType } from 'shared/documents/DocumentGenerator';

import useDocumentPreview from '../hooks/useDocumentPreview';

export const DOCUMENT_SIZES = {
  small: {
    width: '420',
    height: '540',
  },
  medium: {
    width: '560',
    height: '720',
  },
  large: {
    width: '800',
    height: '1000',
  },
};

export const renderPages = (pages) => {
  return pages?.map((page, i) => {
    return (
      <Page size="LETTER" dpi={72} key={i}>
        <Html
          renderers={{
            // span is used to ensure that text is rendered correctly (with alignment styles)
            span: ({ children, style }) => {
              return <Text style={style}>{children}</Text>;
            },
          }}
        >
          {page}
        </Html>
        <View
          style={{
            position: 'absolute',
            bottom: 10,
            left: 0,
            right: 0,
            height: 15,
            marginBottom: '-6px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: '24px',
            paddingTop: 1,
          }}
          fixed
        >
          <View
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                fontSize: 6,
                letterSpacing: '1px',
                color: '#474746',
              }}
            >
              POWERED BY AGRIFUL
            </Text>
          </View>
          <Text
            style={{
              marginRight: 10,
              color: '#474746',
              fontSize: 7,
            }}
          >
            {DateTime.now().toFormat(`MM/dd/yyyy 'at' HH:mm a`)}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) => `${pageNumber} of ${totalPages}`}
            fixed
            style={{
              color: '#474746',
              fontSize: 7,
            }}
          />
        </View>
      </Page>
    );
  });
};

export default function DocumentPreview({
  width,
  height,
  data,
  documentTemplate,
  entityType,
  type,
  loading,
  extendedData,
}: {
  width: string;
  height: string;
  data: unknown;
  documentTemplate?: DocumentType;
  type: string;
  loading: boolean;
  extendedData?: Record<string | number, unknown>;
}) {
  const pages = useDocumentPreview({ data, documentTemplate, entityType, type, extendedData });

  if (!pages) {
    return null;
  }

  if (loading) {
    return (
      // place in center
      <Box display="flex" justifyContent="center" alignItems="center" height={height} width={width}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFViewer width={width} height={height} showToolbar={false} style={{ border: 'none', borderRadius: '4px' }}>
      <Document>{renderPages(pages)}</Document>
    </PDFViewer>
  );
}
