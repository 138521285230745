/**
 * View symbols: https://fonts.google.com/icons
 */
export default function MaterialSymbol({
  name,
  className,
  style,
}: {
  name: string;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <span
      style={{
        color: 'rgba(58, 71, 16, 0.5)',
        ...(style ?? {}),
      }}
      className={`material-symbols-outlined ${className ?? ''}`}
    >
      {name}
    </span>
  );
}
