import { PriceChange } from '@mui/icons-material';

import { useParams } from '@redwoodjs/router';

import Nutrition from 'src/components/atoms/Nutrition';
import TabbedMainCard from 'src/components/containers/TabbedMainCard';
import NewPricingSheet from 'src/modules/systemManagement/pricingSheets/containers/NewPricingSheet';

import { PricingSheetMeta } from '../constants';
import ProductList from '../editPanels/ProductList';
import Settings from '../editPanels/Settings';
import useEditPricingSheet from '../hooks/useEditPricingSheet';
import usePricingSheet from '../hooks/usePricingSheet';

const pricingSheetTabs = [
  {
    label: 'Product List',
    icon: <Nutrition />,
    payload: ProductList,
  },
  {
    label: 'Settings',
    icon: <PriceChange />,
    payload: Settings,
  },
];

export type PricingSheetTabProps = {
  pricingSheet: PricingSheetMeta;
  editPricingSheet: (data: Partial<PricingSheetMeta>) => Promise<unknown>;
};

export default function EditPricingSheet() {
  const { id } = useParams();
  const pricingSheet = usePricingSheet(id);
  const editPricingSheet = useEditPricingSheet(id);

  if (!pricingSheet) return;
  return (
    <main className="p-6">
      {pricingSheet.draft ? (
        <NewPricingSheet pricingSheet={pricingSheet} editPricingSheet={editPricingSheet} />
      ) : (
        <TabbedMainCard
          tabs={pricingSheetTabs}
          render={(Component) => <Component pricingSheet={pricingSheet} editPricingSheet={editPricingSheet} />}
        />
      )}
    </main>
  );
}
