import { Settings } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  gridColumnDefinitionsSelector,
  GridToolbarContainer,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid-premium';

import { VIEW_PAGES } from 'src/managers/viewConfig.store';

import { ServerGridFilter } from '../serverEnhanced/types';
import { ServerEnhancedGridStateWithHelpers } from '../serverEnhanced/useServerEnhancedGridState';

import BulkActionToolbar from './BulkActionToolbar';
import ClearableSearch from './ClearableSearch';
import CustomizeTable from './CustomizeTable';
import AddFilter from './filters/AddFilter';
import FilterList from './filters/FilterList';
import { useQuickFilterCounts } from './filters/tools';
import ToolbarButton from './ToolbarButton';
import ViewConfigurationMenu from './ViewConfigurationMenu';

export type GridToolbarQuickFilter = {
  label: string;
  filters?: ServerGridFilter[];
};

type GridToolbarV2Props = {
  enhancedGridState: ServerEnhancedGridStateWithHelpers;
  viewPage?: VIEW_PAGES;
  apiRef: ReturnType<typeof useGridApiRef>;
  quickFilterCountModel?: string;
  entityName?: string;
  bulkActions?: React.ReactNode;
};

export default function GridToolbarV2({
  enhancedGridState,
  viewPage,
  apiRef,
  quickFilterCountModel,
  entityName,
  bulkActions,
}: GridToolbarV2Props) {
  const counts = useQuickFilterCounts(quickFilterCountModel, enhancedGridState.quickFilterOptions);
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);

  return (
    <GridToolbarContainer className="flex flex-col gap-2 mb-4 -mx-1 print:hidden">
      <div className="flex gap-3 w-full items-center">
        <ClearableSearch searchState={enhancedGridState.searchText} onSearch={enhancedGridState.setSearch} />
        <AddFilter columns={columns} enhancedGridState={enhancedGridState} />

        {viewPage && <ViewConfigurationMenu page={viewPage} enhancedGridState={enhancedGridState} />}

        <div className="flex-grow" />

        <ToolbarButton icon={<Settings />} label="Customize Table">
          {(close) => <CustomizeTable closeMenu={close} />}
        </ToolbarButton>
      </div>

      <FilterList columns={columns} enhancedGridState={enhancedGridState} />

      {bulkActions && Object.values(enhancedGridState.selectedRows).length > 0 && (
        <BulkActionToolbar
          apiRef={apiRef}
          enhancedGridState={enhancedGridState}
          entityName={entityName}
          bulkActions={bulkActions}
        />
      )}

      {enhancedGridState.quickFilterOptions && (
        <div className="flex justify-start gap-2 w-full mt-2">
          {enhancedGridState.quickFilterOptions.map((filter) => {
            const selected = filter.label === enhancedGridState.quickFilterOption?.label;
            return (
              <Button
                key={filter.label}
                sx={{
                  borderRadius: '999px',
                  textTransform: 'none',
                  fontSize: '14px',
                  lineHeight: 1,
                  py: 1,
                  bgcolor: selected ? 'primary.200' : '',
                  borderColor: selected ? 'transparent' : 'grey.600',
                  color: selected ? 'primary.800' : 'grey.600',
                  fontWeight: selected ? 700 : 400,
                  cursor: selected ? 'default' : 'pointer',
                  '&:hover': {
                    bgcolor: selected ? 'primary.200' : 'primary.50',
                  },
                }}
                onClick={() => enhancedGridState.setQuickFilterOption(filter)}
                variant="outlined"
                color="primary"
                size="small"
              >
                {filter.label} {filter.label in counts ? `(${counts[filter.label]})` : ''}
              </Button>
            );
          })}
        </div>
      )}
    </GridToolbarContainer>
  );
}
