import { createContext, PropsWithChildren, ReactNode, useContext, useState } from 'react';

import { InstantSearch } from 'react-instantsearch-hooks-web';

import { SearchIndex, useAlgolia } from './AlgoliaProvider';

export const PersistantSerachContext = createContext<{
  query: string;
  handleOnChange: (value: string) => void;
  filters: Record<string, unknown>;
  setFilters: (value: Record<string, unknown>) => void;
}>(undefined);

/**
 * Provide persistant search state in situtations where querying a search
 * re-renders the component tree, therefore losing the query.
 */
export default function PersistentSearchProvider({ children }: PropsWithChildren) {
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState<Record<string, unknown>>({});

  const handleOnChange = (value: string) => {
    setQuery(value);
  };

  return (
    <PersistantSerachContext.Provider value={{ handleOnChange, query, filters, setFilters }}>
      {children}
    </PersistantSerachContext.Provider>
  );
}

export type PersistantSearchProps = {
  indexName: SearchIndex;
  children: ReactNode;
};

/**
 * Use InstantSearch with a persistent query, requires a PersistantSearchProvider
 * with a SearchBox child fufilled with the props of `onChange` and `value`
 */
export function PersistantSearch({ children, indexName }: PersistantSearchProps) {
  const algolia = useAlgolia();
  const { query } = usePersistentSearch();

  return (
    <InstantSearch
      searchClient={algolia}
      indexName={indexName}
      initialUiState={{
        [indexName]: {
          query,
        },
      }}
    >
      {children}
    </InstantSearch>
  );
}

export const usePersistentSearch = () => useContext(PersistantSerachContext);
