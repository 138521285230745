export const UpdateProductionRun = gql`
  mutation UpdateProductionRun($id: Int!, $input: UpdateProductionRunInput!) {
    updateProductionRun(id: $id, input: $input) {
      id
    }
  }
`;

export const UpdateProductionRunStatus = gql`
  mutation UpdateProductionRunStatus($id: Int!, $input: UpdateProductionRunStatusInput!) {
    updateProductionRunStatus(id: $id, input: $input) {
      id
      status
      startedAt
      completedAt
    }
  }
`;

export const UpdateSlug = gql`
  mutation updateProductionRunSlug($id: Int!, $slug: String!) {
    updateProductionRunSlug(id: $id, slug: $slug) {
      id
      slug
    }
  }
`;

export const DeleteComponent = gql`
  mutation DeleteProductionRunComponentMutation($id: Int!) {
    deleteProductionRunComponent(id: $id) {
      id
    }
  }
`;

export const UpdateInputComponent = gql`
  mutation UpdateProductionRunInputMutation($id: Int!, $input: UpdateProductionRunInputComponentInput!) {
    updateProductionRunInputComponent(id: $id, input: $input) {
      id
    }
  }
`;

export const UpdateOutputComponent = gql`
  mutation UpdateProductionRunOutputMutation($id: Int!, $input: UpdateProductionRunOutputComponentInput!) {
    updateProductionRunOutputComponent(id: $id, input: $input) {
      id
    }
  }
`;

export const DeleteProductionRun = gql`
  mutation DeleteProductionRun($id: Int!) {
    deleteProductionRun(id: $id) {
      id
    }
  }
`;

export const SetPalletTagsOnProductionRunComponent = gql`
  mutation SetPalletTagsOnProductionRunComponent($productionRunComponentId: Int!, $input: [QuantityForPalletTag!]!) {
    setPalletTagsOnProductionRunComponent(productionRunComponentId: $productionRunComponentId, input: $input) {
      palletTag {
        id
        tagNumber
        cartonQuantity
      }
      deletedAt
      quantity
    }
  }
`;
