import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import { navigate, routes } from '@redwoodjs/router';
import { Metadata, useMutation } from '@redwoodjs/web';

import FavoriteButton from 'src/components/atoms/buttons/FavoriteButton';
import ExportGridButton from 'src/components/atoms/ExportGridButton';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import InventoryTransfersGrid from 'src/modules/inventory/inventoryTransfers/InventoryTransfersGrid';

const CREATE_INVENTORY_TRANSFER_MUTATION = gql`
  mutation createInventoryTransfer($input: CreateInventoryTransferInput!) {
    createInventoryTransfer(input: $input) {
      slug
    }
  }
`;

export default function InventoryTransfersPage() {
  const [createInventoryTransfer] = useMutation(CREATE_INVENTORY_TRANSFER_MUTATION);

  const handleCreateInventoryTransfer = async () => {
    const { data } = await createInventoryTransfer({ variables: { input: {} } });
    navigate(routes.inventoryTransfer({ slug: data.createInventoryTransfer.slug }));
  };

  return (
    <>
      <Metadata title="Inventory Transfers" />
      <PageHeader pageType={PageType.InventoryTransfers}>
        <Box sx={{ ml: 'auto', display: 'flex', my: 'auto', gap: 1 }}>
          <FavoriteButton label="Inventory Transfers" />
          <ExportGridButton canPrint={false} />
          <Button sx={{ ml: 2 }} onClick={handleCreateInventoryTransfer} variant="contained" size="medium">
            New
            <AddIcon sx={{ ml: 1 }} />
          </Button>
        </Box>
      </PageHeader>
      <PageBody>
        <InventoryTransfersGrid />
      </PageBody>
    </>
  );
}
