import { User } from 'types/graphql';

const getFullName = (user?: Pick<User, 'firstName' | 'lastName'>) => {
  if (!user) {
    return '--';
  }

  const { firstName = '', lastName = '' } = user;

  if (!firstName && !lastName) {
    return '--';
  }

  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
};

export default getFullName;
