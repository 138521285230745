import { useMemo, useState } from 'react';

import { Add, Edit } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, MenuItem, Typography } from '@mui/material';

import fuzzyComplete from 'src/utils/fuzzyComplete';

import { DropdownMenuButton } from '../menus/DropdownMenuButton';

import { SlimTextField } from './LabelsContainer';

export default function LabelMenu({ availableLabels, handleAttachLabel, openEditLabelsModal }) {
  const [filter, setFilter] = useState('');

  const fuzzyFilter = fuzzyComplete(['label', 'description']);

  const filteredLabels = useMemo(() => {
    return fuzzyFilter(availableLabels, { inputValue: filter });
  }, [availableLabels, filter, fuzzyFilter]);

  return (
    <DropdownMenuButton
      hint="Add Label"
      ButtonComponent={IconButton}
      sx={{
        width: '20px',
        height: '20px',
        ml: 0.5,
      }}
      Icon={
        <Add
          sx={{
            fontSize: '20px',
          }}
        />
      }
    >
      <Box px={1}>
        <SlimTextField
          label="Filter labels"
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        <Box overflow="scroll" my={1}>
          {filteredLabels.map((labelDefinition) => (
            <MenuItem
              key={labelDefinition.label}
              sx={{
                borderRadius: '4px',
                py: 1,
                px: 1,
              }}
              onClick={() => {
                handleAttachLabel(labelDefinition);
              }}
            >
              <Box>
                <Box display="flex" gap={1} alignItems="center" width="100%">
                  <Box
                    sx={{
                      width: '16px',
                      height: '16px',
                      backgroundColor: labelDefinition.color,
                      borderRadius: '100%',
                      cursor: 'pointer',
                    }}
                  />
                  <Typography fontSize="14px">{labelDefinition.label}</Typography>
                </Box>
                <Typography fontSize="14px" variant="subtitle2" color="text.secondary">
                  {labelDefinition.description}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Box>
        <Divider sx={{ my: 1 }} />

        <Button fullWidth size="small" startIcon={<Edit />} onClick={openEditLabelsModal}>
          Edit Labels
        </Button>
      </Box>
    </DropdownMenuButton>
  );
}
