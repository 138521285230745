import { forwardRef } from 'react';

import { Link } from '@redwoodjs/router';

import { LinkProps } from './Link';

/**
 * This component trasnforms a MUI Link with href into a Redwood Link with to.
 * This is necessary because MUI Link does not support the to prop.
 *
 * @example Usage with Icon button
    <IconButton
      LinkComponent={TransformedLink}
      href={routes.purchaseOrder({ slug: purchaseOrder.slug })}
    >
      ...
    </IconButton>
 */
const TransformedLink = forwardRef<HTMLAnchorElement, LinkProps>(({ href, ...props }, ref) => {
  return <Link to={href} {...props} ref={ref} />;
});

export default TransformedLink;
