import { Prisma } from '@prisma/client';
import { isNil } from 'lodash';
import moment from 'moment';

export const formatFullName = (user: Prisma.UserGetPayload<unknown>) => {
  if (!user) {
    return '--';
  }

  return `${user.firstName} ${user.lastName}`;
};

export const formatDate = (date: Date | null) => {
  if (!date) {
    return '--';
  }

  return moment(date).format('MM/DD/YYYY');
};

export const formatNumber = (value?: number | null) => {
  if (isNil(value)) {
    return '--';
  }

  return value.toLocaleString('en-US');
};

export const formatCurrency = (value?: number | null, currency?: string) => {
  if (isNil(value)) {
    return '--';
  }

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: currency ?? 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
