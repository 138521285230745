export type PricingSheetMeta = {
  id: number;
  draft: boolean;
  enabled: boolean;
  name: string;
  customerGroups?: { id: number; name }[];
  individualCustomers?: { id: number; name }[];
  startDate?: Date;
  expiryDate?: Date;
  pricingFormula?: PricingFormula;
  pricingValue?: number;
  roundingValue?: number;
  entries?: {
    productId: number;
    pricingFormula?: PricingFormula;
    pricingValue?: number;
  }[];
};

export type Status = 'Active' | 'Inactive' | 'Expired' | 'Draft';

export const statusClass: Record<Status, string> = {
  Active: '!bg-green-200 !text-green-900',
  Inactive: '',
  Expired: '!bg-errorLight !text-errorDark',
  Draft: '',
};

export type PricingFormula = 'FLAT_RATE' | 'COST_BASIS_PERCENT_MARGIN' | 'COST_BASIS_FLAT_MARGIN' | 'CUSTOM';

export const pricingFormulaLabels: Record<PricingFormula, string> = {
  FLAT_RATE: 'Flat rate',
  COST_BASIS_PERCENT_MARGIN: 'Cost basis + % margin',
  COST_BASIS_FLAT_MARGIN: 'Cost basis + flat margin',
  CUSTOM: 'Custom',
};

export const pricingFormulaOptions = Object.entries(pricingFormulaLabels).map(([key, value]) => ({
  name: value,
  value: key,
}));

export const roundingValueLabel: Record<number, string> = {
  0: 'No Rounding',
  5: 'Round to the nearest $0.05',
  10: 'Round to the nearest $0.10',
  50: 'Round to the nearest $0.50',
  100: 'Round to the nearest $1.00',
};

export const roundingValueOptions = Object.entries(roundingValueLabel).map(([key, value]) => ({
  name: value,
  value: parseInt(key),
}));
