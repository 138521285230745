import { useMutation } from '@redwoodjs/web';

import { GET_PRICING_SHEET_ENTRIES } from './usePricingSheetEntries';

const EDIT_PRODUCT_COST_BASIS = gql`
  mutation EditProductCostBasis($id: Int!, $costBasis: Int!) {
    changeProductCostBasis(id: $id, costBasis: $costBasis) {
      id
    }
  }
`;

export default function useEditProductCostBasis() {
  const [editProductCostBasis] = useMutation(EDIT_PRODUCT_COST_BASIS, { refetchQueries: [GET_PRICING_SHEET_ENTRIES] });

  return async (id: number, costBasis: number) => {
    return editProductCostBasis({ variables: { id, costBasis } });
  };
}
