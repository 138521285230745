import { ReactNode } from 'react';

import { Close } from '@mui/icons-material';
import { Button, Drawer, Fade, SxProps, useTheme } from '@mui/material';
import { create } from 'zustand';

import { GLOBAL_APP_BAR_HEIGHT } from 'src/components/containers/AppBar';

type SideDrawerStore = {
  content: ReactNode;
  title: string;
  props?: { sx?: SxProps; buttonSx?: SxProps };
  isHidden: boolean;
  openDrawer: (content: ReactNode, title?: string, props?: { sx?: SxProps; buttonSx?: SxProps }) => void;
  closeDrawer: () => void;
  hideDrawer: () => void;
  revealDrawer: () => void;
};

const useSideDrawerState = create<SideDrawerStore>((set) => ({
  content: null,
  title: '',
  isHidden: false,
  openDrawer: (content, title = '', props) => set({ content, title, props, isHidden: false }),
  closeDrawer: () => set({ content: null, isHidden: false }),
  hideDrawer: () => set({ isHidden: true }),
  revealDrawer: () => set({ isHidden: false }),
}));

export const useSideDrawerContent = () => useSideDrawerState((state) => state.content);
export const useSideDrawerTitle = () => useSideDrawerState((state) => state.title);
export const useSideDrawerProps = () => useSideDrawerState((state) => state.props);
export const useSideDrawerVisibility = () => useSideDrawerState((state) => state.isHidden);
export const useSideDrawer = () => {
  return {
    openDrawer: useSideDrawerState((state) => state.openDrawer),
    closeDrawer: useSideDrawerState((state) => state.closeDrawer),
    hideDrawer: useSideDrawerState((state) => state.hideDrawer),
    revealDrawer: useSideDrawerState((state) => state.revealDrawer),
  };
};

export function SideDrawer() {
  const drawerContent = useSideDrawerContent();
  const drawerTitle = useSideDrawerTitle();
  const drawerProps = useSideDrawerProps();
  const isHidden = useSideDrawerVisibility();
  const { closeDrawer } = useSideDrawer();

  const theme = useTheme();

  return (
    <Drawer
      variant="temporary" // Keep the temporary variant
      anchor="right"
      open={!!drawerContent}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          mt: `${GLOBAL_APP_BAR_HEIGHT}px`,
          py: '12px',
          px: '24px',
          pb: `${GLOBAL_APP_BAR_HEIGHT + 36}px`,
          maxWidth: '95vw',
          overflowY: 'auto',
          ...(drawerProps?.sx ?? {}),
          position: 'absolute',
        },
      }}
      SlideProps={{
        style: {
          transform: isHidden ? 'translateX(90%)' : 'translateX(0)',
          transition: 'transform 0.3s ease-in-out',
        },
      }}
    >
      <header className="flex justify-between items-center mb-3">
        <b>{drawerTitle}</b>
        <Button onClick={closeDrawer} className="gap-1" sx={drawerProps?.buttonSx}>
          <Close fontSize="small" />
          <p className="leading-none">Close</p>
        </Button>
      </header>
      <div className="flex h-full">{drawerContent}</div>

      {/* This shade serves to allow dialog models to hide their shade, so that there aren't multiple conflicting shades,
        but keeps the side drawer shaded. */}
      <Fade in={isHidden} timeout={300}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.action.disabledBackground,
            opacity: isHidden ? 1 : 0,
            transition: `opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)`,
            zIndex: 1,
          }}
        />
      </Fade>
    </Drawer>
  );
}
