import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { isNil } from 'lodash';

export default function UnderlinedMatch({ target, input }: { target: string; input: string }) {
  if (isNil(target)) {
    return null;
  }

  const matches = match(target, input, { insideWords: true });
  const parts = parse(target, matches, { insideWords: true });

  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          style={{
            textDecoration: part.highlight ? 'underline' : 'inherit',
          }}
        >
          {part.text}
        </span>
      ))}
    </>
  );
}
