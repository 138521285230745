import { Product } from '../../core';
import { Weight } from '../../core/product';
import { BuySellOrderLineItem, ListTemplate } from '../../types';
import Money from '../../utils/Money';
import { applyTableMetadata, formatCurrency, formatDecimal } from '../templates/formatters';

export type ProductsBSOGridOptions = {
  currencyCode?: string;
};

export type BuySellOrderProductTemplateDataType = Partial<{
  description: string;
  commodity: string;
  variety: string;
  size: string;
  unit: string;
  label: string;
  productCode: string;
  countyOfOrigin: string;
  grade: string;
  organic: string;

  unitsOrdered: string;
  unitsInvoiced: string;
  unitsShipped: string;

  orderedWeight: string;
  invoicedWeight: string;
  shippedWeight: string;

  orderedBuyTotal: string;
  shippedBuyTotal: string;
  invoicedBuyTotal: string;
  orderedSellTotal: string;
  shippedSellTotal: string;
  invoicedSellTotal: string;
}>;

export const productsBsoGrid = (
  lineItems: BuySellOrderLineItem[],
  { currencyCode }: ProductsBSOGridOptions
): ListTemplate<BuySellOrderProductTemplateDataType> => {
  return applyTableMetadata(
    lineItems.map((lineItem) => {
      const { product } = lineItem;

      const weight = Weight.of(product);

      const orderedWeight = weight.forQuantity(lineItem.unitsOrdered);
      const invoicedWeight = weight.forQuantity(lineItem.unitsInvoiced);
      const shippedWeight = weight.forQuantity(lineItem.unitsShipped);

      const buyPrice = Money.toDollars(lineItem.buyCurrencyPrice ?? lineItem.buyPrice ?? 0);

      const sellPrice =
        lineItem.deliveredCurrencyPrice ??
        lineItem.deliveredPrice ??
        lineItem.sellCurrencyPrice ??
        lineItem.sellPrice ??
        0;
      const sellPriceFmt = Money.toDollars(sellPrice);

      const shippedPallets = Math.ceil((lineItem.unitsShipped || 1) / (lineItem.product.cartonsPerPallet || 1));
      const orderedPallets = Math.ceil((lineItem.unitsOrdered || 1) / (lineItem.product.cartonsPerPallet || 1));

      return {
        description: Product.productDescription(product),
        commodity: product.commodity.name,
        variety: product.commodityStyle.name,
        size: product.commoditySize.name,
        unit: product.commodityUnit.name,
        label: product.commodityLabel?.name,
        productCode: product.productCode,
        countyOfOrigin: product.countryOfOrigin || '',
        grade: product.grade,
        organic: product.organic ? 'Organic' : '',
        unitsOrdered: formatDecimal(lineItem.unitsOrdered),
        unitsInvoiced: formatDecimal(lineItem.unitsInvoiced),
        unitsShipped: formatDecimal(lineItem.unitsShipped),
        orderedWeight: formatDecimal(orderedWeight),
        invoicedWeight: formatDecimal(invoicedWeight),
        shippedWeight: formatDecimal(shippedWeight),
        buyPrice: formatCurrency(buyPrice, currencyCode),
        sellPrice: formatCurrency(sellPriceFmt, currencyCode),
        orderedBuyTotal: formatCurrency(buyPrice * (lineItem.unitsOrdered ?? 0), currencyCode),
        shippedBuyTotal: formatCurrency(buyPrice * (lineItem.unitsShipped ?? 0), currencyCode),
        invoicedBuyTotal: formatCurrency(buyPrice * (lineItem.unitsInvoiced ?? 0), currencyCode),
        orderedSellTotal: formatCurrency(sellPriceFmt * (lineItem.unitsOrdered ?? 0), currencyCode),
        shippedSellTotal: formatCurrency(sellPriceFmt * (lineItem.unitsShipped ?? 0), currencyCode),
        invoicedSellTotal: formatCurrency(sellPriceFmt * (lineItem.unitsInvoiced ?? 0), currencyCode),
        orderedPallets: formatDecimal(orderedPallets),
        shippedPallets: formatDecimal(shippedPallets),
      };
    })
  );
};
