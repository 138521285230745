import { useQuery } from '@redwoodjs/web';

import BuySellOrderDocumentApi from '../api/BuySellOrderDocument.api';

export default function useBuySellOrderDocument({ slug, skip = false }) {
  const { data, loading, refetch } = useQuery(BuySellOrderDocumentApi.GetBuySellOrderData, {
    variables: { slug },
    skip,
  });

  return {
    buySellOrder: data?.buySellOrder,
    loading,
    getDocumentData: () => refetch({ slug }).then((res) => res.data.buySellOrder),
  };
}
