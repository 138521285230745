import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import QuickDatePicker from 'src/components/atoms/QuickDatePicker';
import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';

export type StartAccountReconcilingForm = {
  startingDate: DateTime;
  endingDate: DateTime;
};

export const StartAccountReconcilingModal = ({
  isOpen,
  onClose,
  onNext,
}: {
  isOpen: boolean;
  onClose: () => void;
  onNext: (formValues: StartAccountReconcilingForm) => void;
}) => {
  const [startingDate, setStartingDate] = useState<string>(null);
  const [endingDate, setEndingDate] = useState<string>(null);

  const closeModal = () => {
    setStartingDate(null);
    setEndingDate(null);
    onClose();
  };

  const isValid = useMemo(
    () =>
      startingDate !== null &&
      DateTime.fromFormat(startingDate, 'MM/dd/yyyy') !== null &&
      endingDate !== null &&
      DateTime.fromFormat(endingDate, 'MM/dd/yyyy') !== null,
    [startingDate, endingDate]
  );

  const formik = useFormik({
    initialValues: {},
    validateOnChange: false,
    onSubmit: () => {
      onNext({
        startingDate: DateTime.fromISO(startingDate, { zone: 'UTC' }),
        endingDate: DateTime.fromISO(endingDate, { zone: 'UTC' }),
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Reconcile transactions</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Box sx={{ marginTop: 3 }} display="flex" flexDirection="column">
              <QuickDatePicker
                label="Starting date"
                value={startingDate}
                onChange={setStartingDate}
                renderInput={(params) => <TextField {...params} key={3215 /*TS complains */} />}
              />
              <Typography color="text.secondary" variant="caption" sx={{ px: 1.75 }}>
                Enter the starting date you'd like to reconcile from.
              </Typography>
            </Box>

            <Box sx={{ marginTop: 3 }} display="flex" flexDirection="column">
              <QuickDatePicker
                label="Ending date"
                value={endingDate}
                onChange={setEndingDate}
                renderInput={(params) => <TextField {...params} key={3215 /*TS complains */} />}
              />
              <Typography color="text.secondary" variant="caption" sx={{ px: 1.75 }}>
                Enter the ending date you'd like to reconcile to.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button type="submit" disabled={!isValid} onClick={() => formik.handleSubmit()} variant="contained">
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
};
