const GetInvoiceDocumentData = gql`
  query GetInvoiceDocumentData($slug: String!) {
    invoice(slug: $slug) {
      id
      organization {
        label
        logoUrl
        primaryLocation {
          ...PlaceAttributes
        }
      }
      slug
      totalAmountDue
      sentAt
      payments {
        paymentAmount
      }
      salesOrder {
        ...SalesOrderDocumentAttributes
      }
      expenses {
        ...ExpenseAttributes
      }
      referenceNumber
    }
  }
`;

export default {
  GetInvoiceDocumentData,
};
