export default function convertToTitleCase(str: string) {
  if (!str) {
    return '';
  }

  // Remove underscores and split the string into words
  let words = str.replace(/_/g, ' ').split(' ');

  // Capitalize the first letter of each word
  words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  return words.join(' ');
}
