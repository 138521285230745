import { AppBar, Button, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { routes } from '@redwoodjs/router';

import { useIsSmallScreen } from 'src/hooks/screen-sizes';

import TransformedLink from '../atoms/TransformedLink';

import NotificationMenu from './menus/NotificationMenu';
import { UserMenu } from './menus/UserMenu';
import { SearchBar } from './SearchBar';

export const GLOBAL_APP_BAR_HEIGHT = 80;

export default function MainAppBar() {
  const theme = useTheme();
  const flags = useFlags();
  const isSmallScreen = useIsSmallScreen();

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.primary.dark,
        zIndex: theme.zIndex.drawer + 1,
        height: GLOBAL_APP_BAR_HEIGHT,
        display: 'flex',
        boxShadow:
          '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);',
      }}
    >
      <Box className="flex pl-4 pr-3 items-center my-auto" sx={{ gap: isSmallScreen ? 2 : 4 }}>
        <Button
          className="cursor-pointer flex items-center -ml-3"
          href={routes.landing()}
          LinkComponent={TransformedLink}
        >
          <img className="h-[30px]" src={isSmallScreen ? '/icon_light.svg' : '/logo_light.svg'} alt="Agriful" />
        </Button>
        <SearchBar />

        <Box sx={{ display: 'flex', gap: '8px' }}>
          {flags.notifications && <NotificationMenu />}
          <UserMenu />
        </Box>
      </Box>
    </AppBar>
  );
}
