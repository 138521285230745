import { useState } from 'react';

import { ChevronRight } from '@mui/icons-material';
import { Button, Card, CardActions } from '@mui/material';

import { navigate, routes } from '@redwoodjs/router';

import StatusStepper from 'src/components/atoms/StatusStepper';
import { PricingSheetTabProps } from 'src/modules/systemManagement/pricingSheets/containers/EditPricingSheet';
import ProductList from 'src/modules/systemManagement/pricingSheets/editPanels/ProductList';
import Settings from 'src/modules/systemManagement/pricingSheets/editPanels/Settings';

const steps = ['Setup', 'Product list'];

export default function NewPricingSheet({ pricingSheet, editPricingSheet }: PricingSheetTabProps) {
  const [step, setStep] = useState('Setup');
  const [saving, setSaving] = useState(false);

  const next = () => {
    if (step === 'Setup') {
      return setStep('Product list');
    }
    if (step === 'Product list') {
      setSaving(true);
      editPricingSheet({ draft: false, enabled: true }).then(() => {
        navigate(routes.systemManagement({ tab: 'pricing-sheets' }));
      });
    }
  };

  return (
    <main className="flex flex-col gap-4">
      <StatusStepper allStatuses={steps} currentStatus={step} />
      <Card>
        {step === 'Setup' && <Settings pricingSheet={pricingSheet} editPricingSheet={editPricingSheet} />}
        {step === 'Product list' && <ProductList pricingSheet={pricingSheet} editPricingSheet={editPricingSheet} />}
        <CardActions className="border-t border-grey-300 !p-4 justify-end">
          <Button
            disabled={step === 'Setup' ? !pricingSheet.name : saving}
            variant="contained"
            color="primary"
            onClick={next}
            endIcon={step === 'Setup' && <ChevronRight />}
          >
            {step === 'Setup' ? 'Next' : 'Save'}
          </Button>
        </CardActions>
      </Card>
    </main>
  );
}
