export const UpdatePurchaseOrder = gql`
  mutation UpdatePurchaseOrder($id: Int!, $input: UpdatePurchaseOrderInput!) {
    updatePurchaseOrder(id: $id, input: $input) {
      id
    }
  }
`;

export const UpdatePurchaseOrderStatus = gql`
  mutation UpdatePurchaseOrderStatus($id: Int!, $input: UpdatePurchaseOrderStatusInput!) {
    updatePurchaseOrderStatus(id: $id, input: $input) {
      id
      status
      slug
      lineItems {
        id
        lot {
          id
          slug
          warehouseId
        }
      }
    }
  }
`;

export const UpdateSlug = gql`
  mutation updatePurchaseOrderSlug($id: Int!, $slug: String!) {
    updatePurchaseOrderSlug(id: $id, slug: $slug) {
      id
      slug
    }
  }
`;

export const DuplicatePurchaseOrder = gql`
  mutation DuplicatePurchaseOrder($id: Int!, $templateInput: DuplicateOrderTemplateInput) {
    duplicatePurchaseOrder(id: $id, templateInput: $templateInput) {
      id
      slug
    }
  }
`;

export const DeletePurchaseOrder = gql`
  mutation DeletePurchaseOrder($id: Int!) {
    deletePurchaseOrder(id: $id) {
      id
    }
  }
`;

export const UpdatePurchaseOrderFlagged = gql`
  mutation UpdatePurchaseOrderFlagged($id: Int!, $input: FlagPurchaseOrderInput!) {
    updatePurchaseOrderFlagged(id: $id, input: $input) {
      id
    }
  }
`;
