import { useCallback, useState } from 'react';

import { LocationOn, Print, Close } from '@mui/icons-material';
import { Button, Card, IconButton } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import {
  useWarehouseLocations,
  UseWarehouseLocationsResult,
  useWarehouses,
  UseWarehousesResult,
} from 'src/api/warehouses.api';
import Autocomplete from 'src/components/atoms/Autocomplete';
import MaterialSymbol from 'src/components/atoms/MaterialSymbol';
import { useLookupPalletTag, useUpdatePalletTag } from 'src/modules/palletTags/api/palletTags.api';
import { PalletTag } from 'src/modules/palletTags/types';
import { useBarcodeScanner } from 'src/modules/scanning/events/BarcodeScanner';
import { getJSONFromLocalStorage } from 'src/utils/safeStorage';

type AssignLocationFlowProps = {
  /**
   * Pre-selected warehouse when coming from within a lot (non-scanning flow)
   */
  warehouse?: UseWarehousesResult;
  /**
   * Pre-selected pallet tag when coming from clicking "Add Location" on a pallet tag (non-scanning flow)
   */
  palletTag?: PalletTag;
  close?: () => void;
};

enum FlowStep {
  SELECT_WAREHOUSE,
  ASSIGN_PALLET,
  ASSIGN_LOCATION,
}

const SELECTED_WAREHOUSE_LS_KEY = 'Scan-Location-SelectedWarehouse';

export default function AssignLocationFlow(props: AssignLocationFlowProps) {
  const [loading, setLoading] = useState(false);

  const [wh, setWh] = useState<UseWarehousesResult>(
    props.warehouse || getJSONFromLocalStorage(SELECTED_WAREHOUSE_LS_KEY)
  );

  const [step, setStep] = useState<FlowStep>(() => {
    if (!wh) return FlowStep.SELECT_WAREHOUSE;
    if (!props.palletTag) return FlowStep.ASSIGN_PALLET;
    return FlowStep.ASSIGN_LOCATION;
  });

  const warehouses = useWarehouses({ skip: step !== FlowStep.SELECT_WAREHOUSE });
  const setWarehouse = (wh) => {
    setWh(wh);
    localStorage.setItem(SELECTED_WAREHOUSE_LS_KEY, JSON.stringify(wh));
    setStep(FlowStep.ASSIGN_PALLET);
  };

  const changeWarehouse = () => {
    setWh(null);
    setPalletTag(null);
    setLocation(null);
    localStorage.removeItem(SELECTED_WAREHOUSE_LS_KEY);
    setStep(FlowStep.SELECT_WAREHOUSE);
  };

  // Pallet Tag Selection
  const lookupPalletTag = useLookupPalletTag(wh?.place?.id);
  const [palletTag, setPalletTag] = useState<PalletTag>(props.palletTag);
  const [updatePalletTag] = useUpdatePalletTag(() => {
    reset();
    setLoading(false);
    enqueueSnackbar('Pallet Tag Updated', { variant: 'success' });
    props.close?.();
  });

  // Location Selection
  const warehouseLocations = useWarehouseLocations(wh?.place?.id);
  const [location, setLocation] = useState<UseWarehouseLocationsResult>(props.palletTag?.warehouseLocation);

  const reset = useCallback(() => {
    setPalletTag(undefined);
    setLocation(undefined);
    setStep(FlowStep.ASSIGN_PALLET);
  }, []);

  const nextStep = () => {
    if (!palletTag) {
      setStep(FlowStep.ASSIGN_PALLET);
      return;
    }
    if (!location) {
      setStep(FlowStep.ASSIGN_LOCATION);
      return;
    }

    // Done
    setLoading(true);
    updatePalletTag({
      variables: {
        id: palletTag.id,
        input: {
          warehouseLocationId: location?.id ?? null,
        },
      },
    });
  };

  const isNextDisabled = () => {
    if (loading) return true;

    if (step === FlowStep.SELECT_WAREHOUSE) {
      return !wh;
    }
    if (step === FlowStep.ASSIGN_PALLET) {
      return !palletTag;
    }
    if (step === FlowStep.ASSIGN_LOCATION) {
      return !location;
    }
    return false;
  };

  useBarcodeScanner(async (barcode: string) => {
    const scannedLocation = warehouseLocations.find((loc) => loc.slug === barcode);
    if (scannedLocation) {
      if (scannedLocation.id === location?.id) {
        nextStep();
      } else {
        setLocation(scannedLocation);
      }
      return;
    }

    if (palletTag?.tagNumber === barcode) {
      nextStep();
      return;
    }

    const pt = await lookupPalletTag(barcode);
    if (pt) {
      setPalletTag(pt);
      return;
    }

    enqueueSnackbar(`Coud not find a Location or Pallet with scanned code "${barcode}"`, { variant: 'warning' });
  });

  const locationGroupBy = wh?.tagKeys?.length > 0 ? wh?.tagKeys[0] : null;

  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="flex items-center justify-between">
        <p>Assign Location</p>
        <IconButton onClick={props.close} size="small">
          <Close fontSize="small" />
        </IconButton>
      </div>

      {step === FlowStep.SELECT_WAREHOUSE ? (
        <>
          <p>Please select warehouse to start</p>
          {warehouses.map((warehouse) => (
            <Card
              key={warehouse.id}
              onClick={() => setWarehouse(warehouse)}
              className="py-2 px-4 min-w-[50vw] min-h-[64px] flex flex-col text-lg cursor-pointer !shadow-none border border-grey-300 hover:border-green-400"
            >
              <b>{warehouse.place.description}</b>
              <p className="text-sm text-grey-700">{warehouse.place.fullAddress}</p>
            </Card>
          ))}
        </>
      ) : (
        <div className="flex items-center gap-2 justify-between">
          <p>You are in:</p>
          <b className="flex-grow">{wh.place.description}</b>
          {!props.warehouse && (
            <Button size="small" variant="outlined" onClick={changeWarehouse}>
              Change
            </Button>
          )}
        </div>
      )}
      {step === FlowStep.ASSIGN_PALLET && !palletTag && (
        <div className="flex items-center gap-3 p-2 border rounded border-grey-300">
          <MaterialSymbol name="barcode_scanner" />
          <p>Scan the code on the palette tag. The code and relevant information will appear below.</p>
        </div>
      )}
      {palletTag && (
        <div className="flex items-center gap-2">
          <div className="border border-grey-300 p-2 rounded-xl">
            <Print className="text-green-500" />
          </div>
          <div>
            <p className="flex-grow">
              Pallet Tag: <b>{palletTag.tagNumber}</b>
            </p>
          </div>
        </div>
      )}
      {step === FlowStep.ASSIGN_LOCATION && !location && (
        <>
          <p>Scan location tag or search for it manually.</p>
          <Autocomplete<UseWarehouseLocationsResult>
            options={warehouseLocations}
            groupBy={locationGroupBy ? (opt) => opt.tags[locationGroupBy] : null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            value={location}
            onChange={(_, value) => setLocation(value)}
            getOptionLabel={(option) => option?.description ?? ''}
            filterOptions={(options, { inputValue }) => {
              const query = inputValue.toLowerCase().trim();
              return options.filter((o) => {
                const searchStr = `${o.description} ${o.slug} ${Object.values(o.tags).join(' ')}`.toLowerCase();
                return searchStr.includes(query);
              });
            }}
          />
        </>
      )}
      {location && (
        <div className="flex items-center gap-2">
          <div className="border border-grey-300 p-2 rounded-xl">
            <LocationOn className="text-green-500" />
          </div>
          <div>
            <p className="flex-grow">
              Location: <b>{location.description}</b>
            </p>
          </div>
        </div>
      )}
      {((step === FlowStep.ASSIGN_LOCATION && location) || (step === FlowStep.ASSIGN_PALLET && palletTag)) && (
        <p className="text-sm text-grey-700">To proceed scan the same code or click “Next”</p>
      )}
      <div className="flex justify-end gap-2">
        {step !== FlowStep.SELECT_WAREHOUSE && (
          <>
            <Button onClick={reset}>Reset</Button>
            <Button variant="contained" onClick={nextStep} disabled={isNextDisabled()}>
              {step === FlowStep.ASSIGN_LOCATION ? 'Assign' : 'Next'}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
