import { DateTime } from 'luxon';
import { Box, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export type ClosingPeriodInfoProps = {
  closedAt?: DateTime | null;
};

export const ClosingPeriodInfo = ({ closedAt }: ClosingPeriodInfoProps) => {
  const text = closedAt ? `Previous period closed on ${closedAt.toLocaleString()}` : 'Period open';

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography color="text.secondary" display="flex" flexDirection="row" alignItems="center">
        <InfoOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> {text}
      </Typography>
    </Box>
  );
};
