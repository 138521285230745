import { useState } from 'react';

import { Delete, MoreVert } from '@mui/icons-material';
import { Box, Fade, FormControl, FormHelperText, IconButton, ListItemIcon, MenuItem, TextField } from '@mui/material';
import { fromUTC } from 'shared/utils/DateTime';

import { useAuth } from 'src/auth';
import convertToTitleCase from 'src/utils/convertToTitleCase';

import { DropdownMenuButton } from '../menus/DropdownMenuButton';

export type CommentProps = {
  text: string;
  createdAt: string;
  external?: boolean;
  membership: {
    user?: {
      id?: number;
      firstName?: string;
      lastName?: string;
    };
  };
  contact?: {
    id: number;
    personFirstName?: string;
    personLastName?: string;
  };
  current?: boolean;
  id: number;
  documentTypes?: string[];
  customDocumentTypes?: {
    documentTemplateId: number;
  }[];
};

export default function Comment({
  id,
  text,
  membership,
  contact,
  createdAt,
  external,
  documentTypes = [],
  customDocumentTypes = [],
  customDocumentTypeOptions = [],
  handleDelete,
}: CommentProps & {
  handleDelete: (id: number) => void;
}) {
  const firstName = membership?.user.firstName || contact?.personFirstName;
  const lastName = membership?.user.lastName || contact?.personLastName;

  // flip the above
  const customDocuments = customDocumentTypeOptions
    .filter(({ id }) => customDocumentTypes.some(({ documentTemplateId }) => documentTemplateId === id))
    .map(({ name }) => name);

  const attachedDocs = [...documentTypes.map((type) => convertToTitleCase(type)), ...customDocuments];
  const docsLabel = attachedDocs?.length > 0 ? attachedDocs.join(', ') : ' No documents';
  const [hovered, setHovered] = useState(false);

  const date = fromUTC(createdAt)?.toFormat('DD');

  return (
    <FormControl sx={{ width: '100%', pt: 1.5 }}>
      <TextField
        defaultValue={text}
        inputProps={{ readOnly: true }}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        InputProps={{
          endAdornment: (
            <Box display="flex" justifyContent="center">
              <DropdownMenuButton
                ButtonComponent={IconButton}
                sx={{
                  ml: 1,
                  width: '23px',
                  height: '23px',
                }}
                Icon={
                  <Fade in={hovered}>
                    <MoreVert fontSize="small" />
                  </Fade>
                }
              >
                <MenuItem
                  onClick={() => {
                    handleDelete(id);
                  }}
                >
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              </DropdownMenuButton>
            </Box>
          ),
        }}
        multiline
      />
      {external && (
        <FormHelperText
          sx={{
            textAlign: 'right',
            position: 'absolute',
            px: 0.5,
            backgroundColor: 'background.paper',
            top: '-1px',
            right: '-4px',
          }}
        >
          {docsLabel}
        </FormHelperText>
      )}
      <FormHelperText
        sx={{
          textAlign: 'right',
          position: 'absolute',
          px: 0.5,
          backgroundColor: 'background.paper',
          top: '-1px',
          left: '-4px',
          display: 'flex',
          gap: 0.75,
        }}
      >
        <strong>
          {firstName} {lastName}
        </strong>
        {date}
      </FormHelperText>
    </FormControl>
  );
}
