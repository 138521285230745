import { useMemo } from 'react';

import { Box, Card, Chip, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { getGridNumericOperators, useGridApiRef } from '@mui/x-data-grid-premium';
import type { Hit } from 'instantsearch.js';
import _ from 'lodash';

import { routes } from '@redwoodjs/router';
import type { CellFailureProps, CellSuccessProps, TypedDocumentNode } from '@redwoodjs/web';

import ExpandAllButton from 'src/components/atoms/buttons/ExpandAllButton';
import { HierarchicalGroupingCell } from 'src/components/atoms/HierarchicalGroupingCell';
import Link from 'src/components/atoms/Link';
import DataGrid, { GridColObj } from 'src/components/containers/grids/DataGrid';
import useGridExport from 'src/hooks/useGridExport';
import { LedgerAccountIndex } from 'src/lib/algolia';
import { accounts } from 'src/lib/theme';
import { usePersistentSearch } from 'src/providers/SearchBoxProvider';
import { formatCurrency, dateCellFormatter } from 'src/utils/grid/cell/cellFormatters';

export const QUERY: TypedDocumentNode<any, any> = gql`
  query chartOfAccountsPage($ledgerAccountKeys: [String!]!) {
    organization {
      closedAt
    }

    chartOfAccountsPage(ledgerAccountKeys: $ledgerAccountKeys) {
      key
      organizationId
      path
      number
      name
      type
      updatedAt
      ownBalance
      childBalance
    }
  }
`;

export const beforeQuery = (props: LedgerAccountsProps) => {
  const { hits } = props;

  if (hits.length === 0) {
    // TODO can we short circuit to the EMPTY rendering?
    return {
      variables: {
        ledgerAccountKeys: [],
      },
    };
  }

  return {
    variables: {
      ledgerAccountKeys: hits.map((h) => h.path.join('/')),
    },
  };
};

type LedgerAccountsProps = {
  hits: Hit<LedgerAccountIndex>[];
  exportZeroBalances?: boolean;
};

type AnnotatedLedgerAccountProps = {
  chartOfAccountsPage?: {
    key;
    path;
    updatedAt;
    ownBalance;
    childBalance;
  }[];
} & LedgerAccountsProps;

const pathToString = (path: string[]) => path.join('/');

const LedgerAccounts = ({ hits, chartOfAccountsPage, exportZeroBalances }: AnnotatedLedgerAccountProps) => {
  const { query } = usePersistentSearch();
  const isSearching = useMemo(() => !!query, [hits]);

  if (isSearching) {
    return (
      <SearchLedgerAccounts
        hits={hits}
        exportZeroBalances={exportZeroBalances}
        chartOfAccountsPage={chartOfAccountsPage}
      />
    );
  } else {
    return (
      <ListLedgerAccounts
        hits={hits}
        exportZeroBalances={exportZeroBalances}
        chartOfAccountsPage={chartOfAccountsPage}
      />
    );
  }
};

const ListLedgerAccounts = ({ hits, chartOfAccountsPage, exportZeroBalances }: AnnotatedLedgerAccountProps) => {
  const apiRef = useGridApiRef();

  const annotatedAccounts = _.keyBy(chartOfAccountsPage ?? [], (a) => pathToString(a.path));

  const rows = hits.map((h) => {
    const annotationKey = pathToString(h.path);
    const balanceInCents =
      (annotatedAccounts[annotationKey] &&
        annotatedAccounts[annotationKey].ownBalance + annotatedAccounts[annotationKey].childBalance) ||
      0;

    return {
      key: h.objectID,
      accountNumber: h.number,
      accountName: h.name,
      accountType: h.type
        .toString()
        .match(/[A-Z][a-z]+|[0-9]+/g)
        .join(' '),
      accounts: 0,
      balance: balanceInCents / 100,
      path: h.path,
    };
  });

  const exportOptions = useMemo(
    () => ({
      getRowsToExport: () => {
        if (!exportZeroBalances) {
          return rows.filter((row) => row.balance !== 0).map((row) => row.key);
        }

        return rows.map((row) => row.key);
      },
      exportZeroBalances,
    }),
    [exportZeroBalances, rows]
  );

  useGridExport(apiRef, 'ledger-accounts', exportOptions, exportOptions);

  const columnDefinitions: GridColObj = {
    accountName: {
      headerName: 'Account Name',
      sortable: true,
      flex: 1,
    },
    accountType: {
      headerName: 'Account Type',
      width: 200,
      maxWidth: 500,
      sortable: false,
      renderCell: (params) => <Chip label={params.row.accountType?.toString()} />,
    },
    accounts: {
      headerName: 'Sub-Accounts',
      width: 150,
      maxWidth: 150,
      sortable: false,
      valueGetter: (_value, row, _column, apiRef) => apiRef.current.getRowNode(row.id)?.children?.length,
    },
    balance: {
      headerName: 'Balance',
      flex: 1,
      sortable: false,
      valueFormatter: (value) => formatCurrency({ value }),
      renderCell: (params) => (
        <HierarchicalGroupingCell params={params} toggle={false}>
          <Typography fontSize="14px" sx={{ paddingLeft: 0.5 }}>
            {params.formattedValue}
          </Typography>
        </HierarchicalGroupingCell>
      ),
      filterOperators: getGridNumericOperators(),
    },
  };

  const toolbar = useMemo(
    () => ({
      searchbarLabel: 'Search by account number or keyword',
      useSearchbar: true,
      BeforeButtons: () => <ExpandAllButton apiRef={apiRef} />,
    }),
    [apiRef]
  );

  return (
    <Card sx={{ px: 3, py: 3 }}>
      <ThemeProvider theme={accounts.dataGridTheme}>
        <DataGrid
          treeData
          defaultGroupingExpansionDepth={3}
          groupingColDef={{
            headerName: 'Account Number',
            renderCell: (params) => (
              <HierarchicalGroupingCell params={params} toggle={true}>
                <Link
                  to={routes.accountRegister({ path: pathToString(params.row.path) })}
                  label={params.row.accountNumber}
                  alignItems="center"
                  sx={{ paddingRight: 0.5, paddingLeft: 1 }}
                />
              </HierarchicalGroupingCell>
            ),
            valueGetter: (_value, row) => row.accountNumber,
            sortComparator: (a, b) => (a > b ? 1 : -1),
            sortable: true,
          }}
          rowGroupingColumnMode="single"
          getRowId={(row) => row.key}
          rows={rows}
          columns={columnDefinitions}
          storageKey="ledger-accounts"
          apiRef={apiRef}
          getTreeDataPath={(row) => row.path}
          toolbar={toolbar}
          rowHeight={47}
          sx={accounts.dataGridStyling}
          disableChildrenSorting={true}
          disableMultipleColumnsSorting={true}
          initialState={{
            sorting: {
              sortModel: [{ field: '__tree_data_group__', sort: 'asc' }],
            },
          }}
        />
      </ThemeProvider>
    </Card>
  );
};

const SearchLedgerAccounts = ({ hits, chartOfAccountsPage, exportZeroBalances }: AnnotatedLedgerAccountProps) => {
  const apiRef = useGridApiRef();
  const annotatedAccounts = _.keyBy(chartOfAccountsPage ?? [], (a) => pathToString(a.path));

  const rows = hits.map((h) => {
    const annotationKey = pathToString(h.path);
    const balanceInCents = (annotatedAccounts[annotationKey] && annotatedAccounts[annotationKey].currentBalance) || 0;

    return {
      key: h.objectID,
      accountNumber: h.number,
      accountName: h.name,
      accountType: h.type
        .toString()
        .match(/[A-Z][a-z]+|[0-9]+/g)
        .join(' '),
      lastUpdatedAt: h.updatedAt,
      balance: balanceInCents / 100,
      path: h.path,
      displayPath: h.displayPath,
    };
  });

  const exportOptions = useMemo(
    () => ({
      getRowsToExport: () => {
        if (!exportZeroBalances) {
          return rows.filter((row) => row.balance !== 0).map((row) => row.key);
        }

        return rows.map((row) => row.key);
      },
      exportZeroBalances,
    }),
    [exportZeroBalances, rows]
  );

  useGridExport(apiRef, 'ledger-accounts-search', exportOptions, exportOptions);

  const columnDefinitions: GridColObj = {
    accountNumber: {
      headerName: 'Account Number',
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Link to={routes.accountRegister({ path: pathToString(params.row.path) })} label={params.row.accountNumber} />
          <Typography color="text.secondary" variant="subtitle2" sx={{ marginTop: 0.75 }}>
            {params.row.displayPath.join(' > ')}
          </Typography>
        </Box>
      ),
    },
    accountName: {
      headerName: 'Account Name',
      flex: 1,
    },
    accountType: {
      headerName: 'Account Type',
      width: 200,
      maxWidth: 500,
      renderCell: (params) => <Chip label={params.row.accountType?.toString()} />,
    },
    lastUpdatedAt: {
      headerName: 'Last updated',
      width: 140,
      maxWidth: 140,
      valueFormatter: dateCellFormatter,
    },
    balance: {
      headerName: 'Balance',
      flex: 1,
      valueFormatter: (value) => formatCurrency({ value }),
    },
  };

  const toolbar = useMemo(
    () => ({
      searchbarLabel: 'Search by account number or keyword',
      useSearchbar: true,
      BeforeButtons: () => <ExpandAllButton apiRef={apiRef} />,
    }),
    [apiRef]
  );

  return (
    <Card sx={{ px: 3, py: 3 }}>
      <ThemeProvider theme={accounts.dataGridTheme}>
        <DataGrid
          getRowId={(row) => row.key}
          rows={rows}
          columns={columnDefinitions}
          storageKey="ledger-accounts-search"
          apiRef={apiRef}
          toolbar={toolbar}
          rowHeight={71}
          sx={accounts.dataGridStyling}
        />
      </ThemeProvider>
    </Card>
  );
};

// TODO TAS-764 Loading animation
export const Loading = ({ hits }: CellSuccessProps<LedgerAccountsProps>) => {
  return <LedgerAccounts hits={hits} />;
};

export const Empty = ({ hits, chartOfAccountsPage }: CellSuccessProps<AnnotatedLedgerAccountProps>) => {
  return <LedgerAccounts hits={hits} chartOfAccountsPage={chartOfAccountsPage} />;
};

export const Failure = ({ error }: CellFailureProps) => <div style={{ color: 'red' }}>Error: {error?.message}</div>;

export const Success = ({
  hits,
  chartOfAccountsPage,
  exportZeroBalances,
}: CellSuccessProps<AnnotatedLedgerAccountProps>) => {
  return (
    <LedgerAccounts hits={hits} chartOfAccountsPage={chartOfAccountsPage} exportZeroBalances={exportZeroBalances} />
  );
};
