import { useCallback, useMemo } from 'react';

import computeFullAddress from 'api/src/lib/shared/computefullAddress';
import type { FindOrderBoardQuery, FindOrderBoardQueryVariables } from 'types/graphql';

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web';

import { useBoardStore } from 'src/pages/OrderBoardPage/OrderBoardPage';
import getFullName from 'src/utils/getFullName';

import OrderBoard from '../LandingCell/components/OrderBoard';

export const QUERY = gql`
  query FindOrderBoardQuery(
    $dateRange: [DateTime!]!
    $purchaseOrderStatuses: [PurchaseOrderStatus!]!
    $salesOrderStatuses: [SalesOrderStatus!]!
    $workOrderStatuses: [WorkOrderStatus!]!
  ) {
    purchaseOrders(statuses: $purchaseOrderStatuses, receiveDateRange: $dateRange) {
      id
      slug
      flagged
      createdAt
      updatedAt
      status
      businessEntity {
        id
        name
      }
      receiveDate
      shipment {
        # ship from
        place {
          ...PlaceAttributes
        }
        # warehouse
        destination {
          ...PlaceAttributes
        }
      }
      lineItems {
        id
        product {
          description
        }
        unitsOrdered
        unitsReceived
        buyPrice
      }
      buyer {
        user {
          firstName
          lastName
        }
      }
      expenses {
        id
        description
        category
        quantity
        unitAmount
        includedInDlvPrice
      }
      vendorPo
    }
    salesOrders(statuses: $salesOrderStatuses, shipDateRange: $dateRange) {
      id
      slug
      flagged
      createdAt
      updatedAt
      status
      shipDate
      businessEntity {
        id
        name
      }
      salesPerson {
        user {
          firstName
          lastName
        }
      }
      shipment {
        # ship to
        place {
          ...PlaceAttributes
        }
        # warehouse
        origin {
          ...PlaceAttributes
        }
      }
      customerPo
      lineItems {
        id
        product {
          description
        }
        unitsOrdered
        unitsReceived
        sellPrice: deliveredPrice
      }
      expenses {
        id
        description
        category
        quantity
        unitAmount
        includedInDlvPrice
      }
    }
    workOrders(statuses: $workOrderStatuses, deliveryDateRange: $dateRange) {
      id
      slug
      flagged
      createdAt
      updatedAt
      status
      shipment {
        deliveryDate
      }
      grower {
        id
        name
      }
      harvestLocation
      fieldTicketId
      shipment {
        # warehouse
        destination {
          ...PlaceAttributes
        }
      }
      lineItems {
        id
        product {
          description
        }
        unitsOrdered
      }
      # TODO: Add Membership relation to WorkOrder
      # broker {
      #   user {
      #     firstName
      #     lastName
      #   }
      # }
    }
  }
`;

const getWarehouseLabel = (warehouse) => {
  return warehouse?.description ?? computeFullAddress(warehouse);
};

const mapPOToRow = ({
  vendorPo,
  slug,
  businessEntity,
  buyer,
  shipment,
  receiveDate,
  ...matching
}: FindOrderBoardQuery['purchaseOrders'][number]) => {
  return {
    slug,
    prefixedSlug: `PO #${slug}`,
    route: 'purchaseOrder',
    customer: businessEntity?.name,
    salesPerson: getFullName(buyer?.user),
    customerOrderNumber: vendorPo,
    customerLocation: computeFullAddress(shipment?.place),
    warehouse: getWarehouseLabel(shipment?.destination),
    warehouseId: shipment?.destination?.id,
    date: receiveDate,
    ...matching,
  };
};

const mapSOToRow = ({
  slug,
  customerPo,
  businessEntity,
  salesPerson,
  shipment,
  shipDate,
  ...matching
}: FindOrderBoardQuery['salesOrders'][number]) => {
  return {
    slug,
    prefixedSlug: `SO #${slug}`,
    route: 'salesOrder',
    customer: businessEntity?.name,
    salesPerson: getFullName(salesPerson?.user),
    customerOrderNumber: customerPo,
    customerLocation: computeFullAddress(shipment?.place),
    warehouse: getWarehouseLabel(shipment?.origin),
    warehouseId: shipment?.origin?.id,
    date: shipDate,
    ...matching,
  };
};

const mapWOToRow = ({
  slug,
  grower,
  shipment,
  harvestLocation,
  fieldTicketId,
  ...matching
}: FindOrderBoardQuery['workOrders'][number]) => {
  return {
    slug,
    prefixedSlug: `GP #${slug}`,
    route: 'workOrder',
    customer: grower?.name,
    salesPerson: '--',
    customerOrderNumber: fieldTicketId,
    customerLocation: harvestLocation,
    warehouse: getWarehouseLabel(shipment?.destination),
    warehouseId: shipment?.destination?.id,
    date: shipment?.deliveryDate,
    ...matching,
  };
};

export const Loading = () => <div>Loading...</div>;

export const Empty = () => (
  <OrderBoard
    orders={{
      PurchaseOrder: [],
      SalesOrder: [],
      WorkOrder: [],
    }}
  />
);

export const Failure = ({ error }: CellFailureProps<FindOrderBoardQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
);

export const Success = (orderBoard: CellSuccessProps<FindOrderBoardQuery, FindOrderBoardQueryVariables>) => {
  const orderTypes = useBoardStore((state) => state.orderTypes);
  const warehouses = useBoardStore((state) => state.warehouses);

  const ordersForWarehousesFilter = useCallback(
    (order) => {
      return warehouses.some((warehouse) => warehouse.id === order.warehouseId);
    },
    [warehouses]
  );

  const orders = useMemo(() => {
    const ordersByType = {
      PurchaseOrder: [],
      SalesOrder: [],
      WorkOrder: [],
    };

    if (orderTypes.some((type) => type.value === 'PurchaseOrder')) {
      ordersByType.PurchaseOrder = orderBoard.purchaseOrders.map(mapPOToRow).filter(ordersForWarehousesFilter);
    }

    if (orderTypes.some((type) => type.value === 'SalesOrder')) {
      ordersByType.SalesOrder = orderBoard.salesOrders.map(mapSOToRow).filter(ordersForWarehousesFilter);
    }

    if (orderTypes.some((type) => type.value === 'WorkOrder')) {
      ordersByType.WorkOrder = orderBoard.workOrders.map(mapWOToRow).filter(ordersForWarehousesFilter);
    }

    return ordersByType;
  }, [orderBoard, orderTypes, ordersForWarehousesFilter]);

  return <OrderBoard orders={orders} />;
};
