import { FC } from 'react';

import { PropsOf } from '@emotion/react';
import { FormControl, FormControlProps, InputLabel, TextField } from '@mui/material';
import { FieldInputProps, useField, useFormikContext } from 'formik';

export default function FormField<T extends FC, P = PropsOf<T>>({
  Field = TextField,
  name,
  fieldProps,
  inputLabel,
  continuousSync = true,
  children,
  hideInputLabel,
  useValue,
  onChangeValue,
  ...controlProps
}: {
  Field?: T | typeof TextField;
  fieldProps: P;
  inputLabel?: string;
  name: string;
  continuousSync?: boolean;
  target?: '';
  hideInputLabel?: boolean;
  useValue?: boolean;
  onChangeValue?: (value: any, previousValue: any) => void;
} & FormControlProps) {
  const { handleSubmit } = useFormikContext();
  const [field, props, helpers] = useField(name);
  const InputField = Field as FC<P & FieldInputProps<unknown>>;

  // some events pass their value as the second argument,
  // others as the event target value
  const handleChange = (event: React.ChangeEvent<HTMLFormElement>, value) => {
    const newValue = useValue ? value : event?.target?.value || value;

    // TODO: Refactor
    helpers.setValue(newValue, false);

    if (continuousSync) {
      // formik doesn't update the form values immediately,
      // so we need to wait for the next tick
      setTimeout(() => {
        handleSubmit(event);
      }, 0);
    }

    // use a different on Change so
    onChangeValue?.(newValue, field.value);
  };

  return (
    <FormControl {...controlProps}>
      {/* TODO: Refactor */}
      {inputLabel && !hideInputLabel && <InputLabel>{inputLabel}</InputLabel>}
      <InputField
        label={inputLabel}
        {...field}
        {...fieldProps}
        onChange={handleChange}
        error={props.touched && !!props.error}
      >
        {children}
      </InputField>
    </FormControl>
  );
}
