import { useMemo } from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid-premium';

import { routes } from '@redwoodjs/router';

import ErroredCell from 'src/components/atoms/grid/ErroredCell';
import Link from 'src/components/atoms/Link';
import StatusBadge from 'src/components/atoms/StatusBadge';
import { workOrderStatuses } from 'src/components/atoms/StatusStepper';
import { GridColObj } from 'src/components/containers/grids/DataGrid';
import { getDistinctValues } from 'src/modules/grids/toolbar/filters/tools';
import { dateCellFormatter, dateGetter } from 'src/utils/grid/cell/cellFormatters';
import columnActions from 'src/utils/grid/column/columnActions';

export function useGrowerProductsColumns(handleDeleteRow, handleFlagRow): GridColObj {
  return useMemo(
    () => ({
      slug: {
        headerName: 'Order Number',
        type: 'string',
        minWidth: 110,
        flex: 1,
        renderCell: ({ row, value }: GridRenderCellParams) => {
          return (
            <ErroredCell showErrorIndicator={row.flagged} errorMessage="Order is flagged">
              <Link to={routes.growerProduct({ slug: row.slug })} label={`GP #${value}`} />
            </ErroredCell>
          );
        },
      },
      submittedAt: {
        headerName: 'Order Date',
        type: 'date',
        minWidth: 115,
        valueGetter: (value) => dateGetter(value),
        valueFormatter: dateCellFormatter,
      },
      'shipment.deliveryDate': {
        headerName: 'Receive Date',
        type: 'date',
        width: 115,
        flex: 1,
        valueFormatter: dateCellFormatter,
        valueGetter: (_value, row) => dateGetter(row.shipment?.deliveryDate),
      },
      status: {
        headerName: 'Status',
        type: 'string',
        minWidth: 110,
        flex: 1,
        getFilterSelectOptions: async () => workOrderStatuses,
        renderCell: (params: GridRenderCellParams) => (
          <StatusBadge status={params.row.isTemplate ? 'TEMPLATE' : params.value} context="purchaseOrder" />
        ),
      },
      flagged: {
        headerName: 'Flagged',
        hideable: false,
        type: 'boolean',
      },
      'grower.name': {
        headerName: 'Grower',
        type: 'string',
        minWidth: 100,
        flex: 1,
        valueGetter: (_value, row) => row.grower?.name,
        getFilterSelectOptions: (client) => getDistinctValues(client, 'GrowerProduct.grower.name'),
      },
      'growerContact.name': {
        headerName: 'Grower Contact',
        minWidth: 150,
        flex: 1,
        type: 'string',
        valueGetter: (_value, row) => row.growerContact?.name,
        getFilterSelectOptions: (client) => getDistinctValues(client, 'GrowerProduct.growerContact.name'),
      },
      fieldTicketId: {
        headerName: 'Field Ticket #',
        type: 'string',
        minWidth: 100,
        flex: 1,
      },
      harvestLocation: {
        headerName: 'Block/Ranch',
        type: 'string',
        minWidth: 100,
        flex: 1,
        valueGetter: (_value, row) => row.harvestLocation,
      },
      harvestedAt: {
        headerName: 'Harvest Date',
        type: 'date',
        minWidth: 115,
        flex: 1,
        valueFormatter: dateCellFormatter,
      },
      actions: {
        type: 'actions',
        width: 80,
        headerName: 'Actions',
        getActions: columnActions((params) => {
          const actions: Record<string, unknown> = {
            flagRow: (flagged, { id }) => {
              return handleFlagRow(id, flagged);
            },
            isFlagged: params.row.flagged,
          };

          if (params.row.status === 'DRAFT') {
            actions.deleteRow = ({ row }) => {
              handleDeleteRow(row);
            };
          }

          return actions;
        }),
      },
    }),
    [handleDeleteRow, handleFlagRow]
  );
}
