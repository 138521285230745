import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { asUTCDate, parseDate } from 'shared/utils/DateTime';

export default function QuickDatePicker({ ...props }: DesktopDatePickerProps<string | DateTime | Date, string>) {
  const onChange = (value, keyboardInput) => {
    if (value === null) {
      props.onChange(null, keyboardInput);
      return;
    }

    // set zone to UTC to ensure server does not offset time
    const date = parseDate(value)?.setZone('utc', {
      keepLocalTime: true,
    });

    if (!date?.isValid) {
      return;
    }

    props.onChange(date.toISO(), keyboardInput);
  };

  return (
    <DesktopDatePicker
      {...props}
      value={asUTCDate(props.value)}
      inputFormat="MM/dd/yyyy"
      InputProps={{
        onKeyDown: (e) => {
          // move cursor forward if last character is a slash
          if (e.key === '/') {
            const input = e.target as HTMLInputElement;
            const { selectionStart, selectionEnd } = input;

            const noSelection = selectionStart === selectionEnd;
            const lastChar = input.value[input.value.length - 1];
            const cursorBeforeEnd = selectionStart === input.value.length - 1;

            if (noSelection && lastChar === '/' && cursorBeforeEnd) {
              input.setSelectionRange(selectionStart + 1, selectionEnd + 1);
              e.preventDefault();
            }
          }

          if (e.key === 'Enter' || e.key === 'Tab') {
            const input = e.target as HTMLInputElement;
            const [first, second, third] = input.value.split('/');
            let day: string, month: string;
            // since we're using user input, we want to match with user's local time.
            // it'll be converted to a zero'd out UTC date object via onChange
            let assumed = DateTime.local();

            if (third) {
              if (third.length === 2) {
                assumed = assumed.set({ year: parseInt(`20${third})`) });
              } else {
                return;
              }
            }

            if (!second) {
              day = first;
            } else {
              month = first;
              day = second;
            }

            assumed = assumed.set({ day: parseInt(day) });

            if (month) {
              assumed = assumed.set({ month: parseInt(month) });
            }

            onChange(assumed.toISO(), input.value);
          }
        },
      }}
      onChange={onChange}
    />
  );
}
