import { Prisma } from '@prisma/client';
import { ExpenseAccountLevel } from 'types/graphql';

import { USD } from 'src/lib/money/usd';

/**
 * Computes the cost for a single Expense
 */
export const computeExpenseCost = (unitAmount: number, quantity: number): USD => {
  return USD.fromCents(unitAmount).multiply(quantity);
};

/**
 * Sums the total cost of a list of Expenses by their accountLevel
 */
export const computeTotalExpenseCost = (
  expenses: Array<Prisma.ExpenseGetPayload<unknown>>,
  unitAmountKey: 'unitAmount' | 'currencyAmount' = 'unitAmount'
): Record<ExpenseAccountLevel, USD> => {
  const totalExpenseCost: Record<ExpenseAccountLevel, USD> = {
    ACCOUNTS_PAYABLE: USD.fromCents(0),
    ACCOUNTS_RECEIVABLE: USD.fromCents(0),
    GROWER_PAYABLE: USD.fromCents(0),
  };

  expenses.forEach((expense) => {
    const { currencyAmount, quantity, accountLevel } = expense;

    const expenseCost = computeExpenseCost(
      expense[unitAmountKey] ?? currencyAmount ?? expense['unitAmount'], // Always fall back on `unitAmount`
      quantity
    );

    totalExpenseCost[accountLevel] = totalExpenseCost[accountLevel].add(expenseCost);
  });

  return totalExpenseCost;
};
