import { Box } from '@mui/material';

export type OrderInfoProps = {
  statusStepper?: React.ReactNode;
  orderMetrics?: React.ReactNode;
};

export default function OrderInfo({ statusStepper, orderMetrics }: OrderInfoProps) {
  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{
          mb: 1.5,
          flexGrow: 1,
          maxWidth: '1400px',
        }}
      >
        {statusStepper}

        {orderMetrics && (
          <Box gap={2} display="flex" flexWrap="wrap">
            {orderMetrics}
          </Box>
        )}
      </Box>
    </Box>
  );
}
