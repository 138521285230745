import { useEffect, useState } from 'react';

import { TextField } from '@mui/material';
import { useDebounce } from 'usehooks-ts';

import { PricingFormula } from 'src/modules/systemManagement/pricingSheets/constants';
import useEditPricingSheetEntry from 'src/modules/systemManagement/pricingSheets/hooks/useEditPricingSheetEntry';
import { calculateMarginAndPrice } from 'src/modules/systemManagement/pricingSheets/utils';
import money from 'src/utils/money';

type PricingValueCellProps = {
  entryId: number;
  pricingFormula: PricingFormula;
  pricingValue?: number;
  sheetRoundingValue?: number;
  costBasis?: number;
};

function getPricingValue(formula: PricingFormula, value: number) {
  let val = value;
  if (formula === 'COST_BASIS_FLAT_MARGIN') {
    val = money.toDollars(val);
  }
  return val ? val.toString() : '';
}

export default function PricingValueCell(props: PricingValueCellProps) {
  const [val, setVal] = useState(getPricingValue(props.pricingFormula, props.pricingValue));
  const editPricingSheetEntry = useEditPricingSheetEntry();

  const debouncedVal = useDebounce(parseFloat(val), 500);

  useEffect(() => {
    setVal(getPricingValue(props.pricingFormula, props.pricingValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pricingFormula, props.pricingValue]);

  useEffect(() => {
    editPricingSheetEntry(props.entryId, {
      pricingValue: props.pricingFormula === 'COST_BASIS_FLAT_MARGIN' ? money.toCents(debouncedVal) : debouncedVal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedVal]);

  if (!props.pricingFormula || props.pricingFormula === 'FLAT_RATE') return <span className="text-grey-500">n/a</span>;

  let dollarValue = '';
  if (props.pricingFormula === 'COST_BASIS_PERCENT_MARGIN' && val) {
    const { margin } = calculateMarginAndPrice(
      props.costBasis,
      props.pricingFormula,
      debouncedVal,
      props.sheetRoundingValue
    );
    if (!isNaN(margin)) {
      dollarValue = money.toHuman(margin);
    }
  }

  return (
    <TextField
      variant="standard"
      type="number"
      value={val}
      onChange={(e) => setVal(e.target.value)}
      InputProps={{
        className: 'h-full w-full -mb-[1px] before:!bottom-[-1px]',
        startAdornment: (
          <span className="text-grey-500">{props.pricingFormula === 'COST_BASIS_PERCENT_MARGIN' ? '% ' : '$ '}</span>
        ),
        endAdornment: dollarValue ? <span className="text-grey-500">({dollarValue})</span> : undefined,
      }}
      className="h-full w-full"
    />
  );
}
