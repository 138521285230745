import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page } from '@react-pdf/renderer';

import { USD } from 'src/lib/money/usd';
import computeTotalCartons from 'src/services/lineItems/utils/computeTotalCartons';
import computeTotalPallets from 'src/services/lineItems/utils/computeTotalPallets';
import computeTotalWeight from 'src/services/lineItems/utils/computeTotalWeight';
import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';

import { Expenses, Footer, Notes, ProductsGrid, SalesOrderHeader, TermsAndTotals, getUnitPrice } from '../sections';
import { formatCurrency } from '../utils/formatters';
import { docStyles } from '../utils/styles';

export type BSOSalesOrderTemplateProps = Prisma.BuySellOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    broker: { include: { user: true; subsidiary: true } };
    vendor: true;
    vendorContact: true;
    customer: true;
    customerContact: true;
    shipment: true;
    subsidiary: true;
    lineItems: {
      include: {
        product: {
          include: {
            commodity: true;
            commodityLabel: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
          };
        };
      };
    };
    expenses: {
      include: {
        businessEntity: true;
      };
    };
    notes: {
      include: {
        membership: {
          include: {
            user: true;
          };
        };
      };
    };
  };
}>;

export function BSOSalesOrderTemplate(buySellOrder: BSOSalesOrderTemplateProps) {
  const allLineItems = buySellOrder.lineItems;
  const address = getPrimaryPlace(buySellOrder.organization.places);

  const deliveredExpenses = buySellOrder.expenses.filter((expense) => expense.includedInDlvPrice);

  const totals = {
    pallets: computeTotalPallets(allLineItems).ordered,
    cartons: computeTotalCartons(allLineItems).ordered,
    weight: computeTotalWeight(allLineItems).ordered,
  };

  const lineItemTotal = allLineItems.reduce((total, lineItem) => {
    return total.add(USD.fromCents(getUnitPrice(lineItem, totals, deliveredExpenses)).multiply(lineItem.unitsOrdered));
  }, USD.fromCents(0));

  const accountsReceivableExpenses = buySellOrder.expenses
    .filter((expense) => expense.accountLevel === 'ACCOUNTS_RECEIVABLE')
    .filter(
      (expense) =>
        (expense.businessEntity === null || expense.businessEntityId === buySellOrder.customer.id) &&
        !expense.includedInDlvPrice &&
        !expense.parentExpenseId
    );

  const expenseTotal = accountsReceivableExpenses.reduce((total, expense) => {
    return total.add(USD.fromCents(expense.currencyAmount ?? expense.unitAmount).multiply(expense.quantity));
  }, USD.fromCents(0));

  const grandTotal = expenseTotal.add(lineItemTotal);

  const externalNotes = buySellOrder.notes?.filter(
    (n) => n.external && n.documentTypes.includes('SALES_ORDER_CONFIRMATION')
  );

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <SalesOrderHeader order={buySellOrder} />

        <ProductsGrid
          productLineItems={allLineItems}
          showUnitsOrdered={true}
          showSubTotal={true}
          showUnitPrice={true}
          deliveredExpenses={deliveredExpenses}
          totals={totals}
          currency={buySellOrder.currencyCode}
          productTemplate={buySellOrder.organization.productDescriptionTemplate}
        />

        <Expenses expenses={accountsReceivableExpenses} currency={buySellOrder.currencyCode} />

        <TermsAndTotals grandTotal={formatCurrency(grandTotal.dollars, buySellOrder.currencyCode)} />

        <Notes notes={externalNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
