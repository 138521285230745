import _ from 'lodash';
import { asUTCDate, fromUTC } from 'shared/utils/DateTime';
import Money from 'shared/utils/Money';

export const currencyNumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatCurrency({ value, currency = 'USD', fractionalDigits = 2 }) {
  if (value === null || value === undefined) {
    return value;
  }

  if (isNaN(Number(value))) {
    return null;
  }

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits,
  });
}

export function toDollarCurrencyFormatter(value) {
  if (value === null || value === undefined) {
    return value;
  }

  return currencyNumberFormat.format(Money.toDollars(value));
}

export function currencyFormatter(value) {
  if (value === null || value === undefined) {
    return value;
  }

  return currencyNumberFormat.format(value);
}

export function dateCellFormatter(value) {
  const date = asUTCDate(value);

  if (!date) {
    return '--';
  }

  return date.toFormat('MM/dd/yyyy');
}

export function dateTimeCellFormatter(value) {
  const date = fromUTC(value);

  if (!date) {
    return '--';
  }
  return date.toFormat('MM/dd/yyyy h:mm a');
}

export function fromNowCellFormatter(suffix?: string) {
  return (value) => {
    const date = fromUTC(value);

    if (!value) {
      return '--';
    }

    return date.toRelative() + (suffix || '');
  };
}

export function capitalizeFormatter(value) {
  if (value === null || value === undefined) {
    return '--';
  }

  return _.capitalize(value);
}

// DataGrid no longer supports string date values for `date` and `dateTime` columns
export function dateGetter(value?: Date | number | string | null) {
  if (value instanceof Date) return value;
  return value && new Date(value);
}
