import { Note } from '../../types';
import { DocumentType } from '../DocumentGenerator';
import { applyListMeta } from '../templates/formatters';

export function documentNotes(notes: Note[], templateId: number | undefined, type: string) {
  return applyListMeta(
    notes.filter(
      ({ documentTypes, customDocumentTypes }) =>
        documentTypes.includes(type as DocumentType) ||
        customDocumentTypes?.some(({ documentTemplateId }) => documentTemplateId === templateId)
    )
  );
}
