import { useMemo } from 'react';

import { Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from 'src/lib/constants';

export default function CustomFieldsForm({
  customFieldsKey,
  customFieldValues,
  handleUpdateCustomFields,
  filterFields,
}: {
  customFieldsKey: string;
  customFieldValues: Record<string, string | boolean>;
  handleUpdateCustomFields: (field: any, value: string) => void;
  filterFields?: (field: any) => boolean;
}) {
  const flags = useFlags();

  const fields = useMemo(() => {
    let customFields = flags[FeatureFlags.CustomFields][customFieldsKey];

    if (filterFields) {
      customFields = customFields?.filter(filterFields);
    }

    return customFields ?? [];
  }, [flags, filterFields, customFieldsKey]);

  return (
    <>
      {fields?.map((field) => {
        return (
          <FieldByType
            key={field.key}
            name={field.name}
            type={field.type}
            value={customFieldValues[field.key]}
            onChange={(e) => handleUpdateCustomFields(field, e)}
          />
        );
      })}
    </>
  );
}

const FieldByType = ({ type, name, value, onChange }) => {
  switch (type) {
    case 'boolean':
      return (
        <FormControlLabel
          control={<Checkbox />}
          checked={Boolean(value ?? false)}
          onChange={() => {
            onChange(!value);
          }}
          label={name}
        />
      );
    default:
      return (
        <FormControl>
          <TextField label={name} value={value ?? ''} onChange={(e) => onChange(e.target.value)} />
        </FormControl>
      );
  }
};
