import { Box, Slide } from '@mui/material';

import { useSideModal } from 'src/providers/SideModalContextProvider';

export default function SideModal() {
  const { sideModal, open } = useSideModal();
  const topHeight = (document.getElementById('page-body')?.getBoundingClientRect().top ?? 0) + window.scrollY;

  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Box
        sx={{
          maxHeight: 'calc(100vh - 250px)',
          overflowY: 'scroll',
          position: 'sticky',
          top: `${topHeight + 24}px`,
          minWidth: '480px',
        }}
      >
        {sideModal}
      </Box>
    </Slide>
  );
}
