import { useState } from 'react';

import { Box, Button, TextField } from '@mui/material';

import { FilterItemProps } from './FilterItem';

export default function TextFilter({ removeFilter, updateFilter, filter, column }: FilterItemProps) {
  const [value, setValue] = useState(() => {
    const val = filter.where?.[0]?.contains;
    return column.serverFilterValueTransform?.from?.(val) ?? val ?? '';
  });

  const submit = () => {
    const v = column.serverFilterValueTransform?.to?.(value) ?? value;
    updateFilter({ field: filter.field, where: [{ contains: v, mode: 'insensitive' }] });
  };

  return (
    <>
      <TextField
        size="small"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        sx={{ m: 1 }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !!value) {
            submit();
          }
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          p: 1,
          borderTop: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Button variant="text" color="primary" onClick={removeFilter}>
          Clear
        </Button>
        <Button variant="contained" disabled={!value} color="primary" onClick={submit}>
          Apply
        </Button>
      </Box>
    </>
  );
}
