import { Place } from '../../types';
import { TemplateComponent } from '../DocumentGenerator';

export type AddressTemplateType = {
  description?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  addressCountry?: string;
  hasLineTwo?: boolean;
};

export const AddressComponent: TemplateComponent<Place, AddressTemplateType, void, true> = {
  templatize: (address?: {
    description?: string;
    streetAddress1?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    addressCountry?: string;
  }) => {
    if (!address) {
      return {};
    }

    const { description, streetAddress1, streetAddress2, city, state, postalCode, addressCountry } = address;

    const appendCommaToCity = state || postalCode;

    const fomattedCity = appendCommaToCity ? `${city},` : city;

    const hasLineTwo = !!(city || state || postalCode);

    return {
      description,
      streetAddress1,
      streetAddress2,
      city: fomattedCity,
      state,
      postalCode,
      addressCountry,
      hasLineTwo,
    };
  },
  definitionMap: {
    description: { label: 'Description', type: 'string' },
    streetAddress1: { label: 'Street Address 1', type: 'string' },
    streetAddress2: { label: 'Street Address 2', type: 'string' },
    city: { label: 'City', type: 'string' },
    state: { label: 'State', type: 'string' },
    postalCode: { label: 'Postal Code', type: 'string' },
    addressCountry: { label: 'Country', type: 'string' },
    hasLineTwo: { label: 'Has Line Two', type: 'boolean' },
  },
};
