import { useRef, useState } from 'react';

import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { PageNavigationPlugin } from '@react-pdf-viewer/page-navigation';

type PageSelectProps = {
  pageNavigationPlugin: PageNavigationPlugin;
};

export function PageSelect({ pageNavigationPlugin }: PageSelectProps) {
  const { jumpToPage, jumpToPreviousPage, jumpToNextPage, CurrentPageLabel } = pageNavigationPlugin;
  const [currentPageInput, setCurrentPageInput] = useState('0');
  const [editCurrentPage, setEditCurrentPage] = useState(false);
  const pageInputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);

  return (
    <>
      <CurrentPageLabel>
        {({ currentPage, numberOfPages }) => {
          // Current Page Label flashes when zoom changes,
          // So we'll collect the state to prevent that.
          setNumberOfPages(numberOfPages);
          setCurrentPage(currentPage);

          return null;
        }}
      </CurrentPageLabel>
      <Box display="flex" width="95px" justifyContent="space-between">
        {!editCurrentPage && (
          <IconButton onClick={jumpToPreviousPage} sx={{ width: '20px', height: '20px' }} disabled={currentPage === 0}>
            <ArrowBackIosNew sx={{ fontSize: '10px' }} />
          </IconButton>
        )}
        {editCurrentPage ? (
          <TextField
            onChange={(e) => {
              setCurrentPageInput(e.target.value?.length ? Number(e.target.value).toString() : '');
            }}
            onKeyDown={(e) => {
              const value = Number(currentPageInput);

              if (e.key === 'Enter' && value) {
                jumpToPage(value - 1);
              }
            }}
            onBlur={() => setEditCurrentPage(false)}
            value={currentPageInput}
            size="small"
            sx={{ width: '70px', mx: 'auto' }}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">/ {numberOfPages}</InputAdornment>,
                ref: pageInputRef,
                style: { fontSize: '14px' },
              },
            }}
          />
        ) : (
          <Typography
            style={{ userSelect: 'none', cursor: numberOfPages === 1 ? 'auto' : 'pointer' }}
            variant="subtitle2"
            color="text.secondary"
            onClick={() => {
              if (numberOfPages === 1) {
                return;
              }

              setEditCurrentPage(true);
              setCurrentPageInput(currentPage + 1);

              setTimeout(() => {
                const inputElement = pageInputRef.current?.querySelector('input');
                inputElement?.focus();
              });
            }}
          >
            {currentPage + 1} / {numberOfPages}
          </Typography>
        )}
        {!editCurrentPage && (
          <IconButton
            onClick={jumpToNextPage}
            sx={{ width: '20px', height: '20px' }}
            disabled={currentPage === numberOfPages - 1}
          >
            <ArrowForwardIos sx={{ fontSize: '10px' }} />
          </IconButton>
        )}
      </Box>
    </>
  );
}
