import { SalesTermsMapper } from '../../mappers';
import {
  BusinessEntity,
  Contact,
  Expense,
  ListTemplate,
  Note,
  Organization,
  Payment,
  Place,
  SalesTerms,
} from '../../types';
import { Payment as PaymentUtils } from '../../utils';
import Money from '../../utils/Money';
import { AddressComponent } from '../components/address.template';
import { documentNotes } from '../components/documentNotes.template';
import { ExpensesComponent } from '../components/expensesGrid.template';
import { DocumentTypePopulator } from '../DocumentGenerator';

import { formatCurrency, formatDate, formatOrganizationName } from './formatters';

type OrderInputType = {
  slug: string;
};

export type StandaloneInvoiceInputType = {
  organization: Organization;
  slug: string;
  invoiceDate: string;
  payments: Payment[];
  totalAmountDue: number;
  salesOrder: OrderInputType;
  purchaseOrder: OrderInputType;
  workOrder: OrderInputType;
  buySellOrder: OrderInputType;
  expenses: Expense[];
  billTo: BusinessEntity;
  billToContact: Contact;
  billingAddress: Place;
  paymentTerms: string;
  referencePayableNum: string;
  notes: Note[];
};

type InvoiceOrderTemplateDataType = Partial<{
  totalPaid: string;
  invoiceDate: string;
  paymentTerms: string;
  totalAmountDue: string;
  paymentDueDate: string;
  billTo: string;
  billToAddress: string;
  billToContact: string;
  order: { number: string; prefix: string };
  referenceNumber: string;
  notes: ListTemplate<{ text: string }>;
}>;

const getOrderFromType = (invoice: StandaloneInvoiceInputType) => {
  if (invoice.salesOrder) {
    return { orderNumber: invoice.salesOrder.slug, prefixedNumber: `SO #${invoice.salesOrder.slug}` };
  }

  if (invoice.purchaseOrder) {
    return { orderNumber: invoice.purchaseOrder.slug, prefixedNumber: `PO #${invoice.purchaseOrder.slug}` };
  }

  if (invoice.workOrder) {
    return { orderNumber: invoice.workOrder.slug, prefixedNumber: `GP #${invoice.workOrder.slug}` };
  }

  if (invoice.buySellOrder) {
    return { orderNumber: invoice.buySellOrder.slug, prefixedNumber: `BSO #${invoice.buySellOrder.slug}` };
  }

  return {};
};

export const StandardInvoiceTemplate: DocumentTypePopulator<
  StandaloneInvoiceInputType,
  void,
  InvoiceOrderTemplateDataType
> = {
  populate: (invoice, { templateId, type }, globalOptions) => {
    if (!invoice) {
      return {};
    }

    const { organization, notes, expenses, slug, payments, invoiceDate, totalAmountDue, paymentTerms } = invoice;

    const paymentDueDate = PaymentUtils.computePaymentDueDate(invoiceDate, paymentTerms);

    const totalPaid = payments.reduce((acc, payment) => acc + payment.paymentAmount || 0, 0);

    return {
      invoiceNumber: slug,
      organization: formatOrganizationName({
        organization,
        customOrgName: globalOptions.customOrgName,
      }),

      businessAddress: AddressComponent.templatize(organization?.primaryLocation),

      invoiceDate: formatDate(invoiceDate),
      ...getOrderFromType(invoice),
      paymentDueDate: formatDate(paymentDueDate),
      // Invoices do not have currency support at the moment.
      totalPaid: formatCurrency(Money.toDollars(totalPaid), 'USD'),
      totalAmountDue: formatCurrency(Money.toDollars(totalAmountDue), 'USD'),
      charges: ExpensesComponent.templatize(expenses, { currency: 'USD' }),
      billToAddress: AddressComponent.templatize(invoice.billingAddress),
      billToContact: invoice.billToContact?.name,
      billTo: invoice.billTo?.name,
      paymentTerms: SalesTermsMapper.getLabel(paymentTerms as SalesTerms),
      referenceNumber: invoice.referencePayableNum,
      notes: documentNotes(notes, templateId, type),
      phoneNumber: organization?.primaryLocation?.phone ?? '--',
    };
  },
};
