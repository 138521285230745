type WorkOrderStatus =
  | 'DRAFT'
  | 'SUBMITTED'
  | 'RECEIVING'
  | 'PENDING_SETTLEMENT'
  | 'CLOSED'
  | 'CANCELLED' // TODO: Implement in backend
  | 'ERROR'; // TODO: Implement in the backend

type EditableFields =
  | 'orderDate'
  | 'grower'
  | 'growerContact'
  | 'fieldTicketId'
  | 'harvestDate'
  | 'harvestLocation'
  | 'commissionRate'
  | 'productType'
  | 'settlementBasis'
  | 'destination'
  | 'deliveryDate'
  | 'lineItems'
  | 'expenses';

type FieldEditability = {
  [field in EditableFields]: boolean;
};

export class Validator {
  private static toSubmitted: WorkOrderStatus[] = ['DRAFT', 'SUBMITTED'];
  private static toReceiving: WorkOrderStatus[] = [
    'DRAFT',
    'SUBMITTED',
    'RECEIVING',
  ];
  private static toPendingSettlement: WorkOrderStatus[] = [
    'DRAFT',
    'SUBMITTED',
    'RECEIVING',
    'PENDING_SETTLEMENT',
  ];
  private static toClosed: WorkOrderStatus[] = [
    'DRAFT',
    'SUBMITTED',
    'RECEIVING',
    'PENDING_SETTLEMENT',
    'CLOSED',
  ];

  public static active: WorkOrderStatus[] = [
    'SUBMITTED',
    'RECEIVING',
    'PENDING_SETTLEMENT',
  ];

  public static isFieldEditable(
    field: EditableFields,
    status: WorkOrderStatus = 'DRAFT',
    isGrowerAvailable = false,
    numOpenSettlements = 0
  ) {
    const editabilityMap: FieldEditability = {
      // Uptil submitted
      grower: this.toSubmitted.includes(status),

      // Uptil receiving
      destination: this.toReceiving.includes(status),
      lineItems: this.toReceiving.includes(status) && numOpenSettlements === 0,

      // Uptil pending settlement
      growerContact:
        this.toPendingSettlement.includes(status) && isGrowerAvailable,
      deliveryDate: this.toPendingSettlement.includes(status),
      harvestLocation:
        this.toPendingSettlement.includes(status) && isGrowerAvailable,
      fieldTicketId:
        this.toPendingSettlement.includes(status) && isGrowerAvailable,
      harvestDate:
        this.toPendingSettlement.includes(status) && isGrowerAvailable,
      commissionRate:
        this.toPendingSettlement.includes(status) && isGrowerAvailable,
      productType:
        this.toPendingSettlement.includes(status) && isGrowerAvailable,
      settlementBasis:
        this.toPendingSettlement.includes(status) &&
        isGrowerAvailable &&
        numOpenSettlements === 0,

      // Uptil closed
      // TODO: Implement such that no settlements are open
      expenses: this.toClosed.includes(status) && numOpenSettlements === 0,

      // Common fields
      orderDate: true,
    };

    return editabilityMap[field];
  }
}
