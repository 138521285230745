import { Editor, Plugin } from 'grapesjs';

import { attrsToString, resizerAll, resizerBtm, resizerRight } from './utils';

const plugin =
  (setModalOpen, logoUrl) =>
  (editor: Editor): Plugin => {
    const blockManager = editor.BlockManager;
    const commands = editor.Commands;
    const domComponents = editor.DomComponents;

    const styleManager = editor.StyleManager;

    editor.on('load', () => {
      styleManager.addProperty('flex', {
        name: 'Gap',
        property: 'gap',
        units: ['px', '%'],
        defaults: '0',
        type: 'number',
      });

      styleManager.addProperty('dimension', {
        name: 'Min Width',
        property: 'min-width',
        units: ['px', '%'],
        defaults: 'auto',
        type: 'number',
      });

      styleManager.addProperty('dimension', {
        name: 'Min Height',
        property: 'min-height',
        units: ['px', '%'],
        defaults: 'auto',
        type: 'number',
      });
    });

    commands.add('open-preview', {
      run: (editor) => {
        const pagesHtml = editor.Pages.getAll().map((page) => {
          const component = page.getMainComponent();
          return {
            html: editor.getHtml({ component }),
            css: editor.getCss({ component }),
          };
        });

        // right now, just hardcoding to page 0. Support for multiple pages will be added shortly
        setModalOpen(pagesHtml[0]);
      },
    });

    const imgAttrs = attrsToString({
      'data-gjs-resizable': resizerAll,
    });

    blockManager.add('orgLogo', {
      label: 'Organization Logo',
      content: `<div style="width: 100px; height: 100px"><img ${imgAttrs} data-gjs-type="orgLogo" src="${logoUrl}" /></div>`,
      media: `<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M21,3H3C2,3 1,4 1,5V19A2,2 0 0,0 3,21H21C22,21 23,20 23,19V5C23,4 22,3 21,3M5,17L8.5,12.5L11,15.5L14.5,11L19,17H5Z" />
  </svg>`,
      category: 'Basic',
    });

    domComponents.addType('orgLogo', {
      model: {
        tagName: 'img',
        toHTML: function () {
          return `<img id="${this.ccid}" src="{{{orgLogo}}}" />`;
        },
      },
    });

    blockManager.add('header', {
      label: 'Header Block',
      content: `
    <div class="header">
      <div class="header-row">
        <div style="display: flex; flex-direction: row" >
          <div style="width: 26px; height: 26px" >
            <img data-gjs-type="orgLogo" />
          </div>
          <div>
            <div data-gjs-type="variable" >{{organization.name}}</div>
          </div>
        </div>
        <div>
          <div data-gjs-type="variable" >{{organization.name}}</div>
        </div>
      </div>
      <div class="header-row">
        <div>
          <div data-gjs-type="variable" ></div>
        </div>
        <div>
          <div data-gjs-type="variable" ></div>
        </div>
      </div>
    </div>
      <style>
        .header {
          box-sizing: border-box;

          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 24px 24px 16px;

          width: 100%;
          height: 121px;

          background: #F7F8F3;
        }
        .header-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      </style>
    `,
      media: `
      <svg viewBox="0 0 24 24">
        <path d="M9.4 16.6 4.8 12l4.6-4.6L8 6l-6 6 6 6zm5.2 0 4.6-4.6-4.6-4.6L16 6l6 6-6 6z" />
      </svg>
    `,
      category: 'Basic',
    });

    blockManager.add('variable', {
      label: 'Variable',
      content: `<div data-gjs-type="variable">Hello, {{name}}!</div>`,
      media: `
      <svg viewBox="0 0 24 24">
        <path d="M9.4 16.6 4.8 12l4.6-4.6L8 6l-6 6 6 6zm5.2 0 4.6-4.6-4.6-4.6L16 6l6 6-6 6z" />
      </svg>
    `,
      category: 'Basic',
    });

    const variants = [
      {
        label: 'Content',
        value: 'content',
        className: 'typography-content',
        style: {
          color: '#474746',
          'font-size': '9px',
          'line-height': '133%',
        },
      },
      {
        label: 'Organization Name',
        value: 'orgName',
        className: 'typography-org-name',
        style: {
          'font-weight': 600,
          'line-height': '150%',
          'font-size': '16px',
          color: '#667239',
        },
      },
      {
        label: 'Heading',
        value: 'heading',
        className: 'typography-heading',
        style: {
          'letter-spacing': '1px',
          'line-height': '118%',
          'font-size': '11px',
        },
      },
      {
        label: 'Subheading',
        value: 'subheading',
        className: 'typography-subheading',
        style: {
          'line-height': '122.22%',
          'font-size': '9px',
          'font-weight': 500,
        },
      },
      {
        label: 'Section Heading',
        value: 'sectionHeading',
        className: 'typography-section-heading',
        style: {
          color: '#474746',
          'font-size': '8px',
          'line-height': '137.5%',
          'letter-spacing': '1px',
        },
      },
    ];

    const textStyles = variants
      .map(({ className, style }) => {
        return `
      .${className} {
        ${Object.entries(style)
          .map(([key, value]) => {
            return `${key}: ${value};`;
          })
          .join('\n')}
      }
    `;
      })
      .join('\n');

    domComponents.addType('variable', {
      model: {
        defaults: {
          tagName: 'div',
          droppable: true,
          draggable: true,
          attributes: { 'data-gjs-type': 'variable' },
          styles: textStyles,
          traits: [
            {
              type: 'text',
              label: 'Variable',
              name: 'variable',
              changeProp: true,
            },
            {
              type: 'text',
              label: 'Prefix',
              name: 'prefix',
              changeProp: true,
            },
            {
              type: 'text',
              label: 'Suffix',
              name: 'suffix',
              changeProp: true,
            },
            {
              type: 'select',
              label: 'Variant',
              name: 'variant',
              options: variants.map(({ label, value }) => {
                return { id: value, label };
              }),
              changeProp: true,
              default: 'content',
            },
          ],
        },
        init() {
          this.listenTo(this, 'change:variable', this.handleContentChange);
          this.listenTo(this, 'change:prefix', this.handleContentChange);
          this.listenTo(this, 'change:suffix', this.handleContentChange);
          this.listenTo(this, 'change:variant', this.handleTypographyClassChange);
        },
        handleContentChange() {
          this.view.render();
        },
        toHTML: function () {
          const prefix = this.get('prefix') ?? '';
          const suffix = this.get('suffix') ?? '';
          const variable = this.get('variable');
          const classes = this.get('classes').pluck('name').join(' ');

          return `<span id="${this.ccid}" class="${classes}">${prefix}{{{${variable}}}}${suffix}</span>`;
        },
        handleTypographyClassChange() {
          const selectedType = this.get('variant');

          variants.forEach(({ className }) => this.removeClass(className));

          if (selectedType) {
            const className = variants.find((v) => v.value === selectedType)?.className;

            this.addClass(className);
          }
        },
      },
      view: {
        onRender: function () {
          const prefix = this.model.get('prefix') || '';
          const suffix = this.model.get('suffix') || '';
          const variable = this.model.get('variable') || '';

          this.el.innerHTML = `${prefix}${variable}${suffix}` || '<i>select variable</i>';
        },
      },
    });

    blockManager.add('list', {
      label: 'List',
      content: `<div data-gjs-type="list"></div>`,
      media: `
      <svg viewBox="0 0 24 24">
        <path d="M9.4 16.6 4.8 12l4.6-4.6L8 6l-6 6 6 6zm5.2 0 4.6-4.6-4.6-4.6L16 6l6 6-6 6z" />
      </svg>
    `,
      category: 'Basic',
    });

    domComponents.addType('list', {
      model: {
        defaults: {
          tagName: 'div',
          droppable: true,
          draggable: true,
          attributes: { 'data-gjs-type': 'list' },
          traits: [
            {
              type: 'text',
              label: 'List',
              name: 'list',
              changeProp: true,
            },
          ],
        },
        init() {
          this.listenTo(this, 'change:list', this.handleContentChange);
        },
        handleContentChange() {
          this.view.render();
        },
        toHTML: function () {
          const list = this.get('list');
          const children = this.components();
          let childrenHtml = '';

          if (children) {
            children.forEach((child) => {
              childrenHtml += child.toHTML();
            });
          }

          const classes = this.get('classes').pluck('name').join(' ');

          return `{{#${list}}}
                  <div class="${classes}">
                    ${childrenHtml}
                    </div>
                {{/${list}}}`;
        },
      },
    });

    const commonBlockProps = {
      category: 'Basic',
      attributes: { class: 'gjs-fonts gjs-f-b1' },
    };

    const clsRow = 'layout-row';
    const clsCell = 'layout-cell';

    const rowAttr = {
      class: clsRow,
      'data-gjs-resizable': resizerBtm,
      'data-gjs-name': 'Row',
    };

    const colAttr: Record<string, string | unknown> = {
      class: clsCell,
      'data-gjs-draggable': `.${clsRow}`,
      'data-gjs-resizable': { ...resizerRight, keyWidth: 'flex-basis', minDim: 0 },
      'data-gjs-name': 'Cell',
    };

    const privateCls = [`.${clsRow}`, `.${clsCell}`];

    editor.on(
      'selector:add',
      (selector) => privateCls.indexOf(selector.getFullName()) >= 0 && selector.set('private', 1)
    );

    const attrsRow = attrsToString(rowAttr);
    const attrsCell = attrsToString(colAttr);

    const styleRow = `
    .${clsRow} {
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
    }
    `;

    const styleClm = `
    .${clsCell} {
      min-height: ${10}px;
      text-wrap: break-word;
      font-size: 13px;
      flex: 1;
    }`;

    blockManager.add('section', {
      ...commonBlockProps,
      label: 'Section',

      content: `
      <div class="section" data-gjs-type="section">
      </div>
      <style>
        .section {
          padding: 24px 24px 16px;
          height: 100%;
        }
      </style>
    `,
    });

    domComponents.addType('section', {
      model: {
        defaults: {
          tagName: 'section',
          attributes: { class: 'section' },
          components: [
            {
              type: 'block',
              content: '<div></div>',
            },
          ],
        },
      },
    });

    blockManager.add('address', {
      label: 'Address',
      content: `<div data-gjs-type="address"></div>`,
      media: `
      <svg viewBox="0 0 24 24">
        <path d="M9.4 16.6 4.8 12l4.6-4.6L8 6l-6 6 6 6zm5.2 0 4.6-4.6-4.6-4.6L16 6l6 6-6 6z" />
      </svg>
    `,
      category: 'Basic',
    });

    const optionalSpan = (content) => {
      return `
      {{#${content}}}
        <div>
          <span>{{{${content}}}}</span>
        </div>
      {{/${content}}}`;
    };

    domComponents.addType('address', {
      model: {
        defaults: {
          tagName: 'div',
          droppable: true,
          draggable: true,
          attributes: { 'data-gjs-type': 'address' },
          traits: [
            {
              type: 'text',
              label: 'Address',
              name: 'address',
              changeProp: true,
            },
            {
              type: 'text',
              label: 'Header Name',
              name: 'header',
              changeProp: true,
              default: '',
            },
            {
              type: 'checkbox',
              label: 'Description as Header',
              name: 'descriptionHeader',
              changeProp: true,
            },
          ],
        },
        init() {
          this.listenTo(this, 'change:address', this.handleContentChange);
        },
        handleContentChange() {
          this.view.render();
        },
        toHTML: function () {
          const address = this.get('address');
          const boldHeader = this.get('descriptionHeader');
          const headerName = this.get('header');

          return `
        <div id="${this.ccid}" class="typography-content" style="display: flex; flex-direction: column; gap: 2px">
          <div ${boldHeader ? 'class="typography-subheading"' : ''}>
            ${optionalSpan(`${address}.description`)}
            {{^${address}.description}}
              <span>${headerName}</span>
            {{/${address}.description}}
          </div>
          ${optionalSpan(`${address}.streetAddress1`)}
          {{#${address}.hasLineTwo}}
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
              ${optionalSpan(`${address}.city`)}
              ${optionalSpan(`${address}.state`)}
              ${optionalSpan(`${address}.postalCode`)}
            </div>
          {{/${address}.hasLineTwo}}
          {{^_settings.hideAddressCountry}}
            ${optionalSpan(`${address}.addressCountry`)}
          {{/_settings.hideAddressCountry}}
        </div>`;
        },
      },
      view: {
        onRender: function () {
          const address = this.model.get('address');

          this.el.innerHTML = `<div>${address}</div>`;
        },
      },
    });

    blockManager.add('column2', {
      ...commonBlockProps,
      label: 'Columns',
      media: `
      <svg viewBox="0 0 23 24">
        <path fill="currentColor" d="M2 20h8V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM13 20h8V4h-8v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1Z"/>
      </svg>`,

      content: `<div ${attrsRow}>
      <div ${attrsCell} ></div>
      <div ${attrsCell}></div>
    </div>
    ${`<style>
        ${styleRow}
        ${styleClm}
      </style>`}`,
    });

    editor.DomComponents.addType('text', {
      model: {
        defaults: {
          tagName: 'span',
          styles: textStyles,
          traits: [
            {
              type: 'select',
              label: 'Variant',
              name: 'variant',
              options: variants.map(({ label, value }) => {
                return { id: value, label };
              }),
              changeProp: true,
              default: 'content',
            },
          ],
        },

        init() {
          this.listenTo(this, 'change:variant', this.handleTypographyClassChange);
        },

        handleTypographyClassChange() {
          const selectedType = this.get('variant');

          variants.forEach(({ className }) => this.removeClass(className));

          if (selectedType) {
            const className = variants.find((v) => v.value === selectedType)?.className;

            this.addClass(className);
          }
        },
      },
    });

    blockManager.add('text', {
      ...commonBlockProps,
      activate: true,
      label: 'Text',

      media: `
      <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z" />
      </svg>`,
      content: {
        type: 'text',
        content: `Insert content here`,
      },
    });

    blockManager.add('icon', {
      category: 'Basic',
      activate: true,
      label: 'Icon',

      media: `
      <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z" />
      </svg>`,
      content: {
        type: 'icon',
      },
    });

    domComponents.addType('icon', {
      model: {
        defaults: {
          tagName: 'svg',
          droppable: true,
          draggable: true,
          attributes: { 'data-gjs-type': 'icon' },
          traits: [
            {
              type: 'select',
              label: 'Icon Name',
              name: 'icon',
              default: 'mail',
              options: [
                { id: 'mail', name: 'Mail' },
                { id: 'call', name: 'Call' },
              ],
              changeProp: true,
            },
          ],
        },
        init() {
          this.listenTo(this, 'change:icon', this.handleContentChange);
        },
        handleContentChange() {
          this.view.render();
        },
        toHTML: function () {
          const iconType = this.get('icon');
          const icon = icons[iconType] ?? {};

          return `
        <svg xmlns="http://www.w3.org/2000/svg" width="${icon.width}" height="${icon.height}"  viewBox="${icon.viewBox}" fill="#667239">
          <path d="${icon.path}"/>
        </svg>
        `;
        },
      },
      view: {
        onRender: function () {
          const iconType = this.model.get('icon');
          const icon = icons[iconType] ?? {};

          this.el.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="${icon.width}" height="${icon.height}" viewBox="${icon.viewBox}"  fill="#667239">
          <path d="${icon.path}"/>
        </svg>`;
        },
      },
    });

    editor.BlockManager.add('hide-if-empty', {
      label: 'Hide if not present',
      content: { type: 'hide-if-empty' },
      category: 'Basic',
    });

    editor.DomComponents.addType('hide-if-empty', {
      model: {
        defaults: {
          tagName: 'div',
          droppable: true,
          traits: [
            {
              label: 'Variable',
              type: 'text',
              name: 'variable',
              default: '',
            },
          ],
        },
        toHTML: function () {
          const variable = this.get('traits')?.get('variable');
          const variableValue = variable?.getValue();

          let childrenHtml = '';

          this.components().each((model) => {
            childrenHtml += model.toHTML();
          });

          return `
        {{^${variableValue}.isEmpty}}
          ${childrenHtml}
        {{/${variableValue}.isEmpty}}
        `;
        },
      },
    });

    blockManager.add('spacer', {
      label: 'Spacer',
      content: `<div style="margin-top: auto"></div>`,
      media: `
      <svg viewBox="0 0 24 24">
        <path d="M9.4 16.6 4.8 12l4.6-4.6L8 6l-6 6 6 6zm5.2 0 4.6-4.6-4.6-4.6L16 6l6 6-6 6z" />
      </svg>
    `,
      category: 'Basic',
    });
  };

// Just hardcoding a few icons for now.
const icons = {
  mail: {
    viewBox: '0 0 9 8',
    width: 8,
    height: 8,
    path: 'M7.83331 0.666687H1.16665C0.708313 0.666687 0.33748 1.04169 0.33748 1.50002L0.333313 6.50002C0.333313 6.95835 0.708313 7.33335 1.16665 7.33335H7.83331C8.29165 7.33335 8.66665 6.95835 8.66665 6.50002V1.50002C8.66665 1.04169 8.29165 0.666687 7.83331 0.666687ZM7.83331 2.33335L4.49998 4.41669L1.16665 2.33335V1.50002L4.49998 3.58335L7.83331 1.50002V2.33335Z',
  },
  call: {
    viewBox: '0 0 9 8',
    width: 8,
    height: 7,
    path: 'M7.8375 5.40833C7.325 5.40833 6.82917 5.325 6.36667 5.175C6.22083 5.125 6.05833 5.1625 5.94583 5.275L5.29167 6.09583C4.1125 5.53333 3.00833 4.47083 2.42083 3.25L3.23333 2.55833C3.34583 2.44167 3.37917 2.27917 3.33333 2.13333C3.17917 1.67083 3.1 1.175 3.1 0.6625C3.1 0.4375 2.9125 0.25 2.6875 0.25H1.24583C1.02083 0.25 0.75 0.35 0.75 0.6625C0.75 4.53333 3.97083 7.75 7.8375 7.75C8.13333 7.75 8.25 7.4875 8.25 7.25833V5.82083C8.25 5.59583 8.0625 5.40833 7.8375 5.40833Z',
  },
};

export default plugin;
