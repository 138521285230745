import { ReactNode } from 'react';

import { Box, BoxProps, Typography, keyframes } from '@mui/material';

export type IndicatorProps = {
  value: ReactNode;
  sx?: BoxProps['sx'];
  animate?: boolean;
};

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  55% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  95% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export default function Indicator({ value, sx = {}, animate }: IndicatorProps) {
  if (!value) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 2,
        right: -6,
        color: 'white',
        bgcolor: 'primary.main',
        borderRadius: '50%',
        width: '15px',
        height: '15px',
        boxShadow: 2,
        animation: animate ? `${pulse} 2s infinite ease-in-out` : 'none',
        ...sx,
      }}
    >
      <Typography
        fontSize="11px"
        lineHeight="16px"
        sx={{
          mx: 'auto',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
}
