import Mustache from 'mustache';

import { DefinitionMap } from '../defintionMapping';
import { ListTemplate } from '../types';

import { BuySellOrderInputType, BuySellOrderTemplate } from './templates/buySellOrder.template';
import { BuySellOrderInvoiceInputType, BuySellOrderInvoiceTemplate } from './templates/buySellOrderInvoice.template';
import { CustomerStatementInputType, CustomerStatementTemplate } from './templates/customerStatement.template';
import { InvoiceOrderInputType, InvoiceTemplate } from './templates/invoice.template';
import { PurchaseOrderTemplate } from './templates/purchaseOrder.template';
import { RemittanceInputType, RemittanceTemplate } from './templates/remittance.template';
import { SalesOrderInputType, SalesOrderOptions, SalesOrderTemplate } from './templates/salesOrder.template';
import { StandaloneInvoiceInputType, StandardInvoiceTemplate } from './templates/standardInvoice.template';

export type DocumentTemplate = {
  id?: number;
  pages: {
    css: string;
    html: string;
  }[];
  generationOptions?: any;
  name: string;
};

export type DocumentTypePopulator<Input, Opts, Output> = {
  populate: (data: Input, options: Opts & { type: string; templateId?: number }, globalOptions: any) => Output;
  definitionMap?: DefinitionMap<Output>;
};

export type TemplateComponent<Input, Output, Opts = void, Optional extends boolean = false> = {
  templatize: Optional extends true
    ? (data: Input | undefined, options: Opts) => Output
    : (data: Input, options: Opts) => Output;
  definitionMap: Output extends ListTemplate<infer U> ? DefinitionMap<U> : DefinitionMap<Output>;
};

export enum DocumentType {
  SALES_ORDER_CONFIRMATION = 'SALES_ORDER_CONFIRMATION',
  BILL_OF_LADING = 'BILL_OF_LADING',
  PURCHASE_ORDER_CONFIRMATION = 'PURCHASE_ORDER_CONFIRMATION',
  RECEIVING_TICKET = 'RECEIVING_TICKET',
  PAYABLE = 'PAYABLE',
  LOT_SETTLEMENT = 'LOT_SETTLEMENT',
  PICK_TICKET = 'PICK_TICKET',
  SHIPMENT_CONFIRMATION = 'SHIPMENT_CONFIRMATION',
  INVOICE = 'INVOICE',
  WORK_ORDER_CONFIRMATION = 'WORK_ORDER_CONFIRMATION',
  GROWER_SETTLEMENT = 'GROWER_SETTLEMENT',
  CARRIER_CONFIRMATION = 'CARRIER_CONFIRMATION',
  MULTI_BILL_OF_LADING = 'MULTI_BILL_OF_LADING',
  PRODUCTION_RUN_REPORT = 'PRODUCTION_RUN_REPORT',
  PASSING = 'PASSING',
  TRACEABILITY_IMPORT = 'TRACEABILITY_IMPORT',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
  CUSTOM = 'CUSTOM',
  REMITTANCE = 'REMITTANCE',
}

export enum DocumentTemplateEntityType {
  SALES_ORDER = 'SALES_ORDER',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  BUY_SELL_ORDER = 'BUY_SELL_ORDER',
  INVOICE = 'INVOICE',
  REMITTANCE = 'REMITTANCE',
  BUY_SELL_ORDER_INVOICE = 'BUY_SELL_ORDER_INVOICE',
  STANDARD_INVOICE = 'STANDARD_INVOICE',
  CUSTOMER_STATEMENT = 'CUSTOMER_STATEMENT',
  // GROWER_PRODUCT = 'GROWER_PRODUCT',
  // PRODUCTION_RUN = 'PRODUCTION_RUN',
  // PAYABLE = 'PAYABLE',
}

// Define the mapping type
type DocumentTypeMapping = {
  [DocumentTemplateEntityType.SALES_ORDER]: {
    template: typeof SalesOrderTemplate;
    input: SalesOrderInputType;
    options: SalesOrderOptions;
    documentTypes:
      | DocumentType.SALES_ORDER_CONFIRMATION
      | DocumentType.CARRIER_CONFIRMATION
      | DocumentType.BILL_OF_LADING
      | DocumentType.PICK_TICKET;
  };
  [DocumentTemplateEntityType.PURCHASE_ORDER]: {
    template: typeof PurchaseOrderTemplate;
    input: SalesOrderInputType;
    options: SalesOrderOptions;
    documentTypes:
      | DocumentType.PURCHASE_ORDER_CONFIRMATION
      | DocumentType.CARRIER_CONFIRMATION
      | DocumentType.BILL_OF_LADING
      | DocumentType.RECEIVING_TICKET;
  };
  [DocumentTemplateEntityType.REMITTANCE]: {
    template: typeof RemittanceTemplate;
    input: RemittanceInputType;
    options: void;
    documentTypes: DocumentType.REMITTANCE;
  };
  [DocumentTemplateEntityType.INVOICE]: {
    template: typeof InvoiceTemplate;
    input: InvoiceOrderInputType;
    options: void;
    documentTypes: DocumentType.INVOICE;
  };
  [DocumentTemplateEntityType.BUY_SELL_ORDER]: {
    template: typeof BuySellOrderTemplate;
    input: BuySellOrderInputType;
    options: void;
    documentTypes:
      | DocumentType.BILL_OF_LADING
      | DocumentType.CARRIER_CONFIRMATION
      | DocumentType.SALES_ORDER_CONFIRMATION
      | DocumentType.PURCHASE_ORDER_CONFIRMATION
      | DocumentType.PASSING;
  };
  [DocumentTemplateEntityType.BUY_SELL_ORDER_INVOICE]: {
    template: typeof BuySellOrderInvoiceTemplate;
    input: BuySellOrderInvoiceInputType;
    options: void;
    documentTypes: DocumentType.INVOICE;
  };
  [DocumentTemplateEntityType.STANDARD_INVOICE]: {
    template: typeof StandardInvoiceTemplate;
    input: StandaloneInvoiceInputType;
    options: void;
    documentTypes: DocumentType.INVOICE;
  };
  [DocumentTemplateEntityType.CUSTOMER_STATEMENT]: {
    template: typeof CustomerStatementTemplate;
    input: CustomerStatementInputType;
    options: void;
    documentTypes: DocumentType.OTHER;
  };
};

const typePopulators: {
  [K in DocumentTemplateEntityType]?: {
    populator: DocumentTypeMapping[K]['template'];
    templateDefinitions?: {
      [A in DocumentTypeMapping[K]['documentTypes']]?: {
        options?: DocumentTypeMapping[K]['options'];
      };
    };
  };
} = {
  [DocumentTemplateEntityType.SALES_ORDER]: {
    populator: SalesOrderTemplate,
    templateDefinitions: {
      [DocumentType.SALES_ORDER_CONFIRMATION]: {
        options: {
          totalUnitsType: 'ordered',
        },
      },
      [DocumentType.CARRIER_CONFIRMATION]: {
        options: {
          totalUnitsType: 'picked',
        },
      },
      [DocumentType.BILL_OF_LADING]: {
        options: {
          totalUnitsType: 'picked',
        },
      },
    },
  },
  [DocumentTemplateEntityType.PURCHASE_ORDER]: {
    populator: PurchaseOrderTemplate,
  },
  [DocumentTemplateEntityType.REMITTANCE]: {
    populator: RemittanceTemplate,
    templateDefinitions: {
      [DocumentType.REMITTANCE]: {},
    },
  },
  [DocumentTemplateEntityType.INVOICE]: {
    populator: InvoiceTemplate,
  },
  [DocumentTemplateEntityType.STANDARD_INVOICE]: {
    populator: StandardInvoiceTemplate,
  },
  [DocumentTemplateEntityType.BUY_SELL_ORDER_INVOICE]: {
    populator: BuySellOrderInvoiceTemplate,
  },
  [DocumentTemplateEntityType.BUY_SELL_ORDER]: {
    populator: BuySellOrderTemplate,
  },
  [DocumentTemplateEntityType.CUSTOMER_STATEMENT]: {
    populator: CustomerStatementTemplate,
  },
};

const defaultTemplates: {
  [E in DocumentTemplateEntityType]?: {
    [D in DocumentType]?: () => Promise<{
      templateState: any;
      name: string;
      entityType: string | DocumentTemplateEntityType;
      type: string;
      pages: {
        html: string;
        css: string;
      }[];
    }>;
  };
} = {
  [DocumentTemplateEntityType.REMITTANCE]: {
    [DocumentType.REMITTANCE]: () => import('./defaults/remittance.document').then((module) => module.default),
  },
  [DocumentTemplateEntityType.CUSTOMER_STATEMENT]: {
    [DocumentType.OTHER]: () => import('./defaults/customerStatement.document').then((module) => module.default),
  },
};

const populate = <E extends DocumentTemplateEntityType, T extends DocumentType>(
  entityType: E,
  documentType: T,
  documentTemplate: DocumentTemplate | null,
  data: Partial<DocumentTypeMapping[E]['input']>,
  globalOptions: any
) => {
  const typePopulator = typePopulators[entityType];

  if (!typePopulator) {
    return null;
  }

  const { populator } = typePopulator;

  let options: any = {};

  if (documentType === DocumentType.CUSTOM && documentTemplate?.generationOptions) {
    for (const option of documentTemplate.generationOptions) {
      options[option.key] = option.defaultValue;
    }
  } else {
    // TODO: Deprecate all hardcoded document types
    const templateDefinitions = typePopulator.templateDefinitions;

    if (templateDefinitions && documentType in templateDefinitions) {
      options = templateDefinitions[documentType]?.options ?? {};
    }
  }

  return populator.populate(
    data as any,
    { ...options, type: documentType, templateId: documentTemplate?.id } as any,
    globalOptions
  );
};

const generate = async <E extends DocumentTemplateEntityType, T extends DocumentType>(
  entityType: E,
  type: T,
  documentTemplate: DocumentTemplate | null,
  data: DocumentTypeMapping[E]['input'],
  extendedData: Record<string | number, unknown> = {}
) => {
  const populatedData = populate(entityType, type, documentTemplate, data, extendedData?._settings || {});

  console.debug(data, populatedData, documentTemplate);

  if (!populatedData) {
    throw new Error('No populator found');
  }

  const template = documentTemplate ?? (await defaultTemplates[entityType]?.[type]?.());

  if (!template) {
    throw new Error('No template found');
  }

  const generatedPages = template.pages.map((page: any) => {
    return `<html>${Mustache.render(page.html, { ...populatedData, ...extendedData })}
      <style>
        font-family: 'Monseratt';
        ${page.css}
      </style>
    </html>`;
  });

  return generatedPages;
};

export default {
  generate,
  DocumentType,
  defaultTemplates,
  DocumentTemplateEntityType,
};
