import { Box, Tooltip } from '@mui/material';

export default function ErroredCell({
  children,
  showErrorIndicator,
  errorMessage,
  density = 'standard',
  color = 'error.dark',
}) {
  const height = density === 'compact' ? '35px' : '51px';

  return (
    <>
      {showErrorIndicator && (
        <Tooltip title={errorMessage} placement="right" followCursor>
          <Box
            sx={{
              width: '40px',
              height,
              transform: 'translateX(-10px)',
              position: 'absolute',
            }}
          >
            <Box
              sx={{
                backgroundColor: color,
                width: '4px',
                borderRadius: '0 4px 4px 0',
                height: '100%',
              }}
            />
          </Box>
        </Tooltip>
      )}
      {children}
    </>
  );
}
