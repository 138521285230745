import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page } from '@react-pdf/renderer';

import computeTotalCartons from 'src/services/lineItems/utils/computeTotalCartons';
import computeTotalPallets, { GenericLineItem } from 'src/services/lineItems/utils/computeTotalPallets';
import computeTotalWeight from 'src/services/lineItems/utils/computeTotalWeight';

import { Expenses, Footer, Notes, PassingHeader, ProductsGrid, TermsAndTotals, getUnitPrice } from '../sections';
import { formatCurrency } from '../utils/formatters';
import money from '../utils/money';
import { docStyles } from '../utils/styles';

type SalesOrderPassingTemplateProps = Prisma.SalesOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    salesPerson: { include: { user: true; subsidiary: true } };
    businessEntity: { include: { places: true } };
    businessEntityContact: true;
    subsidiary: true;
    lineItems: {
      select: {
        unitPrice: true;
        unitsPicked: true;
      };
      include: {
        lot: true;
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
          };
        };
      };
    };
    notes: {
      include: {
        membership: {
          include: {
            user: true;
          };
        };
        contact: true;
      };
    };
    expenses: { include: { businessEntity: true } };
  };
}>;

export type BSOPassing = Prisma.BuySellOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    broker: { include: { user: true } };
    customer: { include: { places: true } };
    customerContact: true;
    lineItems: {
      select: {
        unitPrice: true;
        unitsShipped: true;
      };
      include: {
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
          };
        };
      };
    };
    notes: {
      include: {
        membership: {
          include: {
            user: true;
          };
        };
      };
    };
    expenses: { include: { businessEntity: true } };
  };
}>;

type PassingTemplateProps = SalesOrderPassingTemplateProps &
  BSOPassing & {
    orderType: 'SalesOrder' | 'BuySellOrder';
  };

export function PassingTemplate({ overrideContactEmail, ...order }: PassingTemplateProps) {
  const allLineItems = order.lineItems as GenericLineItem[];
  const businessEntity = order.businessEntity || order.customer;
  const arExpenses = order.expenses
    .filter((expense) => expense.accountLevel === 'ACCOUNTS_RECEIVABLE')
    .filter((expense) => expense.businessEntityId === null || expense.businessEntityId === businessEntity?.id)
    .filter((expense) => !expense.includedInDlvPrice && !expense.parentExpenseId);

  const externalNotes = order.notes.filter((n) => n.external === true && n.documentTypes.includes('PASSING'));

  const deliveredExpenses = order.expenses.filter((expense) => expense.includedInDlvPrice);

  const isSalesOrder = order.orderType === 'SalesOrder';
  const isBuySellOrder = order.orderType === 'BuySellOrder';

  let totals;

  if (isSalesOrder) {
    totals = {
      pallets: computeTotalPallets(allLineItems).picked,
      cartons: computeTotalCartons(allLineItems).picked,
      weight: computeTotalWeight(allLineItems).picked,
    };
  } else {
    totals = {
      pallets: computeTotalPallets(allLineItems).shipped,
      cartons: computeTotalCartons(allLineItems).shipped,
      weight: computeTotalWeight(allLineItems).shipped,
    };
  }

  const lineItemTotal = allLineItems.reduce((total, lineItem) => {
    const unitQuantity = lineItem.unitsPicked ?? lineItem.unitsShipped;

    const unitPrice = getUnitPrice(lineItem, totals, deliveredExpenses);

    return total + unitPrice * unitQuantity;
  }, 0);

  const expenseTotal = arExpenses.reduce((total, expense) => {
    return total + (expense.currencyAmount ?? expense.currencyAmount) * expense.quantity;
  }, 0);

  const grandTotal = lineItemTotal + expenseTotal;

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <PassingHeader order={order} overrideContactEmail={overrideContactEmail} />

        <ProductsGrid
          productLineItems={allLineItems}
          showUnitPrice={true}
          showUnitsOrdered={true}
          showUnitsShipped={isBuySellOrder}
          showUnitsPicked={isSalesOrder}
          showSubTotal={true}
          deliveredExpenses={deliveredExpenses}
          totals={totals}
          currency={order.currencyCode}
          productTemplate={order.organization.productDescriptionTemplate}
        />

        <Expenses expenses={arExpenses} currency={order.currencyCode} />

        <TermsAndTotals grandTotal={formatCurrency(money.toDollars(grandTotal), order.currencyCode)} />

        <Notes notes={externalNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
