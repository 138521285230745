/**
 *
  - Obtain the first 11 digits of the UPC code. The UPC is generally 12 digits long, with the 12th digit being the check digit that needs to be calculated.
  - Sum the digits in odd positions (1st, 3rd, 5th, etc.).
  - Sum the digits in even positions (2nd, 4th, 6th, etc.).
  - Multiply the sum of the digits in odd positions by 3.
  - Add the sum of the even-positioned digits to the result obtained in step 4.
  - Find the modulo 10 of the result from step 5. In other words, find the remainder when the sum is divided by 10.
  - Calculate the check digit by subtracting the modulo result from 10. If the modulo result is 0, then the check digit is 0.
 */
export function upcCheckDigit(upc: string): number {
  const upcArr = upc.split('').map(Number);

  const oddSum = upcArr.filter((_, index) => index % 2 === 0).reduce((acc, curr) => acc + curr, 0);
  const evenSum = upcArr.filter((_, index) => index % 2 === 1).reduce((acc, curr) => acc + curr, 0);

  const total = oddSum * 3 + evenSum;

  return (10 - (total % 10)) % 10;
}

function crc16(str: string) {
  let crc = 0xffff;
  for (let i = 0; i < str.length; i++) {
    const byte = str.charCodeAt(i);

    crc ^= byte;

    for (let j = 0; j < 8; j++) {
      if (crc & 0x0001) {
        crc = (crc >> 1) ^ 0xa001;
      } else {
        crc = crc >> 1;
      }
    }
  }
  return crc;
}

/**
 *  https://www.produceinventory.com/files/newsroom/Voice-Pick-Code.pdf
 */
export function voicePickCode(gtin: string, lot: string, date: string) {
  const plainText = gtin + lot + (date || '');

  const crcHash = crc16(plainText);

  const voicePickCode = crcHash % 10000;

  const largeDigits = voicePickCode % 100;
  const smallDigits = Math.floor(voicePickCode / 100);

  return {
    full: voicePickCode.toString().padStart(4, '0'),
    large: largeDigits.toString().padStart(2, '0'),
    small: smallDigits.toString().padStart(2, '0'),
  };
}
