/*
  The accounts register page has, or will have, quite a few different forms of use.
  From viewing to reconciling bank accounts to virtual accounts etc.

  This class keeps track of the state & capabilities of that state.
 */

import _ from 'lodash';
import { DateTime } from 'luxon';

import { LedgerEntryIndex } from 'src/lib/algolia';

import { ChartOfAccountsPageLedgerAccount, LedgerEntryGroup } from '$api/types/graphql';

export type AccountRegisterState =
  | {
      mode: 'LISTING';
      account?: ChartOfAccountsPageLedgerAccount;
    }
  | {
      mode: 'RECONCILING_BANK';
      account?: ChartOfAccountsPageLedgerAccount;
      startingBalance: number;
      endingBalance: number;
      endingDate: DateTime;
    }
  | {
      mode: 'RECONCILING_ACCOUNT';
      account?: ChartOfAccountsPageLedgerAccount;
    };

export type AccountRegisterCapabilities = {
  canStartBankReconciling: boolean;
  canEndBankReconciling: boolean;

  canStartAccountReconciling: boolean;
  canEndAccountReconciling: boolean;

  canReconcileLedgerLines: boolean;
  canReconcileBatches: boolean;
};

export type AnnotatedLedgerEntry = {
  ledgerLineKey: string;
  rollingBalance: number;
  reconciled: boolean;
};

export type AccountRegisterEvents = {
  onIsReconciledChanged: (ledgerEntryKey: string, isReconciled: boolean) => Promise<void>;
  onBankReconciliationCompleted: (
    assetAccountPath: string,
    adjustmentAmount: number,
    endingBalanceDate: DateTime
  ) => Promise<void>;
  onDepositSlipCreated: (
    depositDate: DateTime,
    memo: string,
    depositedByMemberId: number,
    ledgerEntryKeys: string[]
  ) => Promise<void>;
  onAnnotationsReceived: (annotations: LedgerEntryGroup[]) => void;
  onFiltersChanged: (filters: AccountRegisterFilters) => void;
};

export const NO_HANDLERS: AccountRegisterEvents = {
  onIsReconciledChanged: () => Promise.resolve(),
  onBankReconciliationCompleted: () => Promise.resolve(),
  onDepositSlipCreated: () => Promise.resolve(),
  onAnnotationsReceived: () => {},
  onFiltersChanged: () => {},
};

export const NO_CAPABILITIES = {
  canStartBankReconciling: false,
  canEndBankReconciling: false,

  canStartAccountReconciling: false,
  canEndAccountReconciling: false,

  canReconcileLedgerLines: false,
  canReconcileBatches: false,
};

export type AccountRegisterFilters = {
  path?: string;
  accountReconciliationId?: number;
  from?: DateTime;
  until?: DateTime;
};

export class AccountRegisterContext {
  public readonly state: AccountRegisterState;
  public readonly entries: LedgerEntryGroup[];
  public readonly filters: AccountRegisterFilters;

  private constructor(state: AccountRegisterState, entries: LedgerEntryGroup[], filters: AccountRegisterFilters) {
    this.state = state;
    this.entries = entries;
    this.filters = filters;
  }

  public static create() {
    return new AccountRegisterContext({ mode: 'LISTING' }, [], {});
  }

  public forListing(): AccountRegisterContext {
    return new AccountRegisterContext({ mode: 'LISTING', account: this.state.account }, this.entries, this.filters);
  }

  public forBankReconciliation(
    startingBalance: number,
    endingBalance: number,
    endingDate: DateTime
  ): AccountRegisterContext {
    return new AccountRegisterContext(
      {
        mode: 'RECONCILING_BANK',
        account: this.state.account,
        startingBalance: startingBalance,
        endingBalance: endingBalance,
        endingDate,
      },
      this.entries,
      this.filters
    );
  }

  public forAccountReconciliation(startingDate: DateTime, endingDate: DateTime): AccountRegisterContext {
    return new AccountRegisterContext({ mode: 'RECONCILING_ACCOUNT', account: this.state.account }, this.entries, {
      ...this.filters,
      from: startingDate,
      until: endingDate,
    });
  }

  public withLines(entries: LedgerEntryGroup[]): AccountRegisterContext {
    const existingLinesByKey = _.keyBy(this.entries, (l) => l.entry.key);

    const nextLines = _.uniqBy(entries.concat(this.entries), (entry) => entry.entry.key);

    return new AccountRegisterContext(this.state, nextLines.filter(Boolean), this.filters);
  }

  public withFilters(filters: AccountRegisterFilters): AccountRegisterContext {
    return new AccountRegisterContext(this.state, this.entries, filters);
  }

  public withAccount(account: ChartOfAccountsPageLedgerAccount): AccountRegisterContext {
    return new AccountRegisterContext({ ...this.state, account }, this.entries, this.filters);
  }

  public withLog(log: string): AccountRegisterContext {
    console.log(`AccountRegisterContext:${log}`);
    return new AccountRegisterContext(this.state, this.entries, this.filters);
  }

  public get bankReconciliationStats(): { agrifulBalance: number; unreconciledBalance: number; deltaBalance?: number } {
    if (!['RECONCILING_BANK', 'RECONCILING_ACCOUNT'].includes(this.state.mode)) {
      return null;
    }

    const { canEndBankReconciling, canEndAccountReconciling } = this.capabilities;
    if (!canEndBankReconciling && !canEndAccountReconciling) {
      return null;
    }

    const agrifulBalance = this.entries
      .filter((l) => l.entry.reconciled)
      .flatMap((entry) => entry.lines.filter((line) => line.account.path === this.state.account?.path.join('/')))
      .reduce((accumulator, l) => accumulator + l.amount, 0);
    const unreconciledBalance = this.entries
      .filter((l) => !l.entry.reconciled)
      .flatMap((entry) => entry.lines.filter((line) => line.account.path === this.state.account?.path.join('/')))
      .reduce((accumulator, l) => accumulator + l.amount, 0);
    const deltaBalance =
      this.state.mode === 'RECONCILING_BANK'
        ? Math.abs(this.state.endingBalance - this.state.startingBalance - agrifulBalance) > 0.01
          ? this.state.endingBalance - this.state.startingBalance - agrifulBalance
          : 0
        : null;

    return {
      agrifulBalance,
      unreconciledBalance,
      deltaBalance,
    };
  }

  public get capabilities(): AccountRegisterCapabilities {
    const { mode, account } = this.state;
    switch (mode) {
      case 'LISTING':
        return {
          ...NO_CAPABILITIES,
          canStartBankReconciling: account?.isPhysical ?? false,
          canStartAccountReconciling: !account?.isPhysical,
        };
      case 'RECONCILING_BANK':
        return {
          ...NO_CAPABILITIES,
          canEndBankReconciling: true,
          canReconcileLedgerLines: true,
        };
      case 'RECONCILING_ACCOUNT':
        return {
          ...NO_CAPABILITIES,
          canEndAccountReconciling: true,
          canReconcileLedgerLines: true,
        };
    }
  }
}

const safeObject = (obj) =>
  obj ? Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined)) : {};
