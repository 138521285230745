import { useEffect, useMemo, useRef } from 'react';

import { navigate, routes } from '@redwoodjs/router';
import { useMutation, useQuery } from '@redwoodjs/web';

import DocumentTemplateApi from '../api/DocumentTemplate.api';
import createDocumentStoragePlugin from '../plugins/documentStorage';

/**
 * Implements temp local storage and remote document storage
 *
 * TODO: Add TTL for local storage
 * TODO: Implement isNew flag in documentStorage plugin
 *
 */
export default function useDocumentStorage({
  isNew,
  documentConfig,
  setStorageSaveModalOpen,
  setStorageLoadModalOpen,
  setTemplateId,
}) {
  const [saveDocument] = useMutation(DocumentTemplateApi.SaveDocumentTemplate);

  const documentConfigRef = useRef<{
    name: string;
    type: string;
    entity: string;
  }>(documentConfig);

  const { refetch: loadDocument } = useQuery(DocumentTemplateApi.LoadDocumentTemplateState, {
    skip: isNew,
    variables: {
      name: documentConfigRef.current?.name,
      entityType: documentConfigRef.current?.entity,
    },
  });

  useEffect(() => {
    documentConfigRef.current = documentConfig;
  }, [documentConfig]);

  const plugin = useMemo(
    () =>
      createDocumentStoragePlugin({
        setStorageSaveModalOpen,
        setStorageLoadModalOpen,
        saveRemote: (saveState, pages, metadata) => {
          if (!documentConfigRef.current) {
            throw new Error('Document config not set');
          }

          return saveDocument({
            variables: {
              name: metadata.name,
              input: {
                templateState: JSON.stringify(saveState),
                template: {
                  pages,
                },
                entityType: metadata.entity,
                type: metadata.type,
                generationOptions: metadata.generationOptions,
              },
            },
          }).then(({ data }) => {
            navigate(routes.documentEditor({ name: metadata.name, entity: metadata.entity, type: metadata.type }), {
              replace: true,
            });

            return data;
          });
        },
        loadRemote: async () => {
          const { data } = await loadDocument({
            name: documentConfigRef.current?.name,
          });

          if (!data) {
            return null;
          }

          setTemplateId(data.documentTemplate?.id);

          const template = data.documentTemplate?.templateState;

          if (!template) {
            return null;
          }

          return JSON.parse(template);
        },
        documentConfig: documentConfigRef,
      }),
    [loadDocument, saveDocument, documentConfigRef, setTemplateId, setStorageSaveModalOpen, setStorageLoadModalOpen]
  );

  return { plugin };
}
