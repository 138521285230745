import * as React from 'react';

import { Box, IconProps, ListItem, ListItemButton } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

import SimpleMenu from 'src/components/atoms/SimpleMenu';

import SidebarLink, { SidebarLinkItem } from '../sidebar/SidebarLink';

export type SidebarMinimizedLinkProps = {
  Icon: React.FC<IconProps>;
  links: Omit<SidebarLinkItem, 'icon'>[];
  current: boolean;
  title: string;
};

export function SidebarMinimizedLink({ Icon, links, current, title }: SidebarMinimizedLinkProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ml: 0.5, mr: 'auto', width: '100%' }}>
      <ListItemButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onMouseOver={handleClick}
        onMouseLeave={handleClose}
        onClick={handleClick}
        sx={{
          background: current ? theme.palette.primary[50] : 'transparent',
          color: current ? theme.palette.primary.main : theme.palette.grey[700],
          height: '44px',
          width: '100%',
        }}
      >
        <Icon sx={{ width: '20px', height: '20px' }} />
        {anchorEl && (
          <SimpleMenu anchorEl={anchorEl}>
            <ListItem sx={{ color: theme.palette.grey[500] }} divider>
              {title}
            </ListItem>
            {links.map((link) => (
              <SidebarLink key={link.text} {...link} minimized onClick={handleClose} />
            ))}
          </SimpleMenu>
        )}
      </ListItemButton>
    </Box>
  );
}
