import { CreatePricingSheetEntryInput } from 'types/graphql';

import { useMutation } from '@redwoodjs/web';

import { GET_PRICING_SHEET_ENTRIES } from './usePricingSheetEntries';

const ADD_PRICING_SHEET_ENTRY = gql`
  mutation CreatePricingSheetEntry($input: CreatePricingSheetEntryInput!) {
    createPricingSheetEntry(input: $input) {
      id
    }
  }
`;

export default function useAddPricingSheetEntry() {
  const [createPricingSheetEntry] = useMutation(ADD_PRICING_SHEET_ENTRY, {
    refetchQueries: [GET_PRICING_SHEET_ENTRIES],
  });

  return async (input: CreatePricingSheetEntryInput) => createPricingSheetEntry({ variables: { input } });
}
