import { useQuery } from '@redwoodjs/web';

import BuySellOrderInvoiceDocumentApi from '../api/BuySellOrderInvoiceDocument.api';

export default function useBuySellOrderInvoiceDocument({ slug, skip = false }) {
  const { data, loading, refetch } = useQuery(BuySellOrderInvoiceDocumentApi.GetBuySellOrderInvoiceDocumentData, {
    variables: { slug },
    skip,
  });

  return {
    invoice: data?.buySellOrderInvoice,
    loading,
    getDocumentData: () => refetch({ slug }).then((res) => res.data.buySellOrderInvoice),
  };
}
