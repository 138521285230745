import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, IconProps, AccordionSummary as MuiAccordionSummary } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ButtonBase from '@mui/material/ButtonBase/ButtonBase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useLocation } from '@redwoodjs/router';

import { useSidebar } from '../../../providers/SidebarContextProvider';
import { SidebarMinimizedLink } from '../menus/SidebarMinimizedLink';

import { GLOBAL_SIDE_BAR_WIDTH } from './Sidebar';
import SidebarLink, { SidebarLinkItem } from './SidebarLink';

export type SidebarAccordianProps = {
  text: string;
  icon: React.FC<IconProps>;
  links: Omit<SidebarLinkItem, 'icon'>[];
  route: string;
  testId?: string;
};

const AccordionSummary = styled((props: AccordionSummaryProps) => {
  const theme = useTheme();

  return (
    <ButtonBase sx={{ width: '100%', paddingRight: theme.spacing(2.5) }}>
      <MuiAccordionSummary {...props} sx={{ width: '100%' }} />
    </ButtonBase>
  );
})(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2.5),
  },
}));

export default function SidebarNestedLinks({ icon: Icon, text, links, route, testId }: SidebarAccordianProps) {
  const theme = useTheme();
  const location = useLocation();
  const { sidebarOpen } = useSidebar();
  const current = location.pathname.split('/')[1] === route;

  return (
    <ListItem disablePadding disableGutters sx={{ justifyContent: sidebarOpen ? 'initial' : 'center' }}>
      {sidebarOpen ? (
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            boxShadow: 'none',
            background: 'none',
            margin: 0,
            '& .MuiButtonBase-root': {
              height: '44px',
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              '& .MuiSvgIcon-root': {
                width: '20px',
              },
            },
            width: GLOBAL_SIDE_BAR_WIDTH,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: '0' }} data-testid={testId}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                my: 'auto',
                color: current ? theme.palette.primary.main : theme.palette.grey[600],
              }}
            >
              <Icon sx={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <Typography
              sx={{
                fontWeight: current ? 700 : 400,
                color: current ? theme.palette.primary.main : theme.palette.grey[700],
              }}
            >
              {text}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ width: GLOBAL_SIDE_BAR_WIDTH, p: 0 }}>
            <List sx={{ py: 0 }}>
              {links.map((link) => (
                <SidebarLink key={link.text} {...link} nested sxListItemButton={{ paddingLeft: theme.spacing(8.5) }} />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ) : (
        <SidebarMinimizedLink Icon={Icon} title={text} links={links} current={current} data-testid={testId} />
      )}
    </ListItem>
  );
}
