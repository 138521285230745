import React, { ComponentProps, SyntheticEvent } from 'react';

import Typography from '@mui/material/Typography';
import { AddressRole } from 'types/graphql';

import { routes } from '@redwoodjs/router';

import formatAddress from 'src/utils/address/formatAddress';
import fuzzyComplete from 'src/utils/fuzzyComplete';

import Autocomplete from '../Autocomplete';
import Link from '../Link';
import UnderlinedMatch from '../UnderlinedMatch';

export interface AutocompletePlaceType {
  __typename?: 'Place';
  id: number;
  description: string;
  fullAddress?: string;

  streetAddress1?: string;

  city?: string;
  state?: string;
  postalCode?: string;
  addressCountry?: string;

  addressRoles?: AddressRole[];

  businessEntityId?: number;
}

type AutocompleteAddressFieldProps = {
  fieldLabel: string;
  disabled?: boolean;

  selectedAddress?: AutocompletePlaceType;
  addressBook: AutocompletePlaceType[];

  onChange?: (value: AutocompletePlaceType, e: SyntheticEvent) => void;
} & ComponentProps<typeof Autocomplete<AutocompletePlaceType>>;

const AutocompleteAddressField: React.FC<Omit<AutocompleteAddressFieldProps, 'options'>> = ({
  fieldLabel,
  disabled,

  selectedAddress,
  addressBook,

  businessEntityId,

  onChange,
  ...props
}) => {
  const defaultOptionLabel = (option: AutocompletePlaceType) => {
    const fallbackAddress = formatAddress(option) || option?.streetAddress1 || '--';

    return option?.description || fallbackAddress;
  };

  const link = businessEntityId
    ? routes.businessEntity({ id: businessEntityId })
    : routes.systemManagement({ tab: 'address-book' });

  return (
    <Autocomplete<AutocompletePlaceType>
      {...props}
      value={selectedAddress}
      label={fieldLabel}
      options={addressBook}
      disabled={disabled}
      getOptionLabel={defaultOptionLabel}
      onChange={(e, value) => onChange(value, e)}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      filterOptions={fuzzyComplete(['description', 'streetAddress1'])}
      details={(option: AutocompletePlaceType, inputValue: string) => {
        const isDisplayingAddress = !option.description;

        if (isDisplayingAddress) {
          return null;
        }

        const fallbackAddress = formatAddress(option) || option?.streetAddress1;

        return (
          fallbackAddress && (
            <Typography variant="subtitle2" color="text.secondary">
              <UnderlinedMatch target={fallbackAddress} input={inputValue} />
            </Typography>
          )
        );
      }}
      noOptionsText={!disabled && <Link to={link} label="Create new address" />}
    />
  );
};

export default AutocompleteAddressField;
