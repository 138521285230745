import { Metadata } from '@redwoodjs/web';

import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import EditPricingSheet from 'src/modules/systemManagement/pricingSheets/containers/EditPricingSheet';

const PricingSheetPage = () => {
  return (
    <>
      <Metadata title="Pricing Sheet" description="PricingSheet page" />
      <PageHeader pageType={PageType.PricingSheet} />
      <EditPricingSheet />
    </>
  );
};

export default PricingSheetPage;
