const GetBuySellOrderInvoiceDocumentData = gql`
  query GetBuySellOrderInvoiceDocumentData($slug: String!) {
    buySellOrderInvoice(slug: $slug) {
      organization {
        label
        logoUrl
        primaryLocation {
          ...PlaceAttributes
        }
      }
      slug
      totalAmountDue
      sentAt
      payments {
        paymentAmount
      }
      buySellOrder {
        ...BuySellOrderDocumentAttributes
      }
      expenses {
        ...ExpenseAttributes
      }
      referenceNumber
    }
  }
`;

export default {
  GetBuySellOrderInvoiceDocumentData,
};
