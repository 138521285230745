import { useCallback, useEffect, useState } from 'react';

import { Box, Button } from '@mui/material';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { enqueueSnackbar } from 'notistack';
import { Configure } from 'react-instantsearch-hooks-web';
import { asUTCDate } from 'shared/utils/DateTime';

import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';

import { GET_LEDGER_ACCOUNT_DETAILS } from 'src/api/ledgerAccounts.api';
import {
  COMPLETE_OPEN_RECONCILIATION_SESSION,
  CREATE_DEPOSIT_SLIP_FROM_ENTRIES,
  FETCH_LEDGER_ENTRY_GROUPS,
  OPEN_RECONCILIATION_SESSION,
  UPDATE_LEDGER_ENTRY_MEMO,
  UPDATE_LEDGER_ENTRY_RECONCILIATION,
  UPDATE_LEDGER_LINE_AMOUNT,
  UPDATE_LEDGER_LINE_MEMO,
} from 'src/api/ledgerEntries.api';
import { useAuth } from 'src/auth';
import FavoriteButton from 'src/components/atoms/buttons/FavoriteButton';
import { LedgerEntriesGrid } from 'src/modules/accounting/register/LedgerEntriesGrid';
import { EndBankReconcilingModal } from 'src/components/containers/modals/EndBankReconcilingModal';
import { StartAccountReconcilingModal } from 'src/components/containers/modals/StartAccountReconcilingModal';
import { StartBankReconcilingModal } from 'src/components/containers/modals/StartBankReconcilingModal';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import { LedgerEntryIndex } from 'src/lib/algolia';
import { AccountRegisterContext, AccountRegisterFilters } from 'src/pages/AccountRegisterPage/AccountRegisterContext';

import {
  ChartOfAccountsPageLedgerAccount,
  CreateAccountReconciliationInput,
  LedgerAccountStub,
  LedgerEntryGroup,
} from '$api/types/graphql';

type OpenModalType = null | 'START_BANK_RECONCILIATION' | 'END_BANK_RECONCILIATION' | 'START_ACCOUNT_RECONCILIATION';

const AccountRegisterPage = ({ path, accountReconciliationId }) => {
  const [updateLedgerEntryReconciliation] = useMutation(UPDATE_LEDGER_ENTRY_RECONCILIATION);
  const [completeOpenReconciliationSession] = useMutation(COMPLETE_OPEN_RECONCILIATION_SESSION);
  const [openReconciliationSession] = useMutation(OPEN_RECONCILIATION_SESSION);
  const [createDepositSlip] = useMutation(CREATE_DEPOSIT_SLIP_FROM_ENTRIES);

  const { currentUser } = useAuth();
  const [context, setContext] = useState<AccountRegisterContext>(() =>
    AccountRegisterContext.create().withFilters({ path, accountReconciliationId })
  );

  const onIsReconciledChanged = useCallback(
    async (ledgerEntryKey, isReconciled) => {
      const pessimisticContext = context;
      setContext((ctx) =>
        ctx
          .withLines(
            context.entries.map((l) =>
              l.entry.key === ledgerEntryKey ? { ...l, entry: { ...l.entry, reconciled: isReconciled } } : l
            )
          )
          .withLog('onIsReconciledChanged')
      );

      updateLedgerEntryReconciliation({
        variables: { ledgerEntryKey, reconciled: isReconciled, accountPath: context.state.account.path.join('/') },
      }).catch((error) => {
        setContext(pessimisticContext.withLog('onIsReconciledChangedError'));
        enqueueSnackbar(`Error reconciling entry: ${error.message}`, {
          variant: 'error',
        });
      });
    },
    [context]
  );
  const onBankReconciliationCompleted = useCallback(
    async (assetAccountPath, adjustmentAmount, endingBalanceDate: DateTime) => {
      const pessimisticContext = context;
      completeOpenReconciliationSession({
        variables: {
          assetAccountPath,
          adjustmentAmount: Math.floor(adjustmentAmount),
          endingBalanceDate: endingBalanceDate.toISODate(),
        },
      }).catch((error) => {
        setContext(pessimisticContext.withLog('onReconciledWithAdjustmentError'));
        enqueueSnackbar(`Error making adjustment: ${error.message}`, {
          variant: 'error',
        });
      });

      setContext((ctx) =>
        ctx
          .forListing()
          .withLines(
            [
              // infer values that we normally get from Algolia
              optimisticallyInferAdjustment(adjustmentAmount, {
                path: ctx.state.account?.path.join('/'),
                name: ctx.state.account?.name,
              }),
              ...ctx.entries,
            ],
            (updated, existing) => ({ ...updated, ...existing })
          )
          .withLog('onReconciledWithAdjustment')
      );
    },
    [context]
  );
  const onAnnotationsReceived = useCallback(
    (annotations: LedgerEntryIndex[]) => {
      setContext((ctx) => ctx.withLines(annotations).withLog('onAnnotationsReceived'));
    },
    [context]
  );

  const onStartReconciliation = useCallback(
    (input: CreateAccountReconciliationInput) => {
      const pessimisticContext = context;
      openReconciliationSession({
        variables: {
          input,
        },
      }).catch((error) => {
        setContext(pessimisticContext.withLog('onStartReconciliationError'));
        enqueueSnackbar(`Error opening reconciliation session: ${error.message}`, {
          variant: 'error',
        });
      });

      setContext((ctx) =>
        ctx
          .forBankReconciliation(input.beginningBalance, input.endingBalance, asUTCDate(input.endDate))
          .withFilters({
            ...ctx.filters,
            from: asUTCDate(input.startDate),
            until: asUTCDate(input.endDate)?.plus({ days: 1 }),
          })
          .withLog('onStartReconciliation')
      );
      setModalOpen(null);
    },
    [context]
  );

  const onResumeReconciliation = useCallback(
    (input: CreateAccountReconciliationInput) => {
      setContext((ctx) =>
        ctx
          .forBankReconciliation(input.beginningBalance, input.endingBalance, asUTCDate(input.endDate))
          .withFilters({
            ...ctx.filters,
            from: asUTCDate(input.startDate),
            until: asUTCDate(input.endDate)?.plus({ days: 1 }),
          })
          .withLog('onStartReconciliation')
      );
      setModalOpen(null);
    },
    [context]
  );

  const onEndReconciliation = useCallback(async () => {
    setModalOpen(null);
    if (context.state.mode === 'RECONCILING_BANK') {
      await onBankReconciliationCompleted(
        context.filters.path,
        context.bankReconciliationStats.deltaBalance,
        context.state.endingDate
      );
    } else {
      setContext((ctx) =>
        ctx
          .forListing()
          .withFilters({ ...ctx.filters, from: null, until: null })
          .withLog('onEndReconciliation')
      );
    }
  }, [context]);

  const onFiltersChanged = useCallback(
    async (filters: AccountRegisterFilters) => {
      setContext((ctx) => ctx.withFilters(filters).withLog('onFiltersChanged'));
    },
    [context]
  );

  const onStartAccountReconciliation = ({ startingDate, endingDate }) => {
    setContext((ctx) => ctx.forAccountReconciliation(startingDate, endingDate).withLog('onStartAccountReconciliation'));
    setModalOpen(null);
  };

  const onEndAccountReconciliation = () =>
    setContext((ctx) =>
      ctx
        .forListing()
        .withFilters({ path: ctx.filters.path, from: null, until: null })
        .withLog('onEndAccountReconciliation')
    );

  const onAccountDetailsReceived = useCallback(
    (account: ChartOfAccountsPageLedgerAccount) => {
      setContext((ctx) => ctx.withAccount(account).withLog('withAccount'));
    },
    [context]
  );

  const onDepositSlipCreated = useCallback(
    (depositDate: DateTime, memo: string, depositedByMemberId: number, ledgerEntryKeys: string[]) =>
      createDepositSlip({
        variables: {
          input: {
            depositedById: depositedByMemberId,
            accountPath: context.state.account.path.join('/'),
            depositDate,
            memo,
            ledgerEntryKeys: ledgerEntryKeys,
          },
        },
      }).then((response) => {
        setContext((ctx) =>
          ctx
            .withLines(
              ctx.entries.map(
                (tuple) =>
                  response.data.createDepositSlipFromEntries.find((t) => t.entry.key === tuple.entry.key) ?? tuple
              )
            )
            .withLog('onDepositSlipCreated')
        );
      }),
    [context]
  );

  const [modalOpen, setModalOpen] = useState<OpenModalType>(null);
  const { canStartBankReconciling, canEndBankReconciling, canStartAccountReconciling, canEndAccountReconciling } =
    context.capabilities;

  const query = useQuery(GET_LEDGER_ACCOUNT_DETAILS, {
    variables: {
      ledgerAccountKeys: path && [path],
    },
  });

  const accountDetails = query?.data?.chartOfAccountsPage;
  useEffect(() => {
    if (accountDetails && accountDetails.length === 1) {
      onAccountDetailsReceived(accountDetails[0]);
    }
  }, [accountDetails]);

  return (
    <>
      <MetaTags title="Account Register" />
      <PageHeader
        pageType={PageType.AccountRegister}
        title={context.state.account && `${context.state.account.number} ${context.state.account.name}`}
      >
        <Box sx={{ ml: 'auto', gap: 1, display: 'flex', my: 'auto' }}>
          <FavoriteButton label="Account Register" />
          {canStartBankReconciling && (
            <Button variant="contained" onClick={() => setModalOpen('START_BANK_RECONCILIATION')}>
              RECONCILE
            </Button>
          )}
          {canEndBankReconciling && (
            <Button variant="contained" onClick={() => setModalOpen('END_BANK_RECONCILIATION')}>
              COMPLETE RECONCILIATION
            </Button>
          )}
          {canStartAccountReconciling && (
            <Button variant="contained" onClick={() => setModalOpen('START_ACCOUNT_RECONCILIATION')}>
              RECONCILE
            </Button>
          )}
          {canEndAccountReconciling && (
            <Button variant="contained" onClick={onEndAccountReconciliation}>
              COMPLETE RECONCILIATION
            </Button>
          )}
        </Box>
      </PageHeader>
      <StartBankReconcilingModal
        isOpen={modalOpen === 'START_BANK_RECONCILIATION'}
        accountPath={context.state?.account?.path?.join('/')}
        onClose={() => setModalOpen(null)}
        onNext={(form) => {
          const input = {
            ledgerAccountPath: context.state.account.path.join('/'),
            reconciledById: currentUser.membershipId as number,
            startDate: asUTCDate(form.startingBalanceDate).toISO(),
            endDate: asUTCDate(form.endingBalanceDate).toISO(),

            endingBalance: form.endingBalanceAmount,
            beginningBalance: form.startingBalanceAmount,
          };

          if (form.accountReconciliationId) {
            onResumeReconciliation(input);
          } else {
            onStartReconciliation(input);
          }
        }}
      />
      <EndBankReconcilingModal
        context={context}
        isOpen={modalOpen === 'END_BANK_RECONCILIATION'}
        onClose={() => setModalOpen(null)}
        onConfirm={onEndReconciliation}
      />
      <StartAccountReconcilingModal
        isOpen={modalOpen === 'START_ACCOUNT_RECONCILIATION'}
        onClose={() => setModalOpen(null)}
        onNext={onStartAccountReconciliation}
      />
      <PageBody sx={{ flexDirection: 'column' }}>
        <LedgerEntriesGrid
          context={context}
          onAnnotationsReceived={onAnnotationsReceived}
          onIsReconciledChanged={onIsReconciledChanged}
          onBankReconciliationCompleted={onBankReconciliationCompleted}
          onFiltersChanged={onFiltersChanged}
          onDepositSlipCreated={onDepositSlipCreated}
        />
      </PageBody>
    </>
  );
};

const optimisticallyInferAdjustment = (amount: number, account: LedgerAccountStub): LedgerEntryGroup => ({
  entry: {
    key: `inferred-adjustment-${DateTime.utc()}`,
    memo: 'Reconciliation Adjustment Entry',
    reconciled: true,
    createdAt: DateTime.utc().toJSDate(),
    postedAt: DateTime.utc().toISODate(),
    sources: [],
    payments: [],
  },
  lines: [
    {
      key: `inferred-adjustment-${DateTime.utc()}-line`,
      memo: 'Reconciliation Adjustment',
      account,
      amount,
      sources: [],
    },
  ],
  ledgerKey: '',
  organizationId: 0,
});

export default AccountRegisterPage;
