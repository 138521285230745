import { useMemo, useState } from 'react';

import { FilterList } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { gridColumnDefinitionsSelector, useGridApiRef, useGridSelector, GridColDef } from '@mui/x-data-grid-premium';

import { ServerGridFilter } from '../../serverEnhanced/types';
import { ServerEnhancedGridStateWithHelpers } from '../../serverEnhanced/useServerEnhancedGridState';
import ToolbarButton from '../ToolbarButton';

type AddFilterProps = {
  columns: GridColDef<any, any, any>[];
  enhancedGridState: ServerEnhancedGridStateWithHelpers;
};

export default function AddFilter({ columns, enhancedGridState }: AddFilterProps) {
  const filterableColumns = useMemo(() => columns.filter((col) => !!col.filterable), [columns]);
  const currentFiltersKeys = useMemo(
    () => enhancedGridState.gridFilters.map((filter) => filter.field),
    [enhancedGridState.gridFilters]
  );
  const [filterKeys, setFilterKeys] = useState<string[]>(currentFiltersKeys);

  const toggleFilter = (field: string, checked: boolean) => {
    setFilterKeys((prev) => (checked ? [...prev, field] : prev.filter((key) => key !== field)));
  };

  const apply = () => {
    const newFilters: ServerGridFilter[] = filterKeys.map((field) => {
      const existingFilter = enhancedGridState.gridFilters.find((filter) => filter.field === field);
      if (existingFilter) return existingFilter;
      return { field, where: [] };
    });
    enhancedGridState.updateFilters(newFilters);
  };

  return (
    <ToolbarButton icon={<FilterList />} label="Filters">
      {(closeMenu) => (
        <Stack sx={{ minWidth: '256px' }}>
          <Typography sx={{ p: 0.5, pl: 2 }} variant="overline">
            ADD FILTER
          </Typography>
          <Stack sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
            {filterableColumns.map((col) => (
              <FormControlLabel
                key={col.field}
                sx={{ p: 0.5, m: 0, gap: 1, '&:hover': { bgcolor: 'primary.100' } }}
                control={<Checkbox checked={filterKeys.includes(col.field)} size="small" sx={{ py: 0.5, px: 1 }} />}
                label={col.headerName ?? col.field}
                onChange={(_, val) => toggleFilter(col.field, val)}
              />
            ))}
          </Stack>
          <Stack direction="row" justifyContent="space-between" sx={{ p: 1, pb: 0.5, borderTop: '1px solid #D8D9D7' }}>
            <Button
              onClick={() => {
                setFilterKeys([]);
                closeMenu();
              }}
            >
              Clear All
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                apply();
                closeMenu();
              }}
            >
              Add Filters
            </Button>
          </Stack>
        </Stack>
      )}
    </ToolbarButton>
  );
}
