import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ScannerIcon(props: Omit<SvgIconProps, 'key'>) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 18">
      <path
        d="M0 18V13H2V16H5V18H0ZM17 18V16H20V13H22V18H17ZM3 15V3H5V15H3ZM6 15V3H7V15H6ZM9 15V3H11V15H9ZM12 15V3H15V15H12ZM16 15V3H17V15H16ZM18 15V3H19V15H18ZM0 5V0H5V2H2V5H0ZM20 5V2H17V0H22V5H20Z"
        fill="#28310B"
        fillOpacity="0.54"
      />
    </SvgIcon>
  );
}
