import { useQuery } from '@redwoodjs/web';

import InvoiceDocumentApi from '../api/InvoiceDocument.api';

export default function useInvoiceDocument({ slug, skip = false }) {
  const { data, loading, refetch } = useQuery(InvoiceDocumentApi.GetInvoiceDocumentData, {
    variables: { slug },
    skip,
  });

  return {
    invoice: data?.invoice,
    loading,
    getDocumentData: () => refetch({ slug }).then((res) => res.data.invoice),
  };
}
