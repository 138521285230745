import { useMemo } from 'react';

import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-premium';

import { routes } from '@redwoodjs/router';

import Link from 'src/components/atoms/Link';
import StatusBadge from 'src/components/atoms/StatusBadge';
import useGridExport from 'src/hooks/useGridExport';
import ServerEnhancedDataGrid from 'src/modules/grids/serverEnhanced/ServerEnhancedDataGrid';
import { useServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';
import { getDistinctValues } from 'src/modules/grids/toolbar/filters/tools';
import GridToolbarV2, { GridToolbarQuickFilter } from 'src/modules/grids/toolbar/GridToolbarV2';
import getFullName from 'src/utils/getFullName';
import { dateCellFormatter, dateGetter } from 'src/utils/grid/cell/cellFormatters';

import { useInventoryTransfersPage } from './useInventoryTransfersPage';

export const gridQuickFilters: GridToolbarQuickFilter[] = [
  {
    label: 'All time',
    filters: [],
  },
  {
    label: 'Open Transfers',
    filters: [
      {
        field: 'status',
        where: [{ in: ['SUBMITTED', 'SHIPPED'] }],
      },
    ],
  },
  {
    label: 'Closed',
    filters: [{ field: 'status', where: [{ equals: 'CLOSED' }] }],
  },
  {
    label: 'Drafts',
    filters: [{ field: 'status', where: [{ equals: 'DRAFT' }] }],
  },
];

const columns = {
  slug: {
    headerName: 'Transfer Number',
    type: 'string',
    minWidth: 110,
    flex: 1,
    renderCell: ({ row, value }: GridRenderCellParams) => (
      <Link to={routes.inventoryTransfer({ slug: row.slug })} label={`IT #${value}`} />
    ),
  },
  status: {
    headerName: 'Status',
    type: 'string',
    minWidth: 110,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <StatusBadge status={params.value} />,
    getFilterSelectOptions: async () => ['DRAFT', 'SUBMITTED', 'SHIPPED', 'CLOSED'],
  },
  'shipment.origin.description': {
    headerName: 'Origin',
    type: 'string',
    flex: 1,
    valueGetter: (_value, row) => {
      const origin = row.shipment.origin;
      if (!origin) return '--';
      if (origin.description) return origin.description;
      return [origin.streetAddress1, origin.city, origin.state].filter(Boolean).join(' ');
    },
    getFilterSelectOptions: (client) => getDistinctValues(client, 'InventoryTransfer.shipment.origin'),
  },
  'shipment.destination.description': {
    headerName: 'Destination',
    type: 'string',
    flex: 1,
    valueGetter: (_value, row) => {
      const destination = row.shipment.destination;
      if (!destination) return '--';
      if (destination.description) return destination.description;
      return [destination.streetAddress1, destination.city, destination.state].filter(Boolean).join(' ');
    },
    getFilterSelectOptions: (client) => getDistinctValues(client, 'InventoryTransfer.shipment.destination'),
  },
  'shipment.shipDate': {
    headerName: 'Ship Date',
    type: 'date',
    width: 115,
    flex: 1,
    filterable: false,
    valueGetter: (_value, row) => dateGetter(row.shipment.shipDate),
    valueFormatter: dateCellFormatter,
  },
  'shipment.deliveryDate': {
    headerName: 'Delivery Date',
    type: 'date',
    width: 115,
    flex: 1,
    filterable: false,
    valueGetter: (_value, row) => dateGetter(row.shipment.deliveryDate),
    valueFormatter: dateCellFormatter,
  },
  'shipment.temperatureMin': {
    headerName: 'Min Temperature',
    type: 'number',
    width: 115,
    flex: 1,
    filterable: false,
    valueGetter: (_value, row) => row.shipment.temperatureMin,
  },
  'shipment.temperatureMax': {
    headerName: 'Max Temperature',
    type: 'number',
    width: 115,
    flex: 1,
    filterable: false,
    valueGetter: (_value, row) => row.shipment.temperatureMax,
  },
  'contact.user.firstName': {
    headerName: 'Contact',
    minWidth: 100,
    flex: 1,
    type: 'string',
    valueGetter: (_value, row) => getFullName(row.contact?.user),
    getFilterSelectOptions: (client) => getDistinctValues(client, 'InventoryTransfer.contact.user.fullName'),
  },
};

const STORAGE_KEY = 'inventoryTransfers';

export default function InventoryTransfersGrid() {
  const apiRef = useGridApiRef();

  const enhancedGridState = useServerEnhancedGridState(STORAGE_KEY, gridQuickFilters);
  const { rows, loading, pageInfo, totalCount, fetchAllForExport } = useInventoryTransfersPage(enhancedGridState);
  useGridExport(apiRef, 'inventory-transfers', undefined, undefined, fetchAllForExport);

  const toolbar = useMemo(
    () => (
      <GridToolbarV2 apiRef={apiRef} enhancedGridState={enhancedGridState} quickFilterCountModel="InventoryTransfer" />
    ),
    [apiRef, enhancedGridState]
  );

  return (
    <ServerEnhancedDataGrid
      sx={{ p: 3 }}
      enhancedGridState={enhancedGridState}
      totalCount={totalCount}
      pageInfo={pageInfo}
      loading={loading}
      apiRef={apiRef}
      rows={rows}
      pinnedColumns={{ left: ['slug'], right: ['actions'] }}
      columns={columns}
      storageKey={STORAGE_KEY}
      slots={{ toolbar }}
    />
  );
}
