import { ReactNode } from 'react';

import { Chip, useTheme } from '@mui/material';
import Color from 'color';

import convertToTitleCase from 'src/utils/convertToTitleCase';

const getStatusColor = (theme, flagged, isTemplate) => {
  if (flagged) {
    return theme.palette.error;
  }

  if (isTemplate) {
    return theme.palette.info;
  }

  return theme.palette.primary;
};

const getDisplayStatus = (flagged, isTemplate, status) => {
  if (isTemplate) {
    return 'Template';
  }

  if (flagged) {
    return 'Flagged';
  }

  return status;
};

export default function HeaderStatusBadge({
  status,
  flagged,
  isTemplate,
  afterStatus = null,
}: {
  status: string;
  flagged?: boolean;
  isTemplate?: boolean;
  afterStatus?: ReactNode;
}) {
  const theme = useTheme();

  const displayStatus = getDisplayStatus(flagged, isTemplate, convertToTitleCase(status));
  const color = getStatusColor(theme, flagged, isTemplate);

  return (
    <Chip
      data-testid="status-badge"
      label={
        <>
          {displayStatus}
          {afterStatus}
        </>
      }
      sx={{
        ml: 2,
        my: 'auto',
        height: '32px',
        color: color.dark,
        background: Color(color.main).alpha(0.22).toString(),
      }}
    />
  );
}
