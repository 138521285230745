import { useCallback } from 'react';

import { useUser } from '@clerk/clerk-react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { startOfDay } from 'shared/utils/DateTime';
import { v4 } from 'uuid';

import { Form, useForm } from '@redwoodjs/forms';
import { useMutation } from '@redwoodjs/web';

import { CREATE_NOTE_MUTATION } from 'src/api/notes.api';

import { postComment } from '../notes/CommentsSection';
import { NewNote } from '../notes/NewComment';

export type NoteRequiredModel = {
  handleClose: () => void;
  headerLabel: string;
  contentText: string;
  confirmLabel: string;

  additionalOptions?: React.ReactNode;

  // List out all actions that will be taken after action. (Shown as a description for user to confirm)
  postActionNotes?: string[];

  onSubmit: (comment?: string) => void;

  // enabled by default - the modal itself will handle posting the comment
  autoHandleCommentPost?: boolean;
  open: boolean;
} & Omit<NewNote, 'onCreate'>;

export default function NoteRequiredModel({
  handleClose,
  onSubmit,
  headerLabel,
  contentText,
  confirmLabel,
  postActionNotes,
  open,
  external,
  postCommentTo,
  additionalOptions = null,
  autoHandleCommentPost = true,
}: NoteRequiredModel) {
  const { user } = useUser();

  const [createComment] = useMutation(CREATE_NOTE_MUTATION, {
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const formMethods = useForm<{
    comment: string;
  }>({
    defaultValues: {
      comment: '',
    },
  });

  const { register, reset } = formMethods;

  const handleSubmit = useCallback(
    ({ comment }) => {
      if (autoHandleCommentPost) {
        createComment({
          variables: {
            input: {
              text: comment,
              external,
              ...postCommentTo,
            },
          },
        }).then(() => {
          postComment({
            text: comment,
            current: true,
            external,
            id: v4(),
            membership: {
              user: {
                firstName: user.firstName,
                lastName: user.lastName,
              },
            },
            createdAt: startOfDay().toISO(),
          });
          onSubmit(comment);
          reset();
          handleClose();
        });
      } else {
        onSubmit(comment);
        reset();
        handleClose();
      }
    },
    [postCommentTo, external, onSubmit, handleClose, createComment, reset, user, autoHandleCommentPost]
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <Form onSubmit={handleSubmit} formMethods={formMethods}>
        <DialogTitle>{headerLabel}</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
          <FormControl sx={{ width: '100%', pt: 3 }}>
            <TextField
              multiline
              minRows={3}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              defaultValue=""
              {...register('comment')}
            />

            {additionalOptions}

            {postActionNotes && postActionNotes.length > 0 && (
              <Box>
                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                  The following actions will be taken:
                </Typography>
                <Box ml={2.5}>
                  {postActionNotes.map((note) => (
                    <DialogContentText key={note} variant="body2" sx={{ mt: 0.5, color: 'rgba(0, 0, 0, 0.54)' }}>
                      <li>{note}</li>
                    </DialogContentText>
                  ))}
                </Box>
              </Box>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit">{confirmLabel}</Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
