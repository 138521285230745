import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page } from '@react-pdf/renderer';

import { USD } from 'src/lib/money/usd';

import { Expenses, Footer, Notes, ProductsGrid, PurchaseOrderHeader, TermsAndTotals } from '../sections';
import { formatCurrency } from '../utils/formatters';
import { docStyles } from '../utils/styles';

export type BSOPurchaseOrderTemplateProps = Prisma.BuySellOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    broker: { include: { user: true; subsidiary: true } };
    vendor: true;
    vendorContact: true;
    vendorBillTo: true;
    customer: true;
    customerContact: true;
    shipment: true;
    subsidiary: true;
    lineItems: {
      include: {
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
          };
        };
      };
    };
    expenses: {
      include: {
        businessEntity: true;
      };
    };
    notes: {
      include: {
        membership: {
          include: {
            user: true;
          };
        };
      };
    };
  };
}>;

export function BSOPurchaseOrderTemplate(buySellOrder: BSOPurchaseOrderTemplateProps) {
  const allLineItems = buySellOrder.lineItems;

  const lineItemTotal = allLineItems.reduce((total, lineItem) => {
    return total.add(USD.fromCents(lineItem.buyCurrencyPrice ?? lineItem.buyPrice).multiply(lineItem.unitsOrdered));
  }, USD.fromCents(0));

  const accountsPayableExpenses = buySellOrder.expenses
    .filter((expense) => expense.accountLevel === 'ACCOUNTS_PAYABLE')
    .filter(
      (expense) =>
        (expense.businessEntity === null || expense.businessEntityId === buySellOrder.vendor?.id) &&
        !expense.parentExpenseId
    );

  const expenseTotal = accountsPayableExpenses.reduce((total, expense) => {
    return total.add(USD.fromCents(expense.currencyAmount ?? expense.unitAmount).multiply(expense.quantity));
  }, USD.fromCents(0));

  const grandTotal = expenseTotal.add(lineItemTotal);

  const externalNotes = buySellOrder.notes?.filter((n) => n.documentTypes.includes('PURCHASE_ORDER_CONFIRMATION'));

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <PurchaseOrderHeader order={buySellOrder} />

        <ProductsGrid
          productLineItems={allLineItems}
          showUnitsOrdered={true}
          showUnitPrice={true}
          showSubTotal={true}
          isBuySellOrderPurchaseOrder={true}
          currency={buySellOrder.currencyCode}
          productTemplate={buySellOrder.organization.productDescriptionTemplate}
        />

        <Expenses expenses={accountsPayableExpenses} currency={buySellOrder.currencyCode} />

        <TermsAndTotals grandTotal={formatCurrency(grandTotal.dollars, buySellOrder.currencyCode)} />

        <Notes notes={externalNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
