import { DocumentType } from './documents/DocumentGenerator';

export type Organization = {
  logoUrl: string;
  label: string;
  primaryLocation: Place;
  places: Place[];
};

export type Membership = {
  user: User;
};

export type User = {
  firstName: string;
  lastName: string;
  email: string;
};

export type Product = {
  id: number;
  productCode?: string;

  commodity: {
    name: string;
  };
  commodityStyle: {
    name: string;
  };
  commoditySize: {
    name: string;
  };
  commodityUnit: {
    name: string;
  };
  commodityLabel?: {
    name: string;
  };

  countryOfOrigin: string;
  organic?: boolean;
  grade?: string;

  weightPerInventoryUnit: number;
  variableWeight: boolean;

  cartonsPerPallet: number;
};

export type Lot = {
  slug: string;
  initialQuantity: number;
  initialWeight: number;
};

export type BusinessEntity = {
  id: number;
  name: string;
  receivablesAccount?: LedgerAccount;
  payablesAccount?: LedgerAccount;
};

export type LedgerEntryGroupKey =
  | 'payables_to'
  | 'invoices_to'
  | 'payable'
  | 'invoice'
  | 'counter_party'
  | 'departments/staff';

export type LedgerAccount = {
  id: number;
  fragmentAccountPath: string;
  accountType: AccountSubType;
  accountNumber: string;
  accountName: string;
};

export type PalletTag = {
  id: number;
  tagNumber: string;

  cartonQuantity: number;
  initialQuantity: number;

  weight: number;
  initialWeight: number;
};

export type PalletTagsOnLineItem = {
  quantity: number;
  palletTag: PalletTag;
};

export type LineItem = {
  id: number;

  unitsOrdered?: number;
  unitsReceived?: number;
  unitsPicked?: number;
  unitsShipped?: number;
  settlementPrice: number;
  currencyPrice: number;
  product: Product;
  lot: Lot;
  landedCost: number;
  profit: number;
  consignmentPayableId: number;
  parentLineItemId: number;
  childrenLineItems: LineItem[];
  weightReceived: number;
  external: boolean;
  vendor: BusinessEntity;
  vendorId: number;

  buyPrice: number;
  sellPrice: number;

  buyCurrencyPrice: number;
  sellCurrencyPrice: number;

  deliveredPrice: number;
  deliveredCurrencyPrice: number;

  palletTagsOnLineItem: PalletTagsOnLineItem[];
};

export type BuySellOrderLineItem = {
  id: number;

  unitsOrdered?: number;
  unitsPicked?: number;
  unitsShipped?: number;
  unitsInvoiced?: number;

  product: Product;

  sellPrice: number;
  sellCurrencyPrice: number;

  buyPrice: number;
  buyCurrencyPrice: number;

  deliveredPrice: number;
  deliveredCurrencyPrice: number;
};

export enum SalesTerms {
  COD = 'COD',
  NET_7 = 'NET_7',
  NET_10 = 'NET_10',
  NET_14 = 'NET_14',
  NET_21 = 'NET_21',
  NET_25 = 'NET_25',
  NET_30 = 'NET_30',
  NET_45 = 'NET_45',
  NET_60 = 'NET_60',
  NET_40 = 'NET_40',
  NET_90 = 'NET_90',
}

export enum SalesOrderStatus {
  DRAFT,
  PENDING,
  SUBMITTED,
  APPROVED,
  PICKING,
  SHIPPED,
  CLOSED,
  CANCELLED,
  ERROR,
}

export type Contact = {
  id: number;
  name: number;
  email: string;
  phone: string;
};

export type Place = {
  description?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  addressCountry?: string;
  faxNumber?: string;
  phone?: string;
};

export type Shipment = {
  terms: string;
  shipDate: string;
  deliveryDate: string;
  freightCompany?: BusinessEntity;
  freightCompanyId?: number;
  destination?: Place;
  destinationId?: number;
  origin?: Place;
  originId?: number;
  // Ensure that place is remapped to either destination or origin
  place?: Place;
  placeId?: number;
  customFields: Record<string, string | number>;
  temperatureMin: number;
  temperatureMax: number;
};

export type AccountType = 'Asset' | 'Liability' | 'Income' | 'Expense';
export type AccountSubType =
  | 'CashAssets'
  | 'CurrentAssets'
  | 'CurrentLiabilities'
  | 'DirectCosts'
  | 'Equity'
  | 'Income'
  | 'IndirectCosts'
  | 'LongTermAssets'
  | 'LongTermLiabilities'
  | 'OtherAssets'
  | 'OtherCosts'
  | 'OtherIncome'
  | 'asset'
  | 'expense'
  | 'income'
  | 'liability';

export enum AccountLevel {
  ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
  GROWER_PAYABLE = 'GROWER_PAYABLE',
}

export type Expense = {
  id: number;
  expenseType: {
    id: number;
    name: string;
    receivablesAccount?: LedgerAccount;
    payablesAccount?: LedgerAccount;
  };
  description?: string;
  unitAmount: number;
  quantity: number;
  currencyAmount?: number;
  settlementAmount?: number;
  ledgerAccount?: LedgerAccount;
  businessEntity?: BusinessEntity;
  businessEntityId?: number;
  parentExpenseId?: number;
  includedInDlvPrice?: boolean;
  accountLevel: AccountLevel;
};

export type GenericInvoice = {
  slug: string;
  // wo and so are intentionally left as the order slug
  // as payables to a customer is not relevant
  workOrder?: {
    slug?: string;
  };
  salesOrder?: {
    slug?: string;
  };
  buySellOrder?: {
    vendorSo?: string;
  };
  purchaseOrder?: {
    vendorPo?: string;
  };
};

export type Payment = {
  slug: string;
  paymentDate: string;
  paymentAmount: number;
  paymentDetails: string;
  invoice?: {
    slug: string;
    sentAt?: string;
  };
  standardInvoice?: GenericInvoice & {
    slug: string;
    invoiceDate?: string;
  };
  standardPayable?: GenericInvoice & {
    invoiceDate?: string;
    referenceInvoiceNum?: string;
    payToAddress?: Place;
  };
  payable?: GenericInvoice & {
    receivedAt?: string;
    referenceNumber?: string;
    purchaseOrder?: {
      shipment?: {
        place?: Place;
      };
    };
  };
  buySellOrderInvoice?: GenericInvoice & {
    sentAt?: string;
    referenceNumber?: string;
  };
  buySellOrderPayable?: GenericInvoice & {
    receivedAt?: string;
    referenceNumber?: string;
    buySellOrder?: {
      vendorSo?: string;
      vendorBillTo?: Place;
    };
  };
  consignmentPayable?: GenericInvoice & {
    createdAt?: string;
    referenceNumber?: string;
    workOrder?: {
      shipment?: {
        origin?: Place;
      };
    };
  };
  childPayments: Payment[];
};

export type Note = {
  id: number;
  text: string;
  user: User;
  createdAt: string;
  documentTypes: DocumentType[];
  customDocumentTypes: {
    documentTemplateId: number;
  }[];
};

export type ListTemplate<T> = {
  isEmpty: boolean;
  items: T[];
};

export type CustomFields = Record<string, string | number | boolean>;

export type FinanceConfiguration = {
  id: number;
  cogsAccount?: LedgerAccount;
  consignmentExpensesAccount?: LedgerAccount;
  defaultAssetsAccount?: LedgerAccount;
  defaultExpensesAccount?: LedgerAccount;
  defaultIncomeAccount?: LedgerAccount;
  defaultLiabilitiesAccount?: LedgerAccount;
  deferredCogsAccount?: LedgerAccount;
  inventoryAccount?: LedgerAccount;
  inventoryIncomeAccount?: LedgerAccount;
  inventoryLossAccount?: LedgerAccount;
  orderSalesAccount?: LedgerAccount;
  otherExpensesAccount?: LedgerAccount;
  payableAccount?: LedgerAccount;
  purchaseAccrualsAccount?: LedgerAccount;
  purchaseExpensesAccount?: LedgerAccount;
  receivablesAccount?: LedgerAccount;
};

export type PageInfo = {
  endCursor?: number;
  hasNextPage?: boolean;
};

export type PaginationEdge<T> = {
  node: T;
  cursor: number;
};

export type PaginationResult<T = any> = {
  totalCount: number;
  edges: PaginationEdge<T>[];
  pageInfo: PageInfo;
};
