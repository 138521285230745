import { Typography } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid-premium';

import Hint from '../Hint';

export default function BoldHeader(params: GridColumnHeaderParams) {
  return (
    <Hint title={params.colDef.description}>
      <Typography fontWeight={500} fontSize={14}>
        {params.colDef.headerName}
      </Typography>
    </Hint>
  );
}
