import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import FormField from 'src/components/atoms/form/FormField';
import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useQuery } from '@redwoodjs/web';
import { asUTCDate } from 'shared/utils/DateTime';
import { ClosingPeriodDatePicker } from 'src/components/atoms/ClosingPeriodDatePicker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QuickDatePicker from 'src/components/atoms/QuickDatePicker';

export type StartBankReconcilingForm = {
  accountReconciliationId: number;
  startingBalanceAmount: number;
  endingBalanceAmount: number;
  startingBalanceDate: DateTime;
  endingBalanceDate: DateTime;
};

export const StartBankReconcilingModal = ({
  isOpen,
  onClose,
  accountPath,
  onNext,
}: {
  isOpen: boolean;
  onClose: () => void;
  accountPath: string;
  onNext: (formValues: StartBankReconcilingForm) => void;
}) => {
  const [endingBalanceAmount, setEndingBalanceAmount] = useState<string>(null);
  const [endingBalanceDate, setEndingBalanceDate] = useState<string>(null);
  const [suggestedSession, setSuggestedSession] = useState<{
    accountReconciliationId: number;
    startDate?: DateTime;
    endDate?: DateTime;
    beginningBalance: number;
    endingBalance?: number;
  }>(null);
  const findOrSuggestSession = useQuery(
    gql`
      query findOrSuggestAccountReconciliation($accountPath: String!) {
        findOrSuggestAccountReconciliation(accountPath: $accountPath) {
          accountReconciliationId
          ledgerAccountPath
          startDate
          endDate
          beginningBalance
          endingBalance
        }
      }
    `,
    { variables: { accountPath: accountPath }, skip: !accountPath }
  );

  useEffect(() => {
    if (accountPath) {
      findOrSuggestSession.refetch({ accountPath });
    }
  }, [accountPath]);

  useEffect(() => {
    if (findOrSuggestSession.data?.findOrSuggestAccountReconciliation) {
      const suggestion = {
        accountReconciliationId: findOrSuggestSession.data?.findOrSuggestAccountReconciliation.accountReconciliationId,
        startDate: asUTCDate(findOrSuggestSession.data?.findOrSuggestAccountReconciliation.startDate),
        endDate: asUTCDate(findOrSuggestSession.data?.findOrSuggestAccountReconciliation.endDate),
        beginningBalance: findOrSuggestSession.data?.findOrSuggestAccountReconciliation.beginningBalance,
        endingBalance: findOrSuggestSession.data?.findOrSuggestAccountReconciliation.endingBalance,
      };
      setSuggestedSession(suggestion);
    }
  }, [findOrSuggestSession.data?.findOrSuggestAccountReconciliation]);

  useEffect(() => {
    if (suggestedSession?.accountReconciliationId && isOpen) {
      // if an open session already exists, immediately transition to it
      onNext({
        accountReconciliationId: suggestedSession.accountReconciliationId,
        startingBalanceAmount: suggestedSession.beginningBalance,
        endingBalanceAmount: suggestedSession.endingBalance,
        startingBalanceDate: suggestedSession.startDate,
        endingBalanceDate: suggestedSession.endDate,
      });
    }
  }, [suggestedSession, isOpen]);

  const closeModal = () => {
    setEndingBalanceAmount(null);
    setEndingBalanceDate(null);
    onClose();
  };

  const isValid = useMemo(
    () =>
      Number.isFinite(+endingBalanceAmount) &&
      endingBalanceDate !== null &&
      suggestedSession?.startDate &&
      DateTime.fromISO(endingBalanceDate, { zone: 'utc' }) !== null &&
      suggestedSession?.startDate < DateTime.fromISO(endingBalanceDate, { zone: 'utc' }),
    [endingBalanceAmount, endingBalanceDate, suggestedSession]
  );

  const formik = useFormik({
    initialValues: {},
    validateOnChange: false,
    onSubmit: () => {
      onNext({
        accountReconciliationId: suggestedSession?.accountReconciliationId,
        startingBalanceAmount: suggestedSession?.beginningBalance,
        endingBalanceAmount: Math.floor(Number.parseFloat(endingBalanceAmount) * 100),
        startingBalanceDate: suggestedSession?.startDate,
        endingBalanceDate: DateTime.fromISO(endingBalanceDate, { zone: 'UTC' }),
      });
    },
  });

  if (suggestedSession?.accountReconciliationId && isOpen) {
    return <></>;
  }

  return (
    <FormikProvider value={formik}>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Reconcile transactions</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <FormField
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              margin="dense"
              name="endingBalanceAmount"
              fieldProps={{
                type: 'text',
                label: 'Ending balance amount',
                size: 'small',
                value: endingBalanceAmount,
              }}
              fullWidth
              continuousSync={false}
              about={'this is about'}
              onChangeValue={setEndingBalanceAmount}
            />
            <Typography color="text.secondary" variant="caption" sx={{ px: 1.75 }}>
              Enter the ending balance as it appears in your bank statement.
            </Typography>

            <Box sx={{ marginTop: 3 }} display="flex" flexDirection="column">
              <QuickDatePicker
                label="Ending balance date"
                value={endingBalanceDate}
                onChange={setEndingBalanceDate}
                minDate={suggestedSession?.startDate.startOf('day')?.toLocaleString()}
                components={{
                  ActionBar: () => (
                    <>
                      {suggestedSession?.startDate && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          borderTop={1}
                          borderColor="#005556"
                          paddingY={2}
                          paddingX={1.5}
                        >
                          <Typography color="#005556" display="flex" flexDirection="row" alignItems="start">
                            <InfoOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> A new session cannot end
                            before {suggestedSession?.startDate.startOf('day')?.toLocaleString()}.
                          </Typography>
                        </Box>
                      )}
                    </>
                  ),
                }}
                renderInput={(props) => <TextField sx={{ width: '100%' }} {...props} />}
              />
              <Typography color="text.secondary" variant="caption" sx={{ px: 1.75 }}>
                Enter the date of your bank statement.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button type="submit" disabled={!isValid} onClick={() => formik.handleSubmit()} variant="contained">
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
};
