import { Warehouse } from '@mui/icons-material';

import { Link, routes } from '@redwoodjs/router';
import { Metadata } from '@redwoodjs/web';

export default function ScanPage() {
  return (
    <>
      <Metadata title="Scan Home" />
      <div className="flex flex-col gap-6 p-4 bg-white min-h-full">
        <p className=" text-lg">Select scanner action</p>
        <Link
          to={routes.scanLocation()}
          className="bg-white p-4 flex flex-col gap-4 justify-center items-center rounded border border-grey-300 hover:shadow"
        >
          <Warehouse style={{ fontSize: 80 }} className="text-green-main" />
          <p className="text-xl">Assign Pallet Locations</p>
        </Link>
      </div>
    </>
  );
}
