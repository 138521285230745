import { SyntheticEvent, useEffect, useRef } from 'react';

import { AutocompleteProps, AutocompleteValue, FilterOptionsState, TextField, useTheme } from '@mui/material';
import { GridCellParams, GridRenderEditCellParams, GridRowId, useGridApiContext } from '@mui/x-data-grid-premium';

import Autocomplete, { ExtraAutoCompleteProps } from '../Autocomplete';

/**
 * TODO: preview the first match in the cell
 * Note: this must be used inside of an AddableDataGrid.
 */
export default function AutoCompleteCell<
  T = { id?: number },
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  id,
  value,
  field,
  filterOptions,
  options,
  getOptionLabel,
  details,
  onChange,
  isOptionEqualToValue = areIdsEqual,
  textFieldProps,
  freeSolo,
  freeSoloPrefix,
  defaultValue = null,
  hasFocus,
  ...props
}: {
  id: GridRowId;
  value?: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>;
  field: string;
  filterOptions?: (options: T[], params: FilterOptionsState<T>) => T[];
  getOptionLabel: (option: T) => string;
  onChange?: (e: SyntheticEvent, newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>) => void;
  details?: (option, inputValue?: string) => React.ReactNode;
  freeSoloPrefix?: string;
  textFieldProps?: React.ComponentProps<typeof TextField>;
  options: T[];
  autoFocus?: boolean;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  freeSolo?: FreeSolo;
  defaultValue?: T;
  hasFocus?: boolean;
} & GridRenderEditCellParams &
  ExtraAutoCompleteProps &
  Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'id'> & {
    renderInput?: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderInput'];
  }) {
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (hasFocus) {
      ref.current?.focus();
    }
  }, [hasFocus]);

  const handleChange =
    onChange ??
    ((e, value) => {
      apiRef.current.setEditCellValue({ id, field, value });
      e.stopPropagation();
    });

  // These are props that are being spread from `renderEditCell(params)` onto the autocomplete which throws a bunch of warnings
  // We should really move away from spread props and use a more explicit API for this, but this is the safest way to suppress the warnings for now
  const {
    rowNode: _,
    colDef: _a,
    cellMode: _b,
    isEditable: _c,
    isProcessingProps: _d,
    getPrediction: _e,
    formattedValue: _f,
    formatPrediction: _g,
    ...autocompleteProps
  } = props;

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      value={value || defaultValue}
      freeSolo={freeSolo}
      fullWidth
      details={details}
      freeSoloPrefix={freeSoloPrefix}
      filterOptions={filterOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      options={options}
      getOptionLabel={getOptionLabel}
      size="small"
      slotProps={{
        popper: {
          sx: {
            zIndex: theme.zIndex.tooltip + 1,
            minWidth: 'fit-content',
            maxWidth: '90vw',
          },
        },
      }}
      border="none"
      onChange={handleChange}
      {...autocompleteProps}
      textFieldProps={{
        inputRef: ref,
        fullWidth: true,
        ...textFieldProps,
        sx: {
          '& .MuiInputBase-input': {
            fontSize: '14px',
            outline: 'none',
          },
          '& .MuiInputBase-root': {
            overflow: 'hidden',
            overflowX: 'auto',
            flexWrap: 'nowrap',
            wrap: 'nowrap',
            maxHeight: '64px',
            width: '100%',
            borderRadius: 0,
          },
          ...textFieldProps?.sx,
        },
      }}
      sx={{
        height: '100%',
        '& .MuiFormControl-root': {
          height: '100%',
        },
        ...autocompleteProps.sx,
      }}
    />
  );
}

const areIdsEqual = (option, value) => option.id === value.id;
export const autocompleteCellClassName = (params: GridCellParams) => {
  return `AutoCompleteCell-${params.cellMode}`;
};
