import { useState } from 'react';

import { Box, Collapse, TextField, TextFieldProps } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';

import { useMutation } from '@redwoodjs/web';

import Label from '../../atoms/Label';

import EditLabelsModal from './EditLabelsModel';
import LabelMenu from './LabelMenu';

export const CREATE_LABEL_MUTATION = gql`
  mutation CreateLabelDefinition($input: CreateLabelDefinitionInput!) {
    createLabelDefinition(input: $input) {
      id
    }
  }
`;

export const UPDATE_LABEL_MUTATION = gql`
  mutation UpdateLabelDefinition($id: Int!, $input: UpdateLabelDefinitionInput!) {
    updateLabelDefinition(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_LABEL_MUTATION = gql`
  mutation DeleteLabelDefinition($id: Int!) {
    deleteLabelDefinition(id: $id) {
      id
    }
  }
`;

export const LABELS_QUERY = gql`
  query LabelDefinitionsQuery($types: [LabelType]) {
    labelDefinitions(types: $types) {
      id
      label
      description
      color
      labelType
    }
  }
`;

export const ATTACH_LABEL_MUTATION = gql`
  mutation AttachLabel($input: CreateLabelAttachmentInput!) {
    createLabelAttachment(input: $input) {
      id
      labelDefinition {
        id
      }
    }
  }
`;

export const DETACH_LABEL_MUTATION = gql`
  mutation DetachLabel($id: Int!) {
    deleteLabelAttachment(id: $id) {
      id
    }
  }
`;

export default function LabelsContainer({
  attachedLabels,
  setAttachedLabels,
  labelDefinitions,
  setLabelDefinitions,
  labelType,
  attachTo,
}) {
  const [createLabel] = useMutation(CREATE_LABEL_MUTATION);
  const [deleteLabel] = useMutation(DELETE_LABEL_MUTATION);
  const [updateLabel] = useMutation(UPDATE_LABEL_MUTATION);
  const [attachLabel] = useMutation(ATTACH_LABEL_MUTATION);
  const [detachLabel] = useMutation(DETACH_LABEL_MUTATION);

  const [editLabels, setEditLabels] = useState(false);

  const remainingLabels = labelDefinitions.filter(
    (labelDefinition) =>
      !attachedLabels.find((attachedLabel) => attachedLabel.labelDefinition.id === labelDefinition.id)
  );

  const handleAttachLabel = (labelDefinition) => {
    // don't optimistically update to avoid animation issues

    attachLabel({
      variables: {
        input: {
          labelDefinitionId: labelDefinition.id,
          ...attachTo,
        },
      },
    }).then(({ data }) => {
      const attachmentId = data.createLabelAttachment.id;

      setAttachedLabels([
        ...attachedLabels,
        {
          id: attachmentId,
          labelDefinition,
        },
      ]);
    });
  };

  const handleDelete = (labelId) => {
    setLabelDefinitions((allLabels) => {
      return allLabels.filter((label) => label.id !== labelId);
    });

    setAttachedLabels((allLabels) => {
      return allLabels.filter((attachedLabel) => attachedLabel.labelDefinition.id !== labelId);
    });

    deleteLabel({
      variables: {
        id: labelId,
      },
    });
  };

  const handleSave = (label) => {
    setLabelDefinitions(
      labelDefinitions.map((allLabel) => {
        if (allLabel.id === label.id) {
          return label;
        }

        return allLabel;
      })
    );

    setAttachedLabels(
      attachedLabels.map((attachedLabel) => {
        if (attachedLabel.labelDefinition.id === label.id) {
          return {
            ...attachedLabel,
            labelDefinition: label,
          };
        }

        return attachedLabel;
      })
    );

    updateLabel({
      variables: {
        id: label.id,
        input: {
          label: label.label,
          description: label.description,
          color: label.color,
        },
      },
    });
  };

  const handleCreate = (label) => {
    setLabelDefinitions((allLabels) => [...allLabels, label]);

    createLabel({
      variables: {
        input: {
          label: label.label,
          description: label.description,
          color: label.color,
          labelType,
        },
      },
    }).then(({ data }) => {
      setLabelDefinitions((allLabels) => {
        return allLabels.map((allLabel) => {
          if (allLabel.id === 'draft-row') {
            return {
              ...allLabel,
              id: data.createLabelDefinition.id,
            };
          }

          return allLabel;
        });
      });
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <TransitionGroup component={null}>
          {attachedLabels.map((label) => (
            <Collapse orientation="horizontal" key={label.id}>
              <Label
                {...label.labelDefinition}
                onRemove={() => {
                  setAttachedLabels(attachedLabels.filter((attachedLabel) => attachedLabel.id !== label.id));

                  detachLabel({
                    variables: {
                      id: label.id,
                    },
                  });
                }}
              />
            </Collapse>
          ))}
        </TransitionGroup>
        <LabelMenu
          availableLabels={remainingLabels}
          handleAttachLabel={handleAttachLabel}
          openEditLabelsModal={() => setEditLabels(true)}
        />
      </Box>
      <EditLabelsModal
        open={editLabels}
        handleClose={() => setEditLabels(false)}
        handleSave={handleSave}
        handleCreate={handleCreate}
        handleDelete={handleDelete}
        allLabels={labelDefinitions}
      />
    </>
  );
}

export const SlimTextField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        sx: {
          height: '30px',
          fontSize: '14px',
        },
      }}
      InputLabelProps={{
        sx: {
          height: '30px',
          fontSize: '14px',
        },
      }}
      sx={{
        '.MuiFormLabel-root[data-shrink=false]': { top: '-10px' },
        ...props.sx,
      }}
    />
  );
};
