export const DELETE_NOTE_MUTATION = gql`
  mutation DeleteNoteMutation($id: Int!) {
    deleteNote(id: $id) {
      id
    }
  }
`;

export const CREATE_NOTE_MUTATION = gql`
  mutation CreateNoteMutation($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      text
      external
      membership {
        user {
          id
          firstName
          lastName
        }
      }
      purchaseOrderId
      salesOrderId
      lotId
    }
  }
`;
