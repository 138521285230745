import { useMemo } from 'react';

import { Clear as ClearIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { debounce, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

import { useRefine } from 'src/providers/RefineProvider';
import { usePersistentSearch } from 'src/providers/SearchBoxProvider';
import { OverridableStringUnion } from '@mui/types';
import { FormControlPropsSizeOverrides } from '@mui/material/FormControl/FormControl';
export type SearchBoxProps = {
  size: OverridableStringUnion<'small' | 'medium', FormControlPropsSizeOverrides>;
  label: string;
};

export default function SearchBox({ size, label, ...otherProps }: SearchBoxProps) {
  const { query, handleOnChange } = usePersistentSearch();
  const { refine } = useRefine();

  const debounceSearch = useMemo(() => {
    return debounce((value) => {
      refine(value);
    }, 100);
  }, [refine]);

  // TODO figure out how to let the custom label not overlap border
  return (
    <FormControl sx={{ flexGrow: 1 }} size={size} variant="outlined">
      <InputLabel htmlFor="search-box">{'Search'}</InputLabel>
      <OutlinedInput
        id="search-box"
        {...otherProps}
        value={query}
        onChange={(e) => {
          handleOnChange(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            {query && (
              <IconButton
                onClick={() => {
                  handleOnChange('');
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
            <IconButton
              onClick={() => {
                debounceSearch(query);
              }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        label="Search"
        onKeyDown={(e) => {
          const target = e.target as HTMLInputElement;

          if (e.key === 'Enter') {
            debounceSearch(target.value);
            e.preventDefault();
          }
        }}
      />
    </FormControl>
  );
}
