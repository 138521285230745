import { ReactNode } from 'react';

import { Box, Card, CSSObject, useTheme } from '@mui/material';

export type MainCardProps = {
  above?: ReactNode;
  children: ReactNode;
  noPadding?: boolean;
  sx?: CSSObject;
};

/**
 * Main Card is the central container used throughout the app
 * to display the main content of a page.
 */
export function MainCard({ children, noPadding = false, above, sx }: MainCardProps) {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'grid', width: '100%' }}>
      {above}
      <Card
        sx={{
          width: '100%',
          boxShadow: '1px #E0E0E0',
          display: 'flex',
          flexDirection: 'column',
          p: noPadding ? 0 : 2,
          ...sx,
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        {children}
      </Card>
    </Box>
  );
}
