import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';

import { BOLHeader, Expenses, Footer, Notes, ProductsGrid } from '../sections';
import { colors, docStyles, primaryDarkTextColor, primaryLightTextColor, sideMarginPadding } from '../utils/styles';

const border = `1 solid ${colors.primaryDarkTextColor}`;

const EmptyLine = () => {
  return (
    <View
      style={{
        flex: 1,
        fontSize: 8,
        flexGrow: 1,
        borderBottomWidth: 1,
        borderBottomColor: primaryLightTextColor,
      }}
    />
  );
};

const EmptyBox = () => {
  return (
    <View
      style={{
        border: border,
        width: 30,
        height: 20,
      }}
    ></View>
  );
};

const termsText = {
  paddingVertical: 5,
  fontSize: 8,
};

const BOLRightColumn = ({ temperatureMin, temperatureMax }) => {
  return (
    <View
      style={{
        flexDirection: 'column',
        paddingRight: sideMarginPadding,
        paddingLeft: 20,
        gap: 10,
        fontSize: 8,
        width: '30%',
      }}
    >
      <View>
        <View
          style={{
            border: border,
            paddingVertical: 3,
            paddingHorizontal: 4,
            flexDirection: 'row',
            alignContent: 'center',
          }}
        >
          <Text>Temp Range:</Text>
          <Text
            style={{
              paddingHorizontal: 8,
              fontSize: 8,
              flexGrow: 1,
              textAlign: 'center',
              fontWeight: 'semibold',
            }}
          >
            {temperatureMin}°F - {temperatureMax}°F
          </Text>
        </View>
        <View
          style={{
            border: border,
            borderTop: 0,
            paddingVertical: 3,
            paddingHorizontal: 4,
          }}
        >
          <Text>Phyto No.</Text>
        </View>
      </View>

      <View
        style={{
          border: border,
          paddingTop: 3,
          paddingBottom: 14,
          paddingHorizontal: 4,
        }}
      >
        <Text>Temp Recorder No.:</Text>
      </View>

      <View
        style={{
          border: border,
          paddingTop: 3,
          paddingBottom: 14,
          paddingHorizontal: 4,
        }}
      >
        <Text>Temp Recorder No.:</Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Text>Total Packages:</Text>
        <View style={{ flexGrow: 1 }}></View>
        <EmptyBox></EmptyBox>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Text>Pallets:</Text>
        <View style={{ flexGrow: 1 }}></View>
        <EmptyBox></EmptyBox>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <View>
          <Text>Chep Blue Pallets:</Text>
          <Text style={{ fontSize: 6 }}>(Number Shipped)</Text>
        </View>
        <View style={{ flexGrow: 1 }}></View>
        <EmptyBox></EmptyBox>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Text>Heat Treated Pallets:</Text>
        <View style={{ flexGrow: 1 }}></View>
        <EmptyBox></EmptyBox>
      </View>

      <View
        style={{
          justifyContent: 'space-between',
          fontSize: 10,
          flexDirection: 'column',
          gap: 6,
          width: '100%',
        }}
      >
        <View style={{ flexDirection: 'row', gap: 2, width: '100%' }}>
          <Text style={{ fontSize: 8 }}>Date In: </Text>
          <EmptyLine />
          <View
            style={{
              width: '35px',
            }}
          />
        </View>
        <View style={{ flexDirection: 'row', gap: 2, width: '100%' }}>
          <Text style={{ fontSize: 8 }}>Time In:</Text>
          <EmptyLine />
          <Text
            style={{
              width: '35px',
              fontSize: 8,
            }}
          >
            AM{'   '}PM
          </Text>
        </View>
        <View style={{ flexDirection: 'row', gap: 2, width: '100%' }}>
          <Text style={{ fontSize: 8 }}>Date Out:</Text>
          <EmptyLine />
          <View
            style={{
              width: '35px',
            }}
          />
        </View>
        <View style={{ flexDirection: 'row', gap: 2, width: '100%' }}>
          <Text style={{ fontSize: 8 }}>Time Out:</Text>
          <EmptyLine />
          <Text
            style={{
              width: '35px',
              fontSize: 8,
            }}
          >
            AM{'   '}PM
          </Text>
        </View>
      </View>
    </View>
  );
};

// TODO: Add type for input
export default function GSCBillOfLadingDocument(salesOrder) {
  const expenses = salesOrder.expenses
    .filter((e) => e.accountLevel === 'ACCOUNTS_RECEIVABLE' && e.businessEntityId === salesOrder.businessEntity?.id)
    .filter((e) => !e.includedInDlvPrice);

  const { showUnitsOrdered = true, showTerms = true, showBillTo = true } = salesOrder;

  const notes = salesOrder.notes?.filter((n) => n.documentTypes.includes('BILL_OF_LADING'));

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <BOLHeader order={salesOrder} showTerms={showTerms} showBillTo={showBillTo} />

        <View
          style={{
            paddingLeft: sideMarginPadding,
            paddingVertical: 15,
          }}
        >
          <View
            style={{
              justifyContent: 'space-between',
              width: '40%',
              fontSize: 8,
              flexDirection: 'column',
              gap: 6,
            }}
          >
            <View style={{ flexDirection: 'row', gap: 2, width: '100%' }}>
              <Text>Carrier</Text>
              <EmptyLine />
            </View>
            <View style={{ flexDirection: 'row', gap: 2, width: '100%' }}>
              <Text>Trailer License</Text>
              <EmptyLine />
            </View>
            <View style={{ flexDirection: 'row', gap: 2, width: '100%' }}>
              <Text>State</Text>
              <EmptyLine />
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '77%',
            }}
          >
            <ProductsGrid
              viewStyle={{
                flexDirection: 'column',
                paddingLeft: sideMarginPadding,
                paddingVertical: 10,
              }}
              productLineItems={salesOrder.lineItems}
              showUnitsOrdered={showUnitsOrdered}
              showUnitsPicked={true}
              productTemplate={salesOrder.organization.productDescriptionTemplate}
            />
            <Expenses
              expenses={expenses}
              viewStyle={{
                flexDirection: 'column',
                paddingLeft: sideMarginPadding,
                paddingVertical: 10,
              }}
            />
          </View>
          <BOLRightColumn
            temperatureMin={salesOrder.shipment?.temperatureMin}
            temperatureMax={salesOrder.shipment?.temperatureMax}
          />
        </View>

        <View
          style={{
            width: '100%',
            fontSize: 12,
            fontWeight: 'semibold',
            color: primaryDarkTextColor,
            textAlign: 'center',
          }}
        >
          <Text>CORNER & STRAP</Text>
        </View>

        {notes?.length > 0 && (
          <View>
            <Notes notes={notes} />
          </View>
        )}

        <View style={docStyles.signatureLinesContainer} wrap={false}>
          <View style={docStyles.signatureHeader}>
            <Text>SIGNED BY</Text>
          </View>

          <View style={docStyles.signatureDetailsContainer}>
            <View style={docStyles.signatureDetails}>
              <Text>Shipper's Signature</Text>
            </View>
            <View style={docStyles.signatureDetails}>
              <Text>Driver's Signature</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={docStyles.signatureDetails}>
              <Text>Shipper's Print Signature</Text>
            </View>
            <View style={docStyles.signatureDetails}>
              <Text>Cosignee's Signature</Text>
            </View>
          </View>
        </View>

        <Footer />
      </Page>
      <Page size="LETTER" style={docStyles.page}>
        <View
          style={{
            marginTop: '10%',
            paddingHorizontal: 30,
          }}
        >
          <Text style={docStyles.termsTitle}>CONTRACT TERMS AND CONDITIONS</Text>

          <Text style={termsText}>
            1. It is mutually agreed, as to each carrier of all or any of said property over all or any portion of said
            route to destination, and as to each party at any time interested in all or any of said property, that every
            service to be performed hereunder shall be subject to all the terms and conditions of the Uniform Domestic
            Straight Bill of Lading set forth (1) in Officials, Southern, Western, and Illinois Freight Classifications
            in effect on the date hereof, if this is a rail or rail water shipment, or (2) in the applicable motor
            carrier classification or tariff if this is a motor carrier shipment.
          </Text>
          <Text style={termsText}>
            2. Where used in the Bill of Lading, the term Carrier is understood to mean the person, firm, or corporation
            operating the motor vehicle and in possession of the property under this contract, and the term hereunder by
            the Carrier shall bind jointly and severally the said person, firm, or corporation owning or operating said
            motor vehicle. The Carrier here assumes full responsibility for any and all loss, damage, or delay to said
            property while in its possession and until delivery to consignee of said destination except when caused by
            an act of God, act of public enemy, or by an act of omission of the shipper or consignee.
          </Text>
          <Text style={termsText}>
            3. The Carrier agrees to transport the property under the protective service at the temperature specified
            between the origin and destination shown herein and deliver same to the consignee is good condition at the
            delivery time specified, if any, in the event the Carrier fails to so transport and deliver the property,
            then Carrier agrees to pay the owner of the property the invoice value of product on origin resulting from
            such failure.
          </Text>
          <Text style={termsText}>
            4. It is hereby stipulated and agreed that the delivery time shown herein is based on the Carrier's usual
            and normal schedule for perishable shipments transported with reasonable dispatch between the points shown
            herein. The Carrier further represents that said delivery can be performed without violating any local,
            state, or federal traffic or safety laws and regulations, and it has complied and will comply with all laws
            and regulations of local, state, and federal authorities which could affect this transportation agreement.
          </Text>
          <Text style={termsText}>
            5. Carrier promised to respect Receiver's right of inspection of the produce before unloading and or payment
            of charges. Delay of delivery may result in a claim for market loss if Carrier is negligent. Delay of
            delivery shall be prima facie evidence of Carrier's negligence. No responsibility attaches to the Shipper or
            Carrier performance by reason of his having arranged for the haul.
          </Text>
          <Text style={termsText}>
            6. The burden of proving "condition" damage shall lie with the Receiver. The Receiver's receipt given to the
            driver at the time of unloading must contain notation specifying the nature of claimed damage chargeable to
            the Carrier. Fully supported claim for such damage shall be presented to the Carrier or Truck Broker, if
            any, within 30 days following the date of delivery receipt. "CONDITION" DAMAGE relates to the condition of
            product which may have substantially changed in transit by reason of Carrier's failure to properly provide
            essential protection, or as a result of in-transit delay. Claim must be supported with proof of the alleged
            damage and equally substantiate proof of the real loss.
          </Text>
          <Text style={termsText}>
            7. The Carrier hereby warrants and represents to said shipper and consignee or other of said shipment that
            the motor vehicle described herein is covered by a valid effective insurance policy, in at least the amounts
            prescribed by the federal government. It is further represented that this shipment is covered by a presently
            effective cargo insurance policy in at least the amount of $25,000 and that additional coverage will be
            obtained to cover the actual value of said shipment if the shipment status value on the face hereof.
          </Text>
          <Text style={termsText}>
            8. The Carrier agrees to assign all its rights and remedies by reason of insurance covered to the Truck
            Broker, together with and including all money which may be payable to become payable thereunder. The Carrier
            further agrees to indemnify the Truck Broker to any and all just and reasonable payments made by the Truck
            Broker to the shipper or owner or the property on behalf of the Carrier, together with any expenses incurred
            including reasonable attorney fees. It is further agreed the Truck Broker is not liable or responsible to
            anyone other than the shipper and consignee for the operation, maintenance, or control of the Carrier's
            equipment, any violation of traffic regulations, load weight limitations or rules, regulations, or laws
            which shall pertain to or affect the transportation of the property contemplated herein.
          </Text>
          <Text style={termsText}>
            9. All parties hereto acknowledge that the Truck Broker, for compensation received from the Carrier, has
            acted solely as a broker in this transaction, bringing together the shipper and the Carrier, and is not the
            employer, employee, principal, or agent of the Carrier. It is further acknowledged that the shipper or
            consignee has relied on the Truck Broker in securing adequate and satisfactory transportation services, and
            that the Truck Broker agrees to indemnify and hold harmless the shipper or consignee or the owners of the
            property transported from any loss due to the carrier's negligence, act of omission, or any failure to fully
            perform and comply with the terms of this agreement.
          </Text>
          <Text style={termsText}>
            10. Any alteration, addition, or erasure in this Bill of Lading which shall be made without the special
            notation hereon of the agent of the carrier issuing this bill of lading, shall be without effect, and this
            Bill of Lading shall be enforceable according to its original tenor.
          </Text>
          <View
            style={{
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            <Text style={termsText}>
              {`Uniform Domestic Straight Bill of Lading
            Adopted by carriers represented in the
            Official, Southern, Western, and Illinois Freight Associations
            March 15, 1922, as amended November 1, 1930 and June 15, 1941`}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
