const LoadDocumentTemplateState = gql`
  query LoadDocumentState($name: String!, $entityType: DocumentTemplateEntityType!) {
    documentTemplate(name: $name, entityType: $entityType) {
      id
      templateState
    }
  }
`;

const SaveDocumentTemplate = gql`
  mutation SaveDocumentState($name: String!, $input: SaveDocumentTemplateInput!) {
    saveDocumentTemplate(name: $name, input: $input) {
      id
    }
  }
`;

const GetDocumentTemplatesForEntityType = gql`
  query GetDocumentTemplatesForEntityType($entityType: DocumentTemplateEntityType) {
    documentTemplates(entityType: $entityType) {
      id
      template
      name
      generationOptions
      type
    }
  }
`;

const GetDocumentTypesForEntityType = gql`
  query GetDocumentTypesForEntityType($entityType: DocumentTemplateEntityType) {
    documentTemplates(entityType: $entityType) {
      id
      name
      generationOptions
      type
    }
  }
`;

const GetDocumentTemplatesState = gql`
  query GetDocumentTemplatesState {
    documentTemplates {
      id
      name
      templateState
      generationOptions
      entityType
      type
    }
  }
`;

export default {
  LoadDocumentTemplateState,
  SaveDocumentTemplate,
  GetDocumentTemplatesState,
  GetDocumentTemplatesForEntityType,
  GetDocumentTypesForEntityType,
};
