const LookupPalletTag = gql`
  query LookupPalletTag($tagNumber: String!, $warehouseId: Int!) {
    palletTagLookup(tagNumber: $tagNumber, warehouseId: $warehouseId) {
      id
      tagNumber
      cartonQuantity
      weight
      lot {
        id
        slug
        product {
          id
          description
          commodityId
          organic
          countryOfOrigin
        }
      }
    }
  }
`;

const AddPalletTagToLineItem = gql`
  mutation AddPalletTagToLineItem($lineItemId: Int!, $input: AddPalletTagToLineItemInput!) {
    addPalletTagToLineItem(lineItemId: $lineItemId, input: $input) {
      palletTag {
        id
        tagNumber
        cartonQuantity
      }
      deletedAt
      quantity
      lineItem {
        unitsPicked
        lot {
          ...LotRepackChildren

          status
          id
          slug
          landedCost
          profit
          initialQuantity
          initialWeight
          warehouseId
          quantity

          lotOrderSources {
            workOrderId
          }

          product {
            description
            landedCost
            commodity {
              name
            }
          }
        }
      }
    }
  }
`;

const AddPalletTagToInventoryTransfer = gql`
  mutation AddPalletTagToInventoryTransfer($inventoryTransferId: Int!, $input: AddPalletTagToInventoryTransferInput!) {
    addPalletTagToInventoryTransfer(inventoryTransferId: $inventoryTransferId, input: $input) {
      palletTag {
        id
        tagNumber
        cartonQuantity
      }
      deletedAt
      quantity
      lotTransferItem {
        id
        unitsTransferred
        lot {
          ...LotAttributes
          transferredChildren {
            slug
            warehouseId
          }
          product {
            ...ProductAttributes
          }
        }
      }
    }
  }
`;

// TODO: Add response when we don't solve this by refetching
const AddPalletTagToProductionRun = gql`
  mutation AddPalletTagToProductionRun($productionRunId: Int!, $input: AddPalletTagToProductionRunInput!) {
    addPalletTagToProductionRun(productionRunId: $productionRunId, input: $input) {
      palletTagId
    }
  }
`;

export const CreateLineItemFromPalletTag = gql`
  mutation createLineItemFromPalletTag($input: CreateLineItemInput!) {
    createLineItem(input: $input) {
      ...LineItemAttributes
    }
  }
`;

export default {
  LookupPalletTag,
  AddPalletTagToLineItem,
  AddPalletTagToInventoryTransfer,
  AddPalletTagToProductionRun,
  CreateLineItemFromPalletTag,
};
