import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';

import { mapSalesTermsToLabel } from 'src/lib/utils/salesTerms';
import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';

import {
  AddressBlock,
  ContactBlock,
  DateBlock,
  DocumentDetailBlock,
  Expenses,
  Footer,
  Notes,
  OrgDetailsBlock,
  ProductsGrid,
  TermsAndTotals,
  formatDate,
} from '../sections';
import { SignatureLinesGroup } from '../sections/SignatureLinesGroup';
import { docStyles, sideMarginPadding } from '../utils/styles';

import { CustomShippingFields } from './SalesOrderTemplate';

function BOLOrderHeader({ order, showDelivery = true, showDateBlocks = true, showTerms = true, showBillTo = true }) {
  const orderSlug = order.slug;
  const submittedAt = order.submittedAt;
  // <po attribute> || <bso attribute>
  const customerPo = order.vendorPo || order.customerPo;
  const customer = order.businessEntity || order.customer;
  const customerContact = order.businessEntityContact || order.customerContact;
  const shipDate = order.shipment?.shipDate || order.shipDate;
  const deliveryDate = order.shipment?.deliveryDate || order.deliveryDate;
  const shipDestination = order.shipment?.place || order.shipment?.destination;

  const vendorSOs = order.fulfilledByPurchaseOrders
    ?.map((po) => po.vendorPo)
    .filter(Boolean)
    .join(', ');

  let documentSlugPrefix = '';

  if (order.__typename === 'PurchaseOrder') {
    documentSlugPrefix = 'PO';
  } else if (order.__typename === 'InventoryTransfer') {
    documentSlugPrefix = 'IT';
  } else {
    documentSlugPrefix = order.organization.salesOrderDocumentPrefix ?? 'SO';
  }

  return (
    <View style={docStyles.documentDetailsContainer}>
      <DocumentDetailBlock headerText={`${documentSlugPrefix} #${orderSlug}`}>
        <DateBlock dateHeader="Order Date" date={submittedAt} />

        {customerPo && (
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Text>Cust. PO.: </Text>
            <Text
              style={{
                fontWeight: 'semibold',
              }}
            >
              {customerPo}
            </Text>
          </View>
        )}
        {vendorSOs && (
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Text>Vend. SOs: </Text>
            <Text
              style={{
                fontWeight: 'semibold',
              }}
            >
              {vendorSOs}
            </Text>
          </View>
        )}
      </DocumentDetailBlock>

      {showDelivery && (
        <DocumentDetailBlock headerText="Delivery">
          {showDateBlocks && (
            <>
              <DateBlock dateHeader="Ship Date" date={shipDate} />
              <DateBlock dateHeader="Delivery Date" date={deliveryDate} />
            </>
          )}

          <AddressBlock address={shipDestination} displayCountry={false} bold />

          {/* TODO: Add Carrier Information */}
        </DocumentDetailBlock>
      )}

      {showBillTo && (
        <DocumentDetailBlock headerText="Bill To">
          <ContactBlock
            orgName={customer?.name}
            contactName={customerContact?.name}
            contactEmail={customerContact?.email}
            address={order.billTo}
            displayCountry={false}
          />
          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>
      )}

      {showTerms && (
        <DocumentDetailBlock headerText="Terms">
          <Text>{mapSalesTermsToLabel(order.salesTerms ?? order.customerSalesTerms)}</Text>
          <Text>{order.shipment.terms}</Text>
        </DocumentDetailBlock>
      )}

      {/* Add other SO related information here */}
    </View>
  );
}

export function BOLHeader({
  order,
  showDateBlocks = true,
  showEmail = true,
  showTerms = true,
  showBillTo = true,
  overrideContactEmail,
}) {
  const member = order.broker || order.salesPerson || order.buyer;
  const address = getPrimaryPlace(
    order.organization.places,
    order.subsidiary ?? member?.subsidiary ?? order.shipment?.origin
  );

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName="BILL OF LADING"
        orgLogoUrl={order.organization.logoUrl}
        orgName={order.subsidiary?.description ?? order.organization.label}
        address={address}
        user={member?.user}
        showEmail={showEmail}
        overrideContactEmail={overrideContactEmail}
      />

      <BOLOrderHeader order={order} showDateBlocks={showDateBlocks} showTerms={showTerms} showBillTo={showBillTo} />
    </View>
  );
}

// TODO: Add type for input
export default function GSPBillOfLadingDocument({
  salesOrder,
  displayUnitPrice,
  displayWeight = false,
  expenseCategories,
  showUnitsOrdered = true,
  showTerms = true,
  showBillTo = true,
  customFields,
}) {
  const notes = salesOrder.notes?.filter((n) => n.documentTypes.includes('BILL_OF_LADING'));

  const shipment = salesOrder.shipment;

  const shipDate = formatDate(salesOrder.shipDate);

  const deliveryDate = formatDate(salesOrder.deliveryDate);

  const expenses = salesOrder.expenses?.filter((expense) =>
    (expenseCategories ?? []).includes(expense.expenseType?.name)
  );

  const hasExpenses = expenses.length > 0;
  const hasNotes = notes?.length > 0;

  const appointmentNumber = salesOrder.shipment?.customFields?.['appointmentNumber'];

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <BOLHeader
          order={salesOrder}
          showDateBlocks={false}
          showTerms={showTerms}
          showBillTo={showBillTo}
          overrideContactEmail="paperwork@groselpro.com"
        />

        <BOLShippingDetails
          shipDate={shipDate}
          appointmentNumber={appointmentNumber}
          deliveryDate={deliveryDate}
          shipment={shipment}
        />

        <ProductsGrid
          productLineItems={salesOrder.lineItems.filter(
            // Don't show line items with 0 units picked
            (lineItem) => lineItem.unitsPicked !== 0
          )}
          showLineItemWeight={false}
          showUnitsOrdered={showUnitsOrdered}
          showUnitPrice={displayUnitPrice}
          currency={salesOrder.currencyCode}
          orderType="SO"
          productTemplate={salesOrder.organization.productDescriptionTemplate}
        />

        {hasExpenses && <Expenses expenses={expenses} currency={salesOrder.currencyCode} />}

        <CustomShippingFields
          customFields={customFields?.filter(({ key }) => key !== 'appointmentNumber')}
          salesOrder={salesOrder}
          documentType="BILL_OF_LADING"
          carrierHeaderText="Freight Broker"
          width="33.33%"
        />

        {hasNotes && <Notes notes={notes} />}

        <View
          style={{
            marginTop: 'auto',
          }}
        >
          <TermsAndTotals
            terms={{
              title: 'Driver Instructions',
              text: [
                'Note carefully the delivery time and date, late arrival may result in a fine of $250 per day.',
                'Please call 956-584-1910 or your broker for issues with delivery date/time, product quantaties/condition, or delivery location',
                'The merchandise described above received in apparent good order and condition and consigned and destined as indicated above, which the said truck owners, or the driver as his agent agrees to carry and deliver to the consignee in good condition and good order said destination. In accepting this shipment above the signor, as owner, agent or driver for the owner assumes all responsibility for its safe delivery in good condition to destination and will reimburse shipper for all damage to merchandise. You are responsible for this manifest. Shortages will be deducted from your cartage at FOB rates.',
              ],
            }}
          />

          <SignatureLinesGroup signatures={['Driver', 'Receiver']} />
          <TermsAndTotals
            terms={{
              title: 'TERMS',
              text: [
                'The perishable agricultural commodities listed on this invoice are sold subject to the statutory trust authorized by section 5(c) of the Perishable Agricultural Commodities Act, 1930 (7 U.S.C. 499e(c)). The seller of these commodities retains a trust claim over these commodities, all inventories of food or other products derived from these commodities, and any receivables or proceeds from the sale of these commodities until full payment is received.',
              ],
            }}
          />
        </View>
        <Footer />
      </Page>
    </Document>
  );
}

export const BOLShippingDetails = ({ shipDate, deliveryDate, shipment, appointmentNumber }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        paddingTop: 20,
        paddingHorizontal: sideMarginPadding,
      }}
    >
      <DocumentDetailBlock headerText="Ship Date">
        <Text>{shipDate}</Text>
      </DocumentDetailBlock>
      <DocumentDetailBlock headerText="Delivery Date">
        <Text>{deliveryDate}</Text>
      </DocumentDetailBlock>
      <DocumentDetailBlock headerText="Appointment #">
        <Text>{appointmentNumber}</Text>
      </DocumentDetailBlock>
      <DocumentDetailBlock headerText="Temperature">
        <Text>
          {shipment.temperatureMin}°F - {shipment.temperatureMax}°F
        </Text>
      </DocumentDetailBlock>
    </View>
  );
};
