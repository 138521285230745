import { Prisma } from '@prisma/client';

import getProductWeight from '../utils/getProductWeight';

export const computeProductionRunTotals = (
  productionRun: Prisma.ProductionRunGetPayload<{
    include: {
      productionRunComponents: {
        include: {
          lot: {
            include: {
              product: {
                include: {
                  commodity: true;
                  commoditySize: true;
                  commodityStyle: true;
                  commodityUnit: true;
                  commodityLabel: true;
                };
              };
            };
          };
        };
      };
    };
  }>
) => {
  const inputs = productionRun.productionRunComponents.filter((component) => component.type === 'INPUT');

  const outputs = productionRun.productionRunComponents.filter((component) => component.type === 'OUTPUT');

  const totalInputWeight = inputs.reduce((total, input) => {
    const inputWeight = Math.abs(input.quantityChange) * getProductWeight(input.lot.product, input.lot);

    return total + inputWeight;
  }, 0);

  const totalOutputWeight = outputs.reduce((total, output) => {
    const outputWeight = output.quantityChange * getProductWeight(output.lot.product, output.lot);

    return total + outputWeight;
  }, 0);

  const totalWeightDumped = inputs.reduce((total, input) => {
    if (!input.weightDumped) {
      return total;
    }

    if (typeof input.weightDumped === 'number') {
      return total + input.weightDumped;
    }

    return total + input.weightDumped.toNumber();
  }, 0);

  const percentUtilized = totalInputWeight ? (totalOutputWeight / totalInputWeight) * 100 : 0;
  const percentDumped = totalOutputWeight ? (totalWeightDumped / totalInputWeight) * 100 : 0;

  return {
    totalInputWeight,
    totalOutputWeight,
    totalWeightDumped,
    percentUtilized,
    percentDumped,
  };
};
