import { useEffect } from 'react';

import { useAuth } from '@clerk/clerk-react';
import { clarity } from 'react-microsoft-clarity';

if (process.env.CLARITY_PROJECT_ID) {
  clarity.init(process.env.CLARITY_PROJECT_ID);
}

export function useIdentifyClarityUser() {
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && clarity.hasStarted()) {
      clarity.identify(currentUser.id, { email: currentUser.emailAddress });
    }
  }, [currentUser]);
}
