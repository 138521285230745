import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';

import computeFullAddress from 'src/lib/shared/computefullAddress';
import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';

import { DocumentDetailBlock, Expenses, Footer, Notes, OrgDetailsBlock, formatDate } from '../sections';
import Table, { TableColumnDefinition } from '../sections/Table';
import { docStyles } from '../utils/styles';

export function ShipmentCarrierHeader({ order }) {
  const { organization, broker, shipment, slug, __typename, customerPo, vendorSo, subsidiary } = order;

  const soPrefix = order.organization.salesOrderDocumentPrefix ?? 'SO';
  const orderPrefix = __typename === 'SalesOrder' ? soPrefix : 'BSO';
  const orderLabel = `${orderPrefix} #${slug}`;

  const hasVendorSO = vendorSo !== undefined;

  const address = getPrimaryPlace(organization.places, subsidiary ?? broker?.subsidiary);

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={'CARRIER CONFIRMATION'}
        orgLogoUrl={organization.logoUrl}
        orgName={organization.label}
        address={address}
        user={broker?.user}
      />
      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={orderLabel}>
          {hasVendorSO && <Text>Vendor SO: {vendorSo || '--'}</Text>}
          <Text>Customer PO: {customerPo || '--'}</Text>
        </DocumentDetailBlock>
        <DocumentDetailBlock headerText={'Carrier'}>
          <Text>{shipment.freightCompany?.name || '--'}</Text>
        </DocumentDetailBlock>
        <DocumentDetailBlock headerText={'Temperature Range'}>
          <Text>
            {shipment.temperatureMin}°F - {shipment.temperatureMax}°F
          </Text>
        </DocumentDetailBlock>
      </View>
    </View>
  );
}

// TODO: Add type for input
export default function ShipmentCarrierConfirmationTemplate({ order }) {
  const notes = order.notes.filter((n) => n.documentTypes.includes('CARRIER_CONFIRMATION'));

  const freightCompanyId = order.shipment.freightCompany?.id;

  const expenses = order.expenses
    .filter((e) => e.businessEntityId === freightCompanyId)
    .filter((e) => e.accountLevel === 'ACCOUNTS_PAYABLE');

  const metrics = {
    numPallets: order.numPallets,
    weight: order.weight,
  };

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <ShipmentCarrierHeader order={order} />

        <WaypointGrid
          address={order.shipment.origin}
          date={order.shipment.shipDate}
          metrics={metrics}
          label="PICK UP"
        />

        <WaypointGrid
          address={order.shipment.destination}
          date={order.shipment.deliveryDate}
          metrics={metrics}
          label="DROP OFF"
        />

        <View
          style={{
            gap: 4,
            justifyContent: 'flex-end',
            flexDirection: 'row',
            paddingRight: 30,
            paddingBottom: 20,
          }}
          wrap={false}
        >
          <View style={docStyles.subTotalColumn}>
            <Text style={{ ...docStyles.subTotalHeader, width: '100px' }}>PALLETS</Text>

            <Text
              style={{
                ...docStyles.gridTotal,
                width: '100px',
                textAlign: 'center',
              }}
            >
              {order.numPallets.ordered.toLocaleString('en-US')}
            </Text>
          </View>

          <View style={{ ...docStyles.subTotalColumn, width: '100px' }}>
            <Text style={docStyles.subTotalHeader}>WEIGHT</Text>

            <Text
              style={{
                ...docStyles.gridTotal,
                width: '100px',
                textAlign: 'center',
              }}
            >
              {order.weight.ordered.toLocaleString('en-US')} lbs.
            </Text>
          </View>
        </View>

        <Expenses expenses={expenses} currency={order.currencyCode} />

        <Notes notes={notes} />

        <Footer />
      </Page>
    </Document>
  );
}

export function WaypointGrid({ date, address, metrics, label }) {
  const componentGridColumns: TableColumnDefinition<{
    date: string;
    address: string;
    pallets: number;
    weight: string;
  }>[] = [
    {
      field: 'date',
      headerName: label,
      sx: { minWidth: 75 },
    },

    {
      field: 'address',
      headerName: 'Address',
      sx: { minWidth: 150, textAlign: 'right' },
    },

    {
      field: 'pallets',
      headerName: 'Pallets',
      sx: { minWidth: 50, textAlign: 'right' },
    },

    {
      field: 'weight',
      headerName: 'Weight',
      sx: { minWidth: 50, textAlign: 'right' },
    },

    /* TODO: Add lots. 1. Either pre-entered lots, 2. empty space where they can write lots */
  ];

  const placeName = address?.description ? `${address.description}\n` : '';

  return (
    <View style={docStyles.sectionContainer} wrap={false}>
      <Table
        columns={componentGridColumns}
        rows={[
          {
            id: 0,
            date: formatDate(date),
            address: `${placeName}${computeFullAddress(address)}`,
            pallets: metrics.numPallets.ordered,
            weight: metrics.weight.ordered ? `${metrics.weight.ordered.toFixed('0')} lbs` : '--',
          },
        ]}
      />
    </View>
  );
}
