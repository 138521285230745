import { useRef, useState } from 'react';

import { Editor } from '@monaco-editor/react';
import { Box, Button, Card, Divider, Tabs, TextField, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ReactToPrint from 'react-to-print';
import type { PalletTagsQuery } from 'types/graphql';

import { useMutation, type CellFailureProps, type CellSuccessProps } from '@redwoodjs/web';

import Autocomplete from 'src/components/atoms/Autocomplete';
import Tab from 'src/components/atoms/Tab';
import { PageBody } from 'src/components/containers/PageBody';
import { PalletTag, basicTemplate } from 'src/components/containers/palletTags/PalletTag';

export const QUERY = gql`
  query TagLayoutsQuery {
    tagLayouts {
      id
      name
      schema
    }
  }
`;

// create new tag layout
const CREATE_TAG_LAYOUT = gql`
  mutation CreateTagLayoutMutation($input: CreateTagLayoutInput!) {
    createTagLayout(input: $input) {
      id
    }
  }
`;

const UPDATE_TAG_LAYOUT = gql`
  mutation UpdateTagLayoutMutation($id: Int!, $input: UpdateTagLayoutInput!) {
    updateTagLayout(id: $id, input: $input) {
      id
    }
  }
`;

export const Loading = () => <div>Loading...</div>;

export const Failure = ({ error }: CellFailureProps) => <div style={{ color: 'red' }}>Error: {error?.message}</div>;

const templateData = {
  lot: '00002-001',
  receiveDate: '12/19/23',
  countryOfOrigin: 'CAN',
  tagNumber: '00145',
  quantity: 13,
  description: 'Apple Idaho',
  packStyle: 'cartons Medium',
  productCode: '32912',
  size: 'Medium',
  grade: 'B',
  label: 'test',
  unit: 'cartons',
  gtinCode: '10054384002041',
  rawDate: '2021-09-24T00:00:00.000-04:00',
  commodity: 'Apple',
  variety: 'Idaho',
  organic: true,
  grower: 'Grower',
  vendor: 'Vendor',
  growerOrVendor: 'Mixed',
  fieldTicketId: '123456',
  harvestLocation: 'HL20233',
  weight: 12.34,
  weightPerUnit: 1.94,

  repackStartDate: '12/19/23',
  repackCompletedDate: '12/19/23',
  date: '12/19/23',
  pickCode: {
    small: '00',
    large: '98',
  },
};

export const Success = ({ tagLayouts }: CellSuccessProps<PalletTagsQuery>) => {
  const [editingTag, setEditingTag] = useState(tagLayouts[0]);
  const [previewData, setPreviewData] = useState(templateData);
  const [currentTab, setSelectedTab] = useState(0);
  const [newLayoutname, setNewLayoutName] = useState('');
  const [layouts, setLayouts] = useState(tagLayouts);

  const [createTagLayout] = useMutation(CREATE_TAG_LAYOUT, {
    onCompleted: (newLayout) => {
      setLayouts([...layouts, newLayout]);
    },
  });

  const [updateTagLayout] = useMutation(UPDATE_TAG_LAYOUT, {
    onCompleted: (newLayout) => {
      setLayouts(layouts.map((layout) => (layout.id === newLayout.id ? newLayout : layout)));
    },
  });

  const editingJSON = currentTab === 0 ? editingTag?.schema : previewData;
  const ref = useRef();

  return (
    <PageBody sx={{ height: '100%' }}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <Box display="flex" p={2} gap={2}>
          <Autocomplete
            options={tagLayouts}
            width="400px"
            value={editingTag}
            onChange={(e, newValue) => {
              setEditingTag(newValue);
            }}
            getOptionLabel={(option) => option.name}
          />
          <Button
            variant="contained"
            onClick={() => {
              updateTagLayout({
                variables: {
                  id: editingTag.id,
                  input: {
                    name: editingTag.name,
                    schema: editingTag.schema,
                  },
                },
              });
            }}
          >
            Save
          </Button>

          <Box display="flex" gap={2} ml="auto" justifyItems="center">
            <TextField
              onChange={(e) => {
                setNewLayoutName(e.target.value);
              }}
            />
            <Button
              disabled={newLayoutname.length === 0}
              onClick={() => {
                createTagLayout({
                  variables: {
                    input: {
                      name: newLayoutname,
                      tagType: 'PALLET',
                      schema: {
                        name: newLayoutname,
                        ...basicTemplate,
                      },
                    },
                  },
                });
              }}
              variant="contained"
            >
              Create New
            </Button>
          </Box>
        </Box>
        <Box display="flex" height="100%">
          <Box display="flex" flexDirection="column" flexGrow={1} mr={1}>
            <Box display="flex">
              <Tabs value={currentTab}>
                <Tab
                  label="Layout"
                  onClick={() => {
                    setSelectedTab(0);
                  }}
                />
                <Tab
                  label="Data"
                  onClick={() => {
                    setSelectedTab(1);
                  }}
                />
              </Tabs>
              <Button
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(JSON.stringify(editingJSON));
                }}
                sx={{
                  ml: 'auto',
                }}
                size="small"
              >
                Copy
              </Button>
            </Box>
            <Editor
              theme="vs-dark"
              defaultLanguage="json"
              value={JSON.stringify(editingJSON, null, 2)}
              onChange={(value) => {
                if (currentTab === 0) {
                  setEditingTag({
                    ...editingTag,
                    schema: JSON.parse(value),
                  });
                } else {
                  setPreviewData(JSON.parse(value));
                }
              }}
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box mx={2} minWidth="500px">
            <Box display="flex" mb={2} width="100%">
              <Typography variant="h5">Preview</Typography>
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" sx={{ height: 30, my: 'auto', ml: 'auto' }}>
                    Print Preview
                  </Button>
                )}
                content={() => ref.current}
                pageStyle={() => {
                  const refTag = editingTag?.schema;
                  const width = refTag?.width;
                  const height = refTag?.height;

                  return `
                width: '${width}';
                height: '${height}';
                `;
                }}
              />
            </Box>
            {editingTag && (
              <ErrorBoundary
                fallbackRender={({ error, resetErrorBoundary }) => (
                  <>
                    <Button onClick={() => resetErrorBoundary()}>Retry</Button>
                    <Typography color="error">There was a problem while rendering: ${error.message}</Typography>
                  </>
                )}
              >
                <>
                  <Box display="flex" width="100%">
                    <Box mx="auto">
                      <PalletTag template={editingTag?.schema} data={previewData} />
                    </Box>
                  </Box>
                  <Box hidden>
                    <Box ref={ref}>
                      {new Array(10).fill(0).map((_, i) => {
                        return (
                          <PalletTag
                            key={i}
                            template={editingTag?.schema}
                            data={{
                              ...previewData,
                              tagNumber: i.toString().padStart(5, '0'),
                            }}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </>
              </ErrorBoundary>
            )}
          </Box>
        </Box>
      </Card>
    </PageBody>
  );
};
