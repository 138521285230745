import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page } from '@react-pdf/renderer';

import { USD } from 'src/lib/money/usd';
import { computeExpenseCost } from 'src/services/expenses/utils/computeExpenseCost';
import computeTotalCartons from 'src/services/lineItems/utils/computeTotalCartons';
import computeTotalPallets from 'src/services/lineItems/utils/computeTotalPallets';
import computeTotalWeight from 'src/services/lineItems/utils/computeTotalWeight';

import { Expenses, Footer, Notes, ProductsGrid, PurchaseOrderHeader, TermsAndTotals } from '../sections';
import { formatCurrency } from '../utils/formatters';
import { docStyles } from '../utils/styles';

type PurchaseOrderTemplateProps = Prisma.PurchaseOrderGetPayload<{
  include: {
    organization: { include: { places: true } };

    buyer: { include: { user: true; subsidiary: true } };
    businessEntity: true;
    businessEntityContact: true;
    subsidiary: true;
    lineItems: {
      select: {
        unitPrice: true;
        unitsReceived: true;
      };
      include: {
        lot: true;
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
            commodityLabel: true;
          };
        };
      };
    };
    expenses: true;
    payables: true;
    notes: {
      include: {
        membership: {
          include: { user: true };
        };
      };
    };
  };
}>;

const calculateTotalExpenseAmount = (expense) => {
  return computeExpenseCost(expense.currencyAmount ?? expense.unitAmount, expense.quantity);
};

export function PurchaseOrderTemplate(
  { overrideContactEmail, ...purchaseOrder }: PurchaseOrderTemplateProps

  // TODO: Add a BSO prop here, pass it down to other entities
) {
  const allLineItems = purchaseOrder.lineItems;

  const apExpenses = purchaseOrder.expenses
    ?.filter((expense) => expense.accountLevel === 'ACCOUNTS_PAYABLE')
    .filter((expense) => {
      return (
        (expense.businessEntityId === null || expense.businessEntityId === purchaseOrder.businessEntity?.id) &&
        !expense.parentExpenseId
      );
    });
  const externalNotes = purchaseOrder.notes.filter((n) => n.documentTypes.includes('PURCHASE_ORDER_CONFIRMATION'));

  const lineItemSubtotal = allLineItems.reduce(
    (total, li) => total.add(USD.fromCents(li.unitsOrdered * (li.buyCurrencyPrice ?? li.buyPrice))),
    USD.fromCents(0)
  );

  const apExpenseSubtotal = apExpenses.reduce((acc, expense) => {
    return acc.add(calculateTotalExpenseAmount(expense));
  }, USD.fromCents(0));

  // Formula = SUM(line items) + SUM(AR Expenses)
  const totalAmountDue = lineItemSubtotal.add(apExpenseSubtotal);
  const deliveredExpenses = purchaseOrder.expenses.filter((expense) => expense.includedInDlvPrice);

  const totals = {
    pallets: computeTotalPallets(allLineItems).ordered,
    cartons: computeTotalCartons(allLineItems).ordered,
    weight: computeTotalWeight(allLineItems).ordered,
  };

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <PurchaseOrderHeader order={purchaseOrder} overrideContactEmail={overrideContactEmail} />

        <ProductsGrid
          productLineItems={allLineItems}
          showUnitsOrdered={true}
          showBuyPrice={true}
          showSubTotal={true}
          deliveredExpenses={deliveredExpenses}
          totals={totals}
          currency={purchaseOrder.currencyCode}
          productTemplate={purchaseOrder.organization.productDescriptionTemplate}
        />

        <Expenses expenses={apExpenses} currency={purchaseOrder.currencyCode} />

        <TermsAndTotals grandTotal={formatCurrency(totalAmountDue.dollars)} />

        <Notes notes={externalNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
