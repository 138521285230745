import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page, Text, View } from '@react-pdf/renderer';

import { USD } from 'src/lib/money/usd';
import { computeExpenseCost } from 'src/services/expenses/utils/computeExpenseCost';
import computeTotalCartons from 'src/services/lineItems/utils/computeTotalCartons';
import computeTotalPallets from 'src/services/lineItems/utils/computeTotalPallets';
import computeTotalWeight from 'src/services/lineItems/utils/computeTotalWeight';

import {
  DocumentDetailBlock,
  Expenses,
  Footer,
  Notes,
  ProductsGrid,
  SalesOrderHeader,
  TermsAndTotals,
  getUnitPrice,
} from '../sections';
import { formatCurrency } from '../utils/formatters';
import { docStyles, sideMarginPadding } from '../utils/styles';

type SalesOrderTemplateProps = Prisma.SalesOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    salesPerson: { include: { user: true; subsidiary: true } };
    businessEntity: true;
    businessEntityContact: true;
    subsidiary: true;
    lineItems: {
      select: {
        unitPrice: true;
        unitsReceived: true;
      };
      include: {
        lot: true;
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
            commodityLabel: true;
          };
        };
      };
    };
    expenses: true;
    payables: true;
    notes: {
      include: {
        membership: {
          include: { user: true };
        };
      };
    };
  };
}>;

const calculateTotalExpenseAmount = (expense) => {
  return computeExpenseCost(expense.currencyAmount ?? expense.unitAmount, expense.quantity);
};

export const CustomShippingFields = ({
  customFields,
  salesOrder,
  documentType,
  carrierHeaderText = 'Carrier',
  width = '25%',
}) => {
  const fields = customFields?.filter((field) => field.documents?.includes(documentType));

  if (!fields?.length) return null;

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 10,
        paddingHorizontal: sideMarginPadding,
      }}
    >
      <View
        style={{
          width,
          paddingHorizontal: 5,
          height: '35px',
        }}
      >
        <DocumentDetailBlock headerText={carrierHeaderText}>
          <Text>{salesOrder.shipment?.freightCompany?.name ?? ''}</Text>
        </DocumentDetailBlock>
      </View>
      {fields.map((field) => (
        <View
          key={field.key}
          style={{
            width,
            paddingHorizontal: 5,
            height: '40px',
          }}
        >
          <DocumentDetailBlock headerText={field.name}>
            <Text>{salesOrder.shipment?.customFields?.[field.key] ?? ''}</Text>
          </DocumentDetailBlock>
        </View>
      ))}
    </View>
  );
};

export function SalesOrderTemplate({
  overrideContactEmail,
  customFieldDefinitions,
  ...salesOrder
}: SalesOrderTemplateProps) {
  const allLineItems = salesOrder.lineItems;
  const arExpenses = salesOrder.expenses
    ?.filter((expense) => expense.accountLevel === 'ACCOUNTS_RECEIVABLE')
    .filter(
      (expense) =>
        (expense.businessEntityId === null || expense.businessEntityId === salesOrder.businessEntity?.id) &&
        !expense.includedInDlvPrice &&
        !expense.parentExpenseId
    );

  const externalNotes = salesOrder.notes.filter((n) => n.documentTypes.includes('SALES_ORDER_CONFIRMATION'));

  const deliveredExpenses = salesOrder.expenses.filter((expense) => expense.includedInDlvPrice);

  const totals = {
    pallets: computeTotalPallets(allLineItems).ordered,
    cartons: computeTotalCartons(allLineItems).ordered,
    weight: computeTotalWeight(allLineItems).ordered,
  };

  const currency = salesOrder.currencyCode;

  const lineItemSubtotal = allLineItems.reduce(
    (total, li) => total.add(USD.fromCents(getUnitPrice(li, totals, deliveredExpenses)).multiply(li.unitsOrdered)),
    USD.fromCents(0)
  );

  const arExpenseSubtotal = arExpenses.reduce((acc, expense) => {
    return acc.add(calculateTotalExpenseAmount(expense));
  }, USD.fromCents(0));

  // Formula = SUM(line items) + SUM(AR Expenses)
  const totalAmountDue = lineItemSubtotal.add(arExpenseSubtotal);

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <SalesOrderHeader order={salesOrder} overrideContactEmail={overrideContactEmail} />

        <ProductsGrid
          productLineItems={allLineItems}
          showUnitsOrdered={true}
          showSubTotal={true}
          showUnitPrice={true}
          deliveredExpenses={deliveredExpenses}
          totals={totals}
          currency={currency}
          productTemplate={salesOrder.organization.productDescriptionTemplate}
        />

        <Expenses expenses={arExpenses} currency={currency} />

        <CustomShippingFields
          customFields={customFieldDefinitions}
          salesOrder={salesOrder}
          documentType="SALES_ORDER_CONFIRMATION"
        />

        <TermsAndTotals grandTotal={formatCurrency(totalAmountDue.dollars, currency)} />

        <Notes notes={externalNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
