import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Download, Print, Tune } from '@mui/icons-material';
import { Box, Checkbox, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Document, usePDF } from '@react-pdf/renderer';
import BillOfLadingDocument from 'api/src/lib/documents/templates/BillOfLadingDocument';
import { BSOBillOfLadingTemplate } from 'api/src/lib/documents/templates/BSOBillOfLadingTemplate';
import { BSOInvoiceTemplate } from 'api/src/lib/documents/templates/BSOInvoiceTemplate';
import { BSOPurchaseOrderTemplate } from 'api/src/lib/documents/templates/BSOPurchaseOrderTemplate';
import { BSOSalesOrderTemplate } from 'api/src/lib/documents/templates/BSOSalesOrderTemplate';
import CarrierConfirmationTemplate from 'api/src/lib/documents/templates/CarrierConfirmationTemplate';
import { GrowerSettlementTemplate } from 'api/src/lib/documents/templates/GrowerSettlementTemplate';
import GSCBillOfLadingDocument from 'api/src/lib/documents/templates/GSCBillOfLadingDocument';
import GSPBillOfLadingDocument from 'api/src/lib/documents/templates/GSPBillOfLadingDocument';
import IntegroBillOfLadingDocument from 'api/src/lib/documents/templates/IntegroBillOfLadingDocument';
import IntegroSOPOSourcedBOLDocument from 'api/src/lib/documents/templates/IntegroSOPOSourcedBOLDocument';
import MPBillOfLadingDocument from 'api/src/lib/documents/templates/MPBillOfLadingDocument';
import MultiBillOfLadingDocument from 'api/src/lib/documents/templates/MultiBillOfLadingDocument';
import { PassingTemplate } from 'api/src/lib/documents/templates/PassingTemplate';
import { PickTicketTemplate } from 'api/src/lib/documents/templates/PickTicketTemplate';
import { ProductionRunReportTemplate } from 'api/src/lib/documents/templates/ProductionRunReportTemplate';
import { PurchaseOrderTemplate } from 'api/src/lib/documents/templates/PurchaseOrderTemplate';
import { ReceivingTicketTemplate } from 'api/src/lib/documents/templates/ReceivingTicketTemplate';
import { SalesOrderTemplate } from 'api/src/lib/documents/templates/SalesOrderTemplate';
import ShipmentCarrierConfirmationTemplate from 'api/src/lib/documents/templates/ShipmentCarrierConfirmationTemplate';
import SOPOSourcedBOLDocument from 'api/src/lib/documents/templates/SOPOSourcedBOLDocument';
import { StandardInvoiceTemplate } from 'api/src/lib/documents/templates/StandardInvoiceTemplate';
import { WorkOrderReceivingTicketTemplate } from 'api/src/lib/documents/templates/WorkOrderReceivingTicketTemplate';
import { WorkOrderTemplate } from 'api/src/lib/documents/templates/WorkOrderTemplate';
import { saveAs } from 'file-saver';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LineItem, Product } from 'shared/core';
import { DocumentType } from 'types/graphql';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { useMutation, useQuery } from '@redwoodjs/web';

import { CREATE_DOCUMENT } from 'src/api/documents.api';
import { useAuth } from 'src/auth';
import { CanadaConfirmationOfSale } from 'src/components/templates/CanadaDocumentOfSale';
import OrderCarrierConfirmation from 'src/components/templates/OrderCarrierConfirmation';
import { FeatureFlags } from 'src/lib/constants';
import { uploadToFilestack } from 'src/lib/filestack';
import DocumentTemplateApi from 'src/modules/document-editor/api/DocumentTemplate.api';
import { renderPages } from 'src/modules/document-preview/components/DocumentPreview';
import DocumentViewer from 'src/modules/document-preview/components/DocumentViewer';
import { ZoomControls } from 'src/modules/document-preview/components/ZoomControls';
import useDocumentPreview from 'src/modules/document-preview/hooks/useDocumentPreview';
import { printUrl } from 'src/modules/document-preview/utils';
import convertToTitleCase from 'src/utils/convertToTitleCase';

import { GLOBAL_APP_BAR_HEIGHT } from '../AppBar';
import { QUERY_EXPENSE_TYPES } from '../grids/ExpensesGrid';
import { DropdownMenuButton } from '../menus/DropdownMenuButton';

type GeneratedDocumentType = Extract<
  DocumentType,
  | 'PURCHASE_ORDER_CONFIRMATION'
  | 'RECEIVING_TICKET'
  | 'SALES_ORDER_CONFIRMATION'
  | 'PICK_TICKET'
  | 'BILL_OF_LADING'
  | 'GROWER_SETTLEMENT'
  | 'INVOICE'
>;

interface DocumentGenerationOptions {
  filename?: string;
  orderType?: 'PO' | 'SO' | 'BSO' | 'GP' | 'LOAD' | 'PRODUCTION_RUN' | 'IT' | 'STANDALONE';
  orderId?: number;
  orderSlug?: string;
  // TODO: Migrate all parent components to pass generic orderId and orderSlug and specify orderType instead of order-specific id/slug parameters
  salesOrderId?: number; // use orderId instead
  salesOrderSlug?: string; // use orderSlug instead
  purchaseOrderId?: number; // use orderId instead
  purchaseOrderSlug?: string; // use orderSlug instead
  onSuccess?: (newDocument: Document) => void;
  onError?: (error: unknown) => void;
}
export interface DocumentGeneratorProps {
  document?: ((contextProps: unknown) => JSX.Element) | JSX.Element;
  generatable: boolean;
  generateOptions?: DocumentGenerationOptions;
  documentType?: GeneratedDocumentType;
  documentTypes?: string[];
  documentEntityType?: string;
  getTemplateData?: () => Promise<unknown>;
  contextProps?: any;
}

type UserDocumentOptions = {
  bol: {
    displayUnitPrice: boolean;
    expenseCategories: string[];
  };
};

const useDocumentOptions = create(
  persist<{
    options: UserDocumentOptions;
    updateDocumentOptions: (options: UserDocumentOptions) => void;
  }>(
    (set, get) => ({
      options: {
        bol: {
          displayUnitPrice: false,
          displayWeight: true,
          expenseCategories: [],
        },
      },
      updateDocumentOptions: (options: UserDocumentOptions) => {
        set({
          options: {
            ...get().options,
            ...options,
          },
        });
      },
    }),
    {
      name: 'document-gen-options',
    }
  )
);

const BOLDocumentOptions = ({ docType, orderType, isGoldenStarCitrusOrg, isGrowerSelectProduceOrg }) => {
  const bolOptions = useDocumentOptions((state) => state.options.bol);

  const updateDocumentOptions = useDocumentOptions((state) => state.updateDocumentOptions);

  const { data: expenseTypesResponse } = useQuery(QUERY_EXPENSE_TYPES);

  const expenseTypes = useMemo(() => {
    return expenseTypesResponse?.expenseTypes.map((et) => et.name) ?? [];
  }, [expenseTypesResponse]);

  if (docType !== 'BILL_OF_LADING' || (orderType !== 'BSO' && orderType !== 'SO' && orderType !== 'IT')) {
    return null;
  }

  const addExpenseCategory = (category) => {
    updateDocumentOptions({
      bol: {
        ...bolOptions,
        expenseCategories: [...bolOptions.expenseCategories, category],
      },
    });
  };

  const removeExpenseCategory = (category) => {
    updateDocumentOptions({
      bol: {
        ...bolOptions,
        expenseCategories: bolOptions.expenseCategories.filter((c) => c !== category),
      },
    });
  };

  const displayUnitPriceOption = !isGoldenStarCitrusOrg && orderType !== 'IT';
  const displayWeightOption = isGrowerSelectProduceOrg && docType === 'BILL_OF_LADING';

  return (
    <Box py={1}>
      {/* golden star does not display the unit price */}
      {displayUnitPriceOption && (
        <Box display="flex" alignItems="center" gap={1}>
          <Checkbox
            checked={bolOptions.displayUnitPrice}
            onChange={(e) =>
              updateDocumentOptions({
                bol: {
                  ...bolOptions,
                  displayUnitPrice: e.target.checked,
                },
              })
            }
          />
          <Typography>Display Unit Price</Typography>
        </Box>
      )}
      {displayWeightOption && (
        <Box display="flex" alignItems="center" gap={1}>
          <Checkbox
            checked={bolOptions.displayWeight}
            onChange={(e) =>
              updateDocumentOptions({
                bol: {
                  ...bolOptions,
                  displayWeight: e.target.checked,
                },
              })
            }
          />
          <Typography>Display Weight</Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" gap={1}>
        <DropdownMenuButton ButtonComponent={IconButton} Icon={<Tune />}>
          {expenseTypes.map((option) => {
            const selected = bolOptions.expenseCategories.includes(option);

            return (
              <MenuItem
                key={option}
                value={option}
                selected={selected}
                onClick={() => {
                  if (selected) {
                    removeExpenseCategory(option);
                  } else {
                    addExpenseCategory(option);
                  }
                }}
              >
                {option}
              </MenuItem>
            );
          })}
        </DropdownMenuButton>
        <Typography>Expense Categories</Typography>
      </Box>
    </Box>
  );
};

// documents loaded from public folder and filled with data, as opposed to our custom PDF templates
const sourceDocuments = {
  CANADA_CONFIRMATION_OF_SALE: CanadaConfirmationOfSale,
};

export default function DocumentGenerator({
  document,
  documentType,
  documentTypes,
  generatable,
  generateOptions,
  documentEntityType,
  getTemplateData,
  extraDocuments,
  contextProps,
}: DocumentGeneratorProps) {
  const { orderType } = generateOptions ?? {};

  const [docType, setDocType] = useState(documentType ?? documentTypes?.[0]);

  /**
    BEGIN NEW DOCUMENT GENERATION LOGIC
  */

  const documentTemplates = useQuery(DocumentTemplateApi.GetDocumentTemplatesForEntityType, {
    variables: {
      entityType: documentEntityType,
    },
  });

  const [documentTemplateData, setDocumentTemplateData] = useState({ loading: false, data: null });

  const flags = useFlags();

  useEffect(() => {
    const { data } = documentTemplates;
    const templates = data?.documentTemplates;

    if (!templates?.length || !getTemplateData || documentTemplateData?.data || documentTemplateData?.loading) {
      return;
    }

    setDocumentTemplateData({ loading: true, data: null });

    getTemplateData()
      .then((templateData) => {
        setDocumentTemplateData({ loading: false, data: templateData });
      })
      .catch(() => {
        setDocumentTemplateData({ loading: false, data: null });
      });
  }, [documentTemplates, documentEntityType, documentTypes, getTemplateData, documentTemplateData]);

  const [selectedTemplate, setSelectedTemplate] = useState();

  useEffect(() => {
    const { data } = documentTemplates;
    const templates = data?.documentTemplates;

    if (!templates?.length || !documentTemplateData) {
      return;
    }

    const loadedTemplate = templates?.find((template) => template.type === docType || template.name === docType);

    if (!loadedTemplate) {
      setSelectedTemplate(null);

      return;
    }

    setSelectedTemplate(loadedTemplate);
  }, [documentTemplates, documentTemplateData, docType]);

  const documentPreviewProps = useMemo(() => {
    if (documentTemplateData?.loading) {
      return {};
    }

    return {
      data: documentTemplateData?.data,
      entityType: documentEntityType,
      documentTemplate: selectedTemplate
        ? {
            id: selectedTemplate.id,
            ...selectedTemplate.template,
            generationOptions: selectedTemplate.generationOptions,
          }
        : null,
      type: selectedTemplate?.type,
    };
  }, [selectedTemplate, documentTemplateData, documentEntityType]);

  const customDocumentPages = useDocumentPreview(documentPreviewProps);

  /**
    END NEW DOCUMENT GENERATION LOGIC
  */

  const { currentUser } = useAuth();
  const { salesOrder, purchaseOrder, buySellOrder, workOrder, inventoryTransfer } = contextProps;
  const order = salesOrder || purchaseOrder || buySellOrder || workOrder || inventoryTransfer;

  const inventoryTransferOverrides = useMemo(
    () =>
      inventoryTransfer
        ? {
            showTerms: false,
            showBillTo: false,
            showUnitsOrdered: false,
            displayUnitPrice: false,
          }
        : {},
    [inventoryTransfer]
  );

  const documentOptions = useDocumentOptions((state) => state.options);

  const [embeddedDoc, setEmbeddedDoc] = React.useState<{ url: string; blob: Blob }>(null);
  const useEmbeddedDoc = useMemo(() => !!sourceDocuments[docType], [docType]);

  useEffect(() => {
    let url = '';

    if (docType && sourceDocuments[docType]) {
      sourceDocuments[docType](contextProps).then((pdfUrl) => {
        url = pdfUrl;

        setEmbeddedDoc(pdfUrl);
      });
    }

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [docType, contextProps]);

  const goldenStarCitrusOrgIds = [
    'org_2QvuptBuwUyNuUCaAMz8K1vvyPt', // Staging
    'org_2XY3euTXxAmhNqt5UsODQ9XaFQI', // Prod
  ];

  const growerSelectProduceOrgIds = ['org_2eFrJg6UR7OjCsfNCnVu0r3Rhzu', 'org_2bnRzYwsZP132Izq1RG02kXp1WF'];

  const isGoldenStarCitrusOrg = goldenStarCitrusOrgIds.includes(currentUser.organizationClerkId as string);
  const isGrowerSelectProduceOrg = growerSelectProduceOrgIds.includes(currentUser.organizationClerkId as string);

  // The legacy code accepts a single document to be rendered
  // The new code accepts a list of documents and allows selecting between them
  const doc = useCallback(() => {
    /******** HARD CODED CUSTOM BOL PATCH FOR GOLDEN STAR CITRUS ********/

    const avostreetOrganizationClerkIds = [
      'org_2Ws3jKKorcGOW5OMmtOtmGqAMNU', // Staging
      'org_2b6kjHcrxkyI6fLcFrD7oisE7no', // Prod
    ];

    const millenniumPacificOrgId = 'org_2aDSqRjaBUJOQiDIe688TYfi8X7'; // Prod

    const isMillenniumPacificOrg = currentUser.organizationClerkId === millenniumPacificOrgId;

    const isAvostreetOrganization = avostreetOrganizationClerkIds.includes(currentUser.organizationClerkId as string);

    const isIntegroProduce = currentUser.organizationClerkId === 'org_2fexqmTGojCUtkQYGHtKSOJQBID'; // Prod

    const orgContactEmails = {
      org_2eFrJg6UR7OjCsfNCnVu0r3Rhzu: 'paperwork@groselpro.com',
      org_2bnRzYwsZP132Izq1RG02kXp1WF: 'paperwork@groselpro.com',
    };

    const overrideContactEmail = orgContactEmails[currentUser.organizationClerkId as string];

    if (customDocumentPages) {
      return <Document>{renderPages(customDocumentPages)}</Document>;
    }

    if (document) {
      return typeof document === 'function' ? document(contextProps) : document;
    }

    if (documentTypes || documentType) {
      switch (docType) {
        case 'SALES_ORDER_CONFIRMATION':
          if (orderType === 'BSO') {
            return <BSOSalesOrderTemplate {...contextProps.buySellOrder} />;
          }
          return (
            <SalesOrderTemplate
              {...contextProps.salesOrder}
              customFieldDefinitions={flags[FeatureFlags.CustomFields]['salesOrder']}
              overrideContactEmail={overrideContactEmail}
            />
          );
        case 'PICK_TICKET': {
          // on the PICK_TICKET page, the document data is the pickTicket object
          const order = contextProps.pickTicket || contextProps.salesOrder;
          return (
            <PickTicketTemplate
              {...{
                ...order,
                lineItems: LineItem.Utils.mergeChildLineItemsIntoParents(order.lineItems),
              }}
              overrideContactEmail={overrideContactEmail}
            />
          );
        }

        case 'BILL_OF_LADING': {
          if (orderType === 'BSO') {
            return (
              <BSOBillOfLadingTemplate
                customTerms={flags.customBolTerms?.customTerms}
                buySellOrder={contextProps.buySellOrder}
                {...documentOptions.bol}
              />
            );
          }

          if (orderType === 'LOAD') {
            return (
              <MultiBillOfLadingDocument
                organization={contextProps.organization}
                load={contextProps.load}
                orders={contextProps.orders}
                waypoints={contextProps.waypoints}
              />
            );
          }

          const order = contextProps.pickTicket || contextProps.salesOrder || contextProps.inventoryTransfer;

          /**
           * Until we have customizable documents, some hacks to get around user requests for custom documents.
           */
          if (isGoldenStarCitrusOrg) {
            return (
              <GSCBillOfLadingDocument
                {...{
                  ...order,
                  lineItems: LineItem.Utils.mergeChildLineItemsIntoParents(order.lineItems),
                }}
                {...inventoryTransferOverrides}
              />
            );
          }

          if (isGrowerSelectProduceOrg) {
            const customFields = flags[FeatureFlags.CustomFields]['salesOrder'];

            return (
              <GSPBillOfLadingDocument
                salesOrder={{
                  ...order,
                  lineItems: LineItem.Utils.mergeChildLineItemsIntoParents(order.lineItems),
                }}
                {...inventoryTransferOverrides}
                {...documentOptions.bol}
                customFields={customFields}
              />
            );
          }

          if (isMillenniumPacificOrg) {
            return (
              <MPBillOfLadingDocument
                salesOrder={{
                  ...{
                    ...order,
                    lineItems: LineItem.Utils.mergeChildLineItemsIntoParents(order.lineItems),
                  },
                }}
                {...documentOptions.bol}
                {...inventoryTransferOverrides}
                customTerms={flags.customBolTerms?.customTerms}
              />
            );
          }

          if (orderType === 'SO') {
            const hasPOLineItem = order.lineItems.some((lineItem) => lineItem.purchaseOrder);

            if (hasPOLineItem) {
              if (isIntegroProduce) {
                return (
                  <IntegroSOPOSourcedBOLDocument
                    salesOrder={{
                      ...order,
                      lineItems: LineItem.Utils.mergeChildLineItemsIntoParents(order.lineItems),
                    }}
                    {...documentOptions.bol}
                    {...inventoryTransferOverrides}
                  />
                );
              }

              return <SOPOSourcedBOLDocument salesOrder={order} />;
            }
          }

          if (isIntegroProduce) {
            return (
              <IntegroBillOfLadingDocument
                salesOrder={{
                  ...order,
                  lineItems: LineItem.Utils.mergeChildLineItemsIntoParents(order.lineItems),
                }}
                {...documentOptions.bol}
                {...inventoryTransferOverrides}
              />
            );
          }

          return (
            <BillOfLadingDocument
              customTerms={flags.customBolTerms?.customTerms}
              salesOrder={{
                ...order,
                lineItems: LineItem.Utils.mergeChildLineItemsIntoParents(order.lineItems),
              }}
              {...documentOptions.bol}
              {...inventoryTransferOverrides}
            />
          );
        }
        case 'PURCHASE_ORDER_CONFIRMATION':
          if (orderType === 'BSO') {
            return <BSOPurchaseOrderTemplate {...contextProps.buySellOrder} />;
          }
          return <PurchaseOrderTemplate {...contextProps.purchaseOrder} />;
        case 'RECEIVING_TICKET':
          // on the RECEIVING_TICKET page, the document data is the receivingTicket object
          if (orderType === 'GP') {
            return (
              <WorkOrderReceivingTicketTemplate
                {...contextProps.workOrder}
                overrideContactEmail={overrideContactEmail}
              />
            );
          }
          return (
            <ReceivingTicketTemplate
              {...(contextProps.receivingTicket || contextProps.purchaseOrder)}
              overrideContactEmail={overrideContactEmail}
            />
          );

        case 'WORK_ORDER_CONFIRMATION':
          return <WorkOrderTemplate {...contextProps.workOrder} overrideContactEmail={overrideContactEmail} />;

        case 'INVOICE':
          if (orderType === 'BSO') {
            return <BSOInvoiceTemplate {...contextProps.buySellOrderInvoice} />;
          }

          if (orderType === 'STANDALONE') {
            return <StandardInvoiceTemplate {...contextProps.standardInvoice} />;
          }
          // TODO: migrate the existing SO invoice document preview to use this
          // For now, returning nothing
          return <></>;

        case 'GROWER_SETTLEMENT':
          return (
            <GrowerSettlementTemplate
              consignmentPayable={{
                ...contextProps.consignmentPayable,
                // The frontend receives the SalesOrderLineItemWithProportionOfSale type, but the document expects a
                // regular LineItem type. So we extract the lineItem from each salesOrderLineItemWithProportionOfSale
                salesOrderLineItems: contextProps.consignmentPayable.salesOrderLineItems.map(
                  ({ lineItem }) => lineItem
                ),
              }}
              salesOrderLineItemsWithProportionOfSale={contextProps.consignmentPayable.salesOrderLineItems}
              Product={Product}
            />
          );

        case 'CARRIER_CONFIRMATION':
          if (orderType === 'LOAD') {
            return (
              <CarrierConfirmationTemplate
                organization={contextProps.organization}
                load={contextProps.load}
                orders={contextProps.orders}
                waypoints={contextProps.waypoints}
              />
            );
          }

          if (contextProps.carrierConfirmation) {
            return <OrderCarrierConfirmation {...contextProps.carrierConfirmation} />;
          }

          return <ShipmentCarrierConfirmationTemplate order={order} />;

        case 'PRODUCTION_RUN_REPORT':
          return <ProductionRunReportTemplate {...contextProps.productionRun} />;

        case 'PASSING': {
          const order = contextProps.salesOrder || contextProps.buySellOrder || contextProps.pickTicket;
          return (
            <PassingTemplate
              orderType={order.__typename}
              {...order}
              isAvostreetOrganization={isAvostreetOrganization}
              overrideContactEmail={overrideContactEmail}
            />
          );
        }
        default:
          return <></>;
      }
    }
  }, [
    contextProps,
    customDocumentPages,
    document,
    documentOptions,
    docType,
    documentTypes,
    documentType,
    flags,
    isGoldenStarCitrusOrg,
    isGrowerSelectProduceOrg,
    inventoryTransferOverrides,
    orderType,
    currentUser,
    order,
  ]);

  const getDocTypeLabel = (docType) => {
    if (docType === 'WORK_ORDER_CONFIRMATION') {
      return 'Grower Product';
    }

    if (typeof docType === 'object') {
      return docType.name;
    }

    return convertToTitleCase(docType);
  };

  const customDocuments =
    documentTemplates?.data?.documentTemplates
      ?.filter((template) => template.type === 'CUSTOM')
      ?.map(({ name }) => name) ?? [];

  const [createDocument] = useMutation(CREATE_DOCUMENT);
  const [pdf, setDocument] = usePDF({ document: doc() });

  useEffect(() => {
    setDocument(doc());
  }, [docType, setDocument, doc]);

  const handleDownload = useCallback(
    async (print = false) => {
      const { filename, orderType, orderId, orderSlug, onSuccess, onError } = generateOptions;
      const pdfToDownload = useEmbeddedDoc ? embeddedDoc : pdf;

      if (!pdfToDownload || !generatable) {
        return;
      }

      // If a filename is provided, use that,
      // otherwise use the derived filename
      const derivedFilename = () => {
        if (documentType && filename) {
          return filename;
        }

        switch (docType) {
          case 'SALES_ORDER_CONFIRMATION':
            return `Sales Order ${orderSlug}`;
          case 'PURCHASE_ORDER_CONFIRMATION':
            return `Purchase Order ${orderSlug}`;
          case 'WORK_ORDER_CONFIRMATION':
            return `Grower Product Confirmation ${orderSlug}`;
          case 'WORK_ORDER_RECEIVING_TICKET':
            return `Grower Product Receiving Ticket ${orderSlug}`;
          default:
            return `${convertToTitleCase(docType)} ${orderSlug}`;
        }
      };

      try {
        const fileName = `${derivedFilename()}.pdf`;
        const uploadResult = await uploadToFilestack(pdfToDownload.blob, fileName);
        const type = useEmbeddedDoc ? 'CUSTOM' : (documentType ?? docType);

        const result = await createDocument({
          variables: {
            input: {
              title: uploadResult._file.name,
              // when selecting from multiple document types, we set 'docType'
              type,
              url: uploadResult.url,
              ...(orderType === 'PO' && { purchaseOrderId: orderId }),
              ...(orderType === 'SO' && { salesOrderId: orderId }),
              ...(orderType === 'BSO' && { buySellOrderId: orderId }),
              ...(orderType === 'GP' && { workOrderId: orderId }),
              ...(orderType === 'LOAD' && { loadId: orderId }),
              ...(orderType === 'PRODUCTION_RUN' && {
                productionRunId: orderId,
              }),
            },
          },
        });

        if (print) {
          printUrl(pdfToDownload.url);
        } else {
          saveAs(pdfToDownload.blob, fileName);
        }
        onSuccess?.(result.data.createDocument);
      } catch (error) {
        onError?.(error);
      }
    },
    [createDocument, docType, documentType, generateOptions, useEmbeddedDoc, generatable, pdf, embeddedDoc]
  );

  const containerRef = useRef<HTMLElement>(null);

  return (
    <Stack width="65vh" height="100%">
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb="2" width="100%">
        <Select
          size="small"
          sx={{ alignSelf: 'flex-start', minWidth: '140px' }}
          value={docType ?? documentType}
          onChange={(e) => {
            if (e.target.value !== 'CANADA_CONFIRMATION_OF_SALE' && embeddedDoc) {
              setEmbeddedDoc(null);
            }
            setDocType(e.target.value);
          }}
          disabled={!documentTypes?.length || documentTypes?.length <= 1}
        >
          {[...(extraDocuments ?? []), ...(documentTypes ?? []), ...customDocuments, documentType]
            .filter(Boolean)
            .map((docType) => (
              <MenuItem key={docType} value={docType}>
                {getDocTypeLabel(docType)}
              </MenuItem>
            ))}
        </Select>
        <Box display="flex" justifyContent="center">
          <IconButton onClick={() => handleDownload(true)}>
            <Print sx={{ color: 'primary.main' }} />
          </IconButton>
          <IconButton onClick={() => handleDownload(false)}>
            <Download sx={{ color: 'primary.main' }} />
          </IconButton>
        </Box>
      </Box>
      <Box flexGrow={1} ref={containerRef} mt={3}>
        <DocumentViewer
          width="100%"
          height={`${containerRef.current?.getBoundingClientRect().height - GLOBAL_APP_BAR_HEIGHT - 68}px`}
          pdf={useEmbeddedDoc ? embeddedDoc : pdf}
          Controls={ZoomControls}
          borderRadius="2px"
        />
      </Box>
      <BOLDocumentOptions
        docType={docType}
        orderType={orderType}
        isGoldenStarCitrusOrg={isGoldenStarCitrusOrg}
        isGrowerSelectProduceOrg={isGrowerSelectProduceOrg}
      />
    </Stack>
  );
}
