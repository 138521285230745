import { Box, Switch, Typography } from '@mui/material';
import { DocumentTemplate, DocumentType } from 'types/graphql';

export type DocumentTypeUnion = DocumentType | DocumentTemplate;

export type DocumentTypeOption = {
  label: string;
  value: DocumentTypeUnion;
};

export type DocumentTypeOptions = DocumentTypeUnion[];

export type DisplayedDocumentsMenuProps<T extends DocumentTypeUnion> = {
  setDocumentTypes: (documentTypes: DocumentTypeUnion[]) => void;
  documentTypes: DocumentTypeUnion[];
  documentTypeOptions: DocumentTypeOptions;
  renderOptionLabel: (type: DocumentTypeUnion) => string;
  isOptionSelected: (option: DocumentTypeUnion, value: DocumentTypeUnion) => boolean;
};

export default function DisplayedDocumentsMenu<T extends DocumentTypeUnion>({
  setDocumentTypes,
  documentTypes,
  documentTypeOptions,
  renderOptionLabel,
  isOptionSelected,
}: DisplayedDocumentsMenuProps<T>) {
  if (documentTypeOptions.length === 0) {
    return null;
  }

  return (
    <>
      {documentTypeOptions.map((value) => {
        const isSelected = isOptionSelected(value, documentTypes);

        return (
          <Box display="flex" key={value} px={1} alignItems="center">
            <Typography mr={1}>{renderOptionLabel(value)}</Typography>
            <Switch
              sx={{
                ml: 'auto',
              }}
              defaultChecked={isSelected}
              value={isSelected}
              onChange={(_, checked) => {
                if (checked) {
                  setDocumentTypes([...documentTypes, value]);
                } else {
                  setDocumentTypes(documentTypes.filter((type) => type !== value));
                }
              }}
            />
          </Box>
        );
      })}
    </>
  );
}
