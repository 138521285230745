import React, { useMemo } from 'react';

import { Prisma } from '@prisma/client';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import { Place, User } from 'types/graphql';

import { mapSalesTermsToLabel } from 'src/lib/utils/salesTerms';
import computePaymentDueDate from 'src/services/utils/computePaymentDueDate';
import { stripSlugPrefix } from 'src/services/utils/generateInvoiceOrPayableSlug';
import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';

import money from '../utils/money';
import { docStyles } from '../utils/styles';

type PurchaseOrderTemplateProps = Prisma.PurchaseOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    buyer: { include: { user: true; subsidiary: true } };
    businessEntity: true;
    businessEntityContact: true;
    shipment: {
      include: {
        origin: true;
        destination: true;
      };
    };
    subsidiary: true;
    lineItems: {
      select: {
        buyPrice: true;
        unitsReceived: true;
      };
      include: {
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
            commodityLabel: true;
          };
        };
      };
    };
    expenses: true;
    payables: true;
    notes: {
      include: {
        membership: {
          include: { user: true };
        };
      };
    };
  };
}>;

type SalesOrderTemplateProps = Prisma.SalesOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    salesPerson: { include: { user: true; subsidiary: true } };
    businessEntity: true;
    businessEntityContact: true;
    billTo: true;
    shipment: {
      include: {
        origin: true;
        place: true; // TODO: Update to `destination`
      };
    };
    subsidiary: true;
    lineItems: {
      select: {
        sellPrice: true;
        unitsPicked: true;
      };
      include: {
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
            commodityLabel: true;
          };
        };
      };
    };
    expenses: true;
    payables: true;
    notes: {
      include: {
        membership: { include: { user: true } };
      };
    };
  };
}>;

type BSOTemplateProps = Prisma.BuySellOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    broker: { include: { user: true; subsidiary: true } };
    vendor: true;
    vendorContact: true;
    customer: true;
    customerContact: true;
    shipment: {
      include: {
        origin: true;
        destination: true;
      };
    };
    billTo: true;
    vendorBillTo: true;
    subsidiary: true;
    lineItems: {
      include: {
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
          };
        };
      };
    };
    expenses: true;
    notes: {
      include: {
        membership: {
          include: {
            user: true;
          };
        };
      };
    };
  };
}>;

export const AddressBlock = ({
  address,
  displayDescription = true,
  displayCountry = true,
  bold = false,
}: {
  address?: Place | Prisma.PlaceGetPayload<unknown>;
  displayDescription?: boolean;
  displayCountry?: boolean;
  bold?: boolean;
}) => {
  if (!address) {
    return null;
  }

  const description = address?.description;
  const showDescription = displayDescription && description;

  const displayAddress = useMemo(() => {
    const result = [];

    if (address.city) {
      result.push(`${address.city},`);
    }

    if (address?.state) {
      result.push(address.state);
    }

    if (address?.addressCountry && displayCountry) {
      result.push(address.addressCountry);
    }

    if (address?.postalCode) {
      result.push(address.postalCode);
    }

    return result.join(' ');
  }, [address]);

  const style = {
    fontWeight: bold ? 'semibold' : 'normal',
  };

  return (
    <>
      {showDescription && <Text style={style}>{description}</Text>}
      <Text style={style}>{address?.streetAddress1}</Text>
      <Text style={style}>{displayAddress}</Text>
    </>
  );
};

const breakText = (text: string, breakLength: number) => {
  if (text.length < breakLength) {
    return text;
  }

  const result = [];

  for (let i = breakLength; i < text.length; i += breakLength) {
    result.push(text.slice(i - breakLength, i), '\n');
  }

  result.push(text.slice(text.length - (text.length % breakLength)));

  return result;
};

export const OrgDetailsBlock = ({
  documentName,
  orgLogoUrl,
  orgName,
  address,
  user,
  showEmail = true,
  overrideContactEmail,
}: {
  documentName: string;
  orgLogoUrl: string;
  orgName: string;
  address: Place | Prisma.PlaceGetPayload<unknown>;
  user?: User | Prisma.UserGetPayload<unknown>;
  showEmail?: boolean;
  overrideContactEmail?: string;
}) => {
  const showOrgName = !address?.description;

  // since we're deprecating documents, just hardcoding the hell out of this one
  // this override contact email seems to only be populated on the front end,
  // and documents are so disperse on the background and i don't got time for all dat
  const isGrowerSelect = orgName?.includes('Growers Select Produce');

  if (isGrowerSelect) {
    overrideContactEmail = 'paperwork@groselpro.com';
  }

  return (
    <View style={docStyles.letterHeadContainer}>
      <View style={docStyles.titleAndDetailsContainer}>
        <Text style={docStyles.documentTitle}>{documentName}</Text>

        <View style={docStyles.organizationIdentifier}>
          {orgLogoUrl && (
            <View style={docStyles.organizationLogo}>
              <Image src={orgLogoUrl} />
            </View>
          )}
          <Text style={docStyles.organizationName} hyphenationCallback={(word) => [word]}>
            {orgName?.toUpperCase()}
          </Text>
        </View>
      </View>

      <View style={docStyles.contactInformationContainer}>
        <View style={docStyles.billingAddressContainer}>
          <View style={docStyles.billingAddressText}>
            {showOrgName && <Text>{orgName}</Text>}
            <AddressBlock address={address} />
          </View>
        </View>

        {user && !overrideContactEmail && (
          <View style={docStyles.phoneAndEmailContainer}>
            <View style={docStyles.phoneAndEmailText}>
              <Text>
                {user?.firstName || '-'} {user?.lastName || '-'}
              </Text>

              <Text>{address?.phone}</Text>

              {showEmail && user?.email && (
                <Link src={'mailto:' + user?.email}>
                  {user?.email}
                  {/* {breakText(user?.email, 20)} */}
                </Link>
              )}
            </View>
          </View>
        )}
        {overrideContactEmail && (
          <View style={docStyles.phoneAndEmailContainer}>
            <View style={docStyles.phoneAndEmailText}>
              <Link src={'mailto:' + overrideContactEmail}>{overrideContactEmail}</Link>
              <Text>{address?.phone}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export const DocumentDetailBlock = ({ headerText, children, flex = 1 }) => {
  return (
    <View style={{ flex }}>
      <View style={docStyles.documentDetailHeaderText}>
        <Text>{headerText.toUpperCase()}</Text>
      </View>

      <View style={docStyles.documentDetailMainText}>{children}</View>
    </View>
  );
};

export const formatDate = (date: string | Date) => {
  if (!date) {
    return null;
  }

  const dateUTC =
    date instanceof Date
      ? DateTime.fromJSDate(date, {
          zone: 'utc',
        })
      : DateTime.fromISO(date, {
          zone: 'utc',
        });

  return dateUTC
    .setZone('utc', {
      keepLocalTime: true,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toFormat('MM/dd/yyyy');
};

const formatDateTime = (date: string | Date) => {
  if (!date) {
    return null;
  }

  const dateLocal = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);

  return dateLocal.toFormat('MM/dd/yyyy h:mm a');
};

export const DateBlock = ({
  dateHeader,
  date,
  shouldDisplayTime = false,
}: {
  dateHeader: string;
  date: string | Date;
  shouldDisplayTime?: boolean;
}) => {
  const timeString = formatDateTime(date);
  const dateString = formatDate(date);

  return (
    <View>
      {date && (
        <Text>
          {dateHeader}: {shouldDisplayTime ? `${timeString}` : `${dateString}`}
        </Text>
      )}
    </View>
  );
};

export const ContactBlock = ({
  orgName,
  contactName,
  contactEmail,
  address,
  displayCountry = true,
}: {
  orgName: string;
  contactName: string;
  contactEmail: string;
  address?: Place | Prisma.PlaceGetPayload<unknown>;
  displayCountry?: boolean;
}) => {
  const showOrgName = !address?.description?.trim();

  return (
    <View>
      {showOrgName && <Text>{orgName}</Text>}
      <Link src={'mailto:' + contactEmail}>{contactName}</Link>
      {address && <AddressBlock address={address} displayCountry={displayCountry} />}
    </View>
  );
};

export function PurchaseOrderHeader({
  order,
  headerName = 'PURCHASE ORDER',
  overrideContactEmail,
}: {
  order: PurchaseOrderTemplateProps & BSOTemplateProps;
  headerName?: string;
  overrideContactEmail?: string;
}) {
  const orderSlug = order.slug;
  const submittedAt = order.submittedAt;

  // <po attribute> || <bso attribute>

  // `order.vendorPo` is mis-named in PO db entity. It needs to be renamed to `order.vendorSo`
  // The second `order.vendorSo` is for BSOs (BuySellOrders), implemented correctly
  const vendorSo = order.vendorPo || order.vendorSo;
  const vendor = order.businessEntity || order.vendor;
  const vendorContact = order.businessEntityContact || order.vendorContact;
  const receiveDate = order.receiveDate || order.shipment?.shipDate;
  const location = order.shipment?.origin || order.shipment?.place;
  const buyer = order.buyer || order.broker;

  const address = getPrimaryPlace(order.organization.places, order.subsidiary ?? buyer?.subsidiary);

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={headerName}
        orgLogoUrl={order.organization.logoUrl}
        orgName={order.subsidiary?.description ?? order.organization.label}
        address={address}
        user={buyer?.user}
        overrideContactEmail={overrideContactEmail}
      />

      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={`PO #${orderSlug}`}>
          <DateBlock dateHeader="Order Date" date={submittedAt} />

          <Text>Vend. SO.: {vendorSo}</Text>
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Pay To">
          <ContactBlock
            orgName={vendor?.name}
            contactName={vendorContact?.name}
            contactEmail={vendorContact?.email}
            address={order.vendorBillTo ?? order.billTo}
          />

          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Pick Up">
          <DateBlock dateHeader="Receive Date" date={receiveDate} />

          <AddressBlock address={location} />
          {/* TODO: Add Carrier Information */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Terms">
          <Text>{mapSalesTermsToLabel(order.salesTerms ?? order.vendorSalesTerms)}</Text>
        </DocumentDetailBlock>

        {/* Add other SO related information here */}
      </View>
    </View>
  );
}

export function SalesOrderHeader({
  order,
  headerName = 'SALES ORDER',
  overrideContactEmail,
}: {
  order: SalesOrderTemplateProps & BSOTemplateProps;
  headerName?: string;
  overrideContactEmail?: string;
}) {
  const orderSlug = order.slug;
  const submittedAt = order.submittedAt;

  const salesPerson = order.salesPerson || order.broker;
  const address = getPrimaryPlace(
    order.organization.places,
    order.subsidiary ?? salesPerson?.subsidiary ?? order.shipment?.origin
  );

  // <po attribute> || <bso attribute>

  // `order.vendorPo` is mis-named in PO db entity. It needs to be renamed to `order.vendorSo`
  // The second `order.vendorSo` is for BSOs (BuySellOrders), implemented correctly
  const customerPo = order.customerPo;
  const customer = order.businessEntity || order.customer;
  const customerContact = order.businessEntityContact || order.customerContact;
  const shipDate = order.shipDate ?? order.shipment?.shipDate;

  const documentSlugPrefix = order.organization.salesOrderDocumentPrefix ?? 'SO';

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={headerName}
        orgLogoUrl={order.organization.logoUrl}
        orgName={order.subsidiary?.description ?? order.organization.label}
        address={address}
        user={salesPerson?.user}
        overrideContactEmail={overrideContactEmail}
      />

      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={`${documentSlugPrefix} #${orderSlug}`}>
          <DateBlock dateHeader="Order Date" date={submittedAt} />

          <Text>Cust. PO.: {customerPo}</Text>
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Bill To">
          <ContactBlock
            orgName={customer?.name}
            contactName={customerContact?.name}
            contactEmail={customerContact?.email}
            address={order.billTo}
          />

          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Delivery">
          <DateBlock dateHeader="Ship Date" date={shipDate} />

          <AddressBlock address={order.shipment?.destination || order.shipment?.place} />

          {/* TODO: Add Carrier Information */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Terms">
          <Text>{mapSalesTermsToLabel(order.salesTerms ?? order.customerSalesTerms)}</Text>
        </DocumentDetailBlock>

        {/* Add other SO related information here */}
      </View>
    </View>
  );
}

export function InvoiceHeader({ invoice, overrideContactEmail }) {
  const salesPerson = invoice.salesOrder.salesPerson;

  const address = getPrimaryPlace(
    invoice.organization.places,
    invoice.salesOrder.subsidiary ?? salesPerson?.subsidiary
  );

  const documentSlugPrefix = invoice.organization.salesOrderDocumentPrefix ?? 'SO';

  const paymentDueDate = computePaymentDueDate(invoice.sentAt, invoice.salesOrder.salesTerms);

  const shipDestination = invoice.salesOrder.shipment?.place || invoice.salesOrder.shipment?.destination;

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName="INVOICE"
        orgLogoUrl={invoice.organization.logoUrl}
        orgName={
          invoice.salesOrder.subsidiary?.description ??
          invoice.salesOrder.organization?.label ??
          invoice.organization.label
        }
        address={address}
        user={salesPerson?.user}
        overrideContactEmail={overrideContactEmail}
      />

      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={`${documentSlugPrefix} #${invoice.slug.replace(/\SO-/g, '')}`}>
          <DateBlock dateHeader="Order Date" date={invoice.sentAt || invoice.createdAt} />

          <Text>Cust. PO.: {invoice.salesOrder.customerPo}</Text>
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Bill To">
          <ContactBlock
            orgName={invoice.salesOrder.businessEntity?.name}
            contactName={invoice.salesOrder.businessEntityContact?.name}
            contactEmail={invoice.salesOrder.businessEntityContact?.email}
            address={invoice.salesOrder.billTo}
          />

          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Delivery">
          <DateBlock dateHeader="Ship Date" date={invoice.salesOrder.shipDate} />

          <AddressBlock address={shipDestination} />
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Terms">
          <Text>{mapSalesTermsToLabel(invoice.salesOrder.salesTerms)}</Text>

          <DateBlock dateHeader="Due Date" date={paymentDueDate} />

          {/* TODO: Add "Paid" text block, once fully paid */}
        </DocumentDetailBlock>

        {/* Add other SO related information here */}
      </View>
    </View>
  );
}

export function PassingHeader({ order, overrideContactEmail }) {
  const businessEntity = order.businessEntity || order.customer;
  const businessEntityContact = order.businessEntityContact || order.customerContact;
  const salesPerson = order.salesPerson || order.broker;
  const address = getPrimaryPlace(
    order.organization.places,
    order.subsidiary ?? salesPerson?.subsidiary ?? order.shipment?.origin
  );

  let documentSlugPrefix = '';

  if (order.__typename === 'SalesOrder' || order.orderType === 'SalesOrder') {
    documentSlugPrefix = order.organization.salesOrderDocumentPrefix ?? 'SO';
  } else {
    documentSlugPrefix = 'BSO';
  }

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={order.isAvostreetOrganization ? 'INVOICE' : 'PASSING'}
        orgLogoUrl={order.organization.logoUrl}
        orgName={order.subsidiary?.description ?? order.organization.label}
        address={address}
        user={salesPerson?.user}
        overrideContactEmail={overrideContactEmail}
      />

      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={`${documentSlugPrefix} #${order.slug.replace(/\SO-/g, '')}`}>
          <DateBlock dateHeader="Order Date" date={order.submittedAt || order.createdAt} />

          <Text>Cust. PO.: {order.customerPo}</Text>
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Bill To">
          <ContactBlock
            orgName={businessEntity?.name}
            contactName={businessEntityContact?.name}
            contactEmail={businessEntityContact?.email}
            address={order.billTo}
          />

          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Delivery">
          {order.shipDate && <DateBlock dateHeader="Ship Date" date={order.shipDate} />}

          <AddressBlock address={order.shipment?.destination || order.shipment?.place} />
        </DocumentDetailBlock>

        {/* Add other SO related information here */}
      </View>
    </View>
  );
}

export function BSOInvoiceHeader({ invoice }) {
  const salesTerms = invoice.buySellOrder?.customerSalesTerms;
  const paymentDueDate = computePaymentDueDate(new Date(), salesTerms);

  const broker = invoice.buySellOrder?.broker;
  const address = getPrimaryPlace(invoice.organization.places, invoice.buySellOrder?.subsidiary ?? broker?.subsidiary);

  const customerPO = invoice.buySellOrder?.customerPo;

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName="INVOICE"
        orgLogoUrl={invoice.organization.logoUrl}
        orgName={
          invoice.buySellOrder.subsidiary?.description ??
          invoice.buySellOrder.organization?.label ??
          invoice.organization.label
        }
        address={address}
        user={broker?.user}
      />

      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={`SO #${stripSlugPrefix(invoice.slug)}`}>
          <DateBlock dateHeader="Order Date" date={invoice.sentAt || invoice.createdAt} />

          <Text>Cust. PO.: {customerPO}</Text>
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Bill To">
          <ContactBlock
            orgName={invoice.buySellOrder.customer?.name}
            contactName={invoice.buySellOrder.customerContact?.name}
            contactEmail={invoice.buySellOrder.customerContact?.email}
            address={invoice.buySellOrder.billTo}
          />

          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Delivery">
          <DateBlock dateHeader="Ship Date" date={invoice.buySellOrder.shipment?.shipDate} />

          {/* TODO: Add Carrier Information */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Terms">
          <Text>{mapSalesTermsToLabel(salesTerms)}</Text>

          <DateBlock dateHeader="Due Date" date={paymentDueDate} />

          {/* TODO: Add "Paid" text block, once fully paid */}
        </DocumentDetailBlock>

        {/* Add other SO related information here */}
      </View>
    </View>
  );
}

export function BOLHeader({ order, showDateBlocks = true, showEmail = true, showTerms = true, showBillTo = true }) {
  const member = order.broker || order.salesPerson || order.buyer;
  const address = getPrimaryPlace(
    order.organization.places,
    order.subsidiary ?? member?.subsidiary ?? order.shipment?.origin
  );

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName="BILL OF LADING"
        orgLogoUrl={order.organization.logoUrl}
        orgName={order.subsidiary?.description ?? order.organization.label}
        address={address}
        user={member?.user}
        showEmail={showEmail}
      />

      <BOLOrderHeader order={order} showDateBlocks={showDateBlocks} showTerms={showTerms} showBillTo={showBillTo} />
    </View>
  );
}

export function BOLOrderHeader({
  order,
  showDelivery = true,
  showDateBlocks = true,
  showTerms = true,
  showBillTo = true,
}) {
  const orderSlug = order.slug;
  const submittedAt = order.submittedAt;
  // <po attribute> || <bso attribute>
  const customerPo = order.vendorPo || order.customerPo;
  const customer = order.businessEntity || order.customer;
  const customerContact = order.businessEntityContact || order.customerContact;
  const shipDate = order.shipment?.shipDate || order.shipDate;
  const deliveryDate = order.shipment?.deliveryDate || order.deliveryDate;
  const shipDestination = order.shipment?.place || order.shipment?.destination;

  let documentSlugPrefix = '';

  if (order.__typename === 'PurchaseOrder') {
    documentSlugPrefix = 'PO';
  } else if (order.__typename === 'InventoryTransfer') {
    documentSlugPrefix = 'IT';
  } else {
    documentSlugPrefix = order.organization.salesOrderDocumentPrefix ?? 'SO';
  }

  return (
    <View style={docStyles.documentDetailsContainer}>
      <DocumentDetailBlock headerText={`${documentSlugPrefix} #${orderSlug}`}>
        <DateBlock dateHeader="Order Date" date={submittedAt} />

        {customerPo && <Text>Cust. PO.: {customerPo}</Text>}
      </DocumentDetailBlock>

      {showBillTo && (
        <DocumentDetailBlock headerText="Bill To">
          <ContactBlock
            orgName={customer?.name}
            contactName={customerContact?.name}
            contactEmail={customerContact?.email}
            address={order.billTo}
          />
          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>
      )}

      {showDelivery && (
        <DocumentDetailBlock headerText="Delivery">
          {showDateBlocks && (
            <>
              <DateBlock dateHeader="Ship Date" date={shipDate} />
              <DateBlock dateHeader="Delivery Date" date={deliveryDate} />
            </>
          )}

          <AddressBlock address={shipDestination} />

          {/* TODO: Add Carrier Information */}
        </DocumentDetailBlock>
      )}

      {showTerms && (
        <DocumentDetailBlock headerText="Terms">
          <Text>{mapSalesTermsToLabel(order.salesTerms ?? order.customerSalesTerms)}</Text>
          <Text>{order.shipment.terms}</Text>
        </DocumentDetailBlock>
      )}

      {/* Add other SO related information here */}
    </View>
  );
}

export function WorkOrderHeader({ order, headerName = 'GROWER PRODUCT', overrideContactEmail }) {
  const userAddress = getPrimaryPlace(order.organization.places);

  const orderSlug = order.slug;

  const grower = order.grower;
  const growerContact = order.growerContact;

  const submittedAt = order.submittedAt;
  const harvestAt = order.harvestedAt;
  const receivedAt = order.receivedAt;

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={headerName}
        orgLogoUrl={order.organization.logoUrl}
        orgName={order.subsidiary?.description ?? order.organization.label}
        address={userAddress}
        user={null} // TODO: Fix when a user is added to WOs
        overrideContactEmail={overrideContactEmail}
      />

      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={`GP #${orderSlug}`}>
          <DateBlock dateHeader="Order Date" date={submittedAt} />
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="GROWER">
          <ContactBlock orgName={grower?.name} contactName={growerContact?.name} contactEmail={growerContact?.email} />

          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Delivery">
          <DateBlock dateHeader="Received Date" date={receivedAt} />

          <Text>Field Ticket: {order.fieldTicketId ?? '--'} </Text>

          <Text>Harvested on: {harvestAt && formatDate(harvestAt)}</Text>

          <Text>Block/Ranch: {order.harvestLocation || '--'}</Text>

          {/* TODO: Add delivery location */}

          {/* TODO: Add Carrier Information */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Terms">
          <Text>Commission: {order.commissionRate}%</Text>
        </DocumentDetailBlock>

        {/* Add other SO related information here */}
      </View>
    </View>
  );
}
export function ConsignmentPayableHeader({ consignmentPayable, headerName = 'GROWER SETTLEMENT' }) {
  const grower = consignmentPayable?.workOrder?.grower;
  const growerContact = consignmentPayable?.workOrder?.growerContact;

  const order = consignmentPayable?.workOrder;

  const harvestAt = order.harvestedAt;

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={headerName}
        orgLogoUrl={consignmentPayable.organization.logoUrl}
        orgName={consignmentPayable.subsidiary?.description ?? consignmentPayable.organization?.label}
        address={getPrimaryPlace(consignmentPayable.organization.places)}
        user={null} // TODO: Fix when a user is added to WOs
      />

      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={`${consignmentPayable?.slug}`}>
          {/* TODO: Currently, nothing in the database stores in the `settlement date`. Add to database and update here */}
          <DateBlock dateHeader="Settlement Date" date={consignmentPayable.updatedAt} />
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="GROWER">
          <ContactBlock orgName={grower?.name} contactName={growerContact?.name} contactEmail={growerContact?.email} />

          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Delivery">
          {/* TODO: Update */}
          <DateBlock dateHeader="Received Date" date={order?.shipment?.deliveryDate} />
          <Text>Field Ticket: {order.fieldTicketId ?? '--'} </Text>
          <Text>Harvested on: {harvestAt && formatDate(harvestAt)}</Text>
          <Text>Block/Ranch: {order.harvestLocation || '--'}</Text>
          {/* TODO: Add delivery location */}
          {/* TODO: Add Carrier Information */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText="Terms">
          <Text>Commission</Text>
        </DocumentDetailBlock>
      </View>
    </View>
  );
}
