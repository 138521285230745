import React, { createContext, useEffect } from 'react';

import { useInterpret } from '@xstate/react';
import { AnyStateMachine } from 'xstate';

type ProviderProps = {
  machine: AnyStateMachine;
  children?: React.ReactNode;
  context?: any;
  services?: any;
  actions?: any;
};

export const StateMachineContext = createContext<{
  service: ReturnType<typeof useInterpret>;
}>({
  service: undefined,
});

export const StateMachineProvider = ({ machine, children, context, services, actions }: ProviderProps) => {
  const service = useInterpret(machine, {
    context,
    services,
    actions,
  });

  useEffect(() => {
    service.send('UPDATE_CONTEXT', context);
  }, [context]);

  return <StateMachineContext.Provider value={{ service }}>{children}</StateMachineContext.Provider>;
};
