import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import Mustache from 'mustache';

import { PalletTagLayout } from 'src/modules/palletTags/types';

import Barcode from './BarCode';

export const basicTemplate: PalletTagLayout = {
  name: 'Basic 4x2',
  width: '4in',
  height: '2.125in',
  layout: [
    {
      type: 'box',
      width: '100%',
      height: '100%',
      children: [
        {
          name: 'Table',
          type: 'table',
          showDividers: true,
          children: [
            {
              name: 'Header Row',
              type: 'headerRow',
              children: [
                { headerName: 'Lot' },
                { headerName: 'Receive Date' },
                {
                  headerName: 'COO',
                },
                { headerName: 'Qty' },
              ],
            },
            {
              name: 'Row',
              type: 'row',
              children: [
                { type: 'string', value: '{{ lot }}' },
                { type: 'date', value: '{{{ receiveDate }}}' },
                { type: 'string', value: '{{ countryOfOrigin }}' },
                { type: 'number', value: '{{ quantity }}' },
              ],
            },
            {
              name: 'Header Row (2)',
              type: 'headerRow',
              children: [
                {
                  headerName: 'Commodity',
                  colSpan: 2,
                },
                { headerName: 'Pack' },
                { headerName: 'Grade' },
              ],
            },
            {
              name: 'Row (2)',
              type: 'row',
              children: [
                { type: 'string', value: '{{{ description }}}', colSpan: 2 },
                { type: 'string', value: '{{{ packStyle }}}' },
                { type: 'string', value: '{{{ grade }}}' },
              ],
            },
          ],
        },
        {
          name: 'Pallet Tag',
          type: 'barcode',
          text: 'Pallet Tag #{{ tagNumber }}',
          width: '60%',
          height: '80%',
          scale: 3,
          barCodeHeight: 5,
          textsize: 5.5,
          inkspread: 0.5,
          value: '{{ tagNumber }}',
        },
      ],
    },
  ],
};

const getWrapWidth = (width) => {
  if (!width) {
    return {};
  }

  return {
    maxWidth: width === 'none' ? undefined : width,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
};

const renderTable = (table, data) => {
  const rows = table.children.map((row, index) => {
    if (row.type === 'headerRow') {
      return (
        <TableHead key={index}>
          <TableRow>
            {row.children.map((cell, cellIndex) => (
              <TableCell
                key={cellIndex}
                colSpan={cell.colSpan}
                sx={{
                  px: 0.125,
                  py: 0.25,
                  fontWeight: 'bold',
                  ...(table.headerCellStyle || {}),
                  ...(cell.style || {}),
                }}
              >
                {cell.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }

    return (
      <TableBody key={index}>
        <TableRow>
          {row.children.map((cell, cellIndex) => {
            const isSpecial = cell.type === 'box' || cell.type === 'barcode';

            return (
              <TableCell
                key={cellIndex}
                colSpan={cell.colSpan}
                sx={{
                  px: 0.125,
                  py: 0.25,
                  ...getWrapWidth(cell.wrapWidth),
                  ...(table.cellStyle || {}),
                  ...(cell.style || {}),
                }}
              >
                {isSpecial ? renderComponents[cell.type](cell, data) : Mustache.render(cell.value, data)}
              </TableCell>
            );
          })}
        </TableRow>
      </TableBody>
    );
  });

  return <Table>{rows}</Table>;
};

const renderComponents = {
  table: renderTable,
  box: ({ children, ...element }, data, tagId) => <Box {...element}>{renderElements(children, data, tagId)}</Box>,
  value: ({ value }, data) => Mustache.render(value, data),
  text: (element, data) => <Typography {...element}>{Mustache.render(element.text, data)}</Typography>,
  barcode: (element, data, tagId) => {
    const { lot, gtinCode, rawDate, productCode } = data ?? {};

    return (
      <Barcode
        {...element}
        textTemplate={element.text}
        lotNumber={lot}
        gtinCode={gtinCode}
        packDate={rawDate}
        productCode={productCode}
        tagNumber={element.value ? Mustache.render(element.value, data) : ''}
        tagId={tagId}
      />
    );
  },
};

const renderElements = (layout, data, tagId) => {
  return layout?.map((element) => {
    const renderMethod = renderComponents[element.type];
    return renderMethod ? renderMethod(element, data, tagId) : null;
  });
};

export function PalletTag({
  template,
  id,
  data, // Pass the data object that contains the mustache template variables
}) {
  const layout = template?.layout;

  if (!layout) {
    return null;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        p: template.padding ?? 1,
        mb: 2,
        width: template.width,
        height: template.height,
        '@media print': {
          mb: 0,
          border: 'none',
        },
      }}
      id={id}
    >
      <Box display="flex" flexDirection="column" height="100%">
        {renderElements(layout, data, id)}
      </Box>
    </Card>
  );
}
