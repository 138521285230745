import { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import QuickDatePicker from 'src/components/atoms/QuickDatePicker';
import { DateTime } from 'luxon';
import { useOrganizationQuery, useUpdateClosingPeriodMutation } from 'src/modules/closingPeriods/api/closingPeriods';
import { fromUTC } from 'shared/utils/DateTime';

export type ClosingPeriodModalProps = {
  open: boolean;
  onClose?: () => void;
  onClosingPeriodChanged?: (closedAt: DateTime) => void;
};

export default function ClosingPeriodModal({ open, onClose, onClosingPeriodChanged }: ClosingPeriodModalProps) {
  const [closedAt, setClosedAt] = useState<DateTime>(null);
  const { data, loading } = useOrganizationQuery();
  const [updateClosingPeriodMutation] = useUpdateClosingPeriodMutation();

  useMemo(() => {
    const result = data?.organization?.closedAt;
    if (loading || result === null || result === undefined) {
      return;
    }

    setClosedAt(fromUTC(result));
  }, [data, loading]);

  const handleClosingPeriodChanged = async () => {
    const input = { closedAt: closedAt.toJSDate() };
    await updateClosingPeriodMutation({ variables: { input } });
    onClosingPeriodChanged?.(closedAt);
  };

  const closeModal = () => {
    onClose();
  };

  const closedAtSet = !!closedAt;

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>Closing period settings</DialogTitle>
      <DialogContent>
        <DialogContentText maxWidth="700px">
          Note, that no edits can be done after date of closing period.
          <br /> You can always change the date later if needed.
        </DialogContentText>
        <Box display="flex" flexDirection="column" paddingY={1}>
          <QuickDatePicker
            label="Closing period date"
            value={closedAt}
            disabled={loading}
            disableFuture={true}
            maxDate={DateTime.now().minus({ days: 1 }).toLocaleString()}
            onChange={(value) => {
              setClosedAt(value && DateTime.fromISO(value, { zone: 'utc' }));
            }}
            renderInput={(params) => <TextField {...params} name="closedAt" />}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button disabled={!closedAtSet} type="submit" variant="contained" onClick={handleClosingPeriodChanged}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
