import { Button } from '@mui/material';
export type ClosingPeriodButtonProps = {
  onClick: () => void;
};

export const ClosingPeriodButton = ({ onClick }: ClosingPeriodButtonProps) => {
  return (
    <div>
      <Button size="medium" variant="outlined" onClick={onClick}>
        Close Period
      </Button>
    </div>
  );
};
