import { useCallback, useEffect, useMemo } from 'react';

import {
  Box,
  Divider,
  List,
  TableCell as MuiTableCell,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { mapExpenseCategoryToLabel } from 'api/src/lib/utils/expenseCategories';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { asUTCDate } from 'shared/utils/DateTime';

import Link from 'src/components/atoms/Link';
import StatusBadge from 'src/components/atoms/StatusBadge';
import { BoardLayout, useBoardStore } from 'src/pages/OrderBoardPage/OrderBoardPage';
import { entityTypes } from 'src/utils/entityTypes';
import { currencyNumberFormat } from 'src/utils/grid/cell/cellFormatters';
import money from 'src/utils/money';

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(0.25, 1),
}));

const HeaderTableCell = styled(MuiTableCell)(({ theme }) => ({
  fontWeight: 500,
  padding: theme.spacing(0.25, 1),
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
}));

// hardcoded for now, we can introduce a setting for this later
const COLUMN_COUNT = 3;

const convertToColumns = (arr, columns) => {
  const result = Array.from({ length: columns }, () => []);

  for (let i = 0; i < arr.length; i++) {
    const col = i % columns;

    result[col].push(arr[i]);
  }

  return result;
};

const layoutOrders = (orders, boardLayout: BoardLayout[][]) => {
  let flatOrders = [...orders.PurchaseOrder, ...orders.SalesOrder, ...orders.WorkOrder];

  let existingOrdersColumns;

  // If boardLayout exists, load it first
  if (boardLayout.length) {
    existingOrdersColumns = boardLayout.map((column) =>
      column
        .map(({ id, __typename }) => {
          const foundOrder = orders[__typename]?.find((order) => order.id === id);
          // Remove found orders from flatOrders
          if (foundOrder) {
            flatOrders = flatOrders.filter((order) => order.id !== id || order.__typename !== __typename);
          }
          return foundOrder;
        })
        .filter(Boolean)
    );

    // Distribute remaining orders (new orders) to the bottom of the columns
    const additionalColumns = convertToColumns(flatOrders, COLUMN_COUNT);
    existingOrdersColumns.forEach((existingColumn, i) => {
      existingColumn.push(...(additionalColumns[i] || []));
    });

    return existingOrdersColumns;
  }

  // If no boardLayout, just convert all orders to columns
  return convertToColumns(flatOrders, COLUMN_COUNT);
};

const getColumnIndexFromId = (droppableId: string) => {
  const [_, index] = droppableId.split(':');
  return parseInt(index, 10);
};

export default function OrderBoard({ orders }) {
  const setBoardLayout = useBoardStore((state) => state.setBoardLayout);
  const boardLayout = useBoardStore((state) => state.boardLayout);

  const orderColumns = useMemo(() => layoutOrders(orders, boardLayout), [orders, boardLayout]);

  // initilize layout in store
  useEffect(() => {
    const minifiedLayout = orderColumns.map((column, colIndex) =>
      column.map((order) => ({
        colIndex,
        id: order.id,
        __typename: order.__typename,
      }))
    );

    // Check if boardLayout actually needs an update
    if (JSON.stringify(minifiedLayout) !== JSON.stringify(boardLayout)) {
      setBoardLayout(minifiedLayout);
    }
  }, [setBoardLayout, orderColumns, boardLayout]);

  const onDragEnd: OnDragEndResponder = useCallback(
    ({ source, destination }) => {
      if (destination === undefined || destination === null) return null;

      const isSameColumn = destination.droppableId === source.droppableId;
      const isSameIndex = destination.index === source.index;

      if (isSameColumn && isSameIndex) return null;

      const sourceColumnIndex = getColumnIndexFromId(source.droppableId);
      const destColumnIndex = getColumnIndexFromId(destination.droppableId);

      const sourceList = [...boardLayout[sourceColumnIndex]];
      const destinationList = isSameColumn ? sourceList : [...boardLayout[destColumnIndex]];

      const [movedItem] = sourceList.splice(source.index, 1);

      if (!movedItem) {
        return boardLayout;
      }

      destinationList.splice(destination.index, 0, movedItem);

      const newColumns = [...boardLayout];
      newColumns[sourceColumnIndex] = sourceList;

      if (!isSameColumn) {
        newColumns[destColumnIndex] = destinationList;
      }

      setBoardLayout(newColumns);
    },
    [boardLayout, setBoardLayout]
  );

  const orderCount = useMemo(() => {
    return orderColumns.reduce((acc, column) => {
      return acc + column.length;
    }, 0);
  }, [orderColumns]);

  const noOrders = orderCount === 0;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box position="relative" display="grid" gridTemplateColumns={`repeat(${COLUMN_COUNT}, 1fr)`}>
        {orderColumns.map((column, index) => (
          <Box key={index} display="flex">
            <OrderBoardColumn orders={column} droppableId={`column:${index}`} />
            {index < COLUMN_COUNT - 1 && <Divider orientation="vertical" flexItem />}
          </Box>
        ))}
        {noOrders && (
          <Typography
            style={{
              position: 'absolute',
              top: '33%',
              left: 'calc(50% - 89px)',
            }}
            variant="h6"
            color="text.secondary"
          >
            No orders to display.
          </Typography>
        )}
      </Box>
    </DragDropContext>
  );
}

export const OrderBoardColumn = ({ orders, droppableId }) => {
  return (
    <Box flexGrow={1} height="100%" minHeight="calc(100vh - 210px)">
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              height: '100%',
            }}
          >
            {orders.map((order, index) => (
              <Draggable
                key={`${order.id}-${order.__typename}`}
                draggableId={`${order.id}-${order.__typename}`}
                index={index}
              >
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.draggableProps} py={0.5} px={1}>
                    <OrderBoardCard order={order} provided={provided} />
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </Box>
  );
};

export const OrderBoardCard = ({ order, provided }) => {
  const orderType = entityTypes[order.__typename];

  const isOutgoing = order.__typename === 'SalesOrder';
  const custLocationLabel = isOutgoing ? 'Ship To' : 'Shipped From';
  const dateLabel = isOutgoing ? 'Ship Date' : 'Delivery Date';

  const isWorkOrder = order.__typename === 'WorkOrder';
  const custOrderLabel = isWorkOrder ? 'Field Ticket #' : 'Cust. Order #';

  const showPrice = !isWorkOrder;
  const hasExpenses = order.expenses?.length > 0;

  const date = asUTCDate(order.date);

  return (
    <Paper
      sx={{
        p: 1,
        backgroundColor: 'background.default',
      }}
      elevation={2}
      {...provided.dragHandleProps}
    >
      <Box display="flex" p={1} mb={1}>
        <Link
          fontSize="16px"
          fontWeight={600}
          label={`${order.prefixedSlug}`}
          to={orderType.route({ slug: order.slug })}
        />
        <StatusBadge
          status={order.flagged ? 'FLAGGED' : order.status}
          context={order.type}
          sx={{
            ml: 'auto',
          }}
        />
      </Box>
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <HeaderTableCell>Warehouse</HeaderTableCell>
              <TableCell>{order.warehouse}</TableCell>
            </TableRow>
            <TableRow>
              <HeaderTableCell>Customer</HeaderTableCell>
              <TableCell>{order.customer}</TableCell>
            </TableRow>
            <TableRow>
              <HeaderTableCell
                sx={{
                  minWidth: '105px',
                }}
              >
                {dateLabel}
              </HeaderTableCell>
              <TableCell>{date?.toFormat('MM/dd/yyyy') ?? '--'}</TableCell>
            </TableRow>
            <TableRow>
              <HeaderTableCell
                sx={{
                  minWidth: '105px',
                }}
              >
                {custLocationLabel}
              </HeaderTableCell>
              <TableCell>{order.customerLocation}</TableCell>
            </TableRow>
            <TableRow>
              <HeaderTableCell
                sx={{
                  minWidth: '100px',
                }}
              >
                {custOrderLabel}
              </HeaderTableCell>
              <TableCell>{order.customerOrderNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table sx={{ mt: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>Product Description</TableCell>
              <TableCell>Units</TableCell>
              {showPrice && (
                <>
                  <TableCell>Price</TableCell>
                  <TableCell>Total</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {order.lineItems.map((lineItem) => (
              <TableRow key={lineItem.id}>
                <TableCell>{lineItem.product.description}</TableCell>
                <TableCell>{lineItem.unitsOrdered}</TableCell>
                {showPrice && (
                  <>
                    <TableCell>
                      {currencyNumberFormat.format(money.toDollars(lineItem.buyPrice ?? lineItem.sellPrice))}
                    </TableCell>
                    <TableCell>
                      {currencyNumberFormat.format(
                        money.toDollars((lineItem.buyPrice ?? lineItem.sellPrice) * lineItem.unitsOrdered)
                      )}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {hasExpenses && (
          <Table sx={{ mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>Expense Description</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.expenses.map((expense) => {
                if (expense.includedInDlvPrice) {
                  return null;
                }
                return (
                  <TableRow key={expense.id}>
                    <TableCell>{expense.description ?? mapExpenseCategoryToLabel(expense.category)}</TableCell>
                    <TableCell>
                      {currencyNumberFormat.format(money.toDollars(expense.quantity * expense.unitAmount))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </Paper>
  );
};
