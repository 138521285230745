import { Fragment } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Card, SkeletonProps, SxProps, Theme, Typography, useTheme } from '@mui/material';

import isNil from 'src/utils/isNil';
import money from 'src/utils/money';

import Hint from '../atoms/Hint';
import Skeleton from '../atoms/Skeleton';

export type MetricFormats = 'number' | 'money' | 'dollars';

const formatters: Record<MetricFormats, (value, currency) => string> = {
  number: (value) => {
    if (!isFinite(value)) {
      return '--';
    }

    return value.toLocaleString('en-US', {
      maximumFractionDigits: 2,
    });
  },
  money: (value, currency) =>
    money.toDollars(value).toLocaleString('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  dollars: (value, currency) =>
    value.toLocaleString('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
};

export type MetricDef = {
  text?: string;
  format?: MetricFormats;
  label: string;
  status?: 'success' | 'error' | 'warning' | 'neutral';
  statusMessage?: string;
};

export type MetricProps = MetricDef & {
  value: unknown;
  loading?: boolean;
  currency?: string;
  containerSx?: SxProps<Theme>;
};

const MetricIcon = ({ status, statusMessage }) => {
  const theme = useTheme();

  const colorMap = {
    success: theme.palette.success.main,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
    neutral: theme.palette.primary.light,
  };

  return (
    <>
      {status !== 'neutral' && (
        <Hint title={statusMessage}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ml: 'auto' }}>
            {status === 'error' && <ErrorIcon sx={{ color: colorMap[status] }} fontSize="small" />}
            {status === 'success' && <CheckCircleIcon sx={{ color: colorMap[status] }} fontSize="small" />}
            {status === 'warning' && <WarningIcon sx={{ color: colorMap[status] }} fontSize="small" />}
          </Box>
        </Hint>
      )}
    </>
  );
};

export function Metric({
  value,
  text,
  label,
  format = 'number',
  loading = false,
  status = 'neutral',
  statusMessage,
  currency = 'USD',
  containerSx = null,
}: MetricProps) {
  const theme = useTheme();

  if (isNil(value) || (typeof value === 'number' && isNaN(value))) {
    return null;
  }

  let displayText = formatters[format](value, currency);

  if (text) {
    displayText = `${displayText} ${text}`;
  }

  const colorMap = {
    success: theme.palette.success.main,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
    neutral: 'transparent',
  };

  const Wrapper = loading ? Skeleton : Fragment;
  const wrapperProps = loading
    ? ({
        variant: 'text',
      } as SkeletonProps)
    : {};

  return (
    <Card
      sx={{
        display: 'flex',
        minWidth: 200,
        ...containerSx,
      }}
    >
      <Box
        sx={{
          bgcolor: colorMap[status],
          height: '100%',
          width: '5px',
        }}
      />
      <Box p={1.5} width="100%">
        <Wrapper {...wrapperProps}>
          <Box display="flex" width="100%" gap={1}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'text.secondary',
              }}
              variant="subtitle2"
            >
              {label}
            </Typography>
            <MetricIcon status={status} statusMessage={statusMessage} />
          </Box>
        </Wrapper>
        <Wrapper {...wrapperProps}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: 'text.primary',
            }}
            variant="h6"
          >
            {displayText}
          </Typography>
          {!!statusMessage && (
            <Typography
              variant="caption"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 150,
              }}
            >
              {statusMessage}
            </Typography>
          )}
        </Wrapper>
      </Box>
    </Card>
  );
}
