import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import { mapSalesTermsToLabel } from 'src/lib/utils/salesTerms';
import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';

import {
  AddressBlock,
  ContactBlock,
  DateBlock,
  DocumentDetailBlock,
  Footer,
  Notes,
  OrgDetailsBlock,
  ProductsGrid,
} from '../sections';
import { SignatureLinesGroup } from '../sections/SignatureLinesGroup';
import { docStyles } from '../utils/styles';

export function MultiBOLHeader({ organization, order }) {
  const address = getPrimaryPlace(organization.places);

  const documentSlugPrefix = order.organization.salesOrderDocumentPrefix ?? 'SO';

  const customerPo = order.customerPo;
  const customer = order.businessEntity || order.customer;
  const customerContact = order.businessEntityContact || order.customerContact;

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={'BILL OF LADING'}
        orgLogoUrl={organization.logoUrl}
        orgName={organization.label}
        address={address}
        user={order?.salesPerson?.user}
      />
      <View style={docStyles.documentDetailsContainer}>
        <DocumentDetailBlock headerText={`${documentSlugPrefix} #${order.slug}`}>
          <DateBlock dateHeader={'Order Date'} date={order.submittedAt} />

          {customerPo && <Text>Cust. PO.: {customerPo}</Text>}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText={'Bill To'}>
          <ContactBlock
            orgName={customer?.name}
            contactName={customerContact?.name}
            contactEmail={customerContact?.email}
            address={order.billTo}
          />
          {/* TODO: Business Entities' Bill To location? */}
        </DocumentDetailBlock>

        <DocumentDetailBlock headerText={'Terms'}>
          <Text>{mapSalesTermsToLabel(order.salesTerms ?? order.customerSalesTerms)}</Text>
          <Text>{order.shipment.terms}</Text>
        </DocumentDetailBlock>

        {/* Add other SO related information here */}
      </View>
    </View>
  );
}

// TODO: Add type for input
export default function SOPOSourcedBOLDocument({ salesOrder, displayUnitPrice }) {
  const notes = salesOrder.notes.filter((n) => n.documentTypes.includes('BILL_OF_LADING'));

  const ordersGroups = {
    [`${salesOrder.id}-so`]: {
      type: 'salesOrder',
      ...salesOrder,
      lineItems: salesOrder.lineItems.filter((li) => !li.external),
    },
  };

  salesOrder.fulfilledByPurchaseOrders.forEach((po) => {
    ordersGroups[`${po.id}-po`] = {
      type: 'purchaseOrder',
      ...po,
      lineItems: [],
    };
  });

  const poLineItems = salesOrder.lineItems.filter((li) => li.external);

  poLineItems.forEach((li) => {
    const poId = li.purchaseOrder.id;
    const order = ordersGroups[`${poId}-po`];

    order?.lineItems.push(li);
  });

  const orders = Object.values(ordersGroups);

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <MultiBOLHeader organization={salesOrder.organization} order={salesOrder} />

        {orders.map((order) => {
          if (order?.lineItems.length === 0) {
            return null;
          }

          return (
            <View key={order.slug + order.type}>
              <View style={docStyles.pageSplitContainer}>
                <View style={docStyles.documentDetailsContainer}>
                  {order.type === 'purchaseOrder' && (
                    <DocumentDetailBlock headerText={`PO #${order.slug}`} flex={0.5}>
                      Vend. SO: ${order.vendorPo}`
                    </DocumentDetailBlock>
                  )}
                  <DocumentDetailBlock headerText={'Pick Up'}>
                    {order.shipment.shipDate && <Text>{moment(order.shipment.shipDate).format('MM/DD/YYYY')}</Text>}
                    <AddressBlock address={order.shipment.origin ?? order.shipment.place} />
                  </DocumentDetailBlock>
                  <DocumentDetailBlock headerText={'Drop Off'}>
                    {order.shipment.deliveryDate && (
                      <Text>{moment(order.shipment.deliveryDate).format('MM/DD/YYYY')}</Text>
                    )}
                    <AddressBlock address={order.shipment.destination ?? order.shipment.place} />
                  </DocumentDetailBlock>
                </View>
              </View>
              <ProductsGrid
                productLineItems={order.lineItems}
                showLineItemWeight
                showUnitPrice={displayUnitPrice}
                currency={order.currencyCode}
                orderType="SO"
                showUnitsOrdered
                showUnitsPicked
                productTemplate={salesOrder.organization.productDescriptionTemplate}
              />
            </View>
          );
        })}

        <Notes notes={notes} />

        <SignatureLinesGroup signatures={['Consignee', 'Driver', 'Receiver']} />

        <Footer />
      </Page>
    </Document>
  );
}
