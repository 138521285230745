import { Entity } from '../constants';
import { EntityMapper } from '../mappers';
import { EntityMapperType } from '../mappers/Entity';
import { AllIds, InvoiceIds, MetaIds, PayableIds, ReceiptIds } from '../constants/Entity';
import _ from 'lodash';

const entityIdKeys = Object.values<EntityMapperType>(EntityMapper).map((orderType) => orderType.id);

/**
 * Finds the existing order id and returns the id key
 */
export const getOrderIdKeyById = (objWithOrder: {
  salesOrderId?: number;
  purchaseOrderId?: number;
  workOrderId?: number;
  buySellOrderId?: number;
}) => {
  const orderIdKey = entityIdKeys.find((key) => {
    return !!objWithOrder[key as keyof typeof objWithOrder];
  });

  return orderIdKey;
};

/**
 * Finds the existing order id and returns the order type
 */
export const getOrderTypeById = (objWithOrder: {
  salesOrderId?: number;
  purchaseOrderId?: number;
  workOrderId?: number;
  buySellOrderId?: number;
}) => {
  const orderType = Object.keys(EntityMapper).find((orderType) => {
    const id = EntityMapper[orderType as Entity.Type].id;

    return !!objWithOrder[id as keyof typeof objWithOrder];
  });

  return orderType;
};

export const getOrderByPresense = (objWithOrder: {
  purchaseOrder?: unknown;
  salesOrder?: unknown;
  workOrder?: unknown;
  buySellOrder?: unknown;
}) => {
  const { purchaseOrder, salesOrder, workOrder, buySellOrder } = objWithOrder;
  const order = purchaseOrder || salesOrder || workOrder || buySellOrder;

  return order;
};

export function selectFromReceipts<T>(objWithReceipts: {
  invoice?: T;
  standardInvoice?: T;
  buySellOrderInvoice?: T;
  payable?: T;
  standardPayable?: T;
  buySellOrderPayable?: T;
  consignmentPayable?: T;
  payment?: T;
}): (T & { type: Entity.Type })[] {
  const selected: (T & { type: Entity.Type })[] = [];

  if (objWithReceipts.invoice) {
    selected.push({
      ...objWithReceipts.invoice,
      type: Entity.Type.INVOICE,
    });
  }
  if (objWithReceipts.standardInvoice) {
    selected.push({
      ...objWithReceipts.standardInvoice,
      type: Entity.Type.STANDARD_INVOICE,
    });
  }
  if (objWithReceipts.buySellOrderInvoice) {
    selected.push({
      ...objWithReceipts.buySellOrderInvoice,
      type: Entity.Type.BUY_SELL_ORDER_INVOICE,
    });
  }
  if (objWithReceipts.payable) {
    selected.push({
      ...objWithReceipts.payable,
      type: Entity.Type.PAYABLE,
    });
  }
  if (objWithReceipts.standardPayable) {
    selected.push({
      ...objWithReceipts.standardPayable,
      type: Entity.Type.STANDARD_PAYABLE,
    });
  }
  if (objWithReceipts.buySellOrderPayable) {
    selected.push({
      ...objWithReceipts.buySellOrderPayable,
      type: Entity.Type.BUY_SELL_ORDER_PAYABLE,
    });
  }
  if (objWithReceipts.consignmentPayable) {
    selected.push({
      ...objWithReceipts.consignmentPayable,
      type: Entity.Type.CONSIGNMENT_PAYABLE,
    });
  }
  if (objWithReceipts.payment) {
    selected.push({
      ...objWithReceipts.payment,
      type: Entity.Type.PAYMENT,
    });
  }

  return selected;
}

export function extractInvoiceIds(invoiceLinked: InvoiceIds): InvoiceIds {
  return _.pickBy(
    {
      invoiceId: invoiceLinked?.invoiceId,
      standardInvoiceId: invoiceLinked?.standardInvoiceId,
      buySellOrderInvoiceId: invoiceLinked?.buySellOrderInvoiceId,
    },
    _.identity
  );
}

export function extractPayableIds(payableLinked: PayableIds): PayableIds {
  return _.pickBy(
    {
      payableId: payableLinked?.payableId,
      standardPayableId: payableLinked?.standardPayableId,
      buySellOrderPayableId: payableLinked?.buySellOrderPayableId,
      consignmentPayableId: payableLinked?.consignmentPayableId,
    },
    _.identity
  );
}

export function extractReceiptIds(receiptLinked: ReceiptIds): ReceiptIds {
  return _.pickBy(
    {
      ...extractInvoiceIds(receiptLinked),
      ...extractPayableIds(receiptLinked),
    },
    _.identity
  );
}

export function extractMetaIds(metaLinked: MetaIds): MetaIds {
  return _.pickBy(
    {
      paymentId: metaLinked?.paymentId,
      lotQuantityAdjustmentId: metaLinked?.lotQuantityAdjustmentId,
    },
    _.identity
  );
}

export function extractAllIds(idLinked: AllIds): AllIds {
  return _.pickBy(
    {
      ...extractReceiptIds(idLinked),
      ...extractMetaIds(idLinked),
    },
    _.identity
  );
}
