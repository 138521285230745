import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { ModalProps } from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90vh',
  p: 2,
};

interface CustomModalProps extends Omit<ModalProps, 'title'> {
  title: React.ReactNode;
  close: () => void;
}

export default function CustomModal({ children, title, close, ...props }: Omit<CustomModalProps, 'key'>) {
  return (
    <Dialog onClose={close} {...props} maxWidth="lg" fullWidth>
      <DialogContent>
        <div className="flex pb-3">
          <div className="flex-grow text-lg">{title}</div>
          <IconButton onClick={close}>
            <Close size="small" />
          </IconButton>
        </div>
        <div className="flex-grow overflow-y-auto">{children}</div>
      </DialogContent>
    </Dialog>
  );
}
