import { BulkCreatePricingSheetEntryInput } from 'types/graphql';

import { useMutation } from '@redwoodjs/web';

import { GET_PRICING_SHEET_ENTRIES } from './usePricingSheetEntries';

const BULK_ADD_PRICING_SHEET_ENTRY = gql`
  mutation BulkCreatePricingSheetEntry($input: BulkCreatePricingSheetEntryInput!) {
    bulkCreatePricingSheetEntry(input: $input) {
      id
    }
  }
`;

export default function useBulkAddPricingSheetEntry() {
  const [bulkCreatePricingSheetEntry] = useMutation(BULK_ADD_PRICING_SHEET_ENTRY, {
    refetchQueries: [GET_PRICING_SHEET_ENTRIES],
  });

  return async (input: BulkCreatePricingSheetEntryInput) => bulkCreatePricingSheetEntry({ variables: { input } });
}
