import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import { docStyles } from '../utils/styles';

export function SignatureLines() {
  return (
    <View style={docStyles.signatureLinesContainer} wrap={false}>
      <View style={docStyles.signatureHeader}>
        <Text>SIGNED BY</Text>
      </View>

      <View style={docStyles.signatureDetailsContainer}>
        <View style={docStyles.signatureDetails}>
          <Text>Name</Text>
        </View>
        <View style={docStyles.signatureDetails}>
          <Text>Signature</Text>
        </View>
      </View>
    </View>
  );
}
