import { useEffect, useState } from 'react';

import {
  BlocksProvider,
  LayersProvider,
  PagesProvider,
  SelectorsProvider,
  StylesProvider,
  TraitsProvider,
  useEditorMaybe,
} from '@grapesjs/react';
import { Card } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import MaterialSymbol from 'src/components/atoms/MaterialSymbol';

import { MAIN_BORDER_COLOR, cx } from './common';
import CustomBlockManager from './CustomBlockManager';
import CustomLayerManager from './CustomLayerManager';
import CustomPageManager from './CustomPageManager';
import CustomSelectorManager from './CustomSelectorManager';
import CustomStyleManager from './CustomStyleManager';
import CustomTraitManager from './CustomTraitManager';

const defaultTabProps = {
  className: '!min-w-0',
};

export default function RightSidebar({ className }: React.HTMLAttributes<HTMLDivElement>) {
  const editor = useEditorMaybe();
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Card>
      <div className={cx('gjs-right-sidebar flex flex-col', className)}>
        <Tabs
          value={selectedTab}
          onChange={(_, v) => {
            if (v === 0) {
              editor?.runCommand('open-style-manager');
            }

            setSelectedTab(v);
          }}
          variant="fullWidth"
        >
          <Tab {...defaultTabProps} label={<MaterialSymbol name="format_paint" />} />
          <Tab {...defaultTabProps} label={<MaterialSymbol name="settings" />} />
          <Tab {...defaultTabProps} label={<MaterialSymbol name="stacks" />} />
          <Tab {...defaultTabProps} label={<MaterialSymbol name="grid_view" />} />
          <Tab {...defaultTabProps} label={<MaterialSymbol name="auto_stories" />} />
        </Tabs>
        <div className={cx('overflow-y-auto flex-grow border-t', MAIN_BORDER_COLOR)}>
          {selectedTab === 0 && (
            <>
              <SelectorsProvider>{(props) => <CustomSelectorManager {...props} />}</SelectorsProvider>
              <StylesProvider>{(props) => <CustomStyleManager {...props} />}</StylesProvider>
            </>
          )}
          {selectedTab === 1 && <TraitsProvider>{(props) => <CustomTraitManager {...props} />}</TraitsProvider>}
          {selectedTab === 2 && <LayersProvider>{(props) => <CustomLayerManager {...props} />}</LayersProvider>}
          {selectedTab === 3 && <BlocksProvider>{(props) => <CustomBlockManager {...props} />}</BlocksProvider>}
          {selectedTab === 4 && <PagesProvider>{(props) => <CustomPageManager {...props} />}</PagesProvider>}
        </div>
      </div>
    </Card>
  );
}
