import { PropsWithChildren } from 'react';

import { Box, CSSObject } from '@mui/system';

export function PageBody({ children, sx }: PropsWithChildren<{ sx?: CSSObject }>) {
  return (
    <Box
      id="page-body"
      sx={{
        px: 3,
        py: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
