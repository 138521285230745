import { useState } from 'react';

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Table, TableBody } from '@mui/material';
import Color from 'color';
import randomColor from 'randomcolor';

import LabelEditRow from './LabelEditRow';
import LabelPreviewRow from './LabelPreviewRow';

export default function EditLabelsModal({ open, allLabels, handleClose, handleDelete, handleSave, handleCreate }) {
  const [editingRow, setEditingRow] = useState(null);
  const [showDraft, setShowDraft] = useState(false);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
        }}
      >
        Edit Labels
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 'auto' }}
          disabled={showDraft}
          onClick={() => {
            setShowDraft(true);
            setEditingRow(null);
          }}
        >
          Add New
        </Button>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 1,
        }}
      >
        <DialogContentText
          sx={{
            px: 2,
            mb: 2,
          }}
        >
          Create, customize, and delete labels.
        </DialogContentText>
        <Table size="small">
          <TableBody>
            {allLabels.map((label, i) => {
              const isEditing = i === editingRow;

              return (
                <>
                  <LabelPreviewRow
                    key={label.id}
                    label={label}
                    index={i}
                    handleDelete={handleDelete}
                    setEditingRow={setEditingRow}
                    isEditing={isEditing}
                  />
                  <LabelEditRow
                    id={label.id}
                    initialColor={label.color}
                    initialLabel={label.label}
                    initialDescription={label.description}
                    isEditing={isEditing && !showDraft}
                    handleCancel={() => {
                      setEditingRow(null);
                    }}
                    handleSave={handleSave}
                  />
                </>
              );
            })}
            <LabelEditRow
              id={'draft-row'}
              initialColor={Color(randomColor()).lightness(90).hex()}
              initialLabel=""
              initialDescription=""
              isEditing={showDraft}
              isDraft
              handleCancel={() => {
                setShowDraft(false);
              }}
              handleSave={(label) => {
                handleCreate(label);
                setShowDraft(false);
              }}
            />
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}
