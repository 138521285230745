export const GET_MEMBERSHIPS = gql`
  query fetchMemberships {
    memberships {
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
