import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page, Text, View } from '@react-pdf/renderer';

import { USD } from 'src/lib/money/usd';
import { mapPaymentTermsToLabel } from 'src/lib/utils/standardInvoicePaymentTerms';
import computePaymentDueDate from 'src/services/utils/computePaymentDueDate';
import { stripSlugPrefix } from 'src/services/utils/generateInvoiceOrPayableSlug';
import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';

import {
  ContactBlock,
  DateBlock,
  DocumentDetailBlock,
  Expenses,
  Footer,
  Notes,
  OrgDetailsBlock,
  TermsAndTotals,
} from '../sections';
import { docStyles } from '../utils/styles';

type StandardInvoiceTemplateProps = Prisma.StandardInvoiceGetPayload<{
  include: {
    organization: { include: { places: true } };
    billTo: { include: { places: true } };
    billToContact: true;
    expenses: true;
    notes: {
      include: {
        membership: {
          include: {
            user: true;
          };
        };
      };
    };
  };
}>;

export function StandardInvoiceTemplate(standardInvoice: StandardInvoiceTemplateProps) {
  const invoiceSlugWithoutPrefix = stripSlugPrefix(standardInvoice.slug);
  const address = getPrimaryPlace(standardInvoice.organization.places);

  // StandardInvoice.totalAmountDue is only calculated when the invoice transitions to Open status. If previewing the
  // document while in Draft, totalAmountDue is null and we calculate it manually here.
  const totalAmountDue = standardInvoice.totalAmountDue
    ? USD.fromCents(standardInvoice.totalAmountDue)
    : standardInvoice.expenses.reduce((total, expense) => {
        return total.add(USD.fromCents(expense.unitAmount).multiply(expense.quantity));
      }, USD.fromCents(0));

  const paymentDueDate = computePaymentDueDate(standardInvoice.invoiceDate ?? new Date(), standardInvoice.paymentTerms);

  const notes = standardInvoice.notes?.filter((note) => note.documentTypes.includes('INVOICE'));
  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        {/* HEADER */}
        <View style={docStyles.pageHeaderContainer}>
          <OrgDetailsBlock
            documentName={'INVOICE'}
            orgLogoUrl={standardInvoice.organization.logoUrl}
            orgName={standardInvoice.organization.label}
            address={address}
          />

          <View style={docStyles.documentDetailsContainer}>
            <DocumentDetailBlock headerText={`Invoice #${invoiceSlugWithoutPrefix}`}>
              <DateBlock dateHeader={'Invoice Date'} date={standardInvoice.invoiceDate ?? new Date()} />
            </DocumentDetailBlock>

            <DocumentDetailBlock headerText={'Payment Details'}>
              <Text>{`Payment Terms: ${mapPaymentTermsToLabel(standardInvoice.paymentTerms)}`}</Text>
              <Text>{`Due by: ${paymentDueDate.toDateString()}`}</Text>
            </DocumentDetailBlock>

            <DocumentDetailBlock headerText={'Bill To'}>
              <ContactBlock
                orgName={standardInvoice.billTo?.name}
                contactName={standardInvoice.billToContact?.name}
                contactEmail={standardInvoice.billToContact?.email}
              />
            </DocumentDetailBlock>
          </View>
        </View>

        <Expenses expenses={standardInvoice.expenses} />

        <TermsAndTotals
          grandTotal={totalAmountDue.string}
          includePacaTerms={false} // Standard invoices are only used for non-produce items, so PACA terms are not needed
        />

        {notes?.length > 0 && <Notes notes={notes} />}

        <Footer />
      </Page>
    </Document>
  );
}
