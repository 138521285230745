export const UpdateSlug = gql`
  mutation updateSalesOrderSlug($id: Int!, $slug: String!) {
    updateSalesOrderSlug(id: $id, slug: $slug) {
      id
      slug
    }
  }
`;

export const UpdateStatus = gql`
  mutation SubmitSalesOrderStatus($id: Int!, $input: UpdateSalesOrderStatusInput!) {
    #
    updateSalesOrderStatus(id: $id, input: $input) {
      id
      status
    }
  }
`;

export const UpdateOrder = gql`
  mutation UpdateSalesOrder($id: Int!, $input: UpdateSalesOrderInput!) {
    updateSalesOrder(id: $id, input: $input) {
      id
      status
    }
  }
`;

export const DuplicateOrder = gql`
  mutation DuplicateSalesOrder($id: Int!, $templateInput: DuplicateOrderTemplateInput) {
    duplicateSalesOrder(id: $id, templateInput: $templateInput) {
      id
      slug
    }
  }
`;

export const DeleteOrder = gql`
  mutation DeleteSalesOrder($id: Int!) {
    deleteSalesOrder(id: $id) {
      id
    }
  }
`;

export const UpdateOrderFlagged = gql`
  mutation UpdateSalesOrderFlagged($id: Int!, $input: FlagSalesOrderInput!) {
    updateSalesOrderFlagged(id: $id, input: $input) {
      id
    }
  }
`;

export const PollLineItemForLinkedPo = gql`
  query PollLineItemForLinkedPo($id: Int!) {
    lineItem(id: $id) {
      id
      external
      purchaseOrder {
        id
        slug
        status
        expenses {
          ...ExpenseAttributes
        }
        businessEntity {
          id
          name
        }
      }
    }
  }
`;
