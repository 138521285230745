import { useState } from 'react';

import { Clear as ClearIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, TextField } from '@mui/material';

type Props = {
  searchState?: string;
  onSearch: (search: string) => void;
};

export default function ClearableSearch({ searchState, onSearch }: Props) {
  const [search, setSearch] = useState(searchState ?? '');

  return (
    <TextField
      className="max-w-[400px] flex-grow"
      size="small"
      placeholder="Search"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onKeyUp={(e) => e.key === 'Enter' && onSearch(search)}
      slotProps={{
        input: {
          startAdornment: <SearchIcon className="text-grey-500 mr-2" />,
          endAdornment: search && (
            <IconButton
              onClick={() => {
                setSearch('');
                onSearch('');
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        },
      }}
    />
  );
}
