import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

import { Link as RedwoodLink } from '@redwoodjs/router';

export type LinkProps = MuiLinkProps & {
  label: string;
  color?: string;
  to?: string | ((routeArgs) => string);
  routeArgs?: Record<string, unknown>;
  icon?: React.ReactNode;
};

const safeLink = (to: string | ((routeArgs) => string), routeArgs?: Record<string, unknown>) => {
  if (typeof to === 'function') {
    const validArgs = Object.keys(routeArgs).every((key) => routeArgs[key]);

    if (!validArgs) {
      console.warn('Invalid route arguments', routeArgs);

      return {};
    }

    return {
      to: to(routeArgs),
      component: RedwoodLink,
    };
  }

  return {
    to,
    component: RedwoodLink,
  };
};

export default function Link({
  label,
  color,
  sx = {},
  fontSize = '14px',
  to,
  routeArgs,
  href,
  icon,
  ...linkProps
}: LinkProps) {
  const routeLink = href ? { href } : safeLink(to, routeArgs);

  return (
    <MuiLink
      {...linkProps}
      sx={{
        textDecoration: 'underline',
        textUnderlineOffset: '4px',
        cursor: 'pointer',
        fontSize,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        ...sx,
      }}
      {...routeLink}
      color={color || 'primary.main'}
    >
      {label}
      {icon}
    </MuiLink>
  );
}
