import { Select, styled } from '@mui/material';
import Color from 'color';

export const ChipSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    borderRadius: '9999px !important',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: Color(theme.palette.primary.main).alpha(0.22).toString(),
    '&:hover': {
      background: Color(theme.palette.primary.main).alpha(0.33).toString(),
    },
    padding: '0.25rem 0.5rem 0.25rem 0.5rem',
  },
}));
