import { Close, Label as LabelIcon } from '@mui/icons-material';
import { Box, Chip, Collapse, IconButton, Typography } from '@mui/material';
import Color from 'color';

import Hint from './Hint';

export default function Label({
  label,
  color,
  description,
  icon,
  onRemove,
}: {
  label: string;
  color: string;
  description: string;
  icon?: JSX.Element;
  onRemove?: () => void;
}) {
  const [isHovered, setIsHovered] = React.useState(false);
  const baseColor = Color(color);
  const iconColor = baseColor.darken(0.5).string();
  const textColor = baseColor.isDark() ? baseColor.lighten(1.5).string() : baseColor.darken(0.75).string();

  return (
    <Hint title={description}>
      <Chip
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        size="small"
        sx={{
          backgroundColor: baseColor.string(),
          mx: '3px',
        }}
        label={
          <Box display="flex" gap={0.5} alignItems="center">
            {icon ?? (
              <LabelIcon
                sx={{
                  fontSize: '16px',
                  alignSelf: 'center',
                  color: iconColor,
                }}
              />
            )}
            <Typography fontSize="13px" color={textColor} height="16px">
              {label}
            </Typography>
            {onRemove && (
              <Collapse in={isHovered} orientation="horizontal">
                <IconButton
                  size="small"
                  sx={{
                    width: '20px',
                    height: '20px',
                  }}
                  onClick={() => onRemove()}
                >
                  <Close
                    sx={{
                      fontSize: '14px',
                    }}
                  />
                </IconButton>
              </Collapse>
            )}
          </Box>
        }
      />
    </Hint>
  );
}
