import { useCallback, useState } from 'react';

import { useEditorMaybe } from '@grapesjs/react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material';
import { z, ZodError } from 'zod';

import Autocomplete from 'src/components/atoms/Autocomplete';

import { documentTypeGroups, documentTypeOptions } from '../pages/DocumentEditorPage/DocumentEditorPage';

export const DocumentTypeSelection = ({ value, setDocumentType, ...props }) => {
  return (
    <Autocomplete
      options={documentTypeOptions}
      value={value}
      onChange={(event, newValue) => {
        setDocumentType(newValue);
      }}
      getOptionLabel={(option) => option.label}
      groupBy={(option) => {
        return documentTypeGroups[option.entity];
      }}
      size="small"
      {...props}
    />
  );
};

const zodModel = z.array(
  z.object(
    {
      key: z.string({
        required_error: 'Key is required',
      }),
      defaultValue: z.any().optional(),
      type: z.enum(['string', 'number', 'boolean', 'object', 'array'], {
        required_error: 'Type is required',
      }),
    },
    {
      required_error: 'Object must have key, type, and optionally defaultValue',
    }
  ),
  {
    required_error: 'Array of objects is required',
  }
);

export default function DocumentStorageSaveModal({
  open,
  onClose,
  documentName,
  documentType,
  setDocumentName,
  setDocumentType,
  generationOptions,
  setGenerationOptions,
}) {
  const editor = useEditorMaybe();
  const [optionsError, setOptionsError] = useState(null);

  const handleSaveConfirm = useCallback(() => {
    editor.store({
      remote: true,
      documentName,
      documentType,
      generationOptions: JSON.parse(generationOptions || {}),
    });
    onClose();
  }, [editor, documentName, documentType, generationOptions, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent>
        <DialogContentText>Save changes to document?</DialogContentText>
        <Box p={2} display="flex" flexDirection="column" gap={2}>
          <TextField
            fullWidth
            label="Document Name"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
          />
          <TextField
            multiline
            minRows={4}
            fullWidth
            value={generationOptions || ''}
            error={!!optionsError}
            helperText={optionsError}
            onChange={(e) => {
              const value = e.target.value ?? '{}';

              setGenerationOptions(e.target.value);

              if (value) {
                try {
                  zodModel.parse(JSON.parse(value));

                  setOptionsError(null);
                } catch (e) {
                  setOptionsError(`Invalid JSON: ${e.issues?.[0].message ?? e}`);
                }
              }
            }}
          />
          <DocumentTypeSelection
            textFieldProps={{
              fullWidth: true,
            }}
            width="100%"
            size="large"
            label="Document Type"
            value={documentType}
            setDocumentType={setDocumentType}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!!optionsError} onClick={handleSaveConfirm}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
