import { useEffect } from 'react';

const eventName = 'barcode-scanned';

// TODO: Add more options, and allow user to configure them for broader support
type ScannerOptions = {
  timeOut?: number;
  scanTerminatorKey?: string;
  minLength?: number;
};

class BarcodeScanner extends EventTarget {
  timeOut: number;
  minLength: number;
  scanTerminatorKey: string;
  timer: number;
  capture: string;
  target: EventTarget;

  constructor(options: ScannerOptions = {}) {
    super();

    options = {
      timeOut: 130,
      scanTerminatorKey: 'Enter',
      minLength: 5,
      ...options,
    };

    this.timeOut = options.timeOut;
    this.scanTerminatorKey = options.scanTerminatorKey;
    this.minLength = options.minLength;
    this.timer = Date.now();
    this.capture = '';
    this.target = new EventTarget();
    document.addEventListener('keypress', this.keypress.bind(this));
  }

  keypress(e) {
    const now = Date.now();

    // If out timer is out, we need to reset because it was not a barcode
    if (now - this.timer > this.timeOut) {
      this.reset();
    }

    // It seems we are still fast enough to be a barcode, so add to capture
    const sinceFirst = now - this.timer;
    if (sinceFirst < this.timeOut) {
      if (e.key === this.scanTerminatorKey && this.capture.length >= this.minLength) {
        e.stopPropagation();
        e.preventDefault();
        this.dispatchScanEvent();
        return;
      }

      this.capture += e.key;
    }
  }

  dispatchScanEvent() {
    const event = new CustomEvent(eventName, { detail: this.capture });
    this.dispatchEvent(event);
    this.reset();
  }

  reset() {
    this.timer = Date.now();
    this.capture = '';
  }
}

export default {
  event: eventName,
  Target: BarcodeScanner,
};

const scanner = new BarcodeScanner({ minLength: 3 });
export function useBarcodeScanner(onScan: (barcode: string) => void) {
  useEffect(() => {
    const scanEvent = (e) => {
      onScan(e.detail);
    };

    scanner.addEventListener(eventName, scanEvent);

    return () => {
      scanner.removeEventListener(eventName, scanEvent);
    };
  }, [onScan]);

  return null;
}
