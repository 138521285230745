import { useMemo } from 'react';

import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import seedrandom from 'seedrandom';

import Skeleton from 'src/components/atoms/Skeleton';

import DataGrid, { DataGridProps, GridColObj } from '../grids/DataGrid';

export type SkeletonDataGridProps = Omit<DataGridProps, 'rows'> & {
  rowCount: number;
  enterNewText?: string;
  DataGridComponent?: React.ComponentType<DataGridProps>;
  sampleRowCreator?: (i: number) => Record<string, any>;
  idGetter?: (i: number) => string | number;
};

const SkeletonCell = ({
  colDef,
  widths,
  rowNode,
}: GridRenderCellParams & {
  widths?: number[];
}) => {
  const { type, minWidth = 100 } = colDef;
  const randWidth = useMemo(() => {
    const partialWidth = minWidth / (type === 'number' ? 3.5 : 2);
    const restWidth = minWidth - partialWidth;
    const randomWidth = widths[rowNode.id];

    return randomWidth * partialWidth + restWidth * 0.9;
  }, [minWidth, type, rowNode, widths]);

  if (type === 'actions') {
    return <Skeleton width={20} height={20} variant={'circular'} />;
  }

  return <Skeleton width={randWidth} variant={'text'} />;
};

export const withSkeletonColumnCells = (columns: GridColObj, rows: number, idGetter = (i) => i) => {
  const keys = Object.keys(columns);
  const rng = seedrandom(keys[0]);
  const skeletonColumns = keys.map((key) => {
    const column = columns[key];
    const widths = {};

    for (let i = 0; i < rows; i++) {
      widths[idGetter(i)] = rng();
    }

    return {
      [key]: {
        ...column,
        renderHeader: (params) => (column.renderHeader ? <Skeleton>{column.renderHeader(params)}</Skeleton> : null),
        renderCell: (params) => <SkeletonCell {...params} widths={widths} />,
      },
    };
  });

  return Object.assign({}, ...skeletonColumns);
};

export default function SkeletonDataGrid({
  columns,
  enterNewText,
  rowCount,
  DataGridComponent = DataGrid,
  groupingColDef,
  sampleRowCreator = (i) => ({ id: i }),
  idGetter,
  ...props
}: SkeletonDataGridProps) {
  const skeletonized = useMemo(() => withSkeletonColumnCells(columns, rowCount, idGetter), [columns, rowCount]);

  const rows = [];

  for (let i = 0; i < rowCount; i++) {
    rows.push(sampleRowCreator(i));
  }

  return (
    <>
      <DataGridComponent
        {...props}
        columns={skeletonized}
        sortModel={[]}
        rows={rows}
        groupingColDef={{
          ...groupingColDef,
          renderHeader: (params) =>
            groupingColDef.renderHeader ? <Skeleton>{groupingColDef.renderHeader(params)}</Skeleton> : null,
          renderCell: (params) =>
            groupingColDef.renderHeader ? <Skeleton>{groupingColDef.renderHeader(params)}</Skeleton> : null,
        }}
      />
      {enterNewText && (
        <Skeleton sx={{ ml: 1 }}>
          <Button variant="contained">{enterNewText}</Button>
        </Skeleton>
      )}
    </>
  );
}
