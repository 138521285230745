import { routes } from '@redwoodjs/router';

import Link from 'src/components/atoms/Link';

export const getInvoiceRouteInfoByType = (type: string, slug: string) => {
  let route = routes.invoice({ slug });
  const labelPrefix = 'Invoice';

  if (type === 'STANDARD' || type === 'StandardInvoice') {
    route = routes.invoice({ slug, type: 'standard' });
  }

  if (type === 'BSO') {
    route = routes.invoice({ slug, type: 'bso' });
  }

  return { route, labelPrefix };
};

export default function InvoiceLink({ type, slug }) {
  const { route, labelPrefix } = getInvoiceRouteInfoByType(type, slug);

  return <Link to={route} label={`${labelPrefix} #${slug}`} />;
}
