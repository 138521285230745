import { registerFragment } from '@redwoodjs/web/apollo';

registerFragment(gql`
  fragment DocumentAttributes on Document {
    id
    title
    url
    type
    createdAt
    uploadedBy {
      user {
        id
        firstName
        lastName
      }
    }
    documentTemplateId
    documentTemplate {
      name
    }
  }
`);

registerFragment(gql`
  fragment ExpenseAttributes on Expense {
    id
    description
    businessEntity {
      id
      name
    }
    businessEntityId
    accountLevel
    expenseType {
      id
      name
      receivablesAccountId
      payablesAccountId
    }
    category
    unitOfMeasure
    quantity
    unitAmount
    currencyAmount
    totalAmount
    parentExpenseId
    purchaseOrderId
    buySellOrderId
    salesOrderId
    workOrderId
    proportion
    childrenExpenses {
      id
    }
    loadId
    includedInDlvPrice
    parentExpenseId
    locked

    ledgerAccount {
      id
      accountName
      accountType
      accountNumber
    }
  }
`);

registerFragment(gql`
  fragment ExpenseFinanceEntities on Expense {
    allInvoices {
      ... on Invoice {
        id
        slug
      }
      ... on BuySellOrderInvoice {
        id
        slug
      }
      ... on StandardInvoice {
        id
        slug
      }
    }
    allPayables {
      ... on Payable {
        id
        slug
      }
      ... on BuySellOrderPayable {
        id
        slug
      }
      ... on StandardPayable {
        id
        slug
      }
      ... on ConsignmentPayable {
        id
        slug
      }
    }
  }
`);

registerFragment(gql`
  fragment LotAttributes on Lot {
    id
    slug
    status
    quantityAvailable
    quantity
    landedCost
    profit
    receiveDate
    warehouseId
    profit

    lotSupplierSources {
      businessEntity {
        id
        name
      }
      place {
        id
        description
      }
      type
    }

    productionRunComponents {
      id
      createdAt
      type

      productionRun {
        id
        slug
        completedAt
      }
    }

    fieldTicketIds
    harvestLocations
  }
`);

registerFragment(gql`
  fragment LotSourceAttributes on Lot {
    lotSupplierSources {
      businessEntity {
        id
        name
      }
      place {
        id
        description
      }
      type
    }

    fieldTicketIds
    harvestLocations
  }
`);

registerFragment(gql`
  fragment LotMetrics on Lot {
    metrics {
      repacked
      dumped
      shipped
      received
    }
  }
`);

registerFragment(gql`
  fragment LotRepackChildren on Lot {
    repackChildren {
      id
      isRoot
      proportionOfSourceUtilized
      proportionComposedOfSource

      lot {
        ...LotAttributes
        ...LotMetrics
        ...LotSourceAttributes
      }
      sourceLot {
        ...LotAttributes
        ...LotMetrics
      }
    }
  }
`);

registerFragment(gql`
  fragment PalletTagAttributes on PalletTag {
    id
    lotId
    # status
    initialQuantity
    cartonQuantity
    tagNumber
    tagLayout {
      id
      name
      schema
      tagType
    }
    weight
    initialWeight
    printed
    warehouseLocation {
      id
      slug
      description
      tags
    }
    quantityAdjustments {
      delta
      weightDelta
      initialDelta
      initialWeightDelta
      adjustmentType
      createdAt
      lotQuantityAdjustment {
        id
      }
      adjustedBy {
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`);

registerFragment(gql`
  fragment NoteAttributes on Note {
    id
    text
    createdAt
    external
    documentTypes
    membership {
      user {
        id
        firstName
        lastName
        email
      }
    }
    customDocumentTypes {
      documentTemplateId
    }
  }
`);

registerFragment(gql`
  fragment OrganizationAttributes on Organization {
    id
    label
    productDescriptionTemplate
    logoUrl
    salesOrderDocumentPrefix
    closedAt
  }
`);

registerFragment(gql`
  fragment PlaceAttributes on Place {
    id
    description

    streetAddress1
    streetAddress2

    city
    state
    addressCountry
    postalCode

    addressRoles

    phone
  }
`);

registerFragment(gql`
  fragment ProductAttributes on Product {
    id
    description
    countryOfOrigin
    weightPerInventoryUnit
    cartonsPerPallet
    grade
    variableWeight
    productCode
    organic
    commodity {
      name
    }
    commodityStyle {
      name
    }
    commoditySize {
      name
    }
    commodityUnit {
      name
    }
    commodityLabel {
      name
    }
    gtinCode
    customFields
  }
`);

registerFragment(gql`
  fragment ReceiptAdjustmentAttributes on ReceiptAdjustment {
    id
    amount
    type
    reason
    memo

    ledgerEntryGroup {
      lines {
        account {
          name
        }
      }
    }

    balancingAccount {
      accountName
    }
  }
`);

registerFragment(gql`
  fragment LineItemAttributes on LineItem {
    id
    salesOrderId
    purchaseOrderId
    unitsOrdered
    unitsReceived
    unitsPicked
    unitsShipped
    currencyPrice
    buyPrice
    buyCurrencyPrice
    sellPrice
    sellCurrencyPrice
    weightReceived
    profit
    parentLineItemId
    external
    deliveredPrice
    deliveredCurrencyPrice

    palletTagsOnLineItem {
      palletTag {
        id
        tagNumber
        cartonQuantity
      }
      quantity
    }

    lot {
      ...LotRepackChildren

      status
      id
      grade
      slug
      landedCost
      profit
      initialQuantity
      initialWeight
      warehouseId
      quantity

      lotOrderSources {
        workOrderId
      }

      product {
        description
        landedCost
        commodity {
          name
        }
      }
    }
    ediProductMapping {
      id
      ediProductDescription
      ediProductId
    }
    product {
      description
      organic
      weightPerInventoryUnit
      variableWeight
      landedCost
      productId: id
      cartonsPerPallet
      countryOfOrigin
      commodity {
        name
        id
      }
      commodityStyle {
        name
      }
      commoditySize {
        name
      }
      commodityUnit {
        name
      }
      commodityLabel {
        name
      }
      grade
      metrics {
        netAvailable
      }
    }
  }
`);

registerFragment(gql`
  fragment SalesOrderDocumentAttributes on SalesOrder {
    organization {
      label
      logoUrl
      primaryLocation {
        ...PlaceAttributes
      }
    }
    slug
    shipDate
    deliveryDate
    submittedAt
    salesTerms
    status
    customerPo
    currencyCode
    customFields
    salesPerson {
      id
      user {
        id
        firstName
        lastName
        email
      }
    }
    businessEntity {
      id
      name
    }
    businessEntityContact {
      id
      name
      email
      phone
    }
    billTo {
      ...PlaceAttributes
    }
    subsidiary {
      ...PlaceAttributes
    }
    exchangeRate
    shipment {
      terms
      shipDate
      deliveryDate
      freightCompany {
        id
        name
      }
      origin {
        ...PlaceAttributes
      }
      originId
      place {
        ...PlaceAttributes
      }
      placeId
      customFields
      temperatureMin
      temperatureMax
    }
    lineItems {
      ...LineItemAttributes
    }
    expenses {
      ...ExpenseAttributes
    }
    notes {
      ...NoteAttributes
    }
  }
`);

registerFragment(gql`
  fragment PaymentInvoicePayableRelations on Payment {
    parentPaymentId
    invoiceId
    ledgerAccountPath
    invoice {
      id
      slug
      invoiceDate: sentAt
      totalAmountDue
      totalPaid
      salesOrder {
        id
        slug
        customerPo
      }
      referenceNumber
    }
    buySellOrderInvoiceId
    buySellOrderInvoice {
      id
      slug
      invoiceDate: sentAt
      totalAmountDue
      totalPaid
      buySellOrder {
        id
        slug
        customerPo
      }
      referenceNumber
    }
    standardInvoiceId
    standardInvoice {
      id
      slug
      invoiceDate
      totalAmountDue
      totalPaid
      purchaseOrder {
        id
        slug
        vendorPo # This is actually the vendor's SO # despite the name
      }
      salesOrder {
        id
        slug
        customerPo
      }
      buySellOrder {
        id
        slug
        vendorSo
      }
      workOrder {
        id
        slug
        fieldTicketId
      }
      referenceNumber: referencePayableNum
    }
    payableId
    payable {
      id
      slug
      invoiceDate: receivedAt
      totalAmountDue
      totalPaid
      purchaseOrder {
        id
        slug
        vendorPo # This is actually the vendor's SO # despite the name
      }
      referenceNumber
    }
    buySellOrderPayableId
    buySellOrderPayable {
      id
      slug
      invoiceDate: receivedAt
      totalAmountDue
      totalPaid
      buySellOrder {
        id
        slug
        vendorSo
      }
      referenceNumber
    }
    consignmentPayableId
    consignmentPayable {
      id
      slug
      invoiceDate: createdAt
      totalAmountDue
      totalPaid
      workOrder {
        id
        slug
        fieldTicketId
      }
      referenceNumber
    }
    standardPayableId
    standardPayable {
      id
      slug
      invoiceDate
      totalAmountDue
      totalPaid
      purchaseOrder {
        id
        slug
        vendorPo # This is actually the vendor's SO # despite the name
      }
      salesOrder {
        id
        slug
        customerPo
      }
      buySellOrder {
        id
        slug
        vendorSo
      }
      workOrder {
        id
        slug
        fieldTicketId
      }
      referenceNumber: referenceInvoiceNum
    }
  }
`);
