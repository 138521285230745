import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { JSPrintManager, WSStatus } from 'jsprintmanager';
import { useFlags } from 'launchdarkly-react-client-sdk';

export enum PrintManagerStatus {
  DISABLED,
  CONNECTING,
  CONNECTED,
  FAILED,
}

type ConfiguredPrinter = {
  name: string;
  paper: string;
  paperSize: {
    width: number;
    height: number;
    dpi: number;
  };
  rotation?: string;
  offset?: {
    x: number;
    y: number;
  };
  deviceName: string;
};

type PrintManagerContext = {
  status: PrintManagerStatus;
  printers: ConfiguredPrinter[];
};

export const PrintManagerContext = createContext<PrintManagerContext>({
  status: PrintManagerStatus.DISABLED,
  printers: [],
});

const handleSocketOpen = async (printConfig): Promise<ConfiguredPrinter[]> => {
  const printers = (await JSPrintManager.getPrinters()) as string[];
  const allowedPrinters = printers.filter((printer) => !printConfig.excludeList?.includes(printer));

  const withPages = await Promise.all(
    allowedPrinters.map((name) => JSPrintManager.getDefaultPaperName(name).then((paper) => ({ name, paper })))
  );

  const withPaperSizes = await Promise.all(
    withPages.map((printer) =>
      JSPrintManager.getPaperInfo(printer.name, printer.paper).then((paperSize) => ({ ...printer, paperSize }))
    )
  );

  const configuredPrinters = withPaperSizes.map((printer) => {
    if (printConfig.printers?.[printer.name]) {
      return { ...printer, ...printConfig.printers[printer.name], deviceName: printer.name };
    }

    return { ...printer, deviceName: printer.name };
  });

  return configuredPrinters;
};

export default function PrintManagerProvider({ children }: PropsWithChildren) {
  const [printers, setPrinters] = useState<ConfiguredPrinter[]>([]);
  const [status, setStatus] = useState(PrintManagerStatus.DISABLED);

  const flags = useFlags();
  const printConfig = flags['printManagerConfiguration'];

  const enabled = printConfig?.enabled;
  const socketStatus = JSPrintManager.websocket_status;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (JSPrintManager.websocket_status == WSStatus.Open) {
      return;
    }

    JSPrintManager.license_url = window.location.origin + '/.redwood/functions/jspm';

    JSPrintManager.auto_reconnect = true;

    setStatus(PrintManagerStatus.CONNECTING);

    setTimeout(
      () => setStatus((status) => (status === PrintManagerStatus.CONNECTING ? PrintManagerStatus.FAILED : status)),
      5000
    );

    JSPrintManager.start(true, printConfig.host, printConfig.port).catch((e) => {
      console.error('JS Print Manager:', e);
    });

    JSPrintManager.WS.onStatusChanged = function () {
      console.debug(`JS Print Manager: Status updated to ${JSPrintManager.websocket_status}`);

      switch (JSPrintManager.websocket_status) {
        case WSStatus.Open: {
          handleSocketOpen(printConfig).then((printers) => {
            console.debug('JS Print Manager: Found Printers', printers);

            setPrinters(printers);
          });

          setStatus(PrintManagerStatus.CONNECTED);
          break;
        }
        case WSStatus.Closed:
        case WSStatus.Blocked:
          setStatus(PrintManagerStatus.FAILED);
          break;
      }
    };
  }, [socketStatus, enabled, printConfig]);

  return <PrintManagerContext.Provider value={{ printers, status }}>{children}</PrintManagerContext.Provider>;
}

export const usePrintManager = () => useContext(PrintManagerContext);
