export const GET_LEDGER_ACCOUNT_DETAILS = gql`
  query GetLedgerAccountDetails($ledgerAccountKeys: [String!]!) {
    chartOfAccountsPage(ledgerAccountKeys: $ledgerAccountKeys) {
      path
      number
      name
      type
      ownBalance
      childBalance
      isPhysical
    }
  }
`;
