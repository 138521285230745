import CalendarIcon from '@mui/icons-material/CalendarToday';
import { Box, Chip, Divider, List, ListItem } from '@mui/material';
import { DateTime } from 'luxon';

import { MenuDropDown } from 'src/components/containers/grids/Toolbar';

import LuxonStaticDateRangePicker from './LuxonStaticDateRangePicker';

export const dateRangeShortcuts = [
  {
    label: 'This Week',
    getRange: () => [DateTime.local().startOf('week'), DateTime.local()],
  },
  {
    label: 'Last Week',
    getRange: () => [
      DateTime.local().minus({ weeks: 1 }).startOf('week'),
      DateTime.local().minus({ weeks: 1 }).endOf('week'),
    ],
  },
  {
    label: 'Last 7 Days',
    getRange: () => [DateTime.local().minus({ days: 7 }), DateTime.local()],
  },
  {
    label: 'Last Month',
    getRange: () => [
      DateTime.local().minus({ months: 1 }).startOf('month'),
      DateTime.local().minus({ months: 1 }).endOf('month'),
    ],
  },
  {
    label: 'Last Quarter',
    getRange: () => [
      DateTime.local().minus({ quarters: 1 }).startOf('quarter'),
      DateTime.local().minus({ quarters: 1 }).endOf('quarter'),
    ],
  },
  {
    label: 'QTD',
    getRange: () => [DateTime.local().startOf('quarter'), DateTime.local()],
  },
  {
    label: 'MTD',
    getRange: () => [DateTime.local().startOf('month'), DateTime.local()],
  },
  {
    label: 'YTD',
    getRange: () => [DateTime.local().startOf('year'), DateTime.local()],
  },
];

export type DateRange = [DateTime, DateTime];

export default function DateRangePicker({
  setDateFilter,
  dateFilter,
  showAllTime = false,
  hint,
}: {
  setDateFilter: (dateRange: DateRange) => void;
  dateFilter: DateRange;
  showAllTime?: boolean;
  hint?: string;
}) {
  return (
    <MenuDropDown icon={<CalendarIcon />} hint={hint ?? 'Select date range'}>
      <Box display="flex">
        <List
          dense
          sx={(theme) => ({
            p: theme.spacing(2),
            pr: 0,
            '& .MuiListItem-root': {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
          })}
        >
          {
            // If showAllTime is true, show the "All Time" option
            showAllTime && (
              <ListItem>
                <Chip
                  label="All Time"
                  onClick={() => {
                    setDateFilter([null, null]);
                  }}
                />
              </ListItem>
            )
          }
          {dateRangeShortcuts.map((item) => {
            return (
              <ListItem key={item.label}>
                <Chip
                  label={item.label}
                  onClick={() => {
                    setDateFilter(item.getRange() as DateRange);
                  }}
                />
              </ListItem>
            );
          })}
        </List>
        <Divider orientation="vertical" />
        <Box my="auto">
          <LuxonStaticDateRangePicker
            onChange={([start, end]) => {
              setDateFilter([start, end]);
            }}
            value={dateFilter}
            maxDate={DateTime.local()}
            calendars={2}
            renderInput={() => null} // required field, but not needed.
          />
        </Box>
      </Box>
    </MenuDropDown>
  );
}
