export const HierarchyBottomIcon = (
  <svg width="47" height="100%" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      x1="16.5"
      y1="0.5"
      x2="16.5"
      y2="47.5"
      stroke="#9E9E9E"
      strokeLinecap="square"
      strokeDasharray="1 2"
      d="M16.156 -250L16.135 23.5"
      strokeWidth="0.9791666666666666"
    />
    <path
      x1="42.5"
      y1="24"
      x2="17.5"
      y2="24"
      stroke="#9E9E9E"
      strokeLinecap="square"
      strokeDasharray="1 2"
      d="M41.615 23.5L17.135 23.5"
      strokeWidth="0.9791666666666666"
    />
    <path
      cx="44"
      cy="24"
      r="4"
      fill="#9E9E9E"
      d="M47 23.5A3.917 3.917 0 0 1 43.083 27.417A3.917 3.917 0 0 1 39.167 23.5A3.917 3.917 0 0 1 47 23.5z"
    />
  </svg>
);

export const HierarchyMiddleIcon = (
  <svg width="47" height="100%" viewBox="0 0 47 47" fill="#ff0000" xmlns="http://www.w3.org/2000/svg" overflow="clip">
    <path
      x1="16.5"
      y1="-100"
      x2="16.5"
      y2="100"
      stroke="#9E9E9E"
      strokeLinecap="square"
      strokeDasharray="1 2"
      d="M16.156 -250L16.156 250"
      strokeWidth="0.9791666666666666"
    />
    <path
      x1="42.5"
      y1="24"
      x2="17.5"
      y2="24"
      stroke="#9E9E9E"
      strokeLinecap="square"
      strokeDasharray="1 2"
      d="M41.615 23.5L17.135 23.5"
      strokeWidth="0.9791666666666666"
    />
    <path
      cx="44"
      cy="24"
      r="4"
      fill="#9E9E9E"
      d="M47 23.5A3.917 3.917 0 0 1 43.083 27.417A3.917 3.917 0 0 1 39.167 23.5A3.917 3.917 0 0 1 47 23.5z"
    />
  </svg>
);

export const HierarchyTopIcon = (
  <svg width="47" height="100%" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      x1="16.5"
      y1="-100"
      x2="16.5"
      y2="100"
      stroke="#9E9E9E"
      strokeLinecap="square"
      strokeDasharray="1 2"
      d="M16.156 -250L16.156 250"
      strokeWidth="0.9791666666666666"
    />
  </svg>
);
