import { useCallback } from 'react';

import { Clear } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';

import { useSideDrawerContent } from 'src/components/containers/SideDrawer';

import { ServerEnhancedGridStateWithHelpers } from '../serverEnhanced/useServerEnhancedGridState';

type Props = {
  apiRef: ReturnType<typeof useGridApiRef>;
  enhancedGridState: ServerEnhancedGridStateWithHelpers;
  entityName: string;
  bulkActions: React.ReactNode;
};

export default function BulkActionToolbar({ apiRef, enhancedGridState, entityName, bulkActions }: Props) {
  const sideDrawerOpen = !!useSideDrawerContent();
  const numSelected = Object.values(enhancedGridState.selectedRows).length;

  const clearSelection = useCallback(() => {
    apiRef.current.setRowSelectionModel([]);
  }, [apiRef]);

  return (
    <Box
      className="flex items-center bg-white shadow-xl rounded-lg mx-auto px-5 gap-4 transition-transform"
      sx={{
        position: 'fixed',
        top: '48px',
        left: 0,
        right: 0,
        maxWidth: 'min(768px, 75vw)',
        minHeight: '62px',
        zIndex: sideDrawerOpen ? 1200 : 1201,
        transitionDuration: '300ms',
        transform: sideDrawerOpen ? 'translateY(36px)' : 'translateY(0)',
      }}
    >
      <Typography variant="body1">
        {numSelected} {entityName}
        {numSelected > 1 ? 's' : ''} selected
      </Typography>
      <Button color="error" startIcon={<Clear />} onClick={clearSelection}>
        CANCEL
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      {bulkActions}
    </Box>
  );
}
