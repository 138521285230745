import { Entity } from '../constants';

// TODO: migrate WorkOrder references to GrowerProduct

export type EntityMapperType = {
  prefix: string;
  id: string;
  statuses: string[];
  name: string;
};

export const Mapper: {
  [key in Entity.Type]: EntityMapperType;
} = {
  SalesOrder: {
    prefix: 'SO',
    id: 'salesOrderId',
    statuses: ['DRAFT', 'SUBMITTED', 'PICKING', 'SHIPPED', 'CLOSED', 'CANCELLED', 'ERROR'],
    name: 'Sales Order',
  },
  PurchaseOrder: {
    prefix: 'PO',
    id: 'purchaseOrderId',
    statuses: ['DRAFT', 'SUBMITTED', 'RECEIVING', 'PENDING_LIQUIDATION', 'CLOSED', 'CANCELLED', 'ERROR'],
    name: 'Purchase Order',
  },
  WorkOrder: {
    prefix: 'GP',
    id: 'workOrderId',
    statuses: ['DRAFT', 'SUBMITTED', 'RECEIVING', 'PENDING_SETTLEMENT', 'CLOSED'],
    name: 'Grower Product',
  },
  BuySellOrder: {
    prefix: 'BSO',
    id: 'buySellOrderId',
    statuses: ['DRAFT', 'SUBMITTED', 'PAYMENT_RECONCILIATION', 'CLOSED'],
    name: 'Buy-Sell Order',
  },
  ProductionRun: {
    prefix: 'PR',
    id: 'productionRunId',
    statuses: ['DRAFT', 'SUBMITTED', 'CLOSED'],
    name: 'Production Run',
  },
  Lot: {
    prefix: '',
    id: 'lotId',
    statuses: ['OPEN', 'LOCKED', 'CLOSED'],
    name: 'Lot',
  },
  Invoice: {
    prefix: 'INV',
    id: 'invoiceId',
    statuses: [],
    name: 'Invoice',
  },
  StandardInvoice: {
    prefix: 'STD-INV',
    id: 'invoiceId',
    statuses: [],
    name: 'Standard Invoice',
  },
  BuySellOrderInvoice: {
    prefix: 'BSO-INV',
    id: 'buySellOrderInvoiceId',
    statuses: [],
    name: 'BSO Invoice',
  },
  Payable: {
    prefix: 'PYBL',
    id: 'payableId',
    statuses: [],
    name: 'Payable',
  },
  StandardPayable: {
    prefix: 'STD-PYBL',
    id: 'standardPayableId',
    statuses: [],
    name: 'Payable',
  },
  BuySellOrderPayable: {
    prefix: 'BSO-PYBL',
    id: 'buySellOrderPayableId',
    statuses: [],
    name: 'BSO Payable',
  },
  ConsignmentPayable: {
    prefix: 'PYBL',
    id: 'consignmentPayableId',
    statuses: [],
    name: 'Consignment Payable',
  },
  Payment: {
    prefix: 'PAY',
    id: 'paymentId',
    statuses: [],
    name: 'Payment',
  },
};
