import { Expense, Organization, Payment } from '../../types';
import { Payment as PaymentUtils } from '../../utils';
import Money from '../../utils/Money';
import { ExpensesComponent } from '../components/expensesGrid.template';
import { DocumentTypePopulator } from '../DocumentGenerator';

import { formatCurrency, formatDate } from './formatters';
import { SalesOrderInputType, SalesOrderTemplate, SalesOrderTemplateDataType } from './salesOrder.template';

export type InvoiceOrderInputType = {
  organization: Organization;
  slug: string;
  sentAt: string;
  payments: Payment[];
  totalAmountDue: number;
  salesOrder: SalesOrderInputType;
  expenses: Expense[];
  referenceNumber: string;
};

type InvoiceOrderTemplateDataType = Partial<{
  totalPaid: string;
  sentAt: string;
  totalAmountDue: string;
  paymentDueDate: string;
  referenceNumber: string;
}> &
  SalesOrderTemplateDataType;

export const InvoiceTemplate: DocumentTypePopulator<InvoiceOrderInputType, void, InvoiceOrderTemplateDataType> = {
  populate: (invoice, { type, templateId }, globalOptions) => {
    if (!invoice) {
      return {};
    }

    const { expenses, slug, salesOrder, payments, sentAt, totalAmountDue, referenceNumber } = invoice;

    const { salesTerms } = salesOrder;
    const paymentDueDate = PaymentUtils.computePaymentDueDate(sentAt, salesTerms);

    const totalPaid = payments.reduce((acc, payment) => acc + payment.paymentAmount || 0, 0);

    return {
      ...SalesOrderTemplate.populate(salesOrder, { totalUnitsType: 'picked', type, templateId }, globalOptions),
      invoiceNumber: slug,
      invoiceDate: formatDate(sentAt),
      paymentDueDate: formatDate(paymentDueDate),
      // Invoices do not have currency support at the moment.
      totalPaid: formatCurrency(Money.toDollars(totalPaid), 'USD'),
      totalAmountDue: formatCurrency(Money.toDollars(totalAmountDue), 'USD'),
      charges: ExpensesComponent.templatize(expenses, { currency: 'USD' }),
      referenceNumber,
    };
  },
};
