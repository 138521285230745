export const GET_ACCOUNT_RECONCILIATIONS = gql`
  query accountReconciliations($ledgerAccountPath: String!) {
    accountReconciliations(ledgerAccountPath: $ledgerAccountPath) {
      id
      startDate
      closedOn
      endingBalance

      adjustments {
        amount
      }

      aggregate {
        reconciledBalance
        accountPaths
      }
    }
  }
`;
