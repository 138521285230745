import { useEffect, useMemo, useState } from 'react';

import { useOrganization } from '@clerk/clerk-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Mustache from 'mustache';
import DocumentGenerator from 'shared/documents/DocumentGenerator';

export default function useDocumentPreview({
  documentTemplate,
  data,
  getDocumentData,
  type,
  entityType,
  extendedData,
}: {
  documentTemplate?: any;
  data?: any;
  getDocumentData?: () => Promise<any>;
  type?: any;
  entityType?: any;
  extendedData?: any;
}) {
  const { organization } = useOrganization();
  const [logo, setLogo] = useState(null);

  /**
   * Work around for CORS fetching for rendering images
   * in documents
   */
  useEffect(() => {
    if (!organization?.imageUrl || !logo) {
      return;
    }

    fetch(organization?.imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        setLogo(url);
      });
  }, [organization?.imageUrl, logo]);

  const flags = useFlags();

  const [document, setDocument] = useState(null);

  useEffect(() => {
    const generateDocument = async () => {
      if (!type) {
        return null;
      }

      const settings = flags['documentSettings'];

      const documentData = data ? data : await getDocumentData();

      try {
        return DocumentGenerator.generate(entityType, type, documentTemplate, documentData, {
          ...(extendedData ?? {}),
          orgLogo: logo,
          _settings: settings,
        });
      } catch (e) {
        console.warn(e);
      }
    };

    generateDocument().then((pages) => {
      setDocument(pages);
    });
  }, [type, entityType, data, documentTemplate, logo, flags, extendedData, getDocumentData]);

  return document;
}
