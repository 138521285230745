import { Expense, Organization, Payment } from '../../types';
import { Payment as PaymentUtils } from '../../utils';
import Money from '../../utils/Money';
import { ExpensesComponent } from '../components/expensesGrid.template';
import { DocumentTypePopulator } from '../DocumentGenerator';

import { BuySellOrderInputType, BuySellOrderTemplate, BuySellOrderTemplateDataType } from './buySellOrder.template';
import { formatCurrency, formatDate } from './formatters';

export type BuySellOrderInvoiceInputType = {
  organization: Organization;
  slug: string;
  sentAt: string;
  payments: Payment[];
  totalAmountDue: number;
  buySellOrder: BuySellOrderInputType;
  expenses: Expense[];
  referenceNumber: string;
};

type InvoiceOrderTemplateDataType = Partial<{
  totalPaid: string;
  sentAt: string;
  totalAmountDue: string;
  paymentDueDate: string;
}> &
  BuySellOrderTemplateDataType;

export const BuySellOrderInvoiceTemplate: DocumentTypePopulator<
  BuySellOrderInvoiceInputType,
  void,
  InvoiceOrderTemplateDataType
> = {
  populate: (invoice, { type, templateId }, globalOptions) => {
    if (!invoice) {
      return {};
    }

    const { expenses, slug, buySellOrder, payments, sentAt, totalAmountDue, referenceNumber } = invoice;

    const { customerSalesTerms } = buySellOrder;
    const paymentDueDate = PaymentUtils.computePaymentDueDate(sentAt, customerSalesTerms);

    const totalPaid = payments.reduce((acc, payment) => acc + payment.paymentAmount || 0, 0);

    return {
      ...BuySellOrderTemplate.populate(buySellOrder, { type, templateId }, globalOptions),
      invoiceNumber: slug,
      paymentDueDate: formatDate(paymentDueDate),
      // Invoices do not have currency support at the moment.
      totalPaid: formatCurrency(Money.toDollars(totalPaid), 'USD'),
      totalAmountDue: formatCurrency(Money.toDollars(totalAmountDue), 'USD'),
      charges: ExpensesComponent.templatize(expenses, { currency: 'USD' }),
      referenceNumber,
      invoiceDate: formatDate(sentAt),
    };
  },
};
