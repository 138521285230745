import { SalesTerms } from 'types/graphql';

type AllowedPaymentTerms = Exclude<SalesTerms, 'NET_40' | 'NET_90'>; // Excluded types are no longer displayed to the user and will be deprecated

const PAYMENT_TERMS_LABELS: Record<SalesTerms, string> = {
  COD: 'COD',
  NET_7: 'Net_7 Days',
  NET_10: 'Net_10 Days',
  NET_14: 'Net_14 Days',
  NET_21: 'Net_21 Days',
  NET_25: 'Net_25 Days',
  NET_30: 'Net_30 Days',
  NET_45: 'Net_45 Days',
  NET_60: 'Net_60 Days',

  // The below terms are deprecated and can no longer be selected for new invoices, but we have existing records that
  // use them so we have to still map them to a label until we can perform a data migration
  NET_40: 'Net 40 Days',
  NET_90: 'Net_90 Days',
};

export const PAYMENT_TERMS_OPTIONS: AllowedPaymentTerms[] = [
  'COD',
  'NET_7',
  'NET_10',
  'NET_14',
  'NET_21',
  'NET_25',
  'NET_30',
  'NET_45',
  'NET_60',
];

export const DEFAULT_PAYMENT_TERMS = 'NET_30';

/**
 * Maps the enumerated payment terms value to a a user-friendly label for use in documents and UI. If no label is
 * defined, the original value is returned.
 *
 * Note: In the context of StandardInvoices and StandardPayables, we use the term "payment terms" instead of "sales
 * terms". However, they use the same underlying SalesTerms graphql enum type.
 *
 * @param terms - the SalesTerms enum value
 * @returns a user-friendly label for the given payment terms
 */
export const mapPaymentTermsToLabel = (terms: SalesTerms | string) => PAYMENT_TERMS_LABELS[terms] ?? terms;
