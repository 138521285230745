import { useQuery } from '@redwoodjs/web';

import PurchaseOrderDocumentApi from '../api/PurchaseOrderDocument.api';

export default function usePurchaseOrderDocument({ slug, skip = false }) {
  const { data, loading, refetch } = useQuery(PurchaseOrderDocumentApi.GetPurchaseOrderDocumentData, {
    variables: { slug },
    skip,
  });

  return {
    purchaseOrder: data?.purchaseOrder,
    loading,
    getDocumentData: () => refetch({ slug }).then((res) => res.data.purchaseOrder),
  };
}
