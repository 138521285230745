import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from '@mui/material';

import { useSideModal } from 'src/providers/SideModalContextProvider';

import { useSideDrawer } from '../containers/SideDrawer';
import DocumentGenerator, { DocumentGeneratorProps } from '../containers/sidemodals/DocumentGenerator';

import Hint from './Hint';

export default function GenerateDocumentButton(props: DocumentGeneratorProps) {
  const { openDrawer } = useSideDrawer();
  const { contextProps } = useSideModal();

  return (
    <Hint title="Open Document Preview">
      <IconButton
        size="medium"
        onClick={() =>
          openDrawer(<DocumentGenerator {...props} contextProps={contextProps} />, 'Document Preview', {
            sx: {
              pb: 0,
              height: '100%',
            },
          })
        }
      >
        <DownloadIcon />
      </IconButton>
    </Hint>
  );
}
