import { useMemo, useRef } from 'react';

import { useOrganization } from '@clerk/clerk-react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ReactToPrint from 'react-to-print';

import { PricingSheetMeta } from 'src/modules/systemManagement/pricingSheets/constants';
import { PricingSheetEntry } from 'src/modules/systemManagement/pricingSheets/hooks/usePricingSheetEntries';
import { calculateMarginAndPrice } from 'src/modules/systemManagement/pricingSheets/utils';
import money from 'src/utils/money';

type PrintPricingSheetProps = {
  sheet: PricingSheetMeta;
  entries: PricingSheetEntry[];
  selectedRows: any[];
};

export default function PrintPricingSheet({ entries, sheet, selectedRows }: PrintPricingSheetProps) {
  const componentRef = useRef(null);
  const { organization } = useOrganization();

  const entriesWithPrice = useMemo(() => {
    const filter = selectedRows.map((row) => row.id);
    return entries
      .filter((entry) => (selectedRows.length ? filter.includes(entry.id) : true))
      .map((entry) => {
        const { total } = calculateMarginAndPrice(
          entry.costBasis,
          sheet.pricingFormula === 'CUSTOM' ? entry.pricingFormula : sheet.pricingFormula,
          sheet.pricingFormula === 'CUSTOM' ? entry.pricingValue : sheet.pricingValue,
          sheet.roundingValue
        );
        return {
          ...entry,
          price: total ? money.toHuman(total) : '--',
        };
      });
  }, [entries, selectedRows, sheet]);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Button variant="outlined">
            Print Sheet
            {selectedRows.length ? ` (${selectedRows.length})` : ''}
          </Button>
        )}
        content={() => componentRef.current}
      />

      <div className="hidden">
        <div ref={componentRef} className="flex flex-col">
          <header className="bg-green-50 grid grid-cols-3 p-8 gap-4">
            <div className="flex flex-col gap-4 items-start justify-end">
              <img
                src={organization.imageUrl}
                alt={organization.name}
                className="max-h-[64px] max-w-[256px] object-contain"
              />
              <h1>{organization.name}</h1>
            </div>
            <div className="flex flex-col justify-end">
              <p>Pricing Sheet</p>
              <b className="text-lg">{sheet.name}</b>
            </div>
            <div className="flex flex-col justify-end">
              <p>Print Date</p>
              <b>{new Date().toLocaleDateString()}</b>
              {sheet.startDate && sheet.expiryDate && (
                <>
                  <p className="mt-4">Effective Dates</p>
                  <b>
                    {sheet.startDate.toLocaleDateString()} - {sheet.expiryDate.toLocaleDateString()}
                  </b>
                </>
              )}
            </div>
          </header>
          <div className="m-8">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product name</TableCell>
                  <TableCell>Product code</TableCell>
                  <TableCell>Price per unit</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entriesWithPrice.map((entry) => (
                  <TableRow key={entry.id}>
                    <TableCell>{entry.productName}</TableCell>
                    <TableCell>{entry.productCode}</TableCell>
                    <TableCell>{entry.price}</TableCell>
                    <TableCell>{entry.note}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
