import { useCallback, useState } from 'react';

import { MenuItem, Select } from '@mui/material';

import { PricingFormula, pricingFormulaOptions } from 'src/modules/systemManagement/pricingSheets/constants';
import useEditPricingSheetEntry from 'src/modules/systemManagement/pricingSheets/hooks/useEditPricingSheetEntry';

type PricingFormulaCellProps = {
  entryId: number;
  pricingFormula?: PricingFormula;
};

export default function PricingFormulaCell(props: PricingFormulaCellProps) {
  const [val, setVal] = useState<PricingFormula>(props.pricingFormula || 'FLAT_RATE');
  const editPricingSheetEntry = useEditPricingSheetEntry();

  const onChange = useCallback(
    (e) => {
      editPricingSheetEntry(props.entryId, { pricingFormula: e.target.value, pricingValue: 0 });
      setVal(e.target.value as PricingFormula);
    },
    [editPricingSheetEntry]
  );

  return (
    <Select
      variant="standard"
      value={val}
      onChange={onChange}
      classes={{
        root: 'w-full h-full -mb-[1px]',
        select: '!h-full !p-0 !flex !items-center',
      }}
    >
      {pricingFormulaOptions
        .filter((o) => o.value !== 'CUSTOM')
        .map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
    </Select>
  );
}
