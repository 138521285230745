import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';

import { useMutation } from '@redwoodjs/web';

import { WorkOrdersApi } from 'src/api';
import ConfirmModal from 'src/components/containers/modals/ConfirmModal';
import useGridExport from 'src/hooks/useGridExport';
import { VIEW_PAGES, useViewColumnOrder } from 'src/managers/viewConfig.store';
import ServerEnhancedDataGrid from 'src/modules/grids/serverEnhanced/ServerEnhancedDataGrid';
import { useServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';
import GridToolbarV2 from 'src/modules/grids/toolbar/GridToolbarV2';

import { useGrowerProductsGrid } from './api/grid';
import { gridQuickFilters } from './constants';
import { useGrowerProductsColumns } from './useGrowerProductsColumns';

const STORAGE_KEY = 'work-orders';

export default function GrowerProductsGrid() {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const enhancedGridState = useServerEnhancedGridState(STORAGE_KEY, gridQuickFilters);
  const { rows, loading, pageInfo, totalCount, fetchAllForExport } = useGrowerProductsGrid(enhancedGridState);

  useGridExport(apiRef, 'work-orders', undefined, undefined, fetchAllForExport);

  const [orderRows, setOrderRows] = useState([]);

  // TODO(rob): move mutation logic to update cache so we don't need state
  useEffect(() => {
    setOrderRows(rows ?? []);
  }, [rows]);

  const [orderForDeletion, setOrderForDeletion] = useState(undefined);

  const [deleteWorkOrder] = useMutation(WorkOrdersApi.DeleteOrder, {
    onCompleted: ({ deleteWorkOrder: deleted }) => {
      setOrderRows(orderRows.filter((row) => row.id !== deleted.id));
    },
  });

  const [updateWorkOrder] = useMutation(WorkOrdersApi.UpdateOrder, {
    onCompleted: () => {
      enqueueSnackbar('Grower Product updated', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleFlagChange = useCallback(
    (id, flagged) => {
      setOrderRows((prev) => prev.map((row) => (row.id === id ? { ...row, flagged } : row)));
      return updateWorkOrder({ variables: { id, input: { flagged } } });
    },
    [updateWorkOrder]
  );

  const columns = useGrowerProductsColumns(setOrderForDeletion, handleFlagChange);
  const columnOrder = useViewColumnOrder();

  const toolbar = useMemo(
    () => (
      <GridToolbarV2
        apiRef={apiRef}
        enhancedGridState={enhancedGridState}
        viewPage={VIEW_PAGES.GROWER_PRODUCTS}
        quickFilterCountModel="WorkOrder"
      />
    ),
    [apiRef, enhancedGridState]
  );

  return (
    <>
      <ServerEnhancedDataGrid
        sx={{ p: 3 }}
        enhancedGridState={enhancedGridState}
        totalCount={totalCount}
        pageInfo={pageInfo}
        loading={loading}
        rows={orderRows}
        pinnedColumns={{ left: ['slug'], right: ['actions'] }}
        apiRef={apiRef}
        columns={columns}
        columnOrderToSync={columnOrder}
        disabledColumns={{ flagged: true }}
        storageKey={STORAGE_KEY}
        slots={{ toolbar }}
      />
      <ConfirmModal
        title="Delete Grower Product"
        prompt={`Do you want to delete Grower Product #${orderForDeletion?.slug}?`}
        handleClose={() => setOrderForDeletion(undefined)}
        handleConfirm={() => {
          deleteWorkOrder({ variables: { id: orderForDeletion.id } });
          setOrderForDeletion(undefined);
        }}
        open={orderForDeletion !== undefined}
      />
    </>
  );
}
