import { ReactElement, ReactNode, useEffect } from 'react';

import { Box, Divider, Tabs } from '@mui/material';

import { navigate } from '@redwoodjs/router';

import Tab from 'src/components/atoms/Tab';

import { MainCard, MainCardProps } from './MainCard';

export type FilterTab<Payload> = {
  label: string;
  key?: string;
  icon?: React.ReactElement;
  aboveTabs?: React.ReactElement;
  payload?: Payload;
};

export type MainCardTabs<Payload = unknown> = FilterTab<Payload>[];

export type StatusTabsPanelProps<Payload> = {
  aboveTabs?: ReactNode;
  tabs: MainCardTabs<Payload>;
  defaultTab?: number;
  render?: (statuses: Payload) => ReactNode;
  children?: ReactElement;
  showTabs?: boolean;
  onTabChange?: (tab: number) => void;
  queryParam?: string;
} & Partial<Omit<MainCardProps, 'tabs'>>;

/**
 * TabbedMainCard is the tabbed alternative to MainCard,
 * pass in a FilterTabs object and a render function,
 * which recevies the optional payload of FilterTab
 */
export default function TabbedMainCard<Payload>({
  aboveTabs,
  tabs,
  defaultTab = 0,
  render: tabPanel = (panel) => panel as ReactNode,
  onTabChange,
  queryParam,
  ...mainCardProps
}: StatusTabsPanelProps<Payload>) {
  const [selectedTab, setStatusTab] = React.useState(defaultTab);
  const payload = tabs[selectedTab].payload;

  // TODO: Remove when useTabs is implemented everywhere TAS-786
  useEffect(() => {
    setStatusTab(defaultTab);
  }, [defaultTab]);

  return (
    <MainCard
      {...mainCardProps}
      above={
        <>
          {aboveTabs}
          <Tabs
            sx={{ gap: 2 }}
            value={selectedTab}
            onChange={(_, newTab) => {
              setStatusTab(newTab);
              onTabChange?.(newTab);

              if (queryParam) {
                // replaces the query parameters with the new tab
                // will need to be updated to support maintaining other query params if that is required.
                const searchParams = new URLSearchParams({
                  [queryParam]: tabs[newTab].key,
                }).toString();

                navigate(`?${searchParams}`, { replace: true });
              }
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.label} label={tab.label} icon={tab.icon} indicatorValue={tab.indicatorValue} />
            ))}
          </Tabs>
          <Divider sx={{ mb: 1.5 }} />
        </>
      }
    >
      <Box key={selectedTab}>{tabPanel(payload)}</Box>
    </MainCard>
  );
}
