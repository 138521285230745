import { useLazyQuery } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';

import { useMutation } from '@redwoodjs/web';

import { GET_WAREHOUSE_PALLETS } from 'src/api/warehouses.api';
import ScanningApi from 'src/modules/scanning/api/Scanning.api';

export const useCreatePalletTags = (onCompleted?) =>
  useMutation(
    gql`
      mutation CreatePalletTags($input: CreatePalletTagsInput!) {
        createPalletTags(input: $input) {
          ...PalletTagAttributes
        }
      }
    `,
    {
      onCompleted: () => {
        enqueueSnackbar('Pallet Tag Created', { variant: 'success', autoHideDuration: 1000 });
        onCompleted?.();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    }
  );

export const useUpdatePalletTag = (onCompleted?) =>
  useMutation(
    gql`
      mutation UpdatePalletTag($id: Int!, $input: UpdatePalletTagInput!) {
        updatePalletTag(id: $id, input: $input) {
          printed
          warehouseLocationId
        }
      }
    `,
    {
      refetchQueries: [GET_WAREHOUSE_PALLETS],
      onCompleted,
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    }
  );

export const useDeletePalletTags = (onCompleted?) =>
  useMutation(
    gql`
      mutation DeletePalletTags($ids: [Int]!) {
        deletePalletTags(ids: $ids) {
          count
        }
      }
    `,
    {
      onCompleted: () => {
        enqueueSnackbar('Pallet Tags Deleted', { variant: 'success', autoHideDuration: 1000 });
        onCompleted?.();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    }
  );

export const usePrintPalletTags = (palletTags, setPalletTags, selection) =>
  useMutation(
    gql`
      mutation PrintPalletTags($ids: [Int]!) {
        printPalletTags(ids: $ids) {
          count
        }
      }
    `,
    {
      onCompleted: () => {
        enqueueSnackbar('Pallet Tags Printed', { variant: 'success', autoHideDuration: 1000 });
        setPalletTags(
          palletTags.map((tag) => {
            if (selection.includes(tag.id)) {
              return { ...tag, printed: true };
            }

            return tag;
          })
        );
      },
    }
  );

export const useLookupPalletTag = (placeId: number) => {
  const [lookupTag] = useLazyQuery(ScanningApi.LookupPalletTag);

  return async (tagNumber: string) => {
    const res = await lookupTag({ variables: { tagNumber, warehouseId: placeId } });
    return res.data?.palletTagLookup;
  };
};
