import { useCallback } from 'react';

/**
 * A hook that retries a function with exponential backoff.
 *
 * If the response is nullish, the function will retry after a delay that increases exponentially.
 * If the response is false, the function will abort and return null.
 *
 * @param func The function to retry
 * @param delayMult The multiplier for the delay between retries
 * @param maxRetries The maximum number of retries
 * @returns The function that retries the original function
 *
 */
export default function useExponentialBackoff<T = void>(
  func: (input: T) => Promise<unknown>,
  delayMult = 1,
  maxRetries = 10
) {
  const expontentialBackoff = useCallback(
    async (input: T, retries = 0) => {
      try {
        const response = await func(input);

        if (response) {
          return response;
        }

        // abort
        if (response === false) {
          return null;
        }
      } catch (error) {
        console.error('Failed to fetch token', error);
      }

      if (retries > maxRetries) {
        console.error('Failed to fetch token after 10 retries');
        return null;
      }

      const timeoutSecs = retries * retries;
      const timeoutMs = timeoutSecs * (1000 * delayMult);

      console.warn(`Failed to fetch token, retrying in ${timeoutSecs} seconds`);

      await new Promise((resolve) => setTimeout(resolve, timeoutMs));

      return await expontentialBackoff(input, retries + 1);
    },
    [func, delayMult, maxRetries]
  );

  return expontentialBackoff;
}
