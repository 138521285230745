import { useEffect, useState } from 'react';

import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { useHits, useSearchBox } from 'react-instantsearch-hooks-web';
import { useDebounce } from 'usehooks-ts';

type SearchLookupProps = {
  label?: string;
  filterOptions?: (options: any[], state: any) => any[];
  textFieldClassName?: string;
  renderOption: (option: any) => React.ReactNode;
  getOptionLabel: (option: any) => string;
  onSelect?: (props: any, option: any) => void;
};

// A simplified version of SearchAutocomplete intended to not store it's value - ex for a search rather than an input
export default function SearchLookup(props: SearchLookupProps) {
  const [value, setValue] = useState('');
  const { refine } = useSearchBox();
  const { hits } = useHits();

  const debouncedValue = useDebounce(value, 100);

  useEffect(() => {
    refine(debouncedValue);
  }, [debouncedValue, refine]);

  return (
    <MuiAutocomplete
      className="grow"
      clearOnEscape
      options={hits}
      autoHighlight
      inputValue={value}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        } else {
          setValue('');
          document.body.focus();
        }
      }}
      onChange={(_, val) => {
        if (val) {
          props.onSelect?.(val);
        }
      }}
      filterOptions={props.filterOptions}
      getOptionLabel={props.getOptionLabel}
      renderOption={props.renderOption}
      renderInput={(params) => (
        <TextField {...params} label={props.label} className={props.textFieldClassName} fullWidth />
      )}
    />
  );
}
