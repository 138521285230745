import { ButtonBase, IconProps, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CSSObject, useTheme } from '@mui/material/styles';

import { navigate, useLocation } from '@redwoodjs/router';

import TransformedLink from 'src/components/atoms/TransformedLink';

import { useSidebar } from '../../../providers/SidebarContextProvider';
import { AnyRoute } from '../RouteLinks';

export type SidebarLinkItem = {
  icon?: React.FC<IconProps>;
  to?: AnyRoute | string;
  text: string;
  sxListItemButton?: CSSObject;
  nested?: boolean;
  minimized?: boolean;
  onClick?: (e) => void;
  action?: (newTab?: boolean) => void;
  testId?: string;
  className?: string;
};

export type SidebarLinkProps = SidebarLinkItem;

export default function SidebarLink({
  text,
  icon: Icon,
  to,
  sxListItemButton,
  nested,
  minimized,
  onClick,
  action,
  testId,
  className,
}: SidebarLinkProps) {
  const theme = useTheme();
  const location = useLocation();
  const { sidebarOpen } = useSidebar();
  const route = to && (typeof to === 'string' ? to : to());
  const pathToCheck = nested ? location.pathname : '/' + location.pathname.split('/')[1];
  const current = pathToCheck === route;

  return (
    <ListItem key={text} disablePadding disableGutters sx={{ display: 'block', minHeight: 'px' }} className={className}>
      <ButtonBase
        sx={{
          background: !sidebarOpen && current ? theme.palette.primary[50] : 'transparent',
          justifyContent: sidebarOpen ? 'initial' : 'center',
          px: 2.5,
          width: '100%',
          height: '40px',
          ...sxListItemButton,
          '& .MuiTypography-root': {
            fontSize: nested ? '14px' : '16px',
          },
          transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
        href={route}
        LinkComponent={TransformedLink}
        data-testid={testId}
        onClick={(e) => {
          onClick?.(e);
          action?.();
        }}
        // Middle click
        onMouseDown={(e) => {
          if (e.button === 1) {
            e.preventDefault();
          }
        }} // prevent page scroll from taking precendence
        onAuxClick={(e) => {
          if (e.button === 1) {
            onClick?.(e);
            action?.(true);
          }
        }}
      >
        {Icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sidebarOpen ? 3 : 'auto',
              justifyContent: 'center',
              color: current ? theme.palette.primary.main : theme.palette.grey[600],
            }}
          >
            <Icon sx={{ width: '20px', height: '20px' }} />
          </ListItemIcon>
        )}
        <ListItemText
          disableTypography
          primary={text}
          sx={{
            fontSize: nested ? '14px' : '16px',
            fontWeight: current ? 'bold' : 'normal',
            color: current ? theme.palette.primary.main : theme.palette.grey[700],
            opacity: sidebarOpen === !minimized ? 1 : 0,
            textAlign: 'left',
          }}
        />
      </ButtonBase>
    </ListItem>
  );
}
