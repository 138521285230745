import ConfirmModal from 'src/components/containers/modals/ConfirmModal';
import { AccountRegisterContext } from 'src/pages/AccountRegisterPage/AccountRegisterContext';
import { Typography } from '@mui/material';
import { useEffect } from 'react';

export const EndBankReconcilingModal = ({
  context,
  isOpen,
  onClose,
  onConfirm,
}: {
  context: AccountRegisterContext;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}) => {
  if (isOpen && context.bankReconciliationStats && Math.abs(context.bankReconciliationStats.deltaBalance) < 0.01) {
    // auto close if no need to warn someone about unreconciled transactions
    useEffect(() => {
      onConfirm().then();
    }, []);
    return <></>;
  }

  const prompt = isOpen && (
    <Typography>
      Ending reconciliation now will insert an adjustment ledger entry of{' '}
      {(context.bankReconciliationStats.deltaBalance / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })}
      .
    </Typography>
  );
  return (
    <ConfirmModal
      handleClose={onClose}
      handleConfirm={onConfirm}
      open={isOpen}
      title="End reconciliation"
      prompt={prompt}
      action="Complete"
    />
  );
};
