import { Download as DownloadIcon } from '@mui/icons-material';
import { Divider, MenuItem, Typography } from '@mui/material';

import { exportGrid, useGridExportState } from 'src/hooks/useGridExport';

import { DropdownMenuButton } from '../containers/menus/DropdownMenuButton';

export default function ExportGridButton({ optionsComponent = null, canPrint = true }) {
  const gridIsExporting = useGridExportState((s) => s.isExporting);

  return (
    <DropdownMenuButton
      buttonProps={{ variant: 'outlined', disabled: gridIsExporting }}
      label="Export"
      Icon={<DownloadIcon />}
    >
      {optionsComponent}
      {!!optionsComponent && <Divider />}
      <MenuItem sx={{ display: 'flex' }} onClick={() => exportGrid('excel')}>
        <Typography sx={{ my: 'auto' }}>Excel</Typography>
      </MenuItem>
      <MenuItem sx={{ display: 'flex' }} onClick={() => exportGrid('csv')}>
        <Typography sx={{ my: 'auto' }}>CSV</Typography>
      </MenuItem>
      {canPrint && (
        <MenuItem sx={{ display: 'flex' }} onClick={() => exportGrid('print')}>
          <Typography sx={{ my: 'auto' }}>Print</Typography>
        </MenuItem>
      )}
    </DropdownMenuButton>
  );
}
