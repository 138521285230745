const GetPurchaseOrderDocumentData = gql`
  query GetPurchaseOrderDocumentData($slug: String!) {
    purchaseOrder(slug: $slug) {
      organization {
        label
        logoUrl
        primaryLocation {
          ...PlaceAttributes
        }
      }
      slug
      receiveDate
      submittedAt
      salesTerms
      status
      vendorPo
      currencyCode
      customFields
      buyer {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }
      businessEntity {
        id
        name
      }
      businessEntityContact {
        id
        name
        email
        phone
      }
      subsidiary {
        ...PlaceAttributes
      }
      exchangeRate
      shipment {
        terms
        shipDate
        deliveryDate
        freightCompany {
          id
          name
        }
        destination {
          ...PlaceAttributes
        }
        destinationId
        place {
          ...PlaceAttributes
        }
        placeId
        customFields
        temperatureMin
        temperatureMax
      }
      lineItems {
        ...LineItemAttributes
      }
      expenses {
        ...ExpenseAttributes
      }
      notes {
        ...NoteAttributes
      }
    }
  }
`;

export default {
  GetPurchaseOrderDocumentData,
};
