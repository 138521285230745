import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';

import styles from '../utils/styles';

export function Footer() {
  return (
    <View style={styles.footer} fixed>
      <Text style={styles.footerText}>{DateTime.now().toFormat(`MM/dd/yyyy 'at' HH:mm a`)}</Text>
      <Text style={{ ...styles.footerText, marginLeft: '10' }}>Powered by Agriful</Text>
      <Text render={({ pageNumber, totalPages }) => `${pageNumber} of ${totalPages}`} fixed style={styles.pageNumber} />
    </View>
  );
}
