import { useMemo, useState } from 'react';

import { Document, Page, Text } from '@react-pdf/renderer';
import CarrierConfirmationTemplate from 'api/src/lib/documents/templates/CarrierConfirmationTemplate';
import { docStyles } from 'api/src/lib/documents/utils/styles';

import { useQuery } from '@redwoodjs/web';

export const QUERY_ORDER_LOAD = gql`
  query FindLoadCarrierConfirmationQuery($slug: String!) {
    load(slug: $slug) {
      id
      status
      slug
      freightCompany {
        id
        name
      }
      referenceNumber
      licensePlate
      licensePlateState
      temperatureMin
      temperatureMax
      driverName
      driverLicense
      coordinator {
        id
        user {
          id
          firstName
          lastName
        }
      }
      waypoints {
        id
        loadOrderConnection {
          buySellOrder {
            id
            slug
            numCartons {
              ordered
            }
            numPallets {
              ordered
            }
            weight {
              ordered
            }
          }
          salesOrder {
            id
            slug
            numCartons {
              ordered
            }
            numPallets {
              ordered
            }
            weight {
              ordered
            }
          }
          purchaseOrder {
            id
            slug
            numCartons {
              ordered
            }
            numPallets {
              ordered
            }
            weight {
              ordered
            }
          }
          workOrder {
            id
            slug
          }
        }
        location
        type
        arrivalTime
        waypointIndex
      }
      organization {
        id
        label
        logoUrl
        productDescriptionTemplate
        places {
          id
          streetAddress1
          city
          state
          postalCode
          addressRoles
        }
        memberships {
          id
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
      notes {
        ...NoteAttributes
      }
    }
  }
`;

export const useOrderCarrierConfirmation = (slug) => {
  const [load, setLoad] = useState(null);
  const { loading } = useQuery(QUERY_ORDER_LOAD, {
    variables: {
      slug: slug || '',
    },
    onCompleted: (data) => {
      setLoad(data.load);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const orders = useMemo(() => {
    const ordersByType = {
      BuySellOrder: {},
      SalesOrder: {},
      PurchaseOrder: {},
    };

    load?.waypoints?.forEach((waypoint) => {
      const loc = waypoint.loadOrderConnection;
      const order = loc?.buySellOrder || loc?.salesOrder || loc?.purchaseOrder;
      const type = order.__typename;

      if (!ordersByType[type][order.id]) {
        ordersByType[type][order.id] = order;
      }
    });

    return Object.values(ordersByType).flatMap((orders) => Object.values(orders));
  }, [load]);

  return slug
    ? {
        load,
        orders,
        loading,
      }
    : null;
};

export default function OrderCarrierConfirmation({ load, orders, loading }) {
  if (loading) {
    return (
      <Document>
        <Page size="LETTER" style={docStyles.page}>
          <Text>Loading...</Text>
        </Page>
      </Document>
    );
  }

  if (!load) {
    return (
      <Document>
        <Page size="LETTER" style={docStyles.page}>
          <Text>No load available</Text>
        </Page>
      </Document>
    );
  }

  return (
    <CarrierConfirmationTemplate
      organization={load.organization}
      load={load}
      orders={orders}
      waypoints={load.waypoints}
    />
  );
}
