import { useQuery } from '@redwoodjs/web';

const GET_ORG_INFO = gql`
  query GetOrgInfo {
    organization {
      id
      gs1CompanyPrefix
      salesOrderDocumentPrefix
    }
  }
`;

export type OrgInfo = {
  id: number;
  gs1CompanyPrefix: number;
  salesOrderDocumentPrefix?: string;
};

export default function useOrgInfo(): OrgInfo {
  const { data } = useQuery(GET_ORG_INFO);
  return data?.organization ?? {};
}
