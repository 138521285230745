import { Button, Menu } from '@mui/material';

import useAnchorEl from 'src/hooks/useAnchorEl';

type ToolbarButtonProps = {
  icon?: React.ReactNode;
  label?: string;
  children: (closeMenu: () => void) => React.ReactNode;
};

export default function ToolbarButton(props: ToolbarButtonProps) {
  const { anchorEl, open, handleClick, handleClose } = useAnchorEl();

  return (
    <>
      <Button variant="text" color="primary" sx={{ my: 'auto' }} onClick={handleClick} startIcon={props.icon}>
        <span className="invisible sm:visible">{props.label}</span>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.children(handleClose)}
      </Menu>
    </>
  );
}
