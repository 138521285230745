import { useEffect, useState } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { useDebounce } from 'usehooks-ts';

import Autocomplete from 'src/components/atoms/Autocomplete';
import QuickDatePicker from 'src/components/atoms/QuickDatePicker';
import SearchAutocomplete from 'src/components/atoms/SearchAutocomplete';
import { pricingFormulaOptions, roundingValueOptions } from 'src/modules/systemManagement/pricingSheets/constants';
import { PricingSheetTabProps } from 'src/modules/systemManagement/pricingSheets/containers/EditPricingSheet';
import useEnabledCustomerGroups from 'src/modules/systemManagement/pricingSheets/hooks/useEnabledCustomerGroups';
import { BusinessEntityIndex, SearchIndex, useAlgolia } from 'src/providers/AlgoliaProvider';
import money from 'src/utils/money';

type TargetTypes = 'ALL' | 'GROUPS' | 'INDIVIDUALS' | 'BOTH';

export default function Settings({ pricingSheet, editPricingSheet }: PricingSheetTabProps) {
  const algolia = useAlgolia();
  const [targetting, setTargetting] = useState<TargetTypes>(() => {
    if (pricingSheet.customerGroups.length && pricingSheet.individualCustomers.length) return 'BOTH';
    if (pricingSheet.customerGroups.length) return 'GROUPS';
    if (pricingSheet.individualCustomers.length) return 'INDIVIDUALS';
    return 'ALL';
  });
  const [state, setState] = useState({
    name: pricingSheet.name,
    customerGroups: pricingSheet.customerGroups,
    individualCustomers: pricingSheet.individualCustomers.map((c) => ({ name: c.name, businessEntityId: c.id })),
    startDate: pricingSheet.startDate,
    expiryDate: pricingSheet.expiryDate,
    pricingFormula: pricingSheet.pricingFormula,
    pricingValue:
      pricingSheet.pricingFormula === 'COST_BASIS_FLAT_MARGIN'
        ? money.toDollars(pricingSheet.pricingValue)
        : pricingSheet.pricingValue,
    roundingValue: pricingSheet.roundingValue,
  });

  const customerGroups = useEnabledCustomerGroups();

  const updateState = (key: keyof typeof state, value: any) => {
    setState({ ...state, [key]: value });
  };

  const debouncedState = useDebounce(state, 400);

  useEffect(() => {
    editPricingSheet(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedState]);

  useEffect(() => {
    if ((targetting === 'GROUPS' || targetting === 'ALL') && state.individualCustomers.length) {
      updateState('individualCustomers', []);
    }
    if ((targetting === 'INDIVIDUALS' || targetting === 'ALL') && state.customerGroups.length) {
      updateState('customerGroups', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetting]);

  useEffect(() => {
    if (state.pricingFormula === 'FLAT_RATE' || state.pricingFormula === 'CUSTOM') {
      updateState('pricingValue', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pricingFormula]);

  return (
    <div className="grid grid-cols-2 gap-6 mx-auto">
      <div className="flex flex-col gap-6 p-6 max-w-[625px]">
        <b>General information</b>
        <TextField
          label="Name"
          variant="outlined"
          value={state.name}
          onChange={(e) => updateState('name', e.target.value)}
        />
        <p>
          Choose customers or groups to whom this pricing sheet will be applied.
          <br />
          Additional fields will appear according to your choice.
        </p>
        <RadioGroup value={targetting} onChange={({ target: { value } }) => setTargetting(value)}>
          <FormControlLabel value="ALL" control={<Radio />} label="All customers" />
          <FormControlLabel value="GROUPS" control={<Radio />} label="Specific customer groups" />
          <FormControlLabel value="INDIVIDUALS" control={<Radio />} label="Specific individual customers" />
          <FormControlLabel value="BOTH" control={<Radio />} label="Specific customer groups + individual customers" />
        </RadioGroup>
        {(targetting === 'GROUPS' || targetting === 'BOTH') && (
          <FormControl style={{ width: '100%' }}>
            <Autocomplete<{ name: string; id: number }, true>
              multiple
              label="Customer groups"
              value={state.customerGroups}
              options={customerGroups}
              getOptionLabel={(option) => `${option.name}`}
              onChange={(_, value) => updateState('customerGroups', value)}
            />
          </FormControl>
        )}
        {(targetting === 'INDIVIDUALS' || targetting === 'BOTH') && (
          <InstantSearch searchClient={algolia} indexName={SearchIndex.BusinessEntities}>
            <FormControl>
              <Configure filters="(areRolesEmpty:true OR roles:CUSTOMER) AND disabled:false" />
              <SearchAutocomplete<Partial<BusinessEntityIndex>, true>
                multiple
                value={state.individualCustomers}
                label="Individual customers"
                onChange={(_, value) => updateState('individualCustomers', value)}
                getOptionLabel={(option) => option.name}
              />
            </FormControl>
          </InstantSearch>
        )}
        <div className="flex gap-4">
          <QuickDatePicker
            label="Start date"
            value={state.startDate}
            onChange={(date) => updateState('startDate', date)}
            renderInput={(params) => <TextField {...params} />}
          />
          <QuickDatePicker
            label="Expiry date"
            value={state.expiryDate}
            onChange={(date) => updateState('expiryDate', date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </div>
      <div className="flex flex-col gap-6 p-6 max-w-[625px]">
        <b>Pricing setup</b>
        <FormControl style={{ width: '100%' }}>
          <Autocomplete<{ name: string; value: string }>
            label="Pricing settings"
            value={pricingFormulaOptions.find((o) => o.value === state.pricingFormula)}
            options={pricingFormulaOptions}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(_, value) => updateState('pricingFormula', value?.value)}
          />
        </FormControl>
        {state.pricingFormula.endsWith('MARGIN') && (
          <TextField
            label={state.pricingFormula === 'COST_BASIS_PERCENT_MARGIN' ? '% margin' : '$ margin'}
            variant="outlined"
            value={state.pricingValue}
            type="number"
            onChange={(e) => updateState('pricingValue', parseFloat(e.target.value))}
          />
        )}
        <FormControl style={{ width: '100%' }}>
          <Autocomplete<{ name: string; value: number }>
            label="Rounding option"
            value={roundingValueOptions.find((o) => o.value === state.roundingValue)}
            options={roundingValueOptions}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(_, value) => updateState('roundingValue', value?.value)}
          />
        </FormControl>
      </div>
    </div>
  );
}
