import { PricingFormula } from './constants';
import { PricingSheetMeta, Status } from './constants';

export function getPricingSheetStatus(pricingSheet: PricingSheetMeta): Status {
  if (pricingSheet.draft) {
    return 'Draft';
  }
  if (pricingSheet.expiryDate) {
    if (new Date(pricingSheet.expiryDate) < new Date()) {
      return 'Expired';
    }
  }
  if (pricingSheet.startDate) {
    if (new Date(pricingSheet.startDate) > new Date()) {
      return 'Inactive';
    }
  }
  return pricingSheet.enabled ? 'Active' : 'Inactive';
}

// All monetary inputs and output are INTs representing cents
export function calculateMarginAndPrice(
  costBasis: number,
  pricingFormula: PricingFormula,
  pricingValue?: number,
  roundingValue?: number
) {
  const margin = pricingFormula === 'COST_BASIS_PERCENT_MARGIN' ? costBasis * (pricingValue / 100) : pricingValue;
  let total = Math.round(costBasis + margin);

  if (roundingValue) {
    total = Math.round(total / roundingValue) * roundingValue;
  }

  return { margin, total };
}
