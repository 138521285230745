import { useState } from 'react';

import { SelectorsResultProps, useEditorMaybe } from '@grapesjs/react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@mui/material';
import { Selector } from 'grapesjs';

export default function CustomSelectorManager({
  selectors,
  targets,
  addSelector,
  removeSelector,
}: Omit<SelectorsResultProps, 'Container'>) {
  const [newSelector, setNewSelector] = useState('');

  const addNewSelector = () => {
    if (newSelector?.length) {
      const name = newSelector.trim().toLowerCase().replace(/\s+/g, '-');
      addSelector({ name, label: newSelector });
    }

    setNewSelector('');
  };

  const editor = useEditorMaybe();

  const setSelector = (selector: Selector) => {
    editor.StyleManager.select(selector.getFullName());
  };

  const targetStr = targets.join(', ');

  return (
    <div className="gjs-custom-selector-manager p-2 flex flex-col gap-2 text-left">
      <Card
        sx={{
          padding: 1,
        }}
      >
        {targetStr ? (
          <Box display="flex">
            <TextField
              value={newSelector}
              placeholder="Add Selector"
              onChange={(e) => {
                const value = e.target.value;

                setNewSelector(value);
              }}
            />
            <Button disabled={!newSelector?.length} onClick={addNewSelector}>
              Add
            </Button>
          </Box>
        ) : (
          <div className="opacity-70">Select a component</div>
        )}
        <List
          sx={{
            width: '100%',
          }}
        >
          {selectors.map((selector) => (
            <ListItemButton
              selected={targets.includes(selector.getName())}
              onClick={() => setSelector(selector)}
              key={selector.toString()}
            >
              <ListItemText>{selector.getLabel()}</ListItemText>
              <ListItemSecondaryAction onClick={() => removeSelector(selector)}>
                <Close color="action" />
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      </Card>
      <div>
        Selected: <span className="opacity-70">{targetStr || 'None'}</span>
      </div>
    </div>
  );
}
