// Define the possible statuses for a BuySellOrder.
type BuySellOrderStatus =
  | 'DRAFT'
  | 'SUBMITTED'
  | 'PAYMENT_RECONCILIATION'
  | 'CLOSED'
  | 'CANCELLED'
  | 'ERROR'
  | 'CANCELLED';

// List of fields that can be editable in a BuySellOrder.
type EditableFields =
  | 'broker'
  | 'vendor'
  | 'vendorContact'
  | 'customerPO'
  | 'vendorSO'
  | 'purchaseTerms'
  | 'billTo'
  | 'origin'
  | 'customer'
  | 'customerContact'
  | 'salesTerms'
  | 'destination'
  | 'shippingTerms'
  | 'freightCompany'
  | 'shipDate'
  | 'deliveryDate'
  | 'lineItems'
  | 'expenses'
  | 'currency'
  | 'subsidiary';

// Type definition to represent the editability of each field.
type FieldEditability = {
  [field in EditableFields]: boolean;
};

// Validator class to determine field editability based on the BuySellOrder status.
export class Validator {
  // Define statuses up to which fields are editable.
  private static toSubmitted: BuySellOrderStatus[] = ['DRAFT', 'SUBMITTED'];
  private static toPaymentReconciliation: BuySellOrderStatus[] = [
    'DRAFT',
    'SUBMITTED',
    'PAYMENT_RECONCILIATION',
  ];
  private static toClosed: BuySellOrderStatus[] = [
    'DRAFT',
    'SUBMITTED',
    'PAYMENT_RECONCILIATION',
    'CLOSED',
  ];

  public static active: BuySellOrderStatus[] = [
    'SUBMITTED',
    'PAYMENT_RECONCILIATION',
  ];

  public static isRevertable(status: BuySellOrderStatus) {
    return ['SUBMITTED', 'PAYMENT_RECONCILIATION', 'CLOSED'].includes(status);
  }

  /**
   * Determines if a field is editable based on the current status of the BuySellOrder.
   * Most fields are editable until the 'Payment Reconciliation' status.
   * @param field The field to check for editability.
   * @param status The current status of the BuySellOrder.
   * @param numOpenInvoices? The number of open invoices for the BuySellOrder.
   * @returns Boolean indicating if the field is editable.
   */
  public static isFieldEditable(
    field: EditableFields,
    status: BuySellOrderStatus,
    numOpenInvoices = 0
  ) {
    const editabilityMap: FieldEditability = {
      // To `Submitted` status.
      broker: this.toSubmitted.includes(status),

      // To `Payment Reconciliation` status.
      vendor: this.toPaymentReconciliation.includes(status),
      vendorContact: this.toPaymentReconciliation.includes(status),
      purchaseTerms: this.toPaymentReconciliation.includes(status),
      salesTerms: this.toPaymentReconciliation.includes(status),
      shippingTerms: this.toPaymentReconciliation.includes(status),
      billTo: this.toPaymentReconciliation.includes(status),
      origin: this.toPaymentReconciliation.includes(status),
      customer: this.toPaymentReconciliation.includes(status),
      customerContact: this.toPaymentReconciliation.includes(status),
      destination: this.toPaymentReconciliation.includes(status),
      freightCompany: this.toPaymentReconciliation.includes(status),
      shipDate: this.toPaymentReconciliation.includes(status),
      deliveryDate: this.toPaymentReconciliation.includes(status),
      customerPO: this.toPaymentReconciliation.includes(status),
      vendorSO: this.toPaymentReconciliation.includes(status),
      currency: this.toPaymentReconciliation.includes(status),

      // To `Closed` status (with conditions)
      lineItems: this.toClosed.includes(status) && numOpenInvoices === 0,
      expenses: this.toClosed.includes(status) && numOpenInvoices === 0,
      subsidiary: true,
    };

    return editabilityMap[field];
  }
}
