import { PDFArray, PDFBool, PDFDict, PDFDocument, PDFName, PDFPage, PDFString } from 'pdf-lib';
import { parseDate } from 'shared/utils/DateTime';

import formatAddress from 'src/utils/address/formatAddress';
import money from 'src/utils/money';

enum CanConfFields {
  vendorName = '0085.vendor.name and address',
  buyerName = '0085.purchaser.name and address',
  destination = '0085.place of direct shipment to Canada',
  productDescription = '0085.specification of commodities',
  countryOfOrigin = '0085.country of origin',
  netWeight = '0085.net weight',
  unitsOrdered = '0085.nunber of packages',
  sellPrice = '0085.price per package',
  totalPrice = '0085.total cost per commodity',
  poNumber = '0085.purchaser order number',
  totalWeight = '0085.total weight.net',
  totalAllProducts = '0085.total costs of all commodities',
  specialAgreements = '0085.special agreements',
  deliveryDate = '0085.date of delivery',
  purchaseDate = '0085.date.purchased',
  transportMode = '0085.transportation mode',
  shipmentPlace = '0085.place of direct shipment to Canada',
  directShipmentDate = '0085.date.direct shipment',
  saleMode = '0085.sale mode',
  currency = '0085.currency of settlement',
  tariffItem = '0085.harmonized system tariff item',
}

// ensures that the fields are unique between pages
const postProcessCopiedAnnots = (page: PDFPage, pageNum) => {
  const acroForm = page.doc.catalog.lookup(PDFName.of('AcroForm'), PDFDict);
  const acroFields = acroForm.lookup(PDFName.of('Fields'), PDFArray);

  acroForm.set(PDFName.of('NeedAppearances'), PDFBool.True);

  const annots = page.node.Annots()!;
  for (let idx = 0; idx < annots.size(); idx++) {
    const annot = annots.lookup(idx, PDFDict);

    if (!annot.has(PDFName.of('T'))) {
      continue;
    }

    const tVal = (annot.lookup(PDFName.of('T')) as any).value;

    annot.set(PDFName.of('P'), page.ref);
    annot.set(PDFName.of('T'), PDFString.of(`${tVal}_${pageNum}`));
    annot.delete(PDFName.of('AP'));

    acroFields.push(annots.get(idx));
  }
};

export const CanadaConfirmationOfSale = async ({ salesOrder }) => {
  const pdfBytesToLoad = await fetch('/documents/canada_conf_of_sale.pdf').then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(pdfBytesToLoad);

  // copy page 0

  const pages = Math.ceil(salesOrder.lineItems.length / 10);

  // setup pages
  for (let i = 1; i < pages; i++) {
    const [page] = await pdfDoc.copyPages(pdfDoc, [0]);

    postProcessCopiedAnnots(page, i);
    pdfDoc.addPage(page);
  }

  const form = pdfDoc.getForm();

  let totalWeight = 0;
  let totalCost = 0;

  const currency = salesOrder.currencyCode ?? 'USD';

  salesOrder.lineItems.forEach((lineItem, index) => {
    const product = lineItem.product;
    const sellPrice = lineItem.sellCurrencyPrice ?? lineItem.sellPrice;
    const unitsOrdered = lineItem.unitsPicked ?? lineItem.unitsOrdered;
    const weight = product.weightPerInventoryUnit * unitsOrdered;
    const cost = sellPrice * unitsOrdered;
    const pageIndex = Math.floor(index / 10);
    const pageMarker = pageIndex > 0 ? `_${pageIndex}` : '';
    const rowIndex = index % 10;

    const description = [
      product.commodity?.name,
      product.commodityStyle?.name,
      product.commoditySize?.name,
      product.commodityLabel?.name,
      product.grade,
      product.organic ? 'Organic' : '',
      product.commodityUnit?.name,
    ]
      .filter(Boolean)
      .join(' ');

    const productField = form.getTextField(`${CanConfFields.productDescription}.${rowIndex}${pageMarker}`);

    const cooField = form.getTextField(`${CanConfFields.countryOfOrigin}.${rowIndex}${pageMarker}`);

    const totalWeightField = form.getTextField(`${CanConfFields.netWeight}.${rowIndex}${pageMarker}`);
    const totalOrderedField = form.getTextField(`${CanConfFields.unitsOrdered}.${rowIndex}${pageMarker}`);

    const sellPriceField = form.getTextField(`${CanConfFields.sellPrice}.${rowIndex}${pageMarker}`);

    const totalCostField = form.getTextField(`${CanConfFields.totalPrice}.${rowIndex}${pageMarker}`);

    const tariffItem = form.getTextField(`${CanConfFields.tariffItem}.${rowIndex}${pageMarker}`);

    productField.setFontSize(5);
    totalWeightField.setFontSize(7);
    cooField.setFontSize(7);
    cooField.setFontSize(7);
    totalOrderedField.setFontSize(7);
    sellPriceField.setFontSize(7);
    totalCostField.setFontSize(7);

    productField.setText(description);
    cooField.setText(product.countryOfOrigin);
    totalWeightField.setText(`${weight.toLocaleString('en-US')}lbs`);
    totalOrderedField.setText(unitsOrdered.toLocaleString('en-US'));
    sellPriceField.setText(
      money.toDollars(sellPrice).toLocaleString('en-US', {
        currency,
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
    totalCostField.setText(
      money.toDollars(cost).toLocaleString('en-US', {
        currency,
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );

    tariffItem.setText((index + 1).toLocaleString());

    totalWeight += weight;
    totalCost += cost;
  });

  const mainAddress =
    salesOrder.subsidiary ??
    salesOrder.organization.places.find((places) => places.addressRoles.includes('PRIMARY_BUSINESS'));

  const deliveryDate = salesOrder.deliveryDate;
  const shipment = salesOrder.shipment;
  const shipTo = shipment?.place;

  for (let i = 0; i < pages; i++) {
    const pageMarker = i > 0 ? `_${i}` : '';

    const totalWeightField = form.getTextField(`${CanConfFields.totalWeight}${pageMarker}`);
    const totalCostField = form.getTextField(`${CanConfFields.totalAllProducts}${pageMarker}`);
    const poNumberField = form.getTextField(`${CanConfFields.poNumber}${pageMarker}`);

    form
      .getTextField(`${CanConfFields.vendorName}${pageMarker}`)
      .setText(`${salesOrder.organization.label}\n${formatAddress(mainAddress)}`);

    form
      .getTextField(`${CanConfFields.buyerName}${pageMarker}`)
      .setText(`${salesOrder.businessEntity?.name ?? ''}\n${formatAddress(salesOrder.shipment?.place)}`);

    form
      .getTextField(`${CanConfFields.purchaseDate}${pageMarker}`)
      .setText(salesOrder.submittedAt ? parseDate(salesOrder.submittedAt).toFormat('MM/dd/yyyy') : '');
    totalWeightField.setText(`${totalWeight.toLocaleString('en-US')}lbs`);
    totalWeightField.setFontSize(7);
    totalCostField.setText(
      money.toDollars(totalCost).toLocaleString('en-US', {
        currency: currency,
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
    totalCostField.setFontSize(7);
    poNumberField.setText(salesOrder.customerPo ?? '');
    poNumberField.setFontSize(7);

    const deliveryDateField = form.getTextField(`${CanConfFields.directShipmentDate}${pageMarker}`);
    const transportModeField = form.getTextField(`${CanConfFields.transportMode}${pageMarker}`);
    const shipmentPlaceField = form.getTextField(`${CanConfFields.shipmentPlace}${pageMarker}`);
    const currencyField = form.getTextField(`${CanConfFields.currency}${pageMarker}`);

    deliveryDateField.setText(deliveryDate ? parseDate(deliveryDate).toFormat('MM/dd/yyyy') : '');

    transportModeField.setText(`Reefer Truck ${shipment.temperatureMin}°F - ${shipment.temperatureMax}°F`);
    shipmentPlaceField.setText(formatAddress(shipTo));
    shipmentPlaceField.setFontSize(7);

    currencyField.setText(currency);
    // check fob
    // form.getCheckBox(`${CanConfFields.saleMode}${pageMarker}`).check();
  }

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  return { url, blob };
};
