import { DateTime } from 'luxon';

/**
 * Compute the payment due date for an order, based on the sales terms and invoiceDate.
 *
 * @param invoiceDate - the date an invoice is sent
 * @param salesTerms - the sales (or payment) terms for the order
 * @returns the payment due date
 */
const computePaymentDueDate = (invoiceDate: Date | string | null, salesTerms: string): Date | null => {
  if (!invoiceDate) {
    return null;
  }

  const invoiceDateTime = DateTime.fromISO(invoiceDate instanceof Date ? invoiceDate.toISOString() : invoiceDate);

  if (!invoiceDateTime.isValid) {
    return null;
  }

  // Compute the due date based on sales terms
  switch (salesTerms) {
    case 'COD':
      return invoiceDateTime.toJSDate();
    case 'NET_7':
      return invoiceDateTime.plus({ days: 7 }).toJSDate();
    case 'NET_10':
      return invoiceDateTime.plus({ days: 10 }).toJSDate();
    case 'NET_14':
      return invoiceDateTime.plus({ days: 14 }).toJSDate();
    case 'NET_21':
      return invoiceDateTime.plus({ days: 21 }).toJSDate();
    case 'NET_25':
      return invoiceDateTime.plus({ days: 25 }).toJSDate();
    case 'NET_30':
      return invoiceDateTime.plus({ days: 30 }).toJSDate();
    case 'NET_45':
      return invoiceDateTime.plus({ days: 45 }).toJSDate();
    case 'NET_60':
      return invoiceDateTime.plus({ days: 60 }).toJSDate();
    case 'NET_40': // deprecated
      return invoiceDateTime.plus({ days: 40 }).toJSDate();
    case 'NET_90': // deprecated
      return invoiceDateTime.plus({ days: 90 }).toJSDate();
    default: // NET_30 by default
      return invoiceDateTime.plus({ days: 30 }).toJSDate();
  }
};

export default computePaymentDueDate;
