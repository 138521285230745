export function printUrl(url: string) {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = url;
  document.body.appendChild(iframe);

  iframe.onload = () => {
    iframe.contentWindow?.print();
    window.addEventListener(
      'focus',
      () => {
        iframe.remove();
      },
      { once: true }
    );
  };
}
