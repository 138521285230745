/**
 * Transform algolia business entity record to business entity object
 */
export const transformBusinessEntity = (option) => {
  if (!option) {
    return null;
  }

  return {
    id: option.businessEntityId,
    name: option.name,
  };
};
