import { createContext, PropsWithChildren, ReactElement, useContext } from 'react';

export const SideModalContext = createContext({
  sideModal: undefined,
  contextProps: undefined,
  openSideModal: (_modal: ReactElement) => {},
  closeSideModal: () => {},
  open: false,
});

export default function SideModalContextProvider({
  children,
  contextProps,
}: PropsWithChildren<{ contextProps: unknown }>) {
  const [sideModal, setSideModal] = React.useState<ReactElement>(null);
  const [open, setOpen] = React.useState(false);

  const openSideModal = (modal: ReactElement) => {
    setSideModal(modal);
    setOpen(true);
  };

  const closeSideModal = () => {
    setOpen(false);
  };

  return (
    <SideModalContext.Provider
      value={{
        sideModal,
        openSideModal,
        closeSideModal,
        open,
        contextProps,
      }}
    >
      {children}
    </SideModalContext.Provider>
  );
}

export const useSideModal = () => useContext(SideModalContext);
