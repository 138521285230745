import { SalesTerms } from 'types/graphql';

type AllowedSalesTerms = Exclude<SalesTerms, 'NET_40' | 'NET_90'>; // Excluded types are no longer displayed to the user and will be deprecated

const SALES_TERMS_LABELS: Record<SalesTerms, string> = {
  COD: 'COD PACA Prompt',
  NET_7: 'Net 7 Days PACA Prompt',
  NET_10: 'Net 10 Days PACA Prompt',
  NET_14: 'Net 14 Days PACA Prompt',
  NET_21: 'Net 21 Days PACA Prompt',
  NET_25: 'Net 25 Days PACA Prompt',
  NET_30: 'Net 30 Days PACA Prompt',
  NET_45: 'Net 45 Days PACA Prompt',
  NET_60: 'Net 60 Days PACA Prompt',

  // The below terms are deprecated and can no longer be selected for new orders, but we have existing records that
  // use them so we have to still map them to a label until we can perform a data migration
  NET_40: 'Net 40 Days PACA Prompt',
  NET_90: 'Net 90 Days PACA Prompt',
};

export const SALES_TERMS_OPTIONS: AllowedSalesTerms[] = [
  'COD',
  'NET_7',
  'NET_10',
  'NET_14',
  'NET_21',
  'NET_25',
  'NET_30',
  'NET_45',
  'NET_60',
];

export const DEFAULT_SALES_TERMS = 'NET_30';

export const mapSalesTermsToLabel = (terms: SalesTerms) => SALES_TERMS_LABELS[terms];
