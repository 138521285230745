import { CreatePlaceInput } from 'types/graphql';

export default function computeFullAddress(place: Partial<CreatePlaceInput>) {
  if (!place) {
    return '';
  }

  const { streetAddress1, streetAddress2, city, state, postalCode, addressCountry } = place;

  const formattedAddress = [streetAddress1, streetAddress2, city, state, postalCode, addressCountry]
    .filter(Boolean)
    .join(', ');

  return formattedAddress;
}
