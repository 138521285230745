import { Editor, Plugin } from 'grapesjs';

const plugin: Plugin<{}> = (editor: Editor) => {
  const flexTableStyles = `
    .flex-table {
        display: flex;
        flex-direction: row;
        width: 100%;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        overflow: hidden;
        flex-wrap: nowrap;
        font-family: 'Montserrat';
        font-size: 9px;
    }

    .flex-table-cell-header {
      background: #F7F8F5;
      font-weight: 500;
    }

    .flex-table-cell-footer {
      font-weight: 500;
      border-top: 1px solid #E0E0E0;
    }
  `;

  const flexColumnStyles = `
    .flex-column {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        min-width: 0;
        overflow: hidden;
    }
  `;

  const flexCellStyles = `
    .flex-table-cell {
        flex: 0 0 25px;
        padding-vertical: 6px;
        margin-horizontal: 6px;
        box-sizing: border-box;
        overflow: ellipsis;
        white-space: nowrap;
        padding: 6px 10px;
    }
  `;

  const privateCls = ['.page', '.flex-table-cell', `.flex-table-column`, `.flex-table`];

  editor.on(
    'selector:add',
    (selector) => privateCls.indexOf(selector.getFullName()) >= 0 && selector.set('private', 1)
  );

  editor.Components.addType('page', {
    model: {
      defaults: {
        attributes: { class: 'page' },
        draggable: false,
      },
    },
  });

  const addCellComponent = (type) => {
    editor.Components.addType(`flex-table-cell-${type}`, {
      model: {
        defaults: {
          tagName: 'div',
          attributes: { class: ['flex-table-cell', `flex-table-${type}`] },
          components: ['<div class="flex-table-cell-content">Insert Text</div>'],
          styles: flexCellStyles,
          toolbar: [
            {
              attributes: { class: 'fa fa-table' },
              command: (e) => {
                e.runCommand('core:component-exit', { force: 1 });
                e.runCommand('core:component-exit', { force: 1 });
              },
            },
            {
              attributes: { class: 'fa fa-arrow-up' },
              command: (e) => e.runCommand('core:component-exit', { force: 1 }),
            },
            {
              attributes: {
                class: 'fa fa-arrows gjs-no-touch-actions',
                draggable: true,
              },
              command: 'tlb-move',
            },
          ],
        },
      },
    });
  };

  addCellComponent('header');
  addCellComponent('row');
  addCellComponent('footer');

  editor.Components.addType('flex-table', {
    model: {
      defaults: {
        tagName: 'div',
        attributes: { class: 'flex-table' },
        components: [
          {
            type: 'flex-column',
            attributes: { class: 'flex-column' },
            components: [
              {
                type: 'flex-table-cell-header',
                attributes: { class: 'flex-table-cell-header flex-table-cell' },
              },
              { type: 'flex-table-cell-row' },
              { type: 'flex-table-cell-footer' },
            ],
          },
          {
            type: 'flex-column',
            attributes: { class: 'flex-column' },
            components: [
              {
                type: 'flex-table-cell-header',
                attributes: { class: 'flex-table-cell-header flex-table-cell' },
              },
              { type: 'flex-table-cell-row' },
              { type: 'flex-table-cell-footer' },
            ],
          },
          {
            type: 'flex-column',
            attributes: { class: 'flex-column' },
            components: [
              {
                type: 'flex-table-cell-header',
                attributes: { class: 'flex-table-cell-header flex-table-cell' },
              },
              { type: 'flex-table-cell-row' },
              {
                type: 'flex-table-cell-footer',
              },
            ],
          },
        ],
        styles: `${flexTableStyles} ${flexColumnStyles} ${flexCellStyles}`,
      },
    },
  });

  editor.BlockManager.add('flex-table-block', {
    label: 'Table',
    content: { type: 'flex-table' },
    category: 'Tables',
    attributes: { class: 'gjs-fonts gjs-f-text' },
  });

  editor.DomComponents.addType('flex-table-variable-rows', {
    model: {
      defaults: {
        tagName: 'div',
        droppable: true,
        draggable: true,
        attributes: { 'data-gjs-type': 'variable' },
        styles: `${flexCellStyles}`,
        traits: [
          {
            type: 'text',
            label: 'Variable',
            name: 'variable',
            changeProp: true,
          },
          {
            type: 'text',
            label: 'Prefix',
            name: 'prefix',
            changeProp: true,
          },
          {
            type: 'text',
            label: 'Suffix',
            name: 'suffix',
            changeProp: true,
          },
          {
            type: 'select',
            label: 'Alignment',
            name: 'alignment',
            options: [
              { id: 'center', name: 'Center' },
              { id: 'left', name: 'Left' },
              { id: 'right', name: 'Right' },
            ],
          },
        ],
      },
      init() {
        this.listenTo(this, 'change:variable', this.handleContentChange);
        this.listenTo(this, 'change:alignment', this.handleContentChange);
      },
      handleContentChange() {
        this.view.render();
      },

      toHTML: function () {
        const column = this.parent();
        const grid = column?.parent();
        const tableVariableTrait = grid?.get('traits')?.get('variable');
        const tableVariable = tableVariableTrait?.getValue();

        const classes = this.get('classes').pluck('name').join(' ');

        const alignmentTrait = this.get('traits').get('alignment');
        const alignment = alignmentTrait?.getValue();

        const contentClass = alignment ? `class="flex-table-cell-content-${alignment}"` : '';

        return `
          {{#${tableVariable}.items}}
          <div class="flex-table-cell flex-table-row" {{^isFirst}}style="border-top: 1px solid #E0E0E0;"{{/isFirst}}>
            <div ${contentClass}>
              <span class="${classes}">{{{${this.get('variable')}}}}</span>
            </div>
          </div>
          {{/${tableVariable}.items}}
        `;
      },
    },

    view: {
      onRender: function () {
        const content = this.model.get('variable') || '<i>select variable</i>';
        const alignment = this.model.get('alignment') || 'left';
        const contentClass = alignment ? `flex-table-cell-content-${alignment}` : '';

        this.el.innerHTML = `
          <div data-gjs-type="flex-table-cell-row" class="flex-table-cell flex-table-row">
            <div class="${contentClass}">
              ${content}
            </div>
          </div>
        `;
      },
    },
  });

  editor.DomComponents.addType('flex-variable-table', {
    model: {
      defaults: {
        tagName: 'div',
        attributes: { class: 'flex-table' },
        components: [
          {
            type: 'flex-column',
            attributes: { class: 'flex-column', key: 'column_1' },
            components: [
              { type: 'flex-table-cell-header', content: [{ type: 'flex-table-cell-content' }] },
              { type: 'flex-table-variable-rows' },
              { type: 'flex-table-cell-footer' },
            ],
          },
          {
            type: 'flex-column',
            attributes: { class: 'flex-column', key: 'column_2' },
            components: [
              { type: 'flex-table-cell-header', content: [{ type: 'flex-table-cell-content' }] },
              { type: 'flex-table-variable-rows' },
              { type: 'flex-table-cell-footer' },
            ],
          },
          {
            type: 'flex-column',
            attributes: { class: 'flex-column', key: 'column_3' },
            components: [
              { type: 'flex-table-cell-header', content: [{ type: 'flex-table-cell-content' }] },
              { type: 'flex-table-variable-rows' },
              {
                type: 'flex-table-cell-footer',
              },
            ],
          },
        ],
        styles: `${flexTableStyles} ${flexColumnStyles}`,
        traits: [
          {
            label: 'Variable',
            type: 'text',
            name: 'variable',
            default: '',
          },
        ],
      },
      toHTML: function () {
        const variable = this.get('traits')?.get('variable');
        const variableValue = variable?.getValue();

        let childrenHtml = '';

        this.components().each((model) => {
          childrenHtml += model.toHTML();
        });

        return `
        {{^${variableValue}.isEmpty}}
          <div class="flex-table">
           ${childrenHtml}
          </div>
        {{/${variableValue}.isEmpty}}
        `;
      },
    },
  });

  editor.Traits.addType('list', {});

  editor.BlockManager.add('flex-dynamic-table-block', {
    label: 'Variable Table',
    content: { type: 'flex-variable-table' },
    category: 'Tables',
    attributes: { class: 'gjs-fonts gjs-f-text' },
  });
};

export default plugin;
