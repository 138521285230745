import { registerFragment } from '@redwoodjs/web/apollo';

registerFragment(gql`
  fragment PaymentFragment on Payment {
    id
    slug
    paymentAmount
    paymentDate
    paymentMethod
    payable {
      slug
      totalAmountDue
      receivedAt
      purchaseOrder {
        vendorPo
        # TODO: Add bill to for PO/payable
        shipment {
          place {
            ...PlaceAttributes
          }
        }
      }
      referenceNumber
    }
    buySellOrderPayable {
      slug
      receivedAt
      totalAmountDue
      referenceNumber
      buySellOrder {
        vendorSo
        vendorBillTo {
          ...PlaceAttributes
        }
      }
    }
    consignmentPayable {
      slug
      createdAt
      totalAmountDue
      referenceNumber
      workOrder {
        shipment {
          origin {
            ...PlaceAttributes
          }
        }
      }
    }
    standardPayable {
      slug
      invoiceDate
      totalAmountDue
      totalPaid
      purchaseOrder {
        id
        slug
        vendorPo # This is actually the vendor's SO # despite the name
      }
      salesOrder {
        id
        slug
        customerPo
      }
      buySellOrder {
        id
        slug
        vendorSo
      }
      workOrder {
        id
        slug
        fieldTicketId
      }
      referenceInvoiceNum
      payToAddress {
        ...PlaceAttributes
      }
    }
  }
`);

const GetRemittanceDocumentData = gql`
  query GetRemittanceDocumentData($slug: String!) {
    remittance(slug: $slug) {
      organization {
        label
        logoUrl
        primaryLocation {
          ...PlaceAttributes
        }
      }
      id
      slug
      payments {
        childPayments {
          ...PaymentFragment
        }

        ...PaymentFragment
      }
    }
  }
`;

export default {
  GetRemittanceDocumentData,
};
