import { Editor, Plugin } from 'grapesjs';

const plugin: Plugin<{}> = (editor: Editor) => {
  editor.Commands.add('open-style-manager', {
    run: (editor) => {
      const selected = editor.getSelected();

      if (!selected) {
        return;
      }

      selected.getSelectors().models.forEach((selector) => {
        const name = selector.get('name');

        editor.StyleManager.select(name);
      });
    },
  });

  editor.StyleManager.addSector('border', {
    name: 'Border',
    open: false,
    buildProps: [
      'border-style',
      'border-top-color',
      'border-top-width',
      'border-right-color',
      'border-right-width',
      'border-bottom-color',
      'border-bottom-width',
      'border-left-color',
      'border-left-width',
    ],
    properties: [
      {
        property: 'border-style',
        type: 'select',
        defaults: 'solid',
        options: [
          { id: 'none', value: 'none', name: 'None' },
          { id: 'solid', value: 'solid', name: 'Solid' },
          { id: 'dashed', value: 'dashed', name: 'Dashed' },
          { id: 'dotted', value: 'dotted', name: 'Dotted' },
        ],
      },
      {
        property: 'border-top-color',
        name: 'Border Top Color',
        type: 'color',
      },
      {
        property: 'border-top-width',
        name: 'Border Top Width',
        type: 'slider',
        units: ['px'],
        min: 0,
        max: 20,
      },
      {
        property: 'border-right-color',
        name: 'Border Right Color',
        type: 'color',
      },
      {
        property: 'border-right-width',
        name: 'Border Right Width',
        type: 'slider',
        units: ['px'],
        min: 0,
        max: 20,
      },
      {
        property: 'border-bottom-color',
        name: 'Border Bottom Color',
        type: 'color',
      },
      {
        property: 'border-bottom-width',
        name: 'Border Bottom Width',
        type: 'slider',
        units: ['px'],
        min: 0,
        max: 20,
      },
      {
        property: 'border-left-color',
        name: 'Border Left Color',
        type: 'color',
      },
      {
        property: 'border-left-width',
        name: 'Border Left Width',
        type: 'slider',
        units: ['px'],
        min: 0,
        max: 20,
      },
    ],
  });
};

export default plugin;
