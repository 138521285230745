import { useEffect, useMemo, useState } from 'react';

import { useEditorMaybe } from '@grapesjs/react';
import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Typography,
} from '@mui/material';
import DocumentGenerator from 'shared/documents/DocumentGenerator';

import { navigate, routes } from '@redwoodjs/router';
import { useQuery } from '@redwoodjs/web';

import convertToTitleCase from 'src/utils/convertToTitleCase';

import DocumentTemplateApi from '../api/DocumentTemplate.api';
import { documentTypeGroups, documentTypeOptions } from '../pages/DocumentEditorPage/DocumentEditorPage';

type DocumentSections = Record<
  string,
  {
    name: string;
    entityType: string;
    templateState: string;
    type: string;
    id: number | string;
    isTemplate?: boolean;
  }[]
>;

export default function DocumentStorageLoadModal({
  open,
  onClose,
  setDocumentName,
  setDocumentType,
  setGenerationOptions,
}) {
  const editor = useEditorMaybe();

  const { refetch } = useQuery(DocumentTemplateApi.GetDocumentTemplatesState, { skip: true });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [documentSections, setDocumentSections] = useState<DocumentSections>({});

  useEffect(() => {
    const fetchSections = async () => {
      const sections: DocumentSections = {};

      for (const { type, entity } of documentTypeOptions) {
        const defaultForType = DocumentGenerator.defaultTemplates[entity]?.[type];

        if (!defaultForType) {
          continue;
        }

        const defaultTemplate = await defaultForType();
        const templateMetadata = { id: `template-${entity}-${type}`, isTemplate: true };

        if (!sections[entity]) {
          sections[entity] = defaultTemplate ? [{ ...templateMetadata, ...defaultTemplate }] : [];
        } else {
          sections[entity].push({ ...templateMetadata, ...defaultTemplate });
        }
      }

      const { data } = await refetch();
      const customTemplates = data?.documentTemplates ?? [];

      customTemplates.forEach((template) => {
        const section = sections[template.entityType];

        if (section) {
          section.push(template);
        } else {
          sections[template.entityType] = [template];
        }
      });
      return sections;
    };

    fetchSections().then((sections) => setDocumentSections(sections));
  }, [refetch]);

  const handleClose = () => {
    setSelectedTemplate(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogContent>
        <DialogContentText>Select document to open:</DialogContentText>
        <Box p={2} display="flex" flexDirection="column" gap={2}>
          {Object.entries(documentSections).map(([entity, templates]) => {
            if (templates?.length === 0) {
              return null;
            }

            return (
              <Box key={entity}>
                <Typography variant="subtitle2">{documentTypeGroups[entity]} Documents</Typography>
                <Divider />
                <Box p={1} display="flex" flexWrap="wrap" gap={2}>
                  {templates.map((template) => {
                    const isSelected = selectedTemplate?.id === template.id;

                    return (
                      <Card key={template.name}>
                        <CardActionArea
                          onClick={() => {
                            setSelectedTemplate({ ...template, entityType: entity });
                          }}
                        >
                          <CardContent>
                            <Box display="flex" alignItems="center" gap={2}>
                              <Typography fontSize="18px" fontWeight="semibold">
                                {template.name} {template?.isTemplate && ' (Agriful)'}
                              </Typography>
                              {isSelected && <Check color="success" />}
                            </Box>
                            <Chip label={convertToTitleCase(template.type)} size="small" />
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!selectedTemplate}
          onClick={() => {
            const template =
              typeof selectedTemplate.templateState === 'string'
                ? JSON.parse(selectedTemplate.templateState)
                : selectedTemplate.templateState;

            editor.loadProjectData(template);

            setDocumentName(selectedTemplate.name);
            setDocumentType({
              entity: selectedTemplate.entityType,
              type: selectedTemplate.type,
              label: convertToTitleCase(selectedTemplate.type),
            });
            setGenerationOptions(JSON.stringify(selectedTemplate.generationOptions ?? {}, null, 2));

            navigate(
              routes.documentEditor(
                {
                  name: selectedTemplate.name,
                  type: selectedTemplate.type,
                  entity: selectedTemplate.entityType,
                },
                { replace: true }
              )
            );

            setSelectedTemplate(null);
            onClose();
          }}
        >
          Open
        </Button>
      </DialogActions>
    </Dialog>
  );
}
