import useServerEnhancedGridQuery from 'src/modules/grids/serverEnhanced/useServerEnhancedGridQuery';
import { ServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';

const GET_INVENTORY_TRANSFERS_PAGE = gql`
  query GetInventoryTransferPage($input: StandardPageInput) {
    inventoryTransferPage(input: $input) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          status
          slug
          contact {
            user {
              firstName
              lastName
            }
          }
          shipment {
            shipDate
            deliveryDate
            origin {
              ...PlaceAttributes
            }
            destination {
              ...PlaceAttributes
            }
            temperatureMin
            temperatureMax
          }
        }
      }
    }
  }
`;

export function useInventoryTransfersPage(gridState: ServerEnhancedGridState) {
  return useServerEnhancedGridQuery({
    queryKey: 'inventoryTransferPage',
    query: GET_INVENTORY_TRANSFERS_PAGE,
    gridState,
  });
}
