import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { IconButton, IconButtonProps } from '@mui/material';

import NoteRequiredModal from 'src/components/containers/modals/NoteRequiredModal';

import Hint from '../Hint';

export default function FlagButton({
  flagged,
  handleFlagChange,
  label,
  id,
  size = 'medium',
  buttonProps = {},
  autoHandleCommentPost = true,
}: {
  label?: string;
  flagged: boolean;
  handleFlagChange: (flagged: boolean, comment: string) => Promise<unknown>;
  id?: {
    salesOrderId?: number;
    purchaseOrderId?: number;
    buySellOrderId?: number;
    workOrderId?: number;
  };
  size?: 'small' | 'medium';
  buttonProps?: IconButtonProps;
  autoHandleCommentPost?: boolean;
}) {
  const [open, setOpen] = React.useState(false);

  const Icon = flagged ? FlagIcon : FlagOutlinedIcon;

  const context = flagged ? `clear the flag from this order` : 'mark this order as flagged';

  const confirmLabel = flagged ? 'Clear Flag' : 'Flag';

  return (
    <>
      <Hint title="Flag">
        <IconButton size={size} {...buttonProps} onClick={() => setOpen(true)}>
          <Icon fontSize={size} />
        </IconButton>
      </Hint>
      {label && (
        <NoteRequiredModal
          headerLabel={`Flagging ${label}`}
          contentText={`Are you sure you want to ${context}? If so, enter relevant notes below.`}
          confirmLabel={confirmLabel}
          open={open}
          external={false}
          autoHandleCommentPost={autoHandleCommentPost}
          onSubmit={(comment) => {
            handleFlagChange(!flagged, comment);
          }}
          handleClose={() => {
            setOpen(false);
          }}
          postCommentTo={id}
        />
      )}
    </>
  );
}
