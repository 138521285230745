import { navigate, routes } from '@redwoodjs/router';
import { useMutation } from '@redwoodjs/web';

export const CREATE_PURCHASE_ORDER_MUTATION = gql`
  mutation createPurchaseOrder($input: CreatePurchaseOrderInput!) {
    createPurchaseOrder(input: $input) {
      id
      slug
    }
  }
`;

export const CREATE_PURCHASE_ORDER_TEMPLATE_MUTATION = gql`
  mutation createPurchaseOrderTemplate($name: String!) {
    createPurchaseOrderTemplate(name: $name) {
      id
      slug
    }
  }
`;

export const CREATE_PURCHASE_ORDER_FROM_TEMPLATE_MUTATION = gql`
  mutation createPurchaseOrderFromTemplate($orderTemplateId: Int!) {
    createPurchaseOrderFromTemplate(orderTemplateId: $orderTemplateId) {
      id
      slug
    }
  }
`;

export function useCreatePurchaseOrder() {
  const [createPurchaseOrder] = useMutation(CREATE_PURCHASE_ORDER_MUTATION);

  return () => {
    createPurchaseOrder({
      variables: { input: { status: 'DRAFT' } },
      onCompleted: ({ createPurchaseOrder }) => {
        navigate(routes.purchaseOrder({ slug: createPurchaseOrder.slug }));
      },
    });
  };
}

export function useCreatePurchaseOrderTemplate() {
  const [createPurchaseOrderTemplate] = useMutation(CREATE_PURCHASE_ORDER_TEMPLATE_MUTATION);

  return (name: string) => {
    createPurchaseOrderTemplate({
      variables: { name },
    }).then(({ data }) => {
      const { slug } = data.createPurchaseOrderTemplate;

      navigate(routes.purchaseOrder({ slug }));
    });
  };
}

export function useCreatePurchaseOrderFromTemplate() {
  const [createPurchaseOrderFromTemplate] = useMutation(CREATE_PURCHASE_ORDER_FROM_TEMPLATE_MUTATION);

  return (orderTemplateId: number) => {
    createPurchaseOrderFromTemplate({
      variables: { orderTemplateId },
    }).then(({ data }) => {
      const { slug } = data.createPurchaseOrderFromTemplate;
      navigate(routes.purchaseOrder({ slug }));
    });
  };
}
