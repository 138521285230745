const validateSlugFormat = (slug: string): string | null => {
  // Define your validation rules here
  if (slug.length < 3 && slug.length > 20) return 'Label must be between 3 and 20 characters';
  if (/\s/.test(slug)) return 'Label must not contain spaces';
  if (!/^[A-Z0-9-]*$/.test(slug)) return 'Label must be alphanumeric and all caps';
  if (/^-|-$/.test(slug)) return 'Label must not start or end in hyphens';
  if (/--+/.test(slug)) return 'Label must not include repeating hyphens';
  // Add any other validations as needed

  return null; // Return null if no errors
};

export default {
  validateSlugFormat,
};
