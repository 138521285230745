import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import { docStyles } from '../utils/styles';

export function TermsAndTotals({
  grandTotal,
  includePacaTerms = true,
  terms = {
    title: 'TERMS',
    text: [
      'The perishable agricultural commodities listed on this invoice are sold subject to the statutory trust authorized by section 5(c) of the Perishable Agricultural Commodities Act, 1930 (7 U.S.C. 499e(c)). The seller of these commodities retains a trust claim over these commodities, all inventories of food or other products derived from these commodities, and any receivables or proceeds from the sale of these commodities until full payment is received.',
      'A FINANCE CHARGE calculated at the rate of 11/2% products derived from these commodities, and any receivables or proceeds from the sale of these commodities until full payment is received.',
      "Should any action be commenced between the parties to this contract concerning the sums due hereunder or the rights and duties of any party hereto or the interpretation of this contract, the prevailing party in such action shall be entitled to, in addition to such other relief as may be granted, an award as and for the actual attorney's fees and costs in bringing such action and/or enforcing any judgment granted therein.",
    ],
  },
}: {
  grandTotal?: string;
  includePacaTerms?: boolean;
  terms?: {
    title?: string;
    text: string[];
  };
}) {
  return (
    <View style={docStyles.termsAndTotalsContainer} wrap={false}>
      {includePacaTerms ? (
        <View
          style={{
            ...docStyles.termsContainer,
            width: grandTotal ? '65%' : '100%',
          }}
        >
          {terms.title && <Text style={docStyles.termsTitle}>{terms.title}</Text>}

          {terms.text.map((text, index) => (
            <Text key={index} style={docStyles.termsText}>
              {text}
            </Text>
          ))}
        </View>
      ) : (
        // Empty view to keep the layout consistent and push the totals to the right
        <View></View>
      )}

      {grandTotal && (
        <View style={docStyles.totalsContainer}>
          {/* TODO: Add tax or other sub-total rows here */}

          <View style={docStyles.grandTotalRow}>
            <Text style={docStyles.grandTotalTitle}>AMOUNT DUE: </Text>

            <Text style={docStyles.grandTotalText}>{grandTotal}</Text>
          </View>
        </View>
      )}
    </View>
  );
}
