import { useMemo } from 'react';

import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { GridColObj } from 'src/components/containers/grids/DataGrid';
import CostBasisCell from 'src/modules/systemManagement/pricingSheets/components/CostBasisCell';
import PricingFormulaCell from 'src/modules/systemManagement/pricingSheets/components/PricingFormulaCell';
import PricingValueCell from 'src/modules/systemManagement/pricingSheets/components/PricingValueCell';
import { PricingFormula } from 'src/modules/systemManagement/pricingSheets/constants';
import useRemoveProductFromSheet from 'src/modules/systemManagement/pricingSheets/hooks/useRemoveProductFromSheet';
import { calculateMarginAndPrice } from 'src/modules/systemManagement/pricingSheets/utils';
import { formatCurrency } from 'src/utils/grid/cell/cellFormatters';
import money from 'src/utils/money';

export const columnOrderToSync = [
  'productName',
  'productCode',
  'costBasis',
  'pricingFormula',
  'pricingValue',
  'sheetPricingValue',
  'pricePerUnit',
  'edit',
];

export default function usePricingSheetEntriesColumns(
  sheetPricingFormula: PricingFormula,
  sheetPricingValue?: number,
  sheetRoundingValue?: number
): GridColObj {
  const removeProduct = useRemoveProductFromSheet();

  return useMemo(
    () => ({
      productName: {
        headerName: 'Product',
        type: 'string',
        flex: 1.5,
      },
      productCode: {
        headerName: 'Code',
        type: 'string',
        flex: 1,
      },
      note: {
        headerName: 'Print Note',
        editable: true,
        type: 'string',
        flex: 1,
      },
      costBasis: {
        headerName: 'Cost Basis',
        type: 'number',
        flex: 1,
        valueFormatter: (value) => formatCurrency({ value }),
        renderCell: ({ row }) => (
          <CostBasisCell
            costBasis={row.costBasis}
            productCode={row.productCode}
            productName={row.productName}
            productId={row.productId}
          />
        ),
      },
      // for CUSTOM pricing formula, each entry has it's own pricing formula and margin value
      ...(sheetPricingFormula === 'CUSTOM' && {
        pricingFormula: {
          type: 'string',
          flex: 1,
          headerName: 'Pricing Formula',
          renderCell: ({ row }) => <PricingFormulaCell entryId={row.id} pricingFormula={row.pricingFormula} />,
        },
        pricingValue: {
          headerName: 'Margin',
          type: 'number',
          flex: 1,
          renderCell: ({ row }) => (
            <PricingValueCell
              entryId={row.id}
              costBasis={row.costBasis}
              sheetRoundingValue={sheetRoundingValue}
              pricingFormula={row.pricingFormula}
              pricingValue={row.pricingValue}
            />
          ),
        },
      }),
      // for sheet based pricing, just calculate and show the margin based on the sheet settings
      ...(sheetPricingFormula?.endsWith('MARGIN') && {
        sheetPricingValue: {
          headerName: sheetPricingFormula === 'COST_BASIS_PERCENT_MARGIN' ? `${sheetPricingValue}% margin` : '$ margin',
          type: 'number',
          flex: 1,
          valueGetter: (_value, row) => {
            const { margin } = calculateMarginAndPrice(
              row.costBasis,
              sheetPricingFormula,
              sheetPricingValue,
              sheetRoundingValue
            );
            return margin ? money.toDollars(margin) : 0;
          },
          valueFormatter: (value) => formatCurrency({ value }),
        },
      }),
      pricePerUnit: {
        headerName: 'FOB Price',
        type: 'number',
        flex: 1,
        valueGetter: (_value, row) => {
          const { total } = calculateMarginAndPrice(
            row.costBasis,
            sheetPricingFormula === 'CUSTOM' ? row.pricingFormula : sheetPricingFormula,
            sheetPricingFormula === 'CUSTOM' ? row.pricingValue : sheetPricingValue,
            sheetRoundingValue
          );
          return total ? money.toDollars(total) : 0;
        },
        valueFormatter: (value) => formatCurrency({ value }),
      },
      edit: {
        type: 'button',
        headerName: '',
        disableExport: true,
        resizable: false,
        width: 72,
        align: 'center',
        sortable: false,
        renderCell: ({ row }) => (
          <IconButton className="invisible group-hover:visible" onClick={() => removeProduct(row.id)}>
            <Tooltip title="Remove from pricing list" placement="top">
              <Delete />
            </Tooltip>
          </IconButton>
        ),
      },
    }),
    [removeProduct, sheetPricingFormula, sheetPricingValue, sheetRoundingValue]
  );
}
