import { useEffect, useState } from 'react';

import { Metadata } from '@redwoodjs/web';

const debugTime = 500;

let startTime = Date.now();

export default function ScanDebugPage() {
  const [capture, setCapture] = useState([]);

  useEffect(() => {
    const keypress = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const now = Date.now();

      if (now - startTime > debugTime) {
        startTime = now;
        setCapture([{ key: e.key, time: 0, now }]);
        return;
      }

      setCapture((prev) => {
        const last = prev[prev.length - 1];
        return [...prev, { key: e.key, time: now - last.now, now }];
      });
    };

    document.addEventListener('keypress', keypress);
    return () => {
      document.removeEventListener('keypress', keypress);
    };
  }, []);

  const inputTimings = capture.slice(1).map((c) => c.time);

  return (
    <>
      <Metadata title="Scan Debug" />
      <div className="flex flex-col gap-6 p-4">
        <h3>Agriful Scan Debug</h3>
        <p>Capture: {capture.map((c) => c.key).join(' ')}</p>
        {inputTimings.length > 0 && (
          <p>Avg time between input: {(inputTimings.reduce((a, b) => a + b, 0) / inputTimings.length).toFixed(2)}ms</p>
        )}
      </div>
    </>
  );
}
