import React from 'react';

import { Prisma } from '@prisma/client';
import { Text, View } from '@react-pdf/renderer';

import styles, { docStyles } from '../utils/styles';

export type ExternalNotesDocumentsProp = Prisma.NoteGetPayload<{
  select: {
    id: true;
    note: true;
    createdAt: true;
  };
  include: {
    membership: {
      select: { user: true };
    };
  };
}>;

export function Notes({ notes }: { notes: ExternalNotesDocumentsProp[] }) {
  if (notes.length === 0) {
    return null;
  }

  return (
    <View style={docStyles.notesContainer}>
      <View style={docStyles.notesHeader}>
        <Text>NOTES</Text>
      </View>

      <View>
        {notes.map((note) => (
          <View key={note.id} style={docStyles.noteContainer}>
            <View style={docStyles.noteText}>
              <Text>{note.text}</Text>
            </View>

            <View style={docStyles.noteMetadata}>
              <Text>{note.membership.user.email}</Text>

              <Text style={styles.noteDate}>{new Date(note.createdAt).toDateString()}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}
