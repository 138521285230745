import { useEffect } from 'react';

import { Tune } from '@mui/icons-material';
import { Box } from '@mui/material';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateTime } from 'luxon';
import { asUTCDate, parseDate, startOfDay } from 'shared/utils/DateTime';
import { PurchaseOrderStatus, SalesOrderStatus, WorkOrderStatus } from 'types/graphql';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { MetaTags, useQuery } from '@redwoodjs/web';

import { AddressAutocomplete } from 'src/components';
import Autocomplete from 'src/components/atoms/Autocomplete';
import FavoriteButton from 'src/components/atoms/buttons/FavoriteButton';
import { statusDefs } from 'src/components/atoms/StatusBadge';
import OrderBoardCell from 'src/components/cells/OrderBoardCell/OrderBoardCell';
import { MenuDropDown } from 'src/components/containers/grids/Toolbar';
import { SlimTextField } from 'src/components/containers/labels/LabelsContainer';
import { MainCard } from 'src/components/containers/MainCard';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import { entityTypes } from 'src/utils/entityTypes';

type OrderBoardOption = {
  label: string;
  value: 'PurchaseOrder' | 'SalesOrder' | 'WorkOrder';
};

export type BoardLayout = {
  colIndex: number;
  id: number;
  __typename: 'PurchaseOrder' | 'SalesOrder' | 'WorkOrder';
};

export const useBoardStore = create(
  persist<{
    dateRangeFilter: DateRange<DateTime>;
    warehouses: { description: string; id: number }[];
    orderTypes: OrderBoardOption[];
    boardLayout: BoardLayout[][];
    orderStatuses: (PurchaseOrderStatus | SalesOrderStatus | WorkOrderStatus)[];
    setDateRangeFilter: (value) => void;
    setWarehouses: (value) => void;
    setBoardLayout: (value) => void;
    setOrderTypes: (value) => void;
    setOrderStatuses: (value) => void;
  }>(
    (set) => ({
      dateRangeFilter: [startOfDay(), startOfDay()],
      boardLayout: [],
      warehouses: [],
      orderTypes: [
        {
          label: 'Purchase Orders',
          value: 'PurchaseOrder',
        },
        {
          label: 'Sales Orders',
          value: 'SalesOrder',
        },
        {
          label: 'Work Orders',
          value: 'WorkOrder',
        },
      ],
      orderStatuses: [
        'SUBMITTED',
        'PICKING',
        'SHIPPED',
        'CLOSED',
        'RECEIVING',
        'PENDING_LIQUIDATION',
        'PENDING_SETTLEMENT',
      ],
      setDateRangeFilter: (value) => {
        set({ dateRangeFilter: value });
      },
      setWarehouses: (value) => {
        set({ warehouses: value });
      },
      setBoardLayout: (value) => {
        set({ boardLayout: value });
      },
      setOrderTypes: (value) => {
        set({ orderTypes: value });
      },
      setOrderStatuses: (value) => {
        set({ orderStatuses: value });
      },
    }),
    {
      name: 'order-board-v2',
    }
  )
);

const filterStatuses = (statuses, types) => {
  return statuses.filter((status) => {
    const orderTypeValues = types.map((type) => type.value);

    return orderTypeValues.some((type) => {
      return entityTypes[type].statuses.includes(status);
    });
  });
};

const OrderBoardFilters = ({ places }) => {
  const dateFilter = useBoardStore((state) => state.dateRangeFilter);
  const warehouses = useBoardStore((state) => state.warehouses);
  const orderTypes = useBoardStore((state) => state.orderTypes);
  const orderStatuses = useBoardStore((state) => state.orderStatuses);
  const setDateRangeFilter = useBoardStore((state) => state.setDateRangeFilter);
  const setWarehouses = useBoardStore((state) => state.setWarehouses);
  const setOrderTypes = useBoardStore((state) => state.setOrderTypes);
  const setOrderStatuses = useBoardStore((state) => state.setOrderStatuses);

  useEffect(() => {
    const shouldInitWarehouse = warehouses.length === 0 && places.length > 0;

    if (shouldInitWarehouse) {
      setWarehouses([places[0]]);
    }
  }, [places, warehouses, setWarehouses]);

  return (
    <Box display="flex" gap={1}>
      <MenuDropDown icon={<Tune />} hint="Select date range">
        <Box p={1} display="flex" flexDirection="column" gap={1.5}>
          <AddressAutocomplete
            fieldLabel="Warehouse"
            addressBook={places}
            selectedAddress={warehouses}
            multiple
            onChange={setWarehouses}
          />
          <Autocomplete<OrderBoardOption, true>
            label="Order Type"
            value={orderTypes}
            onChange={(_, value) => {
              setOrderTypes(value);
            }}
            multiple
            options={[
              {
                label: 'Purchase Orders',
                value: 'PurchaseOrder',
              },
              {
                label: 'Sales Orders',
                value: 'SalesOrder',
              },
              {
                label: 'Work Orders',
                value: 'WorkOrder',
              },
            ]}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            getOptionLabel={(option) => option.label}
          />
          <Autocomplete<SalesOrderStatus | WorkOrderStatus | PurchaseOrderStatus, true>
            label="Order Status"
            value={filterStatuses(orderStatuses, orderTypes)}
            onChange={(_, value) => {
              setOrderStatuses(value);
            }}
            multiple
            options={filterStatuses(
              [
                'DRAFT',
                'SUBMITTED',
                'PICKING',
                'SHIPPED',
                'CLOSED',
                'PENDING_LIQUIDATION',
                'RECEIVING',
                'PENDING_SETTLEMENT',
                'CLOSED',
              ],
              orderTypes
            )}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => statusDefs[option].label}
          />
        </Box>
      </MenuDropDown>
      <DateRangePicker<DateTime>
        value={dateFilter.map(asUTCDate) as DateRange<DateTime>}
        showDaysOutsideCurrentMonth
        renderInput={(startProps, endProps) => (
          <Box display="flex" gap={1} maxWidth="230px" pt={1}>
            <SlimTextField {...startProps} label="Start" />
            <SlimTextField {...endProps} label="End" />
          </Box>
        )}
        onChange={(dateRange) => {
          const valid = dateRange.every((date) => date?.isValid);

          if (valid) {
            setDateRangeFilter(
              dateRange.map((date) => parseDate(date)?.setZone('utc', { keepLocalTime: true })) as DateRange<DateTime>
            );
          }
        }}
      />
    </Box>
  );
};

const FIND_WAREHOUSES_QUERY = gql`
  query FindWarehousesQuery {
    places(addressRoles: [WAREHOUSE], businessEntityId: null, strict: true) {
      id
      description
      streetAddress1
    }
  }
`;

const OrderBoardPage = () => {
  const { data } = useQuery(FIND_WAREHOUSES_QUERY);
  const dateRangeFilter = useBoardStore((state) => state.dateRangeFilter);
  const statuses = useBoardStore((state) => state.orderStatuses);

  const poStatuses = statuses.filter((status) => entityTypes.PurchaseOrder.statuses.includes(status));

  const soStatuses = statuses.filter((status) => entityTypes.SalesOrder.statuses.includes(status));

  const gpStatuses = statuses.filter((status) => entityTypes.WorkOrder.statuses.includes(status));

  return (
    <>
      <MetaTags title="Order Board" description="OrderBoard page" />
      <PageHeader pageType={PageType.OrderBoard}>
        <Box sx={{ ml: 'auto', display: 'flex', my: 'auto', gap: 1 }}>
          <FavoriteButton label="Order Board" />
          <OrderBoardFilters places={data?.places ?? []} />
        </Box>
      </PageHeader>
      <PageBody>
        <MainCard noPadding>
          <OrderBoardCell
            dateRange={dateRangeFilter}
            purchaseOrderStatuses={poStatuses}
            salesOrderStatuses={soStatuses}
            workOrderStatuses={gpStatuses}
          />
        </MainCard>
      </PageBody>
    </>
  );
};

export default OrderBoardPage;
