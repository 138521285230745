import { useCallback, useState } from 'react';

import { Button, Dialog, InputAdornment, TextField } from '@mui/material';

import useBulkEditProductCostBasis from 'src/modules/systemManagement/pricingSheets/hooks/useBulkEditProductCostBasis';
import money from 'src/utils/money';

type BulkEditCostBasisProps = {
  selectedRows: any[];
  afterSubmit?: (ids: number[], costBasis: number) => void;
};

export default function BulkEditCostBasis({ selectedRows, afterSubmit }: BulkEditCostBasisProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(0);
  const bulkEditProductCostBasis = useBulkEditProductCostBasis();

  const onSubmit = useCallback(() => {
    const ids = selectedRows.map((row) => row.productId ?? row.id);
    const cents = money.toCents(editValue);

    bulkEditProductCostBasis(ids, cents);
    afterSubmit?.(ids, cents);
  }, [bulkEditProductCostBasis, selectedRows, editValue, afterSubmit]);

  return (
    <>
      <Button variant="outlined" disabled={!selectedRows.length} onClick={() => setIsEditing(true)}>
        Bulk Edit Cost Basis {selectedRows.length ? `(${selectedRows.length})` : ''}
      </Button>
      {isEditing && (
        <Dialog open onClose={() => setIsEditing(false)}>
          <div className="flex flex-col p-6 gap-4 w-[356px]">
            <b>Editing cost basis for {selectedRows.length} products</b>
            <TextField
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              label="Cost basis"
              variant="outlined"
              value={editValue}
              type="number"
              onChange={(e) => setEditValue(parseFloat(e.target.value))}
            />
            <p>Editing the cost basis for these products will change them in ALL pricing sheets.</p>
            <footer className="flex gap-2 justify-end mt-4 -mb-2 -mr-2">
              <Button onClick={() => setIsEditing(false)}>Cancel</Button>
              <Button
                onClick={() => {
                  onSubmit();
                  setIsEditing(false);
                }}
              >
                Save
              </Button>
            </footer>
          </div>
        </Dialog>
      )}
    </>
  );
}
