import * as React from 'react';

import AccountIcon from '@mui/icons-material/AccountCircle';
// import SettingsIcon from '@mui/icons-material/Settings';
// import StarIcon from '@mui/icons-material/StarOutline';
import { Box, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';

import { navigate, routes } from '@redwoodjs/router';

import { useAuth } from 'src/auth';

import { DropdownMenuItem } from '../DropdownMenuItem';

export function UserMenu() {
  const { currentUser, logOut } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: theme.palette.primary.contrastText,
        }}
      >
        <AccountIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Typography>{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
          <Typography variant="body2">
            {currentUser.emailAddress ?? currentUser.emailAddresses?.[0].emailAddress}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ py: 1 }}>
          <DropdownMenuItem
            Icon={AccountIcon}
            onClick={() => {
              navigate(routes.userProfile());
              handleClose();
            }}
            label="Profile"
          />
        </Box>
        <Divider></Divider>
        <MenuItem
          onClick={() => {
            handleClose();
            logOut({ redirectTo: '/login' });
          }}
        >
          LOG OUT
        </MenuItem>
      </Menu>
    </div>
  );
}
