import StarFilledIcon from '@mui/icons-material/Star';
import StarEmptyIcon from '@mui/icons-material/StarOutline';
import { IconButton } from '@mui/material';

import { useLocation } from '@redwoodjs/router';

import { useSidebar } from '../../../providers/SidebarContextProvider';
import Hint from '../Hint';

export type FavoriteButtonProps = { label: string };

export default function FavoriteButton({ label }: FavoriteButtonProps) {
  const { pathname } = useLocation();
  const { addFavorite, removeFavorite, favorites } = useSidebar();
  const isFavorited = favorites.some((favorite) => favorite.route === pathname);
  const StarIcon = isFavorited ? StarFilledIcon : StarEmptyIcon;

  return (
    <Hint title="Add to favorites">
      <IconButton
        size="medium"
        sx={{
          my: 'auto',
        }}
        onClick={() => {
          if (isFavorited) {
            removeFavorite(pathname);
          } else {
            addFavorite(pathname, label);
          }
        }}
      >
        <StarIcon sx={{ my: 'auto', color: 'action.active' }} />
      </IconButton>
    </Hint>
  );
}
