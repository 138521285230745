import { useCallback, useState } from 'react';

import { Button, Dialog, TextField } from '@mui/material';

import Autocomplete from 'src/components/atoms/Autocomplete';
import { pricingFormulaOptions } from 'src/modules/systemManagement/pricingSheets/constants';
import useBulkEditPricingSheetEntry from 'src/modules/systemManagement/pricingSheets/hooks/useBulkEditPricingSheetEntry';
import money from 'src/utils/money';

type BulkEditFormulaProps = {
  selectedRows: any[];
};

export default function BulkEditFormula({ selectedRows }: BulkEditFormulaProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [pricingFormula, setPricingFormula] = useState(pricingFormulaOptions[0]);
  const [pricingValue, setPricingValue] = useState(0);
  const bulkEditPricingSheetEntry = useBulkEditPricingSheetEntry(selectedRows.map((row) => row.id));

  const onSubmit = useCallback(() => {
    bulkEditPricingSheetEntry({
      pricingFormula: pricingFormula.value,
      pricingValue: pricingFormula.value === 'COST_BASIS_FLAT_MARGIN' ? money.toCents(pricingValue) : pricingValue,
    });
  }, [bulkEditPricingSheetEntry, pricingFormula.value, pricingValue]);

  return (
    <>
      <Button variant="contained" disabled={!selectedRows.length} onClick={() => setIsEditing(true)}>
        Bulk Edit Pricing Formula {selectedRows.length ? `(${selectedRows.length})` : ''}
      </Button>
      {isEditing && (
        <Dialog open onClose={() => setIsEditing(false)}>
          <div className="flex flex-col p-6 gap-4 w-[356px]">
            <b>Editing pricing formula for {selectedRows.length} products</b>
            <Autocomplete<{ name: string; value: string }>
              label="Pricing settings"
              value={pricingFormula}
              options={pricingFormulaOptions}
              getOptionLabel={(option) => `${option.name}`}
              onChange={(_, value) => setPricingFormula(value)}
            />
            {pricingFormula.value.endsWith('MARGIN') && (
              <TextField
                label={pricingFormula.value === 'COST_BASIS_PERCENT_MARGIN' ? '% margin' : '$ margin'}
                variant="outlined"
                value={pricingValue}
                type="number"
                onChange={(e) => setPricingValue(parseFloat(e.target.value))}
              />
            )}
            <footer className="flex gap-2 justify-end mt-4 -mb-2 -mr-2">
              <Button onClick={() => setIsEditing(false)}>Cancel</Button>
              <Button
                onClick={() => {
                  onSubmit();
                  setIsEditing(false);
                }}
              >
                Save
              </Button>
            </footer>
          </div>
        </Dialog>
      )}
    </>
  );
}
