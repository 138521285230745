export const countries = [
  { name: 'Afghanistan', code: 'AFG', id: 0 },
  { name: 'Åland Islands', code: 'ALA', id: 1 },
  { name: 'Albania', code: 'ALB', id: 2 },
  { name: 'Algeria', code: 'DZA', id: 3 },
  { name: 'American Samoa', code: 'ASM', id: 4 },
  { name: 'Andorra', code: 'AND', id: 5 },
  { name: 'Angola', code: 'AGO', id: 6 },
  { name: 'Anguilla', code: 'AIA', id: 7 },
  { name: 'Antarctica', code: 'ATA', id: 8 },
  { name: 'Antigua and Barbuda', code: 'ATG', id: 9 },
  { name: 'Argentina', code: 'ARG', id: 10 },
  { name: 'Armenia', code: 'ARM', id: 11 },
  { name: 'Aruba', code: 'ABW', id: 12 },
  { name: 'Australia', code: 'AUS', id: 13 },
  { name: 'Austria', code: 'AUT', id: 14 },
  { name: 'Azerbaijan', code: 'AZE', id: 15 },
  { name: 'Bahamas', code: 'BHS', id: 16 },
  { name: 'Bahrain', code: 'BHR', id: 17 },
  { name: 'Bangladesh', code: 'BGD', id: 18 },
  { name: 'Barbados', code: 'BRB', id: 19 },
  { name: 'Belarus', code: 'BLR', id: 20 },
  { name: 'Belgium', code: 'BEL', id: 21 },
  { name: 'Belize', code: 'BLZ', id: 22 },
  { name: 'Benin', code: 'BEN', id: 23 },
  { name: 'Bermuda', code: 'BMU', id: 24 },
  { name: 'Bhutan', code: 'BTN', id: 25 },
  { name: 'Bolivia (Plurinational State of)', code: 'BOL', id: 26 },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'BES', id: 27 },
  { name: 'Bosnia and Herzegovina', code: 'BIH', id: 28 },
  { name: 'Botswana', code: 'BWA', id: 29 },
  { name: 'Bouvet Island', code: 'BVT', id: 30 },
  { name: 'Brazil', code: 'BRA', id: 31 },
  { name: 'British Indian Ocean Territory', code: 'IOT', id: 32 },
  { name: 'Brunei Darussalam', code: 'BRN', id: 33 },
  { name: 'Bulgaria', code: 'BGR', id: 34 },
  { name: 'Burkina Faso', code: 'BFA', id: 35 },
  { name: 'Burundi', code: 'BDI', id: 36 },
  { name: 'Cabo Verde', code: 'CPV', id: 37 },
  { name: 'Cambodia', code: 'KHM', id: 38 },
  { name: 'Cameroon', code: 'CMR', id: 39 },
  { name: 'Canada', code: 'CAN', id: 40 },
  { name: 'Cayman Islands', code: 'CYM', id: 41 },
  { name: 'Central African Republic', code: 'CAF', id: 42 },
  { name: 'Chad', code: 'TCD', id: 43 },
  { name: 'Chile', code: 'CHL', id: 44 },
  { name: 'China', code: 'CHN', id: 45 },
  { name: 'Christmas Island', code: 'CXR', id: 46 },
  { name: 'Cocos (Keeling) Islands', code: 'CCK', id: 47 },
  { name: 'Colombia', code: 'COL', id: 48 },
  { name: 'Comoros', code: 'COM', id: 49 },
  { name: 'Congo', code: 'COG', id: 50 },
  { name: 'Congo, Democratic Republic of the', code: 'COD', id: 51 },
  { name: 'Cook Islands', code: 'COK', id: 52 },
  { name: 'Costa Rica', code: 'CRI', id: 53 },
  { name: "Côte d'Ivoire", code: 'CIV', id: 54 },
  { name: 'Croatia', code: 'HRV', id: 55 },
  { name: 'Cuba', code: 'CUB', id: 56 },
  { name: 'Curaçao', code: 'CUW', id: 57 },
  { name: 'Cyprus', code: 'CYP', id: 58 },
  { name: 'Czechia', code: 'CZE', id: 59 },
  { name: 'Denmark', code: 'DNK', id: 60 },
  { name: 'Djibouti', code: 'DJI', id: 61 },
  { name: 'Dominica', code: 'DMA', id: 62 },
  { name: 'Dominican Republic', code: 'DOM', id: 63 },
  { name: 'Ecuador', code: 'ECU', id: 64 },
  { name: 'Egypt', code: 'EGY', id: 65 },
  { name: 'El Salvador', code: 'SLV', id: 66 },
  { name: 'Equatorial Guinea', code: 'GNQ', id: 67 },
  { name: 'Eritrea', code: 'ERI', id: 68 },
  { name: 'Estonia', code: 'EST', id: 69 },
  { name: 'Eswatini', code: 'SWZ', id: 70 },
  { name: 'Ethiopia', code: 'ETH', id: 71 },
  { name: 'Falkland Islands (Malvinas)', code: 'FLK', id: 72 },
  { name: 'Faroe Islands', code: 'FRO', id: 73 },
  { name: 'Fiji', code: 'FJI', id: 74 },
  { name: 'Finland', code: 'FIN', id: 75 },
  { name: 'France', code: 'FRA', id: 76 },
  { name: 'French Guiana', code: 'GUF', id: 77 },
  { name: 'French Polynesia', code: 'PYF', id: 78 },
  { name: 'French Southern Territories', code: 'ATF', id: 79 },
  { name: 'Gabon', code: 'GAB', id: 80 },
  { name: 'Gambia', code: 'GMB', id: 81 },
  { name: 'Georgia', code: 'GEO', id: 82 },
  { name: 'Germany', code: 'DEU', id: 83 },
  { name: 'Ghana', code: 'GHA', id: 84 },
  { name: 'Gibraltar', code: 'GIB', id: 85 },
  { name: 'Greece', code: 'GRC', id: 86 },
  { name: 'Greenland', code: 'GRL', id: 87 },
  { name: 'Grenada', code: 'GRD', id: 88 },
  { name: 'Guadeloupe', code: 'GLP', id: 89 },
  { name: 'Guam', code: 'GUM', id: 90 },
  { name: 'Guatemala', code: 'GTM', id: 91 },
  { name: 'Guernsey', code: 'GGY', id: 92 },
  { name: 'Guinea', code: 'GIN', id: 93 },
  { name: 'Guinea-Bissau', code: 'GNB', id: 94 },
  { name: 'Guyana', code: 'GUY', id: 95 },
  { name: 'Haiti', code: 'HTI', id: 96 },
  { name: 'Heard Island and McDonald Islands', code: 'HMD', id: 97 },
  { name: 'Holy See', code: 'VAT', id: 98 },
  { name: 'Honduras', code: 'HND', id: 99 },
  { name: 'Hong Kong', code: 'HKG', id: 100 },
  { name: 'Hungary', code: 'HUN', id: 101 },
  { name: 'Iceland', code: 'ISL', id: 102 },
  { name: 'India', code: 'IND', id: 103 },
  { name: 'Indonesia', code: 'IDN', id: 104 },
  { name: 'Iran (Islamic Republic of)', code: 'IRN', id: 105 },
  { name: 'Iraq', code: 'IRQ', id: 106 },
  { name: 'Ireland', code: 'IRL', id: 107 },
  { name: 'Isle of Man', code: 'IMN', id: 108 },
  { name: 'Israel', code: 'ISR', id: 109 },
  { name: 'Italy', code: 'ITA', id: 110 },
  { name: 'Jamaica', code: 'JAM', id: 111 },
  { name: 'Japan', code: 'JPN', id: 112 },
  { name: 'Jersey', code: 'JEY', id: 113 },
  { name: 'Jordan', code: 'JOR', id: 114 },
  { name: 'Kazakhstan', code: 'KAZ', id: 115 },
  { name: 'Kenya', code: 'KEN', id: 116 },
  { name: 'Kiribati', code: 'KIR', id: 117 },
  {
    name: "Korea (Democratic People's Republic of)",
    code: 'PRK',
    id: 118,
  },
  { name: 'Korea, Republic of', code: 'KOR', id: 119 },
  { name: 'Kuwait', code: 'KWT', id: 120 },
  { name: 'Kyrgyzstan', code: 'KGZ', id: 121 },
  { name: "Lao People's Democratic Republic", code: 'LAO', id: 122 },
  { name: 'Latvia', code: 'LVA', id: 123 },
  { name: 'Lebanon', code: 'LBN', id: 124 },
  { name: 'Lesotho', code: 'LSO', id: 125 },
  { name: 'Liberia', code: 'LBR', id: 126 },
  { name: 'Libya', code: 'LBY', id: 127 },
  { name: 'Liechtenstein', code: 'LIE', id: 128 },
  { name: 'Lithuania', code: 'LTU', id: 129 },
  { name: 'Luxembourg', code: 'LUX', id: 130 },

  { name: 'Macao', code: 'MAC', id: 131 },
  { name: 'Madagascar', code: 'MDG', id: 132 },
  { name: 'Malawi', code: 'MWI', id: 133 },
  { name: 'Malaysia', code: 'MYS', id: 134 },
  { name: 'Maldives', code: 'MDV', id: 135 },
  { name: 'Mali', code: 'MLI', id: 136 },
  { name: 'Malta', code: 'MLT', id: 137 },
  { name: 'Marshall Islands', code: 'MHL', id: 138 },
  { name: 'Martinique', code: 'MTQ', id: 139 },
  { name: 'Mauritania', code: 'MRT', id: 140 },
  { name: 'Mauritius', code: 'MUS', id: 141 },
  { name: 'Mayotte', code: 'MYT', id: 142 },
  { name: 'Mexico', code: 'MEX', id: 143 },
  { name: 'Micronesia (Federated States of)', code: 'FSM', id: 144 },

  { name: 'Moldova, Republic of', code: 'MDA', id: 145 },
  { name: 'Monaco', code: 'MCO', id: 146 },
  { name: 'Mongolia', code: 'MNG', id: 147 },
  { name: 'Montenegro', code: 'MNE', id: 148 },
  { name: 'Montserrat', code: 'MSR', id: 149 },
  { name: 'Morocco', code: 'MAR', id: 150 },
  { name: 'Mozambique', code: 'MOZ', id: 151 },
  { name: 'Myanmar', code: 'MMR', id: 152 },
  { name: 'Namibia', code: 'NAM', id: 153 },
  { name: 'Nauru', code: 'NRU', id: 154 },
  { name: 'Nepal', code: 'NPL', id: 155 },
  { name: 'Netherlands', code: 'NLD', id: 156 },
  { name: 'New Caledonia', code: 'NCL', id: 157 },
  { name: 'New Zealand', code: 'NZL', id: 158 },
  { name: 'Nicaragua', code: 'NIC', id: 159 },
  { name: 'Niger', code: 'NER', id: 160 },
  { name: 'Nigeria', code: 'NGA', id: 161 },
  { name: 'Niue', code: 'NIU', id: 162 },
  { name: 'Norfolk Island', code: 'NFK', id: 163 },
  { name: 'North Macedonia', code: 'MKD', id: 164 },
  { name: 'Northern Mariana Islands', code: 'MNP', id: 165 },
  { name: 'Norway', code: 'NOR', id: 166 },
  { name: 'Oman', code: 'OMN', id: 167 },
  { name: 'Pakistan', code: 'PAK', id: 168 },
  { name: 'Palau', code: 'PLW', id: 169 },
  { name: 'Palestine, State of', code: 'PSE', id: 170 },
  { name: 'Panama', code: 'PAN', id: 171 },
  { name: 'Papua New Guinea', code: 'PNG', id: 172 },
  { name: 'Paraguay', code: 'PRY', id: 173 },
  { name: 'Peru', code: 'PER', id: 174 },
  { name: 'Philippines', code: 'PHL', id: 175 },
  { name: 'Pitcairn', code: 'PCN', id: 176 },
  { name: 'Poland', code: 'POL', id: 177 },
  { name: 'Portugal', code: 'PRT', id: 178 },
  { name: 'Puerto Rico', code: 'PRI', id: 179 },
  { name: 'Qatar', code: 'QAT', id: 180 },
  { name: 'Réunion', code: 'REU', id: 181 },
  { name: 'Romania', code: 'ROU', id: 182 },
  { name: 'Russian Federation', code: 'RUS', id: 183 },
  { name: 'Rwanda', code: 'RWA', id: 184 },
  { name: 'Saint Barthélemy', code: 'BLM', id: 185 },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SHN',
    id: 186,
  },
  { name: 'Saint Kitts and Nevis', code: 'KNA', id: 187 },
  { name: 'Saint Lucia', code: 'LCA', id: 188 },
  { name: 'Saint Martin (French part)', code: 'MAF', id: 189 },
  { name: 'Saint Pierre and Miquelon', code: 'SPM', id: 190 },
  { name: 'Saint Vincent and the Grenadines', code: 'VCT', id: 191 },
  { name: 'Samoa', code: 'WSM', id: 192 },
  { name: 'San Marino', code: 'SMR', id: 193 },
  { name: 'Sao Tome and Principe', code: 'STP', id: 194 },
  { name: 'Saudi Arabia', code: 'SAU', id: 195 },
  { name: 'Senegal', code: 'SEN', id: 196 },
  { name: 'Serbia', code: 'SRB', id: 197 },
  { name: 'Seychelles', code: 'SYC', id: 198 },
  { name: 'Sierra Leone', code: 'SLE', id: 199 },
  { name: 'Singapore', code: 'SGP', id: 200 },
  { name: 'Sint Maarten (Dutch part)', code: 'SXM', id: 201 },
  { name: 'Slovakia', code: 'SVK', id: 202 },
  { name: 'Slovenia', code: 'SVN', id: 203 },
  { name: 'Solomon Islands', code: 'SLB', id: 204 },
  { name: 'Somalia', code: 'SOM', id: 205 },
  { name: 'South Africa', code: 'ZAF', id: 206 },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'SGS',
    id: 207,
  },
  { name: 'South Sudan', code: 'SSD', id: 208 },
  { name: 'Spain', code: 'ESP', id: 209 },
  { name: 'Sri Lanka', code: 'LKA', id: 210 },
  { name: 'Sudan', code: 'SDN', id: 211 },
  { name: 'Suriname', code: 'SUR', id: 212 },
  { name: 'Svalbard and Jan Mayen', code: 'SJM', id: 213 },
  { name: 'Sweden', code: 'SWE', id: 214 },
  { name: 'Switzerland', code: 'CHE', id: 215 },
  { name: 'Syrian Arab Republic', code: 'SYR', id: 216 },
  { name: 'Taiwan, Province of China', code: 'TWN', id: 217 },
  { name: 'Tajikistan', code: 'TJK', id: 218 },
  { name: 'Tanzania, United Republic of', code: 'TZA', id: 219 },
  { name: 'Thailand', code: 'THA', id: 220 },
  { name: 'Timor-Leste', code: 'TLS', id: 221 },
  { name: 'Togo', code: 'TGO', id: 222 },
  { name: 'Tokelau', code: 'TKL', id: 223 },
  { name: 'Tonga', code: 'TON', id: 224 },
  { name: 'Trinidad and Tobago', code: 'TTO', id: 225 },
  { name: 'Tunisia', code: 'TUN', id: 226 },
  { name: 'Turkey', code: 'TUR', id: 227 },
  { name: 'Turkmenistan', code: 'TKM', id: 228 },
  { name: 'Turks and Caicos Islands', code: 'TCA', id: 229 },
  { name: 'Tuvalu', code: 'TUV', id: 230 },
  { name: 'Uganda', code: 'UGA', id: 231 },
  { name: 'Ukraine', code: 'UKR', id: 232 },
  { name: 'United Arab Emirates', code: 'ARE', id: 233 },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GBR',
    id: 234,
  },
  { name: 'United States of America', code: 'USA', id: 235 },
  { name: 'United States Minor Outlying Islands', code: 'UMI', id: 236 },
  { name: 'Uruguay', code: 'URY', id: 237 },
  { name: 'Uzbekistan', code: 'UZB', id: 238 },
  { name: 'Vanuatu', code: 'VUT', id: 239 },
  { name: 'Venezuela (Bolivarian Republic of)', code: 'VEN', id: 240 },
  { name: 'Viet Nam', code: 'VNM', id: 241 },
  { name: 'Virgin Islands (British)', code: 'VGB', id: 242 },
  { name: 'Virgin Islands (U.S.)', code: 'VIR', id: 243 },
  { name: 'Wallis and Futuna', code: 'WLF', id: 244 },
  { name: 'Western Sahara', code: 'ESH', id: 245 },
  { name: 'Yemen', code: 'YEM', id: 246 },
  { name: 'Zambia', code: 'ZMB', id: 247 },
  { name: 'Zimbabwe', code: 'ZWE', id: 248 },
];
