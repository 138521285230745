import { routes } from '@redwoodjs/router';

// TODO: migrate WorkOrder references to GrowerProduct

export enum EntityType {
  'SALES_ORDER' = 'SalesOrder',
  'PURCHASE_ORDER' = 'PurchaseOrder',
  'WORK_ORDER' = 'WorkOrder',
  'BUY_SELL_ORDER' = 'BuySellOrder',
  'PRODUCTION_RUN' = 'ProductionRun',
  'LOT' = 'Lot',
}

export const entityTypes: {
  [key in EntityType]: {
    route: (params) => string;
    prefix: string;
    id: string;
    statuses: string[];
    name: string;
  };
} = {
  SalesOrder: {
    route: (params) => routes.salesOrder(params),
    prefix: 'SO',
    id: 'salesOrderId',
    statuses: ['DRAFT', 'SUBMITTED', 'PICKING', 'SHIPPED', 'CLOSED', 'CANCELLED', 'ERROR'],
    name: 'Sales Order',
  },
  PurchaseOrder: {
    route: (params) => routes.purchaseOrder(params),
    prefix: 'PO',
    id: 'purchaseOrderId',
    statuses: ['DRAFT', 'SUBMITTED', 'RECEIVING', 'PENDING_LIQUIDATION', 'CLOSED', 'CANCELLED', 'ERROR'],
    name: 'Purchase Order',
  },
  WorkOrder: {
    route: (params) => routes.growerProduct(params),
    prefix: 'GP',
    id: 'workOrderId',
    statuses: ['DRAFT', 'SUBMITTED', 'RECEIVING', 'PENDING_SETTLEMENT', 'CLOSED'],
    name: 'Grower Product',
  },
  BuySellOrder: {
    route: (params) => routes.buySellOrder(params),
    prefix: 'BSO',
    id: 'buySellOrderId',
    statuses: ['DRAFT', 'SUBMITTED', 'PAYMENT_RECONCILIATION', 'CLOSED'],
    name: 'Buy-Sell Order',
  },
  ProductionRun: {
    route: (params) => routes.productionRun(params),
    prefix: 'PR',
    id: 'productionRunId',
    statuses: ['DRAFT', 'SUBMITTED', 'CLOSED'],
    name: 'Production Run',
  },
  Lot: {
    route: (params) => routes.viewLotTracking(params),
    prefix: '',
    id: 'lotId',
    statuses: ['OPEN', 'LOCKED', 'CLOSED'],
    name: 'Lot',
  },
};

const entityTypeKeys = Object.values(entityTypes).map((orderType) => orderType.id);

/**
 * Finds the existing order id and returns the id key
 */
export const getOrderIdKeyById = (objWithOrder: {
  salesOrderId?: number;
  purchaseOrderId?: number;
  workOrderId?: number;
  buySellOrderId?: number;
}) => {
  const orderType = entityTypeKeys.find((key) => {
    return !!objWithOrder[key as keyof typeof objWithOrder];
  });

  return orderType;
};

/**
 * Finds the existing order id and returns the order type
 */
export const getOrderTypeById = (objWithOrder: {
  salesOrderId?: number;
  purchaseOrderId?: number;
  workOrderId?: number;
  buySellOrderId?: number;
}) => {
  const orderType = Object.keys(entityTypes).find((orderType) => {
    const id = entityTypes[orderType].id;

    return !!objWithOrder[id as keyof typeof objWithOrder];
  });

  return orderType;
};

export const getOrderByPresense = (objWithOrder: {
  purchaseOrder?: unknown;
  salesOrder?: unknown;
  workOrder?: unknown;
  buySellOrder?: unknown;
}) => {
  const { purchaseOrder, salesOrder, workOrder, buySellOrder } = objWithOrder;
  const order = purchaseOrder || salesOrder || workOrder || buySellOrder;

  return order;
};
