export enum Type {
  // Orders
  'SALES_ORDER' = 'SalesOrder',
  'PURCHASE_ORDER' = 'PurchaseOrder',
  'WORK_ORDER' = 'WorkOrder',
  'BUY_SELL_ORDER' = 'BuySellOrder',
  'PRODUCTION_RUN' = 'ProductionRun',
  'LOT' = 'Lot',

  // Invoices
  'INVOICE' = 'Invoice',
  'STANDARD_INVOICE' = 'StandardInvoice',
  'BUY_SELL_ORDER_INVOICE' = 'BuySellOrderInvoice',

  // Payables
  'PAYABLE' = 'Payable',
  'STANDARD_PAYABLE' = 'StandardPayable',
  'BUY_SELL_ORDER_PAYABLE' = 'BuySellOrderPayable',
  'CONSIGNMENT_PAYABLE' = 'ConsignmentPayable',

  // Payment
  'PAYMENT' = 'Payment',
}

export type InvoiceIds = {
  invoiceId?: number;
  standardInvoiceId?: number;
  buySellOrderInvoiceId?: number;
};
export type PayableIds = {
  payableId?: number;
  standardPayableId?: number;
  buySellOrderPayableId?: number;
  consignmentPayableId?: number;
};
export type MetaIds = {
  paymentId?: number;
  receiptAdjustmentId?: number;
  lotQuantityAdjustmentId?: number;
  manualLedgerEntryId?: number;
};
export type ReceiptIds = InvoiceIds & PayableIds;
export type AllIds = InvoiceIds & PayableIds & MetaIds;
