import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page } from '@react-pdf/renderer';

import { Footer, Notes, ProductsGrid, PurchaseOrderHeader } from '../sections';
import { docStyles } from '../utils/styles';

type PurchaseOrderTemplateProps = Prisma.PurchaseOrderGetPayload<{
  include: {
    organization: { include: { places: true } };

    buyer: { include: { user: true; subsidiary: true } };
    businessEntity: true;
    businessEntityContact: true;
    subsidiary: true;
    lineItems: {
      select: {
        buyPrice: true;
        unitsReceived: true;
      };
      include: {
        lot: true;
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
          };
        };
      };
    };
    expenses: true;
    payables: true;
    notes: {
      include: {
        membership: {
          include: { user: true };
        };
      };
    };
  };
}>;

export function ReceivingTicketTemplate({ overrideContactEmail, ...purchaseOrder }: PurchaseOrderTemplateProps) {
  const allLineItems = purchaseOrder.lineItems;
  const internalNotes = purchaseOrder.notes.filter((n) => n.documentTypes.includes('RECEIVING_TICKET'));

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <PurchaseOrderHeader
          order={purchaseOrder}
          headerName={'RECEIVING TICKET'}
          overrideContactEmail={overrideContactEmail}
        />

        <ProductsGrid
          productLineItems={allLineItems}
          showLineItemWeight={true}
          showUnitsOrdered={true}
          productTemplate={purchaseOrder.organization.productDescriptionTemplate}
        />

        <Notes notes={internalNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
