import {
  GridFilterItem,
  GridFilterModel,
  gridFilterModelSelector,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import { useEffect } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ServerEnhancedGridStateWithHelpers } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';
import { ServerGridFilter } from 'src/modules/grids/serverEnhanced/types';

export type GridFilterControllerProps<TFilterModel> = {
  gridState: ServerEnhancedGridStateWithHelpers;
  filterModel: TFilterModel;
  filterModelToDataGridModelItems(filterModel: TFilterModel): ServerGridFilter[];
};

export const GridFilterController = <T,>({
  gridState,
  filterModel,
  filterModelToDataGridModelItems,
}: GridFilterControllerProps<T>) => {
  const currentGridFilterModel = gridState.gridFilters;
  // Apply changes from the outside the datagrid, into the datagrid
  useEffect(() => {
    const filterModelItems = filterModelToDataGridModelItems(filterModel);

    let dirty = false;
    let dataGridItems: ServerGridFilter[] = [...currentGridFilterModel];

    for (const filterModelItem of filterModelItems) {
      const matchingItem = dataGridItems.find((i) => i.id === 'account_path_from_url');

      if (!matchingItem) {
        dataGridItems.push(filterModelItem);
        dirty = true;
      }
    }

    // was there actually a change to apply?
    if (!dirty) {
      return;
    }

    gridState.updateFilters(dataGridItems);
  }, [filterModel, currentGridFilterModel]);

  return <></>;
};
