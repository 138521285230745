import { useCallback, useMemo } from 'react';

import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-premium';

export type CellPredictionProps<T> = {
  getPrediction: (params: GridCellParams) => T;
  formatPrediction?: (prediction: T) => string;
};

/**
 * Implements the core functionality for cells that offer a prediction.
 *
 * This could be a cell that offers a prediction for the value of another cell,
 * or a cell that offers a prediction for another value completely.
 *
 * See utilized in: [PredictionCell](../components/atoms/grid/PredictionCell.tsx)
 */
export default function useCellPrediction<T = unknown>({
  getPrediction = () => null,
  formatPrediction = (prediction) => prediction as string,
  ...params
}: CellPredictionProps<T> & GridCellParams) {
  const apiRef = useGridApiContext();
  const { id, field, value } = params;

  const editRow = apiRef.current.getRowWithUpdatedValues(id, field);

  const prediction = getPrediction({
    ...params,
    row: editRow,
  });

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Tab' && !value && prediction) {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: prediction,
        });
      }
    },
    [apiRef, id, field, value, prediction]
  );

  const placeholder = useMemo(() => formatPrediction(prediction), [prediction]);

  return {
    onKeyDown,
    placeholder,
  };
}
