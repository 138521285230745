import { Chip, SxProps, Theme, useTheme } from '@mui/material';
import Color from 'color';

export type ReportContext =
  | 'purchaseOrder'
  | 'salesOrder'
  | 'picking'
  | 'receiving'
  | 'warning'
  | 'invoice'
  | 'payable'
  | 'lot';
export type StatusBadgeProps = {
  status: string;
  context?: ReportContext;
  sx?: SxProps;
  indicatorValue?: unknown;
  size?: 'small' | 'medium';
};
export type StatusColorsResolver = {
  (
    theme: Theme,
    context?: ReportContext
  ): {
    background: string;
    color: string;
  };
};
export type StatusBadgeDef = {
  label: string;
  render: StatusColorsResolver;
};

export const renderers: Record<string, StatusColorsResolver> = {
  default: (theme) => ({
    background: Color(theme.palette.action.selected).alpha(0.08).toString(),
    color: Color(theme.palette.text.primary).alpha(0.8).toString(),
  }),
  warning: (theme) => ({
    background: Color(theme.palette.warning.main).alpha(0.22).toString(),
    color: Color(theme.palette.text.primary).alpha(0.6).toString(),
  }),
  success: (theme) => ({
    background: Color(theme.palette.success.main).alpha(0.22).toString(),
    color: theme.palette.success.dark,
  }),
  error: (theme) => ({
    background: Color(theme.palette.error.main).alpha(0.22).toString(),
    color: theme.palette.error.dark,
  }),
  info: (theme) => ({
    background: Color(theme.palette.info.main).alpha(0.22).toString(),
    color: theme.palette.info.dark,
  }),
  primary: (theme) => ({
    background: Color(theme.palette.primary.main).alpha(0.22).toString(),
    color: theme.palette.primary.dark,
  }),
};

export const statusDefs: Record<string, StatusBadgeDef> = {
  DRAFT: {
    label: 'Draft',
    render: renderers.default,
  },
  warning: {
    label: 'warning',
    render: renderers.warning,
  },
  PICKING: {
    label: 'Picking',
    render: (theme, context) => {
      if (context === 'warning') {
        return renderers.warning(theme);
      }

      return renderers.info(theme);
    },
  },
  PAID: {
    label: 'Paid',
    render: renderers.success,
  },
  SUBMITTED: {
    label: 'Submitted',
    render: renderers.success,
  },
  READY: {
    label: 'Ready',
    render: renderers.success,
  },
  ERROR: {
    label: 'Error',
    render: renderers.error,
  },
  CLOSED: {
    label: 'Closed',
    render: renderers.error,
  },
  CANCELLED: {
    label: 'Cancelled',
    render: renderers.error,
  },
  IN_TRANSIT: {
    label: 'In Transit',
    render: renderers.warning,
  },
  SHIPPED: {
    label: 'Shipped',
    render: renderers.warning,
  },
  PENDING_LIQUIDATION: {
    label: 'Liquidation',
    render: renderers.warning,
  },
  IN_PROGRESS: {
    label: 'In Progress',
    render: renderers.warning,
  },
  MID_PRODUCTION_RUN: {
    label: 'Mid Production Run',
    render: renderers.warning,
  },
  MID_RECEIVING: {
    label: 'Mid Receiving',
    render: renderers.warning,
  },
  RECEIVING: {
    label: 'Receiving',
    render: renderers.info,
  },
  RECEIVED: {
    label: 'Received',
    render: renderers.warning,
  },
  NEEDS_INFO: {
    label: 'Needs Info',
    render: (theme, context) => {
      if (context === 'purchaseOrder') {
        return renderers.error(theme);
      }

      return renderers.info(theme);
    },
  },
  CONFIRMED: {
    label: 'Confirmed',
    render: renderers.info,
  },
  OPEN: {
    label: 'Open',
    render: renderers.success,
  },
  // order tickets
  NOT_STARTED: {
    label: 'Not Started',
    render: renderers.error,
  },
  PARTIALLY_PICKED: {
    label: 'Picked Partially',
    render: renderers.warning,
  },
  FULLY_PICKED: {
    label: 'Picked Fully',
    render: renderers.success,
  },
  PARTIALLY_RECEIVED: {
    label: 'Received Partially',
    render: renderers.warning,
  },
  FULLY_RECEIVED: {
    label: 'Received Fully',
    render: renderers.success,
  },
  // invoices and payables
  PAID_FULL: {
    label: 'Paid Full',
    render: renderers.success,
  },
  PAID_PARTIAL: {
    label: 'Paid Partially',
    render: renderers.warning,
  },
  VOIDED: {
    label: 'Voided',
    render: renderers.error,
  },
  FINALIZED: {
    label: 'Finalized',
    render: renderers.success,
  },
  // buy-sell orders
  PAYMENT_RECONCILIATION: {
    label: 'Payment Reconciliation',
    render: renderers.success,
  },
  // grower products
  PENDING_SETTLEMENT: {
    label: 'Pending Settlement',
    render: renderers.warning,
  },
  // account reconciliations
  PROCESSING_ERROR: {
    label: 'Processing Error',
    render: renderers.error,
  },
  LOCKED: {
    label: 'Locked',
    render: renderers.error,
  },
  // production runs
  COMPLETE: {
    label: 'Complete',
    render: renderers.success,
  },
  // Load
  BOOKED: {
    label: 'Booked',
    render: renderers.warning,
  },
  EN_ROUTE: {
    label: 'En Route',
    render: renderers.info,
  },

  // Checks
  PRINTED: {
    label: 'Printed',
    render: renderers.success,
  },
  TEMPLATE: {
    label: 'Template',
    render: (theme) => {
      return renderers.info(theme);
    },
  },
  FLAGGED: {
    label: 'Flagged',
    render: renderers.error,
  },

  // EDI
  APPROVED: {
    label: 'Approved',
    render: renderers.success,
  },
  PENDING: {
    label: 'Pending',
    render: renderers.default,
  },
  REJECTED: {
    label: 'Locked',
    render: renderers.error,
  },

  INVOICED: {
    label: 'Invoiced',
    render: renderers.success,
  },
};

export default function StatusBadge({ status, context, indicatorValue, size = 'medium', sx = {} }: StatusBadgeProps) {
  const theme = useTheme();

  if (!status) {
    return null;
  }

  if (!statusDefs[status]) {
    return (
      <Chip
        size={size}
        label={status}
        sx={{
          height: '24px',
          ...sx,
        }}
      />
    );
  }

  const { label, render } = statusDefs[status];

  return (
    <Chip
      size={size}
      label={label}
      sx={{
        height: '24px',
        ...sx,
        ...render(theme, context),
      }}
    />
  );
}
