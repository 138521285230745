import { useEffect, useRef } from 'react';

import { GridCellParams } from '@mui/x-data-grid-premium';

import { countries } from 'src/utils/countries';

import AutoCompleteCell from './AutoCompleteCell';

export type CountryOption = {
  id: number;
  code: string;
  name: string;
};

export default function CountryCell({ hasFocus, ...params }: GridCellParams) {
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (hasFocus) {
      ref.current?.focus();
    }
  }, [hasFocus]);

  return (
    <AutoCompleteCell
      textFieldProps={{ inputRef: ref }}
      {...params}
      isOptionEqualToValue={(option, value) => (typeof value === 'object' ? value.code : value) === option.code}
      getOptionLabel={(option) => (typeof option === 'object' ? `${option.name} (${option.code})` : option)}
      options={countries}
    />
  );
}
