import { registerFragment } from '@redwoodjs/web/apollo';

registerFragment(gql`
  fragment BuySellOrderDocumentAttributes on BuySellOrder {
    slug
    status
    orderDate
    organization {
      label
      logoUrl
      primaryLocation {
        ...PlaceAttributes
      }
    }
    broker {
      id
      user {
        id
        firstName
        lastName
        email
      }
    }
    customer {
      id
      name
    }
    customerPo
    customerContact {
      id
      name
      email
      phone
    }
    customerContactId
    customerSalesTerms

    billTo {
      ...PlaceAttributes
    }
    billToId

    vendor {
      id
      name
    }
    vendorBillTo {
      ...PlaceAttributes
    }
    vendorBillToId
    vendorSo
    vendorContact {
      id
      name
      email
      phone
    }
    vendorSalesTerms
    vendorContactId

    exchangeRate
    currencyCode
    customFields
    shipment {
      shipDate
      deliveryDate
      origin {
        ...PlaceAttributes
      }
      destination {
        ...PlaceAttributes
      }
      freightCompany {
        id
        name
      }
      temperatureMin
      temperatureMax
      customFields
      terms
    }
    lineItems {
      buyPrice
      sellPrice
      buyCurrencyPrice
      sellCurrencyPrice
      unitsOrdered
      unitsShipped
      unitsInvoiced
      product {
        ...ProductAttributes
      }
    }
    expenses {
      ...ExpenseAttributes
    }
    notes {
      ...NoteAttributes
    }
  }
`);

const GetBuySellOrderData = gql`
  query GetBuySellOrderDocumentData($slug: String!) {
    buySellOrder(slug: $slug) {
      ...BuySellOrderDocumentAttributes
    }
  }
`;

export default {
  GetBuySellOrderData,
};
