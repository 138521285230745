import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import '@react-pdf-viewer/core/lib/styles/index.css'; // Core styles

type ZoomControlsProps = {
  currentScale: number;
  handleZoomChange: (scale: number) => void;
};

export function ZoomControls({ currentScale, handleZoomChange }: ZoomControlsProps) {
  return (
    <Box display="flex">
      <Button
        startIcon={<ZoomOut />}
        onClick={() => handleZoomChange(currentScale - 0.2)}
        disabled={currentScale <= 0.2}
      >
        Zoom Out
      </Button>
      <Button startIcon={<ZoomIn />} onClick={() => handleZoomChange(currentScale + 0.2)}>
        Zoom In
      </Button>
    </Box>
  );
}
