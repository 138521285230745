export function getJSONFromLocalStorage<T = unknown>(key: string): T | null {
  const item = localStorage.getItem(key);
  if (!item) return null;

  try {
    return JSON.parse(item);
  } catch (e) {
    localStorage.removeItem(key);
  }

  return null;
}
