import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';

import { useHits, useSearchBox } from 'react-instantsearch-hooks-web';

export const RefineContext = createContext<{
  refine: (query: string) => void;
}>(undefined);

/**
 * When useHits and useSearchBox are used separately, it refreshes the hits with an empty query
 * This allows us retrieve `refine` from useSearchBox, and then pass it down to the SearchBox,
 * avoiding the duplicate empty query call.
 */
export default function RefineProvider({ children, refine }: PropsWithChildren<{ refine: (query: string) => void }>) {
  return <RefineContext.Provider value={{ refine }}>{children}</RefineContext.Provider>;
}

export type PaginationInfo = {
  nbHits: number;
  nbPages: number;
  page: number;
  hitsPerPage: number;
};

// TODO: Update all components to set transformHits instead of mapHits, and remove mapHits
export type RefineCellControllerProps<T> = {
  transformHits?: (hits: Record<string, unknown>[]) => T;
  mapHits?: (hit) => Record<string, unknown>;
  onHits?: (hits: Record<string, unknown>[]) => void;
  render: (transformedHits: T | Record<string, unknown>, PaginationInfo) => JSX.Element;
};

export function RefineCellController<T>({ render, mapHits, onHits, transformHits }: RefineCellControllerProps<T>) {
  const { hits, results } = useHits(); // this needs to be called with useHits, or it causes duplicate searches.
  const { refine } = useSearchBox();

  useEffect(() => {
    onHits?.(hits);
  }, [hits]);

  const mapped = useMemo(() => {
    if (transformHits) {
      return transformHits(hits);
    }

    if (mapHits) {
      return hits.map(mapHits);
    }

    return hits;
  }, [hits, mapHits, transformHits]);

  return (
    <RefineProvider refine={refine}>
      {render(mapped, {
        nbHits: results?.nbHits,
        nbPages: results?.nbPages,
        page: results?.page,
        hitsPerPage: results?.hitsPerPage,
      })}
    </RefineProvider>
  );
}

export const useRefine = () => useContext(RefineContext);
