import { useCallback, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { GridRowId, isAutogeneratedRow, useGridApiRef } from '@mui/x-data-grid-premium';
import { Index, InstantSearch } from 'react-instantsearch-hooks-web';
import { LineItemAttributes } from 'types/graphql';
import { z } from 'zod';

import { routes } from '@redwoodjs/router';
import { useMutation, type CellFailureProps } from '@redwoodjs/web';

import CheckMount from 'src/components/atoms/CheckMount';
import BoldHeader from 'src/components/atoms/grid/BoldHeader';
import EditLotCell from 'src/components/atoms/grid/EditLotCell';
import Link from 'src/components/atoms/Link';
import Skeleton from 'src/components/atoms/Skeleton';
import AddableDataGrid from 'src/components/containers/grids/AddableDataGrid';
import { GridColObj } from 'src/components/containers/grids/DataGrid';
import SkeletonDataGrid from 'src/components/containers/skeleton/SkeletonDataGrid';
import useSystemUnitRounding from 'src/hooks/useSystemUnitRounding';
import { SearchIndex, useAlgolia } from 'src/providers/AlgoliaProvider';
import columnActions from 'src/utils/grid/column/columnActions';

import PalletTagSelectionModal, { SelectPalletTagsCell } from '../modals/PalletTagSelectionModal';

const lotTransferColumns: (args?: {
  handleDeleteRow?;
  handleClearRow?;
  handleInventoryLookup?;
  fromWarehouseId?: number;
  toWarehouseId?: number;
  handleOpenTagsModal?;
  handleCloseTagsModal?;
  handleSaveTransferredTags?;
  canEdit;
  unitRounding;
}) => GridColObj<
  LineItemAttributes & {
    lots: LineItemAttributes['lot'][];
  }
> = ({
  handleDeleteRow,
  handleClearRow,
  fromWarehouseId,
  toWarehouseId,
  handleOpenTagsModal,
  canEdit,
  unitRounding,
} = {}) => {
  return {
    lot: {
      headerName: 'Origin Lot',
      editable: true,
      flex: 1,
      type: 'string',
      headerAlign: 'left',
      renderCell: ({ row }) => {
        if (isAutogeneratedRow(row) || !row.lot) {
          return undefined;
        }

        return (
          <Link
            to={routes.viewLotTracking({
              slug: row.lot.slug,
              warehouseId: row.lot.warehouseId,
            })}
            label={row.lot.slug}
          />
        );
      },
      renderEditCell: (params) => (
        <Index indexName={SearchIndex.Lots}>
          <EditLotCell warehouseId={fromWarehouseId} {...params} />
        </Index>
      ),
      align: 'left',
      renderHeader: BoldHeader,
    },
    quantityAvailable: {
      headerName: 'Quantity Available',
      flex: 1,
      type: 'number',
      headerAlign: 'left',
      renderCell: ({ row }) => {
        if (isAutogeneratedRow(row)) {
          return undefined;
        }

        return row.lot ? unitRounding(row.lot?.quantityAvailable - (row.unitsTransferred ?? 0)) : '--';
      },
      align: 'left',
      renderHeader: BoldHeader,
    },
    product: {
      headerName: 'Product',
      flex: 1,
      editable: true, // not really editable, but we wanna display the select lot product
      type: 'string',
      headerAlign: 'left',
      // MAYBE: Allow this to behave as a product filter for lots,
      // Unsure how to do this in a user friendly way, since we'd want the lot to still be editable.
      // So if the user selects a lot, and that updates the product -
      // they'd have to clear the product to update the lot?
      valueGetter: (_value, row) => {
        return row.lot?.product?.description;
      },
      renderEditCell: ({ row }) => {
        const product = row.lot?.product;
        const isString = typeof product === 'string';

        return (
          <Typography fontSize="14px" px={1}>
            {isString ? product : product?.description}
          </Typography>
        );
      },
      align: 'left',
      renderHeader: BoldHeader,
    },
    unitsTransferred: {
      headerName: 'Units Transferred',
      editable: true,
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueSetter: (value, row) => ({
        ...row,
        unitsTransferred: unitRounding(value),
      }),
      renderHeader: BoldHeader,
    },
    palletTags: {
      headerName: 'Tags',
      flex: 1,
      sortable: false,
      description: '',
      editable: false,
      renderCell: (params) => (
        <SelectPalletTagsCell
          handleOpenTagsModal={handleOpenTagsModal}
          canEdit={canEdit}
          getRowPalletTags={(row) => row?.palletTags ?? row?.palletTagsOnTransferItem}
          {...params}
        />
      ),
      renderEditCell: (params) => (
        <Box px="10px" width="100%">
          <SelectPalletTagsCell
            handleOpenTagsModal={handleOpenTagsModal}
            canEdit={canEdit}
            isEditing
            getRowPalletTags={(row) => row?.palletTags ?? row?.palletTagsOnTransferItem}
            {...params}
          />
        </Box>
      ),
    },
    transferredLot: {
      headerName: 'Destination Lot',
      editable: false,
      flex: 1,
      type: 'string',
      headerAlign: 'left',
      valueGetter: (_value, row) => {
        return row.lot?.transferredChildren?.find((child) => child.warehouseId === toWarehouseId);
      },
      renderCell: ({ value, row }) => {
        if (isAutogeneratedRow(row)) {
          return undefined;
        }

        if (!value) {
          return (
            <Typography fontStyle="italic" variant="subtitle2" color="text.secondary">
              Lot will be created on transfer
            </Typography>
          );
        }

        return (
          <Link
            to={routes.viewLotTracking({
              slug: value.slug,
              warehouseId: value.warehouseId,
            })}
            label={value.slug}
          />
        );
      },

      align: 'left',
      renderHeader: BoldHeader,
    },
    actions: {
      type: 'actions',
      width: 10,
      maxWidth: 10,
      align: 'left',
      getActions: columnActions(
        ({ id }) => ({
          deleteRow: () => {
            handleDeleteRow(id);
          },
          clearRow: () => {
            handleClearRow(id);
          },
        }),
        {
          showOnHover: true,
        }
      ),
    },
  };
};

export const Loading = () => <ProductsGridSkeleton />;

export const Empty = () => <div>Empty</div>;

export const Failure = ({ error }: CellFailureProps) => <div style={{ color: 'red' }}>Error: {error?.message}</div>;

type TransferItemRows = {
  id: number;
  lot?: {
    id: number;
    slug: string;
    warehouseId: number;
  };
  unitsTransferred?: number;
}[];

type InventoryTransferGridProps = {
  orderId: { purchaseOrderId?: number; salesOrderId?: number };
  canEdit: boolean;
  transferRows: TransferItemRows;
  setTransferRows: React.Dispatch<React.SetStateAction<TransferItemRows>>;
  canEditLot?: boolean;
  fromWarehouseId?: number;
  toWarehouseId?: number;
  inventoryTransferId?: number;
};

/* ------------------------ MUTATIONS ------------------------  */
const UPDATE_LOT_TRANSFER_ITEM = gql`
  mutation UpdateLotTransferItem($id: Int!, $input: UpdateLotTransferItemInput!) {
    updateLotTransferItem(id: $id, input: $input) {
      id
      lot {
        id
        slug
        warehouseId
        quantity
        product {
          description
        }
        transferredChildren {
          id
          slug
          warehouseId
        }
        palletTags {
          id
          tagNumber
          cartonQuantity
        }
      }
      palletTagsOnTransferItem {
        palletTag {
          id
          tagNumber
          cartonQuantity
        }
      }
    }
  }
`;

const CREATE_LOT_TRANSFER_ITEM = gql`
  mutation CreateLotTransferItem($input: CreateLotTransferItemInput!) {
    createLotTransferItem(input: $input) {
      id
      lot {
        id
        slug
        quantity
        warehouseId
        product {
          description
        }
        palletTags {
          id
          tagNumber
          cartonQuantity
        }
      }
      palletTagsOnTransferItem {
        palletTag {
          id
          tagNumber
          cartonQuantity
        }
      }
    }
  }
`;

const REMOVE_LOT_TRANSFER_ITEM = gql`
  mutation DeleteLotTransferItem($id: Int!) {
    deleteLotTransferItem(id: $id) {
      id
    }
  }
`;

const SET_LOT_TRANSFER_ITEM_TAGS = gql`
  mutation SetLotTransferItemTags($id: Int!, $input: [QuantityForPalletTag!]!) {
    setPalletTagsOnTransferItem(id: $id, input: $input) {
      palletTag {
        id
        tagNumber
        cartonQuantity
      }
      deletedAt
      quantity
    }
  }
`;

export const INVENTORY_TRANSFER_GRID_ID = 'inventory-transfer-grid';

export const InventoryTransferGrid = ({
  transferRows,
  setTransferRows,
  canEdit,
  inventoryTransferId,
  fromWarehouseId,
  toWarehouseId,
}: InventoryTransferGridProps) => {
  const apiRef = useGridApiRef();
  const algolia = useAlgolia();

  const [editingTagsForRow, setEditingTagsForRow] = useState<{
    id: GridRowId;
    isNew: boolean;
  }>();

  const [createLotTransferItem] = useMutation(CREATE_LOT_TRANSFER_ITEM);

  const [deleteLotTransferItem] = useMutation(REMOVE_LOT_TRANSFER_ITEM, {
    onCompleted: ({ deleteLotTransferItem: deleted }) => {
      setTransferRows(transferRows.filter((row) => row.id !== deleted.id));
    },
  });

  const [updateLotTransferItem] = useMutation(UPDATE_LOT_TRANSFER_ITEM, {
    onCompleted: ({ updateLotTransferItem }) => {
      setTransferRows((transferRows) =>
        transferRows.map((row) => {
          if (row.id === updateLotTransferItem.id) {
            return {
              ...row,
              ...updateLotTransferItem,
            };
          }

          return row;
        })
      );
    },
  });

  const [setLotTransferItemTags] = useMutation(SET_LOT_TRANSFER_ITEM_TAGS);

  // Create a new line item using a product we fetched from the database
  function handleSaveLineItem(input) {
    return createLotTransferItem({
      variables: {
        input: {
          ...input,
          inventoryTransferId,
        },
      },
    }).then(({ data }) => {
      const { id, lot } = data.createLotTransferItem;
      return {
        id,
        computed: {
          lot,
        },
      };
    });
  }

  // Once the user stops editing a cell, we get the new row and the old row and we can
  // make mutations as needed.
  // https://mui.com/x/react-data-grid/editing/#persistence
  function processRowChanges(id, input) {
    return updateLotTransferItem({
      variables: {
        id,
        input,
      },
    }).then(({ data }) => data.updateLotTransferItem);
  }

  const handleDeleteRow = useCallback((id) => {
    deleteLotTransferItem({
      variables: {
        id,
      },
    });
  }, []);

  const handleClearRow = useCallback((id) => {
    setTransferRows((productRows) => productRows.filter((row) => row.id !== id));
  }, []);

  // used for controlling whether or not to process the row,
  // useful for popup modals that should prevent row processing
  const [preventNextRowProcess, setPreventNextRowProcess] = useState(null);

  const handleOpenTagsModal = useCallback((id, isNew) => {
    setPreventNextRowProcess(id);
    setEditingTagsForRow({ id, isNew });
  }, []);

  const unitRounding = useSystemUnitRounding();

  const columns = useMemo(
    () =>
      lotTransferColumns({
        handleDeleteRow,
        handleClearRow,
        fromWarehouseId,
        toWarehouseId,
        handleOpenTagsModal,
        canEdit,
        unitRounding,
      }),
    [handleDeleteRow, handleClearRow, fromWarehouseId, toWarehouseId, canEdit, handleOpenTagsModal, unitRounding]
  );

  const createRow = () => {
    return {
      lot: null,
      unitsTransferred: null,
    };
  };

  const rowToLineItem = (row) => {
    return {
      lotId: row?.lot?.id,
      unitsTransferred: row?.unitsTransferred,
    };
  };

  const [isAlgoliaMounted, setIsAlgoliaMounted] = useState(false);

  const handleMount = () => {
    setIsAlgoliaMounted(true);
  };

  const handleCloseTagsModal = useCallback(() => {
    setEditingTagsForRow(undefined);
  }, []);

  const handleSaveTransferredTags = useCallback(
    async (
      { id, isNew },
      palletTags: {
        palletTag: { id: number; tagNumber: string };
        quantity: number;
      }[]
    ) => {
      const isEditing = apiRef.current.getRowMode(id) === 'edit';

      if (isEditing) {
        const rowNode = apiRef.current.getRowNode(id);

        if (!rowNode) {
          return;
        }

        apiRef.current.setEditCellValue({
          id,
          field: 'palletTags',
          value: palletTags,
        });

        handleCloseTagsModal();

        // setLotTransferItemTags only works on existing transfer items
        if (isNew) {
          return;
        }
      }

      const { data } = await setLotTransferItemTags({
        variables: {
          id: id,
          // strip tagNumber from the input
          input: palletTags.map(({ palletTag, quantity }) => ({
            palletTagId: palletTag.id,
            quantity,
          })),
        },
      });

      const palletTagsOnTransferItem = data.setPalletTagsOnTransferItem.filter(({ deletedAt }) => !deletedAt);

      setTransferRows((transferRows) =>
        transferRows.map((row) => {
          if (row.id === id) {
            return {
              ...row,
              palletTagsOnTransferItem,
            };
          }

          return row;
        })
      );

      handleCloseTagsModal();
    },
    [apiRef, handleCloseTagsModal, setLotTransferItemTags, setTransferRows]
  );

  const selectedLotIds = useMemo(() => transferRows.map((row) => row.lot?.id).filter(Boolean), [transferRows]);

  return (
    <>
      {!isAlgoliaMounted && <ProductsGridSkeleton />}
      <InstantSearch searchClient={algolia} indexName={SearchIndex.Products}>
        <CheckMount onMount={handleMount} />
        {isAlgoliaMounted && (
          <>
            <Typography sx={{ pl: 1, pb: 0.5 }} variant="h6">
              Lots
            </Typography>
            <AddableDataGrid
              rows={transferRows}
              columns={columns}
              apiRef={apiRef}
              storageKey={INVENTORY_TRANSFER_GRID_ID}
              initialState={{
                density: 'compact',
                aggregation: {
                  model: {
                    unitsTransferred: 'sum',
                  },
                },
              }}
              shouldPreventRowProcess={(rowId) => {
                if (preventNextRowProcess === rowId) {
                  setPreventNextRowProcess(false);

                  return true;
                }
              }}
              rowModel={({ row }) =>
                z.object({
                  unitsTransferred: z
                    .number({
                      invalid_type_error: 'Must transfer more than 0 units',
                    })
                    .gt(0, {
                      message: 'Must transfer more than 0 units',
                    })
                    .refine((value) => row.lot.quantity >= value, {
                      message: `Lot does not have enough units to transfer`,
                    }),
                  lot: z
                    .object({
                      id: z.number().optional(),
                      slug: z.string().optional(),
                      warehouseId: z.number().optional(),
                      quantity: z.number().optional(),
                      transferredChildren: z
                        .array(
                          z
                            .object({
                              id: z.number().optional(),
                              slug: z.string().optional(),
                              warehouseId: z.number().optional(),
                            })
                            .optional()
                        )
                        .optional(),
                    })
                    .refine(
                      (value) => selectedLotIds.filter((selectedLotId) => selectedLotId === value.id).length <= 1,
                      {
                        message: 'Each lot can only be included once per transfer',
                      }
                    ),
                })
              }
              canEdit={canEdit}
              fieldToFocus="lot"
              newRowLabel="Enter New Lot"
              rowToInput={rowToLineItem}
              createRow={createRow}
              processCellSave={handleSaveLineItem}
              processCellChanges={processRowChanges}
              setRows={setTransferRows}
              onProcessRowUpdateError={(error) => {
                console.error(error);
              }}
            />
          </>
        )}
      </InstantSearch>
      <PalletTagSelectionModal
        handleCloseTagsModal={() => setEditingTagsForRow(undefined)}
        handleSaveTransferredTags={handleSaveTransferredTags}
        editingTagsForRow={editingTagsForRow}
        rows={transferRows}
        apiRef={apiRef}
        getRowPalletTags={(row) => row?.palletTags ?? row?.palletTagsOnTransferItem}
        getUnitsToCompare={(row) => row?.unitsTransferred}
        canEdit={canEdit}
      />
    </>
  );
};

export const ProductsGridSkeleton = () => {
  const columns = useMemo(() => lotTransferColumns(), []);

  return (
    <>
      <Skeleton>
        <Typography sx={{ pl: 1, pb: 0.5 }} variant="h6">
          Products
        </Typography>
      </Skeleton>
      <SkeletonDataGrid
        rowCount={4}
        columns={columns}
        initialState={{ density: 'compact' }}
        hideFooter
        enterNewText="Enter New Lot"
        storageKey={INVENTORY_TRANSFER_GRID_ID}
      />
    </>
  );
};
