import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import { getPrimaryPlace } from 'src/services/utils/getPrimaryPlace';

import {
  BOLOrderHeader,
  DocumentDetailBlock,
  Footer,
  Notes,
  OrgDetailsBlock,
  ProductsGrid,
  SignatureLines,
  TermsAndTotals,
} from '../sections';
import { docStyles } from '../utils/styles';

export function MultiBOLHeader({ organization, load }) {
  const address = getPrimaryPlace(organization.places);

  return (
    <View style={docStyles.pageHeaderContainer}>
      <OrgDetailsBlock
        documentName={'BILL OF LADING'}
        orgLogoUrl={organization.logoUrl}
        orgName={organization.label}
        address={address}
        user={load.coordinator?.user}
      />
    </View>
  );
}

const getWaypointLocations = (waypoints, order) => {
  const orderWaypoints = waypoints.filter((w) => {
    const { purchaseOrder, salesOrder, buySellOrder } = w.loadOrderConnection;
    const waypointOrder = purchaseOrder || salesOrder || buySellOrder;

    return order.__typename === waypointOrder.__typename && order.id === waypointOrder.id;
  });

  const pickUp = orderWaypoints.find((w) => w.type === 'PICK_UP');
  const dropOff = orderWaypoints.find((w) => w.type === 'DROP_OFF');

  return { pickUp, dropOff };
};

// TODO: Add type for input
export default function MultiBillOfLadingDocument({ organization, load, orders, waypoints }) {
  const notes = load.notes.filter((n) => n.documentTypes.includes('BILL_OF_LADING'));

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <MultiBOLHeader organization={organization} load={load} />

        {orders.map((order) => {
          const locations = getWaypointLocations(waypoints, order);
          const productsProps = {};

          if (order.__typename === 'PurchaseOrder') {
            productsProps['showUnitsOrdered'] = true;
          }

          if (order.__typename === 'SalesOrder') {
            productsProps['showUnitsPicked'] = true;
          }

          if (order.__typename === 'BuySellOrder') {
            productsProps['showUnitsOrdered'] = true;
          }

          return (
            <>
              <View style={docStyles.pageSplitContainer}>
                <BOLOrderHeader order={order} showDelivery={false} />
                <View style={docStyles.documentDetailsContainer}>
                  {locations.pickUp && (
                    <DocumentDetailBlock headerText={'Pick Up'}>
                      <Text>{moment(locations.pickUp.arrivalTime).format('MM/DD/YYYY hh:mm A')}</Text>
                      <Text>{locations.pickUp.location}</Text>
                    </DocumentDetailBlock>
                  )}
                  {locations.dropOff && (
                    <DocumentDetailBlock headerText={'Drop Off'}>
                      <Text>{moment(locations.dropOff.arrivalTime).format('MM/DD/YYYY hh:mm A')}</Text>
                      <Text>{locations.dropOff.location}</Text>
                    </DocumentDetailBlock>
                  )}
                </View>
              </View>
              <ProductsGrid productLineItems={order.lineItems} showLineItemWeight {...productsProps} />
            </>
          );
        })}

        <Notes notes={notes} />

        <TermsAndTotals />

        <SignatureLines />
        <Footer />
      </Page>
    </Document>
  );
}
