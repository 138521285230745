import { useQuery } from '@redwoodjs/web';

const GET_ENABLED_CUSTOMER_GROUPS = gql`
  query GetEnabledCustomerGroups {
    customerGroups(enabled: true) {
      id
      name
    }
  }
`;

export default function useEnabledCustomerGroups(): { id: number; name: string }[] {
  const { data } = useQuery(GET_ENABLED_CUSTOMER_GROUPS);
  return data?.customerGroups ?? [];
}
