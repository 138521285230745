import { Modal, Card, Typography, Box, Button, CardHeader } from '@mui/material';

export type ConfirmModalLabelProps = {
  title: string;
  prompt: string | React.ReactNode;
  action?: string;
  color?: string;
};

export type ConfirmButtonProps = {
  handleClose: () => void;
  handleConfirm: () => void;
  open: boolean;
} & ConfirmModalLabelProps;

export default function ConfirmModal({
  title,
  prompt,
  action = 'Delete',
  color = 'error.main',
  open,
  handleClose,
  handleConfirm,
}: ConfirmButtonProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 500,
        }}
        elevation={24}
      >
        <CardHeader title={title} />
        <Box sx={{ px: 1 }}>
          {typeof prompt === 'string' ? (
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
              {prompt}
            </Typography>
          ) : (
            prompt
          )}
        </Box>
        <Box sx={{ ml: 'auto', display: 'flex', gap: 1 }}>
          <Button sx={{ color: 'text.secondary' }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{
              color,
            }}
          >
            {action}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
