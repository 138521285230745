import { useState } from 'react';

import { Box, Button, FormControlLabel, Radio, Stack } from '@mui/material';

import { FilterItemProps } from './FilterItem';

export default function BooleanFilter({ removeFilter, updateFilter, filter, column }: FilterItemProps) {
  const [value, setValue] = useState<boolean>(() => {
    const condition = filter.where?.[0]?.equals;
    if (typeof condition === 'boolean') {
      return condition;
    }
    return undefined;
  });
  return (
    <>
      <Stack sx={{ gap: 1 }}>
        <FormControlLabel
          sx={{ p: 0.5, m: 0, gap: 1, '&:hover': { bgcolor: 'primary.100' } }}
          control={<Radio checked={value === true} size="small" sx={{ py: 0.5, px: 1 }} />}
          label="Yes"
          onChange={() => setValue(true)}
        />
        <FormControlLabel
          sx={{ p: 0.5, m: 0, gap: 1, '&:hover': { bgcolor: 'primary.100' } }}
          control={<Radio checked={value === false} size="small" sx={{ py: 0.5, px: 1 }} />}
          label="No"
          onChange={() => setValue(false)}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          p: 1,
          borderTop: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Button variant="text" color="primary" onClick={removeFilter}>
          Clear
        </Button>
        <Button
          variant="contained"
          disabled={typeof value === 'undefined'}
          color="primary"
          onClick={() => {
            updateFilter({ field: filter.field, where: [{ equals: value }] });
          }}
        >
          Apply
        </Button>
      </Box>
    </>
  );
}
