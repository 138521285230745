import { useMemo, useState } from 'react';

import { Box, Button, Divider, FormControlLabel, Switch } from '@mui/material';
import { Hit } from 'instantsearch.js';
import { DateTime } from 'luxon';
import { Configure } from 'react-instantsearch-hooks-web';
import { fromUTC } from 'shared/utils/DateTime';

import { MetaTags } from '@redwoodjs/web';

import FavoriteButton from 'src/components/atoms/buttons/FavoriteButton';
import ExportGridButton from 'src/components/atoms/ExportGridButton';
import LedgerAccountsCell from 'src/components/cells/LedgerAccountsCell';
import { MainCard } from 'src/components/containers/MainCard';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import { LedgerAccountIndex } from 'src/lib/algolia';
import { useOrganizationQuery } from 'src/modules/closingPeriods/api/closingPeriods';
import { ClosingPeriodButton } from 'src/modules/closingPeriods/atoms/ClosingPeriodButton';
import { ClosingPeriodInfo } from 'src/modules/closingPeriods/atoms/ClosingPeriodInfo';
import ClosingPeriodModal from 'src/modules/closingPeriods/modals/ClosingPeriodModal';
import { SearchIndex } from 'src/providers/AlgoliaProvider';
import { RefineCellController } from 'src/providers/RefineProvider';
import PersistentSearchProvider, { PersistantSearch, usePersistentSearch } from 'src/providers/SearchBoxProvider';

// TODO TAS-759 -- setup actual filters & pagination
const ChartOfAccountsSearchConfig = ({ hitsPerPage = 1000, page = 0 }: { hitsPerPage?: number; page?: number }) => {
  const { filters } = usePersistentSearch();

  return (
    <Configure
      // TODO: Requesting 1000 hits per page is as temp workaround until we can implement true server-side sorting
      // For now, we think that 1000 hits should be high enough us to get all indexed objects, and then we will
      // do client-side sorting
      hitsPerPage={hitsPerPage}
      filters=""
      page={page}
    />
  );
};

const ChartOfAccountsPage = () => {
  const [closingPeriodModalOpen, setClosingPeriodModalOpen] = useState(false);
  const [closedAt, setClosedAt] = useState<DateTime>(null);
  const { data, loading } = useOrganizationQuery();
  const [exportZeroBalances, setExportZeroBalances] = useState(false);

  useMemo(() => {
    const result = data?.organization?.closedAt;
    if (loading || result === null || result === undefined) {
      return;
    }

    setClosedAt(fromUTC(result));
  }, [data, loading]);

  return (
    <>
      <MetaTags title="Accounts" />
      <PageHeader pageType={PageType.ChartOfAccounts}>
        <Box sx={{ ml: 'auto', gap: 1, display: 'flex', my: 'auto' }}>
          <FavoriteButton label="Accounts" />
          <ClosingPeriodInfo closedAt={closedAt} />
          <ClosingPeriodButton onClick={() => setClosingPeriodModalOpen(true)} />
          <ExportGridButton
            canPrint={false}
            optionsComponent={
              <FormControlLabel
                control={<Switch />}
                label="Include 0 Balances"
                labelPlacement="start"
                checked={exportZeroBalances}
                onChange={(_, value) => {
                  setExportZeroBalances(value);
                }}
                sx={{ mx: 2 }}
              />
            }
          />
        </Box>
      </PageHeader>
      <ClosingPeriodModal
        open={closingPeriodModalOpen}
        onClose={() => setClosingPeriodModalOpen(false)}
        onClosingPeriodChanged={(closedAt) => {
          setClosingPeriodModalOpen(false);
          setClosedAt(closedAt);
        }}
      />
      <PageBody>
        <MainCard noPadding>
          <PersistentSearchProvider>
            <PersistantSearch indexName={SearchIndex.LedgerAccounts}>
              <ChartOfAccountsSearchConfig hitsPerPage={1000} page={0} />
              <RefineCellController
                render={(entries: Hit<LedgerAccountIndex>) => (
                  <LedgerAccountsCell exportZeroBalances={exportZeroBalances} hits={entries} />
                )}
              />
            </PersistantSearch>
          </PersistentSearchProvider>
        </MainCard>
      </PageBody>
    </>
  );
};

export default ChartOfAccountsPage;
