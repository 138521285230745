import * as filestack from 'filestack-js';

const client = filestack.init(process.env.FILESTACK_API_KEY);

export const uploadToFilestack = (file: Blob, filename?: string) => {
  const uploadOptions: filestack.UploadOptions = {};
  const storageOptions: filestack.StoreUploadOptions = { filename };

  return client.upload(file, uploadOptions, storageOptions);
};
