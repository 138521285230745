const GetStandaloneInvoiceDocumentData = gql`
  query GetStandaloneInvoiceDocumentData($slug: String!) {
    standardInvoice(slug: $slug) {
      organization {
        label
        logoUrl
        primaryLocation {
          ...PlaceAttributes
        }
      }
      slug
      totalAmountDue
      invoiceDate
      payments {
        paymentAmount
      }
      paymentTerms
      referencePayableNum
      salesOrder {
        slug
      }
      billingAddress {
        ...PlaceAttributes
      }
      billTo {
        name
      }
      billToContact {
        name
      }
      purchaseOrder {
        slug
      }
      workOrder {
        slug
      }
      buySellOrder {
        slug
      }
      expenses {
        ...ExpenseAttributes
      }
      notes {
        ...NoteAttributes
      }
    }
  }
`;

export default {
  GetStandaloneInvoiceDocumentData,
};
