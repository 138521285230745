import React from 'react';

import { Prisma } from '.prisma/client';
import { Document, Page, Text, View } from '@react-pdf/renderer';

import { BOLHeader, Expenses, Footer, Notes, ProductsGrid, TermsAndTotals, formatDate } from '../sections';
import { SignatureLinesGroup } from '../sections/SignatureLinesGroup';
import { docStyles } from '../utils/styles';

import { BOLShippingDetails } from './BillOfLadingDocument';

type BSOPurchaseOrderTemplateProps = Prisma.BuySellOrderGetPayload<{
  include: {
    organization: { include: { places: true } };
    broker: { include: { user: true; subsidiary: true } };
    vendor: true;
    vendorContact: true;
    customer: true;
    customerContact: true;
    shipment: true;
    subsidiary: true;
    lineItems: {
      include: {
        lot: true;
        product: {
          include: {
            commodity: true;
            commoditySize: true;
            commodityStyle: true;
            commodityUnit: true;
            commodityLabel: true;
          };
        };
      };
    };
    expenses: true;
    notes: {
      include: {
        membership: {
          include: {
            user: true;
          };
        };
      };
    };
  };
}>;

export function BSOBillOfLadingTemplate({ buySellOrder, displayUnitPrice, expenseCategories, customTerms }) {
  const notes = buySellOrder.notes.filter((n) => n.documentTypes.includes('BILL_OF_LADING'));

  const shipment = buySellOrder.shipment;
  const shipDate = formatDate(shipment.shipDate);
  const deliveryDate = formatDate(shipment.deliveryDate);

  const expenses = buySellOrder.expenses?.filter((expense) => expenseCategories.includes(expense.category));

  const hasExpenses = expenses.length > 0;

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <BOLHeader order={buySellOrder} showDateBlocks={false} />

        <BOLShippingDetails shipDate={shipDate} deliveryDate={deliveryDate} shipment={shipment} />

        <ProductsGrid
          productLineItems={buySellOrder.lineItems.filter(
            // Don't show line items with 0 units picked
            (lineItem) => lineItem.unitsShipped > 0
          )}
          showLineItemWeight={true}
          showUnitsOrdered={true}
          showUnitsShipped
          orderType="BSO"
          showUnitPrice={displayUnitPrice}
          currency={buySellOrder.currencyCode}
          productTemplate={buySellOrder.organization.productDescriptionTemplate}
        />

        {hasExpenses && <Expenses expenses={expenses} currency={buySellOrder.currencyCode} />}

        <TermsAndTotals terms={customTerms} />

        <SignatureLinesGroup signatures={['Consignee', 'Driver', 'Receiver']} />

        {customTerms?.belowSignatures && (
          <View
            style={{
              fontSize: 8,
              paddingHorizontal: 30,
            }}
          >
            <Text>{customTerms?.belowSignatures}</Text>
          </View>
        )}

        {notes.length > 0 && <Notes notes={notes} />}

        <Footer />
      </Page>
    </Document>
  );
}
