import { useQuery } from '@redwoodjs/web';

export type UseWarehousesResult = {
  id: number;
  tagKeys: string[];
  place: {
    id: number;
    description: string;
    fullAddress: string;
  };
};

export function useWarehouses({ skip }: { skip?: boolean }): UseWarehousesResult[] {
  const query = useQuery(
    gql`
      query GetWarehouses {
        warehouses {
          id
          tagKeys
          place {
            id
            description
            fullAddress
          }
        }
      }
    `,
    { skip }
  );
  return query.data?.warehouses ?? [];
}

export function useWarehouseInfo(placeId?: number): UseWarehousesResult | null {
  const query = useQuery(
    gql`
      query GetWarehouseInfo($placeId: Int!) {
        warehouse(placeId: $placeId) {
          id
          tagKeys
          place {
            id
            description
            fullAddress
          }
        }
      }
    `,
    { variables: { placeId }, skip: !placeId }
  );

  return query.data?.warehouse ?? null;
}

export type UseWarehouseLocationsResult = {
  id: number;
  slug: string;
  description: string;
  tags: Record<string, string>;
};

export function useWarehouseLocations(placeId?: number): UseWarehouseLocationsResult[] {
  const query = useQuery(
    gql`
      query GetWarehouseLocations($placeId: Int!) {
        warehouseLocations(placeId: $placeId) {
          id
          slug
          description
          tags
        }
      }
    `,
    { variables: { placeId }, skip: !placeId }
  );

  return query.data?.warehouseLocations ?? [];
}

export const GET_WAREHOUSE_PALLETS = gql`
  query GetWarehousePlacePallets($placeId: Int!) {
    warehouse(placeId: $placeId) {
      place {
        palletTags(excludeClosedLots: true) {
          id
          tagNumber
          warehouseLocation {
            description
          }
        }
      }
    }
  }
`;

export function useWarehousePalletTags(placeId?: number) {
  const query = useQuery(GET_WAREHOUSE_PALLETS, { variables: { placeId }, skip: !placeId });

  return query.data?.warehouse?.place?.palletTags ?? [];
}
