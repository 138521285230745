import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';

import { useQuery } from '@redwoodjs/web';

import { FETCH_PAYMENTS_FROM_LEDGER_ENTRIES } from 'src/api/ledgerEntries.api';
import { GET_MEMBERSHIPS } from 'src/api/memberships.api';
import { useAuth } from 'src/auth';
import Autocomplete from 'src/components/atoms/Autocomplete';
import LoadingButton from 'src/components/atoms/buttons/LoadingButton';
import QuickDatePicker from 'src/components/atoms/QuickDatePicker';
import InvoiceLink from 'src/modules/accounting/invoices/InvoiceLink';
import getFullName from 'src/utils/getFullName';
import money from 'src/utils/money';

import { LedgerEntryGroup } from '$api/types/graphql';

type PaymentDetails = {
  key: number | string;
  parentPaymentId: number;
  memo: string;
  payerName: string;
  amount: number;

  invoice?: {
    __typename: string;
    slug: string;
  };
  standardInvoice?: {
    __typename: string;
    slug: string;
  };
  buySellOrderInvoice?: {
    __typename: string;
    slug: string;
  };
};

export type CreateDepositSlipModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmitDeposit: (depositSlip: { depositedByMemberId: number; depositDate: DateTime; memo: string }) => Promise<void>;
  account: {
    name: string;
    path: string;
  };
  payments?: PaymentDetails[];
  entries?: LedgerEntryGroup[];
};

export const CreateDepositSlipModal = ({
  open,
  onClose,
  onSubmitDeposit,
  account,
  payments,
  entries,
}: CreateDepositSlipModalProps) => {
  const [depositedBy, setDepositedBy] = useState(null);
  const [depositDate, setDepositDate] = useState(DateTime.now().toISODate());
  const [memo, setMemo] = useState('');
  const [memberships, setMemberships] = useState<
    { id: number; user: { id: number; firstName: string; lastName: string } }[]
  >([]);
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails[]>([]);

  const getMemberships = useQuery(GET_MEMBERSHIPS);
  const fetchPaymentsFromLedgerEntries = useQuery(FETCH_PAYMENTS_FROM_LEDGER_ENTRIES, {
    variables: { ledgerEntryKeys: entries?.map((e) => e.entry.key) ?? [] },
  });

  const { currentUser } = useAuth();

  useEffect(() => {
    if (getMemberships.error) {
      setMemberships([]);
    } else if (getMemberships.data) {
      setMemberships(getMemberships.data.memberships);
    }
  }, [getMemberships.data?.memberships]);

  useEffect(() => {
    if (!depositedBy) {
      setDepositedBy(memberships.find((membership) => membership.user.id === currentUser?.userId));
    }
  }, [memberships, depositedBy, currentUser]);

  useEffect(() => {
    if (payments) {
      setPaymentDetails(payments);
    } else if (entries && fetchPaymentsFromLedgerEntries?.data?.ledgerEntries) {
      setPaymentDetails(
        fetchPaymentsFromLedgerEntries.data.ledgerEntries.flatMap((tuple) =>
          tuple.entry.payments.map((p) => ({
            key: p.id,
            memo: p.paymentDetails,
            amount: p.paymentAmount,
            payerName: p.payer?.name ?? '--',
            parentPaymentId: p.parentPaymentId,
            invoice: p.invoice,
            standardInvoice: p.standardInvoice,
            buySellOrderInvoice: p.buySellOrderInvoice,
          }))
        )
      );
    }
  }, [entries, payments, fetchPaymentsFromLedgerEntries?.data?.ledgerEntries]);

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>Create New Deposit Slip</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a new deposit slip for the selected checks.</DialogContentText>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" flexDirection="row" gap={2} width="100%" mt={2}>
            <FormControl>
              <TextField label="Bank Account" disabled value={account?.name ?? ''} />
            </FormControl>
            <FormControl>
              <Autocomplete
                value={depositedBy}
                label="Deposited By"
                getOptionLabel={(option) => getFullName(option.user)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => {
                  setDepositedBy(value);
                }}
                options={memberships}
                sx={{
                  minWidth: '258px',
                }}
              />
            </FormControl>
            <FormControl>
              <QuickDatePicker
                label="Deposit Date"
                value={depositDate}
                onChange={(data) => {
                  setDepositDate(DateTime.fromISO(data, { zone: 'utc' }).toISODate());
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Box>

          <FormControl>
            <TextField
              label="Deposit Memo"
              value={memo}
              onChange={(e) => {
                setMemo(e.target.value);
              }}
            />
          </FormControl>
        </Box>
        <Box>
          <DepositSlipDetails payments={paymentDetails} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          handleRequest={() =>
            onSubmitDeposit({
              depositedByMemberId: depositedBy.id,
              depositDate: DateTime.fromFormat(depositDate, 'yyyy-MM-dd'),
              memo,
            }).then(() => onClose())
          }
        >
          Deposit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const DepositSlipDetails = ({ payments }: { payments: PaymentDetails[] }) => {
  const totalAmount = payments.reduce(
    (sum, check) =>
      // the parent payment already accounts for the child payment
      check.parentPaymentId ? sum : sum + check.amount,
    0
  );

  const parentChecks = payments.filter((check) => !check.parentPaymentId);

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Check Number</TableCell>
            <TableCell>Payer</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Invoices</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parentChecks.map((check) => (
            <TableRow key={check.key}>
              <TableCell>{check.memo}</TableCell>
              <TableCell>{check.payerName}</TableCell>
              <TableCell>{money.toHuman(check.amount)}</TableCell>
              <TableCell>
                <InvoiceLinkList
                  invoices={payments
                    .map((child) => {
                      const invoice = child.invoice ?? child.standardInvoice ?? child.buySellOrderInvoice;

                      if (!invoice) {
                        return null;
                      }

                      const isChild = child.parentPaymentId === check.key;
                      const isParent = child.key === check.key;

                      if (!isChild && !isParent) {
                        return null;
                      }

                      return {
                        type: invoice.__typename,
                        slug: invoice.slug,
                      };
                    })
                    .filter(Boolean)}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell />
            <TableCell>
              <b>{money.toHuman(totalAmount)}</b>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

const InvoiceLinkList = ({ invoices }) => {
  return (
    <Box display="flex" width="100%">
      {invoices.map((invoice, i) => (
        <>
          <InvoiceLink slug={invoice.slug} type={invoice.type} />
          {i < invoices.length - 1 && <Typography mr={1}>,</Typography>}
        </>
      ))}
    </Box>
  );
};
