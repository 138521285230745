import { DateRange, StaticDateRangePicker, StaticDateRangePickerProps } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { asUTCDate, parseDate } from 'shared/utils/DateTime';

export default function LuxonStaticDateRangePicker({
  onChange,
  value,
  ...props
}: StaticDateRangePickerProps<DateTime, DateTime>) {
  return (
    <StaticDateRangePicker
      displayStaticWrapperAs="desktop"
      onChange={(range) => {
        const [start, end] = range ?? [null, null];

        onChange([asUTCDate(start), asUTCDate(end)?.endOf('day')] as DateRange<DateTime>);
      }}
      value={value?.map((date) => parseDate(date)) as DateRange<DateTime>}
      {...props}
    />
  );
}
