export const UPDATE_LEDGER_LINE_MEMO = gql`
  mutation UpdateLedgerLineMemo($ledgerLineKey: String!, $memo: String!) {
    updateLedgerLine(ledgerLineKey: $ledgerLineKey, input: { memo: $memo }) {
      organizationId
      ledgerKey

      entry {
        key
        memo
        postedAt
        createdAt
        sources {
          slug
          type
        }
        reconciled
      }

      lines {
        key
        account {
          path
          name
        }
        amount
        rollingBalance
        sources {
          slug
          type
        }
        memo
      }
    }
  }
`;

export const UPDATE_LEDGER_LINE_AMOUNT = gql`
  mutation UpdateLedgerLineAmount($ledgerLineKey: String!, $amount: Int!) {
    updateLedgerLine(ledgerLineKey: $ledgerLineKey, input: { amount: $amount }) {
      organizationId
      ledgerKey

      entry {
        key
        memo
        postedAt
        createdAt
        sources {
          slug
          type
        }
        reconciled
      }

      lines {
        key
        account {
          path
          name
        }
        amount
        rollingBalance
        sources {
          slug
          type
        }
        memo
      }
    }
  }
`;

export const UPDATE_LEDGER_ENTRY_MEMO = gql`
  mutation UpdateLedgerEntryMemo($ledgerEntryKey: String!, $memo: String!) {
    updateLedgerEntry(ledgerEntryKey: $ledgerEntryKey, input: { memo: $memo }) {
      organizationId
      ledgerKey

      entry {
        key
        memo
        postedAt
        createdAt
        sources {
          slug
          type
        }
        reconciled
      }

      lines {
        key
        account {
          path
          name
        }
        amount
        rollingBalance
        sources {
          slug
          type
        }
        memo
      }
    }
  }
`;

export const OPEN_RECONCILIATION_SESSION = gql`
  mutation OpenReconciliationSession($input: CreateAccountReconciliationInput!) {
    createAccountReconciliation(input: $input) {
      id
      startDate
      endDate
    }
  }
`;

export const UPDATE_LEDGER_ENTRY_RECONCILIATION = gql`
  mutation UpdateLedgerEntryReconciliation($ledgerEntryKey: String!, $reconciled: Boolean!, $accountPath: String!) {
    updateLedgerEntry(ledgerEntryKey: $ledgerEntryKey, input: { reconciled: $reconciled, accountPath: $accountPath }) {
      organizationId
      ledgerKey

      entry {
        key
        memo
        postedAt
        createdAt
        sources {
          slug
          type
        }
        reconciled
      }

      lines {
        key
        account {
          path
          name
        }
        amount
        rollingBalance
        sources {
          slug
          type
        }
        memo
      }
    }
  }
`;

export const COMPLETE_OPEN_RECONCILIATION_SESSION = gql`
  mutation CompleteOpenReconciliationSession(
    $assetAccountPath: String!
    $adjustmentAmount: Int!
    $endingBalanceDate: Date!
  ) {
    completeOpenReconciliationSession(
      assetAccountPath: $assetAccountPath
      adjustmentAmount: $adjustmentAmount
      endingBalanceDate: $endingBalanceDate
    ) {
      organizationId
      ledgerKey

      entry {
        key
        memo
        postedAt
        createdAt
        sources {
          slug
          type
        }
        reconciled
      }

      lines {
        key
        account {
          path
          name
        }
        amount
        rollingBalance
        sources {
          slug
          type
        }
        memo
      }
    }
  }
`;

export const FETCH_LEDGER_ENTRY_GROUPS = gql`
  query fetchLedgerEntries($ledgerEntryKeys: [String!]!) {
    ledgerEntries(ledgerEntryKeys: $ledgerEntryKeys) {
      organizationId
      ledgerKey

      entry {
        key
        memo
        postedAt
        createdAt
        sources {
          slug
          type
        }
        reconciled
        depositSlip {
          id
          depositedAt
          memo
        }
        accountReconciliation {
          id
        }
      }

      lines {
        key
        account {
          path
          name
        }
        amount
        rollingBalance
        sources {
          slug
          type
        }
        memo
      }
    }
  }
`;

export const FETCH_PAYMENTS_FROM_LEDGER_ENTRIES = gql`
  query fetchPaymentsFromLedgerEntries($ledgerEntryKeys: [String!]!) {
    ledgerEntries(ledgerEntryKeys: $ledgerEntryKeys) {
      entry {
        key
        payments {
          id
          parentPaymentId
          paymentDetails
          payer {
            name
          }
          paymentAmount

          invoice {
            __typename
            slug
          }

          standardInvoice {
            __typename
            slug
          }

          buySellOrderInvoice {
            __typename
            slug
          }
        }
      }
    }
  }
`;

export const CREATE_DEPOSIT_SLIP_FROM_ENTRIES = gql`
  mutation createDeposit($input: CreateDepositSlipFromEntriesInput!) {
    createDepositSlipFromEntries(input: $input) {
      organizationId
      ledgerKey

      entry {
        key
        memo
        postedAt
        createdAt
        sources {
          slug
          type
        }
        reconciled
        depositSlip {
          id
          depositedAt
          memo
        }
      }

      lines {
        key
        account {
          path
          name
        }
        amount
        rollingBalance
        sources {
          slug
          type
        }
        memo
      }
    }
  }
`;
