import { FilterItemProps } from 'src/modules/grids/toolbar/filters/FilterItem';
import { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';

export default function ScalarTextFilter({ removeFilter, updateFilter, filter, column }: FilterItemProps) {
  const [value, setValue] = useState(() => {
    const val = filter.where?.[0]?.has;
    return column.serverFilterValueTransform?.from?.(val) ?? val ?? '';
  });

  return (
    <>
      <TextField size="small" value={value} onChange={(e) => setValue(e.target.value)} sx={{ m: 1 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          p: 1,
          borderTop: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Button variant="text" color="primary" onClick={removeFilter}>
          Clear
        </Button>
        <Button
          variant="contained"
          disabled={!value}
          color="primary"
          onClick={() => {
            const v = column.serverFilterValueTransform?.to?.(value) ?? value;
            updateFilter({ field: filter.field, where: [{ has: v }] });
          }}
        >
          Apply
        </Button>
      </Box>
    </>
  );
}
