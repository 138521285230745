export type OrderType = 'purchaseOrder' | 'salesOrder' | 'buySellOrder' | 'workOrder' | 'load' | 'inventoryTransfer';

export type OrderReference = { type: OrderType; id: number; slug: string };

const SLUG_PREFIXES = {
  purchaseOrder: 'PO',
  salesOrder: 'SO',
  buySellOrder: 'BSO',
  workOrder: 'GP',
  load: 'L',
  inventoryTransfer: 'IT',
};

/**
 * Strips the order acronym prefix from an invoice or payable slug. Order acronym prefixes should be stripped for
 * any external facing display of the slug (i.e. in generated documents). If the slug doesn't have an order
 * acronym prefix, then it is returned as-is.
 */
export const stripSlugPrefix = (slug: string) => {
  const prefix = slug.split('-')[0];

  if (!Object.values(SLUG_PREFIXES).includes(prefix)) {
    return slug;
  }

  return slug.replace(`${prefix}-`, '');
};

/**
 * Generates a slug for a new invoice (or payable) based on the number of existing invoices (or payables) for the
 * order. Follows the format: <order acronym>-<order slug>-<invoice number>
 *
 * @param numExistingInvoices - count of existing invoices (or payables) for this order. Should include soft-deleted
 *  or voided invoices.
 * @param orderSlug - slug of the order this invoice (or payable) is for
 * @param orderType - type of order this invoice (or payable) is for
 * @returns the slug for the new invoice (or payable)
 */
export const generateInvoiceOrPayableSlug = (numExistingInvoices: number, orderSlug: string, orderType: OrderType) => {
  const invoiceNumber = String(numExistingInvoices + 1);

  return `${SLUG_PREFIXES[orderType]}-${orderSlug}-${invoiceNumber}`;
};

export const generateStandaloneInvoiceOrPayableSlug = (numExistingInvoices: number) => {
  return String(numExistingInvoices + 1);
};
