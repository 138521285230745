import { useMutation } from '@redwoodjs/web';

import { GET_PRICING_SHEET_ENTRIES } from './usePricingSheetEntries';

const REMOVE_PRODUCT_FROM_PRICING_SHEET = gql`
  mutation RemoveProductFromPricingSheet($id: Int!) {
    deletePricingSheetEntry(id: $id) {
      id
    }
  }
`;

export default function useRemoveProductFromSheet() {
  const [removeProduct] = useMutation(REMOVE_PRODUCT_FROM_PRICING_SHEET, {
    refetchQueries: [GET_PRICING_SHEET_ENTRIES],
  });

  return async (id: number) => {
    return removeProduct({ variables: { id } });
  };
}
