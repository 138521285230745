import { useEffect, useMemo, useState } from 'react';

import { DocumentTemplateEntityType } from 'types/graphql';

import DocumentPreview, { DOCUMENT_SIZES } from 'src/modules/document-preview/components/DocumentPreview';
import useBuySellOrderDocument from 'src/modules/document-preview/hooks/useBuySellOrderDocument';
import useBuySellOrderInvoiceDocument from 'src/modules/document-preview/hooks/useBuySellOrderInvoiceDocument';
import useCustomerStatementDocument from 'src/modules/document-preview/hooks/useCustomerStatementDocument';
import useInvoiceDocument from 'src/modules/document-preview/hooks/useInvoiceDocument';
import usePurchaseOrderDocument from 'src/modules/document-preview/hooks/usePurchaseOrderDocument';
import useRemittanceDocument from 'src/modules/document-preview/hooks/useRemittanceDocument';
import useSalesOrderDocument from 'src/modules/document-preview/hooks/useSalesOrderDocument';
import useStandaloneInvoiceDocument from 'src/modules/document-preview/hooks/useStandaloneInvoiceDocument';

const useDynamicDocument = ({
  entity,
  queryVariables = {},
}: {
  queryVariables: any;
  entity: DocumentTemplateEntityType;
}) => {
  const salesOrderDocument = useSalesOrderDocument({
    ...queryVariables,
    skip: true,
  });

  const purchaseOrderDocument = usePurchaseOrderDocument({
    ...queryVariables,
    skip: true,
  });

  const remittanceDocument = useRemittanceDocument({
    ...queryVariables,
    skip: true,
  });

  const buySellOrderDocument = useBuySellOrderDocument({
    ...queryVariables,
    skip: true,
  });

  const invoiceDocument = useInvoiceDocument({
    ...queryVariables,
    skip: true,
  });

  const standaloneInvoiceDocument = useStandaloneInvoiceDocument({
    ...queryVariables,
    skip: true,
  });

  const buySellOrderInvoiceDocument = useBuySellOrderInvoiceDocument({
    ...queryVariables,
    skip: true,
  });

  const customerStatementDocument = useCustomerStatementDocument({
    ...queryVariables,
    skip: true,
  });

  const documentType = useMemo(() => {
    if (!entity) {
      return null;
    }

    switch (entity) {
      case 'SALES_ORDER':
        return salesOrderDocument;
      case 'REMITTANCE':
        return remittanceDocument;
      case 'PURCHASE_ORDER':
        return purchaseOrderDocument;
      case 'BUY_SELL_ORDER':
        return buySellOrderDocument;
      case 'INVOICE':
        return invoiceDocument;
      case 'STANDARD_INVOICE':
        return standaloneInvoiceDocument;
      case 'BUY_SELL_ORDER_INVOICE':
        return buySellOrderInvoiceDocument;
      case 'CUSTOMER_STATEMENT':
        return customerStatementDocument;
    }
  }, [
    entity,
    salesOrderDocument,
    standaloneInvoiceDocument,
    remittanceDocument,
    purchaseOrderDocument,
    buySellOrderDocument,
    invoiceDocument,
    buySellOrderInvoiceDocument,
    customerStatementDocument,
  ]);

  return documentType;
};

const tryParse = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    console.error('Could not parse document:', e);

    return null;
  }
};

export default function DocumentEditorPreview({
  open,
  css,
  html,
  entity,
  type,
  generationOptions,
  templateId,
  queryVariables,
}) {
  const documentGetter = useDynamicDocument({ entity, queryVariables });

  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    if (!entity) {
      return;
    }

    documentGetter
      .getDocumentData()
      .then(setDocumentData)
      .catch((e) => {
        console.error(e);
      });
  }, [documentGetter, entity, queryVariables]);

  if (!documentData || !open) {
    return null;
  }

  return (
    <DocumentPreview
      data={documentData}
      type={type}
      entityType={entity}
      {...DOCUMENT_SIZES.large}
      documentTemplate={
        open && documentData
          ? {
              id: templateId,
              generationOptions: tryParse(generationOptions),
              pages: [
                {
                  css,
                  html,
                },
              ],
            }
          : null
      }
    />
  );
}
