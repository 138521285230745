const firstDataField = (data) => {
  return data[Object.keys(data)[0]];
};

const isDataNull = (data) => {
  return firstDataField(data) === null;
};

const isDataEmptyArray = (data) => {
  const field = firstDataField(data);
  return Array.isArray(field) && field.length === 0;
};

/**
 * A custom `isEmpty` implementation that only checks if the first field is `null` or an empty array. This is different
 * from the default `isEmpty` implementation introduced in Redwood v5 that considers a cell empty if all fields are
 * `null` or `[]`.
 *
 * @remarks
 *
 * Consider the following queries. The former returns an object, the latter a list:
 *
 * ```js
 * export const QUERY = gql`
 *   post {
 *     title
 *   }
 * `
 *
 * export const QUERY = gql`
 *   posts {
 *     title
 *   }
 * `
 * ```
 *
 * If either are "empty", they return:
 *
 * ```js
 * {
 *   data: {
 *     post: null
 *   }
 * }
 *
 * {
 *   data: {
 *     posts: []
 *   }
 * }
 * ```
 *
 * Note that the latter can return `null` as well depending on the SDL (`posts: [Post!]`).
 *
 * @remarks
 *
 * We only check the first field (in the example below, `users`):
 *
 * ```js
 * export const QUERY = gql`
 *   users {
 *     name
 *   }
 *   posts {
 *     title
 *   }
 * `
 * ```
 */
const isFirstDataFieldEmpty = (data) => {
  return isDataNull(data) || isDataEmptyArray(data);
};

export default isFirstDataFieldEmpty;
