import { Font, StyleSheet } from '@react-pdf/renderer';

// import font from './Montserrat-Black.ttf';

const oliveExtraExtraLight = '#E0E0E0';
const oliveExtraLight = '#D7D8C2';
const oliveLight = '#F7F8E4';
const oliveMed = '#DFDFCD';
const oliveMedDark = '#6C7544';
const oliveMedMedDark = '#54612B';
const oliveDark = '#2E3817';

// Register Fonts
Font.register({
  family: 'Montserrat',
  fonts: [
    // Italic
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 800,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX8.ttf',
      fontStyle: 'italic',
      fontWeight: 900,
    },

    // Regular
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf',
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf',
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf',
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf',
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-.ttf',
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf',
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const pageHeaderColor = '#FAFBEC';
const pageFooterColor = '#FAFBEC';

const subTotalHeaderColor = '#697240';
const subTotalColumnColor = '#E0E1D1';

export const primaryMainTextColor = '#667239';
export const primaryLightTextColor = '#95A065';
export const primaryDarkTextColor = '#3A4710';

export const sideMarginPadding = 20;
export const DocumentFontSizes = {
  XXLarge: 16,
  XLarge: 12,
  Large: 12,
  Medium: 10,
  Small: 9,
  XSmall: 8,
  XXSmall: 6,
};

export const colors = {
  pageHeaderColor,
  pageFooterColor,
  subTotalHeaderColor,
  subTotalColumnColor,
  primaryMainTextColor,
  primaryLightTextColor,
  primaryDarkTextColor,
};

export const docStyles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    fontFamily: 'Montserrat',
    flexDirection: 'column',
    paddingBottom: 20,
  },

  // Header //
  // // Letter head // //
  pageHeaderContainer: {
    flexDirection: 'column',
    backgroundColor: pageHeaderColor,
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: sideMarginPadding,
  },
  pageSplitContainer: {
    flexDirection: 'column',
    backgroundColor: pageHeaderColor,
    paddingTop: 2,
    paddingBottom: 10,
    paddingHorizontal: sideMarginPadding,
  },
  letterHeadContainer: {
    flexDirection: 'row',
  },
  titleAndDetailsContainer: {
    flexDirection: 'column',
    fontWeight: 'bold',
    width: '50%',
  },
  organizationIdentifier: {
    flexDirection: 'row',
    alignContent: 'center',
    paddingVertical: 5,
  },
  documentTitle: {
    borderBottomWidth: 1.35,
    borderRadius: 1,
    borderBottomColor: primaryLightTextColor,
    color: primaryMainTextColor,
    fontSize: DocumentFontSizes.XLarge,
    lineHeight: 1.5,
    fontWeight: 'bold',
  },
  organizationLogo: {
    flex: 1,
    maxWidth: '60px',
    alignItem: 'center',
    justifyContent: 'center',
  },
  organizationName: {
    fontSize: DocumentFontSizes.XXLarge,
    fontWeight: 'bold',
    color: primaryDarkTextColor,
    alignSelf: 'center',
    paddingHorizontal: 10,
  },
  contactInformationContainer: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  billingAddressContainer: {
    borderBottomWidth: 1,
    borderBottomColor: primaryLightTextColor,
    borderRadius: 1,
    width: '40%',
  },
  billingAddressText: {
    fontSize: DocumentFontSizes.XSmall,
    lineHeight: 1.3,
  },
  phoneAndEmailContainer: {
    borderBottomWidth: 1,
    borderBottomColor: primaryLightTextColor,
    borderRadius: 1,
    width: '50%',
    paddingLeft: 10,
  },
  phoneAndEmailText: {
    fontSize: DocumentFontSizes.XSmall,
    lineHeight: 1.3,
  },
  // // Letter head // //

  // // Document Details // //
  documentDetailsContainer: {
    flexDirection: 'row',
    paddingTop: 15,
  },
  documentDetailsBox: {
    flex: 1,
  },
  documentDetailHeaderText: {
    borderBottomWidth: 1,
    borderBottomColor: primaryLightTextColor,

    fontWeight: 'bold',
    fontSize: DocumentFontSizes.Small,
    color: primaryMainTextColor,
    paddingVertical: 2,
  },
  documentDetailMainText: {
    fontSize: DocumentFontSizes.Medium,
    paddingVertical: 2,
    paddingRight: 5,
  },
  // // Document Details // //
  // Header //

  // General-Purpose Section Styles //
  sectionContainer: {
    flexDirection: 'column',
    paddingHorizontal: sideMarginPadding,
    paddingVertical: 10,
  },
  sectionHeader: {
    fontSize: DocumentFontSizes.Large,
    fontWeight: 'bold',
    color: primaryDarkTextColor,
    borderBottomWidth: 1,
    borderBottomColor: primaryDarkTextColor,
  },
  sectionLineItems: {
    flexDirection: 'row',
    paddingVertical: 10,
  },

  // Products + Expenses //
  lineItemContainer: {
    flexDirection: 'row',
    paddingHorizontal: sideMarginPadding,
    paddingVertical: 20,
  },
  lineItemMainTextCell: {
    fontSize: DocumentFontSizes.Small,
    lineHeight: 2.5,
    paddingHorizontal: 5,
    borderBottomWidth: 1,
    borderBottomColor: primaryLightTextColor,
  },
  lineItemMainText: {
    paddingTop: 5,
    verticalAlign: 'sub',
  },
  mainDescriptionColumn: {
    flex: 2,
  },
  tableColumn: {
    fontSize: DocumentFontSizes.Small,
    flex: 1,
  },
  columnHeader: {
    verticalAlign: 'sub',
    fontSize: DocumentFontSizes.Medium,
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: primaryDarkTextColor,
  },
  // // Subtotals columns // //
  subTotalColumn: {
    minWidth: 80,
    textAlign: 'right',
    fontSize: DocumentFontSizes.Small,
    backgroundColor: subTotalColumnColor,
  },
  subTotalHeader: {
    verticalAlign: 'sub',
    paddingVertical: 5,
    fontSize: DocumentFontSizes.Medium,
    borderBottomWidth: 1,
    borderBottomColor: primaryDarkTextColor,
    textAlign: 'center',
    backgroundColor: subTotalHeaderColor,
    color: 'white',
  },
  lineItemSubTotal: {
    paddingTop: 9,
    fontWeight: 'bold',
    verticalAlign: 'sub',
  },
  gridSubTotal: {
    paddingTop: 9,
    fontWeight: 'bold',
    verticalAlign: 'sub',
  },
  gridTotal: {
    paddingVertical: 4.5,
    fontWeight: 'bold',
  },
  // // Subtotal column // //
  // Products + Expenses //

  // Terms and Totals //
  termsAndTotalsContainer: {
    marginVertical: 10,
    flexDirection: 'row',
    paddingHorizontal: sideMarginPadding,
    color: 'white',
    justifyContent: 'space-between',
  },
  termsContainer: {
    paddingVertical: 2,
    paddingHorizontal: 5,

    backgroundColor: primaryDarkTextColor, // TODO: Change to color code, provided by Molly
  },
  termsTitle: {
    fontSize: DocumentFontSizes.Large,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: primaryLightTextColor,
  },
  termsText: {
    paddingVertical: 2,
    fontSize: DocumentFontSizes.XXSmall,
  },
  totalsContainer: {
    flexDirection: 'column',
    minWidth: 30,
    color: 'black',
    justifyContent: 'flex-end',
  },
  grandTotalRow: {
    flexDirection: 'row',
  },
  grandTotalTitle: {
    paddingVertical: 6,
    paddingHorizontal: 5,
    fontSize: DocumentFontSizes.Medium,
  },
  grandTotalText: {
    width: 85,
    paddingVertical: 5,
    borderBottomWidth: 1.5,
    borderBottomColor: 'black',
    color: 'black',
    textAlign: 'center',
    fontSize: DocumentFontSizes.Large,
    fontWeight: 'bold',
    backgroundColor: subTotalColumnColor,
  },
  // Terms and Totals //

  // Notes //
  notesContainer: {
    flexDirection: 'column',
    paddingHorizontal: sideMarginPadding,
    paddingVertical: 5,
  },
  notesHeader: {
    fontSize: DocumentFontSizes.Large,
    fontWeight: 'bold',
    color: primaryDarkTextColor,
    borderBottomWidth: 1,
    borderBottomColor: primaryLightTextColor,
  },
  noteContainer: {
    flexDirection: 'row',
    fontSize: DocumentFontSizes.Small,
    justifyContent: 'space-between',
    borderBottomWidth: 0.1,
    borderBottomColor: primaryLightTextColor,
    paddingTop: 5,
    paddingBottom: 2,
  },
  noteText: {
    flex: 1,
  },
  noteMetadata: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    flexShrink: 0,
  },
  // Notes //

  // Signature Lines //
  signatureLinesContainer: {
    flexDirection: 'column',
    paddingHorizontal: sideMarginPadding,
  },
  signatureHeader: {
    fontSize: DocumentFontSizes.Medium,
    fontWeight: 'bold',
    color: primaryDarkTextColor,
  },
  signatureDetailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 35,
  },
  signatureDetails: {
    flex: 1,
    fontSize: DocumentFontSizes.XSmall,
    borderTopWidth: 1,
    borderTopColor: primaryLightTextColor,
  },
  // Signature Lines //
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: 'Montserrat',
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 20,
  },
  pageHeaderRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottomWidth: 1,
    borderBottomColor: oliveExtraExtraLight,
    paddingBottom: 10,
    backgroundColor: oliveLight,
    minHeight: 192,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },

  ///////////////
  headerTopRow: {
    flexDirection: 'row',
    paddingTop: 20,
  },
  businessNameColumn: {
    width: '50%',
    paddingLeft: 20,
    justifyContent: 'flex-end',
  },
  documentTitleColumn: {
    width: '50%',
    paddingLeft: 20,
    justifyContent: 'flex-end',
    fontWeight: 'bold',
  },
  documentTitle: {
    borderBottomWidth: 1,
    borderBottomColor: oliveDark,
    color: oliveDark,
    fontSize: 10,
    marginRight: 16,
    marginBottom: 8,
  },
  businessAddressColumn: {
    borderBottomWidth: 1,
    borderBottomColor: oliveDark,
    width: '20%',
    // TODO: the bottom border between address and contact columns should have a gap
  },
  businessContactColumn: {
    borderBottomWidth: 1,
    borderBottomColor: oliveDark,
    width: '20%',
  },
  businessDetailsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    // display: 'inline-block',
  },
  businessLogo: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  businessNameText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: oliveDark,
  },
  businessAddressText: {
    fontSize: 10,
  },
  businessContactText: {
    fontSize: 10,
  },
  //////////////////
  headerMiddleRow: {
    flexDirection: 'row',
    paddingTop: 32,
    paddingLeft: 20,
  },
  headerSubheading: {
    width: '25%',
    borderBottomWidth: 1,
    borderBottomColor: oliveMedDark,
    fontSize: 10,
    color: oliveMedDark,
  },
  /////////////
  headerBottomRow: {
    flexDirection: 'row',
    paddingTop: 8,
    paddingLeft: 20,
  },
  headerBottomRowColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    color: 'gray',
    paddingRight: 16,
    fontSize: 10,
  },
  headerBottomRowText: {
    color: 'gray',
    fontSize: 10,
    fontWeight: 'bold',
  },
  headerBottomTextStatus: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  /////////////////
  commoditiesAndExpensesWrapper: {
    marginTop: 5,
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: 2,
    paddingTop: 10,
  },
  section: {
    minWidth: '30%',
    paddingRight: 10,
  },
  signatureLine: {
    height: 1,
    marginTop: 10,
    width: '100%',
    backgroundColor: '#ddd',
  },
  pageBreakSection: {
    minWidth: '50%',
    marginTop: 60,
    padding: 10,
  },
  table: {
    width: '100%',
    lineHeight: 1,
  },
  tableTotals: {
    marginTop: 10,
    width: '50%',
    lineHeight: 1,
  },
  tableHeader: {
    flexDirection: 'row',
  },
  tableRowHeader: {
    flexDirection: 'row',
    color: oliveDark,
    borderBottomWidth: 1,
    paddingBottom: 6,
    width: '85%',
  },
  tableRowHeaderFull: {
    flexDirection: 'row',
    color: oliveDark,
    borderBottomWidth: 1,
    paddingBottom: 6,
    width: '100%',
  },
  tableRowHeaderSubtotal: {
    flexDirection: 'row',
    justifyContent: 'center',
    color: oliveDark,
    paddingBottom: 6,
    backgroundColor: oliveMedMedDark,
    width: '15%',
  },
  tableRowHeaderSubtotalExpenses: {
    flexDirection: 'row',
    justifyContent: 'center',
    color: oliveDark,
    paddingBottom: 6,
    backgroundColor: oliveExtraLight,
    width: '15%',
    borderBottomWidth: 1,
  },
  tableRowWrapper: {
    flexDirection: 'row',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: oliveExtraExtraLight,
    width: '85%',
  },
  tableRowFull: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: oliveExtraExtraLight,
    width: '100%',
  },
  tableRowBottomTotalsFull: {
    flexDirection: 'row',
    width: '100%',
    // fontWeight: 900, // TODO: fix fontWeight to apply to the text
  },
  tableRowSubtotal: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: oliveExtraExtraLight,
    width: '15%',
  },
  tableCol: {
    minWidth: '20%',
    flexDirection: 'column',
  },
  tableColBottomTotals: {
    minWidth: '20%',
    flexDirection: 'column',
    backgroundColor: oliveMed,
  },
  tableColNumber: {
    minWidth: '20%',
    paddingRight: 30,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  tableColNumberSmall: {
    minWidth: '15%',
    paddingRight: 10,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  tableColNumberBottomTotals: {
    minWidth: '20%',
    paddingRight: 30,
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: oliveMed,
  },
  tableColNumberBottomTotalsSmall: {
    minWidth: '15%',
    paddingRight: 10,
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: oliveMed,
  },
  tableColSubtotalHeader: {
    backgroundColor: oliveMedMedDark,
  },
  tableColSubtotal: {
    minWidth: '15%',
    alignItems: 'flex-end',
    backgroundColor: oliveExtraLight,
  },

  tableColSmall: {
    minWidth: '15%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tableColXSmall: {
    minWidth: '10%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tableColLarge: {
    width: '50%',
    paddingRight: 6,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tableColXLarge: {
    minWidth: '30%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tableCell: {
    marginTop: 9,
    marginBottom: 4,
    fontSize: 10,
    // TODO: make the fontweight lighter
    paddingBottom: 2,
    paddingLeft: 4,
    paddingRight: 4,
  },
  tableCellSubtotalHeader: {
    marginTop: 9,
    marginBottom: 4,
    fontSize: 10,
    // TODO: make the fontweight lighter
    paddingBottom: 2,
    paddingLeft: 4,
    paddingRight: 4,
    color: 'white',
  },
  tableCellTotalPrice: {
    marginTop: 8,
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 900,
    color: 'black',
    paddingLeft: 4,
    paddingRight: 4,
  },
  tableCellOrderInfo: {
    marginTop: 8,
    marginBottom: 4,
    fontSize: 10,
    fontWeight: 3,
    color: '#333',
    paddingLeft: 4,
    paddingRight: 4,
  },
  // Add these styles
  tableRowWrapperExpenses: {
    flexDirection: 'row',
  },
  tableRowExpenses: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: oliveExtraExtraLight,
    width: '85%',
  },
  tableRowSubtotalExpenses: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: oliveExtraExtraLight,
    width: '15%',
  },
  tableColSubtotalHeaderExpenses: {
    backgroundColor: oliveExtraLight,
    marginTop: -14,
    paddingBottom: 28,
    width: '100%',
  },
  tableColSubtotalExpenses: {
    minWidth: '15%',
    alignItems: 'flex-end',
    backgroundColor: oliveExtraLight,
  },
  termsContainer: {
    width: 300,
    backgroundColor: oliveDark,
    color: 'white',
    padding: 4,
  },
  termsTitle: {
    width: '100%',
    borderBottomColor: oliveMedDark,
    borderBottomWidth: 1,
  },
  termsText: {
    fontSize: 10,
    // wrap the text
    width: '100%',
    textAlign: 'justify',
    marginTop: 8,
  },
  termsDetails: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
    fontSize: 10,
  },
  termsDetailsVendor: {
    borderRightWidth: 1,
    borderRightColor: 'white',
    width: '50%',
    marginRight: 16,
  },
  termsDetailsVendorNoBorder: {
    width: '50%',
    marginRight: 16,
  },
  termsDetailsDates: {
    width: '50%',
    paddingLeft: 4,
  },
  totalsContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    lineHeight: 3,
  },
  totalsTitlesContainer: {
    width: 120,
    paddingRight: 8,
    marginTop: -14,
    paddingTop: 28,
    textAlign: 'right',
  },
  totalsValuesContainer: {
    backgroundColor: oliveExtraLight,
    width: 85,
    marginTop: -14,
    paddingTop: 28,
    paddingBottom: 24, // bottom of column ought to align with bottom of terms container
    paddingRight: 6,
    textAlign: 'right',
  },
  notesContainer: {
    width: '100%',
    fontSize: 10,
    color: oliveDark,
  },
  notesTitle: {
    borderBottomColor: oliveMedDark,
    borderBottomWidth: 1,
    marginBottom: 8,
  },
  noteContainer: {
    width: '100%',
    marginBottom: 8,
  },
  smallPrint: {
    fontSize: 8,
  },
  // bottom of page should sit just above the footer
  bottomOfPageWrapper: {
    marginBottom: 30,
  },
  signaturesSubheader: {
    fontSize: 10,
    marginBottom: 8,
  },
  signatureLineAbove: {
    width: '40%',
    borderTopWidth: 1,
    borderTopColor: oliveExtraExtraLight,
    paddingTop: 4,
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    height: 15,
    marginBottom: -10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingTop: 1,
    backgroundColor: oliveLight,
  },
  footerText: {
    fontSize: 8,
    textAlign: 'center',
  },
  pageNumber: {
    position: 'absolute',
    top: 3,
    right: 20,
    fontSize: 8,
    width: 20,
  },
});

export default styles;
