export const FeatureFlags = {
  WorkOrders: 'workOrders',
  BuySellOrders: 'buy-sell-orders',
  ExpensesWithBusinessEntities: 'expenses-charged-to-business-entities',
  ProductionRuns: 'production-runs',
  VariableProductWeight: 'variable-product-weight',
  CheckPrinting: 'check-printing',
  Accounting: 'accounting',
  PresetDashboards: 'preset-dashboards',
  OrderBoard: 'order-board',
  CustomFields: 'customFields',
  BIDashboards: 'bi-dashboards',
  POSellThroughSO: 'poSellThroughSo',
  AccountRegister: 'account-register',
  QuickbooksOnline: 'quickbooks-online',
} as const;

export const DEBOUNCE_DELAY_LOW = 100;
export const DEBOUNCE_DELAY_MEDIUM = 500;
export const DEBOUNCE_DELAY_HIGH = 1000;
