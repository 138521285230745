import { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Clear, Search } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, Stack, TextField } from '@mui/material';

import { FilterItemProps } from './FilterItem';

export default function SelectFilter({ removeFilter, updateFilter, filter, column }: FilterItemProps) {
  const client = useApolloClient();
  const { getFilterSelectOptions } = column;
  const [options, setOptions] = useState<string[]>();
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<string[]>();
  const [selected, setSelected] = useState<string[]>(filter?.where?.[0]?.in ?? []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFilterSelectOptions(client).then((res) => {
      setLoading(false);
      setOptions(res);
      setFilteredOptions(res);
    });
  }, [getFilterSelectOptions]);

  const toggleSelectedOption = (value: string) => {
    setSelected((prev) => {
      if (prev.includes(value)) {
        return prev.filter((v) => v !== value);
      }
      return [...prev, value];
    });
  };

  useEffect(() => {
    setFilteredOptions(
      search ? options.filter((option) => option.toLowerCase().includes(search.toLowerCase())) : options
    );
  }, [options, search]);

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" my={3}>
          <CircularProgress size={24} />
        </Box>
      ) : !options.length ? (
        <Box p={2} textAlign="center">
          No options available
        </Box>
      ) : (
        <>
          <TextField
            size="small"
            placeholder="Search"
            variant="outlined"
            sx={{ mx: 1, mb: 0.5, mt: -0.5 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            slotProps={{
              input: {
                startAdornment: <Search className="text-grey-500 mr-2" />,
                endAdornment: search && (
                  <IconButton onClick={() => setSearch('')}>
                    <Clear />
                  </IconButton>
                ),
              },
            }}
          />
          <Stack sx={{ overflowY: 'auto', flexGrow: 1 }}>
            {filteredOptions?.map((option) => (
              <FormControlLabel
                key={option}
                sx={{ m: 0, gap: 1, '&:hover': { bgcolor: 'primary.100' } }}
                control={<Checkbox checked={selected.includes(option)} size="small" />}
                label={option}
                onChange={() => toggleSelectedOption(option)}
              />
            ))}
          </Stack>
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          p: 1,
          borderTop: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Button variant="text" color="primary" onClick={removeFilter}>
          Clear
        </Button>
        <Button
          disabled={!selected.length}
          variant="contained"
          color="primary"
          onClick={() => {
            updateFilter({ field: filter.field, where: [{ in: selected }] });
          }}
        >
          Apply
        </Button>
      </Box>
    </>
  );
}
