import { FC } from 'react';

import { KeyboardDoubleArrowLeft } from '@mui/icons-material';
import { Box, CSSObject, styled, Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import SidebarLink from 'src/components/containers/sidebar/SidebarLink';

import { useSidebar } from '../../../providers/SidebarContextProvider';
import { GLOBAL_APP_BAR_HEIGHT } from '../AppBar';

import RouteFavorites from './RouteFavorites';

export const GLOBAL_SIDE_BAR_WIDTH = 256;

const openedMixin = (theme: Theme): CSSObject => ({
  width: GLOBAL_SIDE_BAR_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: GLOBAL_SIDE_BAR_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    marginTop: GLOBAL_APP_BAR_HEIGHT,
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export type SidebarListProps = { sidebarOpen: boolean };

export type SidebarProps = {
  listItems: FC<SidebarListProps>;
};

export default function Sidebar({ listItems }: SidebarProps) {
  const { sidebarOpen, toggleOpen } = useSidebar();

  return (
    <Drawer variant="permanent" open={sidebarOpen} PaperProps={{ sx: { mt: `${GLOBAL_APP_BAR_HEIGHT}px` } }}>
      <List>{listItems({ sidebarOpen })}</List>
      <SidebarLink
        text={sidebarOpen ? 'Collapse' : 'Expand'}
        icon={() => <KeyboardDoubleArrowLeft className={sidebarOpen ? ' ' : 'rotate-180'} />}
        onClick={toggleOpen}
        className="mt-4"
      />

      {/* Spacer to give the favorites some room, but don't push them to the bottom */}
      <Box sx={{ flexGrow: 1, maxHeight: '170px' }} />
      <RouteFavorites />
    </Drawer>
  );
}
