import { enqueueSnackbar } from 'notistack';

import { useMutation } from '@redwoodjs/web';

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      id
      title
      url
      type
      createdAt
      uploadedBy {
        user {
          id
          firstName
          lastName
        }
      }
      documentTemplateId
      documentTemplate {
        name
      }
    }
  }
`;

export function useCreateDocument(silent = false) {
  return useMutation(CREATE_DOCUMENT, {
    onCompleted: () => {
      if (!silent) enqueueSnackbar('Document uploaded', { variant: 'success' });
    },
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  });
}
