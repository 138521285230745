export const EDIT_PRODUCT = gql`
  mutation EditProductOnLot($lotId: Int!, $newProductId: Int!, $note: String!) {
    editLotProduct(id: $lotId, newProductId: $newProductId, note: $note) {
      id
    }
  }
`;

export const UPDATE_LOT_STATUS = gql`
  mutation UpdateLotStatus($id: Int!, $input: UpdateLotStatusInput!) {
    updateLotStatus(id: $id, input: $input) {
      id
    }
  }
`;

export const LOCK_LOT = gql`
  mutation LockLot($id: Int!, $reasonCode: LotLockReason!, $reasonDescription: String!) {
    lockLot(id: $id, reasonCode: $reasonCode, reasonDescription: $reasonDescription) {
      id
    }
  }
`;

export const UNLOCK_LOT = gql`
  mutation UnlockLot($id: Int!, $note: String) {
    unlockLot(id: $id, note: $note) {
      id
    }
  }
`;

export const CREATE_LOT_RESERVATION_MUTATION = gql`
  mutation CreateLotReservation($input: CreateLotReservationInput!) {
    createLotReservation(input: $input) {
      id
      quantity
      message
    }
  }
`;

export const RELEASE_RESERVATION = gql`
  mutation DeleteLotReservation($id: Int!) {
    deleteLotReservation(id: $id) {
      id
    }
  }
`;

export const UPDATE_LOT_WAREHOUSE = gql`
  mutation UpdateLotWarehouse($id: Int!, $warehouseId: Int!) {
    updateLotWarehouse(id: $id, warehouseId: $warehouseId) {
      id
    }
  }
`;

export const UPDATE_LOT = gql`
  mutation UpdateLot($id: Int!, $input: UpdateLotInput!) {
    updateLot(id: $id, input: $input) {
      id
    }
  }
`;

export const UpdateQuantityAvailable = gql`
  mutation UpdateLotQuantityAvailable($id: Int!, $input: UpdateLotQuantityInput!) {
    updateLotQuantityAvailable(id: $id, input: $input) {
      id
      quantity
    }
  }
`;

export const UpdateWeight = gql`
  mutation UpdateLotWeight($id: Int!, $initialWeight: Float!) {
    updateLotWeight(id: $id, initialWeight: $initialWeight) {
      id
    }
  }
`;

export const SyncInitialLotValues = gql`
  mutation SyncInitialLotValues($id: Int!, $input: SyncInitialLotValuesInput!) {
    syncInitialLotValues(id: $id, input: $input) {
      id
    }
  }
`;
