import { alpha, styled } from '@mui/material';

import DataGrid from './DataGrid';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: alpha(theme.palette.action.active, 0.054),
  },
  outline: `1px solid ${theme.palette.divider}`,
  '& .disabled': {
    color: theme.palette.text.disabled,
  },
}));

export default StyledDataGrid;
