import { useState } from 'react';

import { Clear as ClearIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, TextField as MuiTextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import Color from 'color';
import qs from 'query-string';
import { Configure, Index, InstantSearch } from 'react-instantsearch-hooks-web';

import { SearchIndex, useAlgolia } from 'src/providers/AlgoliaProvider';

import SearchAutocomplete from '../atoms/SearchAutocomplete';

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: theme.shape.borderRadius,
    height: '36px',
    border: '1px solid',
    borderColor: 'transparent',
    // !important prevents the padding being changed by the presence of a clear icon
    padding: `${theme.spacing(1, 1, 1, 1)} !important`,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&.Mui-focused': {
      border: '1px solid',
      borderColor: Color(theme.palette.primary.contrastText).alpha(0.4).toString(),
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  '& .MuiInputBase-input': { color: theme.palette.primary.contrastText },
  '& .MuiFilledInput-input': {
    padding: theme.spacing(1, 1, 1, 0),
    '&::placeholder': {
      color: '#FAFDE9',
    },
  },
  '& .MuiInputAdornment-positionStart': {
    transform: 'translateY(-6px)',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.contrastText,
  },
}));

export function SearchBar() {
  const algolia = useAlgolia();

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexGrow: 1,
        pr: 4,
      }}
      noValidate
      autoComplete="off"
      action="/search-results"
    >
      <InstantSearch searchClient={algolia} indexName={SearchIndex.Invoices}>
        <Configure />
        <Index indexName={SearchIndex.Orders} />
        <Index indexName={SearchIndex.Products} />
        <Index indexName={SearchIndex.Lots} />
        <Index indexName={SearchIndex.Invoices} />
        <SearchBarInput />
      </InstantSearch>
    </Box>
  );
}

export const SearchBarInput = () => {
  const parsed = qs.parse(location.search) || {};
  const query = typeof parsed.query === 'string' ? parsed.query : parsed.query?.join('+');
  const [value, setValue] = useState(query || '');

  return (
    <SearchAutocomplete
      options={[]}
      id="search"
      showResultsList={false}
      components={{
        TextField,
      }}
      className="transition-all max-w-[200px] focus-within:max-w-[500px]"
      fullWidth
      textFieldProps={{
        name: 'query',
        sx: {
          paddingTop: 0,
          paddingRight: 0,
          width: '100%',
        },
        placeholder: 'Search...',
        variant: 'filled',
        InputProps: {
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {value && (
                <IconButton
                  onClick={() => {
                    setValue('');
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
          onChange: ({ target }) => setValue(target.value),
        },
      }}
      value={value}
    />
  );
};
