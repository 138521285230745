import React from 'react';

import { Prisma } from '@prisma/client';
import { Document, Page } from '@react-pdf/renderer';

import computeTotalCartons from 'src/services/lineItems/utils/computeTotalCartons';
import computeTotalPallets from 'src/services/lineItems/utils/computeTotalPallets';
import computeTotalWeight from 'src/services/lineItems/utils/computeTotalWeight';

import { BSOInvoiceHeader, Expenses, Footer, Notes, ProductsGrid, TermsAndTotals, getUnitPrice } from '../sections';
import money from '../utils/money';
import { docStyles } from '../utils/styles';

export type BSOInvoiceTemplateProps = Prisma.BuySellOrderInvoiceGetPayload<{
  include: {
    buySellOrder: {
      include: {
        organization: { include: { places: true } };
        broker: { include: { user: true; subsidiary: true } };
        vendor: true;
        vendorContact: true;
        customer: { include: { places: true } };
        customerContact: true;
        shipment: true;
        subsidiary: true;
        lineItems: {
          include: {
            lot: true;
            product: {
              include: {
                commodity: true;
                commoditySize: true;
                commodityStyle: true;
                commodityUnit: true;
              };
            };
          };
        };
        expenses: {
          include: {
            businessEntity: true;
          };
          where: { deletedAt: null };
        };
        notes: {
          include: {
            membership: {
              include: {
                user: true;
              };
            };
          };
        };
      };
    };
  };
}>;

export function BSOInvoiceTemplate(invoice: BSOInvoiceTemplateProps) {
  const { buySellOrder } = invoice;

  const allLineItems = invoice.buySellOrder.lineItems;
  const arExpenses = invoice.expenses
    .filter((expense) => expense.accountLevel === 'ACCOUNTS_RECEIVABLE')
    .filter(
      (expense) =>
        expense.businessEntity === null ||
        (expense.businessEntity && expense.businessEntity.id === buySellOrder.customer.id)
    )
    .filter((expense) => !expense.includedInDlvPrice && !expense.parentExpenseId);

  // explicitly using `buySellOrder.expenses` here to ensure that AP expenses with `includedInDlvPrice: true` is available
  const deliveredExpenses = invoice.buySellOrder.expenses.filter((expense) => expense.includedInDlvPrice);

  const totals = {
    pallets: computeTotalPallets(allLineItems).invoiced,
    cartons: computeTotalCartons(allLineItems).invoiced,
    weight: computeTotalWeight(allLineItems).invoiced,
  };

  const lineItemTotal = allLineItems.reduce((total, lineItem) => {
    return total + getUnitPrice(lineItem, totals, deliveredExpenses) * lineItem.unitsInvoiced;
  }, 0);

  const expenseTotal = arExpenses.reduce((total, expense) => {
    return total + expense.unitAmount * expense.quantity;
  }, 0);

  const grandTotal = expenseTotal + lineItemTotal;

  const totalAmountDue = invoice.totalAmountDue || grandTotal;

  const externalNotes = invoice.buySellOrder.notes.filter((n) => n.external && n.documentTypes.includes('INVOICE'));

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <BSOInvoiceHeader invoice={invoice} />

        <ProductsGrid
          productLineItems={allLineItems}
          showUnitPrice={true}
          showUnitsOrdered={true}
          showUnitsInvoiced={true}
          showSubTotal={true}
          isBuySellOrderInvoice={true}
          deliveredExpenses={deliveredExpenses}
          totals={totals}
          productTemplate={buySellOrder.organization.productDescriptionTemplate}
        />

        <Expenses expenses={arExpenses} />

        <TermsAndTotals grandTotal={money.toHuman(totalAmountDue)} />

        <Notes notes={externalNotes} />

        <Footer />
      </Page>
    </Document>
  );
}
