import { useMemo } from 'react';

import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import parseHtml from 'html-react-parser';
import qs from 'query-string';

import { navigate, routes } from '@redwoodjs/router';

import BoldHeader from 'src/components/atoms/grid/BoldHeader';
import DataGrid, { GridColObj } from 'src/components/containers/grids/DataGrid';
import { MainCard } from 'src/components/containers/MainCard';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import { SearchIndex } from 'src/providers/AlgoliaProvider';
import MultiIndexSearch, { Hits, MultiHit } from 'src/providers/MultiIndexProvider';

const orderTypes = {
  SalesOrder: {
    name: 'Sales Order',
    shortName: 'SO',
    route: (slug) => routes.salesOrder({ slug }),
  },
  PurchaseOrder: {
    name: 'Purchase Order',
    shortName: 'PO',
    route: (slug) => routes.purchaseOrder({ slug }),
  },
  BuySellOrder: {
    name: 'Buy Sell Order',
    shortName: 'BSO',
    route: (slug) => routes.buySellOrder({ slug }),
  },
  WorkOrder: {
    name: 'Grower Product',
    shortName: 'GP',
    route: (slug) => routes.growerProduct({ slug }),
  },
};

// TODO: Use i18n instead of dict
const indexes = {
  [SearchIndex.Invoices]: {
    dict: {
      order: 'Invoice #',
    },
    goToRoute: ({ slug, __type }) => {
      const params = { slug };

      if (__type === 'StandardInvoice') {
        params['type'] = 'standard';
      } else if (__type === 'BuySellOrderInvoice') {
        params['type'] = 'bso';
      }

      return navigate(routes.invoice(params));
    },
    mapHit: (hit) => ({
      type: 'invoices',
      goToText: `Invoice #${hit.slug}`,
      slug: hit.slug,
      id: hit.objectID,
      foundIn: 'Invoices',
      resultType: 'Invoice',
      __type: hit.__type,
    }),
  },
  [SearchIndex.Orders]: {
    goToRoute: ({ slug, __type }) => navigate(orderTypes[__type].route(slug)),
    dict: {
      status: 'Status',
      slug: ({ __type }) => orderTypes[__type].name,
      slugs: ({ __type }) => orderTypes[__type].name,
      customerPO: 'Customer PO #',
      customerPOs: 'Customer PO #',
      buyer: 'Buyer',
      contact: 'Contact',
      shipTo: 'Address',
      salesPerson: 'Sales Person',
      customer: 'Customer',
      vendorSO: 'Vendor SO #',
      vendorSOs: 'Vendor SO #',
      vendor: 'Vendor',
      shippedFrom: 'Address',
    },
    mapHit: (hit) => ({
      type: 'orders',
      __type: hit.__type,
      slug: hit.slug,
      resultType: orderTypes[hit.__type].name,
      foundIn: `${orderTypes[hit.__type].name}s`,
      goToText: `${orderTypes[hit.__type].shortName} #${hit.slug}`,
      id: hit.objectID,
    }),
  },

  [SearchIndex.Lots]: {
    goToText: 'Go to Lot',
    goToRoute: ({ slug, warehouseId }) => navigate(routes.viewLotTracking({ slug, warehouseId })),
    dict: {
      slug: 'Lot #',
      description: 'Product Description',
      orders: 'Associated Order',
    },
    mapHit: (hit) => ({
      type: 'lots',
      resultType: 'Lot',
      slug: hit.slug,
      warehouseId: hit.warehouseId,
      foundIn: `Lot #${hit.slug}`,
      goToText: `Lot #${hit.slug}`,
      id: hit.objectID,
    }),
  },
  [SearchIndex.Products]: {
    goToText: 'Go to Product',
    goToRoute: () => navigate(routes.inventory()),
    dict: {
      description: 'Product Description',
    },
    mapHit: (hit) => ({
      type: 'products',
      resultType: 'Product',
      goToText: 'Inventory',
      foundIn: 'Products',
      id: hit.objectID,
    }),
  },
};

const columns: GridColObj<{
  resultType: string;
  type: string;
  id: number;
  goToText: string;
  match: string;
}> = {
  match: {
    headerName: 'Search Result',
    minWidth: 100,
    flex: 2,
    type: 'string',
    renderHeader: BoldHeader,
    renderCell: ({ row }) => {
      return (
        <Typography
          fontSize="14px"
          sx={{
            '& mark': {
              backgroundColor: 'transparent',
              fontWeight: 'bold',
            },
          }}
        >
          {parseHtml(row.match)}
        </Typography>
      );
    },
  },
  resultType: {
    headerName: 'Result Type',
    minWidth: 300,
    flex: 2,
    type: 'string',
    renderHeader: BoldHeader,
  },
  foundIn: {
    headerName: 'Found In',
    minWidth: 200,
    type: 'string',
    flex: 1,
    renderHeader: BoldHeader,
  },
  action: {
    headerName: 'Action',
    width: 300,
    type: 'string',
    renderHeader: BoldHeader,
    renderCell: ({ row }) => {
      const index = indexes[row.type];

      return (
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            index.goToRoute(row);
          }}
          sx={{ width: '100%' }}
        >
          Go To {row.goToText}
          <ArrowForwardIcon sx={{ ml: 1, fontSize: '16px' }} />
        </Button>
      );
    },
  },
};

const hasMatch = ([_, result]) => result.matchLevel !== 'none';
const mapHits = ([indexName, hits]: [string, Hits[]]) => {
  const index = indexes[indexName];

  return hits.map((hit) => {
    const highlightRes = Object.entries(hit._highlightResult).find(hasMatch);

    if (!highlightRes) return null;

    const [field, matchRes] = highlightRes || [undefined, undefined];
    let value = undefined;

    if (Object.hasOwn(matchRes, 'value')) {
      value = matchRes.value;
    } else {
      const match = Object.entries(matchRes).find(hasMatch);

      value = match ? match[1].value : undefined;
    }

    if (!value) return null;

    const fieldValue = index.dict[field];
    const resultType = typeof fieldValue === 'function' ? fieldValue(hit) : fieldValue;

    return {
      ...index.mapHit(hit),
      resultType,
      match: value,
    };
  });
};

const SearchResults = ({ hits }: { hits: MultiHit }) => {
  const rows = useMemo(
    () =>
      hits &&
      Object.entries(hits)
        .map(mapHits)
        .flat()
        .filter((x) => x !== null),
    [hits]
  );

  return (
    <MainCard>
      <DataGrid key="test" rows={rows} columns={columns} hideFooter storageKey="search" />
    </MainCard>
  );
};

const SearchResultsPage = () => {
  const { query } = qs.parse(location.search) || {};

  return (
    <>
      <PageHeader pageType={PageType.SearchResults} entitySlug={query} delimiter=": "></PageHeader>
      <PageBody>
        <MultiIndexSearch
          query={query as string}
          render={(hits) => <SearchResults hits={hits} />}
          indexes={[
            { index: SearchIndex.Orders },
            { index: SearchIndex.Invoices },
            { index: SearchIndex.Products },
            { index: SearchIndex.Lots },
          ]}
        />
      </PageBody>
    </>
  );
};

export default SearchResultsPage;
