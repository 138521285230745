import { useState } from 'react';

import { useOrganization } from '@clerk/clerk-react';
import GjsEditor, { AssetsProvider, Canvas, ModalProvider } from '@grapesjs/react';
import { Box, Dialog } from '@mui/material';
import type { EditorConfig } from 'grapesjs';
import grapesjs, { usePlugin } from 'grapesjs';
import { DocumentTemplateEntityType, DocumentType } from 'types/graphql';

import { MAIN_BORDER_COLOR } from '../../components/common';
import CustomAssetManager from '../../components/CustomAssetManager';
import CustomModal from '../../components/CustomModal';
import DocumentEditorPreview from '../../components/DocumentEditorPreview';
import DocumentStorageLoadModal from '../../components/DocumentStorageLoadModal';
import DocumentStorageSaveModal from '../../components/DocumentStorageSaveModal';
import RightSidebar from '../../components/RightSidebar';
import Topbar from '../../components/Topbar';
import { PAGE_HEIGHT, PAGE_WIDTH } from '../../documentSizes';
import useDocumentStorage from '../../hooks/useDocumentStorage';
import { default as layoutPlugin } from '../../plugins/layout';
import { default as stylePlugin } from '../../plugins/styles';
import { default as tablePlugin } from '../../plugins/table';

const gjsOptions: EditorConfig = {
  height: '100vh',
  storageManager: {
    type: 'hybrid',
    autosave: true,
    autoload: true,
  },
};

export type DocumentOptions = {
  type: DocumentType;
  entity: DocumentTemplateEntityType;
  label?: string;
};

export const documentTypeOptions: DocumentOptions[] = [
  {
    entity: 'SALES_ORDER',
    type: 'SALES_ORDER_CONFIRMATION',
    label: 'Sales Order Confirmation',
  },
  {
    entity: 'SALES_ORDER',
    type: 'CUSTOM',
    label: 'Custom Template',
  },
  {
    entity: 'SALES_ORDER',
    type: 'CARRIER_CONFIRMATION',
    label: 'Carrier Confirmation',
  },
  {
    entity: 'SALES_ORDER',
    type: 'PICK_TICKET',
    label: 'Pick Ticket',
  },
  {
    entity: 'SALES_ORDER',
    type: 'BILL_OF_LADING',
    label: 'Bill of Lading',
  },
  {
    entity: 'SALES_ORDER',
    type: 'SHIPMENT_CONFIRMATION',
    label: 'Shipment Confirmation',
  },
  {
    entity: 'PURCHASE_ORDER',
    type: 'PURCHASE_ORDER_CONFIRMATION',
    label: 'Purchase Order Confirmation',
  },
  {
    entity: 'PURCHASE_ORDER',
    type: 'CUSTOM',
    label: 'Custom Template',
  },
  {
    entity: 'PURCHASE_ORDER',
    type: 'CARRIER_CONFIRMATION',
    label: 'Carrier Confirmation',
  },
  {
    entity: 'PURCHASE_ORDER',
    type: 'RECEIVING_TICKET',
    label: 'Receiving Ticket',
  },
  {
    entity: 'BUY_SELL_ORDER',
    type: 'CUSTOM',
    label: 'Custom Template',
  },
  {
    entity: 'BUY_SELL_ORDER',
    type: 'BILL_OF_LADING',
    label: 'Bill of Lading',
  },
  {
    entity: 'BUY_SELL_ORDER',
    type: 'CARRIER_CONFIRMATION',
    label: 'Carrier Confirmation',
  },
  {
    entity: 'BUY_SELL_ORDER',
    type: 'SALES_ORDER_CONFIRMATION',
    label: 'Sales Order Confirmation',
  },
  {
    entity: 'BUY_SELL_ORDER',
    type: 'PURCHASE_ORDER_CONFIRMATION',
    label: 'Purchase Order Confirmation',
  },
  {
    entity: 'BUY_SELL_ORDER',
    type: 'PASSING',
    label: 'Passing',
  },
  {
    entity: 'INVOICE',
    type: 'INVOICE',
    label: 'Invoice',
  },
  {
    entity: 'PAYABLE',
    type: 'PAYABLE',
    label: 'Payable',
  },
  {
    entity: 'REMITTANCE',
    type: 'REMITTANCE',
    label: 'Remittance',
  },
  {
    entity: 'BUY_SELL_ORDER_INVOICE',
    type: 'INVOICE',
    label: 'Invoice',
  },
  {
    entity: 'STANDARD_INVOICE',
    type: 'INVOICE',
    label: 'Standalone Invoice',
  },
  {
    entity: 'CUSTOMER_STATEMENT',
    type: 'OTHER',
    label: 'Customer Statement',
  },
];

const documentVariables: Record<DocumentTemplateEntityType, { variable: string; type: 'number' | 'string' }[]> = {
  SALES_ORDER: [{ variable: 'slug', type: 'string' }],
  PURCHASE_ORDER: [{ variable: 'slug', type: 'string' }],
  BUY_SELL_ORDER: [{ variable: 'slug', type: 'string' }],
  GROWER_PRODUCT: [{ variable: 'slug', type: 'string' }],
  PRODUCTION_RUN: [{ variable: 'slug', type: 'string' }],
  INVOICE: [{ variable: 'slug', type: 'string' }],
  PAYABLE: [{ variable: 'slug', type: 'string' }],
  REMITTANCE: [
    { variable: 'slug', type: 'string' },
    { variable: 'test', type: 'string' },
  ],
  BUY_SELL_ORDER_INVOICE: [{ variable: 'slug', type: 'string' }],
  STANDARD_INVOICE: [{ variable: 'slug', type: 'string' }],
  CUSTOMER_STATEMENT: [{ variable: 'businessEntityId', type: 'number' }],
};

export const documentTypeGroups: Record<DocumentTemplateEntityType, string> = {
  SALES_ORDER: 'Sales Order',
  PURCHASE_ORDER: 'Purchase Order',
  BUY_SELL_ORDER: 'Buy-Sell Order',
  GROWER_PRODUCT: 'Grower Product',
  PRODUCTION_RUN: 'Production Run',
  INVOICE: 'Invoice',
  PAYABLE: 'Payable',
  REMITTANCE: 'Remittance',
  BUY_SELL_ORDER_INVOICE: 'Buy-Sell Order Invoice',
  STANDARD_INVOICE: 'Standalone Invoice',
  CUSTOMER_STATEMENT: 'Customer Statement',
};

const DocumentEditorPage = ({ new: isNew, name, type, entity }) => {
  const { organization } = useOrganization();
  const [modalOpen, setModalOpen] = useState(null);

  const initialDocumentType = documentTypeOptions.find((option) => option.entity === entity && option.type === type);

  const [documentType, setDocumentType] = useState<DocumentOptions | null>(initialDocumentType);
  const [documentName, setDocumentName] = useState(name);
  const [generationOptions, setGenerationOptions] = useState(null);
  const [queryVariables, setQueryVariables] = useState<Record<string, unknown>>({});
  const [storageSaveModalOpen, setStorageSaveModalOpen] = useState(false);
  const [storageLoadModalOpen, setStorageLoadModalOpen] = useState(false);
  const [templateId, setTemplateId] = useState<number | null>(null);

  const { plugin: storagePlugin } = useDocumentStorage({
    isNew,
    documentConfig: {
      name: documentName,
      type: documentType?.type,
      entity: documentType?.entity,
    },
    setStorageSaveModalOpen,
    setStorageLoadModalOpen,
    setTemplateId,
  });

  console.log(queryVariables);

  return (
    <>
      <Dialog
        open={!!modalOpen}
        onClose={() => {
          setModalOpen(null);
        }}
      >
        <DocumentEditorPreview
          open={!!modalOpen}
          css={modalOpen?.css}
          html={modalOpen?.html}
          entity={documentType?.entity}
          type={documentType?.type}
          queryVariables={queryVariables}
          templateId={templateId}
          generationOptions={generationOptions}
        />
      </Dialog>
      <GjsEditor
        grapesjs={grapesjs}
        grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
        options={gjsOptions}
        plugins={[
          usePlugin(storagePlugin),
          usePlugin(layoutPlugin(setModalOpen, organization.imageUrl)),
          usePlugin(tablePlugin),
          usePlugin(stylePlugin),
        ]}
      >
        <DocumentStorageSaveModal
          open={storageSaveModalOpen}
          onClose={() => setStorageSaveModalOpen(false)}
          documentName={documentName}
          setDocumentName={setDocumentName}
          documentType={documentType}
          setDocumentType={setDocumentType}
          generationOptions={generationOptions}
          setGenerationOptions={setGenerationOptions}
        />
        <DocumentStorageLoadModal
          open={storageLoadModalOpen}
          onClose={() => setStorageLoadModalOpen(false)}
          setDocumentName={setDocumentName}
          setDocumentType={setDocumentType}
          setGenerationOptions={setGenerationOptions}
        />
        <div className={`flex h-full border-t ${MAIN_BORDER_COLOR}`}>
          <div className="gjs-column-m flex flex-col flex-grow">
            <Box>
              <Topbar
                className="min-h-[48px]"
                setQueryVariables={setQueryVariables}
                queryVariables={queryVariables}
                documentVariables={documentVariables[documentType?.entity]}
              />
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
              <Box width={`${PAGE_WIDTH}px`} maxHeight={`${PAGE_HEIGHT}px`} overflow="hidden" mt={4}>
                <Canvas />
              </Box>
            </Box>
          </div>
          <RightSidebar className={`gjs-column-r w-[300px] border-l ${MAIN_BORDER_COLOR}`} />
        </div>
        <ModalProvider>
          {({ open, title, content, close }) => (
            <CustomModal open={open} title={title} close={close}>
              {content}
            </CustomModal>
          )}
        </ModalProvider>
        <AssetsProvider>
          {({ assets, select, close, Container }) => (
            <Container>
              <CustomAssetManager assets={assets} select={select} close={close} />
            </Container>
          )}
        </AssetsProvider>
      </GjsEditor>
    </>
  );
};

export default DocumentEditorPage;
