import { Paper } from '@mui/material';

type Props = {
  anchorEl: HTMLElement;
  children: React.ReactNode;
};

export default function SimpleMenu({ anchorEl, children }: Props) {
  const box = anchorEl.getBoundingClientRect();

  return (
    <Paper
      component="ul"
      sx={{
        position: 'fixed',
        zIndex: 1000,
        left: box.left + box.width,
        top: box.top,
      }}
    >
      {children}
    </Paper>
  );
}
