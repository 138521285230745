import { PropsWithChildren, createContext, useContext, useRef } from 'react';

import { StateCreator, StoreApi, createStore, useStore } from 'zustand';

type GridKeyExpansionObj = Partial<Record<'string' | 'number', boolean>>;
interface GridCoreState {
  hoveredRowId?: string;
  setHoveredRowId: (gridId: string) => void;
  expandedGroups: GridKeyExpansionObj;
  setExpandedGroups: (groups: GridKeyExpansionObj) => void;
  setGroupExpanded: (group: 'string' | 'number', value: boolean) => void;
}

const initializeGridCoreStore: StateCreator<GridCoreState> = (set) => ({
  hoveredRowId: undefined,
  setHoveredRowId: (gridId) => set({ hoveredRowId: gridId }),
  expandedGroups: {},
  setExpandedGroups: (groups) => set({ expandedGroups: groups }),
  setGroupExpanded: (group, shouldExpand) =>
    set((state) => {
      return {
        expandedGroups: { ...state.expandedGroups, [group]: shouldExpand },
      };
    }),
});

const GridCoreContext = createContext<StoreApi<GridCoreState> | null>(null);

export default function GridCoreProvider({ children }: PropsWithChildren) {
  const storeRef = useRef(createStore(initializeGridCoreStore));

  return <GridCoreContext.Provider value={storeRef.current}>{children}</GridCoreContext.Provider>;
}

export function useGridCore<T>(selector: (state: GridCoreState) => T): T {
  const store = useContext(GridCoreContext);

  if (!store) {
    throw new Error('Missing GridCoreProvider');
  }

  return useStore(store, selector);
}
