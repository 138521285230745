import EventEmitter from 'events';

import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import { GridRenderEditCellParams, GridRowId, useGridApiContext } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

import AutoCompleteCell from './AutoCompleteCell';

type FreeSoloCellProps<T> = {
  handleCreate: (newValue: string, resolve: (newValue: T) => void) => void;
  getOptionLabel: (option: T) => string;
  setFreeSoloOptions?: (options: T[]) => void;
} & Omit<AutocompleteProps<T, false, false, true>, 'renderInput' | 'getOptionLabel'>;

export type AddOption = {
  optionLabel: string;
  value: string;
};

const filter = createFilterOptions();

const freeSoloEmitter = new EventEmitter();
const PROCESSING_EVENT = 'processingUpdate';

/**
 * Pass to the datagrid `shouldPreventRowProcess` to ensure that the free solo cell
 * is not updated while it's asynchronously saving the new value
 */
export const useFreeSoloProcessing = (apiRef: MutableRefObject<GridApiPremium>) => {
  const [cellsProcessing, setCellsProcessing] = useState([]);
  const [rowsWaiting, setRowsWaiting] = useState([]);

  /**
   * Add listeners that enable us to track which cells are processing
   *
   * There are no cell edit mode events available in the datagrid api,
   * so we have to handle them manually
   */
  useEffect(() => {
    const handleProcessingUpdate = ({
      processing,
      cell,
    }: {
      processing: boolean;
      cell: {
        rowId: GridRowId;
        field: string;
      };
    }) => {
      if (processing) {
        setCellsProcessing((cellsProcessing) => [...cellsProcessing, cell]);
      } else {
        setCellsProcessing((cellsProcessing) =>
          cellsProcessing.filter(({ rowId, field }) => rowId !== cell.rowId && field !== cell.field)
        );
      }
    };

    freeSoloEmitter.on(PROCESSING_EVENT, handleProcessingUpdate);

    return () => {
      freeSoloEmitter.off(PROCESSING_EVENT, handleProcessingUpdate);
    };
  }, []);

  /**
   * Callback to pass into datagrid, to determine whether the row should not
   * update while a cell is waiting it's value  to be updated
   */
  const shouldPreventRowProcess = useCallback(
    (rowId) => {
      const cellsStillProcessing = cellsProcessing.find(({ rowId: processingRowId }) => rowId === processingRowId);

      if (cellsStillProcessing) {
        setRowsWaiting((rowsWaiting) => [...rowsWaiting, rowId]);

        return true;
      }

      return false;
    },
    [cellsProcessing]
  );

  /**
   * Handle resubmitting rows that have been prevented from updating
   * due to a cell still processing
   */
  useEffect(() => {
    if (rowsWaiting.length === 0) {
      return;
    }

    const completedRow = rowsWaiting.find((rowId) => {
      const cellsStillProcessing = cellsProcessing.find(({ rowId: processingRowId }) => rowId === processingRowId);

      if (cellsStillProcessing) {
        return false;
      }

      setRowsWaiting((rowsWaiting) => rowsWaiting.filter((rowWaiting) => rowWaiting !== rowId));

      return true;
    });

    if (completedRow) {
      apiRef.current.stopRowEditMode({
        id: completedRow,
      });
    }
  }, [cellsProcessing, rowsWaiting, apiRef]);

  return { shouldPreventRowProcess };
};

export default function FreeSoloCell<T = unknown>({
  cell,
  handleCreate,
  getOptionLabel,
  options,
  setFreeSoloOptions,
  ...props
}: FreeSoloCellProps<T> & { cell: GridRenderEditCellParams }) {
  const apiRef = useGridApiContext();
  return (
    <AutoCompleteCell<T, false, false, true>
      hasFocus={cell.hasFocus}
      value={cell.value}
      loading={cell.value?._processing}
      onChange={(e, option) => {
        const isFreeSoloOption = option && typeof option === 'object' && Object.hasOwn(option, 'value');

        console.log(option);

        if (isFreeSoloOption) {
          const value = (option as unknown as AddOption)?.value ?? '';

          apiRef.current.setEditCellValue({
            ...cell,
            value: {
              value,
              _processing: true,
            },
          });

          freeSoloEmitter.emit(PROCESSING_EVENT, {
            processing: true,
            cell: {
              rowId: cell.id,
              field: cell.field,
            },
          });

          handleCreate(value, (newValue) => {
            setFreeSoloOptions?.([...options, newValue]);

            apiRef.current.setEditCellValue({
              ...cell,
              value: newValue,
            });

            freeSoloEmitter.emit(PROCESSING_EVENT, {
              processing: false,
              cell: {
                rowId: cell.id,
                field: cell.field,
              },
            });
          });

          e.stopPropagation();
          return;
        }

        apiRef.current.setEditCellValue({ ...cell, value: option ?? null });
        e.stopPropagation();
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        const isExisting = options.some((option) => inputValue === getOptionLabel(option));

        if (inputValue !== '' && !isExisting) {
          filtered.push({
            value: inputValue,
            freeSolo: true,
          });
        }

        return filtered as T[];
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }

        if (typeof option === 'object' && Object.hasOwn(option, 'value')) {
          return (option as unknown as AddOption).value;
        }

        return getOptionLabel(option);
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      fullWidth
      options={options}
      freeSolo
      {...props}
    />
  );
}
