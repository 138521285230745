import { createContext, PropsWithChildren, useContext } from 'react';

export const GridAddonsContext = createContext<{
  handleAddLineItem?: (args?: unknown) => void;
  showButton?: boolean;
  gridKey: string;
}>(undefined);

export default function GridAddonsContextProvider({
  children,
  handleAddLineItem,
  showButton,
  gridKey,
}: PropsWithChildren<{
  handleAddLineItem?: (args?: unknown) => void;
  showButton?: boolean;
  gridKey: string;
}>) {
  return (
    <GridAddonsContext.Provider value={{ handleAddLineItem, showButton, gridKey }}>
      {children}
    </GridAddonsContext.Provider>
  );
}

export const useGridAddons = () => useContext(GridAddonsContext);
