import { Product } from '../../core';
import { Weight } from '../../core/product';
import { LineItem as LineItemType, ListTemplate } from '../../types';
import Money from '../../utils/Money';
import { TemplateComponent } from '../DocumentGenerator';
import { applyTableMetadata, formatCurrency, formatDecimal } from '../templates/formatters';

export type ProductsGridOptions = {
  getUnitPrice: (lineItem: LineItemType) => number;
  getUnitsForTotal: (lineItem: LineItemType) => number;
  currency?: string;
};

export type ProductTemplateDataType = ListTemplate<
  Partial<{
    description: string;
    commodity: string;
    variety: string;
    size: string;
    unit: string;
    label: string;
    productCode: string;
    countyOfOrigin: string;
    grade: string;
    organic: string;
    units: string;
    weight: string;
    unitPrice: string;
    total: string;
    weightReceived?: string;
    lot?: string;
  }>
>;

export const ProductsComponent: TemplateComponent<LineItemType[], ProductTemplateDataType, ProductsGridOptions> = {
  templatize: (lineItems: LineItemType[], { getUnitsForTotal, getUnitPrice, currency }): ProductTemplateDataType => {
    return applyTableMetadata(
      lineItems.map((lineItem) => {
        const unitCount = getUnitsForTotal(lineItem);
        const unitPrice = getUnitPrice(lineItem);

        const { product } = lineItem;

        const weight = Weight.of(product)
          .withLot(lineItem.lot)
          .withLineItemPalletTags(lineItem.palletTagsOnLineItem)
          .forQuantity(unitCount);

        return {
          description: Product.productDescription(product),
          commodity: product.commodity.name,
          variety: product.commodityStyle.name,
          size: product.commoditySize.name,
          unit: product.commodityUnit.name,
          label: product.commodityLabel?.name,
          productCode: product.productCode,
          countyOfOrigin: product.countryOfOrigin || '',
          grade: product.grade,
          organic: product.organic ? 'Organic' : '',
          units: formatDecimal(unitCount),
          weight: formatDecimal(weight),
          unitPrice: formatCurrency(Money.toDollars(unitPrice), currency),
          total: formatCurrency(Money.toDollars(unitPrice) * unitCount, currency),
          lot: lineItem.lot?.slug,
        };
      })
    );
  },
  definitionMap: {
    description: { label: 'Description', type: 'string' },
    commodity: { label: 'Commodity', type: 'string' },
    variety: { label: 'Variety', type: 'string' },
    size: { label: 'Size', type: 'string' },
    unit: { label: 'Unit', type: 'string' },
    label: { label: 'Label', type: 'string' },
    productCode: { label: 'Product Code', type: 'string' },
    countyOfOrigin: { label: 'Country of Origin', type: 'string' },
    grade: { label: 'Grade', type: 'string' },
    organic: { label: 'Organic', type: 'string' },
    units: { label: 'Units', type: 'string' },
    weight: { label: 'Weight', type: 'string' },
    unitPrice: { label: 'Unit Price', type: 'string' },
    total: { label: 'Total', type: 'string' },
    weightReceived: { label: 'Weight Received', type: 'string' },
    lot: { label: 'Lot', type: 'string' },
  },
};
