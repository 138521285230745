import '@react-pdf-viewer/core/lib/styles/index.css'; // Core styles

import { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { Viewer } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import { PageSelect } from './PageSelect';

type DocumentViewerProps = {
  pdf?: { url: string; blob: Blob };
  width: string;
  height: string;
  Controls: React.FC<{ currentScale: number; handleZoomChange: (scale: number) => void }>;
  controlsStyling?: Record<string, unknown>;
  borderRadius?: string;
};

export default function DocumentViewer({
  pdf,
  width,
  height,
  Controls,
  controlsStyling,
  borderRadius,
}: DocumentViewerProps) {
  const zoomPluginInstance = useRef(zoomPlugin());
  const pagePluginInstance = useRef(pageNavigationPlugin());
  const docContainerRef = useRef(null);
  const [currentScale, setCurrentScale] = useState(1);
  const [currentPdf, setCurrentPdf] = useState(pdf);

  useEffect(() => {
    if (!pdf?.url) return;
    setCurrentPdf(pdf);
  }, [pdf]);

  const handleZoomChange = (scale: number) => {
    zoomPluginInstance.current.zoomTo(scale);
    setCurrentScale(scale);
  };

  const plugins = useMemo(() => [zoomPluginInstance.current, pagePluginInstance.current], []);

  if (!currentPdf?.url) {
    return <Box sx={{ backgroundColor: '#E7E7E5' }} width={width} height={height} />;
  }

  return (
    <>
      <Box
        sx={{
          border: '1px solid #E7E7E5',
          borderRadius,
        }}
      >
        <Box
          ref={docContainerRef}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#E7E7E5',
            '.rpv-core__inner-page': {
              backgroundColor: '#E7E7E5',
              boxShadow: 'none',
              '--rpv-core__page-layer-box-shadow': 'none',
            },
            '.rpv-core__doc-loading': {
              display: 'none',
            },
          }}
          width={width}
          height={height}
        >
          <Viewer
            onDocumentLoad={({ doc }) => {
              doc.getPage(1).then((page) => {
                const { height } = page.getViewport({ scale: 1 });
                const containerHeight = docContainerRef.current?.getBoundingClientRect().height;

                const fitContent = containerHeight / height;

                setTimeout(() => {
                  zoomPluginInstance.current.zoomTo(fitContent);
                  setCurrentScale(fitContent);
                });
              });
            }}
            fileUrl={currentPdf.url}
            plugins={plugins}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(controlsStyling ?? {}),
        }}
      >
        <PageSelect pageNavigationPlugin={pagePluginInstance.current} />
        <Controls currentScale={currentScale} handleZoomChange={handleZoomChange} />
      </Box>
    </>
  );
}
