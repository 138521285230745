import { useEffect, useRef, useState } from 'react';

import { Box, BoxProps, useMediaQuery } from '@mui/material';

import theme from 'src/lib/theme';
import { useSidebar } from 'src/providers/SidebarContextProvider';

const getColumns = (sm: boolean, between: boolean) => {
  return sm ? 1 : between ? 2 : 3;
};

export default function FormGrid({ children, sx }: BoxProps) {
  const { sidebarOpen } = useSidebar();
  const smWidth = 750;
  const mdWidth = 1100;
  // since we're using media query, we need to offset the width to prevent jumpiness
  const padding = 161 + (sidebarOpen ? 175 : 0);
  const smPadded = smWidth + padding;
  const mdPadded = mdWidth + padding;
  const sm = useMediaQuery(theme.breakpoints.down(smPadded));
  const between = useMediaQuery(theme.breakpoints.between(smPadded, mdPadded));
  const [columns, setColumns] = useState(getColumns(sm, between));
  const boxRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([box]) => {
      const { width } = box.contentRect;
      const sm = width < smWidth;
      const between = !sm && width < mdWidth;
      const newColumns = getColumns(sm, between);

      if (newColumns !== columns) {
        setColumns(getColumns(sm, between));
      }
    });

    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [columns]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gap: 2,
        rowGap: 1,
        mb: 1,
        flexWrap: 'wrap',
        ...sx,
      }}
      ref={boxRef}
    >
      {children}
    </Box>
  );
}
