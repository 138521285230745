import { ReactNode, useEffect } from 'react';

import { useOrganization, useOrganizationList } from '@clerk/clerk-react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/system';
import { SnackbarProvider } from 'notistack';

import { navigate, routes, useLocation } from '@redwoodjs/router';

import MainAppBar, { GLOBAL_APP_BAR_HEIGHT } from 'src/components/containers/AppBar';
import Sidebar, { SidebarProps } from 'src/components/containers/sidebar/Sidebar';
import { SideDrawer } from 'src/components/containers/SideDrawer';
import GridFocusProvider from 'src/providers/GridFocusProvider';
import SidebarContextProvider from 'src/providers/SidebarContextProvider';

export type ContentWrapperProps = {
  children: ReactNode;
} & SidebarProps;

export default function ContentLayout({ children, listItems }: ContentWrapperProps) {
  const { setActive, organizationList } = useOrganizationList();
  const { organization } = useOrganization();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    if (organization) {
      return;
    }

    if (organizationList?.length > 0) {
      setActive(organizationList[0]);
      return;
    }

    if (location.pathname === routes.notAMember() || location.pathname === routes.acceptInvite()) return;

    navigate(routes.notAMember());
  }, [setActive, organizationList, location, organization]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <SidebarContextProvider>
          <MainAppBar />
          <Sidebar listItems={listItems} />
          <GridFocusProvider>
            <SideDrawer />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                pt: `${GLOBAL_APP_BAR_HEIGHT}px`,
                minHeight: '100vh',
                flexDirection: 'column',
              }}
            >
              {children}
            </Box>
          </GridFocusProvider>
        </SidebarContextProvider>
      </SnackbarProvider>
    </Box>
  );
}
