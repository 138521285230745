import { useMemo } from 'react';

import { Box, Button, ButtonProps } from '@mui/material';
import { GridBasicGroupNode } from '@mui/x-data-grid/models/gridRows';
import {
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';

import { HierarchyBottomIcon, HierarchyMiddleIcon, HierarchyTopIcon } from 'src/components/atoms/grid/HierarchyIcons';
import { useGridCore } from 'src/providers/GridCoreProvider';

export const HierarchicalGroupingCell = ({
  params,
  toggle,
  children,
}: {
  params: GridRenderCellParams;
  toggle: boolean;
  children;
}) => {
  const { id, field, rowNode } = params;

  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  const setGroupExpanded = useGridCore((store) => store.setGroupExpanded);

  const handleClick: ButtonProps['onClick'] = (event) => {
    const groupNode = rowNode as GridBasicGroupNode;
    const notExpanded = !groupNode?.childrenExpanded;

    apiRef.current.setRowChildrenExpansion(id, notExpanded);
    apiRef.current.setCellFocus(id, field);

    setGroupExpanded(groupNode.groupingKey, notExpanded);

    event.stopPropagation();
  };

  const heirarchyIcons = useMemo(() => {
    let currentNode: GridBasicGroupNode = rowNode as GridBasicGroupNode;
    const heirarchyIcons = [];
    while (currentNode) {
      if (!currentNode.parent) {
        break;
      }

      const currentNodeIndex = apiRef.current.getRowIndexRelativeToVisibleRows(currentNode.id);
      const parentNode = apiRef.current.getRowNode(currentNode.parent) as GridBasicGroupNode;

      const isFooterChild = !parentNode.children
        .map((child) => apiRef.current.getRowIndexRelativeToVisibleRows(child))
        .some((childIndex) => childIndex > currentNodeIndex);

      if (isFooterChild) {
        heirarchyIcons.push(currentNode.id === rowNode.id ? HierarchyBottomIcon : spacerSvg);
      } else {
        heirarchyIcons.push(currentNode.id === rowNode.id ? HierarchyMiddleIcon : HierarchyTopIcon);
      }
      currentNode = parentNode;
    }
    return heirarchyIcons.reverse();
  }, [apiRef, rowNode]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      {...heirarchyIcons}
      {children}
      {toggle && filteredDescendantCount ? (
        <Button onClick={handleClick} tabIndex={-1} sx={{ minWidth: 0, padding: 0 }}>
          {(rowNode as GridBasicGroupNode)?.childrenExpanded ? collapseSvg : expandSvg}
        </Button>
      ) : null}
    </Box>
  );
};

const spacerSvg = (
  <svg width="48" height="10%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
);

const collapseSvg = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 14L12 9L17 14H7Z" fill="#28310B" fillOpacity="0.54" />
  </svg>
);

const expandSvg = (
  <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 10L12 15L17 10H7Z" fill="#28310B" fillOpacity="0.54" />
  </svg>
);
