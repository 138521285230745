import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';

import {
  BOLHeader,
  DocumentDetailBlock,
  Expenses,
  Footer,
  Notes,
  ProductsGrid,
  TermsAndTotals,
  formatDate,
} from '../sections';
import { SignatureLinesGroup } from '../sections/SignatureLinesGroup';
import { docStyles, sideMarginPadding } from '../utils/styles';

// TODO: Add type for input
export default function BillOfLadingDocument({
  salesOrder,
  displayUnitPrice,
  expenseCategories,
  customTerms,
  showUnitsOrdered = true,
  showTerms = true,
  showBillTo = true,
  extraPages = null,
}) {
  const notes = salesOrder.notes?.filter((n) => n.documentTypes.includes('BILL_OF_LADING'));

  const shipment = salesOrder.shipment;

  const shipDate = formatDate(salesOrder.shipDate);

  const deliveryDate = formatDate(salesOrder.deliveryDate);

  const expenses = salesOrder.expenses?.filter((expense) =>
    (expenseCategories ?? []).includes(expense.expenseType?.name)
  );

  const hasExpenses = expenses.length > 0;

  return (
    <Document>
      <Page size="LETTER" style={docStyles.page}>
        <BOLHeader order={salesOrder} showDateBlocks={false} showTerms={showTerms} showBillTo={showBillTo} />

        <BOLShippingDetails shipDate={shipDate} deliveryDate={deliveryDate} shipment={shipment} />

        <ProductsGrid
          productLineItems={salesOrder.lineItems.filter(
            // Don't show line items with 0 units picked
            (lineItem) => lineItem.unitsPicked !== 0
          )}
          showLineItemWeight={true}
          showUnitsOrdered={showUnitsOrdered}
          showUnitsPicked={true}
          showUnitPrice={displayUnitPrice}
          currency={salesOrder.currencyCode}
          orderType="SO"
          productTemplate={salesOrder.organization.productDescriptionTemplate}
        />

        {hasExpenses && <Expenses expenses={expenses} currency={salesOrder.currencyCode} />}

        <TermsAndTotals terms={customTerms} />

        <SignatureLinesGroup signatures={['Consignee', 'Driver', 'Receiver']} />

        {customTerms?.belowSignatures && (
          <View
            style={{
              fontSize: 8,
              paddingHorizontal: 30,
            }}
          >
            <Text>{customTerms?.belowSignatures}</Text>
          </View>
        )}

        {notes?.length > 0 && <Notes notes={notes} />}

        <Footer />
      </Page>
      {extraPages}
    </Document>
  );
}

export const BOLShippingDetails = ({ shipDate, deliveryDate, shipment }) => (
  <View
    style={{
      flexDirection: 'row',
      paddingTop: 20,
      paddingHorizontal: sideMarginPadding,
    }}
  >
    <DocumentDetailBlock headerText="Ship Date">
      <Text>{shipDate}</Text>
    </DocumentDetailBlock>
    <DocumentDetailBlock headerText="Delivery Date">
      <Text>{deliveryDate}</Text>
    </DocumentDetailBlock>
    <DocumentDetailBlock headerText="Temperature">
      <Text>
        {shipment.temperatureMin}°F - {shipment.temperatureMax}°F
      </Text>
    </DocumentDetailBlock>
  </View>
);
