import { UpdatePricingSheetEntryInput } from 'types/graphql';

import { useMutation } from '@redwoodjs/web';

import { GET_PRICING_SHEET_ENTRIES } from './usePricingSheetEntries';

const BULK_EDIT_PRICING_SHEET_ENTRY = gql`
  mutation BulkEditPricingSheetEntry($ids: [Int!]!, $input: UpdatePricingSheetEntryInput!) {
    bulkUpdatePricingSheetEntry(ids: $ids, input: $input)
  }
`;

export default function useBulkEditPricingSheetEntry(ids: number[]) {
  const [bulkEditPricingSheetEntry] = useMutation(BULK_EDIT_PRICING_SHEET_ENTRY, {
    refetchQueries: [GET_PRICING_SHEET_ENTRIES],
  });

  return async (input: UpdatePricingSheetEntryInput) => bulkEditPricingSheetEntry({ variables: { ids, input } });
}
