import { useQuery } from '@redwoodjs/web';

import { GetSalesOrderDocumentData } from '../api/SalesOrderDocument.api';

export default function useSalesOrderDocument({ slug, skip = false }) {
  const { data, loading, refetch } = useQuery(GetSalesOrderDocumentData, {
    variables: { slug },
    skip,
  });

  return {
    salesOrder: data?.salesOrder,
    loading,
    getDocumentData: () => refetch({ slug }).then((res) => res.data.salesOrder),
  };
}
