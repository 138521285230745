import { useEffect, useRef, useState } from 'react';

import { WithEditor } from '@grapesjs/react';
import { DisplaySettings } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Paper, TextField } from '@mui/material';

import { cx } from './common';
import TopbarButtons from './TopbarButtons';

export default function Topbar({
  className,
  queryVariables = {},
  setQueryVariables,
  documentVariables = [],
}: {
  className: string;
  queryVariables: Record<string, unknown>;
  setQueryVariables: (vars: Record<string, unknown>) => void;
  documentVariables: Array<{ variable: string; type: string }>;
}) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setQueryVariables({});
  }, [documentVariables]);

  return (
    <Paper
      sx={{
        borderRadius: 0,
      }}
    >
      <div className={cx('gjs-top-sidebar flex items-center p-1', className)}>
        <IconButton onClick={() => setOpen(true)} ref={ref}>
          <DisplaySettings />
        </IconButton>

        <Menu open={open} onClose={() => setOpen(false)} anchorEl={ref.current}>
          <Box display="flex" flexDirection="column" gap={2} p={2}>
            {documentVariables.map((variable) => {
              const { variable: name, type } = variable;
              const value = queryVariables[name];

              return (
                <TextField
                  key={name}
                  label={name}
                  value={value}
                  type={type}
                  size="small"
                  onChange={(e) => {
                    let val = e.target.value;

                    if (type === 'number') {
                      val = parseFloat(val);
                    }

                    setQueryVariables({
                      ...queryVariables,
                      [name]: val,
                    });
                  }}
                />
              );
            })}
          </Box>
        </Menu>

        <WithEditor>
          <TopbarButtons className="ml-auto px-2" />
        </WithEditor>
      </div>
    </Paper>
  );
}
