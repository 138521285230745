import { navigate, routes } from '@redwoodjs/router';
import { useMutation } from '@redwoodjs/web';

export const CREATE_SALES_ORDER_MUTATION = gql`
  mutation createSalesOrder($input: CreateSalesOrderInput!) {
    createSalesOrder(input: $input) {
      id
      slug
    }
  }
`;

export const CREATE_BUY_SELL_ORDER_MUTATION = gql`
  mutation createBuySellOrder($input: CreateBuySellOrderInput!) {
    createBuySellOrder(input: $input) {
      id
      slug
    }
  }
`;

const CREATE_SALES_ORDER_TEMPLATE_MUTATION = gql`
  mutation createSalesOrderTemplate($name: String!) {
    createSalesOrderTemplate(name: $name) {
      id
      slug
    }
  }
`;

const CREATE_SALES_ORDER_FROM_TEMPLATE_MUTATION = gql`
  mutation createSalesOrderFromTemplate($orderTemplateId: Int!) {
    createSalesOrderFromTemplate(orderTemplateId: $orderTemplateId) {
      id
      slug
    }
  }
`;

export const CREATE_BUY_SELL_ORDER_TEMPLATE_MUTATION = gql`
  mutation createBuySellOrderTemplate($name: String!) {
    createBuySellOrderTemplate(name: $name) {
      id
      slug
    }
  }
`;

export const CREATE_BUY_SELL_ORDER_FROM_TEMPLATE_MUTATION = gql`
  mutation createBuySellOrderFromTemplate($orderTemplateId: Int!) {
    createBuySellOrderFromTemplate(orderTemplateId: $orderTemplateId) {
      id
      slug
    }
  }
`;

export function useCreateSalesOrder() {
  const [createSalesOrder] = useMutation(CREATE_SALES_ORDER_MUTATION);

  return () => {
    createSalesOrder({
      variables: {
        input: {
          status: 'DRAFT',
        },
      },
      onCompleted: ({ createSalesOrder }) => {
        navigate(routes.salesOrder({ slug: createSalesOrder.slug }));
      },
    });
  };
}

export function useCreateBuySellOrder() {
  const [createBuySellOrder] = useMutation(CREATE_BUY_SELL_ORDER_MUTATION);

  return () => {
    createBuySellOrder({
      variables: {
        input: {},
      },
      onCompleted: ({ createBuySellOrder }) => {
        navigate(routes.buySellOrder({ slug: createBuySellOrder.slug }));
      },
    });
  };
}

export function useCreateSalesOrderTemplate() {
  const [createSalesOrderTemplate] = useMutation(CREATE_SALES_ORDER_TEMPLATE_MUTATION);

  return (name: string) => {
    createSalesOrderTemplate({
      variables: { name },
    }).then(({ data }) => {
      const { slug } = data.createSalesOrderTemplate;

      navigate(routes.salesOrder({ slug }));
    });
  };
}

export function useCreateBuySellOrderTemplate() {
  const [createBuySellOrderTemplate] = useMutation(CREATE_BUY_SELL_ORDER_TEMPLATE_MUTATION);

  return (name: string) => {
    createBuySellOrderTemplate({
      variables: { name },
    }).then(({ data }) => {
      const { slug } = data.createBuySellOrderTemplate;
      navigate(routes.buySellOrder({ slug }));
    });
  };
}

export function useCreateSalesOrderFromTemplate() {
  const [createSalesOrderFromTemplate] = useMutation(CREATE_SALES_ORDER_FROM_TEMPLATE_MUTATION);

  return (orderTemplateId: number) => {
    createSalesOrderFromTemplate({
      variables: { orderTemplateId },
    }).then(({ data }) => {
      const { slug } = data.createSalesOrderFromTemplate;
      navigate(routes.salesOrder({ slug }));
    });
  };
}

export function useCreateBuySellOrderFromTemplate() {
  const [createBuySellOrderFromTemplate] = useMutation(CREATE_BUY_SELL_ORDER_FROM_TEMPLATE_MUTATION);

  return (orderTemplateId: number) => {
    createBuySellOrderFromTemplate({
      variables: { orderTemplateId },
    }).then(({ data }) => {
      const { slug } = data.createBuySellOrderFromTemplate;
      navigate(routes.buySellOrder({ slug }));
    });
  };
}
