const formatName = (user?: { firstName?: string; lastName?: string }, emptyDefault = '--') => {
  if (!user) {
    return emptyDefault;
  }

  const { firstName = '', lastName = '' } = user;

  if (!firstName && !lastName) {
    return emptyDefault;
  }

  if (!lastName) {
    return firstName;
  }

  return [firstName, lastName].join(' ');
};

export { formatName };
