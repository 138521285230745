import Mustache from 'mustache';

const defaultTemplate =
  '{{{ commodity }}} {{{ variety }}} {{{ packStyle }}} {{{ label }}} {{{ grade }}} {{{ organic }}} {{{ countryOfOrigin }}}';

export default function description(
  product: {
    commodity: {
      name: string;
    };
    commodityStyle: {
      name: string;
    };
    commodityLabel?: {
      name: string;
    } | null;
    commoditySize: {
      name: string;
    };
    grade?: string | null;
    organic?: boolean;
    countryOfOrigin: string;
  },
  overrideTemplate?: string
) {
  if (!product) {
    return '';
  }

  const template = overrideTemplate || defaultTemplate;

  // We have determined that our users will expect to see product descriptions in line items
  // and elsewhere in this order: commodity, variety, pack style, grade, country of origin
  // in our system, products have commodity, commodityStyle, commoditySize, commodityUnit, commodityLabel, and grade.
  const attributes = {
    commodity: product.commodity?.name,
    variety: product.commodityStyle?.name,
    packStyle: product.commoditySize?.name,
    label: product.commodityLabel?.name,
    grade: product.grade,
    organic: product.organic ? 'Organic' : '',
    countryOfOrigin: product.countryOfOrigin,
  };

  const rendered = Mustache.render(template, attributes);

  return rendered.trim().replace(/\s+/g, ' ');
}
