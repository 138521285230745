import { Prisma } from '@prisma/client';
import { Place } from 'types/graphql';

export const getPrimaryPlace = (
  places: (Place | Prisma.PlaceGetPayload<unknown>)[],
  subsidiary?: Place | Prisma.PlaceGetPayload<unknown>
) => {
  const primaryBusiness = places.find((place) => place.addressRoles.includes('PRIMARY_BUSINESS')) ?? places[0];

  return subsidiary ?? primaryBusiness;
};
