import useCellPrediction, { CellPredictionProps } from 'src/hooks/useCellPrediction';

import SearchCell, { SearchCellProps } from './SearchCell';

/**
 * A cell that offers a prediction for the value of another cell.
 * Prediction must be same type as the cell value.
 */
export default function SearchPredictionCell<T>({
  getPrediction,
  getOptionLabel,
  ...params
}: SearchCellProps<T> & CellPredictionProps<T>) {
  const prediction = useCellPrediction({
    getPrediction,
    formatPrediction: getOptionLabel,
    ...params,
  });

  return (
    <SearchCell
      {...params}
      getOptionLabel={getOptionLabel}
      textFieldProps={{
        ...prediction,
      }}
    />
  );
}
