import { useState } from 'react';

import { Box, TextField } from '@mui/material';

import { useQuery } from '@redwoodjs/web';

import Autocomplete from 'src/components/atoms/Autocomplete';

const QUERY_EXCHANGE_RATES = gql`
  query QueryExchangeRates {
    exchangeRates {
      rates
    }
  }
`;

const currencyOptions = ['USD', 'CAD'];

export const CurrencyInput = ({
  currencyValue,
  exchangeRateValue,
  disabled,
  onChangeCurrency,
  onChangeExchangeRate,
}) => {
  const { data, loading } = useQuery(QUERY_EXCHANGE_RATES);

  const [exchangeRate, setExchangeRate] = useState(exchangeRateValue ?? 1);

  const currency = currencyValue ?? 'USD';
  const rates = data?.exchangeRates?.rates;
  const suggestedRate = rates?.[currency];

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Autocomplete
        width="100px"
        value={currency}
        options={currencyOptions}
        disabled={disabled}
        disableClearable
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option}
        sx={{
          '& .MuiInputBase-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
        onChange={(_, value) => {
          onChangeCurrency(value);

          const newRate = rates?.[value];

          if (newRate) {
            setExchangeRate(newRate);
            onChangeExchangeRate(Number(newRate));
          }
        }}
      />
      <TextField
        value={exchangeRate}
        disabled={disabled || currency === 'USD'}
        label="Exchange Rate"
        onChange={(e) => {
          setExchangeRate(Number(e.target.value));
          onChangeExchangeRate(Number(e.target.value));
        }}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            step: 0.01,
          },
        }}
        onKeyDown={(e) => {
          if (e.key === 'Tab' && !exchangeRate) {
            setExchangeRate(Number(suggestedRate));
            onChangeExchangeRate(Number(suggestedRate));
          }
        }}
        sx={{
          [`& .MuiOutlinedInput-root`]: {
            [`& fieldset`]: {
              borderLeftWidth: '0px',
            },
            [`&:hover fieldset`]: {
              borderLeftWidth: '1px',
            },
            [`&.Mui-focused fieldset`]: {
              borderLeftWidth: '1px',
            },
          },
          '& .MuiInputBase-root': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: 'none',
          },
          flexGrow: 1,
        }}
      />
    </Box>
  );
};
