import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export type AbortSignal = 'abort' | null;

/**
 * Some event handlers cause normal debounces to fire right away, most likely due to some
 * render. This debounce uses a ref to avoid that.
 *
 * @param func - The function to debounce.
 * @param delay - The debounce delay in milliseconds.
 * @param abortSignal - A ref to a value that will cause the debounce to abort if set as 'abort'. It will be cleared once cancelled.
 * @param deps - Dependencies for the callback function.
 */
export function useDebounce(
  func: (...args: any[]) => void,
  delay: number,
  abortSignal?: MutableRefObject<AbortSignal>,
  deps: any[] = []
) {
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  // Memoize the function to ensure a stable reference
  const memoizedFunc = useCallback(func, deps);

  const debounce = useCallback(
    (...args: any[]) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        memoizedFunc(...args);
      }, delay);
    },
    [memoizedFunc, delay]
  );

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (abortSignal?.current === 'abort' && debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
      debounceTimeout.current = null;
      if (abortSignal) {
        abortSignal.current = null;
      }
    }
  }, [abortSignal?.current]);

  return debounce;
}
